import React, {useState} from "react";
import Modal from "../../../../../Modal";
import CancelSwitcher from "./CancelSwitcher";
import SwitchToBasicModal from "./SwitchToBasicModal";
import ServiceCancelationInfoModal from "./ServiceCancelationInfoModal";

const CancelServiceModal = ({cancel, row, activationDevice}) => {
    const [showPlanOptions, setShowPlanOptions] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
        <Modal>
            <div className="modal__content" style={{ width: "40vw" }}>
                <div className="modal__header">
                    <div className="modal__header-row">
                        <h2>Suspend Service</h2>
                        <button onClick={() => cancel(false)}>X</button>
                    </div>
                </div>
                <hr />
                <div className="modal__confirm">
                    <div className="modal__confirm-content">
                        {!showPlanOptions && (
                        <CancelSwitcher showPlan={setShowPlanOptions} />
                        )}
                        {showPlanOptions && !showConfirmation && (
                          <SwitchToBasicModal row={row} activationDevice={activationDevice} setShowConfirmation={setShowConfirmation} />
                        )}
                        <ServiceCancelationInfoModal row={row} activationDevice={activationDevice} onClose={() => cancel(false)} />
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default CancelServiceModal;