import React, { useEffect } from 'react';

import BillingHeader from '../BillingHeader/BillingHeader'
import {ClipedNavbar, ClipedNav} from '../../../general/ClipedNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboard, openDashboard } from '../../../../stores/reducers/dashboardInfo/AC';
import { iFullStoreState } from '../../../../shared/interfaces';
import { DashboardSize } from '../../../../stores/reducers/dashboardInfo';


const BillingNavBar = () => {
    const dispatch = useDispatch();
    const size = useSelector<iFullStoreState, DashboardSize>(
      (s) => s.dashboardInfo.size
    );

    useEffect(() => {
        dispatch(openDashboard());
        return () => {
            if (size === DashboardSize.OPEN_FULL) {
              dispatch(closeDashboard());
            }
        }
    },[size, dispatch])

    return (
                <>
            <BillingHeader/>
            <ClipedNavbar>
                <ClipedNav to="/billing/units">units</ClipedNav>
                <ClipedNav to='/billing/invoices'>invoices</ClipedNav>
                <ClipedNav to='/billing/orders'>orders</ClipedNav>
            </ClipedNavbar>
                </>
    )
}


export default BillingNavBar;
