import {connect as reduxConnect} from 'react-redux';
import {Store} from 'redux';
import {
	always,
	apply,
	cond,
	equals,
	F,
	forEachObjIndexed,
	fromPairs,
	groupBy,
	ifElse,
	isEmpty,
	isNil,
	keys,
	map,
	merge,
	not,
	omit,
	or,
	pathOr,
	pipe,
	prop,
	sortBy,
	splitEvery,
	T,
	toPairs,
	values,
	flatten
} from 'ramda';
import moment from 'moment';
import momentTz, {Moment} from 'moment-timezone';
import {faTruck} from '@fortawesome/fontawesome-free-solid';

import {
	AlertTypes,
	iAlertActivity,
	iConfig,
	iDeviceDetails,
	iFullStoreState,
	iList,
	iLocation,
	iLongLocation,
	Units,
	UserAuth,
	iDevicePing,
	iLoadedFileInfo,
	ItemType,
	iTrip,
	ReportContainer,
	IconTypes
} from './interfaces';
import { isNothing, whole } from './ramda';
import {AUDIT_LOG, defaultSettingsNaming, IS_PUBLIC_VIEW} from './constants';
import { localStorage } from './storage';
import { getDbConfig, getDefaultSettings } from './db/general-db';
import { auditKeyGen } from './firebase';
import { DevicesDetailsContainer } from '../stores/reducers/devicesData';
import { showAllDevices } from "../stores/reducers/tagsDevicesMap/AC";
import jsPDF from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { countSafety } from '../components/pages/reports/report-table/cells/helpers';

export const calcTotalTime = (endDate, startDate) => {

	const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);

    let diffHours = endDateTime.getHours() - startDateTime.getHours();
    let diffMinutes = endDateTime.getMinutes() - startDateTime.getMinutes();

    if (diffMinutes < 0) {
        diffHours--;
        diffMinutes += 60;
    }

    if (diffHours < 0) {
        diffHours += 24;
    }

    const diffString = `${diffHours.toString().padStart(2, '0')}:${diffMinutes.toString().padStart(2, '0')}`;

	return diffString;
};

export const distance = (one: iLocation, two: iLocation) => {

	const radlat1 = Math.PI * one.lat / 180;
	const radlat2 = Math.PI * two.lat / 180;

	const theta = one.lng - two.lng;

	const radtheta = Math.PI * theta / 180;
	let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	dist = Math.acos(dist)
	dist = dist * 180/Math.PI
	dist = dist * 60 * 1.1515

	return dist
}

export const shortLocation = (coordinate: iLongLocation): iLocation => ({
	lat: coordinate.latitude,
	lng: coordinate.longitude,
})
export const fullLocation = (coordinate: iLocation): iLongLocation => ({
	latitude: coordinate.lat,
	longitude: coordinate.lng
})

const getDateFromRecord = (day, isFirstDay = false) =>  {
	if (isFirstDay) {
		return new Date(day.format('DD/MM/YYYY').split("/").reverse().join("-"));
	} else {
		return new Date(day.format('MM/DD/YYYY').split("/").reverse().join("-"));
	}
};

export const timeClockRecords = (records) => {
	const dividedByDayRecords = records.flatMap(record => {
		const startDate = getDateFromRecord(record.startDate, true).getTime();
		const endDate = getDateFromRecord(record.endDate, true).getTime();
		if (endDate !== startDate) {
			const daysDiff = (endDate - startDate) / (24 * 60 * 60 * 1000) + 1;
			const days = Array.from({ length: daysDiff}, (_, idx) => {
				const dayStart = new Date(startDate);
				dayStart.setDate(new Date(startDate).getDate() + idx);
				dayStart.setHours(0);
				dayStart.setMinutes(0);
				dayStart.setSeconds(0);
				const dayEnd = new Date(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate(), 23, 59, 59);
				return {
					...record,
					startDate: idx === 0 ? record.startDate : moment(dayStart),
					endDate: idx === daysDiff - 1 ? record.endDate : moment(dayEnd),
				};
			});
			return days;
		} else {
			return [record];
		}
	});

	return dividedByDayRecords.sort((a, b) => {
		return getDateFromRecord(a.startDate) < getDateFromRecord(b.startDate) ? -1 : 1;
	})
};

export const gstractLocation = (obj) => ({
	lat: obj.lat(), lng: obj.lng()
})
interface theobj<T> {
	[key: string]: T
}
export const vals = <T>(obj: theobj<T>) => Object.keys(obj || {}).map(k => obj[k]);

export const lCount = (obj) => Object.keys(obj || {}).length;
export const lEmpty = (obj) => !Object.keys(obj || {}).length;

export const minutesToFriendly = (minutes: number) => {
	if (minutes > 60) {
		const hours = Math.trunc(minutes/60)
		const min = Math.round(minutes - hours * 60)
		return min >= 10 ? `${hours}H${min}M`: `${hours}H0${min}M`;
	} else {
		return minutes >= 10 ? `0H${Math.round(minutes)}M` : `0H0${Math.round(minutes)}M` ;
	}
}

export const noSubmit = (e: any):any => e.preventDefault();

export const friendlyDiff = (later: moment.Moment, earlier: moment.Moment) => {
	if (later.diff(earlier, 'days')) return later.diff(earlier, 'days') + ' day' + (later.diff(earlier, 'days') > 1 ? 's' : '');
	const both = later.diff(earlier, 'hours', true);
	const minutes = Math.round(60 * both );
	return isNaN(minutes) ? '0H00M' : minutesToFriendly(minutes)
}

export const getDiff = (later: moment.Moment, earlier: moment.Moment) => {
	return later.isValid() && earlier.isValid() ? later.diff(earlier, 'hours', true) : 0;
}

export const idValArr = <T>(obj: theobj<T>) => Object.keys(obj || {}).map(k => ({id: k, val: obj[k] as T}))

export const idValsToList = <T>(arr: {id: string, val: T}[]): iList<T> => fromPairs(arr.map(x => [x.id, x.val]) as any);


export const range = (start,end, step=1) => {
	let ret = [];

	while (start <= end) ret.push(start++);

	return ret;
}

declare var google: any

export const getElipsisPoints = (center: iLocation, width, height) => {
	const c = new google.maps.LatLng(center.lat, center.lng);

    const center1 = new google.maps.LatLng(center.lat+0.1, center.lng);
	const center2 = new google.maps.LatLng(center.lat, center.lng+0.1);

	const latConv = google.maps.geometry.spherical.computeDistanceBetween(c, center1);
	const lngConv = google.maps.geometry.spherical.computeDistanceBetween(c, center2);

	return range(0, 359).map(angle => {
		const x = center.lat + ((height * Math.cos(angle * (Math.PI / 180))) / latConv);
		const y = center.lng + ((width * Math.sin(angle * (Math.PI / 180))) / lngConv);

		return new google.maps.LatLng(x,y);
	})
}

export const getRightLng = (center: iLocation, width) => {
	const c = new google.maps.LatLng(center.lat, center.lng);

	const center1 = new google.maps.LatLng(center.lat+0.1, center.lng);
	const center2 = new google.maps.LatLng(center.lat, center.lng+0.1);

	const latConv = google.maps.geometry.spherical.computeDistanceBetween(c, center1);
	const lngConv = google.maps.geometry.spherical.computeDistanceBetween(c, center2);

	return center.lng + ((width*Math.sin(90*(Math.PI/180)))/lngConv);
}

export const getTopLat = (center: iLocation, height) => {
	const c = new google.maps.LatLng(center.lat, center.lng);

	const center1 = new google.maps.LatLng(center.lat+0.1, center.lng);
	const center2 = new google.maps.LatLng(center.lat, center.lng+0.1);

	const latConv = google.maps.geometry.spherical.computeDistanceBetween(c, center1);
	var lngConv = google.maps.geometry.spherical.computeDistanceBetween(c,center2);

	return center.lat + ((height*Math.cos(0*(Math.PI/180)))/latConv);
}

export const getBottomLeftAngleOfRectangle = (center, topRight) => {

	const diffLat = topRight?.lat - center?.lat;
	const diffLng = topRight?.lng - center?.lng;

	const lat = center?.lat - diffLat;
	const lng = center?.lng - diffLng;

	return {lat, lng}
}

export const getRadiusWidth = (center: iLocation, lng) => {
	const c = new google.maps.LatLng(center.lat, center.lng);

	const center1 = new google.maps.LatLng(center.lat+0.1, center.lng);
	const center2 = new google.maps.LatLng(center.lat, center.lng+0.1);

	const latConv = google.maps.geometry.spherical.computeDistanceBetween(c, center1);
	const lngConv = google.maps.geometry.spherical.computeDistanceBetween(c, center2);

	return ((lng - center.lng)*lngConv)/Math.sin(90*(Math.PI/180))
}
export const getRadiusHeight = (center: iLocation, lat) => {
	const c = new google.maps.LatLng(center.lat, center.lng);

	const center1 = new google.maps.LatLng(center.lat+0.1, center.lng);
	const center2 = new google.maps.LatLng(center.lat, center.lng+0.1);

	const latConv = google.maps.geometry.spherical.computeDistanceBetween(c, center1);
	const lngConv = google.maps.geometry.spherical.computeDistanceBetween(c, center2);

	return ((lat - center.lat)*latConv) / Math.cos(0*(Math.PI/180))
}


const fixAlteredIdx = (chunkIdx, alteredIdx) => {
    // first chunk is always 10 shy since we didn't tack on any before it
    if (chunkIdx === 0) return alteredIdx;

    return alteredIdx + (80 - 10) + ((chunkIdx - 1) * 80)
}

const chunkPointsForSnap = (points: iLocation[]) => {
    // chunk leaving space for overlap for inference purposes
    const chunks = splitEvery(80)(points);

    // take 10 onto each side
    return chunks.map((chunk, idx) => {
        let prev = [...(chunks[idx - 1] || []) ];
        let next = [...(chunks[idx + 1] || []) ];

        prev = prev.slice(Math.max(prev.length - 10, 1));
        next = next.slice(0, 10);

        return [...prev, ...chunk, ...next];
    });
}

export const snappedPoints = async (pings: iLocation[]) => {
    const chunks = chunkPointsForSnap(pings);

    const snapped: any = await Promise.all(chunks.map(async (chunkPoints, chunkidx) => {
        const res = await fetch(
            'https://roads.googleapis.com/v1/snapToRoads?interpolate=true&path=' + chunkPoints.map(ping => `${ping.lat},${ping.lng}`).join('|') + '&key=AIzaSyBsNvkRiUmkDgv71-TaWfubQnf5E1niYXY',
        ).then(response => response.json());

        // lets just make originalIndex the last seen
        let lastSeen = false;
        let normalized = res.snappedPoints.map(x => {
            if (x.originalIndex !== undefined) lastSeen = x.originalIndex;

            return {...x, originalIndex: lastSeen}
        })

        return normalized.map(p => ({
            ...p, originalIndex: p.originalIndex === undefined ? undefined : fixAlteredIdx(chunkidx, p.originalIndex)
        }));
    }));

    // let combined = [].concat.apply([], snapped);

    // now lets group and push future extra points into previous real point
    let grouped = {};
    snapped.forEach(pipe(
		groupBy(prop('originalIndex')),
		omit(keys(grouped)),
		forEachObjIndexed((points, origIdx) => grouped[origIdx] = (points as any).map(x => ({
			lat: x.location.latitude,
			lng: x.location.longitude
		})))
	))

    return Object.keys(grouped).map(originalIndex => {
        const snappedPings = grouped[originalIndex];

        return {...pings[parseInt(originalIndex)], coordinates: snappedPings };
    });
}

export const icoSize = (zoom) => 25 - (22 - zoom)

export const ramdaLog = x => { console.log('rlog', x); return x; }



export const dev = !!localStorage.get('developer');


export const momentCmp = (a:moment.Moment|Date, b:moment.Moment|Date) => a.valueOf() - b.valueOf();


export const alertDotIcon = (alertActivity: iAlertActivity/*, pointSpeed: number*/): string | false => {
	if (!keys(alertActivity).length) return false;

    const has = (t: AlertTypes) => alertActivity['has' + t.toLowerCase()];

    const dir = 'assets/incidents/';

    if (has(AlertTypes.Accel)) return dir + 'accel.png';
    if (has(AlertTypes.Decel)) return dir + 'decel.png';
    if (has(AlertTypes.Heartbeat)) return dir + 'heartbeat.png';
	if (has(AlertTypes.DriverSmoking)) return dir + 'idle.png';
    if (has(AlertTypes.Idle)) return dir + 'idle.png';
    if (has(AlertTypes.IdleStop)) return dir + 'idle-stop.png';
    if (has(AlertTypes.IdleStart)) return dir + 'idle-start.png';
    if (has(AlertTypes.IgnOff)) return dir + 'ignition-off.png';
    if (has(AlertTypes.IgnOn)) return dir + 'ignition-on.png';
    if (has(AlertTypes.InHi)) return dir + 'input-high.png';
    if (has(AlertTypes.InLo)) return dir + 'input-low.png';
    if (has(AlertTypes.Speed)) return dir + 'max-speed.png';
    if (has(AlertTypes.PowerOff)) return dir + 'power-off.png';
    if (has(AlertTypes.PowerOn)) return dir + 'power-on.png';
    if (has(AlertTypes.SOS)) return dir + 'sos.png';
    if (has(AlertTypes.TowStart)) return dir + 'tow-start.png';
    if (has(AlertTypes.TowStop)) return dir + 'tow-stop.png';
    if (has(AlertTypes.MvStart)) return dir + 'move-start.png';
    if (has(AlertTypes.MvStop)) return dir + 'move-stop.png';
    if (has(AlertTypes.TripStart)) return dir + 'trip-start.png';
    if (has(AlertTypes.TripStop)) return dir + 'trip-stop.png';
	if (has(AlertTypes.HarshLeftTurn)) return dir + 'turn_left.png';
	if (has(AlertTypes.HarshRightTurn)) return dir + 'turn_right.png';
	if (has(AlertTypes.FrontCollision)) return dir + 'car_crash.png';
	if (has(AlertTypes.UnfastenedSafetyBelt)) return dir + 'seatbelt.png';
	if (has(AlertTypes.DriverMakingPhoneCalls)) return dir + 'phone.png';
	if (has(AlertTypes.Tailgating)) return dir + 'car_distance.png';
	if (has(AlertTypes.LensCover)) return dir + 'lens_cover.png';

    return Object.keys(alertActivity).some(k => alertActivity[k]) ? dir + 'alerts.png' : false;
}

export const isIncidentSafety = (incident: AlertTypes) =>
	[
		AlertTypes.Speed,
		AlertTypes.Decel,
		AlertTypes.Accel,
		AlertTypes.DriverMakingPhoneCalls,
		AlertTypes.Tailgating,
		AlertTypes.IgnOnInt,
		AlertTypes.IgnOn,
		AlertTypes.IgnOff,
		AlertTypes.Poll,
		AlertTypes.LowVolt,
		AlertTypes.PowerUp,
		AlertTypes.DriverSmoking,
		AlertTypes.Idle,
		AlertTypes.TowStart,
		AlertTypes.TowStop,
		AlertTypes.Heartbeat,
		AlertTypes.IgnOffInt,
		AlertTypes.DirChange,
		AlertTypes.Mileage,
		AlertTypes.VIgnOnInt,
		AlertTypes.VIgnOn,
		AlertTypes.VIgnOff,
		AlertTypes.BatDCInt,
		AlertTypes.BatDC,
		AlertTypes.BatRC,
		AlertTypes.MvStart,
		AlertTypes.MvStop,
		AlertTypes.ECodes,
		AlertTypes.InHi,
		AlertTypes.InLo,
		AlertTypes.IdleStop,
		AlertTypes.IdleStart,
		AlertTypes.SOS,
		AlertTypes.PowerOn,
		AlertTypes.PowerOff,
		AlertTypes.Int,
		AlertTypes.FenceEnter,
		AlertTypes.FenceExit,
		AlertTypes.TripStart,
		AlertTypes.TripStop,
		AlertTypes.UnfastenedSafetyBelt,
		AlertTypes.DriverMakingPhoneCalls,
		AlertTypes.HarshLeftTurn,
		AlertTypes.HarshRightTurn,
		AlertTypes.FrontCollision,
		AlertTypes.LensCover,
		// AlertTypes.speedPosted,
		// AlertTypes.speedCapped,
		// AlertTypes.harsh,
		// AlertTypes.rapid,
	].map(x => x.toLowerCase()).indexOf(incident.indexOf('has') === 0 ? incident.replace('has', '') as AlertTypes : incident.toLowerCase()) !== -1;


export const kilos = mph => Math.round(mph * 1.609344);
export const knots = mph => Math.round(mph * 1.15078);

export const userSpeed = (speed: number, units: Units | undefined = Units.miles): number => {
    switch (units) {
        case Units.kilo:
            return kilos(speed);
        case Units.knots:
            return knots(speed);
        case Units.miles:
        default:
            return speed;
    }
};

export const speedWholeNumber = (mph, units: Units) => whole(cond([
	[equals(Units.miles), always(mph)],
	[equals(Units.kilo), always(kilos(mph))],
	[equals(Units.knots), always(knots(mph))],
	[T, always(mph)],
])(units));

export const friendlySpeed = (mph, deviceSetting?: Units) => {
	if (deviceSetting === Units.miles || !deviceSetting) return !mph && mph !== 0 ? 'N/A mph' : `${whole(mph)} mph`;

	if (deviceSetting === Units.kilo) return !mph && mph !== 0 ? 'N/A kph' : `${whole(kilos(mph))} kph`;

	if (deviceSetting === Units.knots) return !mph && mph !== 0 ? 'N/A knots' : `${whole(knots(mph))} knots`;
}

export const friendlyDist = (miles, deviceSetting?: Units) => {
	if (deviceSetting === Units.miles || !deviceSetting) return `${miles} mi`;

	if (deviceSetting === Units.kilo) return `${kilos(miles)} km`;

	if (deviceSetting === Units.knots) return `${knots(miles)} knots`;
}

export const userUnitSpeedStr = (units: Units = Units.miles) => cond([
	[equals(Units.miles), always('mph')],
	[equals(Units.kilo), always('kph')],
	[equals(Units.knots), always('knots')],
	[T, always('mph')]
])(units);

export const userUnitStr = (units: Units = Units.miles) => cond([
	[equals(Units.miles), always('mpg')],
	[equals(Units.kilo), always('kpl')],
	[equals(Units.knots), always('knots/g')],
	[T, always('mpg')]
])(units);

export const milesPerToUnitsPer = (units: Units = Units.miles, mpg: number): number => cond([
	[equals(Units.miles), always(mpg)],
	[equals(Units.kilo), always(Math.round(mpg * 425144))],
	[equals(Units.knots), always(knots(mpg))],
	[T, always(mpg)]
])(units);

export const friendlyMilesPer = (mpg, deviceSetting: Units = Units.miles) =>
	`${milesPerToUnitsPer(deviceSetting, mpg)} ${userUnitStr(deviceSetting)}`


export const isMobile = window.screen.width <= 768;

export const unitShort = (unit) => {
	switch (unit) {
		case (Units.knots) :
			return 'kn';
		case (Units.kilo) :
			return 'km';
		default:
		case (Units.miles) :
			return 'mi';

	}
}

// deviceOffset
export const utcOffset = (timezone: string, isDst): number => {
	const dstAdjustment = isDst ? 1 : 0;

	const zone = momentTz().tz(timezone).format("Z");

	const hoursOffset = momentTz().zone(zone).utcOffset() / 60;

	return hoursOffset + dstAdjustment;
}

export const clearTimezone = (timezone: string) => {
	let timeValue = timezone.startsWith('-') ? timezone.slice(6) : timezone; // get timezone value without offset (e.g: -08:00 Brazil/DeNoroha => Brazil/DeNoroha)
	let result = timeValue.split(' ').join(''); // remove spaces
	return result;
}

export const userCurrentOffset = parseInt(moment().format('ZZ'))/100;

export const countDateWithUserAndTimezoneOffset = (time: Moment, timezone: string): Moment => {
	const deviceOffset = utcOffset(timezone, false);
    const diff = userCurrentOffset - deviceOffset;
    const date = moment(time).subtract(diff, 'hours');
	return date;
}

export const targetVal = ({ target: { value }}) => value;
export const targetChecked = ({ target: { checked }}) => checked;

export const connect = <O, F>(fn: (state: iFullStoreState, ownProps: O) => F) => reduxConnect(fn);

export const isAddress = (x: any) => ifElse(
	isNothing,
	F,
	pipe(values, apply(or(true)), Boolean)
)(x)

export const emptyObjConst = {}


export const clientDefaultSettings = async (callback) => {
	const defaultSettings = await getDefaultSettings().then(callback)
}

export const syncConfig = async (version) => {

	let dbConfig = await getDbConfig()

	if (version !== dbConfig.version) {
		localStorage.set('db-config', dbConfig)
	}

	return dbConfig;
}

export const getConfig = async () => {
	const config = localStorage.get<iConfig>('db-config');

	if (!config) {
		return await syncConfig(false)
	} else {
		syncConfig(config.version).then((info) => {
			if (!info?.version) return;
			if (info?.version !== config.version) window.location.reload()
		})

		return config
	}
}

export const resetRememberMeIfExists = () => {
	const myItem = window.localStorage.getItem('remember-me');
	window.localStorage.clear();
	myItem && window.localStorage.setItem('remember-me', myItem);
};

export const storePathAwaiterMaker = (theStore: Store<iFullStoreState>) => async <T> (path: string[]) => new Promise<T>(res => {
    const val = pathOr('missing', path, theStore.getState());
    if (val !== 'missing') {
        res(val as T);
        return;
    }
    const cancel = theStore.subscribe(() => {
        const val = pathOr('missing', path, theStore.getState());

        if (val === 'missing') return;

        cancel();

        res(val as T);
    });
});


export const extractDevice = (devicesDetails: DevicesDetailsContainer, id?: string): iDeviceDetails => id && devicesDetails.get(id) || {
	eventValues: {},
	name: 'Unknown device',
	id: '-1',
	possibleAlerts: [],
	externalData: {},
	icon: {
		type: IconTypes.FaIcon,
		fa: faTruck
	},
	timezone: 'EST',
} as iDeviceDetails;

export const extractDevicesFromTags = (filters, allTags) => {
    // tags translate to devices so lets get a true device list
    let deviceIds = Object.keys(filters[ItemType.tag] || {})
        .map(id => allTags[id])
        .filter(tag => tag.instances)
        .filter(tag => tag.instances[ItemType.device])
        .map(tag => Object.keys(tag.instances[ItemType.device]))
        .filter(k => k.length > 0)

    return flatten(deviceIds).concat(
        Object.keys(filters[ItemType.device] || {})
    );
}

export const makeAudit = (user: UserAuth, updateObj: {[path: string]: any}) => pipe(
	toPairs,
	map(([path, v]) => [`${AUDIT_LOG}/${path}/${auditKeyGen()}`, auditObj(user, v)] as [string, any]),
	fromPairs,
	merge(updateObj)
)(updateObj)

const auditObj = (user: UserAuth, newVal) => ({
	time: new Date(),
	uid: user.uid,
	realId: pathOr(false, ['beToken', 'realId'], user),
	newVal: isNil(newVal) ? '__DELETED__' : newVal
})

export const makeAuditForDevicesList = (user, updateObj) => pipe(
	toPairs,
	map(([path, v]) => [`${AUDIT_LOG}/${path}/${auditKeyGen()}`, auditObjForDevicesList(user, v)] as [string, any]),
	fromPairs,
	merge(updateObj)
)(updateObj)

const auditObjForDevicesList = (user, newVal) => ({
	time: new Date(),
	uid: user.uid,
	...(newVal.deviceId && {deviceId: newVal.deviceId}),
	...(newVal.personId && {personId: newVal.personId}),
	action: newVal.action,
})

export const isDev = ['localhost', 'dcmt49peukxhe.cloudfront.net', 'mastrack-dev-s3-site.s3-website-us-east-1.amazonaws.com'].indexOf(window.location.hostname) !== -1;

export const isTouchDevice = (() => {
	try{
		document.createEvent("TouchEvent");
		return true;
	}catch(e){
		return false;
	}
})();

export const ALL_DEVICES_TAG = '-allDevicesTag'

export const getAllDevicesTag = (devices) => {
	const devicesForTag = devices.reduce((acc, rec) => {
		return {...acc, [rec]:true}
	}, {})

	return { [ALL_DEVICES_TAG]: { details: {id: ALL_DEVICES_TAG, name: 'ALL DEVICES', 'extra-info': {}},
								 instances: { device: devicesForTag}}
	       }
}

export const getFilteredDevicesLastPings = (devicesLastPings) => {
	return devicesLastPings.filter(deviceLastPing =>
		deviceLastPing
		&& !isEmpty(deviceLastPing.time)
		&& moment(deviceLastPing.time)
	);
}

export const getOrderedDevicesList = (devicesLastPings) => {
	const sortedByLastPing = sortBy(prop('time'));
	return sortedByLastPing( getFilteredDevicesLastPings(devicesLastPings) ).map(device => device.device);
}

export const setShowedDevices = (devicesLastPings, dispatch) => {
	dispatch(showAllDevices( getOrderedDevicesList(devicesLastPings) ));
}

interface iExportPDFProps  {
	fileName: string,
	data: UserOptions[]
}
export const exportPDF = ({ fileName = "Report.pdf",  data}: iExportPDFProps) => {
	const pdf = new jsPDF();
	data.forEach(table => {
		/*const {
			theme,
			head,
			body,
			foot,
			headStyles,
			bodyStyles,
			footStyles,
		} = table;*/

		autoTable(pdf, table);
	});

	pdf.save( `${fileName + (fileName.endsWith(".pdf") ? "" : ".pdf")}`)

	/*const fileResolution = fileName.match(/.pdf$/) ? "" : ".pdf"
	pdf.save( `${fileName}${fileResolution}`)*/
};

export const WeekOrDayHelper = (day, isWeek) => {
	const asMoment = moment(day);

	const today = moment();

	const rangeType = isWeek ? 'week' : 'day';

	let from = asMoment.clone().startOf(rangeType).toDate();
	let to = asMoment.clone().endOf(rangeType);

	if (to.isAfter(today)) {
		to = moment().endOf('day');
	}

	let endDay = to.toDate();

	return {
		startDate: from,
		endDate: endDay
	}

};

export const handleSearch = (items, searchParams, searchQueryValue) => {
	return Object.keys(items)
		.filter(key => {
			return searchParams.some((newItem) => {
				return (
					items[key].details[newItem]
						?.toString()
						.toLowerCase()
						.indexOf(searchQueryValue.toLowerCase()) > -1
				)
			})
		}).reduce((res, key) => (res[key] = items[key], res), {});
}

export const tryParseJson = (string) => {
	try {
		const result = JSON.parse(string);
		return result;
	} catch (e) {
		return undefined;
	}
}

export const deferredCall = (timeCount, callback) =>{
	setTimeout(callback, timeCount)
}


export const namingHelper = (str) => {
	return defaultSettingsNaming[Object.keys(defaultSettingsNaming).find(item => item === str)];
}

export const toggleBeacon = () => {
	(window as any).Beacon('toggle');
}

// export const countSafetyIncidentsFor = (devicePing: iList<iDevicePing>, deviceKey: string): {count: number, incidents: number} => {
// 	let incidents = 0;
// 	let count = 0;

// 	Object.values(devicePing).forEach(ping => {
// 		if (ping.device === deviceKey) {
// 			incidents += countSafety(ping);
// 			count++;
// 		}
// 	});
// 	return { incidents, count }
// }

export const countAvgSafetyPercentsFor = (deviceTrips: iList<iTrip>, deviceKey: string): number => {
	let safety = 0;
	let count = 0;

	Object.values(deviceTrips).forEach(trip => {
		if (trip.device === deviceKey) {
			safety += trip.safetyPercent;
			count++;
		}
	})

	return count? safety / count : 0;
}

export const countDriveTime = (displayRecords: ReportContainer) => {
    return Object.keys(displayRecords).reduce((acc, record) => {
        return acc + getDiff(moment(displayRecords[record].endDate), moment(displayRecords[record].startDate))
    }, 0);
}

export const countTraveledDistance = (displayRecords: ReportContainer | (iDevicePing | iTrip)[]) => {
	return Object.keys(displayRecords).reduce((acc, record) => {
		return acc + displayRecords[record].miles
	}, 0);
}

export const countDriveTimeFromDate = (trip: iTrip) => {
	return getDiff(moment(trip.endDate), moment(trip.startDate));
}

export const getTimeFormated = ( time: number) => {
    return `${Math.floor(time * 10) / 10} h`;
}

export const formatFileSize = (size: number): string => {
	if (!size) return "0 B";
	const sizePrefix = ["B", "KB", "MB", "GB"];
	for (let i = 0; i < sizePrefix.length; i++) {
		if (size < 1000 || i === sizePrefix.length - 1) {
			return `${parseFloat(size.toFixed(2))} ${sizePrefix[i]}`;
		} else {
			size /= 1000;
		}
	}
};

export const getFileExtension = (name: string):string => {
	const hasDot = name?.includes(".");
	const nameSplitByDot = name.split(".");
	return hasDot ? nameSplitByDot.slice(-1)[0] : "";
};

export const strToRegexStr = (str: string): string => {
	return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
};

export const getIndexFromName = (name: string): number | null => {
	const regexToGetIndex = /^.*\((\d+)\)([.].+)?$/; // number is in first group
	const match = name.match(regexToGetIndex);
	const index = +match?.[1];
	return index || null;
};

export const getNameWithIndex = (name: string, index: number): string => {
	if (!name?.includes(".")) return `${name}(${index})`;
	const nameSplitByDot = name.split(".");
	return `${nameSplitByDot.slice(0, -1).join(".")}(${index}).${nameSplitByDot.slice(-1)}`;
};

export const getSimilarValues = (name: string, arr: iLoadedFileInfo[]): iLoadedFileInfo[] => {
	const hasDot = name?.includes(".");
	const nameSplitByDot =  name.split(".");
	const nameOnly = hasDot ? nameSplitByDot.slice(0, -1).join(".") : name;
	const extension = hasDot ? nameSplitByDot.slice(-1) : "";
	const regexForSimilar = new RegExp(`^${strToRegexStr(nameOnly)}(\\((\\d+)\\))?${extension ? `.${extension}` : ""}$`);
	return arr.filter(itm => itm.name.match(regexForSimilar));
};

export const getFirstAvailable = (array: Array<number | null>): number => {
	const initValue = 2;
	const count = array.length + initValue;
	for (let i = initValue; i <= count; i++) {
		if (!array.includes(i)) return i;
	}
	return Math.max(...array) + 1; // unreal but
};

export const changeSameName = (name: string, arr: iLoadedFileInfo[]): string => {
	const sameCount = arr.filter(itm => itm.name === name).length;
	if (sameCount === 0) return name;

	const similarValues = getSimilarValues(name, arr);
	const namesIndexes = similarValues.map((itm) => getIndexFromName(itm?.name)).filter(itm => !!itm);

	const index = getFirstAvailable(namesIndexes);
	return getNameWithIndex(name, index);
};

export const formatPrice = (item) => {
	if (!item) return item;
	if (isNaN(item)) return item;
	return (+item) >= 0 ? `$${item.toFixed(2)}` : `-$${Math.abs(item).toFixed(2)}`;
}

export const getSumFrom = (array, parentKey, key) => {
	return array.reduce( (sum, cr) => {
		const value = +cr?.[parentKey]?.[key];
		return value ? sum + value : sum;
	}, 0);
}

export const getTotalMilesFor = (devicesTrips: iList<iTrip>, deviceKey: string) => {
	return Object.values(devicesTrips).reduce((acc, trip) => {
		return trip.device == deviceKey? acc + trip.miles : acc;
	}, 0);
}

export const getTotalHoursFor = (devicesTrips: iList<iTrip>, deviceKey: string) => {
	return Object.values(devicesTrips).reduce((acc, trip) => {
		return trip.device == deviceKey? acc + countDriveTimeFromDate(trip) : acc;
	}, 0);
}
