const flv = {
    file: {
        flvVersion: "1.6.2",
        dashVersion: "4.4.0",
    },
};

const hls = {
    file: {
        flvVersion: "1.6.2",
        dashVersion: "4.4.0",
        hlsVersion: "1.1.5",
        hlsOptions: {
            autoStartLoad: true,
            enableWorker: true,
        },
    },
};

export {
    hls,
    flv,
};
