import React from 'react';

import { Toggler } from '../../elements';
import Devices from '../devices/device-list';

import { withSentry } from '../../../shared/hoc/withSentry';
import { PeopleList } from './PeopleList';
import {localStorage} from "../../../shared/storage";

enum DashboardTabs {
  DEVICES,
  PEOPLE,
}

function DevicePeopleToggle() {
  const [tabIdx, setTabIdx] = React.useState<DashboardTabs>(
    DashboardTabs.DEVICES
  );

  const loginToken = localStorage.get('login-init-be-token');

    return (
      <>
        {loginToken['do-anything'] ? (
          <>
            <div style={{ paddingBottom: 10 }}>
              <Toggler onToggle={(tab) => setTabIdx(tab)}>
                <span>
                  {tabIdx === DashboardTabs.DEVICES ? <b>Devices</b> : 'Devices'}
                </span>
                <span>
                  {tabIdx === DashboardTabs.PEOPLE ? <b>People</b> : 'People'}
                </span>
              </Toggler>
            </div>
            {tabIdx === DashboardTabs.DEVICES ? <Devices /> : <PeopleList />}
          </>
          ) : (
              <>
                <b>Devices</b>
                <Devices />
              </>
          )}
      </>
    );
}

export default withSentry(DevicePeopleToggle);
