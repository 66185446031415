import React from 'react';
import { MAP } from 'react-google-maps/lib/constants';
import { equals } from 'ramda';

import { iLocation } from '../../../shared/interfaces';
import { IStreetViewProps } from './types';


class StreetView extends React.Component<IStreetViewProps> {
    private static readonly GMAP_WRAPPER_ID = 'gmap-wrapper';
    private static readonly PANORAMA_CONTAINER_ID = 'pano';

    private static sv = new google.maps.StreetViewService();
    private readonly panorama: google.maps.StreetViewPanorama;

    constructor (props) {
        super(props);

        this.panorama = new google.maps.StreetViewPanorama(
            this.getOrCreatePanoramaWrapper(), {
                visible: true,
                position: this.props.center ?? this.props.defaultCenter,
                zoom: 0,
                addressControl: false,
                fullscreenControl: false,
                motionTrackingControl: false,
                zoomControl: false,
                enableCloseButton: false,
            });

        this.props.mapRef.current!.context[MAP].setStreetView(this.panorama);
    }

    componentDidMount () {
        const {defaultCenter, center} = this.props;

        this.setPanorama(center ?? defaultCenter);
    }

    shouldComponentUpdate (nextProps: Readonly<IStreetViewProps>): boolean {
        return !equals(nextProps.center, this.props.center);
    }

    componentDidUpdate (prevProps: Readonly<IStreetViewProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (!equals(prevProps.center, this.props.center)) {
            this.setPanorama(this.props.center ?? this.props.defaultCenter);
        }
    }

    componentWillUnmount (): void {
        (this.props.mapRef.current!.context[MAP] as google.maps.Map).getStreetView().setVisible(false);

        document.getElementById(StreetView.PANORAMA_CONTAINER_ID)!.style.flex = '0';
    }

    private getOrCreatePanoramaWrapper = () => {
        const mount = document.getElementById(StreetView.GMAP_WRAPPER_ID)!;
        const mounted = !!document.getElementById(StreetView.PANORAMA_CONTAINER_ID);
        const panoramaContainer = document.getElementById(StreetView.PANORAMA_CONTAINER_ID) ?? document.createElement('div');

        panoramaContainer.style.flex = '1';
        if (!mounted) {
            panoramaContainer.id = StreetView.PANORAMA_CONTAINER_ID;
            mount.prepend(panoramaContainer);
        }

        return panoramaContainer;
    };

    private setPanorama = (center: iLocation) => {
        StreetView.sv.getPanorama({location: center, radius: 1000}, (data, status) => {
            if (status !== 'OK' || !data?.location?.pano) return;

            this.panorama.setPano(data!.location!.pano!);
        });
    };

    render () {
        return null;
    }
}

export default StreetView;
