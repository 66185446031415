import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';

import { MinimalTile } from '../../general';
import instance from '../../../api/instance';
import {localStorage} from "../../../shared/storage";

import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import { InputTextField } from './components/InputTextField';

import {
  ISubUser,
  ServerErrorTypes,
  ServerErrors,
  userFieldNames,
} from './AddSubUserPage.types';
import { getFieldOptions, validationSchema } from './AddSubUserPage.utils';

import './AddSubUserPage.styles.scss';
import { history } from '../../../stores/store';
import {UserCan} from "../../../shared/constants";

type MapObjectType = { [key: string]: string };

const AddSubUserPage: FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [user, setUser] = useState<ISubUser>({} as ISubUser);
  const [countriesList, setCountriesList] = useState<MapObjectType>({});
  const [statesList, setStatesList] = useState<MapObjectType>({});

  const [country, setCountry] = useState<string>();
  const [state, setState] = useState<string>();
  const loginToken = localStorage.get('login-init-be-token');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/site/app-get-country`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        const data = await response.json();
        const countries = {};
        const states = {};
        Object.keys(data.countries).forEach((item) => {
          countries[item] = data.countries[item];
        });
        Object.keys(data.state).forEach((item) => {
          if (Object.keys(data.state[item]).length !== 0) {
            states[item] = data.state[item];
          }
        });
        setCountriesList(countries);
        setStatesList(states);

        const defaultCountry = Object.keys(countries)[0];
        const defaultState = Object.keys(states[countries[defaultCountry]])[0];

        setCountry(defaultCountry);
        setState(defaultState);
      })
      .catch((error) => console.log(error));
  }, []);

  const { register, handleSubmit, setError, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleServerError = (errors: ServerErrors) => {
    Object.keys(errors ?? {}).forEach((error) => {
      switch (error) {
        case ServerErrorTypes.billing_address_zip:
          setError(userFieldNames.postalCode, {
            type: 'validate',
            message: errors[error][0],
          });
          break;
        case ServerErrorTypes.email:
          setError(userFieldNames.email, {
            type: 'validate',
            message: errors[error][0],
          })
        break;
        case ServerErrorTypes.username:
          setError(userFieldNames.desiredUserName, {
            type: 'validate',
            message: errors[error][0],
          })
          break;
      }
    });

    setLoading(false);
  };

  const onFormSubmit = (data: { [x: string]: any }) => {
    setLoading(true);

    const isAdmin = loginToken[UserCan.DO_ANYTHING];
    const isSubAdmin = loginToken[UserCan.SUB_ADMIN];
    const createSubAdmin = data[userFieldNames.isSubAdmin];

    const requestBody = {
      username: data[userFieldNames.desiredUserName],
      email: data[userFieldNames.email],
      password: data[userFieldNames.password],
      password2: data[userFieldNames.password_repeat],
      first_name: data[userFieldNames.firstName],
      last_name: data[userFieldNames.lastName],
      phone: data[userFieldNames.phoneNumber],
      billing_address_line1: data[userFieldNames.address1],
      billing_address_line2: data[userFieldNames.address2],
      billing_address_country: country,
      billing_address_city: data[userFieldNames.city],
      billing_address_state: state ?? '',
      billing_address_zip: data[userFieldNames.postalCode],
    };

    let urlQuery = '';
    if(isAdmin && createSubAdmin){
      urlQuery = '?isSubAdmin=true';
    }
    else if(isSubAdmin){
      urlQuery = '?isSubUser=true';
    }

    instance
      .post(
        `${process.env.REACT_APP_API_URL}/api/user/create-sub-user${urlQuery}`,
        requestBody
      )
      .then((response) => {
        if (response?.status === 200) {
          history.push('/person');
          window.location.reload();
        }

        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 400) {
          console.log(error);
        }

        handleServerError(error?.response?.data?.errors);
      });
  };

  const onCountySelect = (e: any) => {
    const newCountry = Object.keys(countriesList)[e.target.value];

    setCountry(newCountry);
    setState(Object.keys(statesList[countriesList[newCountry]] ?? {})[0]);
  };

  const onStateSelect = (e: any) => {
    setState(Object.keys(statesList[countriesList[country]])[e.target.value]);
  };

  const fieldRenderer = (fieldName: userFieldNames): JSX.Element => {
    const { name, type } = getFieldOptions(fieldName);
    switch (fieldName) {
      case userFieldNames.country:
        return (
          <MinimalTile style={{ marginBottom: 10 }} key={fieldName}>
            <FormControl
              fullWidth
              disabled={!Object.keys(countriesList).length}
            >
              <InputLabel id='select-label'>Country</InputLabel>
              <Select
                labelId='select-label'
                id='simple-select'
                label='Country'
                onChange={onCountySelect}
                defaultValue={0}
              >
                {Object.keys(countriesList).map((key: string, idx: number) => (
                  <MenuItem value={idx} key={key}>
                    {key} | {countriesList[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MinimalTile>
        );
      case userFieldNames.state:
        const states = statesList[countriesList[country]];
        return (
          <MinimalTile style={{ marginBottom: 10 }} key={fieldName}>
            <FormControl fullWidth disabled={!states}>
              <InputLabel id='select-label'>State</InputLabel>
              <Select
                labelId='select-label'
                id='simple-select'
                label='State'
                onChange={onStateSelect}
                defaultValue={0}
              >
                {Object.keys(states ?? {}).map((key: string, idx: number) => (
                  <MenuItem value={idx} key={key}>
                    {key} | {states[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MinimalTile>
        );
      default:
        return (
          <InputTextField
            key={fieldName}
            fieldName={fieldName}
            title={name}
            type={type}
            value={user[fieldName]}
            register={register}
            errorMsg={
              errors[fieldName]
                ? errors[fieldName]?.message || 'Validation error'
                : ''
            }
            styles={{ marginBottom: 10 }}
          />
        );
    }
  };

  return (
    <DashboardBlock>
      <ModalHeader title='Add sub user' />

      <div className='form__wrapper'>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          {Object.keys(userFieldNames).map((fieldName) => {
            if (loginToken[UserCan.SUB_ADMIN] && fieldName === userFieldNames.isSubAdmin){
              return null;
            }
            return fieldRenderer(fieldName as userFieldNames);
          })}

          <MinimalTile>
            <div className='button__wrapper'>
              <button className='submit-button' disabled={loading}>
                {loading ? 'Loading...' : 'CREATE'}
              </button>
            </div>
          </MinimalTile>
        </form>
      </div>
    </DashboardBlock>
  );
};

export default AddSubUserPage;
