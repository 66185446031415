import { object, string } from 'yup';

const errorsTexts = {
    required: "This field is required",
    invalid: "Enter valid email or phone number",
    email: "Enter valid email",
    phone: "Enter valid phone number"
}

const phoneRegex = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

// using JSON.stringify and then JSON.parse because "matches" error message may be only string

const customFieldValidation = object().shape({
    email: string()
        .required(JSON.stringify({custom: errorsTexts.invalid}))
        .email(JSON.stringify({custom: errorsTexts.email})),
    phone: string()
        .required(JSON.stringify({custom: errorsTexts.invalid}))
        .matches(phoneRegex, JSON.stringify({custom: errorsTexts.phone})),
});

const validateCustomField = (fieldPath, value) => {
    let isValid = false;
    const errors = {};
    try {
        customFieldValidation.validateSyncAt(fieldPath, value);
        isValid = true;
    } catch (error) {
        error.errors?.forEach((itm) => {
            const [[key, value]] = [...Object.entries(JSON.parse(itm))];
            errors[key] = value;
        });
    }
    return {isValid, errors};
};

export default validateCustomField;
