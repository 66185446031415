import React, { useRef } from 'react';
import { formatPrice, isMobile } from '../../../../../../../shared/helpers';
import useClickOutside from '../../../../../../../hooks/useClickOutside';

const CustomTick = (props) => {
  const {
    fill, height, /*index,*/ orientation,
    payload: {/*coordinate, isShow, offset, tickCoord,*/ value},
    stroke, textAnchor, /*tickFormatter, length,*/
    /*name,*/ type, /*verticalAnchor, visibleTicksCount,*/ width, x, y,
    setCustomTickInfo,
    isActive,
  } = props;

  const content = formatPrice(value);

  const formatting = (price) => {
    if (!price) return price;
    return price.length <= 4 ? price : price.slice(0, 4) + "..";
  }

  const onTickMouseOver = () => {
    // console.log("over");
    const newActive = content.length > 4;
    if (newActive === isActive && !isMobile) return;
    setCustomTickInfo({
      isActive: newActive,
      top: y + (y < height / 2 ? 10 : -35),
      left: x/2,
      content
    });
  };

  const onTickMouseLeave = () => {
    // console.log("leave")
    setCustomTickInfo({
      isActive: false,
      top: 0,
      left: 0,
      content: null
    });
  };

  const textRef = useRef(null);
  const outsideTextClick = () => {
    isActive && onTickMouseLeave()
  }
  useClickOutside({targetRef: textRef, callback: outsideTextClick })

  return <text ref={textRef}
               onMouseOver={onTickMouseOver}
               onClick={onTickMouseOver}
               onMouseLeave={onTickMouseLeave}
               orientation={orientation}
               width={width}
               height={height}
               type={type}
               x={x}
               y={y}
               stroke={stroke}
               fill={fill}
               className="recharts-text recharts-cartesian-axis-tick-value"
               textAnchor={textAnchor}>
    <tspan x={x} dy="0.355em">{formatting(content)}</tspan>
  </text>
};


export default CustomTick;