import { iPerson } from '../../../../shared/interfaces';
import C from '../../../../shared/constants';

export interface ITileProps {
  person: iPerson;
  style?: React.CSSProperties;
}

export const tileCss: React.CSSProperties = {
  margin: 5,
  textDecoration: 'none',
  color: C.primaryText,
  border: `1px solid ${C.mediumGray}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 10,
  borderRadius: 3,
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  width: 125,
  minHeight: 200,
};
