import React from 'react';
import { connect } from 'react-redux';
import { values, pipe, pathOr, uniq, join, filter } from 'ramda';
import { faSpinner } from '@fortawesome/fontawesome-free-solid';

import { BaseComponent } from '../../../../shared/BaseComponent';
import DashboardBlock from '../../../DashboardBlock';
import ModalHeader from '../../../menus/modal/modal-header';
import {
  iFullStoreState,
  ItemType,
  iDeviceDetails,
  iItemsAllowed,
} from '../../../../shared/interfaces';
import { StandardItem } from '../../../general';
import { TableSimple } from '../../../elements/plain-table';
import {
  setAllowedSee,
  removeAllowedSee,
  watchPersonAllowedSee,
  readOnlyAccessToggle,
} from '../../../../shared/db/general-db';
import { Fa } from '../../../elements/fa';
import { Row } from '../../../elements/flex';
import Checkbox from '../../../elements/Checkbox';
import SearchGridOld from '../../../SearchGridOld/SearchGridOld';
import instance from '../../../../api/instance';
import {
  IPropsFromStore,
  IFullProps,
  StateInterface,
  itemStyle,
} from './PersonCanSee.types';
import styles from '../PeoplePage.module.scss';
import canSeeStyles from '../PersonCanSee/PersonCanSee.module.scss';
import {dev} from "../../../../shared/helpers";

export default connect(
  (s: iFullStoreState, o: any): IPropsFromStore => ({
    person: s.general.people[o.match.params.id],
    tags: s.general.tags,
    devicesDetails: s.devicesData.devicesDetails,
    authUser: s.auth.user!,
  })
)(
  class PersonCanSee extends BaseComponent<IFullProps, StateInterface> {
    private canceller;
    constructor(props) {
      super(props);
      this.state = {
        tagFilter: '',
        deviceFilter: '',
        readOnlyAll: false,
        readOnlyAccess: {},
        canEditDevicesAndFences: false,
      };
    }

    _populateState = (allowed: iItemsAllowed) => {
      this.setState({ allowed });
    };

    async componentDidMount() {
      const { person } = this.fullProps;
      this.canceller = watchPersonAllowedSee(person.id, (allowed) =>
        this.setState({ allowed: allowed || {} }, () => {
          const devicesAccess = allowed.device
            ? Object.keys(allowed.device).reduce((acc, rec) => {
                return { ...acc, [rec]: allowed.device[rec].readOnly || false };
              }, {})
            : {};

          this.setState({ readOnlyAccess: { ...devicesAccess } });
        })
      );
    }

    componentWillUnmount() {
      if (this.canceller) this.canceller();
    }

    _toggleTagFlag = async (tagId) => {
      const { tags, person } = this.fullProps;

      const isRemove = pathOr(false, [
        tagId,
        'instances',
        'allowed-see',
        'person',
        person.id,
      ])(tags);

      await (isRemove ? removeAllowedSee : setAllowedSee)(
        this.fullProps.authUser
      )(this.fullProps.person.id, ItemType.tag, tagId);
      instance.post('/api-firebase/revoke', { uid: this.fullProps.person.id });
    };

    _toggleDeviceFlag = async (deviceId, tagId = null) => {
      const { allowed } = this.state;

      const isRemove = pathOr(false, ['device', deviceId, deviceId])(allowed);

      await (isRemove ? removeAllowedSee : setAllowedSee)(
        this.fullProps.authUser
      )(this.fullProps.person.id, ItemType.device, deviceId);
      instance.post('/api-firebase/revoke', { uid: this.fullProps.person.id });
    };

    _filterchange = (key: keyof StateInterface) => (str) =>
      this.setState({ [key]: str } as any);

    static helpMsg = `
        Manage which devices a user can view. Adding a tag will allow this person to see all devices that have the associated tag.
        To prevent a user from seeing a device make sure device overrides and appropriate tags are removed. The complete list
        of seeable devices is at the very bottom.
     `;

    _source = (deviceId) =>
      pipe(
        pathOr({}, ['device', deviceId]),
        values,
        uniq,
        filter((item) => typeof item !== 'boolean'),
        join(' & ')
      )(this.state.allowed);

    debugRender = () => {
      const { person, devicesDetails, tags } = this.props;
      const {
        deviceFilter,
        allowed = false,
        readOnlyAccess,
        tagFilter,
      } = this.state;
      const devicesIds = devicesDetails.keySeq();

      const deviceItems = devicesDetails
        .map((device, deviceId) => (
          <StandardItem
            key={deviceId}
            style={itemStyle(
              pathOr(false, ['device', deviceId, deviceId])(allowed)
            )}
            displayName={device.name}
            itemId={deviceId}
          />
        ))
        .valueSeq()
        .toArray();

      const tagItems = values(tags).map((tag) => (
        <StandardItem
          style={itemStyle(
            pathOr(false, ['instances', 'allowed-see', 'person', person.id])(
              tag
            )
          )}
          key={tag.details.id}
          displayName={tag.details.name}
          itemId={tag.details.id}
        />
      ));

      const searchTagItems = tagItems.filter(({ props }) =>
        props.displayName?.toLowerCase().includes(tagFilter.toLowerCase())
      );

      const searchDeviceItems = deviceItems.filter(({ props }) =>
        props.displayName?.toLowerCase().includes(deviceFilter.toLowerCase())
      );

        const handleSelectAll = () => {
            // @ts-ignore
            const visibleDeviceIds = Object.keys(allowed?.device || {})
                .filter((deviceId) => devicesIds.includes(deviceId))
                .map((deviceId) => devicesDetails.get(deviceId)!);
            const devicesWithNewStatus = visibleDeviceIds
                .map((device: iDeviceDetails) => ({ [device.id]: !readOnlyAccess?.[device.id] }))
                .reduce((result, obj) => {
                    return { ...result, ...obj };
                }, {});
            this.setState({
                readOnlyAccess: {
                    ...readOnlyAccess,
                    ...devicesWithNewStatus,
                }
            });
            visibleDeviceIds.map(device => {
                readOnlyAccessToggle(this.fullProps.authUser)(
                    this.fullProps.person.id,
                    device.id,
                    !readOnlyAccess?.[device.id]
                )
            });
            return;
        };

        const AllBtn = ({ onAllClick }) => (
            <button
                onClick={onAllClick}
                className={canSeeStyles.buttonStyles}
            >
                Select All
            </button>
        );

      return (
        <DashboardBlock>
          <ModalHeader
            title={person ? person.displayName : ''}
            help={PersonCanSee.helpMsg}
          />

          {!allowed ? (
            <Row justify='center'>
              <Fa icon={faSpinner} spin style={{ fontSize: 28 }} />
            </Row>
          ) : (
            <>
              <SearchGridOld
                style={{ marginTop: 10, maxWidth: 350 }}
                textual={true}
                list={tagFilter ? searchTagItems : tagItems}
                filterStr={tagFilter}
                placeholder='Search Tags...'
                perPage={9}
                keyClicked={this._toggleTagFlag}
                filterChange={this._filterchange('tagFilter')}
              />
              <SearchGridOld
                style={{ marginTop: 10, maxWidth: 350 }}
                textual={true}
                list={deviceFilter ? searchDeviceItems : deviceItems}
                filterStr={deviceFilter}
                placeholder='Search Devices...'
                perPage={9}
                keyClicked={this._toggleDeviceFlag}
                filterChange={this._filterchange('deviceFilter')}
              />

              {!!allowed.device && (
                <>
                  <div className={canSeeStyles.headerBlock}>
                    <p className={canSeeStyles.title}>Visible list</p>
                    <AllBtn onAllClick={handleSelectAll} />
                  </div>
                  <TableSimple
                    sm
                    dark
                    striped
                    headers={['Label', 'Source', 'Access']}
                  >
                    {Object.keys(allowed.device || {})
                      .filter((deviceId) => devicesIds.includes(deviceId))
                      .map((deviceId) => devicesDetails.get(deviceId)!)
                      .map((device: iDeviceDetails) => (
                        <tr key={device.id}>
                          <td
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: 170,
                            }}
                          >
                            {device.name}
                          </td>
                          <td>{this._source(device.id)}</td>
                          <td>
                            <Checkbox
                              styles={{ marginTop: 6 }}
                              labelStyles={{ fontSize: 16, color: 'white' }}
                              inputId={device.id}
                              onChange={() =>
                                readOnlyAccessToggle(this.fullProps.authUser)(
                                  this.fullProps.person.id,
                                  device.id,
                                  !readOnlyAccess?.[device.id]
                                )
                              }
                              checked={readOnlyAccess?.[device.id]}
                              label='Read only'
                            />
                          </td>
                        </tr>
                      ))}
                  </TableSimple>
                </>
              )}
            </>
          )}
        </DashboardBlock>
      );
    };
  }
);
