import { select, takeEvery } from 'redux-saga/effects';

import { Actions as GmapActions, recenterMap } from '../reducers/gmap-reducers';
import { iFullStoreState } from '../../shared/interfaces';


function* worker (action: ReturnType<typeof GmapActions.RECENTER_MAP>) {
    const {center, recenterIfOutOfBounds} = action.payload;
    const mapRef = yield select((state: iFullStoreState) => state.gmap.mapRef());

    recenterMap(mapRef, center, recenterIfOutOfBounds);
}

export default function* () {
    yield takeEvery('RECENTER_MAP', worker);
}
