export enum GMapModalContent {
    REPORT_PAGE_FILTERS,
    REPORT_PAGE_FILTERS_DATEPICKER,
    EXPORT_REPORT,
    SELECT_SEND_TO,
	AVERAGE_VALUES,
	GENERATE_REPORT,
    NEW_MAINTENANCE,
    MAINTENANCE_COMPLETE_TASK,
    REPORT_PAGE_TASK_DETAILS,
    EDIT_MAINTENANCE_TASK,
    COMPLETE_EXTRA_TASK,
    EMPTY
}

export const ACTIONS = {
    SHOW_GOOGLE_MAP_MODAL: "G_MAP_MODAL/SHOW_GOOGLE_MAP_MODAL",
    HIDE_GOOGLE_MAP_MODAL: "G_MAP_MODAL/HIDE_GOOGLE_MAP_MODAL",
    SET_PARAMS: "G_MAP_MODAL/SET_PARAMS",
};

type ShowModalParams = {
    contentType: GMapModalContent,
    params?: object
}

export class GMapModalAC {
    static showModal = ({contentType, params = {}}: ShowModalParams) => ({
        type: ACTIONS.SHOW_GOOGLE_MAP_MODAL,
        payload: {contentType, params}

    })
    static hideModal = () => ({type: ACTIONS.HIDE_GOOGLE_MAP_MODAL})
    static setParams = ({params}) => ({type: ACTIONS.SET_PARAMS, payload: {params}})
}