import React from "react";
import Helmet from 'react-helmet';
import {CustomizerStyleForYearPickerProps} from "./CustomizerStyleForYearPicker.interfaces";

const CustomizerStyleForYearPicker = ({activeItemNumber, baseContainerWidth}: CustomizerStyleForYearPickerProps) => {
    const getVw = (px: number) => {
        return `${px * 100 / baseContainerWidth}vw`;
    };

    return <Helmet>
        <style>{`
            .picker-item:nth-child(${activeItemNumber - 1}),
            .picker-item:nth-child(${activeItemNumber + 1}) {
                font-size:  ${getVw(14)} !important;
                opacity: 0.5;
            }
            
            .picker-item:nth-child(${activeItemNumber - 2}),
            .picker-item:nth-child(${activeItemNumber + 2}) {
                font-size: ${getVw(11)} !important;
                opacity: 0.3;
            }
            
            .picker-item:nth-child(${activeItemNumber - 3}),
            .picker-item:nth-child(${activeItemNumber + 3}) {
                font-size: ${getVw(9)} !important;
                opacity: 0.2;
            }
        `}</style>

    </Helmet>;
};

export default CustomizerStyleForYearPicker;