import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom"
import "./index.scss";
import {
    Actions as ReportActions,
  } from '../../../stores/reducers/report-reducers';

export const ClipedNavbar = ({children}) => {
    return (
        <div className="cliped-navbar">
            {children}
        </div>
    )
}

export const ClipedNav = ({ children, to }) => {
    return (
        <NavLink
            activeClassName='active'
            className={`cliped-nav-wraper`}
            to={to}>
            <div className="cliped-nav">
                {children}
            </div>
        </NavLink>
    )
}
