import React, { useCallback, useRef, useState } from "react";
import instance from "../../../../api/instance";

export const billigTablesApiHOC = (Component) => ({...props}) => {

  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ limit, page, url }) => {
    try {
      setLoading(true)
      const response = await instance.get(
        `${url}?size=${limit}&page=${page}`
      );
      console.log('units', response.data.records);
      setData(response.data.records);
      setPageCount(Math.ceil(response.data.total / limit));
    } catch (e) {
      console.log("Error while fetching", e);
    } finally {
      setLoading(false)
    }
  };

  const fetchData = useCallback(({ pageSize, pageIndex, url }) => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        limit: pageSize,
        page: ++pageIndex,
        url
      });
    }
  }, []);

  return (
    <Component
      data={data}
      pageCount={pageCount}
      fetchData={fetchData}
      loading={loading}
    />
  );
}
