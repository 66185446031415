import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    path,
    pipe,
} from "ramda";
import {
    iFullStoreState,
    Units,
    AlertTypes as AlertsType,
    iDeviceDetails,
  } from "../../../../shared/interfaces";

import { ReportTripRow } from "./table-wrapper";

const RenderTripReportData = ({ trip, showTrip, deviceTimezone, deviceUnits, showAllRoutes, deviceId }:any) => {

  const device = useSelector<iFullStoreState, iDeviceDetails | undefined>(state => state.devicesData.devicesDetails.get(trip.current.device));

      return (
        <>
            <table
              className={
                "report-table new-table report-table-fix report-table-travel"
              }
            >
              <tbody className="report-table-travel-body">
                <ReportTripRow
                  deviceId={deviceId}
                  units={deviceUnits}
                  timezone={deviceTimezone}
                  current={trip.current}
                  showAllRoute={showAllRoutes}
                  showTrip={showTrip}
                  device={device}
                />
              </tbody>
            </table>
        </>
      );
    } ;

export default RenderTripReportData;