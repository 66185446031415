const validateForm = ({ formData, scheme }) => {
  let isValid = false;
  const errors = {};
  try {
    scheme.validateSync(formData, {abortEarly: false});
    isValid = true;
  }  catch (error) {
    error.errors?.forEach((itm) => {
      const [[key, value]] = [...Object.entries(itm)];
      errors[key] = value;
    });
  }
  return {isValid, errors};
};

const validateSingleField = ({ fieldPath, value, scheme }) => {
  let isValid = false;
  const errors = {};
  try {
    scheme.validateSyncAt(fieldPath, value);
    isValid = true;
  }  catch (error) {
    error.errors?.forEach((itm) => {
      const [[key, value]] = [...Object.entries(itm)];
      errors[key] = value;
    });
  }
  return {isValid, errors};
}

export {validateForm, validateSingleField};