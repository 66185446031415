import React from "react";
import {compose} from "@bem-react/core";

import {Button as BEMButton} from '../../../../../BemComponents/NewButton/Button';
import {withButtonVariantContained} from "../../../../../BemComponents/NewButton/_variant/Button_variant_contained";
import {withButtonColorMainPrimary} from "../../../../../BemComponents/NewButton/_color/Button_color_main-primary";
import {TimeLineDialogFooterProps} from "./TimeLineDialogFooter.interfaces";

const Button = compose(
    withButtonVariantContained,
    withButtonColorMainPrimary,
)(BEMButton);

const TimelineDialogFooter = (props: TimeLineDialogFooterProps) => {
    /*const tempRange = useSelector<iFullStoreState, { startDate: moment.Moment; endDate: moment.Moment } | undefined>(
        (s) => s.timeline.tempRange,
    );
    const range = useSelector<iFullStoreState, { startDate: moment.Moment; endDate: moment.Moment } | undefined>(
        (s) => s.timeline.range,
    );*/

    const canApplyRange = !!props.tempRange;
    /*(tempRange && !range) || (tempRange && (!tempRange.startDate.isSame(range?.startDate) || !tempRange.endDate.isSame(range?.endDate)))*/

    return <div className="TimelineDialog-Footer">
        <Button disabled={!canApplyRange}
                variant="contained"
                onClick={props.clearRangeCallback}>
            CLEAR
        </Button>
        <Button onClick={props.cancelButtonCallback} className="TimeLineDialogFooter-Button">
            CANCEL
        </Button>
        <Button disabled={!canApplyRange}
                variant="contained"
                color="main-primary"
                onClick={props.updateRangeCallback}>
            UPDATE
        </Button>
    </div>;
};

export default TimelineDialogFooter;