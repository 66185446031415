import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {equals} from 'ramda';

import * as fenceDb from '../../../../../shared/db/fences-db';
import {Actions as MapActions, Actions as GmapActions, defaultBounds} from '../../../../../stores/reducers/gmap-reducers';
import {Actions} from '../../../../../stores/reducers/map-region-reducers';
import {Actions as PathActions} from '../../../../../stores/reducers/map-path-reducers';
import Dialog, {DialogConfigSetter} from '../../../../Dialog';

import {withSentry} from '../../../../../shared/hoc/withSentry';

import TuneIcon from '../../../../SVG-components/tune';
import GpsIcon from '../../../../SVG-components/gps_fixed';
import Checkbox from '../../../../elements/Checkbox';

import C, { ACL, UserCan } from '../../../../../shared/constants';
import { iFullStoreState, ItemType, UserAuth,} from '../../../../../shared/interfaces';
import {IFenceLineProps} from '../FenceBody.interfaces';
import { useIsReadOnly } from '../../../../../hooks/useIsReadOnly';
import styles from './FenceLine.module.scss';

const FenceLine = withSentry(function FenceItem (props: IFenceLineProps) {
    const {fence, itemId, itemType, isVis, toggler, fenceGroup} = props;
    const dispatch = useDispatch();

    const tagName = useSelector<iFullStoreState, string | undefined>(
        (s) => fence.itemType !== ItemType.tag ? undefined : s.general.tags?.[fence.itemId]?.details?.name || 'ALL DEVICES',
    );
    const authUser = useSelector<iFullStoreState, UserAuth>((s) => s.auth.user, (l, r) => equals(l, r));

    const isReadOnly = useIsReadOnly(itemId, itemType as ItemType);
    const hasPermissionToEditFences = ACL.check(UserCan.EDIT_FENCES, authUser.acl.can);
    const canEditFence = !isReadOnly && hasPermissionToEditFences;

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

    const deleteFence = () => {
        const dialog = dialogRef.current;
        console.log('DELETE_FENCE', fence.id, itemType, itemId);
        dialog?.().then((confirmed) => {
            if (!confirmed) return;

            dispatch(Actions.REMOVE_REGION(fence.id));
            dispatch(PathActions.REMOVE_PATH(fence.id));

            fenceDb.deleteFence(authUser)(fence.id, itemType, itemId);
        });
    };

    const moveToFence = () => {
        dispatch(MapActions.SET_BOUNDS(defaultBounds));

        // if (isVis)
        dispatch(GmapActions.RECENTER_MAP(fence.region.center));
    };

    return <>
        <div className="FencesBox" title="Navigate to the tag to edit this fence">
            <Checkbox inputId={fence.id} checked={isVis} onChange={ () => toggler(fence.id, !isVis)} />

            <span className={styles.fenceName} title={fence.name}>{fence.name}</span>

            {/* Edit / delete links -------------------*/}
            <div className={`${fence.itemType == itemType || 'hidden'} ${styles.fenceLinks}`}>
                <GpsIcon className={styles.fenceGpsIcon} onClick={() => moveToFence()} />
                {canEditFence && <Link to={`/${itemType}/${itemId}/${fenceGroup}/${fence.id}/edit`} ><TuneIcon className={styles.FenceTuneIcon} /></Link>}

            </div>

            {/* Tag name */}
            <div className={`${fence.itemType != itemType || 'hidden'} ${styles.fenceTagName}`} title="Navigate to the tag to edit this fence">
                {tagName ?? ''}
                <GpsIcon className={styles.fenceGpsIcon} onClick={() => moveToFence()} />
            </div>
        </div>
        <Dialog setupConfig={setupDialog} title="Confirm Location Request">
            Are you sure you want to delete? This action cannot be undone.
        </Dialog>
    </>;
});

export default FenceLine;