import React from "react";
import {LoadDocumentType} from "../../../../../../../shared/interfaces";
import {ReactComponent as DownloadFile} from "../../../../../../../assets/svg/download_file.svg";
import {ReactComponent as UploadFile} from "../../../../../../../assets/svg/upload_file.svg";
import {ReactComponent as FileIcon} from "../../../../../../../assets/svg/file_icon.svg";

// import style from "./InitFileIcon.module.scss";

const InitFileIcon = (props) => {
  const {loadType, ...domProps} = props;
  // console.log(...domProps);
  if (loadType === LoadDocumentType.DOWNLOAD) {
    return <DownloadFile {...domProps}/>
  }
  if (loadType === LoadDocumentType.UPLOAD) {
    return <UploadFile {...domProps}/>
  }
  else {
    return <FileIcon {...domProps}/>
  }
};

export default InitFileIcon;