import React from 'react';
import { pipe } from 'ramda';
import { BaseComponent } from '../../../../../shared/BaseComponent';
import { kilos, knots, targetVal } from '../../../../../shared/helpers';
import { iDeviceEvent } from '../../../../../shared/interfaces';
import { bounded } from '../../../../../shared/ramda';
import Knob from '../../../../elements/Knob';
import { Col, Row } from '../../../../elements/flex';

import './SettingsPage.styles.scss';

export class SettingsPage extends BaseComponent<
  { event: iDeviceEvent; onChange; initialValue? },
  { value }
> {
  state = {
    value: this.props.initialValue || this.props.event.min || 0,
  };

  __change = pipe(
    bounded(
      this.props.event.min || 0,
      this.props.event.max || Number.MAX_SAFE_INTEGER
    ),
    (value) => {
      this.setState({ value });
      this.props.onChange(value);
    }
  );

  __changeInVoltage = pipe(bounded(0, 40), (value) => {
    if (value <= 40) {
      this.setState({ value });
      this.props.onChange(value);
    } else {
      value = 40;
      this.setState({ value });
      this.props.onChange(value);
    }
  });

  debugRender = () => {
    const { event } = this.props;
    const { value } = this.state;

    if (event.type == 'speed') {
      return (
        <div className='settings__speed'>
          <div className='settings__speed-title'>Max Speed</div>
          <div className='settings__speed-knob'>
            <Knob
              value={value}
              onChange={this.__change}
              min={event.min || 0}
              max={event.max || 100}
              angleOffset={-125}
              angleArc={250}
              fgColor='#b20000'
              inputColor='#b20000'
              step={5}
              thickness={0.1}
            />
          </div>
          <div className='settings__speed-content'>
            <div className='settings__speed-content__mph'>
              <div>{value}</div>
              <div>mph</div>
            </div>
            <div className='settings__speed-content__slash'>/</div>
            <div className='settings__speed-content__kph'>
              <div>{kilos(value)}</div>
              <div>kph</div>
            </div>
            <div className='settings__speed-content__slash'>/</div>
            <div className='settings__speed-content__knots'>
              <div>{knots(value)}</div>
              <div>knots</div>
            </div>
          </div>
        </div>
      );
    }

    if (event.name === 'Voltage Low') {
      return (
        <Col>
          <span>{event.name}</span>
          <span style={{ fontSize: 'smaller' }}>{event.description}</span>
          <input
            className='input-range'
            type='number'
            min='0'
            max='40'
            value={value}
            onChange={pipe(targetVal, this.__changeInVoltage)}
          />
          <Row justify='space-between' style={{ fontSize: 'smaller' }}>
            <span>{0}</span>
            <span>{40}</span>
          </Row>
          {/* Range supports ints so multiple by precision for selection and divide on setting value */}
          <input
            type='range'
            min={0}
            max={40}
            step={1}
            value={value}
            className='fancy-slider'
            style={{ width: '100%' }}
            onChange={pipe(targetVal, this.__changeInVoltage)}
          />
          <span>
            value: {value} {event.unitString || ''}
          </span>
          {!event.src ? null : (
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <img src={event.src} />
            </div>
          )}
        </Col>
      );
    }

    if (event.type == 'range') {
      return (
        <Col>
          <span>{event.name}</span>
          <span style={{ fontSize: 'smaller' }}>{event.description}</span>
          <Row justify='space-between' style={{ fontSize: 'smaller' }}>
            <span>{event.min || 0}</span>
            <span>{event.max || 100}</span>
          </Row>
          {/* Range supports ints so multiple by precision for selection and divide on setting value */}
          <input
            type='range'
            min={event.min}
            max={event.max}
            step={event.step || 1}
            value={value}
            className='fancy-slider'
            style={{ width: '100%' }}
            onChange={pipe(targetVal, this.__change)}
          />
          <span>
            value: {value} {event.unitString || ''}
          </span>
          {!event.src ? null : (
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <img src={event.src} />
            </div>
          )}
        </Col>
      );
    }

    return (
      <div className='settings__no-content'>No settings for this type</div>
    );
  };
}
