import React from "react";
import {enumActionButton} from "../../../../../../shared/interfaces";
import {ReactComponent as DeleteIcon} from "../../../../../../assets/svg/delete.svg";
import {ReactComponent as CancelIcon} from "../../../../../../assets/svg/close_icon.svg";
import "./actionButtonSVG.scss";

const ActionButtonSVG = (props) => {
  const {actionButtonType, ...domProps} = props;
  // console.log(domProps);
  return (actionButtonType === enumActionButton.DELETE)
    ? <DeleteIcon { ...domProps } className="document-file-action-svg-delete" />
    : <CancelIcon { ...domProps } className="document-file-action-svg-cancel" />
}

export default ActionButtonSVG;