import { object, number, date } from 'yup';
import { createBiggerThanMsg, createCorrectMsg, createNumberMsg, createPositiveMsg, createRequiredMsg } from '../../../../../shared/error-messages-constants';
import { validateForm, validateSingleField } from '../../validate-helpers';

const CompleteTaskFormValidation = object().shape({
    date: date()
        .typeError({date: createCorrectMsg("Date")})
        .required({date: createRequiredMsg("Date")}),
    odometer: number()
        .typeError({odometer: createNumberMsg("Odometer")})
        .required({odometer: createRequiredMsg("Odometer")})
        .min(0, {odometer: createBiggerThanMsg("Odometer", 0)}),
    labourHours: number()
        .typeError({labourHours: createNumberMsg("Labour hours")})
        .required({labourHours: createRequiredMsg("Labour hours")})
        .min(0, {labourHours: createBiggerThanMsg("Labour hours", 0)}),
    cost: number()
        .typeError({cost: createNumberMsg("Cost")})
        .required({cost: createRequiredMsg("Cost")})
        .positive({cost: createPositiveMsg("Cost")}),
});

const validateCompleteTaskForm = (formData) => validateForm({ formData, scheme: CompleteTaskFormValidation });

const validateSingleFieldCompleteTaskForm = (fieldPath, value) => validateSingleField({ fieldPath, value, scheme: CompleteTaskFormValidation });

export {validateCompleteTaskForm, validateSingleFieldCompleteTaskForm};
