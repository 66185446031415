import React from "react";
import {iFormattedReportPermissionData} from "../../../../shared/interfaces";

const RenderPermissionReportData = ({ displayName, devices }: iFormattedReportPermissionData) => {
    return (
      <div className="permissions-table-container">
          <h2>{displayName}</h2>
          {devices.length ? (<table>
              <tr>
                  <th>Device</th>
                  <th>Assignment Type</th>
                  <th>Permission level</th>
              </tr>
              {devices.map(device => {
                  return (
                      <tr>
                          <td>{device.name}</td>
                          <td>
                              {device.tag && <p>{`Tag: ${device.tag}`}</p>}
                              {device.device && <p>Direct Assignment</p>}
                          </td>
                          <td>{device.readOnly ? 'Read Only' : 'Full'}</td>
                      </tr>
                  )
              })}
          </table>) : <p className="no-records">No records for user</p>}
      </div>
    )
};

export default RenderPermissionReportData;