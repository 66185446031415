import React, {useRef} from "react";
import moment from "moment";
import styles from "../History.module.scss";

const HistoryItem = ({ history, chooseChannel }) => {
    const radioRef = useRef(null);
    const selectChannel = () => {
      radioRef.current.checked = true;
      chooseChannel(history);
    }

    return (
        <div className={styles.history__list__row} onClick={selectChannel}>
            <div>
              {moment(history.beginTime).format("MM-DD-YY hh:mm:ss a")}
            </div>
            <div>
              {moment(history.endTime).format("MM-DD-YY hh:mm:ss a")}
            </div>
            <div className={styles.chooseChannel}>
                <span>{history.channel}</span>
                <input
                    ref={radioRef}
                    className={styles.chooseChannel_radio}
                    type="radio"
                    name="channel"
                    onChange={() => chooseChannel(history)}
                />
            </div>
        </div>
    )
}

export default HistoryItem;
