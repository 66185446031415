import React, {useEffect, useState} from "react";

import VideoItem, {iVideo} from "./VideoItem";

import styles from "./styles.module.scss";
import {useRedux} from "../../../../states/redux-state";
import instance from "../../../../api/instance";
import Loader from "../../Billing/Other/Loader";

const VideoReport = ({totalCount, body}) => {
  const displayRecordsRaw = useRedux(s => s.report.displayRecords) || {};
  const [videos, setVideos] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const limitPerPage = 10;

  useEffect(() => {
    setVideos(Object.values(displayRecordsRaw));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    console.log('use effect page')
    window.scrollTo(0, 0);
  }, [page]);

  const fetchWithPage = async (page) => {
    const url = '/api/device/video-download-report';
    body.page = page;

    const { data } = await instance.post(url, { ...body });

    return data.data;
  }

  const handlePreviousPage = async () => {
    setIsLoading(true);
    setPage(prevPage => {
      const newPage = prevPage - 1;
      fetchWithPage(newPage).then(records => {
        setVideos(records);
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      });
      return newPage;
    });
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    setPage(prevPage => {
      const newPage = prevPage + 1;
      fetchWithPage(newPage).then(records => {
        setVideos(records);
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      });
      return newPage;
    });
  };


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        videos.length > 0 && (
          <ul className={styles.VideoReport}>
            {videos.map((video: iVideo, index, videos) => (
              <VideoItem
                key={index}
                video={video}
                showDevice={index === 0 || videos[index]?.device !== videos[index - 1]?.device}
              />
            ))}
            <div className={styles.pagination}>
              {page > 1 && <button onClick={handlePreviousPage}>Previous</button>}
              {!(page * limitPerPage >= totalCount) && <button onClick={handleNextPage}>Next</button>}
            </div>
          </ul>
        )
      )}
    </>
  );

};

export default VideoReport;
