import { BaseComponent } from '../../../../../../shared/BaseComponent';
import moment from 'moment';
import React from 'react';
import { range } from '../../../../../../shared/helpers';
import constants from '../../../../../../shared/constants';

const options = range(0, 23).map((hour) => ({
  hour,
  name: moment().hour(hour).format('hA'),
}));

export class FromTo extends BaseComponent<
  {
    from: number;
    to: number;
    change: ({ from, to }: { from: number; to: number }) => any;
  },
  any
> {
  __changeFrom = (newVal: number) =>
    this.props.change({
      from: newVal,
      to: this.props.to,
    });

  __changeTo = (newVal: number) =>
    this.props.change({
      to: newVal,
      from: this.props.from,
    });

  debugRender = () => {
    const { from, to } = this.props;

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <select
          className='mas-input'
          style={{ ...constants.selectCss, marginRight: 8 }}
          defaultValue={from as any}
          onChange={({ target: { value } }) =>
            this.__changeFrom(parseInt(value))
          }
        >
          {options.map(({ hour, name }) => (
            <option key={hour} value={hour}>
              {name}
            </option>
          ))}
        </select>
        <span>To</span>
        <select
          className='mas-input'
          style={{ ...constants.selectCss, marginLeft: 8 }}
          defaultValue={to as any}
          onChange={({ target: { value } }) => this.__changeTo(parseInt(value))}
        >
          {options.map(({ hour, name }) => (
            <option key={hour} value={hour}>
              {name}
            </option>
          ))}
        </select>
      </div>
    );
  };
}
