import {object, string, number, date, array, mixed} from 'yup';
import { createBiggerThanMsg, createCorrectMsg, createNumberMsg, createSelectAtLeastOneMsg, createSelectOneOfMsg } from '../../../../../shared/error-messages-constants';
import { validateForm, validateSingleField } from '../../validate-helpers';

interface ErrorMessage {
    required?: string;
    number?: string;
    integer?: string;
    positive?: string;
    date?: string;
    anyItem?: string;
    anyDevice?: string;
    anyMilesHoursDate?: string;
}

interface ValidationResponse {
    isValid: boolean;
    errors: ErrorMessage;
}

const NewMaintenanceTaskFormValidation = object().shape({
    tasks: array()
        .min(1, {tasks: createSelectAtLeastOneMsg("task")})
        .required()
        .of(
            object().shape({
                label: string(),
                value: string(),
            })
        ),
    miles: mixed()
        .when(['hours', 'date'], {
            is: (hours, date) => !hours && !date,
            then: number()
                .nullable(true)
                .transform((_, val) => val ? Number(val) : null)
                .typeError({ miles: createNumberMsg("Miles") })
                .required({ anyMilesHoursDate: createSelectOneOfMsg("hours, miles, date") })
                .min(0, { miles: createBiggerThanMsg("Miles", 0) }),
        }),
    milesOffset: number()
        .typeError({ milesOffset: createNumberMsg("Miles offset") })
        // .required({milesOffset: errorsTexts.required})
        .min(0, { milesOffset: createBiggerThanMsg("Miles offset", 0) })
        .nullable(true)
        .transform((_, val) => val ? Number(val) : null),
    hours: mixed()
        .when(['miles', 'date'], {
            is: (miles, date) => !miles && !date,
            then: number()
                .nullable(true)
                .transform((_, val) => val ? Number(val) : null)
                .typeError({ hours: createNumberMsg("Hours") })
                .required({ anyMilesHoursDate: createSelectOneOfMsg("miles, hours, date") })
                .min(0, { hours: createBiggerThanMsg("Hours", 0) }),
        }),
    hoursOffset: number()
        .typeError({ hoursOffset: createNumberMsg("Hours offset") })
        // .required({hoursOffset: errorsTexts.required})
        .min(0, { hoursOffset: createBiggerThanMsg("Hours offset", 0) })
        .nullable(true)
        .transform((_, val) => val ? Number(val) : null),
    date: mixed()
        // .typeError({date: errorsTexts.date})
        // .required({date: 'Date is required'})
        .when(['miles', 'hours'], {
            is: (miles, hours) => !miles && !hours,
            then: date()
                .nullable(true)
                .typeError({date: createCorrectMsg("Date")})
                .required({anyMilesHoursDate: createSelectOneOfMsg("miles, hours, date")}),
        }),
    devicesSetCount: number()
        .min(1, {devicesSet: createSelectAtLeastOneMsg("device")}).required(),
}, [
    ["miles", "hours"],
    ["miles", "date"],
    ["hours", "date"],
]);

const validateNewMaintenanceForm = (formData): ValidationResponse => validateForm({formData, scheme: NewMaintenanceTaskFormValidation});

const validateSingleFieldNewMaintenanceForm = (fieldPath, value): ValidationResponse => validateSingleField( { fieldPath, value, scheme: NewMaintenanceTaskFormValidation });

export {validateNewMaintenanceForm, validateSingleFieldNewMaintenanceForm};
