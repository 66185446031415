import firebase from 'firebase/app';

import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';

import moment from 'moment';
import { or, and, pipe, path, omit, fromPairs, ifElse, not, always, flatten, evolve, filter, identity, propOr, map, unnest, values, uniq } from 'ramda';

import { clientDb, clientStore, keyGen, firestore } from '../firebase';
import { iTrip, iList, iTag, iDevicePing, iScheduledReport, ReportType, iDevicePingWithGeofences, iCompleteMaintenanceTaskContainer } from '../interfaces';
import { ItemType, iCoordinate, UserAuth } from '../interfaces';
import { momentCmp, vals, utcOffset, idValArr, extractDevice, makeAudit, ramdaLog, isDev, countDateWithUserAndTimezoneOffset, } from '../helpers';
import { DevicesDetailsContainer } from '../../stores/reducers/devicesData';
import { QUERY_LIMIT_FOR_REPORT_GEOFENCES, QUERY_LIMIT_FOR_REPORT } from "./constants-db";
import {
    Actions as ReportActions,
} from '../../stores/reducers/report-reducers';

export enum AlertTypes {
    harsh='harsh',
    poweroff = 'poweroff',
    poweron = 'poweron',
    sos = 'sos',
    speed = 'speed',

    enter = 'enter',
    exit = 'exit',

}

interface iGetReportProps {
    dates: { startDate: moment.Moment, endDate: moment.Moment }[],
    allTags: iList<iTag>,
    filters: {
        [type: string]: { [id: string]: true }
    },
}

export interface iGetReportPropsWrapper extends iGetReportProps {
    devicesDetails: DevicesDetailsContainer;
}

export const addScheduledReport = (user: UserAuth) => (report:iScheduledReport) => {
    const key = keyGen()

    clientDb().update(makeAudit(user, {
        [`reporting/scheduled/${keyGen()}`]: report
    }))

    return key
}

export const getReportSchedules = async () => (await clientDb().child('reporting').child('scheduled').once('value')).val() as iList<iScheduledReport>;

export const getReportMaintenanceTasks = async () => (await clientDb().child('reporting').child('completedMaintenance').once('value')).val() as iCompleteMaintenanceTaskContainer;

export const updateReportSchedule = (user: UserAuth) => async (id, report) => clientDb().update(makeAudit(user, {
    [`reporting/scheduled/${id}`]: report
}))

export const removeReportSchedule = (user: UserAuth) => async id => clientDb().update(makeAudit(user, {
    [`reporting/scheduled/${id}`]: null
}))

export const removeGeneratedReport = (user: UserAuth) => async id => clientDb().update(makeAudit(user, {
    [`reporting/generated/${id}`]: null
}))

export const setScheduleLabel = (user: UserAuth) => async (id, label) => clientDb().update(makeAudit(user, {
    [`reporting/scheduled/${id}/label`]: label
}))

export const getAuditLog = async () => (await clientDb().child('audit-log').once('value')).val();

export const makeTrip = (dbTrip) => ({
    tripId: dbTrip.id,
    ...dbTrip.data(),
    startDate: moment(new firebase.firestore.Timestamp(dbTrip.data().startDate.seconds, dbTrip.data().startDate.nanoseconds).toDate()),
    endDate: moment(new firebase.firestore.Timestamp(dbTrip.data().endDate.seconds, dbTrip.data().endDate.nanoseconds).toDate()),
} as iTrip)

export const makeTripFromEndpointData = (dbTrip) => ({
    tripId: dbTrip.id,
    ...dbTrip,
    startDate: moment(new firebase.firestore.Timestamp(dbTrip.startDate.seconds, dbTrip.startDate.nanoseconds).toDate()),
    endDate: moment(new firebase.firestore.Timestamp(dbTrip.endDate.seconds, dbTrip.endDate.nanoseconds).toDate()),
} as iTrip)

const safeSnapped = ifElse(Array.isArray, x => x, ifElse(not, always([]), p => [p]))

const makePoint = (point) => {
    const base = point.data();

    base.time = moment(new firebase.firestore.Timestamp(base.time.seconds, base.time.nanoseconds).toDate());

    base.pointId = point.id;

    // fix up the firebase coordinates
    let coordinates: iCoordinate = {
        //{ lat: point.data().location.latitude, lng: point.data().location.longitude }
        location: { lat: base.coordinates.location.latitude, lng: base.coordinates.location.longitude },
        snappedLocations: safeSnapped(base.coordinates.snappedCoordinates).map(c => ({
            location: {
                lat: c.location.latitude,
                lng: c.location.longitude,
            },
            order: c.order
        })).sort((a,b) => a.order - b.order)
    }

    base.coordinates = coordinates;

    if (!base.alertActivity) base.alertActivity = {};

    base.address = base.address || {};

    base.address.street = base.address.street || '';
    base.address.city = base.address.city || '';
    base.address.state = base.address.state || '';
    base.address.zip = base.address.zip || '';

    return base as iDevicePing;
};

export const makePointFromEndpointData = (point) => {

    const base = point;

    base.time = moment(new firebase.firestore.Timestamp(base.time.seconds, base.time.nanoseconds).toDate());

    // fix up the firebase coordinates
    let coordinates: iCoordinate = {
        //{ lat: point.data().location.latitude, lng: point.data().location.longitude }
        location: { lat: base.coordinates.location.latitude, lng: base.coordinates.location.longitude },
        snappedLocations: safeSnapped(base.coordinates.snappedCoordinates).map(c => ({
            location: {
                lat: c.location.latitude,
                lng: c.location.longitude,
            },
            order: c.order
        })).sort((a, b) => a.order - b.order)
    }

    base.coordinates = coordinates;

    if (!base.alertActivity) base.alertActivity = {};

    base.address = base.address || {};

    base.address.street = base.address.street || '';
    base.address.city = base.address.city || '';
    base.address.state = base.address.state || '';
    base.address.zip = base.address.zip || '';

    return base as iDevicePing;
};

const makePointWithGeofences = (point): iDevicePingWithGeofences => {
    const base = makePoint(point) as iDevicePingWithGeofences;
    return base?.geofences? base: null;
}

export const getTrip = async (tripId) => {
    let raw = await clientStore().collection('trips').doc(tripId).get();

    return makeTrip(raw);
}

export const tripPointsReal = async (tripId: string) => {
    console.log('fetch points for trip ', tripId)
    const ret = {} as iList<iDevicePing>;

    // these are the trip points with the real ids

    const points = (await clientStore().collection('points')
        .where('tripId', '==', tripId)
        .orderBy('time', 'asc')
        .get()
    ).docs;

    points.forEach(p => ret[p.id] = makePoint(p));

    if (isDev) {
        console.log('drawing points for trip(s)', uniq(values(ret).map(r => r.tripId)))
    }

    return ret;
}

export const setTripCol = async (labelOrPerson: 'label' | 'personId', tripId: string) => {
    // lets prefetch the trip points;
    // this allows us to start getting the points as soon as they start the process speeding up the save
    // const trip = await getTrip(tripId);
    const tripPointsPromise = tripPointsReal(tripId);

    return async (newVal: string) => {
        const tripPoints = await tripPointsPromise;

        const batch = firestore.batch();

        batch.update(clientStore().collection('trips').doc(tripId), { [labelOrPerson]: newVal });

        vals(tripPoints).filter(p => !!p).forEach(point =>
            batch.update(
                clientStore().collection('points').doc(point.pointId),
                { [labelOrPerson]: newVal }
            )
        )

        await batch.commit();
    }
}


export const setPointCol = (labelOrPerson: 'label' | 'personId', pointId: string) => async (newVal: string) => {
    clientStore().collection('points').doc(pointId).update({ [labelOrPerson]: newVal })
}

const oneOrFail = (filters) => key => filters[key] && Object.keys(filters[key]).length === 1 ? Object.keys(filters[key])[0] : false;

const extractDevices = (filters, allTags) => {
    // tags translate to devices so lets get a true device list
    let deviceIds = Object.keys(filters[ItemType.tag] || {})
        .map(id => allTags[id])
        .filter(tag => tag.instances)
        .filter(tag => tag.instances[ItemType.device])
        .map(tag => Object.keys(tag.instances[ItemType.device]))
        .filter(k => k.length > 0)

    return flatten(deviceIds).concat(
        Object.keys(filters[ItemType.device] || {})
    );
}

const extractPeople = (filters, allTags) => {
        // tags translate to devices so lets get a true device list
    let peopleIds = Object.keys(filters[ItemType.tag] || {})
        .map(id => allTags[id])
        .filter(tag => tag.instances)
        .filter(tag => tag.instances[ItemType.person])
        .map(tag => Object.keys(tag.instances[ItemType.person]))
        .filter(k => k.length > 0)

    return flatten(peopleIds).concat(
        Object.keys(filters[ItemType.person] || {})
    );
}


export const getDevicePoints = async (id, start: moment.Moment, end: moment.Moment, limit: number = null, order: 'asc'|'desc' = 'asc', timezone: string) => {

    const offSetStart = countDateWithUserAndTimezoneOffset(start, timezone)
    const offSetEnd = countDateWithUserAndTimezoneOffset(end, timezone)

    let query = clientStore().collection('points')
        .where('device', '==', id)
        .where('time', '>=', offSetStart.toDate())
        .where('time', '<=', offSetEnd.toDate())
        .orderBy('time', order);

    if (limit) {
        query = query.limit(limit)
    }

    const points = await query.get();

    const ret = points.docs.map(makePoint);

    // let snap2 = await $.post({
    //     url: 'http://localhost:3000/snap',
    //     contentType: 'application/json',
    //     dataType: 'json',
    //     data: JSON.stringify(ret.map(p => ({
    //         pointId: p.pointId,
    //         coordinates: { location: p.coordinates.location }
    //     }))),
    // })

    // snap2.forEach(s => {
    //     if (ret.find(p => p.pointId == s.pointId)) {
    //         ret.find(p => p.pointId == s.pointId).coordinates.snappedLocations = s.coordinates.snappedCoordinates.map(y => ({...y, location: { lat: y.location.latitude, lng: y.location.longitude }}))
    //     }
    // })

    return ret.sort(({time: time1}, {time: time2}) => momentCmp(time1, time2));
}

// reportdb.child('generated').push({
//     scheduleId: '-L2GDQp7u8oxQQAIkbBP',
//     startDate: (new Date()).valueOf() as any,

// } as iGeneratedReport);

export const watchGeneratedReports = (callback: (res) => any) => {
    let wrapper = (res) => callback(
        fromPairs(
            idValArr(res.val()).map(({id, val}: {id: string, val: any}) => [id, {...val, startDate: moment(val.startDate)}]) as any
        )
    );

    clientDb().child('reporting').child('generated').on("value", wrapper, err => console.log(err));

    return () => clientDb().child('reporting').child('generated').off("value", wrapper);
}

export const watchDeviceLive = (id, callback: (ping: iDevicePing | null) => void) => {
    const devLiveHelper = false && id === /*'-LiORm8q4EQ2NYJb4WMV'*/'-Lpd3DS7Fc7L0pNGotsh';

    try {
        clientStore()
            .collection('points')
            .where('device', '==', id)
            .orderBy('time', 'desc')
            .limit(devLiveHelper ? 100 : 1)
            .onSnapshot(s => {

            const pingHistory = s.docs;

            if (!pingHistory.length) {
                callback(null);
                return;
            }

            callback(makePoint(pingHistory.pop()));

            // this can be handy to see a timeline. Just up the limit above and it will live track
            if (devLiveHelper) {
                const intervalId = setInterval(() => {
                    console.log('.');
                    if (pingHistory.length) {
                        callback(makePoint(pingHistory.pop()));
                    } else {
                        clearInterval(intervalId);
                    }
                }, 2000);
            }

        }, e => console.log('error', e, 'id', id));
    } catch (e) {
        console.log(e, 'id', id)
    }
}


export const getTrips = async (props: iGetReportPropsWrapper, limitDevices: string[] | false)
: Promise<iList<iTrip>> => pipe(
    adjustTimesForTimezones(props.devicesDetails),
    getTripsOrPoints<iTrip>(ReportType.TRAVEL, limitDevices),
    filterForTimezone<iTrip>(props.devicesDetails, props.dates),
)(props);

export const getPoints = async (props: iGetReportPropsWrapper, limitDevices: string[] | false, dispatch?, firstOrLastVisible?, next?):
Promise<iList<iDevicePing>> => pipe(
    adjustTimesForTimezones(props.devicesDetails),
    getTripsOrPoints<iDevicePing>(ReportType.STATIC, limitDevices, dispatch, firstOrLastVisible, next),
    filterForTimezone(props.devicesDetails, props.dates)
)(props);

export const getGeonfeces = async (props: iGetReportPropsWrapper, limitDevices: string[] | false)
: Promise<iList<iDevicePingWithGeofences>> => pipe(
    adjustTimesForTimezones(props.devicesDetails),
    getTripsOrPoints<iDevicePingWithGeofences>(ReportType.GEOFENCE, limitDevices),
    filterForTimezone(props.devicesDetails, props.dates)
)(props);

// export const getPoints = async (props: iGetReportPropsWrapper): Promise<iList<iDevicePing>> => getTripsOrPoints({ ...(props as any), type: 'points' });

const adjustTimesForTimezones = (devicesDetails: DevicesDetailsContainer) => (props: iGetReportProps): iGetReportProps => {
    const offsets = devicesDetails.valueSeq().map(d => utcOffset(d.timezone, false)).toArray();

    const min = offsets.length ? Math.min.apply(null, offsets) : 0;
    const max = offsets.length ? Math.max.apply(null, offsets) : 0;

    let userCurrentOffset = parseInt(moment().format('ZZ'))/100;
    if (moment().isDST()) userCurrentOffset -= 1;

    const least = Math.abs(userCurrentOffset) - Math.abs(min);
    const greatest = Math.abs(userCurrentOffset) - Math.abs(max);

    // now we adjust the dates by the user offset and the device offset

    return {
        ...props,
        dates: props.dates.map(({startDate, endDate}) => ({
            startDate: startDate.clone().subtract(Math.abs(least), 'hours'),
            endDate: endDate.clone().add(greatest, 'hours'),
        }))
    };

};

/**
 * Function to shift the device time to match the hour the user searched for.
 * Eg user on east coast searches 8am - 10am. need to shift pacific search times by 4 hours
 * So that their 8am - 10am is shown so centeral device 8am needs to look at east coast 9am times
 */
const filterForTimezone = <T>(devicesDetails: DevicesDetailsContainer, originalDates: {startDate: moment.Moment, endDate: moment.Moment}[]) => async (resP: Promise<iList<T>>): Promise<iList<T>> => {
    const userCurrentOffset = parseInt(moment().format('ZZ'))/100;

    return filter(val => {
        const device = extractDevice(devicesDetails, val.device);

        const tzDates = originalDates.map(({ startDate, endDate }) => ({
            startDate: startDate.clone().add(userCurrentOffset - utcOffset(device.timezone, startDate.isDST()), 'hours'),
            endDate: endDate.clone().add(userCurrentOffset - utcOffset(device.timezone, endDate.isDST()), 'hours'),
        }))

        // naughty copy pasta from below
        const rangeFn = inRange(tzDates);

        const cmpFn = (val as iDevicePing).pointId
            ? pipe(path(['time']), rangeFn)
            : or(
                pipe(path(['startDate']), rangeFn), pipe(path(['endDate']), rangeFn)
            );

        return cmpFn(val);
    })(await resP)
}

const getTripsOrPoints = <T>(type: ReportType, limitDevices: string[]|false = false, dispatch?, firstOrLast?, next? ) => async ({ allTags, dates, filters }: iGetReportProps): Promise<iList<T>> => {
    let queries = [];
    // one query per date range
    // plus if we are in a trip we need to check the end date

    const minStartDate = dates.map(({startDate}) => startDate).reduce((c, d):moment.Moment => !c || d.clone().isBefore(c) ? d.clone() : c.clone(), 0 as any);
    const maxEndDate = dates.map(({endDate}) => endDate).reduce((c, d):moment.Moment => !c || d.clone().isAfter(c) ? d.clone() : c.clone(), 0 as any);

    const createQueryPoints = ({collectionName, field}) => {
        const filtersKeys = Object.keys(filters);

        queries.push(
            clientStore().collection(collectionName)
                .where(field, '>=', minStartDate.toDate())
                .where(field, '<=', maxEndDate.toDate())
        )

        const getFirebaseQueryParams = ({filterType, selectedFilterValue}) => {
            const { filterName, filterValue } = selectedFilterValue;

            let obj = {name: '',value:''}

            switch(filterType) {
                case 'alerts': {
                    obj.name = `alertActivity.has${filterName.toLowerCase()}`
                    obj.value = filterValue;
                    break;
                }
                case 'device': {
                    obj.name = filterType
                    obj.value = filterName;
                    break;
                }
                // TODO need to fix it
                case 'tag': {
                    obj.name = 'device'
                    obj.value = filterValue;
                    break;
                }
                case 'labels': {
                    obj.name = 'label'
                    obj.value = filterValue;
                    break;
                }
                case 'person': {
                    obj.name = 'personId'
                    obj.value = filterName;
                    break;
                }

                default: 
                break;
            }
            return obj
        }

        if(filtersKeys.length) {
            queries = queries.map(query => {
                let newQuery = query;  // TODO refactor (remove query mutation)
                Object.entries(filters).forEach(([filterType, filter]) => {
                    let multiValues = [];
                    let obj = {filterName: '', filterValue: null}
                    const countOfSelectedFilters = Object.keys(filter).length;
                    const moreThanOneFilter = countOfSelectedFilters > 1;
                    const dynamicOperator = moreThanOneFilter ? 'in' : '==';

                    Object.entries(filter).forEach(([keyName, value]) => {
                        if(moreThanOneFilter) {
                            multiValues.push(keyName);
                        } else {
                            obj.filterName = keyName;
                            obj.filterValue = value;
                        } 
                    });

                    const { name: queryFieldName, value: queryFieldValue } = getFirebaseQueryParams({ filterType, selectedFilterValue: obj });
                    let dynamicValue = moreThanOneFilter ? multiValues : queryFieldValue;
                    if (queryFieldName && dynamicOperator && dynamicValue) {
                        newQuery = newQuery.where(queryFieldName, dynamicOperator, dynamicValue)
                    }
                });
                return newQuery;
            })
        }

    }

    if (type === ReportType.STATIC || type === ReportType.GEOFENCE) {
        createQueryPoints({collectionName: 'points', field: 'time'})
    } else {
        createQueryPoints({collectionName: 'trips', field: 'startDate'})
    }

    const one = oneOrFail(filters);

    let matchesFilter = (() => true) as any;

    // build out the filtering stuff.
    // if it is a single item we can send it to back end
    // else it has to be done post fetch because device will never == 'a' and == 'b' and we can't do or
    // for example if user chooses device a and device b we need records where device == a or device == b

    // Device Filter
    // const deviceIds = extractDevices(filters, allTags);
    // if (deviceIds.length === 0) {
    //     matchesFilter = and(matchesFilter, (x: iTrip|iDevicePing) => x.device !== "");
    // }
    // if (deviceIds.length === 1) {
    //     queries = queries.map(x => x.where('device', '==', deviceIds[0]));
    // } else if (deviceIds.length > 1) {
    //     matchesFilter = (x: iTrip|iDevicePing) => deviceIds.indexOf(x.device) !== -1;
    // } else if (limitDevices && limitDevices.length) {
    //     queries = pipe(
    //         ramdaLog,
    //         map(query => limitDevices.map(deviceId => query.where('device', '==', deviceId))),
    //         ramdaLog,
    //         unnest
    //     )(queries)
    // }

    // People Filters
    const peopleIds = extractPeople(filters, allTags);
    if (peopleIds.length === 1) {
        queries = queries.map(x => x.where('personId', '==', peopleIds[0]));
    } else if (peopleIds.length > 1) {
        matchesFilter = and(matchesFilter, (x: iTrip|iDevicePing) => peopleIds.indexOf(x.personId) !== -1);
    }

    // label filters
    if (one('labels')) {
        queries = queries.map(x => x.where('label', '==', one('labels')));
    } else if (Object.keys(filters['labels'] || {}).length > 1) {
        matchesFilter = and(matchesFilter, (x: iTrip|iDevicePing) => filters['labels'][x.label]);
    }

    // Incidents
    // speed-explicit is a special case
    // const alerts = omit(['speed-explicit'])(filters['alerts'] as any);
    // if (Object.keys(alerts).length) {
    //     matchesFilter = and(matchesFilter, (x: iTrip|iDevicePing) => Object.keys(alerts).some(alert => {
    //         return !!x.alertActivity[`has${alert.toLowerCase()}`];
    //     }));
    // }

    // // handle speed-explicit manually
    // if (path(['alerts', 'speed-explicit'])(filters)) {
    //     matchesFilter = and(matchesFilter, (x: iTrip|iDevicePing) => {
    //         const speed = (x as iTrip).maxSpeed || (x as iDevicePing).speed;
    //         return parseInt(filters['alerts']['speed-explicit'] as any) >= speed
    //     })
    // }

    if (type === ReportType.GEOFENCE) {
        queries = queries.map(q => q);
    } else {
        if(typeof next === 'boolean' && next){
            queries = queries.map(q => firstOrLast?.last ? q.orderBy('time','asc').startAfter(firstOrLast?.last) : q);
        }else {
            queries = queries.map(q => firstOrLast?.last ? q.orderBy('time','asc').endBefore(firstOrLast?.first) : q);
        }
    }

    // now lets get them into requests
    const promises = queries.map(async x => (await x.get()).docs)

    // get last doc
    const docsArray = await promises[promises.length - 1];
    const firstOrLastVisible = {
        last: docsArray[docsArray.length - 1],
        first: docsArray[0]
    }
    dispatch && firstOrLastVisible && dispatch(ReportActions.SET_LAST_VISIBLE_RECORDS(firstOrLastVisible));

    // wait for them all to finish
    let res = await Promise.all(promises);

    const ret = {};

    let timeKey = type == ReportType.TRAVEL ? 'startDate' : 'time';


    let rangeFn = inRange(dates);

    let cmpFn = type != ReportType.TRAVEL
        ? pipe(path(['time']), rangeFn)
        : or(
            pipe(path(['startDate']), rangeFn), pipe(path(['endDate']), rangeFn)
        );

    const toDate = ({seconds, nanoseconds}) => new firebase.firestore.Timestamp(seconds, nanoseconds).toDate();

    [].concat.apply([], res)
        .filter(x => matchesFilter(x.data()))
        .sort((a,b) => a.data()[timeKey].seconds - b.data()[timeKey].seconds)
        .filter(x => cmpFn(evolve({
            time: toDate,
            startDate: toDate,
            endDate: toDate,
        })(x.data())))
        .forEach(x => {
            if (type === ReportType.TRAVEL) {
                ret[x.id] = makeTrip(x);
            } else {
                const point = makePoint(x);
                if (point) ret[x.id] = point;
            }
        })
    return ret;
}

const inRange = (dates: {startDate: moment.Moment, endDate: moment.Moment}[]) => pipe(
    ifElse(
        propOr(false, '_isAMomentObject'),
        identity,
        moment
    ),
    asMoment => dates.some(({ startDate, endDate }) => asMoment.isSameOrAfter(startDate) && asMoment.isSameOrBefore(endDate))
)

const deviceMatches = (deviceIds: string[]) => tripOrPing =>
    deviceIds.length == 0 || deviceIds.indexOf(tripOrPing.deviceId) !== -1;
