import React, { FC, useState } from 'react';
import { iCreateNewLabel } from './CreateNewLabel.interfaces';
import { useSelector } from 'react-redux';
import { iFullStoreState, UserAuth } from '../../../../../../shared/interfaces';
import { update as updateLabelDb } from '../../../../../../shared/db/extra-info-db';
import { keyGen } from '../../../../../../shared/firebase';
import { ReactComponent as CloseIcon } from "../../../../../../assets/svg/close.svg";

const CreateNewLabel: FC<iCreateNewLabel> = ({onClose}) => {
    const [value, setValue] = useState("");
    const [error, setError] = useState(null);
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
    const handleChange = (e) => {
        setValue(e.target.value);
    }
    const handleCloseClick = () => {
        onClose?.();
    }
    const handleCreateLabel = async () => {
        try {
            await updateLabelDb(user)(keyGen(), { name: value });
        } catch (e) {
            setError("Couldn't create label");
        } finally {
            onClose?.();
        }
    }

    return (
        <div className="create-new-label">
            <header className="create-new-label__header">
                <p>Create label</p>
                <button className="create-new-label__close-btn" onClick={handleCloseClick}>
                    <CloseIcon className="create-new-label__close-icon"/>
                </button>
            </header>
            { error && <p className="create-new-label__error">{error}</p>}
            <input type="text" value={value} onChange={handleChange} />
            <button disabled={!value} className="create-new-label__create-btn" onClick={handleCreateLabel}>Create</button>
        </div>
    )
}

export default CreateNewLabel;