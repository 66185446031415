import update from 'immutability-helper';
import { FenceType, iList } from "../../shared/interfaces";

// todo: Remove this
class ActionsClass {
    SET_EDIT_MODE = isEditing => ({type: 'FENCES_EDIT_MODE', isEditing})
    UPDATE_FENCE_BACKUP = backup => ({ type: 'FENCE_UPDATE_BACKUP', backup })
    CLEAR_FENCE_BACKUP = () => ({ type: 'FENCE_CLEAR_BACKUP' })
    SET_BACKUP_STATUS = (status) => ({ type: 'FENCE_SET_BACKUP_STATUS', status })
}

export const Actions = new ActionsClass();

export const reducers = (state: iState = initialState, action) => {
    switch (action.type) {
        case 'FENCES_EDIT_MODE':
            return update(state, {
                isEditing: { $set: action.isEditing }
            });
        case 'FENCE_UPDATE_BACKUP':
            return update(state, {
                newFenceBackup: { $set: action.backup }
            });
        case 'FENCE_CLEAR_BACKUP':
            return update(state, {
                newFenceBackup: { $set: null }
            })
        case 'FENCE_SET_BACKUP_STATUS':
            return update(state, {
                shouldMakeBackup: { $set: action.status }
            })
        default:
            return state;
    }
}

export interface INewFenceBackup {
    switcher: boolean;
    hideIcon: boolean;
    hideName: boolean;
    hideShadows: boolean;
    enterFenceNotification: boolean;
    exitFenceNotification: boolean;
    unauthorizedFenceAlertNotification: boolean;
    unauthorizedFenceExclusionNotification: boolean;
    extraInfo: iList<string>;
    deetsToUpdate: {[key: string]: string | object | FenceType};
}

export enum FenceTab {
    MAP = 'map',
    FEN ='fences',
    DEVICES= 'vis-tag-devices',
}
export interface iState {
    currentTab: FenceTab,
    isEditing: boolean,
    newFenceBackup: INewFenceBackup | null,
    shouldMakeBackup: boolean,
}

const initialState = {
    currentTab: FenceTab.FEN,
    isEditing: false,
    newFenceBackup: null,
    shouldMakeBackup: false,
}


