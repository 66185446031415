import React from "react";
import "./itemizedBlock.scss";
import {iFullStoreState} from "../../../../../shared/interfaces";
import {useSelector} from "react-redux";
import {DashboardSize} from "../../../../../stores/reducers/dashboardInfo";
import TaskTable from "./TaskTable";
import {selectReportsMaintenanceDevices} from "../../../../../stores/reducers/maintenance/selectors";
import {isMobile} from "../../../../../shared/helpers";

const ItemizedBlock = () => {
    const reduxDevicesData = useSelector(selectReportsMaintenanceDevices);
    // const isMobile = window['cordova'];
    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);

    const getSumFrom = (array, parentKey, key) => {
        return array.reduce( (sum, cr) => {
            const value = +cr?.[parentKey]?.[key];
            return value ? sum + value : sum;
        }, 0);
    }

    const vehicles = reduxDevicesData.map( device => {
        const {deviceName: name, tasksList} = device;
        const jobs = tasksList.length;
        const miles = getSumFrom(tasksList, 'taskInfo', 'milesNeeded');
        const hours = getSumFrom(tasksList, 'taskInfo', 'hoursNeeded');
        return {name, jobs, miles, hours, tasksList};
    });

    return (
        <>
            {
                vehicles.map(vehicle => {
                    const {name, jobs, miles, hours, tasksList} = vehicle;

                    return <div key={name} className="itemized-block-wrapper">
                        <div className={`itemized-block-header ${DashboardSize.OPEN === size && !isMobile ? "itemized-block-header-column" : ""}`}>
                            <div className="itemized-block-vehicle-name">
                                {name}
                            </div>
                            <div className= {`itemized-block-vehicle-info ${DashboardSize.OPEN === size && !isMobile ? "itemized-block-vehicle-info-small" : ""}`}>
                                <div className="itemized-block-vehicle-info-block">
                                    <div className="itemized-block-vehicle-info-label">Jobs:</div>
                                    <div className="itemized-block-vehicle-info-count">{jobs}</div>
                                </div>
                                <div className="itemized-block-vehicle-info-block">
                                    <div className="itemized-block-vehicle-info-label">Miles:</div>
                                    <div className="itemized-block-vehicle-info-count">{miles}</div>
                                </div>
                                <div className="itemized-block-vehicle-info-block">
                                    <div className="itemized-block-vehicle-info-label">Hours:</div>
                                    <div className="itemized-block-vehicle-info-count">{hours}</div>
                                </div>
                            </div>
                        </div>
                        <TaskTable tasksList={tasksList}/>
                    </div>
                })
            }
        </>
    );

};

export default ItemizedBlock;