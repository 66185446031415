import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { pipe, path, fromPairs, toPairs, filter } from 'ramda';
import { faMinusCircle, faPencilAlt } from '@fortawesome/fontawesome-free-solid';

import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../../components/menus/modal/modal-header';
import { iScheduledReport, iList, iFullStoreState, ItemType, UserAuth } from '../../../shared/interfaces';
import {
    getReportSchedules,
    removeReportSchedule,
    setScheduleLabel,
    updateReportSchedule
} from '../../../shared/db/report-db';
import { idValsToList, idValArr } from '../../../shared/helpers';
import { Table } from '../../general/table';
import Constants from '../../../shared/constants';
import { Overlay } from '../../menus/modal/modal';
import { LabelChooser } from '../../general/trip-list-chooser';
import { ReportFiltersTable } from '../../elements/items-list-row';
import { BaseComponent } from '../../../shared/BaseComponent';
import { Fa } from '../../elements/fa';
import Dialog, { DialogConfigSetter } from '../../Dialog';
import { ReportNavbar } from './ReportNavbar';
import { history } from '../../../stores/store';
import { MinimalTile } from '../../general';
import "./scheduled-reports.scss";
import { addScheduledReport } from '../../../shared/db/report-db';
import { ReactComponent as DeleteOutlineSvg } from "../../../assets/svg/delete_outline.svg";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BottomShadowModal } from "./BottomShadowModal";
import CreateReport from "./create-report";
import { iReportDetails } from '../../../stores/reducers/report-reducers';
import { GMapModalAC, GMapModalContent } from '../../../stores/reducers/gMapModal/AC';
import { ReactComponent as EditSvg } from "../../../assets/svg/edit.svg";
import UpdateReport from "./UpdateReport";
interface iState {
    details: {
        frequency: 'weekly' | 'monthly' | 'daily' | 'anually',
        delivery?: 'weekly' | 'monthly' | 'daily' | 'anually',
        frequencyDays?: { [day: number]: boolean },
        frequencyMonths?: { month: number, day: number }
        rangeDays: number,
        format: 'pdf'|'csv',
    }
}

type IPropsFromStore = {
    authUser: UserAuth;
    labels: Array<string>;
    details: iReportDetails;
}

type IFullProps = IPropsFromStore & {
    match: object,
    location: {
        search: string
    }
};

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => ({
    authUser: state.auth.user!,
    labels: state.general.scheduleLabels || [],
    details: state.report.details
});

class ScheduledReports extends BaseComponent<IFullProps, {editLabel?: string|false, schedules: iList<iScheduledReport>, editReportId: string | number, editReportData: iScheduledReport}> {
    private dialog: DialogConfigSetter;
    constructor(props) {
        super(props);
        this.editReportHandler = this.editReportHandler.bind(this);
    }
    state = {
        schedules: {} as iList<iScheduledReport>,
        editLabel: false as false|string,
        editReportId: '' as string | number,
        editReportData: {
            type: 'travel',
            deliverySchedule: {
                schedule: 'monthly',
                frequencyDays: {},
                frequencyMonths: { month: 0, day: 1 },
            },
            format: 'pdf',
            reportRangeDays: 1,
            sendTo: {
                people: [],
                emails: []
            },
        } as iScheduledReport,
        details: {
            delivery: 'monthly',
            frequency: 'daily',
            rangeDays: 1,
            frequencyDays: {},
            frequencyMonths: { month: 0, day: 1 },
            format: 'pdf',
        },
    }
    setupDialog = (callBack: () => DialogConfigSetter): void => {
        this.dialog = callBack();
    }

    refreshSchedules = async () => {
        const schedules = await getReportSchedules();

        this.setState({ schedules })
    }

    componentDidMount() {
        this.refreshSchedules();
    }

    componentWillReceiveProps(nextProps: Readonly<IFullProps>, nextContext: any) {
        this.refreshSchedules();
    }

    __setLabel = id => async label => {
        setScheduleLabel(this.props.authUser)(id, label);

        this.setState({editLabel: false})

        this.refreshSchedules();
    }

    __editLabel = id => {
        this.setState({editLabel: id})
    }

    editReportHandler = (id, report) => {
        this.setState({editReportId: id});
        this.setState({editReportData: {
                ...report,
                frequency: 'monthly',
                deliverySchedule: {
                ...(report?.deliverySchedule.frequencyMonths == undefined && {frequencyMonths: { month: 0, day: 1 }}),
                ...(report?.deliverySchedule.frequencyDays == undefined && {frequencyDays: {}}),
                ...report?.deliverySchedule,
                }
        }});
    }

    reportUpdater = updateReportSchedule(this.props.authUser);

    debugRender = () => {
        const { labels, details } = this.props;
        const search = this.props.location.search;
        const generateReport = new URLSearchParams(search).get("generateReport");
        const { schedules: dirtySchedules, editLabel } = this.state;

        const schedules = pipe(
            toPairs,
            filter(path([1, 'deliverySchedule'])),
            fromPairs as any,
        )(dirtySchedules) as iList<iScheduledReport>

        const overlay = !editLabel ? undefined : <Overlay close={() => this.setState({editLabel: false})}>
            <LabelChooser type="schedule" canAdd choose={this.__setLabel(editLabel)} items={labels.map(l => ({id: l, content: l}))} />
        </Overlay>

        return <>
            <DashboardBlock overlay={
                <>
                    {overlay}
                    {(details.searchKey || details.reportTypeFromRedux === 'activity') && generateReport && <CreateReportModal/>}
                    {!!this.state.editReportId && <BottomShadowModal>
                            <UpdateReport
                                reportData={this.state.editReportData}
                                id={this.state.editReportId}
                                onClose={this.editReportHandler}
                                onSubmit={this.reportUpdater}
                                refreshReports={this.refreshSchedules}
                            />
                        </BottomShadowModal>
                    }
                </>
            } title='Reports'>
                {/* <ModalHeader title="Scheduled Reports" /> */}

                <ReportNavbar />
                <div className="report-scheduled-wraper">
                    {/* <p style={{maxWidth: 500, textAlign: 'center'}}>
                            To create new scheduled reports choose Static or Travel then add filters and click the Generate Report button.
                        </p> */}
                    <div className="report-cheduled-table-wraper">
                    <PerfectScrollbar>
                        {/* <Table
                            noMeta
                            perPage={5}
                            className="report-table"
                            columns={[
                                { name: 'Label', prop: 'label', cellMapper: ({label}: iScheduledReport, _, id) => <LabelCell label={label} id={id} edit={this.__editLabel} /> },
                                { flex: 2, name: 'Filters', prop: 'filters', cellMapper: (report: iScheduledReport) =>  <DetailsCell report={report} dialog={this.dialog} />},
                                { name: 'Frequency', prop: 'type', cellMapper: ({ deliverySchedule: { schedule } }: iScheduledReport) => schedToString(schedule) },
                                { name: 'Format', prop: 'format', cellMapper: ({format}: iScheduledReport) => formatToString(format) },
                                {
                                    name: 'Remove yes',
                                    prop: 'remove',
                                    cellMapper: (_, __, id) =>
                                        <div
                                            onClick={this.__delete(id)}
                                            className="remove-cell"
                                            >
                                                <DeleteOutlineSvg className="icon-12px"/>
                                        </div>},
                            ]}
                            data={schedules || []}
                        /> */}
                        <div className="report-scheduled">
                            <div className="report-scheduled__row report-scheduled__row__header">
                                <div className="report-scheduled__col">Label</div>
                                <div className="report-scheduled__col">Filters</div>
                                <div className="report-scheduled__col">Frequency</div>
                                <div className="report-scheduled__col">Format</div>
                                <div className="report-scheduled__col">Edit</div>
                                <div className="report-scheduled__col">Remove</div>
                            </div>
                            {
                                Object.entries(schedules).map(([key, schedule]) => (
                                    <div className="report-scheduled__row">
                                        <div className="report-scheduled__col"><LabelCell label={schedule.label} id={key} edit={this.__editLabel} /></div>
                                        <div className="report-scheduled__col"><DetailsCell report={schedule} dialog={this.dialog} /></div>
                                        <div className="report-scheduled__col">{schedToString(schedule.deliverySchedule.schedule)}</div>
                                        <div className="report-scheduled__col">{formatToString(schedule.format)}</div>
                                        <div className="report-scheduled__col"><EditSvg onClick={() => this.editReportHandler(key, schedule)} className="icon-20px report-scheduled__delete"/></div>
                                        <div className="report-scheduled__col"><DeleteOutlineSvg onClick={this.__delete(key)} className="icon-20px report-scheduled__delete"/></div>
                                    </div>
                                ))
                            }
                        </div>
                    </PerfectScrollbar>
                    </div>
                </div>
            </DashboardBlock>
            <Dialog setupConfig={this.setupDialog} />
        </>;
    }

    reportRemover = removeReportSchedule(this.props.authUser);

    __delete = id => async () => {
        await this.reportRemover(id);
        this.setState({schedules: idValsToList(idValArr(this.state.schedules).filter(x => x.id != id))})
    }
}

export default connect(mapStateToProps)(ScheduledReports);

export const CreateReportModal = () => {
    return (
        <BottomShadowModal>
            <CreateReport/>
        </BottomShadowModal>
    )
}

const LabelCell = ({label, id, edit}) => {
    return (
        <button className="but but-plaine" onClick={() => edit(id)} >
            <span style={{color: "black"}}>
                {label}&nbsp;
                <sup><EditSvg className="icon-gray icon-xsm"/></sup>
            </span>
        </button>
    )
}

type iDetailsProps = {
    report: iScheduledReport;
    className?: string;
    dialog?: DialogConfigSetter;
}

export const DetailsCell = ({report, dialog, className = ""}: iDetailsProps) => {
    const filterStrs = [ItemType.device, ItemType.person, ItemType.tag, 'alerts', 'labels'].map(type => {
        if (!report.filters || !report.filters[type]) return `All ${type}`;

        return `${Object.keys(report.filters[type]).length} ${type}`;
    })

    const notification = () => dialog?.({
        title: 'Filters',
        body: <div style={{width: '100%'}}>
            <table className="table table-sm table-bordered">
                <tbody>
                <ReportFiltersTable report={report} />
                </tbody>
            </table>
            {/* <div style={{textAlign: 'center'}}>
                To change a report remove and re-add a new one.
            </div> */}
        </div>,
        type: 'NOTIFICATION',
    });

    return (
        <span className={`details-cell ${className}`} onClick={notification}>{filterStrs.join(' / ')}</span>
    )
};

export const schedToString = (sched: string) => ({
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    once: 'Once',
})[sched.toLowerCase()] || '';

export const formatToString = (format: string) => ({
    pdf: 'PDF',
    csv: 'CSV'
})[format.toString()] || ''

