import { localStorage } from '../shared/storage'
const fSize = el => Number(el.style.fontSize.replace('px', ''));

const overflowing = el => el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

const countMap = {
  1: 3,
  2: 3,
  3: 1,
  4: .5,
  5: .5,
}

var fit = (settings) => ([el, cacheKey]: [HTMLDivElement, string|null]) => setTimeout(() => {
  if (!el) return; // react ref never attached

  const fitKey = `fit-resizer-${cacheKey}`;
  const cachedSize = localStorage.get(fitKey, '__MISSING__');
  // use cached size if we have it.
  if (cachedSize !== '__MISSING__') {
    el.style.fontSize = `${cachedSize}px`;
    return;
  }

  el.style.overflow = 'hidden';
  if (!el.style.fontSize) {
    el.style.fontSize = '17px';
  }

  let valve = 1000;
  let count = 1;
  while ((!overflowing(el) || fSize(el) < settings.minFontSize) && fSize(el) <= settings.maxFontSize && valve--) {
    el.style.fontSize = `${fSize(el) + countMap[count]}px`;
    count++;
  }

  valve = 1000;
  while ((overflowing(el) || fSize(el) > settings.maxFontSize) && fSize(el) >= settings.minFontSize && valve--) {
    el.style.fontSize = `${fSize(el) - countMap[count]}px`;
    count++;
  }

  if (cacheKey) {
    localStorage.set(fitKey, fSize(el));
  }
}, 10);

export const fitText = (els: Array<[any, string|null]>, options?: { minFontSize?: number, maxFontSize?: number }) => els.forEach((el: [any, string|null]) => {
  if (!el) return;

  var settings = {
    minFontSize: 5,
    maxFontSize: 20,
    ...options,
  }

  const fitterFn = fit(settings);

  fitterFn(el);
});