import moment from "moment";
import { string } from "prop-types";
import { iCompleteMaintenanceTask, iDeviceDetails, iFullStoreState, iList, iMaintenanceTask } from "../../../shared/interfaces";
import { selectDevices } from "../devicesData/selector";
import { MaintenanceRequired, MaintenanceTask } from "./types";
import { groupBy } from "ramda";
import { countSafety } from "../../../components/pages/reports/report-table/cells/helpers";
import { ACL, UserCan } from "../../../shared/constants";

export const selectMaintenance = (s: iFullStoreState, deviceKey, maintenanceKey): iMaintenanceTask | undefined => {
    return s.maintenance?.records?.[deviceKey]?.[maintenanceKey];
}

export const selectMaintenanceRequired = (): MaintenanceRequired[] => {
    const maintenanceRequired: MaintenanceRequired[] = [];
    for(let i = 0; i < 20; ++i) {
        maintenanceRequired.push({
            key: i.toString(),
            status: i % 2? 0 : 1,
            task: "Task " + i,
            device: "Device " + i
        });
    }
    return maintenanceRequired;
}

interface MaintenanceActiveTask {
    deviceKey: string;
    deviceName: string;
    maintenanceKey: string;
    status: number;
    taskName: string;
    upcomingHours: number;
    upcomingMiles: number;
    upcomingTaskKey: string;
}

export const selectMaintenanceActiveTasks = (s: iFullStoreState): MaintenanceActiveTask[] => {
    return Object.entries(s.maintenance.records || {}).reduce((mr, [deviceKey, maintenanceList]) => {
        const maintenance = Object.entries(maintenanceList)
            .reduce((maintenance, [maintenanceKey, task]) => {
            if (!task.mainUpcoming) return maintenance;
            const [upcomingTaskKey, upcomingTask] = Object.entries(task.mainUpcoming)[0];
            return [
                ...maintenance, {
                ...upcomingTask,
                deviceKey: deviceKey,
                maintenanceKey: maintenanceKey,
                upcomingTaskKey: upcomingTaskKey,
                status: upcomingTask.status == 2? 1 : 0
                }
            ]
        }, []);
        return [
            ...mr,
            ...maintenance
        ];
    }, []);
}

export interface iMaintenanceTaskExteded extends iMaintenanceTask {
    devicename: string;
    maintenanceKey: string;
    deviceKey: string;
}

export const selectMaintenanceDevices = (s: iFullStoreState): iDeviceDetails[] => {
    return s.maintenance.allowedDevices;
}

export const userCanDo = (s) => {
    return s.auth.user?.acl?.can;
}

export const selectMaintenanceTasks = (s: iFullStoreState): iMaintenanceTaskExteded[] => {

    const userCanDoConst = userCanDo(s);
    const isSubUser = !ACL.check(UserCan.DO_ANYTHING, userCanDoConst)

    const devices = selectDevices(s);
    const devicesForSubUser = selectMaintenanceDevices(s);

    const findNameOfDeviceByDeviceKey = (arrayOfDevices, deviceKey) => {
        const foundObject = arrayOfDevices.find(deviceObj => deviceObj.id === deviceKey);
      
        if (foundObject) {
          return foundObject.name;
        } else {
          return null;
        }
      }
    return Object.entries(s.maintenance.records || {})
        .reduce((tasks, [deviceKey, tasksList]) => {
            const deviceName = isSubUser ? findNameOfDeviceByDeviceKey(devicesForSubUser, deviceKey) : devices.getIn([deviceKey, "name"]);
        
            return [
                ...tasks,
                ...Object.entries(tasksList).map(([key, t]) => ({
                    ...t,
                    deviceName: deviceName || null,
                    deviceKey: deviceKey,
                    maintenanceKey: key
                }))
            ]
        }, []);
}

// export const selectDevicePing = (s: iFullStoreState) => {
//     return s.report.devicePing.records;
// }

export const selectDevicesTrips = (s: iFullStoreState) => {
    return s.report.deviceTrip.records;
}

export interface iReportsMaintenanceDevices {
    deviceName: string;
    deviceKey: string;
    tasksList: [];
}

export const selectReportsMaintenanceDevices = (s: iFullStoreState): iReportsMaintenanceDevices[] => {
    const devices = selectDevices(s);
    const maintenance: iList<iList<iMaintenanceTask>> = Object.entries(s.report.completedMaintenance.records).reduce((acc, [maintenanceKey, taskData]) => {
        const tasksList = acc[taskData.deviceKey] || {};
        tasksList[maintenanceKey] = taskData;
        acc[taskData.deviceKey] = tasksList;
        return acc;
    }, {});

    return Object.entries(maintenance || {}).map(([deviceKey, tasksList]) => {
         const deviceName = devices.getIn([deviceKey, "name"]);
         const tasks = Object.entries(tasksList).map(([taskKey, taskData]) => {
            return {...taskData, taskKey};
         });
        return {
            deviceName,
            deviceKey,
            tasksList: tasks,
        } as any;
    }).sort((a, b) => {
        if (a.deviceKey < b.deviceKey) return -1;
        if (a.deviceKey > b.deviceKey) return 1;
        return 0;
    })
}
