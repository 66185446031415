import React, { useEffect, useRef, useState } from "react";
import {clientStorage} from "../../../../shared/firebase";
import {nanoid} from "nanoid";
import "./uploadDocumentBlock.scss"
import {
    enumActionButton,
    iLoadedFileInfo,
    iRemoveItemFromStorageResponse,
    iUploadDocumentBlock,
} from "../../../../shared/interfaces";
import {Button, ButtonSize, ButtonStyles} from "../../../Button";
import DocumentsList from "../DocumentsList";
import {changeSameName, getFileExtension} from "../../../../shared/helpers";

const UploadDocumentBlock = ({onChange, onReset, fileSizeLimit, filesMaxCount, setInLoad, loadType, needReset}: iUploadDocumentBlock) => {
    const [filesInfo, updateFilesInfo] = useState<iLoadedFileInfo[]>([]);
    const [tooMuchFilesError, updateTooMuchFilesError] = useState<string>("");
    const [needFetch, updateNeedFetch] = useState<boolean>(false);
    const wasUnmounted = useRef<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");

    const getUnmountCallback = () => {
        return () => {
            wasUnmounted.current = true;
        }
    };

    useEffect(getUnmountCallback, []);

    const removeItemUI = ({storageName}: iLoadedFileInfo) => {
        if (wasUnmounted.current) return;
        updateFilesInfo(currentState => {
            return currentState.filter((itm) => itm.storageName !== storageName );
        });
    };

    const removeItemFromStorage = (itm): Promise<iRemoveItemFromStorageResponse> => {
        const {fullPath} = itm;
        console.log("fullPath", fullPath);
        return clientStorage().child(fullPath).delete().then(() => {
            console.log(`${fullPath} was successfully deleted`);
            return {isSuccess: true};
        }).catch((error) => {
            console.log("Error on delete from storage");
            console.log(error);
            return {isSuccess: false, error};
        });
    }

    // const onlyAddedOrCancelHandleDeleteClick = (itm) => {
    //     removeItemUI(itm);
    // };

    const inLoadHandleDeleteClick = async (itm, promise) => {
        const {storageName} = itm;
        try {
            const prom = await promise.cancel();
            console.log(prom);
            removeItemUI(itm);
        } catch (error) {
            if (error.code === "storage/canceled") {
                removeItemUI(itm);
                return;
            }
            const errorText = "Upload error";


            console.log("inLoadError");
            const newFilesInfo = filesInfo.map(itm => {
                if (itm.storageName === storageName) {
                    return {
                        ...itm,
                        inLoad: false,
                        isCancel: true,
                        onDelete: () => removeItemUI(itm),
                        errorText,
                        actionButtonType: enumActionButton.DELETE
                    };
                } else {
                    return itm;
                }
            });

            updateFilesInfo(newFilesInfo);
        }
    };

    const completeHandleDeleteClick = async (itm) => {
        if (!itm.fullPath) {
            removeItemUI(itm);
            return;
        }
        const {isSuccess, error} = await removeItemFromStorage(itm);
        if (isSuccess || error?.code === "storage/object-not-found") {
            removeItemUI(itm);
        }
    };

    const startFetching = () => {
        const filesToSend = filesInfo.filter(itm => !itm.isCancel && !itm.isLoaded && !itm.inLoad);
        filesToSend.forEach((fileInfo) => {
            const {storageName, file} = fileInfo;
            const fullPath = `documents/${storageName}`;
            const fileRef = clientStorage().child(fullPath);
            try {
                const uploadFilePromise = fileRef.put(file);
                uploadFilePromise.on("state_changed", (snapshot) => {

                        const newLoaded = snapshot.bytesTransferred;
                        const onDelete = () => inLoadHandleDeleteClick(fileInfo, uploadFilePromise)

                        updateFilesInfo(currentState => {
                            // console.log("currentState", currentState);
                            // console.log("in load")
                            return currentState.map(itm => {
                                if (itm.storageName === storageName) {
                                    return {
                                        ...itm,
                                        inLoad: true,
                                        loaded: newLoaded,
                                        sizeInBytes: snapshot.totalBytes,
                                        onDelete,
                                        actionButtonType: enumActionButton.CANCEL
                                    };
                                } else {
                                    return itm;
                                }
                            });
                        });

                    }, undefined,
                    () => {
                        const onDelete = () => completeHandleDeleteClick({fullPath, storageName: storageName});

                        updateFilesInfo(currentState => {
                            // console.log("currentState", currentState)
                            return currentState.map(itm => {
                                if (itm.storageName === storageName) {
                                    return {
                                        ...itm,
                                        inLoad: false,
                                        onDelete,
                                        fullPath,
                                        isLoaded: true,
                                        actionButtonType: enumActionButton.DELETE
                                        // errorText: null, // maybe need
                                    };
                                } else {
                                    return itm;
                                }
                            });
                        });

                        console.log("Success");
                    }
                )

            } catch (e) {
                console.log(`Not success ${fileInfo}`)
            }
        });
    };

    const handleFileInputChange = ({target}: {target: HTMLInputElement}) => {
        console.log("input changed");
        setInputValue(target.value);
        const newFiles = target?.files;
        console.log("input files", newFiles);
        updateTooMuchFilesError("");
        updateFilesInfo(currentState => {
            // console.log('currentState', currentState);
            const newFilesInfo = [...currentState];
            for (let i = 0; i < newFiles.length; i++) {
                if (filesMaxCount) {
                    if (newFilesInfo.length > filesMaxCount - 1) {
                        updateTooMuchFilesError(`Select no more than ${filesMaxCount} files`);
                        break;
                    }
                }
                console.log("file info", newFiles[i]);
                const notAvailableSize = !!fileSizeLimit && newFiles[i].size > fileSizeLimit;
                const fileInfo = {
                    name: changeSameName(newFiles[i].name, newFilesInfo),
                    storageName: `${nanoid()}.${getFileExtension(newFiles[i].name)}`,
                    loaded: 0,
                    sizeInBytes: newFiles[i].size,
                    file: newFiles[i],
                } as iLoadedFileInfo;

                if (notAvailableSize) {
                    fileInfo.isCancel = true;
                    fileInfo.errorText = "This file is too large";
                    fileInfo.onDelete = () => removeItemUI(fileInfo);
                    fileInfo.actionButtonType = enumActionButton.DELETE;
                }
                newFilesInfo.push(fileInfo);
                // console.log(getFileExtension(fileInfo.name))
            }
            console.log('newFilesInfo', newFilesInfo);
            return newFilesInfo;
        });

        updateNeedFetch(true);
        setInputValue("");
    };

    useEffect(() => {
        // console.log("onChange");
        if (filesMaxCount) {
            if (filesInfo.length < filesMaxCount) {
                updateTooMuchFilesError("");
            }
        }

        if (onChange) {
            const newDocs = filesInfo
                .filter(itm => itm.isLoaded)
                .map(itm => {
                    const {name, storageName, sizeInBytes, fullPath} = itm;
                    return {name, storageName, sizeInBytes, fullPath};
                });
            onChange(newDocs);
        }

        if (setInLoad) {
            setInLoad(filesInfo.some(item => item.inLoad));
        }

        if (needFetch) {
            startFetching();
            updateNeedFetch(false);
        }

    }, [filesInfo, needFetch]);

    const resetUploadFiles = () => {
        console.log("reset")
        // const newFilesInfo = [...filesInfo];
        filesInfo.forEach(itm => {
            // console.log('itm', itm)
            itm.onDelete?.();
        });
        if (onReset) {
            onReset();
        }
    }

    const handleResetClick = () => {
        resetUploadFiles();
    };

    useEffect( () => {
        if (needReset) {
            resetUploadFiles();
        }
    }, [needReset]);

    const submitHandle = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return false;
    };

    /*const handleSendButtonClick = () => {
        startFetching();
    }*/

    return (
        <>
            <DocumentsList documents={filesInfo} columns={2} loadType={loadType} hasDeleteButton={true} className="uploaded-documents-wrapper"/>
            <form className="uploaded-form" onSubmit={submitHandle}>
                { tooMuchFilesError
                    ? <p className="uploaded-form__error">{tooMuchFilesError}</p>
                    : null
                }
                <input type="file" id="uploadFiles" multiple onChange={handleFileInputChange}
                       style={{display: "none"}}
                       value={inputValue}
                />
                <div className="uploaded-form__controls">
                    <label htmlFor="uploadFiles" className="but but-orange-white but-md">Attach</label>
                    <Button styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD} onClick={handleResetClick}>Reset</Button>
                    {/*<button onClick={handleSendButtonClick}>Send</button>*/}
                </div>
            </form>
        </>
    );
};

export default UploadDocumentBlock;
