import React from "react";
import { IPlansCard } from "./interfaces";
import "./PlansCard.scss";


const PlansCard = ({  plan, isSelected, choose }: IPlansCard) => {
  const { info, planType } = plan;

  return (
    <div
      style={{
        boxShadow:
          isSelected &&
          "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 50%)",
        opacity: isSelected && "1",
      }}
      className="plans"
    >
      <div className="plans__header">
        <span>{planType}</span>
      </div>
      {info?.map((periods) => {
        return (
          <div key={periods.billingPeriods} className="plans__line">
            <span>{periods?.billingPeriods}</span>
            <span>{periods?.price} {periods?.price && periods?.price !== 'Not Available' ? '$' : ''}</span>
          </div>
        );
      })}
      <div className="plans__btn" onClick={() => choose(info, planType)}>
        Choose Plan
      </div>
    </div>
  );
};

export default PlansCard;
