import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { default as C } from '../../../../../shared/constants';
import {
  iDeviceDetails,
  iFullStoreState,
} from '../../../../../shared/interfaces';

interface PersonRowProps {
  device: iDeviceDetails;
}

const PersonRow: FunctionComponent<PersonRowProps> = ({ device }) => {
  const lastPingMsg = useSelector<iFullStoreState, string>(
    (state) => state.devicesData.devicesLastPing.getIn([device.id, 'msg']) ?? ''
  );
  return (
    <div style={{ display: 'flex', marginTop: 10, fontSize: 11 }}>
      {device.name && (
        <div
          style={{
            backgroundColor: C.lightGray,
            borderRadius: 5,
            padding: '2px 5px',
            marginRight: 10,
          }}
        >
          {device.name}
        </div>
      )}
      {lastPingMsg && (
        <div
          style={{
            backgroundColor: C.lightGray,
            borderRadius: 5,
            padding: '2px 5px',
          }}
        >
          {lastPingMsg}
        </div>
      )}
    </div>
  );
};

export default PersonRow;
