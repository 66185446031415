import React, { useEffect, useState } from "react";
import moment from "moment";

import Modal from "../../../Modal";
import Loader from "../Other/Loader";
import { getInvoiceOrOrderById } from "../BillingApi/billingApiRequests";
import "../modalOrdersInvoice.scss";

const ModalOrders = ({ setActive, rowValue }) => {
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData]: any = useState(null);
  const { order, shippingMethod, billing_adrress } = orderData ?? {};
  useEffect(() => {
    setLoading(true);
    getInvoiceOrOrderById({
      endPoint: "/api/order/get-order?order_ID",
      id: rowValue.order_ID,
      setState: setOrderData,
    }).finally(() => setLoading(false));
  }, []);

  return (
    <Modal>
      <div
        className="modal_invoices_orders fade-in"
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="modal_invoices_orders-row">
              <span className="modal_invoices_orders-title">
                {order?.order_ID}
                <span style={{ color: order?.shipped === 1 && "#484646" }}>
                  {order?.shipped === 1 && " - Shipped"}
                </span>
              </span>
              <button onClick={() => setActive(false)}>X</button>
            </div>

            <table className="modal_invoices_orders-table">
              <tbody>
                <tr>
                  <th>Order ID</th>
                  <td>{order?.order_ID}</td>
                </tr>
                <tr>
                  <th>Order Date</th>
                  <td>{moment(order?.date).format("DD/MM/YYYY")}</td>
                </tr>
                <tr>
                  <th>Billing Address</th>
                  <td>
                    <tr>{billing_adrress?.line1}</tr>
                    <tr>{billing_adrress?.line2}</tr>
                    <tr>
                      {billing_adrress?.city}, {billing_adrress?.state},{" "}
                      {billing_adrress?.postal}
                    </tr>
                  </td>
                </tr>
                <tr>
                  <th>Shipping Address</th>
                  <td>
                    <tr>{billing_adrress?.line1}</tr>
                    <tr>{billing_adrress?.line2}</tr>
                    <tr>
                      {billing_adrress?.city}, {billing_adrress?.state},{" "}
                      {billing_adrress?.postal}
                    </tr>
                  </td>
                </tr>
                <tr>
                  <th>Shipping Method</th>
                  <td>
                    {shippingMethod?.shipstation_service_code}{" "}
                    {shippingMethod?.name}
                  </td>
                </tr>
                <tr>
                  <th>Total Paid</th>
                  <td>USD {order?.total_paid}</td>
                </tr>
                <tr>
                  <th>Tracking Number</th>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <hr />

            <table className="modal_invoices_orders-table">
              <tbody>
                <tr>
                  <th>Orders Content</th>
                </tr>
                <tr>
                  <td>Item</td>
                </tr>
                <tr>
                  <td>MT-OBD with Backup Battery</td>
                </tr>
                <tr>
                  <td>OBD Replicator Cable 1</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalOrders;
