import React from "react";
import { billigTablesApiHOC } from "../BillingApi/BillingTablesApiHOC";
import OrdersTable from "./OrdersTable";

const BillingOrders = () => {
  const Orders = billigTablesApiHOC(OrdersTable);
  return <Orders />;
};

export default BillingOrders;
