import React, {FC, useRef, useState} from 'react';

import DeleteIcon from '../../../../SVG-components/delete_outline';
import { ReactComponent as AddIcon } from "../../../../../assets/svg/plus.svg";
import './EditLabelFence.scss';
import useOnClickOutside from "../../../../../hooks/use-outside-click";
import AddLabels from "./AddLabels";
import CreateNewLabel from "./CreateNewLabel";
import {iEditLabelFenceProps} from "./EditLabelFence.interfaces";

const EditLabelFence: FC<iEditLabelFenceProps> = ({extraInfo, onChange, onDelete}) => {
    const [isSelectingLabels, setIsSelectingLabels] = useState(false);
    const [isCreatingLabels, setIsCreatingLabels] = useState(false);
    const addLabelsRef = useRef(null);
    const createNewLabelRef = useRef(null);
    useOnClickOutside(addLabelsRef, () => setIsSelectingLabels(false));
    useOnClickOutside(createNewLabelRef, () => setIsCreatingLabels(false));
    return (
        <>
        <div className='ExtraInfoNew'>
            <p>Change Labels</p>
            <div className="ExtraInfo-inputs">
                {Object.entries(extraInfo).map(([name, value]) => (
                    <div className="ExtraInfo-input" key={name}>
                        <label>{name}</label>
                        <input id={name} value={value} onChange={(e) => onChange(name, e.target.value, extraInfo)}/>
                        <DeleteIcon onClick={() => {
                            onDelete(name);
                        }}/>
                    </div>
                ))}
            </div>
            <div className="ExtraInfoNew__actions">
                <button className="ExtraInfoNew__add-btn" onClick={() => setIsSelectingLabels(!isSelectingLabels)} ><AddIcon /></button>
                <button className="ExtraInfoNew__create-btn" onClick={() => setIsCreatingLabels(!isSelectingLabels)}>Create new</button>
            </div>
            {
                isSelectingLabels && (
                    <div ref={addLabelsRef} className="ExtraInfoNew__modal">
                        <AddLabels
                            chosedLabel={extraInfo}
                            onClose={() => setIsSelectingLabels(false)}
                            onSelect={(value) => {
                                onChange(value, "", extraInfo)
                            }}
                        />
                    </div>
                )
            }

            {
                isCreatingLabels && (
                    <div ref={createNewLabelRef} className="ExtraInfoNew__modal">
                        <CreateNewLabel onClose={() => setIsCreatingLabels(false)}/>
                    </div>
                )
            }
        </div>
        </>
    );
};

export default EditLabelFence;
