import {useSelector} from 'react-redux';

import {
    iFullStoreState,
    ReportContainer,
    Units
} from '../../../../shared/interfaces';


const useDeviceReportUnits = () => {
    const displayRecords = useSelector<iFullStoreState, ReportContainer>(s => s.report.displayRecords || {});
    const deviceIds = Object.keys(displayRecords).reduce((acc, record) => {
        if(acc.indexOf(displayRecords[record].device) === -1) {
            return [...acc, displayRecords[record].device]
        }
        return acc
    }, []);

    let deviceUnits = useSelector<iFullStoreState, Units>(
        s => s.devicesData.devicesDetails.getIn([deviceIds[0], 'units'], Units.miles),
    )
    
    return deviceUnits;
}

export default useDeviceReportUnits;