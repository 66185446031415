import React, { FC } from "react";
import "./index.scss";

type PaginatorType = {
    current: number;
    total: number;
    onPageChange: (page: number) => void;
}

const Paginator: FC<PaginatorType> = ({
    current = 2,
    total = 2,
    onPageChange
}) => {

    const handlePageChange = (page) => () => {
        onPageChange(page);
    }

    return (
       <div className="paginator">
           {current - 2 >= 1 && <div className="paginator__page" onClick={handlePageChange(1)}>1</div>}
           {current - 3 >= 1 && <div className="paginator__dots">...</div>}
           {current - 1 >= 1 && <div className="paginator__page" onClick={handlePageChange(current - 1)}>{current - 1}</div>}
           <div className="paginator__page paginator__page--active" onClick={handlePageChange(current)}>{current}</div>
           {current + 1 <= total && <div className="paginator__page" onClick={handlePageChange(current + 1)}>{current + 1}</div>}
           {current + 3 <= total && <div className="paginator__dots">...</div>}
           {current + 2 <= total && <div className="paginator__page" onClick={handlePageChange(total)}>{total}</div>}
       </div>
    )
}

export default Paginator;