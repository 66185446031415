import { iCompleteMaintenanceTask, iDeviceDetails, iEditMaintenanceTask, iMaintenanceTask, UserAuth } from "../../../shared/interfaces";

export const Actions = {
    SET_ALL_RECORDS: "MAINTENANCE/SET_ALL_RECORDS",
    CREATE_NEW_TASK: "MAINTENANCE/CREATE_NEW_TASK",
    CREATE_NEW_TASK_SUCCESS: "MAINTENANCE/CREATE_NEW_TASK_SUCCESS",
    CREATE_NEW_TASK_FAILED: "MAINTENANCE/CREATE_NEW_TASK_FAILED",

    DELETE_MAINTENANCE: "MAINTENANCE/DELETE_MAINTENANCE_TASK",
    DELETE_MAINTENANCE_SUCCESS: "MAINTENANCE/DELETE_MAINTENANCE_SUCCESS",
    DELETE_MAINTENANCE_FAILED: "MAINTENANCE/DELETE_MAINTENANCE_FAILED",

    COMPLETE_MAINTENANCE_TASK: "MAINTENANCE/COMPLETE_MAINTENANCE_TASK",
    COMPLETE_MAINTENANCE_TASK_SUCCESS: "MAINTENANCE/COMPLETE_MAINTENANCE_TASK_SUCCESS",
    COMPLETE_MAINTENANCE_TASK_FAILED: "MAINTENANCE/COMPLETE_MAINTENANCE_TASK_FAILED",

    COMPLETE_EXTRA_MAINTENANCE_TAKS: "MAINTENANCE/COMPLETE_EXTRA_MAINTENANCE_TASK",

    EDIT_MAINTENANCE_TASK: "MAINTENANCE/EDIT_MAINTENANCE_TASK",
    EDIT_MAINTENANCE_TASK_SUCCESS: "MAINTENANCE/EDIT_MAINTENANCE_TASK_SUCCESS",
    EDIT_MAINTENANCE_TASK_ERROR: "MAINTENANCE/EDIT_MAINTENANCE_TASK_ERROR",

    ADD_NEW_MAINTENANCE_TASK_OPTION: "MAINTENANCE/ADD_NEW_MAINTENANCE_TASK_OPTION",
    ADD_NEW_MAINTENANCE_TASK_OPTION_SUCCESS: "MAINTENANCE/ADD_NEW_MAINTENANCE_TASK_OPTION_SUCCESS",
    SET_MAINTENANCE_TASK_OPTIONS: "MAINTENANCE/SET_MAINTENANCE_TASK_OPTIONS",

    SET_ALLOWED_DEVICES: "SET_ALLOWED_DEVICES",
};

export const ActionsCreator = {
    createMaintenanceTasks: (user: UserAuth, maintenances: iMaintenanceTask[]) => ({
        type: Actions.CREATE_NEW_TASK,
        payload: {user, maintenances}
    }),
    createMaintenanceTasksSuccess: () => ({ type: Actions.DELETE_MAINTENANCE_SUCCESS }),
    createMaintenanceTasksFailed: (e) => ({ type: Actions.DELETE_MAINTENANCE_FAILED, payload: { error: e } }),

    deleteMaintenanceTask: (user: UserAuth, maintenanceKey: string, deviceKey: string, maintenances: iMaintenanceTask[]) => ({
        type: Actions.DELETE_MAINTENANCE,
        payload: { user, maintenanceKey, deviceKey, maintenances }
    }),
    deleteMaintenanceTaskSuccess: () => ({ type: Actions.DELETE_MAINTENANCE_SUCCESS }),
    deleteMaintenanceTaskFailed: (e) => ({ type: Actions.DELETE_MAINTENANCE_FAILED, payload: { error: e } }),

    completeExtraMaintenanceTask: (user: UserAuth, completeTask: iCompleteMaintenanceTask) => ({
        type: Actions.COMPLETE_EXTRA_MAINTENANCE_TAKS,
        payload: { user, completeTask }
    }),

    completeMaintenanceTask: (user: UserAuth, completeTask: iCompleteMaintenanceTask) => ({
        type: Actions.COMPLETE_MAINTENANCE_TASK,
        payload: { user, completeTask }
    }),
    completeMaintenanceTaskSuccess: () => ({ type: Actions.COMPLETE_MAINTENANCE_TASK_SUCCESS }),
    completeMaintenanceTaskFailed: (e) => ({ type: Actions.COMPLETE_MAINTENANCE_TASK_FAILED, payload: { error: e }}),

    editMaintenanceTask: (user: UserAuth, editMaintenace: iEditMaintenanceTask) => ({ type: Actions.EDIT_MAINTENANCE_TASK, payload: { editMaintenace, user }}),
    editMaintenanceTaskSuccess: () => ({ type: Actions.EDIT_MAINTENANCE_TASK_SUCCESS }),
    editMaintenanceTaskError: (error) => ({ type: Actions.EDIT_MAINTENANCE_TASK_ERROR, payload: { error }}),

    addNewMaintenanceTaskOption: (user: UserAuth, newTask: string) => ({ type: Actions.ADD_NEW_MAINTENANCE_TASK_OPTION, payload: { newTask, user }}),
    addNewMaintenanceTaskOptionSuccess: ({key, task}) => ({ type: Actions.ADD_NEW_MAINTENANCE_TASK_OPTION_SUCCESS, payload: { key, task }}),
    setAllMaintenanceTaskOptions: (tasks) => ({ type: Actions.SET_MAINTENANCE_TASK_OPTIONS, payload: { tasks }}),

    setMaintenanceAllowedDevices: (devices: iDeviceDetails) => ({ type: Actions.SET_ALLOWED_DEVICES, payload: { devices }})
}