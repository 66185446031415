import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fitText } from '../../../../shared/fit-text';
import { extractDevice } from '../../../../shared/helpers';
import {
  iFullStoreState,
  ItemType,
  iDeviceDetails,
  iDevicePing,
} from '../../../../shared/interfaces';
import { FaDevice, FaFav } from '../../../elements/fa';
import UserImage from '../../../general/user-image';
import { FormatAddress } from '../../devices/device-list';
import { formatWhenLast } from '../../devices/device-page';
import React from 'react';
import * as favoritesdb from '../../../../shared/db/favorites-db';
import C from '../../../../shared/constants';
import { ITileProps, tileCss } from './PersonTile.types';

export const PersonTile = ({ person, style = {} }: ITileProps) => {
  const isFavorite = useSelector<iFullStoreState, boolean>(
    (state) => !!state.general.favorites?.[ItemType.person]?.[person.id]
  );
  const assignedDevice = useSelector<
    iFullStoreState,
    iDeviceDetails | undefined
  >((state) =>
    extractDevice(state.devicesData.devicesDetails, person.hasDevice?.deviceId)
  );
  const assignedDeviceLastPing = useSelector<
    iFullStoreState,
    iDevicePing | undefined
  >((state) =>
    state.devicesData.devicesLastPing.get(person.hasDevice?.deviceId)
  );
  const uid = useSelector<iFullStoreState, string>(
    (state) => state.auth.user?.uid ?? ''
  );
  const userName = React.createRef();

  React.useEffect(() => {
    fitText([[userName.current, `person-tile-${uid}`]]);
  }, []);

  const ago = formatWhenLast(
    assignedDevice ? assignedDeviceLastPing?.time : undefined
  );

  const toggleFav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    favoritesdb.toggleFavorite(ItemType.person, uid, person.id, !isFavorite);
  };

  return (
    <Link
      key={person.id}
      to={`/person/${person.id}`}
      style={{ ...tileCss, ...style }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderBottom: `1px solid ${C.darkGray}`,
          paddingBottom: 8,
          marginBottom: 3,
          width: '100%',
        }}
      >
        <FaDevice active={!!person.hasDevice} />
        <UserImage key={person.id} person={person} size={50} />
        <FaFav
          isFav={isFavorite}
          style={{ color: C.yellow }}
          onClick={toggleFav}
        />
      </div>

      <div
        ref={userName as any}
        style={{
          width: '100%',
          textAlign: 'center',
          marginBottom: 8,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: 55,
        }}
      >
        {person.displayName}
      </div>

      {/* Last Active */}
      {ago && (
        <span
          style={{
            fontSize: 12,
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={ago.toString()}
        >
          {ago}
        </span>
      )}

      {/* Status */}
      <div
        style={{
          borderRadius: 3,
          backgroundColor: C.lightGray,
          fontSize: 12,
          fontWeight: 'bold',
          textAlign: 'center',
          padding: 3,
        }}
      >
        Status: {assignedDeviceLastPing?.msg ?? 'N/A'}
      </div>

      {/* Location */}
      {assignedDevice && (
        <>
          <FormatAddress
            style={{
              alignSelf: 'stretch',
              fontSize: 11,
              paddingTop: 5,
              textAlign: 'center',
            }}
            address={assignedDeviceLastPing?.address}
          />
          <div style={{ fontSize: 11, paddingTop: 10 }}>
            {assignedDevice.name}
          </div>
        </>
      )}
    </Link>
  );
};
