import React, { CSSProperties as css, useEffect } from 'react';
import { faTimesCircle } from '@fortawesome/fontawesome-free-regular';

import Constants from '../../../shared/constants';
import { Fa } from '../../elements/fa';
import { Row } from '../../elements/flex';

export const Overlay = ({ children = false, close, header }: {header?: string|JSX.Element, children?: any, close?: any}) => {

    useEffect(() => {
        const closeWindow = (e) => {
          if(e.keyCode === 27){
            close();
          }
        }
        window.addEventListener('keydown', closeWindow)
      return () => window.removeEventListener('keydown', closeWindow)
    },[])

    return (
    <div className="modal-overlay" style={overlayCss} onClick={close}>
        {!children ? null :
            <div style={{ overflow: 'auto', margin: '30px 10%', backgroundColor: '#fff', height: '100%', borderRadius: 5, padding: 20, paddingTop: 3, textAlign: 'center' }} onClick={event => event.stopPropagation()}>
                <Row justify="space-between" align="center" style={{ marginRight: -15, marginLeft: -15, marginBottom: 18, borderBottom: `1px solid ${Constants.primaryColor}`}} >
                    <div style={{width: 20}} />
                    {!header ? <div /> : (typeof header === 'string') ? <h6 style={{paddingBottom: 0}}>{header}</h6> : header}
                    {!close ? <div /> : <Fa onClick={close} icon={faTimesCircle} style={{fontSize: 19, cursor: 'pointer', width: 20}} />}
                </Row>
                {children ? children : null}
            </div>
        }
    </div>
)};

const overlayCss: css = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 102,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: Constants.editFenceOverlayBgColor,
}
