import React from "react";
import "./ActivationInfo.scss";

const ActivationInfo = () => {
  return (
    <div className="activation-info">
      <hr />
      <h5>FIND YOUR SERIAL NUMBER</h5>
      <div className="activation-info__block">
        <img
          height="250"
          width="250"
          src="https://mastrack.com/image/catalog/img/devices/XT-2000-Series.webp"
          alt="tracker"
        />
        <div className="activation-info__description">
          <h5>MT-OBD and Hard wired devices:</h5>
          <p>
            The serial number is printed on the label located on the front of
            the device.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ActivationInfo;
