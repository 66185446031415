import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { GithubPicker } from 'react-color';
import { IconDefinition } from '@fortawesome/fontawesome';

import ModalHeader from '../../../menus/modal/modal-header';
import ModalActions from '../../../menus/modal/modal-actions';
import DashboardBlock from '../../../DashboardBlock';
import * as devicedb from '../../../../shared/db/devices-db';
import * as generaldb from '../../../../shared/db/general-db';
import {
  IconTypes,
  iDeviceDetails,
  iFullStoreState,
  iIcon,
  UserAuth,
} from '../../../../shared/interfaces';
import { extractDevice, noSubmit } from '../../../../shared/helpers';
import C, { ICON_NAMES } from '../../../../shared/constants';
import { BaseComponent } from '../../../../shared/BaseComponent';
import { selectIconContext } from '../../../../shared/contexts/selectIcon.context';
import Timezones from './Timezones';
import LocalTile from './LocalTile';
import Dialog, { DialogConfigSetter } from '../../../Dialog';
import styles from './DeviceTabPageEdit.module.scss';
import * as tagsdb from '../../../../shared/db/tags-db';
import * as generalDb from '../../../../shared/db/general-db';
import SvgIcon from '../../../SvgIcon/SvgIcon';

const defaultColor = '#333';

const mapStateToProps = (state: iFullStoreState, ownProps) => ({
  authUser: state.auth.user,
  device: extractDevice(
    state.devicesData.devicesDetails,
    ownProps.match.params.id
  ),
  deviceId: ownProps.match.params.id,
});

@connect(mapStateToProps)
export default class DeviceTabPageEdit extends BaseComponent<
  {},
  { deviceEdits: { [field: string]: any } },
  { authUser: UserAuth; deviceId: string; device: iDeviceDetails }
> {
  static contextType = selectIconContext;
  private dialog: DialogConfigSetter;
  setupDialog = (callBack: () => DialogConfigSetter): void => {
    this.dialog = callBack();
  };

  state = {
    deviceEdits: {},
    isSvg: false,
    svgIcon: null,
  };

  constructor(props) {
    super(props);
    this.updateIcon = this.updateIcon.bind(this);
  }

  findSvg = async (icon) => {
    // const res = await fetch((icon as any)?.url);
    // const index = res.headers.get('content-type').indexOf('svg');
    // const isSvg = index === -1 ? false : true;
    // this.setState((state) => ({
    //   ...state,
    //   isSvg: isSvg,
    //   svgIcon: isSvg ? this.state.svgIcon : null,
    // }));
  };

  updateIcon = async (icon) => {
    let updatedIcon = {
      url: await generalDb.saveIcon(icon as File),
    };
    this.setState((state) => ({
      ...state,
      svgIcon: updatedIcon,
    }));
  };

  changeImage = async () => {
    const selectetIcon = await this.context.selectIcon();
    let icon: iIcon;

    if (!selectetIcon || !(typeof selectetIcon == 'object')) {
      return;
    }
    if ('icon' in selectetIcon) {
      icon = {
        type: IconTypes.FaIcon,
        fa: selectetIcon as IconDefinition,
      };
    } else if ('name' in selectetIcon && ICON_NAMES[selectetIcon.name]) {
      icon = {
        type: IconTypes.SvgIcon,
        svgIconName: selectetIcon.name as string,
      };
    } else {
      icon = {
        type: IconTypes.UserSvgIcon,
        url: await generaldb.saveIcon(selectetIcon as File),
      };
    }

    // this.findSvg(icon);

    this.setState((s) =>
      update(s, {
        deviceEdits: { icon: { $set: icon } },
      })
    );
  };

  handleStateChanges = (name, value) => {
    this.setState((s) => ({
      deviceEdits: update(s.deviceEdits, { [name]: { $set: value } }),
    }));
  };

  addFieldEdit =
    (name) =>
    ({ target: { value } }) => {
      this.handleStateChanges(name, value);
    };

  addTimezoneEdit = (name) => (target) => {
    this.handleStateChanges(name, target.value);
  };

  cancelEdit = () => this.setState({ deviceEdits: {} });

  deviceIcon = () => {
    const {
      device: { icon = false },
    } = this.fullProps;
    const { deviceEdits = {} as any } = this.state;

    if (deviceEdits.icon) return deviceEdits.icon;

    if (icon) {
      this.findSvg(icon);
      return icon;
    }
  };

  setColor = ({ hex }) =>
    this.setState((s) =>
      update(s, {
        deviceEdits: {
          color: { $set: hex },
        },
      })
    );

  private update = async () => {
    let { deviceEdits, svgIcon } = this.state;
    try {
      if (svgIcon) {
        await devicedb.updateDeviceDetails(this.fullProps.authUser)(
          this.fullProps.deviceId,
          {
            ...deviceEdits,
            icon: svgIcon,
          }
        );
      } else {
        await devicedb.updateDeviceDetails(this.fullProps.authUser)(
          this.fullProps.deviceId,
          deviceEdits
        );
      }
    } catch (e) {
      console.log('edit device error', e);
      this.dialog?.({
        title: 'Permission error',
        body: 'You dont have permission to edit devices, please contact admin',
        type: 'NOTIFICATION',
      });
    }

    this.setState(() => ({ deviceEdits: {}, isSvg: false, svgIcon: null }));
  };

  debugRender = () => {
    const { device }: { device: iDeviceDetails } = this.fullProps;
    const { deviceEdits } = this.state || ({} as any);

    if (!device) return null;

    let timezoneData = deviceEdits.timezone || device.timezone;

    const icon = deviceEdits?.icon ?? device?.icon;

    return (
      <DashboardBlock
        overlay={
          Object.keys(this.state.deviceEdits).length ? (
            <ModalActions
              buttons={[
                { click: this.cancelEdit, title: 'Cancel' },
                { click: this.update, title: 'Update', primary: true },
              ]}
            />
          ) : undefined
        }
      >
        <ModalHeader title='Edit Device' />

        {/* Image */}
        <div className={styles.image} onClick={this.changeImage}>
          {icon ? (
            <SvgIcon
              size='lg'
              color={deviceEdits.color || device.color || defaultColor}
              icon={icon}
            />
          ) : (
            'Choose Icon'
          )}
        </div>

        {
          !icon?.url && (
            <div className={styles.colorPicker}>
              <GithubPicker onChange={this.setColor} colors={C.chooserColors} />
            </div>
          )
        }

        <form onSubmit={noSubmit} style={{marginTop: '20px'}}>
          <LocalTile title='Device Title'>
            <input
              key='title-input'
              className={`mas-input ${styles.titleInput}`}
              name='name'
              value={
                deviceEdits.hasOwnProperty('name')
                  ? deviceEdits.name
                  : device.name
              }
              onChange={this.addFieldEdit('name')}
            />
          </LocalTile>

          <LocalTile title='Time Zone'>
            <Timezones
              onChange={this.addTimezoneEdit('timezone')}
              value={timezoneData}
            />
          </LocalTile>

          <LocalTile title='Units'>
            <select
              key='units'
              className={`mas-input ${styles.selectUnits}`}
              onChange={this.addFieldEdit('units')}
              value={deviceEdits.units || device.units || 'miles'}
            >
              <option value='miles'>Miles</option>
              <option value='kilo'>Kilometers</option>
              <option value='knots'>Knots</option>
            </select>
          </LocalTile>
        </form>
        <Dialog setupConfig={this.setupDialog} />
      </DashboardBlock>
    );
  };
}
