export const SELECT_DEVICE_MSG = "Select device";
export const SELECT_TASK_MSG = "Select task";

export const createRequiredMsg = (name) => `${name} is required.`;
export const createNumberMsg = (name) => `${name} should be a number.`;
export const createIntegerMsg = (name) => `${name} should be an integer.`;
export const createPositiveMsg = (name) => `${name} should be positive number.`;
export const createCorrectMsg = (name) => `${name} should be correct.`;
export const createShouldBeCorrectMsg = (name) => `${name} should be correct.`;
export const createBiggerThanMsg = (name, min) => `${name} should be bigger than ${min}.`;
export const createSelectAtLeastOneMsg = (name) => `Select at least one ${name.toLowerCase()}`;
export const createSelectOneOfMsg = (names) => `Select one of ${names}`;