import React, { FC, HTMLProps } from "react";
import "./index.scss";

interface InputProps extends HTMLProps<HTMLInputElement> {

}

const Input: FC<InputProps> = ({className = "", ...props}) => {
    return (
        <input className={`input-plaine ${className}`} {...props}/>
    )
}

export default Input;