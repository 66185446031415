import React, { ChangeEvent, useState } from "react";
import "./newMaintenanceForm.scss";
import {Map} from 'immutable';
// import { createMaintenanceTask } from "../../../../stores/reducers/maintenance/actions";
import Input from "../../../elements/Input";
import DateInput from "../../../elements/DateInput";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonSize, ButtonStyles } from "../../../Button";
import SearchGrid from "../../../SearchGrid";
import { GMapModalAC } from "../../../../stores/reducers/gMapModal/AC";
import { iDeviceDetails, iFullStoreState, iList, iMaintenanceTask, iPerson, iTag, UserAuth } from "../../../../shared/interfaces";
import { ActionsCreator as MaintenanceAC } from "../../../../stores/reducers/maintenance/actions";
import SelectorLabel from "../../../elements/SelectorLabel";
import { selectDevices } from "../../../../stores/reducers/devicesData/selector";
import { selectMaintenanceDevices } from "../../../../stores/reducers/maintenance/selectors";
import { selectPeople, selectTags } from "../../../../stores/reducers/general-selectors";
import { ACL, UserCan } from "../../../../shared/constants";
import { Moment } from "moment";
import { validateNewMaintenanceForm, validateSingleFieldNewMaintenanceForm } from "./NewMaintenanceFormValidation";
import MaintenanceTaskMultiselect from '../../../BLLComponents/MaintenanceTaskMultiselect/index';
import AddContact from "../AddContact";
import PerfectScrollbar from 'react-perfect-scrollbar';


// const colourStyles = {
//     control: styles => ({ ...styles, backgroundColor: '#F7F7F7' }),
//     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
//       const color = 'red';
//       return {
//         ...styles,
//         backgroundColor: isDisabled ? 'red' : '#F7F7F7',
//         color: 'black',
//         cursor: isDisabled ? 'not-allowed' : 'default',
//       }
//     }
// };

const NewMaintenanceForm = () => {
    const innitValues = {
        tasks: [],
        contacts: [],
        contact: '',
        miles: '',
        milesOffset: '',
        hours: '',
        hoursOffset: '',
        date:  null,
        devices: {},
        tags: {},
        people: {},
        customFields: {}
    };
    const userCanDo = useSelector<iFullStoreState, Array<string>>(
        s => s.auth.user?.acl?.can
    );
    const isSubUser = !ACL.check(UserCan.DO_ANYTHING, userCanDo)

    const devicesList = useSelector<iFullStoreState, Map<string, iDeviceDetails>>(selectDevices);
    const allDevices = useSelector<iFullStoreState, iDeviceDetails[]>(s => [...selectDevices(s).values()]);
    const tags = useSelector<iFullStoreState, iTag[]>(s => [...Object.values(selectTags(s))]);
    const subUserDevices = useSelector<iFullStoreState, iDeviceDetails[]>(s => [...selectMaintenanceDevices(s).values()])
    const devices = isSubUser ? subUserDevices : allDevices;


    // const tagDevices = useSelector<iFullStoreState, Map<string, Set<string>>>(s => s.tagsDevicesMap.tagsDevices)
    const people = useSelector<iFullStoreState, iPerson[]>(s => [...Object.values(selectPeople(s))])
    const peopleList = useSelector<iFullStoreState, iList<iPerson>>(selectPeople);
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
    const dispatch = useDispatch();
    const [values, setValues] = useState<{
        tasks: { label: string, value: string }[];
        miles: number | string;
        milesOffset: number | string;
        hours: number | string;
        hoursOffset: number | string;
        devices: iList<iDeviceDetails>;
        date: Moment;
        tags: any;
        contacts: any;
        contact: string;
        // people: iList<iPerson>;
        customFields: iList<{ value: string }>;
    }>({ ...innitValues });
    const [shouldClearAll, setClearAll] = useState(false);
    const [errors, updateErrors] = useState({});

    const checkSingleFieldValidation = (name, additiveFields = {}) => {
        return validateSingleFieldNewMaintenanceForm(name, {...values, ...additiveFields});
    };

    const checkValidate = (additiveFields) => {
        return validateNewMaintenanceForm({...values, ...additiveFields});
    };

    const createMaintenanceTasks = (maintenance) => {
        dispatch(MaintenanceAC.createMaintenanceTasks(user, maintenance));
    };

    const closeModal = () => {
        dispatch(GMapModalAC.hideModal());
    };

    const getDevicesSet = (valuesDevices: iList<iDeviceDetails>, tags: any) => {
        const devicesSet = {};
        Object.values(tags || {}).forEach((tag: any) => {
            Object.keys(tag?.instances?.device || {}).forEach(deviceKey => devicesSet[deviceKey] = true);
        });

        Object.values(valuesDevices || {}).forEach(d => {
            devicesSet[d.id] = true
        });
        return devicesSet;
    };

    const handleBlur = ({target: {name, /*value*/}}: ChangeEvent<HTMLInputElement>) => {
        const {isValid, errors: newErrors} = checkSingleFieldValidation(name);
        if (isValid) {
            if (["miles", "hours"].includes(name)) {
                updateErrors({ ...errors, [name]: null, anyMilesHoursDate: null });
            } else {
                updateErrors({ ...errors, [name]: null });
            }
        } else {
            updateErrors({ ...errors, ...newErrors });
        }
    }

    const handleTaskBlur = (tasks) => {
        const { isValid, errors: newErrors } = checkSingleFieldValidation('tasks', {
            tasks
        });
        if (isValid) {
            updateErrors({ ...errors, 'tasks': null });
        } else {
            updateErrors({ ...errors, ...newErrors });
        }
    };

    const handleDateBlur = () => {
        const { isValid, errors: newErrors } = checkSingleFieldValidation('date');
        if (isValid) {
            updateErrors({ ...errors, 'date': null, anyMilesHoursDate: null });
        } else {
            updateErrors({ ...errors, ...newErrors });
        }
    };

    const handleDevicesBlur = ({devices, tags}) => {
        const devicesSet = getDevicesSet(devices, tags);
        const {isValid, errors: newErrors} = checkSingleFieldValidation(
            'devicesSetCount',
            { devicesSetCount: Object.keys(devicesSet || {}).length, devicesSet }
        );
        if (isValid) {
            updateErrors({ ...errors, 'devicesSet': null });
        } else {
            updateErrors({ ...errors, ...newErrors });
        }
    };

    const handleTaskChange = (value) => {
        setValues({
            ...values,
            tasks: value
        });
    }

    const handleActiveChange = (field) => (active) => {
        setValues({
            ...values,
            [field]: { ...active }
        });
    };

    const handleInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [name]: value,
        });
    }

    const addNewContact = (value) => {
        setValues({
            ...values,
            contact: '',
            contacts: [...values.contacts, value]
        });
    }

    const deleteContact = (index) => {
        setValues({
            ...values,
            contacts: [...values.contacts.slice(0, index), ...values.contacts.slice(index + 1)]
        });
    }

    const handleDateChange = (date: Moment | undefined) => {
        setValues({ ...values, date });
    }

    const handleClearAllClick = () => {
        // console.log('handleClearAllClick')
        setClearAll(true);
        setValues({ ...innitValues });
        setTimeout(() => {
            setClearAll(false);
            updateErrors({});
        }, 0)
    }

    const handleCancelClick = () => {
        closeModal();
    }

    // const handleCustomFieldsChange = (val: iList<{ value: string }>) => {
    //     setValues({...values, customFields: val});
    // }

    const handleApplyClick = () => {
        // const devicesSet = {};
        // Object.values(values.tags || {}).map((tag: any) => {
        //     Object.keys(tag?.instances?.device || {}).map(deviceKey => devicesSet[deviceKey] = true);
        // });
        //
        // Object.values(values.devices || {}).forEach(d => {
        //     devicesSet[d.id] = true
        // });

        const devicesSet = getDevicesSet(values.devices, values.tags);
        // console.log('devices set ', devicesSet);

        // const peopleSet = {};
        // Object.values(values?.people || {})
        //     .forEach(p => peopleSet[p.id] = true);

        // // console.log("values.tags ", values.tags);
        // Object.values(values.tags || {})
        //     .forEach((t: any) =>
        //         Object.keys(t.instances?.person || {})
        //             .forEach(personKey =>
        //                 peopleSet[personKey] = true));


        // let alertEndpoint = Object.keys(peopleSet).reduce((acc, personKey) => {
        //     acc[personKey] = peopleList[personKey].email;
        //     return acc;
        // }, {});
        // alertEndpoint = { ...alertEndpoint, ...Object.values(values.customFields).reduce((acc, v, index) => {
        //     acc[index] = v.value;
        //     return acc;
        // }, {})}

        const findNameOfDeviceByDeviceKey = (arrayOfDevices, deviceKey) => {
            const foundObject = arrayOfDevices.find(deviceObj => deviceObj.id === deviceKey);

            if (foundObject) {
                return foundObject.name;
            } else {
                return null;
            }
        }

        const dateInSeconds = values.date?.startOf("day").toDate().getTime() / 1000;

        const maintenance: iMaintenanceTask[]
            = [...Object.keys(devicesSet)].reduce((main, device) => {
                return [
                    ...main,
                    ...values.tasks.reduce((res, task) => {
                        return [
                            ...res,
                            {
                                deviceKey: device,
                                name: task.label,
                                deviceName: isSubUser ? findNameOfDeviceByDeviceKey(devices, device) : devicesList.getIn([device, "name"]),
                                miles_needed: values.miles || 0,
                                miles_offset: values.milesOffset || 0,
                                hours_needed: values.hours || 0,
                                hours_offset: values.hoursOffset || 0,
                                date: dateInSeconds || null,
                                contacts: values.contacts,
                                // alertEndpoint
                            }
                        ]
                    }, [])
                ]
            }, []);

        const {isValid, errors: newErrors} = checkValidate({devicesSetCount: Object.keys(devicesSet || {}).length, devicesSet});
        if (isValid) {
            createMaintenanceTasks(maintenance);
            closeModal();
        } else {
            updateErrors(newErrors);
            console.info(newErrors)
        }
    }

    return (
        <PerfectScrollbar>
        <div className="new-maintenance-form-container">
            <div className="new-maintenance-form">
                <div className="new-maintenance-form__header">New Maintenance task</div>
                <div style={{ position: "relative" }}>
                    <div className="new-maintenance-form__text">Task</div>
                    <MaintenanceTaskMultiselect
                        // value={values.tasks}
                        // isMulti
                        // styles={colourStyles}
                        // closeMenuOnSelect={false}
                        onChange={(list) => {
                            handleTaskChange(list);
                            // handleTaskBlur(list);
                        }}
                        onClose={handleTaskBlur}
                        placeholder="Select from the list"
                        /*defaultValue={[
                                    // { value: 'oilChange', label: 'Oil Change' },
                           // { value: 'airFilter', label: 'Air Filter' }
                                ]}
                        options={[
                                    { value: 'oilChange', label: 'Oil Change' },
                           { value: 'brakeCheck', label: 'Brake Check' },
                           { value: 'airFilter', label: 'Air Filter' },
                           { value: 'cabinFilter', label: 'Cabin Filter' },
                           { value: 'rotateTires', label: 'Rotate Tires' }
                                ]}*/
                        shouldClear={shouldClearAll}
                    />
                </div>
                <p className='new-maintenance-form__error'>{errors['tasks']}</p>
                <div className="new-maintenance-form__set">
                    <div className="new-maintenance-form__set-group">
                        <div className="new-maintenance-form__text">Miles</div>
                        <Input
                            className="new-maintenance-form__input"
                            placeholder="0"
                            label="Miles"
                            name="miles"
                            size={4}
                            value={values["miles"]}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="new-maintenance-form__set-group">
                        <div className="new-maintenance-form__text">Offset</div>
                        <Input
                            className="new-maintenance-form__input"
                            placeholder="0"
                            label="Offset"
                            name="milesOffset"
                            size={4}
                            value={values["milesOffset"]}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="new-maintenance-form__set-group">
                        <div className="new-maintenance-form__text">Hours</div>
                        <Input
                            className="new-maintenance-form__input"
                            placeholder="0"
                            label="Hours"
                            name="hours"
                            size={4}
                            value={values["hours"]}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="new-maintenance-form__set-group">
                        <div className="new-maintenance-form__text">Offset</div>
                        <Input
                            className="new-maintenance-form__input"
                            placeholder="0"
                            label="Offset"
                            name="hoursOffset"
                            size={4}
                            value={values["hoursOffset"]}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                        />
                    </div>
                </div>
                <p className='new-maintenance-form__error'>{errors['miles']}</p>
                <p className='new-maintenance-form__error'>{errors['milesOffset']}</p>
                <p className='new-maintenance-form__error'>{errors['hours']}</p>
                <p className='new-maintenance-form__error'>{errors['hoursOffset']}</p>
                <div className="new-maintenance-form__date">
                    <DateInput onDateChange={handleDateChange} closeOnDateSelect={true} onClose={handleDateBlur} shouldClear={shouldClearAll} />
                </div>
                <p className='new-maintenance-form__error'>{errors['anyMilesHoursDate'] || errors['date']}</p>
                <div style={{ position: "relative" }}>
                    <SearchGrid<iDeviceDetails>
                    placeholder="Devices..."
                    uidExtractor={(item) => item.id}
                    onActiveChange={(list) => {
                        handleActiveChange("devices")(list);
                        handleDevicesBlur({devices: list, tags: values.tags});
                    }}
                    shouldClear={shouldClearAll}
                    onResetAll={() => {
                        updateErrors({...errors, devicesSet: null});
                        handleActiveChange("devices")({});
                    }}
                    searchFunction={({item, searchString}) => item.name.toLocaleLowerCase().includes(searchString.toLowerCase())}
                    items={[...devices]}
                    render={({item, options: {active}, handleItemClick}) => {
                        return <SelectorLabel key={item.id} itemId={item.id} active={active} title={item.name} fa={item.icon?.fa} onClick={handleItemClick} />
                    }}
                />
                </div>
                <p className='new-maintenance-form__error'>{errors['devicesSet']}</p>
                <SearchGrid<iTag>
                    placeholder="Tags..."
                    uidExtractor={(item) => item.details.id}
                    onActiveChange={(list) => {
                        handleActiveChange("tags")(list);
                        handleDevicesBlur({devices: values.devices, tags: list});
                    }}
                    shouldClear={shouldClearAll}
                    onResetAll={() => {
                        updateErrors({...errors, devicesSet: null});
                        handleActiveChange("tags")({});
                    }}
                    searchFunction={({ item, searchString }) => item.details.name.toLowerCase().includes(searchString.toLowerCase())}
                    items={tags}
                    render={({ item, options: { active }, handleItemClick }) => <SelectorLabel onClick={handleItemClick} key={item.details.id} itemId={item.details.id} title={item.details.name} active={active} fa={item.details?.icon?.fa || { fa: 'tag' }} />}
                />

                {/* <SearchGrid<iPerson>
                    withCustom={true}
                    placeholder="People..."
                    onActiveChange={handleActiveChange("people")}
                    onCustomFieldsChange={handleCustomFieldsChange}
                    shouldClear={shouldClearAll}
                    searchFunction={({item, searchString}) => item.name.includes(searchString)}
                    items={people}
                    uidExtractor={(item) => item.id}
                    render={({ item, options: { active }, handleItemClick }) => <SelectorLabel onClick={handleItemClick} key={item.id} itemId={item.id} title={item.displayName} active={active} url={item.photoURL} />}
                    renderCustom={(value) => {
                        return <SelectorLabel key={value} title={value} active={true} />
                    }}
                /> */}
                <AddContact
                    contacts={values.contacts}
                    contact={values.contact}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onAddContact={addNewContact}
                    onDeleteContact={deleteContact}
                />
            </div>

            <div className="new-maintenance-form-container__actions">
                <div>
                    <Button styleType={ButtonStyles.GRAY_INACTIVE} size={ButtonSize.MD} onClick={handleClearAllClick}>Clear all</Button>
                </div>
                <div className="new-maintenance-form-container__actions-main">
                    <Button styleType={ButtonStyles.WHITE_GRAY} size={ButtonSize.MD} onClick={handleCancelClick}>Cancel</Button>
                    <Button styleType={ButtonStyles.ORANGE_WHITE} size={ButtonSize.MD} onClick={handleApplyClick}>Apply</Button>
                </div>
            </div>
        </div>
        </PerfectScrollbar>
    )
}

export default NewMaintenanceForm;
