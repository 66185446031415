import React from "react";
import "./index.scss";

export const BottomShadowModal = ({children}) => {
    return (
        <div className="bottom-shadow-modal">
            {children}
        </div>
    )
}

