import React from 'react';
import {
    enumActionButton,
    LoadDocumentType,
    iDownloadDocumentBlock,
    iOnDownloadFileClickProps,
    iDownloadFileInfo
} from "../../../../shared/interfaces";
import DocumentsList from "../DocumentsList";
import {clientStorage} from "../../../../shared/firebase";
// import style from './DownloadDocumentBlock.module.scss';

const DownloadDocumentBlock = ({documents, updateDocuments, className}: iDownloadDocumentBlock) => {
// const DownloadDocumentBlock = ({documents: initDocuments, updateDocuments}: iDownloadDocumentBlock) => {

    // const [documents, updateDocuments] = useState([...initDocuments])

    // console.log("documents", documents)

    // const isMobile = window['cordova'];
    const cordova = window['cordova'];
    // console.log(cordova);

    const getUrlByPath = (fullPath: string): Promise<string> => {
        return clientStorage().child(fullPath).getDownloadURL()
            .then((url) => url)
            .catch((error) => {
                // Handle any errors
                console.log(error);
            });
    }

    const saveFileUsingBlob = ({blob, name}: {blob: Blob, name: string}) => {
        const device = window["device"];
        if (cordova && cordova.platformId !== "browser") { // cordova (app) download

            const fileName = `${new Date().getTime()}_${name || "download"}`;

            const getFilesFail = function(e, type = "") {
                alert("Unable to download");
                console.log("Error: ", type, e);
            }

            const getDir = () => {
               return device.platform === "Android"
                   ? 'Download' : "Documents"
            }

            document.addEventListener("deviceready", function () {
                const storageLocation = device.platform === "Android"
                    // ? cordova.file.externalDataDirectory
                    ? cordova.file.externalRootDirectory || 'file:///storage/emulated/0/'
                    : device.platform === "iOS"
                        // ? cordova.file.documentsDirectory
                        ? cordova.file.applicationStorageDirectory
                        : "";

                // window.resolveLocalFileSystemURL
                const resolveLocalFileSystemURL = window["resolveLocalFileSystemURL"];
                resolveLocalFileSystemURL(storageLocation,
                    function (fileSystem) {

                        fileSystem.getDirectory(getDir(), {
                                create: true,
                                exclusive: false
                            },
                            function (dir) {
                                dir.getFile(
                                    fileName,
                                    {
                                        create: true
                                    },
                                    function (file) {
                                        file.createWriter(
                                            function (fileWriter) {
                                                fileWriter.write(blob);

                                                fileWriter.onwriteend = function () {
                                                    const url = file.toURL();
                                                    cordova.plugins.fileOpener2.open(url, blob.type, {
                                                        error: (e) => getFilesFail(e, "on open"),
                                                        success: function success() {
                                                            console.log("success with opening the file", url);
                                                        }
                                                    });
                                                };

                                                fileWriter.onerror = (e) => getFilesFail(e, "on create writer");
                                            },
                                            // failed
                                            (e) => getFilesFail(e, "write")
                                        );
                                    },
                                    (e) => getFilesFail(e, "get file")
                                );
                            },
                            (e) => getFilesFail(e, "get directory"));
                    }, (e) => getFilesFail(e, "resolveLocalFileSystemURL"));
            });
        } else { // browser download
            const url = window.URL.createObjectURL(blob);
            // console.log(url);
            const a = document.createElement("a");
            // console.log(a);
            a.style.display = "none";
            a.href = url
            document.body.appendChild(a)
            a.download = name || "download";
            a.click();
            console.log(window.URL);
            console.log(window.URL.revokeObjectURL);
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }

        return false;

        /*const url = window.URL.createObjectURL(blob);
        console.log(url);
        const a = document.createElement("a");
        console.log(a);
        a.style.opacity = '0';
        a.href = url
        document.body.appendChild(a)
        a.download = name || "download";
        a.click();
        console.log(window.URL);
        console.log(window.URL.revokeObjectURL);
        window.URL.revokeObjectURL(url);
        return false;*/
    };

    const onProgressFetching = ({event, fullPath}: {event: ProgressEvent, fullPath: string}) => {
        if (event.lengthComputable) {
            updateDocuments((currentValues) => {
                return [...currentValues].map((doc: iDownloadFileInfo) => {
                    if (doc.fullPath === fullPath) {
                        return {...doc, loaded: event.loaded, inLoad: true, isCancel: false, errorText: "", isDocumentDisable: true}
                    } else  {
                        return {...doc};
                    }
                });
            })
            // console.log(`Received ${event.loaded} of ${event.total} bytes`);
        } else {
            // console.log(`Received ${event.loaded} bytes`); // no Content-Length
        }
    };

    const onLoadBlob = ({event, xhr, name, fullPath}: {event: ProgressEvent, xhr: XMLHttpRequest, name: string, fullPath: string}) => {
        const blob = xhr.response;

        saveFileUsingBlob({blob, name});

        updateDocuments((currentValues) => {
            return [...currentValues].map((doc: iDownloadFileInfo) => {
                if (doc.fullPath === fullPath) {
                    return {...doc, sizeInBytes:event.total, inLoad: false, isLoaded: true, file: blob, onDelete: undefined, isCancel: false, isDocumentDisable: false}
                } else  {
                    return {...doc};
                }
            });
        })
    }

    const onErrorFetching = ({event, fullPath}: {event: ProgressEvent, fullPath: string}) => {
        updateDocuments((currentValues) => {
            return [...currentValues].map((doc: iDownloadFileInfo) => {
                if (doc.fullPath === fullPath) {
                    return {...doc, loaded: event.loaded, inLoad: false, isLoaded: false, isCancel: true, errorText: "Error", isDocumentDisable: false}
                } else  {
                    return {...doc};
                }
            });
        })
    };

    const startFetchingBlob = ({url, name, fullPath}: {url: string, name: string, fullPath: string}) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onprogress = (event) => {
            onProgressFetching({event, fullPath});
        }
        xhr.onload = (event) => {
            onLoadBlob({event, xhr, name, fullPath});
        }

        xhr.onerror = (event) => {
            onErrorFetching({event, fullPath});
        }

        xhr.open('GET', url);
        xhr.send();

        updateDocuments((currentValues) => {
            return [...currentValues].map((doc: iDownloadFileInfo) => {
                if (doc.fullPath === fullPath) {
                    const onDelete = () => {
                        xhr.abort();
                        updateDocuments((currentValues) => {
                            return [...currentValues].map((doc: iDownloadFileInfo) => {
                                if (doc.fullPath === fullPath) {
                                    return {...doc, onDelete: undefined, inLoad: false, isCancel: true, errorText: "Cancel", isDocumentDisable: false}
                                } else  {
                                    return {...doc};
                                }
                            });
                        });
                    }
                    return {...doc, onDelete, actionButtonType: enumActionButton.CANCEL}
                } else  {
                    return {...doc};
                }
            });
        });
    };

    const onDownloadFileClick = async (doc: iOnDownloadFileClickProps) => {
        const {fullPath, name, file, isLoaded, inLoad} = doc;
        if (inLoad) {
            return;
        }
        if (file && isLoaded) {
            saveFileUsingBlob({blob: file, name});
        } else {
            updateDocuments((currentValues) => {
                return [...currentValues].map((doc: iDownloadFileInfo) => {
                    if (doc.fullPath === fullPath) {
                        return {...doc, isDocumentDisable: true}
                    } else  {
                        return {...doc};
                    }
                });
            });
            const url = await getUrlByPath(fullPath);
            startFetchingBlob({url, name, fullPath});
        }
    };
    // const [documents, updateDocuments] = useState(initDocuments);

    return (
        <DocumentsList documents={documents} loadType={LoadDocumentType.DOWNLOAD} hasDeleteButton={true} onDownloadFileClick={onDownloadFileClick} className={className} />
    );
}

export default DownloadDocumentBlock;
