import React, { useState, useEffect } from "react";
import ToggleSwitch from "../ToggleSwitch";
import {iSchedule, ScheduleTimeType} from "../../shared/interfaces";
import './TimeInput.scss';

interface TimeInputProps {
  item: iSchedule;
  is24hFormat: boolean;
  timeType: ScheduleTimeType;
  onTimeChange: (value: string) => void;
  setIsValidTime: (value: boolean) => void;
}

const TimeInput = ({ item, is24hFormat, timeType, onTimeChange, setIsValidTime }: TimeInputProps) => {
  const initialTime = timeType === 'start' ? item.startTime : item.endTime;
  const otherTime = timeType === 'start' ? item.endTime : item.startTime;
  const [hours, setHours] = useState<number>(Number(initialTime.split(':')[0]));
  const [minutes, setMinutes] = useState<number>(Number(initialTime.split(':')[1]));
  const [isAm, setIsAm] = useState<boolean>(hours < 12);

  useEffect(() => {
    const hoursValue = Number(initialTime.split(':')[0]);

    if (hoursValue > 12 && !is24hFormat) {
      setHours(hoursValue - 12);
    }
    else {
      setHours(hoursValue);
    }

    setMinutes(Number(initialTime.split(':')[1]));
    setIsAm(Number(initialTime.split(':')[0]) < 12);
  }, [item, timeType]);

  const toggleAmPm = () => {
    setIsAm(!isAm);
  }

  const onHoursChange = (e) => {
    const newHours = Number(e.target.value);

    if (is24hFormat) {
      if (newHours >= 0 && newHours <= 23) {
        setHours(newHours);
      }
    } else {
      if (newHours >= 1 && newHours <= 12) {
        setHours(newHours);
      }
    }
  }

  const onMinutesChange = (e) => {
    const newMinutes = Number(e.target.value);

    if (newMinutes >= 0 && newMinutes <= 59) {
      setMinutes(newMinutes);
    }
  }

  useEffect(() => {
    const newTime = transformTo24HourFormat(hours, minutes, isAm);

    if (timeType === 'start' && !compareTime(newTime, otherTime)) {
      setIsValidTime(true);
      onTimeChange(newTime);
    }
    else if (timeType === 'end' && compareTime(newTime, otherTime)) {
      setIsValidTime(true);
      onTimeChange(newTime);
    }
    else {
      setIsValidTime(false);
    }
  }, [hours, minutes, isAm]);

  const transformTo24HourFormat = (h: number, m: number, am: boolean): string => {
    let hours = h;

    if (!is24hFormat) {
      if (!am && hours !== 12) hours += 12;
      if (am && hours === 12) hours = 0;
    }

    return `${hours.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:00`;
  }

  const compareTime = (a: string, b: string) => {
    const [aHours, aMinutes] = a.split(':');
    const [bHours, bMinutes] = b.split(':');

    if (Number(aHours) > Number(bHours)) return true;
    else if ((Number(aHours) === Number(bHours)) && Number(aMinutes) > Number(bMinutes)) return true;
    return false;
  }

  const minHours = is24hFormat ? 0 : 1;
  const maxHours = is24hFormat ? 23 : 12;

  return (
    <div className='time-input'>
      <div className='input-fields'>
        <input
          type='number'
          value={hours}
          onChange={onHoursChange}
          min={minHours}
          max={maxHours}
        />
        :
        <input
          type='number'
          value={minutes}
          onChange={onMinutesChange}
          min={0}
          max={59}
        />
      </div>
      {!is24hFormat && (
        <ToggleSwitch
          label='AM'
          onToggle={toggleAmPm}
          isChecked={isAm}
        />
      )}
    </div>
  );
};

export default TimeInput;