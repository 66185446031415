import instance from "../../../api/instance";

type IsUserHaveFingerPrintgResponse = Promise<{
    has_fingerprint: boolean;
}>

const clientId = 'com.mastrack.Mobilemap';

export class FingerprintHelper {
    private callback:Function;
    private secret:string;
    private username:string;
    private cipherMode:string = 'ENCRYPT';
    private dialogMessage:string;

    public isFingerprintAvailable(callback:Function):void {
        this.callback = callback;
        if (window["device"].platform === "Android") {
            window['Fingerprint'].isAvailable(
                this.fpAuthIsAvailableSuccess.bind(this),
                this.fpAuthIsAvailableError.bind(this)
            );
        } else if (window["device"].platform === "iOS") {
            window['Fingerprint'].isAvailable(
                this.touchIdIsAvailableSuccess.bind(this),
                this.touchIdIsAvailableError.bind(this)
            );
        }
    }

    private fpAuthIsAvailableSuccess(result):void {
        this.callback(null, result);
    }

    private fpAuthIsAvailableError(message):void {
        console.log("fpAuthIsAvailableError(): " + message);
        this.callback(message);
    }
    private touchIdIsAvailableSuccess(result):void {
        result = {isAvailable: true};
        this.callback(null, result);
    }

    private touchIdIsAvailableError(error):void {
        console.log("TouchId error: " + JSON.stringify(error));
        this.callback(error.localizedDescription);
    }

    public authenticate(options:{
        secret:string,
        username:string
        mode?:string,
        ios?: {
            message?:string
        }
    }, callback:Function):void {
        this.callback = callback;
        this.secret = options.secret;
        this.username = options.username;
        if (options.ios) {
            this.dialogMessage = options.ios.message;
        }

        if (options.mode) {
            this.cipherMode = options.mode;
        }
        if (window["device"].platform === "Android") {
            this.androidAuthentication();
        } else if (window["device"].platform === "iOS") {
            this.iosAuthentication();
        }
    }

    private androidAuthentication():void {
        window['Fingerprint'].isAvailable(this.androidAuthIsAvailableSuccess.bind(this),
            this.androidAuthIsAvailableError.bind(this));
    }

    private iosAuthentication():void {
        window['Fingerprint'].isAvailable(
            this.iosAuthIsAvailableSuccess.bind(this),
            this.iosAuthIsAvailableError.bind(this)
        );
    }

    private androidAuthIsAvailableSuccess(result):void {
        if (result) {
            const config = {
                clientId: clientId,
                username:  this.username,
                // password: this.secret,
                // token: this.secret
                // secret: encodedString,
            };
            if (this.cipherMode === 'ENCRYPT') {
                // window['Fingerprint'].encrypt(config,
                //     this.fpAuthEncryptSuccess.bind(this),
                //     this.fpAuthEncryptError.bind(this)
                // );
                window['Fingerprint'].show(config, (status) => {
                    console.log('successfully logged');
                    this.fpAuthEncryptSuccess(status);
                }, (err) => this.fpAuthEncryptError(err)
                );
            } else if (this.cipherMode === 'DECRYPT') {
                // window['Fingerprint'].decrypt(config,
                //     this.fpAuthDecryptSuccess.bind(this),
                //     this.fpAuthDecryptError.bind(this)
                // );
                window['Fingerprint'].show(config,
                    (status) => this.fpAuthDecryptSuccess(status),
                    (err) => this.fpAuthDecryptError(err)
                );
            }
        } else {
            this.callback(null, result);
        }
    }

    private androidAuthIsAvailableError(message):void {
        this.callback(message);
    }

    private iosAuthIsAvailableSuccess(result):void {
        const config = {
            description: 'Use your Face / Touch Id for authenticatin'
        };
        window['Fingerprint'].show(
            config,
            (status) => this.touchIdVerifySuccess(status),
            (err) => this.touchIdVerifyError(err)
        );
    }

    private iosAuthIsAvailableError(error):void {
        this.callback(error.localizedDescription);
    }

    private fpAuthEncryptSuccess(result):void {
        this.callback(null, result);
    }

    private fpAuthEncryptError(message):void {
        this.callback(message);
    }

    private fpAuthDecryptSuccess(result):void {
        this.callback(null, result);
    }

    private fpAuthDecryptError(message):void {
        this.callback(message);
    }

    private touchIdVerifySuccess(result):void {
        result = {success: true};
        this.callback(null, result);
    }

    private touchIdVerifyError(error):void {
        this.callback(error.localizedDescription);
    }

    public deleteFingerPrintToken({ username, userId}) {
        let deleteData = {
            'device_id': window['device'].uuid,
        };
        // @ts-ignore
        (username && (deleteData.username = username));
        // @ts-ignore
        !username && (deleteData.firebase_uid = userId);
        return instance.post(`${process.env.REACT_APP_API_URL}/api-fingerprint/delete-fingerprint`, deleteData)
    }

    public isUserHaveFingerPrint() {
        // const t = localStorage.getItem("t");
        return instance.post(`${process.env.REACT_APP_API_URL}/api-fingerprint/has`, { "device_id": window['device'].uuid })
            .then(({ data }) => data as IsUserHaveFingerPrintgResponse)
    }
}
