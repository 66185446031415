import { object, string, number, date } from 'yup';
import { createBiggerThanMsg, createNumberMsg, createPositiveMsg, createRequiredMsg, createShouldBeCorrectMsg, SELECT_DEVICE_MSG, SELECT_TASK_MSG } from "../../../../../shared/error-messages-constants";
import { validateForm, validateSingleField } from '../../validate-helpers';

const CompleteExtraTaskFormValidation = object().shape({
    date: date()
        .typeError({date: createShouldBeCorrectMsg("Date")})
        .required({date: createRequiredMsg("Date")}),
    odometer: number()
        .typeError({odometer: createNumberMsg("Odometer")})
        .required({odometer: createRequiredMsg("Odometer")})
        .min(0, {odometer: createPositiveMsg("Odometer")}),
    labourHours: number()
        .typeError({labourHours: createNumberMsg("Labour hours")})
        .required({labourHours: createRequiredMsg("Labour hours")})
        .min(0, {labourHours: createBiggerThanMsg("Labour hours", 0)}),
    cost: number()
        .typeError({cost: createNumberMsg("Cost")})
        .required({cost: createRequiredMsg("Cost")})
        .positive({cost: createPositiveMsg("Cost")}),
    device: object().required({device: SELECT_DEVICE_MSG}),
    taskName: string().required({taskName: SELECT_TASK_MSG})
});

const validateCompleteTaskForm = (formData) => validateForm({ formData, scheme: CompleteExtraTaskFormValidation });

const validateSingleFieldCompleteTaskForm = (fieldPath, value) => validateSingleField({fieldPath, value, scheme: CompleteExtraTaskFormValidation});

export {validateCompleteTaskForm, validateSingleFieldCompleteTaskForm};
