import { assoc, cond, evolve } from "ramda";

class ActionsClass {
    SET_TAG_DEETS_DEVICE_FILTER = (str: string) => ({ type: 'SET_TAG_DEETS_DEVICE_FILTER', str })
}

export const Actions = new ActionsClass

export interface iState {
    tagDeetsDeviceFilter: string
}

const initialState: iState = {
    tagDeetsDeviceFilter: ''
}

export const reducers = (state = initialState, action) => {
    if (action.type == 'SET_TAG_DEETS_DEVICE_FILTER') return assoc('tagDeetsDeviceFilter', action.str)(state)

    return state
}