import React, { FC } from "react";
import { Fa } from '../../elements/fa';

interface iProps {
    className?: string,
    fa?: any,
    svg?: any,
    url?: any
};

export const Icon: FC<iProps> = ({fa, svg, url, ...props}) => {
    if (fa) {
        return <Fa icon={fa} {...props} />
    }
    if (svg || url) {
        return <img src={svg || url} {...props} alt=""/>
    }
    return null;
}