import update from 'immutability-helper';

import {clientDb} from '../firebase';
import {ItemType, iRegion, iList, iLocation, iFenceDetails, UserAuth, FenceGroup, iIcon} from '../interfaces';
import {storePathAwaiter} from '../../stores/store';
import {ACL, UserCan} from '../constants';
import {makeAudit} from '../helpers';
import {store} from '../../stores/store';

const fencesdb = () => clientDb().child('fences');

export interface iUpdateFence {
    id: string;
    label?: string;
    description?: string;
    waypoint?: boolean;
    color?: string;
    fenceGroup?: FenceGroup;
    region?: iRegion;
    path?: iLocation[];
    name?: string;
}
export const updateFence = (user: UserAuth) => async (type: ItemType, itemId: string, updateDeets: iUpdateFence) => {
    // make sure the fence type is an int
    const fixed = updateDeets.region !== undefined ?
        update(updateDeets as any, {region:
            {
                shape: {$set: parseInt(updateDeets.region.shape.toString())},
                center: {$unset: ['isOriginal']},
            }
        }) :
        updateDeets;

    const changes = {};

    // tag the fence with the type it is attached to
    fixed['itemType'] = type;
    fixed['itemId'] = itemId;
    fixed['name'] = updateDeets.name;

    Object.keys(fixed).filter((key) => key != 'path').forEach((key) => {
        changes[`fences/fence-instances/${type}/${itemId}/${fixed.id}/${key}`] = fixed[key];
    });

    // handle path separately
    // TODO: [ DATA CONSISTENCY ] not relavant anymore need to check fence creation flow;
    if (!!fixed.path) {
        changes[`fences/fence-paths/${fixed.id}`] = fixed.path;
    }

    // console.log("update", changes)

    await clientDb().update(makeAudit(user, changes));
};

export interface iCreateFence {
    name: string;
    label: string;
    color: string;
    icon: iIcon;
    region: iRegion;
    fenceGroup: FenceGroup;

    // optional fileds
    // it is null to nat create extra field in firebase
    description?: string ;
    waypoint?: boolean;
    enterAlert?: boolean;
    exitAlert?: boolean;

    exitAlertEndpoint?: {
        type: 'people' | 'custom',
        value: string[]
    } ;
    enterAlertEndpoint?: {
        type: 'people' | 'custom',
        value: string[]
    };
}

export interface iFence extends iCreateFence {
    id: string;
    itemId: string;
    itemType: ItemType;
}

export const createFence = (type: ItemType, itemId: string, fence: iCreateFence) => {
    const user = store.getState().auth.user;
    const update = createFenceUpdate(type, itemId, fence);
    return clientDb().update(makeAudit(user, update));
}

export const createFences = (type: ItemType, itemId: string, fences: iCreateFence[]) => {
    const user = store.getState().auth.user;

    const update = fences.reduce((updateAcum, fence) => {
        const update = createFenceUpdate(type, itemId, fence);
        return ({
            ...updateAcum,
            ...update
        })
    }, {});

    return clientDb().update(makeAudit(user, update));
}
export const createFenceUpdate = (type: ItemType, itemId: string, fence: iCreateFence) => {
    const path = `fences/fence-instances/${type}/${itemId}`;
    const fenceId = clientDb().child(path).push().key;

    const createFence = {
        id: fenceId,
        itemId,
        itemType: type,
        ...fence,
        region: {
            ...fence.region,
        }
    }

    const update: {[path: string]: any} = {
        [`${path}/${fenceId}`]: createFence
    }

    update[`fences/fence-notifications/${fenceId}/eventValues/tag/46/on`] = fence.enterAlert;
    update[`fences/fence-notifications/${fenceId}/eventValues/tag/47/on`] = fence.exitAlert;

    fence.enterAlertEndpoint?.value.forEach(value => {
        const path = `fences/fence-notifications/${fenceId}/eventValues/46/custom`;
        const id = clientDb().child(path).push().key;
        update[`${path}/${id}`] = value;
    });
    // fence obj should not contain alerts
    delete createFence.enterAlertEndpoint;
    fence.exitAlertEndpoint?.value.forEach(value => {
        const path = `fences/fence-notifications/${fenceId}/eventValues/47/custom`;
        const id = clientDb().child(path).push().key;
        update[`${path}/${id}`] = value;
    })
    // fence obj should not contain alerts
    delete createFence.exitAlertEndpoint;

    return update;
}

export const watchFences = async ({tagIds, itemType, itemId}, callback: () => any) => {
    // fences are both directly applied and through tags so search the tag fences too.
    console.log('zzzzzzzzzzzzzzz');

    if (!itemType) return {} as iList<iFenceDetails>;

    const direct = (await fencesdb().child('fence-instances').child(itemType.toString()).child(itemId).once('value')).val() as iList<iFenceDetails>;

    const tags = await Promise.all(
        tagIds.map(async (tagId) =>
            (await fencesdb().child('fence-instances').child(ItemType.tag.toString()).child(tagId).once('value')).val() as iList<iFenceDetails>
        )
    );

    const tagFences = {};
    tags.filter((tGroup) => !!tGroup).map((tGroup) => Object.keys(tGroup).forEach((k) => tagFences[k] = tGroup[k]));

    // merge them together prefering direct to tagged
    return {...tagFences, ...direct} as iList<iFenceDetails>;
};

export const getFence = async (itemType: ItemType, itemId: string, fenceId: string) =>
    (await fencesdb().child('fence-instances').child(itemType.toString()).child(itemId).child(fenceId).once('value')).val() as iFenceDetails;

export const deleteFence = (user: UserAuth) => async (id, itemType, itemId) => {
    await clientDb().update(makeAudit(user, {
        [`/fences/fence-instances/${itemType}/${itemId}/${id}`]: null,
        [`/fences/fence-paths/${id}`]: null,
    }));
};

export const getPath = async (id) =>
    (await fencesdb().child('fence-paths').child(id).once('value')).val();

// export const all = async (): Promise<iList<iTag>> => (await tagsDb.once("value")).val();

export const getExtraInfo = async (id) => {
    return id ? (await fencesdb().child('fence-instances').child('extra-info').child(id).once('value')).val() : null;
};

export const updateExtraInfo = (user: UserAuth) => async (itemId: string, updateInfo: object) => {

    const update = Object.keys(updateInfo).reduce((acc, rec) => {
        return {...acc, [rec]: updateInfo[rec]};
    }, {});
    await clientDb().update(makeAudit(user, {
        [`fences/fence-instances/extra-info/${itemId}`]: update
    }));
};

export const updateSerialNumber = (user: UserAuth) => async (itemId: string, number: string) => {
    await clientDb().update(makeAudit(user, {
        [`fences/fence-instances/extra-info/${itemId}`]: {
            'serial_number': number
        }
    }));
};

const getFenceNotification = async (tag, id) => {
    return id ? (await fencesdb().child(`fence-notifications/${id}/eventValues/tag/${tag}`).once('value')).val() : null;
};

const updateFenceNotification = (tag) => (user: UserAuth) => async (itemId: string, flag: boolean) => {
    const updateObj = {
        [`fences/fence-notifications/${itemId}/eventValues/tag/${tag}`]: { on: flag }
    };

    await clientDb().update(makeAudit(user, updateObj));
};

export const getEnterFenceNotification = async (id) => {
    return getFenceNotification(46, id);
};

export const updateEnterFenceNotification = (user: UserAuth) => async (itemId: string, flag: boolean) => {
    return updateFenceNotification(46)(user)(itemId, flag);
};

export const getExitFenceNotification = async (id) => {
    return getFenceNotification(47, id);
};

export const updateExitFenceNotification = (user: UserAuth) => async (itemId: string, flag: boolean) => {
    return updateFenceNotification(47)(user)(itemId, flag);
};

export const getUnauthorizedFenceAlertNotification = async (id) => {
    return getFenceNotification(107, id);
};

export const updateUnauthorizedFenceAlertNotification = (user: UserAuth) => async (itemId: string, flag: boolean) => {
    return updateFenceNotification(107)(user)(itemId, flag);
};

export const getUauthorizedFenceExclusionNotification = async (id) => {
    return getFenceNotification(108, id);
};

export const updateUnauthorizedFenceExclusionNotification = (user: UserAuth) => async (itemId: string, flag: boolean) => {
    return updateFenceNotification(108)(user)(itemId, flag);
};

export const allWatch = async (callback) => {
    const user = await storePathAwaiter<UserAuth>(['auth', 'user']);

    if (!ACL.check(UserCan.SEE_FENCES, user.acl.can)) return;

    return fencesdb().child('fence-instances').on('value', (res) => {
        callback(res.val() || {});
    }, (err) => console.log(err));
};


export const setFenceWaypoint = (user: UserAuth) => async (type: ItemType, itemId: string, fenceId: string, waypoint: boolean) => {
    await clientDb().update(makeAudit(user, {
        [`/fences/fence-instances/${type}/${itemId}/${fenceId}/waypoint`]: waypoint,
    }));
}
