import React from 'react';
// import style from './DownloadSVG.module.scss';

const FileInLoad = ({width, percent, color, className=''}) => {
  const R = (width-1)/2;
  const cx = width/2;
  const cy = width/2;
  const colorWidth = 2*Math.PI*R*percent;
  const spaceWidth = 2*Math.PI*R*(1-percent);
    return (
        <svg className={className} width={width} height={width} viewBox={`0 0 ${width} ${width}`} fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx={cx} cy={cy} r={R} fill="white" stroke="#ECECEC"/>
          <circle id="downloaded" cx={cx} cy={cy} r={R} stroke={color}
                  strokeDasharray={`${colorWidth} ${spaceWidth}`} />
          <path d="M16.5 10.205L15.795 9.5L13 12.295L10.205 9.5L9.5 10.205L12.295 13L9.5 15.795L10.205 16.5L13 13.705L15.795 16.5L16.5 15.795L13.705 13L16.5 10.205Z" fill="#B9B9B9"/>
        </svg>
    );
}

export default FileInLoad;
