import React from 'react';
import { connect } from 'react-redux';
import { pipe, keys, map, pathOr, path, always, when, empty } from 'ramda';

import { StandardItem } from '../general';
import { AlertTypeTitle, iFullStoreState } from '../../shared/interfaces';
import { iList, iPerson, iTag, iScheduledReport } from '../../shared/interfaces';
import { BaseComponent } from '../../shared/BaseComponent';
import { DevicesDetailsContainer } from '../../stores/reducers/devicesData';


export const FilterListRow = ({title, items}) =>
    <tr key={title}>
        <th style={{width: 110}} className="text-nowrap text-right">{title}</th>
        <td style={{display: 'flex', flexWrap: 'wrap'}}>
            {}
            {items.map(t => <StandardItem key={t} style={{marginRight: 5, marginTop: 3, fontSize: 11}} displayName={t} itemId={t} />)}
        </td>
    </tr>


type IProps = {
    report: iScheduledReport;
}
type IPropsFromStore = {
    devicesDetails: DevicesDetailsContainer;
    people: iList<iPerson>;
    tags: iList<iTag>;
}

type IFullProps = IProps & IPropsFromStore

export const ReportFiltersTable = connect((s: iFullStoreState): IPropsFromStore =>({
    devicesDetails: s.devicesData.devicesDetails,
    people: s.general.people,
    tags: s.general.tags,
}))
(class extends BaseComponent<IFullProps> {
    debugRender = () => {
        const { devicesDetails, people, tags, report } = this.props;

        const alerts = () => {
            let items = Object.keys(report.filters?.['alerts'] ?? {})

            if (!items.length) {
                items = ['All Alerts']
            } else {
                items = items.map(i => AlertTypeTitle(i))
            }

            return items;
        }

        return <>
            <FilterListRow title="Alert Filters" items={alerts()} />

            <FilterListRow title="Labels" items={
                Object.keys(report.filters?.['labels'] ?? {['All Labels']: true})
            } />
            {!report.filters?.tag ? <FilterListRow title="Tags" items={['All Tags']} /> :
                <FilterListRow title="Tags" items={
                    pipe(
                        pathOr({}, ['filters', 'tag']),
                        keys,
                        map(k => (path([k, 'details', 'name'])(tags)))
                    )(report)
                } />
            }
            {!report.filters?.device ? <FilterListRow title="Devices" items={['All Devices']} />  :
                <FilterListRow title="Devices" items={
                    pipe(
                        pathOr({}, ['filters', 'device']),
                        keys,
                        map(k => devicesDetails.getIn([k, 'name']))
                    )(report)
                } />
            }
            {!report.filters?.person ? <FilterListRow title="People" items={['All People']} /> :
                <FilterListRow title="People" items={
                    pipe(
                        pathOr({}, ['filters', 'person']),
                        keys,
                        map(k => (path([k, 'displayName'])(people)))
                    )(report)
                } />
            }
        </>
    }
});
