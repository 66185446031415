import React, { FC, forwardRef, HTMLProps, useEffect, useRef, useState } from "react";
import "./index.scss";
import Input from "../Input";
import { Button, ButtonSize, ButtonStyles } from "../Button";
import usePagination from "../../hooks/use-pagination";
import Paginator from "../Paginator";
import useClickOutside from "../../hooks/useClickOutside";
import { iList } from "../../shared/interfaces";
import validateCustomField from "./validateCustomField/validateCustomField";

interface SearchGridProps<T> extends HTMLProps<HTMLDivElement> {
    withCustom?: boolean;
    items: T[];
    placeholder?: string;
    uidExtractor?: (item: T) => string;
    searchFunction: ({item, searchString}: {item: T, searchString: string}) => boolean;
    render: ({item}: {item: T, options: { active: boolean }, handleItemClick}) => JSX.Element;
    renderCustom?: (value: string) => JSX.Element;
    onActiveChange?: (values: {[key: string]: Boolean}) => void;
    onResetAll?: () => void;
    onCustomFieldsChange?: (customFields: iList<{ value: string }>) => void;
    shouldClear?: boolean;
}

function SearchGrid<T>({uidExtractor = () => "uid", items: propItem, searchFunction, render, onResetAll, onActiveChange, onCustomFieldsChange, placeholder = "Search...", withCustom = false, renderCustom, shouldClear = false}: SearchGridProps<T>): React.ReactElement | null {
    const [searchString, setSearchString] = useState("");
    const [items, setItems] = useState(propItem);
    const [selected, setSelected] = useState({});
    const [isCustomOpened, setIsCustomOpened] = useState(false);
    const [customFields, setCustomField] = useState<iList<{ value: string }>>({});
    const [firstRender, setFirstRender] = useState(true)

    const customModalRef = useRef(null);
    const addCustomBtnRef = useRef<HTMLButtonElement>(null);
    useClickOutside({targetRef: customModalRef, omitRefs: [addCustomBtnRef], callback: () => { setIsCustomOpened(false) }});

    const handleResetAllClick = () => {
        setSearchString("");
        setSelected({});
        onResetAll?.();
        setFirstRender(true); // test
        setTimeout(() => {
            setFirstRender(false)
        }, 0);
    }
    const handleSearchChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(value);
    }

    const handleSearch = () => searchString? propItem.filter(item => searchFunction({item, searchString})) : propItem;

    useEffect(() => {
        setFirstRender(false);
    }, []);

    useEffect(() => {
        const searched = handleSearch();
        setItems(searched);
    }, [searchString])

    useEffect(() => {
        if (!firstRender && shouldClear) {
            // console.log('SearchGrid clear');
            setSearchString("");
            setSelected({});
            setFirstRender(true); // test
            setTimeout(() => {
                setFirstRender(false)
            }, 0);
        }
    }, [shouldClear]);

    const handleItemClick = (item) => () => {
        const key = uidExtractor(item);
        const selectedItem = selected[key];
        if (!selectedItem) {
            setSelected({...selected, [key]: item});
        } else {
            const newSelected = {...selected};
            delete newSelected[key];
            setSelected({...newSelected});
        }
    }

    const handleAddClick = () => {
        setIsCustomOpened(!isCustomOpened);
    }

    const handleCustomFieldAdd = (value) => {
        setCustomField({...customFields, [value]: { value }});
        setIsCustomOpened(false);
    }

    useEffect(() => {
        onCustomFieldsChange?.(customFields);
    }, [customFields])

    useEffect(() => {
        if (!firstRender) {
            onActiveChange?.(selected)
        }
    }, [selected]);

    const { page, goToPage, pageNumber } = usePagination<T>({data: items, pageNumber: 1, pageSize: 5});

    return (
        <div className="search-grid">
            <div className="search-grid__actions">
                <Input className="search-grid__input" type="search" placeholder={`${placeholder}`} value={searchString} onChange={handleSearchChange}/>
                <div>
                    { withCustom && <Button ref={addCustomBtnRef} styleType={ButtonStyles.WHITE_GRAY} size={ButtonSize.MD} className="search-grid__reset-all-btn" onClick={handleAddClick}>Add custom</Button>}
                    <Button styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD} className="search-grid__reset-all-btn" onClick={handleResetAllClick}>Reset all</Button>
                </div>
            </div>
            <div className="search-grid__body">
                { isCustomOpened && <AddCustomFieldModal ref={customModalRef} onFieldAdd={handleCustomFieldAdd} />}
                {   page.map((item) => {
                        return render({
                            item,
                            options: {
                                active: selected[uidExtractor(item)]
                            },
                            handleItemClick: handleItemClick(item)
                        })
                    })
                }
                {
                    Object.values(customFields).map((item) => {
                        return renderCustom(item.value);
                    })
                }
            </div>
            <div className="search-grid__pagination">
                <Paginator
                    current={pageNumber}
                    total={Math.ceil(items.length / 5)}
                    onPageChange={(page) => {console.log(page); goToPage(page)} }
                />
            </div>
        </div>
    )
}

interface AddCustomFieldModalProps extends HTMLProps<HTMLDivElement> {
    onFieldAdd?: (value: string) => void;
}

const AddCustomFieldModal: FC<AddCustomFieldModalProps> = forwardRef<HTMLDivElement, AddCustomFieldModalProps>(
    ({onFieldAdd, ...props}, ref) => {
        const [value, setValue] = useState("");
        const [errors, updateErrors] = useState<any>({});
        const handleAddClick = async () => {
            const isEmail = value.includes('@') || value.match(/[a-z]/gi);
            const fieldType = isEmail ? 'email' : 'phone';
            // console.log(fieldType);
            const checkValue = {[fieldType]: value};
            const {isValid, errors: newErrors} = validateCustomField(fieldType, checkValue);
            if (isValid) {
                onFieldAdd?.(value);
                setValue("");
            } else {
                updateErrors({...newErrors});
            }

        };
        const handleInputChange = (e) => { setValue(e.target.value) };

        return (
            <div className="custom-modal" ref={ref} {...props} >
                <div className="custom-modal__body">
                    <input type="text" className="custom-modal__input" value={value} onChange={handleInputChange} />
                    <Button styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD} className="search-grid__reset-all-btn" onClick={handleAddClick} >Add</Button>
                </div>
                <p className="custom-modal__error">{errors.custom}</p>
            </div>
        )
    }
)

export default SearchGrid;