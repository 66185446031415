import React, {FC, useEffect, useState} from "react";
import "./index.scss";
import DayPicker from 'react-day-picker';
import DayPickerNavbar from "../DayPickerNavbar";
import DayPickerWeekday from "../DayPickerWeekday";

interface PlaineDayPicker {
    onDayChange?: (day, {selected}) => void,
    defaultValue?: Date | null;
    value?: Date | null;
}

const PlaineDayPicker: FC<PlaineDayPicker> = ({onDayChange, defaultValue = null, value}) => {
    const [selectedDay, setSelectedDay] = useState(defaultValue);

    useEffect(() => {
        setSelectedDay(value)
    }, [value]);

    const handleDayClick = (day, {selected}: any) => {
        setSelectedDay(selected? undefined : day);
        onDayChange?.(day, {selected});
    }

    return <DayPicker
        className="day-picker"
        selectedDays={selectedDay}
        onDayClick={handleDayClick}
        navbarElement={DayPickerNavbar}
        weekdayElement={DayPickerWeekday}
        captionElement={() => null}
    />
}

export default PlaineDayPicker;