import React from "react";
import Pagination from "@material-ui/lab/Pagination";

const BillingPagination = ({ pageIndex, pageOptions, gotoPage }) => {
  return (
    <div className="pagination">
      <Pagination
        page={pageIndex + 1}
        count={pageOptions.length}
        onChange={(e, number) => {
          gotoPage(number - 1);
        }}
        size={"large"}
      />
    </div>
  );
};

export default BillingPagination;
