export function brainTree(addCard, token, showingCardBehaviour) {
  const client = require("braintree-web/client");
  const hostedFields = require("braintree-web/hosted-fields");
  const submitBtn = document.getElementById("my-submit");
  const form = document.getElementById("my-sample-form");

  const clientDidCreate = (err, client) => {
    hostedFields.create(
      {
        client,
        styles: {
          input: {
            "font-size": "16px",
            color: "#fff",
          },
          ".cvv": {
            "font-size": "12px",
          },
          ".expirationDate": {
            "font-size": "12px",
          },
          ".number": {
            "font-family": "monospace",
          },

          ".valid": {
            color: "#4BCA81",
          },
          ".invalid": {
            color: "#FA5732",
          },
        },
        fields: {
          number: {
            selector: "#card-number",
            maxCardLength: 16,
            placeholder: "4111 1111 1111 1111",
          },
          cvv: {
            selector: "#cvv",
            maxlength: 3,
            placeholder: "•••",
          },
          expirationDate: {
            selector: "#expiration-date",
            placeholder: "MM/YYYY",
            maxlength: 4,
          },
        },
      },
      hostedFieldsDidCreate
    );
  };

  client.create(
    {
      authorization: token,
    },
    clientDidCreate
  );

  const hostedFieldsDidCreate = (err, hostedFields) => {
    submitBtn.addEventListener("click", submitHandler.bind(null, hostedFields));
    submitBtn.removeAttribute("disabled");
  };

  const submitHandler = (hostedFields, event) => {
    event.preventDefault();
    submitBtn.setAttribute("disabled", "disabled");
    hostedFields &&
      hostedFields.tokenize((err, payload) => {
        if (err) {
          submitBtn.removeAttribute("disabled");
          console.error(err);
        } else {
          form["payment_method_nonce"].value = payload.nonce;
          addCard(payload, showingCardBehaviour); // fn for request
        }
      });
  };
}
