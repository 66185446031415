import React from "react";
import {overlayContainerCss} from "../FenceBodyLayout.helpers";

const MobileFenceLayout = ({overlay, children, header, modalActions}): JSX.Element => {
    return (
        <div className="mobile-fence-layout" style={{position: 'relative', flex: 1, width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            {!overlay ? null : <div style={overlayContainerCss}>{overlay}</div>}
            {!header ? null : <div style={{flexShrink: 0, backgroundColor: 'white'}}>{header}</div>}

            <div className="fence-container-elements" style={{flexShrink: 1, overflow: 'auto', paddingBottom: 5, backgroundColor: 'white', flex: 1}}>
                {children}
            </div>

            {!modalActions ? null : <div className="modal-actions-container">{modalActions}</div>}
        </div>
    );
};

export default MobileFenceLayout;