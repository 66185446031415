import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {OrderedMap} from 'immutable';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import Polyline from 'react-google-maps/lib/components/Polyline';
import moment from 'moment';

import { useRedux } from "../../../states/redux-state";
import { iFullStoreState, Units } from '../../../shared/interfaces';
import { IPointsContainer } from '../../../stores/reducers/devicesTripsPoints';
import { Pois } from '../../gmap/pois';
import { Reply } from '../../gmap/Reply';
import { recenterMap } from '../../../stores/reducers/gmap-reducers';


type DeviceTripProps = {
    deviceId: string;
    tripsDetails: {tripId: string, deviceId: string, color: string}[];
    tripId: string;
    tripIdx: number;
    last?: boolean;
    color: string;
    units: Units;
    live?: boolean;
    highlightPointId?: string;
}
const DeviceTrip = ({deviceId, tripId, tripIdx, last = false, color, units, highlightPointId, live = false, tripsDetails}: DeviceTripProps) => {
    const mapRef = useSelector<iFullStoreState, React.RefObject<GoogleMap> | null>(state => state.gmap.mapRef());
    const toggleRecenter = useRedux(s => s.gmap.toggleRecenter);
    const replayPath = useSelector<iFullStoreState, boolean>((s) => s.gmap.replayPath);
    let routeDetails = [];

    const rangeEnd = useSelector<iFullStoreState, moment.Moment | undefined>(
        s => s.timeline.range?.endDate,
        (l, r) => l?.unix() === r?.unix(),
    );
    const showRoute = useSelector<iFullStoreState, boolean>(
        (s) => s.devicesTripsPoints.getIn([deviceId, 'reportTripsIds', tripId]) || false
    );

    const arraysOfTripPoints = useSelector<iFullStoreState, IPointsContainer[]>(
        s => tripsDetails.map(tripItem => {
            return s.devicesTripsPoints.getIn([tripItem.deviceId, 'tripsPoints', tripItem.tripId]).toJS()
        }
    ));

    const pointsRow = useSelector<iFullStoreState, IPointsContainer>(
        s => s.devicesTripsPoints.getIn([deviceId, 'tripsPoints', tripId], OrderedMap()),
        (l, r) => l?.keySeq?.()?.last?.() === r?.keySeq?.()?.last?.()
            // if first loading is all points from last trips(not only last point)
            // next conditions can remove.
            && l?.keySeq?.()?.first?.() === r?.keySeq?.()?.first?.(),
    );

    const deviceFreshestPingId = useSelector<iFullStoreState, string | undefined>(
        state => last ? state.devicesData.devicesLastPing.getIn([deviceId, 'pointId']) : undefined,
    );
    const showLiveTrail = useSelector<iFullStoreState>(
        state => state.general.showLiveTrial
    )
    let points = pointsRow;
    // if (highlightPointId) {
    //     points = pointsRow.filter((_, pointId) => pointId === highlightPointId);
    // }
    const pointsArray = points.valueSeq().toArray();
    let locations = points.map(s => s.coordinates.location).valueSeq().toArray();

    let setOfPointRoutes = arraysOfTripPoints.map(tripItem => {
        return Object.entries(tripItem).map((position, i, array) => {
            if (i+1 === Object.keys(tripItem).length) {
                return {
                    lat: position['1']['coordinates']['location']['lat'],
                    lng: position['1']['coordinates']['location']['lng'],
                    isLast: true
                }
            }
            return position['1']['coordinates']['location']
        })
    })

    routeDetails = setOfPointRoutes.map((route, i) => {
       return  {
           color: tripsDetails[i]['color'],
           points: route
       }
    })

    React.useEffect(() => {

        if (pointsArray.length > 0
                && (!rangeEnd || rangeEnd.isAfter(pointsArray[pointsArray.length -1].time))
        ) {
            recenterMap(mapRef, locations[locations.length - 1], true, toggleRecenter);
        }
    }, [pointsArray.length]);

    if (!points.size) return null;

    const polylineOptions = {
        strokeWeight: 1.5,
        strokeColor: color,
        ...(live ? {} : polylineDashedProps),
    };

    if (live && rangeEnd) {
        if (rangeEnd.isBefore(pointsArray[0].time)) {
            return null;
        }
    }
    const pointsWithoutTripId = tripId === "pointsWithoutTripId";

    return <>
        {!pointsWithoutTripId && showLiveTrail && !replayPath && <Polyline path={locations} options={polylineOptions} />}
        <Pois lastPointId={deviceFreshestPingId} points={pointsArray} color={color} units={units} tripIdx={tripIdx} tripId={tripId} />
        {showLiveTrail && showRoute && <Reply deviceId={deviceId} location={locations} polylineOptions={polylineOptions} routeDetails={routeDetails}/>}
    </>;
};

export default React.memo(DeviceTrip);

const polylineDashedProps = {
    strokeOpacity: 0,
    icons: [{
        icon: {path: 'M 0,-3 0,3', strokeOpacity: 1, scale: 1.5},
        offset: '0',
        repeat: '12px',
    }],
};
