import React, {useState} from 'react';
import MasTrackLogoIcon from '../../SVG-components/mastrack_logo';
import './styles.scss';
import LoginForm from '../../forms/LoginForm';
import RequestPasswordReset from '../../forms/RequestPasswordReset';
import SignUpForm from '../../forms/SignUpForm';
import BackIcon from '../../SVG-components/left_chevron';
import NewPassword from '../NewPassword';
import Loader from '../Billing/Other/Loader';

interface IProps {
    token?: string;
}

const Login: React.FC<IProps> = ({token}) => {
    const [forgotPassword, setForgotPassword] = useState(false);
    const [signUp, setSignUp] = useState(false);
    const [response, setResponse] = useState(null);

    const showResetPasswordPage = window.location.href.indexOf('app-reset-password') !== -1;

    const getForm = () => {
        if (forgotPassword) {
            return <>
                    <div className="name">Request password reset</div>
                    <div className="description">Please enter your email to the following field</div>
                    <RequestPasswordReset goBack={() => setForgotPassword(!forgotPassword)} setResponse={(res) => setResponse(res)}/>
                   </>
        } else if (signUp) {
            return <>
                    <div className="name">Please sign up to view tracking information</div>
                    <div className="description">Create a new account</div>
                    <SignUpForm goBack={() => setSignUp(!signUp)} setResponse={(res) => setResponse(res)}/>
                   </>
        } else {
            return <>
                    <div className="name">Please sign in to view tracking information</div>
                    <div className="description">Please fill out the following fields to login</div>
                    <LoginForm token={token} setForgotPassword={() => setForgotPassword(!forgotPassword)} setSignUp={() => setSignUp(!signUp)}/>
                   </>
        }
    };

    const getPage = () => {
        if(showResetPasswordPage){
            return (<>
                <div className="name">Well done!</div>
                <div className="description">Please enter your new password:</div>
                <NewPassword/>
            </>);
        } else if (response) {
            return (<>
                <div className="name">Done:</div>
                <div className="description">{response}</div>
                <div
                    style={{fontSize: '11px', cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '20px'}}
                    onClick={() => {
                        setForgotPassword(false);
                        setSignUp(false);
                        setResponse(null);
                    }}
                >
                    <BackIcon/>
                        Go back
                </div>
            </>);
        } else {
            return (<>
                {getForm()}
            </>);
        }
    };

    return (
        <div className="LoginWrapper">
            <div className="Login">
                <div className="wrap">
                    <div className="logo">
                        {/* <MasTrackLogoIcon/> */}
						<img src={process.env.REACT_APP_LOGIN_LOGO} alt="logo" />
                    </div>
                    <div className="log-box">
                        <div className="title">{process.env.REACT_APP_LOGIN_TITLE}</div>
                        {getPage()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
