import React from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { iFullStoreState, iList, iPerson } from "../../../../shared/interfaces";
import { selectPeople } from "../../../../stores/reducers/general-selectors";
import { Actions as ReportActions } from "../../../../stores/reducers/report-reducers";
import { GMapModalAC } from "../../../../stores/reducers/gMapModal/AC";
import SearchGrid from "../../../SearchGrid";
import CustomFields from "../../../CustomFields";
import { Button, ButtonSize, ButtonStyles } from "../../../Button";
import { ReportSelectorLabel } from "../ReportSelectorLabel";

const SelectReportSendTo = () => {
    const dispatch = useDispatch();
    const people = useSelector<iFullStoreState, iList<iPerson>>(selectPeople);
    const peopelSendToReportKeys = useSelector<iFullStoreState, iList<boolean>>(s => s.report.details.peopleToSendReport);
    const emailsToSendTo = useSelector<iFullStoreState, string[]>(s => s.report.details.customEmailsToSendReport);
    const handleLabelClick = (key) => () => dispatch(ReportActions.TOGGLE_PEOPLE_SEND_TO_REPORT(people[key]));
    const handleCloseBtnClick = () => dispatch(GMapModalAC.hideModal());
    const handleResetAllClick = () => dispatch(ReportActions.RESET_ALL_SEND_INFO());
    return (
        <div className="report-send-to">
            <div className="report-send-to__header">Send to</div>
            <SearchGrid<iPerson>
                searchFunction={({item, searchString}) => new RegExp(`${searchString}`, "ig").test(item.displayName)}
                items={Object.values(people)}
                onResetAll={handleResetAllClick}
                render={
                    ({item}) => <ReportSelectorLabel key={item.id} className="report-send-to__label" itemId={item.id} active={peopelSendToReportKeys[item.id]} title={item.displayName} url={item.photoURL} onClick={handleLabelClick(item.id)}/>
                }
            />
            <CustomFields<string> items={emailsToSendTo} placeholder="Add email..." onItemAdd={(item) => dispatch(ReportActions.ADD_SEND_TO_REPROT_EMAIL(item))} render={
                ({item}) =>
                <div key={item} className={"report-send-to__email"}>
                    {item}
                    <Button styleType={ButtonStyles.GRAY_INACTIVE} size={ButtonSize.MD} onClick={() => dispatch(ReportActions.REMOVE_SEND_TO_REPORT_EMAIL(item))}>Remove</Button>
                </div>
            }/>
            <div className="reprot-send-to__actions">
                <Button styleType={ButtonStyles.GRAY_INACTIVE} size={ButtonSize.MD} onClick={handleCloseBtnClick}>Close</Button>
            </div>
        </div>
    )
}

export default SelectReportSendTo;