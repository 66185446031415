import React, { CSSProperties as css } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconDefinition } from '@fortawesome/fontawesome';
import * as color from 'color';

import C from '../../../shared/constants';
import { Fa } from '../../elements/fa';
import { iFullStoreState } from '../../../shared/interfaces';
import { isMobile } from '../../../shared/helpers';

type IButtonProps = {
    fa?: IconDefinition;
    title: string;
    primary?: boolean;
    click?: () => void;
    link?: string;
}

type IProps = {
    buttons: Array<IButtonProps>;
    hidden?: boolean;
    isFence?: boolean;
}

const ModalActionsComponent: React.FunctionComponent<IProps> = (props) => {
    const {isFence = false, hidden, buttons, children} = props;
    const fenceEditMode = useSelector<iFullStoreState, boolean>(s => s.fences.isEditing);

    if (fenceEditMode && !isFence) {
        return null;
    }

    return (
        <div className="modal-actions-component" style={{...getStyle(hidden), marginBottom: 30}}>
            {buttons.map(plainButton)}
            {children}
        </div>
    );
};

const getStyle = (hidden?: boolean): css => ({
    display: hidden ? 'none' : 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
    backgroundColor: color('#333').fade(.2),
    color: '#fff',
    borderRadius: 8,
    position: isMobile ? 'fixed' : 'absolute',
    bottom: isMobile ? 60 : 3,
    left: 3,
    right: 3,
    zIndex: 2,
});

export default ModalActionsComponent;


const plainButton = ({fa, title, click, link, primary = false}: IButtonProps) => {
    const primaryCss: css = {
        color: C.primaryColor,
        backgroundColor: '#fff',
        border: '1px solid transparent',
        paddingTop: fa ? 4 : 'inherit',
        paddingBottom: fa ? 2 : 'inherit',
    };

    const nonPrimaryCss: css = {
        color: '#fff',
        border: '1px solid transparent',
        paddingTop: fa ? 4 : 'inherit',
        paddingBottom: fa ? 2 : 'inherit',
    };

    // yuk, refactor this
    return link
        ? (
            <Link key={title} title={title} to={link} style={primary ? primaryCss : nonPrimaryCss} className={`btn ${primary ? 'mas-primary-button' : 'mas-non-primary-button'}`}>
                {fa ? <Fa icon={fa} style={{fontSize: 20}} /> : title }
            </Link>
        )
        : (
            <span key={title} title={title} onClick={click} style={primary ? primaryCss : nonPrimaryCss} className={`btn ${primary ? 'mas-primary-button' : 'mas-non-primary-button'}`}>
                {fa ? <Fa icon={fa} style={{fontSize: 20}} /> : title }
            </span>
        )
}