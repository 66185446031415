import React, { FC } from "react";
import { connect, DispatchProp, useDispatch, useSelector } from "react-redux";
import { Button, ButtonSize, ButtonStyles } from "../../../Button";
import { GMapModalAC, GMapModalContent } from '../../../../stores/reducers/gMapModal/AC';
import { iFullStoreState, ItemType } from "../../../../shared/interfaces";
import { iReportDetails, Actions as ReportActions } from "../../../../stores/reducers/report-reducers";
import { lCount } from "../../../../shared/helpers";
import moment from "moment";
import { useRedux } from "../../../../states/redux-state";

const FilterIndicatorItem: FC<{ className?: string }> = ({ children, className = "" }) => {
    const dispatch = useDispatch();
    const tripsFromSelectedArea = useRedux((s) => s.report.details.tripsFromSelectedArea);

    const handleClick = () => {
        if (tripsFromSelectedArea?.isSelectedAreaMode === true) {
            dispatch(GMapModalAC.showModal({ contentType: GMapModalContent.REPORT_PAGE_FILTERS_DATEPICKER }))
        } else {
            dispatch(GMapModalAC.showModal({ contentType: GMapModalContent.REPORT_PAGE_FILTERS }))
        }
    }
    return (
        <Button
          className={`filter-indicator-item ${className}`}
          styleType={ButtonStyles.WHITE_GRAY}
          size={ButtonSize.SM}
          onClick={handleClick}
        >
            {children}
        </Button>
    )
}

type IFilterIndicatorProps = {
    type: string;
    reportType: string;
}
type IFilterIndicatorPropsFromStore = {
    report: iReportDetails;
}
type IFullFilterIndicatorProps = IFilterIndicatorProps & IFilterIndicatorPropsFromStore & DispatchProp;


const FilterIndicator = connect((state: iFullStoreState): IFilterIndicatorPropsFromStore => ({
    report: state.report.details,
}))
((props: IFullFilterIndicatorProps) => {
    const { type, report } = props as IFullFilterIndicatorProps;

    const typeSlice = (report.filters || {})[type];

    const itemCount = lCount(typeSlice);

    return (
        itemCount === 0
            ? <FilterIndicatorItem>All <span className="type">{type}</span></FilterIndicatorItem>
            : <FilterIndicatorItem>
                <span className="capitalize">{type}: </span>
                <b>{itemCount}</b>
            </FilterIndicatorItem>
    )
});

const FilterIndicators = () => {
    const dispatch = useDispatch();
    const { report } = useSelector<iFullStoreState, { report: iReportDetails }>(s => ({
        report: s.report.details
    }));
    const startDate = moment.min(report.dates.map(d => d.startDate));
    const endDate = moment.max(report.dates.map(d => d.endDate));

    let filters;
    if(report.reportType === 'permissions'){
        filters = [ItemType.person];
    }
    else if(report.reportType === 'video'){
        filters = [ItemType.device, ItemType.tag];
    }
    else{
        filters = [ItemType.device, ItemType.person, ItemType.tag, 'alerts', 'labels'];
    }

    return (
        <div className="report-page-report-filters">
            <div className="filter-indicators" >
                {filters.map(type => <FilterIndicator key={type} type={type} />)}
                {report.reportType !== 'permissions' && (
                    <FilterIndicatorItem className="dates">
                    <span>
                        <span>{startDate.format("MM/DD/YY")} </span>
                        <span className="time">{startDate.format("hh:mmA")}</span>
                        <span>  -  </span>
                    </span>
                        <span>
                        <span>{endDate.format("MM/DD/YY")} </span>
                        <span className="time">{endDate.format("hh:mmA")}</span>
                    </span>
                    </FilterIndicatorItem>
                )}
            </div>

            <div className="report-filters-actions" style={{display: 'flex', justifyContent: 'space-between', fontSize: 10, margin: "10px 0"}}>
                <button className="but but-28px but-link" onClick={() => { dispatch(ReportActions.RESET_DATES()); dispatch(ReportActions.RESET_ALL_FILTERS()) }}>Clear all</button>
            </div>
        </div>
    )
}

export default FilterIndicators;