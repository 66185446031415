import React from 'react';
import { BaseComponent } from '../../../../../shared/BaseComponent';
import { iList } from '../../../../../shared/interfaces';
import Dialog, { DialogConfigSetter } from '../../../../Dialog';
import { mapObjIndexed, pipe, values } from 'ramda';
import DeleteIcon from '../../../../SVG-components/delete_outline';

import './NonUserAlerts.styles.scss';

export class NonUserAlerts extends BaseComponent<
  {
    style?: React.CSSProperties;
    recipients: iList<string>;
    toggleRecipient: (recipient: string, onOff: boolean) => any;
  },
  { newRecipient?: string }
> {
  private dialog: DialogConfigSetter;

  __remove = (recipient) => () => this.props.toggleRecipient(recipient, false);

  __change = ({ target: { value } }) => this.setState({ newRecipient: value });

  __keyDown = ({ keyCode }) => {
    if (keyCode == '13') this.__submit();
  };

  __validEmail = (str) =>
    !/(\.{2}|-{2}|_{2})/.test(str) &&
    /^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(
      str
    );

  __validPhoneNumber = (str) =>
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(str);

  __setupDialog = (callBack: () => DialogConfigSetter): void => {
    this.dialog = callBack();
  };

  __submit = () => {
    const { newRecipient = undefined } = this.state || {};

    if (typeof newRecipient === 'undefined' || newRecipient === '') {
      this.dialog?.({
        title: 'Input is empty!',
        body: 'Please, enter your email or phone number.',
        type: 'NOTIFICATION',
      });

      return;
    } else if (
      this.__validEmail(this.state.newRecipient) ||
      this.__validPhoneNumber(Number(this.state.newRecipient))
    ) {
      this.props.toggleRecipient(this.state.newRecipient, true);
      this.setState({ newRecipient: '' });
    } else {
      this.dialog?.({
        title: 'Incorrect input!',
        body: 'This should be a valid email or phone number.',
        type: 'NOTIFICATION',
      });
    }
  };

  debugRender = () => {
    const { recipients, style = {} } = this.props;
    const { newRecipient = '' } = this.state || {};

    return (
      <div
        className='non-user-alerts'
        style={{
          ...style,
        }}
      >
        <p className='non-user-alerts__header'>
          Add custom recipients phone or email
        </p>
        <div className='non-user-alerts__input'>
          <input
            value={newRecipient}
            className='mas-input'
            onChange={this.__change}
            onKeyDown={this.__keyDown}
          />
          <button
            className='btn btn-sm btn-outline-primary'
            onClick={this.__submit}
          >
            Add
          </button>
        </div>
        <div className='non-user-alerts__list'>
          <p>Email/Phone</p>
          <div>
            {pipe(
              mapObjIndexed((r, k) => {
                const isEmail = r.includes('@');
                const customRecipient = isEmail ? r : r.replace(/\D/g, '');
                return (
                  <div className='non-user-alerts__list-item' key={k}>
                    <p className='non-user-alerts__list-item__email' title={r}>
                      {customRecipient}
                    </p>
                    <p className='non-user-alerts__list-item__icon'>
                      <DeleteIcon
                        className='delete-filter'
                        onClick={this.__remove(k)}
                      />
                    </p>
                  </div>
                );
              }),
              values
            )(recipients)}
          </div>
        </div>

        <Dialog setupConfig={this.__setupDialog} />
      </div>
    );
  };
}
