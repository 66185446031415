import React, {useEffect} from "react";
import Select from "react-select";
import spacetime from "spacetime";
import {i18nTimezones, getTimezoneByValue} from "./Timezones.helper";
import {ITimezone, ITimezoneOption, Props} from './Timezones.interfaces';
import './Timezones.scss';

const Timezones = ({value, onBlur, onChange, labelStyle = "original", className, timezones = i18nTimezones, ...props}: Props) => {
    const getOptions = React.useMemo(() => {
        return Object.entries(timezones)
            .reduce((selectOptions, zone) => {
                const now = spacetime.now().goto(zone[0])
                const tz = now.timezone()

                let label = ""
                let abbrev = zone[0]
                let altName = zone[0]
                let prefix = getTimezoneByValue(zone[0]);

                label = `${prefix}`;

                selectOptions.push({
                    value: zone[0],
                    label: label,
                    offset: tz.current.offset,
                    abbrev: abbrev,
                    altName: altName,
                })

                return selectOptions
            }, [] as ITimezoneOption[])
            .sort((a: ITimezoneOption, b: ITimezoneOption) => a.offset - b.offset)
    }, [labelStyle, timezones]);

    const handleChange = (tz: ITimezone) => {
        onChange && onChange(tz)
    }

    const parseTimezone = (zone: ITimezone) => {
        if (typeof zone === "object" && zone.value && zone.label) return zone
        if (typeof zone === "string") return getOptions.find(tz => tz.value === zone)
        else if (zone.value && !zone.label) {
            return getOptions.find(tz => tz.value === zone.value)
        }
    }
    return (
        <Select
            className={className}
            value={parseTimezone(value)}
            onChange={handleChange}
            options={getOptions}
            menuPosition={'fixed'}
            onBlur={onBlur}
            {...props}
        />
    )
}

export default Timezones;