import React, {useState} from 'react';
import Portal from '../Portal';
import DayPicker from 'react-day-picker';
import {compose} from '@bem-react/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button as ButtonPresenter} from '../../BemComponents/NewButton/Button';
import {withButtonTypeLink} from '../../BemComponents/NewButton/_type/Button_type_link';
import {withButtonThemeOutline} from '../../BemComponents/NewButton/_theme/Button_theme_outline';
import {withButtonVariantContained} from '../../BemComponents/NewButton/_variant/Button_variant_contained';

import './styles.scss';

const Button = compose(
    withButtonThemeOutline,
    withButtonVariantContained,
    withButtonTypeLink,
)(ButtonPresenter);

const DialogDatePicker = ({closeHandler, dateHandler}): JSX.Element => {
    const startEl = React.useRef<HTMLSpanElement>(null);
    const [date, setDate] = useState(null);

    const stopPropagation = (event): void => event.stopPropagation();
    const submit = (): void => {
        dateHandler(date);
        closeHandler();
    };

    return (
        <Portal>
            <div className={'modal-overlay open'} onClick={closeHandler}>
                <div className="modal-window" tabIndex={-1} onClick={stopPropagation}>
                    <span tabIndex={1} ref={startEl} />
                    <div className="modal-header">
                        <div className="modal-title">Choose the exact date:</div>
                        <span onClick={closeHandler}><FontAwesomeIcon icon="times" /></span>
                    </div>
                    <div className="modal-body">
                        <DayPicker
                            initialMonth={new Date()}
                            onDayClick={(date): void => setDate(date)}
                            selectedDays={[date]}
                        />
                    </div>
                    <div className="modal-footer">
                        <Button tabIndex={2} theme="outline" onClick={closeHandler}>CANCEL</Button>
                        <Button tabIndex={3} theme="outline" variant="contained" onClick={(): void => submit()}>OK</Button>
                    </div>
                    <span tabIndex={4} onFocus={(): void => startEl.current?.focus()} />
                </div>
            </div>
        </Portal>
    );
};

export default DialogDatePicker;
