export const ACTIONS = {
  STATUS_CAMERA_ONLINE: "STATUS_CAMERA_ONLINE",
  AVAILABLE_TIME: "AVAILABLE_TIME",
  OPEN_MODAL: "OPEN_MODAL",
  TAKE_DEVICE_INFO: "TAKE_DEVICE_INFO",
  RESET_CAMERAS_STATE: "RESET_CAMERAS_STATE",
  RESET_ALERT_DATE: "RESET_ALERT_DATE"
};

export const openModal = ({ key, value }) => ({
  type: ACTIONS.OPEN_MODAL,
  payload: { key, value },
});


export const takeDeviceInfo = ({ id, alertDate, alertTime }) => ({
  type: ACTIONS.TAKE_DEVICE_INFO,
  payload: { id, alertDate, alertTime },
});

export const statusCameraOnline = (payload) => ({
  type: ACTIONS.STATUS_CAMERA_ONLINE,
  payload,
});

export const cameraAvailableTime = (payload) => ({
  type: ACTIONS.AVAILABLE_TIME,
  payload,
});

export const resetAlertDate = () => ({
  type: ACTIONS.RESET_ALERT_DATE,
});

export const resetCamerasState = () => ({
  type: ACTIONS.RESET_CAMERAS_STATE,
});