import { CircularProgress } from "@material-ui/core";
import React from "react";

type ILoader = {
  color?: "primary" | "secondary" | "inherit";
  size?: number;
};

const Loader = ({ color, size }: ILoader) => {
  return (
    <div className="loader">
      <CircularProgress color={color} size={size} />
    </div>
  );
};

export default Loader;
