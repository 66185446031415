import {FenceGroup, iIcon, iLocation} from '../../../../shared/interfaces';
import { geocode, RequestType, setDefaults } from 'react-geocode';

import { FileFormat } from '../../../../shared/parsers';
import { IconName } from '@fortawesome/fontawesome-free-regular';
import { iCreateFence } from './../../../../shared/db/fences-db';
import toHex from 'colornames';

const convertType = (value: 'Ellipses' | 'Rectangle') => {
  const fenceTypes = {
    'Ellipses': 1,
    'Rectangle': 0
  };
  return fenceTypes[value];
}

type BoolString = 'TRUE' | 'FALSE';
enum FenceTypeName {
  ELLIPSES = 'Ellipses',
  RECTANGLE = 'Rectangle'
};

const booleanStringToBoolean = (value: BoolString): boolean => {
  switch (value) {
    case 'TRUE': return true;
    case 'FALSE':
    default:
      return false
  }
}

const convertAddressToLatLon = (address) => {
  // @ts-ignore
  setDefaults({
    // key: process.env.GMAP_API_KEY,
    key: 'AIzaSyBsNvkRiUmkDgv71-TaWfubQnf5E1niYXY',
    language: 'en',
    region: 'us',
  });

 return geocode(RequestType.ADDRESS, address)
      .then(({ results }) => results)
      // https://www.npmjs.com/package/react-geocode
}

const createCenterObj = async (obj: ICSVFileFence) => {
  if (obj['Center Lat'] && obj['Center Lng']) {
    return {
      'lat': Number(obj['Center Lat'][0]),
      'lng':  Number(obj['Center Lng'][0]),
    }
  } else if (obj['Physical Address']) {
    try {
      const results = await convertAddressToLatLon(obj['Physical Address'][0]);
      const { lat, lng } = results[0].geometry.location;
      return {
        lat,
        lng,
      };
    } catch (err) {
      throw err;
    }
  }
}

export type ICSVFileFence = {
  'Label': string[],
  'Type': FenceTypeName[],
  'Center Lat': string[],
  'Center Lng': string[],
  'Size': string[],
  'Waypoint': BoolString[],
  'Icon': string[],
  'Color': string[],
  'Description': string[],
  'Enter Alert': BoolString[],
  'Enter Alert Endpoints': string[],
  'Exit Alert': BoolString[],
  'Exit Alert Endpoints': string[],
  'Hide Name': BoolString[],
  'Hide Shadows': BoolString[],
  'Hide Icon': BoolString[],
  'Physical Address': string,
};

export const transformFence = async (obj: ICSVFileFence): Promise<iCreateFence> => ({
  'label': obj['Label'][0],
  'name': obj['Label'][0],
  'waypoint': booleanStringToBoolean(obj['Waypoint'][0]),
  'region': {
    'center': await createCenterObj(obj),
    'name': obj['Label'][0],
    'editable': true,
    'width': Number(obj['Size'][0]),
    'height': Number(obj['Size'][0]),
    'shape': convertType(obj['Type'][0] || 'Ellipses'),
    'hideName': booleanStringToBoolean(obj['Hide Name'][0]),
    'hideShadows': booleanStringToBoolean(obj['Hide Shadows'][0]),
    'hideIcon': booleanStringToBoolean(obj['Hide Icon'][0])
  },
  'icon': { fa: {
    iconName: obj['Icon'][0].toLowerCase() as IconName || 'car',
    prefix: 'fas'
  }} as iIcon,
  // TODO: [ DATA CONSISTENCY ] need to look avery iIcon usage;
  // TODO: redo google map marker icon handling
  // for some reasone there is path value in each icon
  // but for representing icon is enought prefix and icon name property
  'color': toHex(obj['Color'][0] || 'Black'),
  'fenceGroup': FenceGroup.FENCE,
  'exitAlertEndpoint': obj['Exit Alert Endpoints']?.[0]? {
    type: 'custom',
    value: obj['Exit Alert Endpoints']
  } : null,
  'description': obj['Description'][0],
  'enterAlertEndpoint': obj['Enter Alert Endpoints']?.[0]? {
    type: 'custom',
    value: obj['Enter Alert Endpoints']
  } : null,
  'exitAlert': booleanStringToBoolean(obj['Exit Alert'][0]),
  'enterAlert': booleanStringToBoolean(obj['Enter Alert'][0])
});



export const createFenceTransformFrom = (type: FileFormat): (obj: any) => Promise<iCreateFence> => {
  switch (type) {
    case FileFormat.CSV:
      return transformFence
  }
}
