import { useEffect, useState } from "react";

interface UsePaginationType<T> {
    data: T[],
    pageNumber: number,
    pageSize: number,
}

interface UsePaginationReturnType<T> {
    page: T[];
    pageNumber;
    goToPage: (page: number) => void;
}

const usePagination = <T>({
    data,
    pageNumber,
    pageSize,
}: UsePaginationType<T>): UsePaginationReturnType<T> => {
    const [page, setPage] = useState(pageNumber);

    const start = (page - 1) * pageSize;
    const end = (page - 1) * pageSize + pageSize;

    return {
        page: data.slice(start, end),
        pageNumber: page,
        goToPage: setPage
    }
}

export default usePagination;