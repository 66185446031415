import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { addIndex, equals, map, pipe, splitEvery, toPairs } from 'ramda';
import { faPlus } from '@fortawesome/fontawesome-free-solid';
import classNames from 'classnames';

import { ACL, UserCan } from '../../../shared/constants';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import { UserAuth, iFullStoreState, iList, iPerson } from '../../../shared/interfaces';
import { isMobile } from '../../../shared/helpers';
import { history } from '../../../stores/store';
import { PersonTile } from './PersonTile';

import styles from './PeoplePage.module.scss';
import {localStorage} from "../../../shared/storage";

export default function PeoplePage() {
  enum SortOptions {
    ASCBYFIRSTNAME = 'ascByFirstName',
    DESCBYFIRSTNAME = 'descByFirstName',
    ASCBYLASTNAME = 'ascByLastName',
    DESCBYLASTNAME = 'descByLastName',
  }
  const sortOptions = [
    { value: SortOptions.ASCBYFIRSTNAME, label: 'ASC by First Name' },
    { value: SortOptions.DESCBYFIRSTNAME, label: 'DESC by First Name' },
    { value: SortOptions.ASCBYLASTNAME, label: 'ASC by Last Name' },
    { value: SortOptions.DESCBYLASTNAME, label: 'DESC by Last Name' },
  ];
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  const handleSortChange = (selectedOption) => {
    setSortOption(selectedOption)
  };

  const people = useSelector<iFullStoreState, iList<iPerson>>(
    (s) => s.general.people,
    (l, r) => equals(l, r)
  );

  const loginToken = localStorage.get('login-init-user');

  const person = useSelector<iFullStoreState, iPerson>(state => state.general.people[loginToken.uid]);

  const selectPeopleByIds = (state, ids) => {
    const people = state.general.people;
    const selectedPeople = {};

    if(!ids || !ids.length){
      return selectedPeople;
    }

    for (const id of ids) {
      if (people[id]) {
        selectedPeople[id] = people[id];
      }
    }

    return selectedPeople;
  }

  const compareByNameAscending = (a, b) => {
    const nameA = (a.displayName?.split(' ').length >= 1) ? a.displayName?.split(' ')[0].toLowerCase() : a.displayName?.toLowerCase();
    const nameB = (b.displayName?.split(' ').length >= 1) ? b.displayName?.split(' ')[0].toLowerCase() : b.displayName?.toLowerCase();

    if (nameA < nameB) return -1;
    return 0;
  };

  const compareByNameDescending = (a, b) => {
    const nameA = (a.displayName?.split(' ').length >= 1) ? a.displayName?.split(' ')[0].toLowerCase() : a.displayName?.toLowerCase();
    const nameB = (b.displayName?.split(' ').length >= 1) ? b.displayName?.split(' ')[0].toLowerCase() : b.displayName?.toLowerCase();
    if (nameA < nameB) return 1; 
    if (nameA > nameB) return -1; 
    return 0;
  };

  const compareByLastNameAscending = (a, b) => {
    const nameA = (a.displayName?.split(' ').length > 1) ? a.displayName?.split(' ')[1].toLowerCase() : a.displayName?.toLowerCase();
    const nameB = (b.displayName?.split(' ').length > 1) ? b.displayName?.split(' ')[1].toLowerCase() : b.displayName?.toLowerCase();

    if (nameA < nameB) return -1; 

    return 0;
  };

  const compareByLastNameDescending = (a, b) => {
    const nameA = (a.displayName?.split(' ').length > 1) ? a.displayName?.split(' ')[1].toLowerCase() : a.displayName?.toLowerCase();
    const nameB = (b.displayName?.split(' ').length > 1) ? b.displayName?.split(' ')[1].toLowerCase() : b.displayName?.toLowerCase();
    if (nameA < nameB) return 1; 
    if (nameA > nameB) return -1; 
  };

  const getSortFunction = (sortOption) => {
      switch (sortOption.value) {
          case SortOptions.ASCBYFIRSTNAME:
              return compareByNameAscending;
          case SortOptions.DESCBYFIRSTNAME:
              return compareByNameDescending;
          case SortOptions.ASCBYLASTNAME:
              return compareByLastNameAscending;
          case SortOptions.DESCBYLASTNAME:
              return compareByLastNameDescending;
          default:
              return compareByNameDescending;
      }
  };

  const [isActiveStatus, setIsActiveStatus] = React.useState(true);

  const userCanDo = useSelector<iFullStoreState, Array<string>>(
    (s) => s.auth.user?.acl?.can
  );

  const userIsAdmin = ACL.check(UserCan.DO_ANYTHING, userCanDo);
  const userIsSubAdmin = ACL.check(UserCan.SUB_ADMIN, userCanDo);

  const authUser = useSelector<iFullStoreState, UserAuth>((s) => s.auth.user);

  const sourceArray: iPerson[] = useSelector(state => {
    if (userIsSubAdmin && !userIsAdmin) {
      const subUsersFromLoginToken = loginToken.subUsers || [];
      const subUsers = selectPeopleByIds(state, [...subUsersFromLoginToken, authUser.uid]);
      return Object.values(subUsers);
    } else {
      return Object.values(people);
    }
  });

  const sortedArray = sourceArray
    .sort(getSortFunction(sortOption))
    .map((el) => el);

  const [activePeople, disabledPeople] = React.useMemo(() => {
    const active = {} as iList<iPerson>;
    const disabled = {} as iList<iPerson>;

    Object.entries(sortedArray).forEach(([id, person]) => {
      if (person?.disabled) {
        disabled[id] = person;
      } else {
        active[id] = person;
      }
    });

    return [active, disabled]
  }, [sortedArray])

  useEffect(() => {
    if (!userIsAdmin && !userIsSubAdmin) {
      history.push(`person/${authUser.uid}`);
    }
  }, []);

  const moveToAddSubUserPage = useCallback(() => {
    history.push('/create-sub-user');
  }, []);

  const getHeaderAction = () => {
    if (!ACL.check(UserCan.DO_ANYTHING, userCanDo)) {
      return null;
    }

    return {
      fa: faPlus,
      click: moveToAddSubUserPage,
      title: 'Add sub user',
    };
  };

  const onSelectActiveStatus = useCallback(() => setIsActiveStatus(true), []);

  const onSelectDisabledStatus = useCallback(() => setIsActiveStatus(false), []);

  return (
    <DashboardBlock>
      <ModalHeader
        title='People'
        action={getHeaderAction()}
        showSelect={true}
        options={sortOptions}
        option={sortOption}
        handleChange={handleSortChange}
        selectDescription="Sort by people's names:"
      />
      <div className={styles.peopleTypesSwitcher}>
        <div 
        className={classNames({
          [styles.typeSelector]: true,
          [styles.activeType]: isActiveStatus
        })} 
        onClick={onSelectActiveStatus}>
          Active
        </div>
        <div 
        className={classNames({
          [styles.typeSelector]: true,
          [styles.activeType]: !isActiveStatus
        })} 
        onClick={onSelectDisabledStatus}>
          Disabled
        </div>
      </div>

      <div
        className={styles.peopleListWrapper}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        {pipe(
          toPairs,
          map(([id, person]) => <PersonTile key={id} person={person} />),
          splitEvery(isMobile ? Infinity : 2) as any,
          addIndex(map)((g, idx) => (
            <div
              className={styles.peopleListDirection}
              key={idx}
              style={{ display: 'flex', flex: 'wrap' }}
            >
              {g}
            </div>
          ))
        )(isActiveStatus ? activePeople : disabledPeople)}
      </div>
    </DashboardBlock>
  );
}
