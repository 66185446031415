import React from "react";
import {BaseComponent} from "../../../../../shared/BaseComponent";
import {containerCss} from "./FenceBodyContainer.helpers";

const FenceBodyContainer = (props) => {
    const debugRender = () => {
        return <div style={containerCss}>{props.children}</div>;
    };

    return debugRender();
};

export default FenceBodyContainer;