import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {equals} from 'ramda';
import moment from 'moment';
import Select from 'react-select';

import Dialog, {DialogConfigSetter} from '../../Dialog';
import {FenceBodyLayout} from '../tab-bodies/BodyLayout/FenceBodyLayout/FenceBodyLayout';
import DialogDataPicker from '../../DialogDataPicker';
import CalendarSvg from '../../SVG-components/calendar';
import ChevronLeft from '../../SVG-components/chevron_left';
import DeleteIcon from '../../SVG-components/delete_outline';

import {keyGen} from '../../../shared/firebase';
import {fenceHistory} from '../../../stores/store';
import {getFilter, updateFilter} from '../../../shared/db/filters-db';
import {iFullStoreState, UserAuth} from '../../../shared/interfaces';
import {actions, customSelectStyles, dateProperties, messageProperties, properties, speedProperties} from './FilterEdit.constants';

import './FilterEdit.scss';

const FilterEdit = (props : {isVisible: boolean, toggleVisible: (visible: boolean) => void }): JSX.Element => {
    const authUser = useSelector<iFullStoreState, UserAuth>((s) => s.auth.user, (l, r) => equals(l, r));

    const {filterId} = useParams();

    const [filter, setFilter] = useState({
        action: null,
        property: null,
        dateProperty: null,
        speedProperty: null,
        messageProperty: null,
        street: null,
        city: null,
        state: null,
        zip: null,
        speed: null,
        hours: null,
        date: null,
        dateFrom: null,
        dateTo: null
    });

    const [showExactDatePicker, setShowExactDatePicker] = useState(false);
    const [showRangeFromDatePicker, setShowRangeFromDatePicker] = useState(false);
    const [showRangeToDatePicker, setShowRangeToDatePicker] = useState(false);

    const handleActionChange = (selectedOption): void => setFilter({...filter, action: selectedOption});
    const handlePropertyChange = (selectedOption): void => setFilter({...filter, property: selectedOption});
    const handleDataPropertyChange = (selectedOption): void => setFilter({...filter, dateProperty: selectedOption});
    const handleSpeedPropertyChange = (selectedOption): void => setFilter({...filter, speedProperty: selectedOption});
    const handleMessagePropertyChange = (selectedOption): void => setFilter({...filter, messageProperty: selectedOption});

    useEffect(() => {
        if (filterId !== 'new-filter') getFilter(filterId).then((res) => setFilter({...filter, ...res}));
    }, []);

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter): DialogConfigSetter => dialogRef.current = callBack();

    const getDialog = (): void => {
        const dialog = dialogRef.current;

        dialog?.({
            title: 'Incorrect Input',
            body: 'You have to fill all fields!',
            type: 'NOTIFICATION',
        });

    };

    const getFilterLabel = (filter): string => {
        const filterWithoutEmptyFields = Object.keys(filter).reduce((acc, rec) => {
            return filter[rec] ? {...acc, [rec]: filter[rec]} : acc;
        }, {});

        return Object.keys(filterWithoutEmptyFields).reduce((acc, rec) => {
            switch (rec) {
            case 'action': return `${acc} ${filter.action.label}`;
            case 'dateProperty': return `${acc} ${filter.dateProperty.label.toLowerCase()}`;
            case 'speedProperty': return `${acc} ${filter.speedProperty.label.toLowerCase()}`;
            case 'messageProperty': return `${acc} ${filter.messageProperty.label}`;
            case 'street': return `${acc} street - ${filter.street}`;
            case 'city': return `${acc} city - ${filter.city}`;
            case 'state': return `${acc} state - ${filter.state}`;
            case 'zip': return `${acc} ZIP code - ${filter.zip}`;
            case 'speed': return `${acc} speed - ${filter.speed}`;
            case 'hours': return `${acc} - ${filter.hours}`;
            case 'date': return `${acc} - ${moment(filter.date).format('DD/MM/YYYY')}`;
            case 'dateFrom': return `${acc} - ${moment(filter.dateFrom).format('DD/MM/YYYY')}`;
            case 'dateTo': return `${acc} ~ ${moment(filter.dateTo).format('DD/MM/YYYY')}`;
            default: return acc;
            }
        }, '');
    };

    const save = async (): Promise<any> => {
        const value = filter.property?.value;

        if (!filter.action || !filter.property) {
            getDialog();

            return;
        } else if (value === 'address' && (!filter.street || !filter.city || !filter.state || !filter.zip)){
            getDialog();

            return;
        } else if (value === 'speed' && (!filter.speedProperty || !filter.speed)) {
            getDialog();

            return;
        }else if (value === 'message' && !filter.messageProperty) {
            getDialog();

            return;
        }else if (value === 'date') {
            if (!filter.dateProperty ) {
                getDialog();

                return;
            } else if (!filter.hours && !filter.date && (!filter.dateTo || !filter.dateFrom)) {
                getDialog();

                return;
            }
        }else if ((value === 'city' && !filter.city) || (value === 'state' && !filter.state) || (value === 'zip' && !filter.zip)) {
            getDialog();

            return;
        }

        const resultFilter = {...filter, label: getFilterLabel(filter), selected: false};

        await updateFilter(authUser)( filterId === 'new-filter' ? keyGen() : filterId, resultFilter);
        fenceHistory.replace('/filters');
    };

    const deleteFilter = (): void => {
        const dialog = dialogRef.current;
        dialog?.({
            title: 'Confirm',
            body: 'Are you sure you want to delete the filter? This action cannot be undone.'
        }).then(async (confirmed) => {
            if (!confirmed) return;

            await updateFilter(authUser)( filterId, null);
            fenceHistory.replace('/filters');
        });
    };

    return (
        <>
            <FenceBodyLayout isVisible={props.isVisible} toggleVisible={props.toggleVisible} needFencesTab={!filterId}>
                <div className='FilterEdit'>

                    <div className='TittleBox'>
                        <ChevronLeft className='SvgElement TitleBox-Icon' onClick={(): void => fenceHistory.replace('/filters')}/>
                        <span >
                            {filterId === 'new-filter' ? 'New Filter' : 'Filter'}
                        </span>
                        { filterId === 'new-filter' ? <span /> : (<DeleteIcon
                            className='TitleBox-Icon SvgElement-trash'
                            onClick={(): void => deleteFilter()}
                        />)}

                    </div>
                    <div className='EditFilterBox'>
                        <div className='SelectBox'>
                            <span>Action</span>
                            <Select
                                styles={customSelectStyles}
                                options={actions}
                                value={filter.action}
                                onChange={handleActionChange}
                            />
                        </div>
                        <div className='SelectBox'>
                            <span>Property</span>
                            <Select
                                styles={customSelectStyles}
                                options={properties}
                                value={filter.property}
                                onChange={handlePropertyChange}
                            />
                        </div>

                        {filter.property?.value === 'date' &&
                        <>
                            <div className='SelectBox'>
                                <span>Type</span>
                                <Select
                                    styles={customSelectStyles}
                                    options={dateProperties}
                                    value={filter.dateProperty}
                                    onChange={handleDataPropertyChange}
                                />
                            </div>

                            {filter.dateProperty?.value === 'hour' &&
                            <div className='EditForm'>
                                <span className='LightTitle'>Value</span>
                                <input
                                    className='LabelInput'
                                    defaultValue={filter.hours || ''}
                                    name='label'
                                    onChange={(e): void => setFilter({...filter, hours: e.target.value})}
                                />
                            </div>
                            }

                            {filter.dateProperty?.value === 'exactDate' &&
                            <div className='EditForm'>
                                <span className='LightTitle'>Date pick:</span>
                                <div className='DataPickerBox' onClick={(): void => setShowExactDatePicker(!showExactDatePicker)}>
                                    <span>{ filter.date ? moment(filter.date).format('DD/MM/YYYY') : 'DD/MM/YYYY'}</span>
                                    <CalendarSvg/>
                                </div>
                            </div>
                            }

                            {filter.dateProperty?.value === 'period' &&
                            <div className='EditForm'>
                                <span className='LightTitle'>From</span>
                                <div className='DataPickerBox' onClick={(): void => setShowRangeFromDatePicker(!showRangeFromDatePicker)}>
                                    <span>{filter.dateFrom ? moment(filter.dateFrom).format('DD/MM/YYYY') : 'DD/MM/YYYY'}</span>
                                    <CalendarSvg/>
                                </div>
                                <span className='LightTitle'>To</span>
                                <div className='DataPickerBox' onClick={(): void => setShowRangeToDatePicker(!showRangeToDatePicker)}>
                                    <span>{ filter.dateTo ? moment(filter.dateTo).format('DD/MM/YYYY') : 'DD/MM/YYYY'}</span>
                                    <CalendarSvg/>
                                </div>
                            </div>
                            }
                        </>
                        }

                        {filter.property?.value === 'address' &&
                        <div className='EditForm'>
                            <span className='LightTitle'>Street</span>
                            <input
                                className='LabelInput'
                                defaultValue={filter.street || ''}
                                name='state'
                                onChange={(e): void => setFilter({...filter, street: e.target.value})}
                            />
                        </div>
                        }
                        {(filter.property?.value === 'city' || filter.property?.value === 'address') &&
                        <div className='EditForm'>
                            <span className='LightTitle'>City</span>
                            <input
                                className='LabelInput'
                                defaultValue={filter.city || ''}
                                name='city'
                                onChange={(e): void => setFilter({...filter, city: e.target.value})}
                            />
                        </div>
                        }
                        {(filter.property?.value === 'state' || filter.property?.value === 'address') &&
                        <div className='EditForm'>
                            <span className='LightTitle'>State</span>
                            <input
                                className='LabelInput'
                                defaultValue={filter.state || ''}
                                name='state'
                                onChange={(e): void => setFilter({...filter, state: e.target.value})}
                            />
                        </div>
                        }
                        {(filter.property?.value === 'zip' || filter.property?.value === 'address') &&
                        <div className='EditForm'>
                            <span className='LightTitle'>ZIP</span>
                            <input
                                className='LabelInput'
                                defaultValue={filter.zip || ''}
                                name='state'
                                onChange={(e): void => setFilter({...filter, zip: e.target.value})}
                            />
                        </div>
                        }

                        {filter.property?.value === 'speed' &&
                        <>
                            <div className='SelectBox'>
                                <span>Type</span>
                                <Select
                                    styles={customSelectStyles}
                                    options={speedProperties}
                                    value={filter.speedProperty}
                                    onChange={handleSpeedPropertyChange}
                                />
                            </div>
                            {filter.speedProperty &&
                            <div className='EditForm'>
                                <span className='LightTitle'>Value</span>
                                <input
                                    className='LabelInput'
                                    defaultValue={filter.speed || ''}
                                    name='speed'
                                    onChange={(e): void => setFilter({...filter, speed: e.target.value})}
                                />
                            </div>}
                        </>
                        }

                        {filter.property?.value === 'message' &&
                        <div className='SelectBox'>
                            <span>Type</span>
                            <Select
                                styles={customSelectStyles}
                                options={messageProperties}
                                value={filter.messageProperty}
                                onChange={handleMessagePropertyChange}
                            />
                        </div>
                        }

                        { showExactDatePicker &&
                        <DialogDataPicker
                            closeHandler={(): void => setShowExactDatePicker(false)}
                            dateHandler={ (date): void => setFilter({...filter, date: date})}
                        />}
                        { showRangeFromDatePicker &&
                        <DialogDataPicker
                            closeHandler={(): void => setShowRangeFromDatePicker(false)}
                            dateHandler={ (date): void => setFilter({...filter, dateFrom: date})}
                        />}
                        { showRangeToDatePicker &&
                        <DialogDataPicker
                            closeHandler={(): void => setShowRangeToDatePicker(false)}
                            dateHandler={ (date): void => setFilter({...filter, dateTo: date})}
                        />}
                    </div>

                    <div className='ButtonsBlock'>
                        <button onClick={(): void => fenceHistory.replace('/filters')} className='WhiteButton'>Cancel</button>
                        <button onClick={(): Promise<any> => save()} className='BlackButton'>Save</button>
                    </div>
                </div>
                <Dialog setupConfig={setupDialog}/>
            </FenceBodyLayout>
        </>
    );
};

export default FilterEdit;

