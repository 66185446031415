import React from 'react';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import { BaseComponent } from '../../../../shared/BaseComponent';
import { ACL, UserCan } from '../../../../shared/constants';
import { getPersonAllowedSee } from '../../../../shared/db/general-db';
import { iTag, iDeviceDetails } from '../../../../shared/interfaces';
import { DevicesDetailsContainer } from '../../../../stores/reducers/devicesData';
import { FaLink } from '../../../elements/fa';
import { TableSimple } from '../../../elements/plain-table';
import LocalPersonTile from '../LocalPeopleTile';
import { pathOr, pipe, values, uniq, join, filter } from 'ramda';
import * as structure from '../../../../shared/interfaces';

import styles from '../PersonTabPage.module.scss';

export class VisibleDevicesTile extends BaseComponent<
  {
    userCanDo;
    personId;
    tags: structure.iList<iTag>;
    devicesDetails: DevicesDetailsContainer;
  },
  { allowed: structure.iItemsAllowed; devices: iDeviceDetails[] }
> {
  async componentDidMount() {
    const { personId } = this.fullProps;
    this.setState({ allowed: await getPersonAllowedSee(personId) });
  }

  _source = (deviceId) =>
    pipe(
      pathOr({}, ['device', deviceId]),
      values,
      uniq,
      filter((item) => typeof item !== 'boolean'),
      join(' & ')
    )(this.state.allowed);

  debugRender = () => {
    const { allowed = {} as any } = this.state || {};
    const { userCanDo, personId, devicesDetails } = this.props;

    const hideTable =
      allowed?.device && Object.keys(allowed.device).length === 0;
    return (
      <LocalPersonTile
        collapsible
        initialCollapsed
        title='Visible Devices'
        loading={!this.state || !this.state.allowed}
        action={
          !ACL.check(UserCan.DO_ANYTHING, userCanDo) ? undefined : (
            <FaLink
              to={`/person/${personId}/visible-devices`}
              icon={faPencilAlt}
              style={{ color: '#fff' }}
            />
          )
        }
      >
        {!this.state || !this.state.allowed || hideTable ? null : (
          <TableSimple
            sm
            striped
            headers={['Device', 'Source']}
            style={{ fontSize: 11 }}
          >
            {Object.keys(allowed.device || {})
              .filter((id) => devicesDetails.has(id))
              .map((id) => devicesDetails.get(id))
              .map((device) => (
                <tr key={device!.id}>
                  <td className={styles.tableItem}>{device!.name}</td>
                  <td>{this._source(device!.id)}</td>
                </tr>
              ))}
          </TableSimple>
        )}
      </LocalPersonTile>
    );
  };
}
