import React, {useState} from "react";
import {useTable, usePagination} from "react-table";
// import {ReactComponent as DeleteOutlineSvg} from "../../../../../assets/svg/delete_outline.svg";
import "./maintenanceTable.scss";
import {useSelector} from "react-redux";
import {iFullStoreState, iMaintenanceTableData} from "../../../../../shared/interfaces";
import {DashboardSize} from "../../../../../stores/reducers/dashboardInfo";
import Paginator from "../../../../Paginator";
import PerfectScrollbar from "react-perfect-scrollbar";

const MaintenanceTable = ({columns/*: oldColumns*/, data, /*hasDeleteButton, removeCallBack, deleteText = "",*/ className = "", hasPagination = false, paginationClass = "", pageSize = 5, hasScroll}: iMaintenanceTableData) => {
// const MaintenanceTable = ({columns: oldColumns, data: oldData, hasDeleteButton = false, removeCallBack, deleteText = "", className = "", hasPagination = false, pageSize = 5}) => {
    const [initialPage, setInitialPage] = useState(1);
    // const [data, setData] = useState(oldData);
    // const [columns, setColumns] = useState(oldColumns);
    const tableConfigsWithoutPagination = [{
        columns,
        data,
        initialState: { pageIndex: initialPage - 1, pageSize },
    }]
    // const [tableConfigs, setTableConfigs] = useState(tableConfigsWithoutPagination)
    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);

    // add delete button
    // useEffect(() => {
    //     if (!hasDeleteButton) return;

    //     const deleteButton = [{
    //         Header: deleteText,
    //         accessor: "deleteButton",
    //     }];
    //     // console.log('deleteButton', deleteButton);

    //     // use init columns
    //     setColumns([...oldColumns, ...deleteButton]); // rerender once at 3-5s

    //     const dataWithDelete = [...data].map(itm => {
    //         const newItm = {...itm};

    //         newItm["deleteButton"] = (
    //                     <DeleteOutlineSvg onClick={() => {
    //                         removeCallBack(itm)
    //                     }}/>
    //                 );
    //         return newItm;
    //     });

    //     setData(dataWithDelete);
    // }, []);

    // pagination not work with useEffect (only like here)
    /*useEffect(() => {
        if (!hasPagination) return;

        const paginationOption = [usePagination];
        // use init table configs
        const newTableConfigs = [...tableConfigsWithoutPagination, ...paginationOption];
        console.log(newTableConfigs);
        console.log(...newTableConfigs);

        setTableConfigs([...newTableConfigs]);
    }, []);*/

    const paginationOption = hasPagination ? [usePagination] : [];
    const tableProps = [...tableConfigsWithoutPagination, ...paginationOption];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        pageCount,
        gotoPage,
        state: {pageIndex}
    } = useTable(...tableProps);

    const dataRow = hasPagination && page ? page : rows;

    const handlePageChange = (newPage) => {
        if (newPage - 1 !== pageIndex) {
            setInitialPage(newPage);
            gotoPage(newPage - 1);
        }
    }

    const JSXTable = () => {
        return (
            <table {...getTableProps()} className={`maintenance-table ${className}`}>
                <thead className={`maintenance-table-head ${className ? `${className}-head` : ""}`}>
                {headerGroups.map(headerGroup => {
                    const defaultClass = `maintenance-table-head-row ${DashboardSize.OPEN === size ? "maintenance-table-head-row-small" : ""}`;
                    return (
                        <tr {...headerGroup.getHeaderGroupProps()} className={defaultClass}>
                            {headerGroup.headers.map(column => {
                                    const defaultClass = `maintenance-table-head-row-itm ${DashboardSize.OPEN === size ? "maintenance-table-head-row-itm-small" : ""}`;
                                    const columnClass = className ? `${className}-head-row-itm` : "";
                                    const personalClass = column?.id ? `${column?.id}${DashboardSize.OPEN === size ? ` ${column?.id}-small` : ""}` : "";
                                    return (
                                        <th {...column.getHeaderProps()}
                                            className={`${defaultClass}${columnClass ? ` ${columnClass}` : ""}${personalClass ? ` ${personalClass}` : ""}`}>
                                            {column.render("Header")}
                                        </th>
                                    )
                                }
                            )}
                        </tr>
                    )
                })}
                </thead>
                <tbody {...getTableBodyProps()}
                       className={`maintenance-table-body ${className ? `${className}-body` : ""}`}>
                {dataRow.map((row) => {
                    prepareRow(row)
                    const defaultClass = `maintenance-table-body-row ${DashboardSize.OPEN === size ? "maintenance-table-body-row-small" : ""}`;
                    return (
                        <tr {...row.getRowProps()} className={defaultClass}>
                            {row.cells.map(cell => {
                                const defaultClass = `maintenance-table-body-row-itm ${DashboardSize.OPEN === size ? "maintenance-table-body-row-itm-small" : ""}`;
                                const cellColumnClass = className ? `${className}-body-row-itm` : "";
                                const personalClass = cell?.column?.id ? `${cell?.column?.id}${DashboardSize.OPEN === size ? ` ${cell?.column?.id}-small` : ""}` : "";
                                return (
                                    <td {...cell.getCellProps()}
                                        className={`${defaultClass}${cellColumnClass ? ` ${cellColumnClass}` : ""}${personalClass ? ` ${personalClass}` : ""}`}>
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    };

    return (
        <div className="wrapper-table">
            {hasScroll ?
                <PerfectScrollbar className={`${className}-scroll`}>
                    <JSXTable />
                </PerfectScrollbar>
                : <JSXTable />
            }
            {
                hasPagination ? (
                    <div className={`maintenance-table__pagination ${paginationClass}`}>
                        <Paginator
                            current={pageIndex + 1}
                            total={pageCount}
                            onPageChange={handlePageChange}
                        />
                    </div>
                    )
                    : null
            }
    </div>
    );
};

export default MaintenanceTable;
