import React, {useEffect, useState} from "react";
import { IAllPlans, Iinputs } from "../../../../interfaces";
import {devicePlanById} from "../../../../BillingApi/billingApiRequests";
import PlansForm from "../../../PlansForm/PlansForm";
import {useSelector} from "react-redux";
import {iReducersState} from "../../../../../../../stores/reducers";
import CreditCard from "../../../../../../CreditCard";

const SwitchToBasicModal = ({ activationDevice, row, setShowConfirmation }) => {
    const [loader, setLoader] = useState(false);
    const [allPlans, setAllPlans] = useState<Array<IAllPlans>>([]);
    const [inputs, setInputs] = useState<Iinputs>({
        name: "",
        surname: "",
        plan_id: "",
        period: row?.plan_description && row?.plan_description.split("-")[1],
        card_id: "",
    });
    const [selectedPlan, setSelectedPlan] = useState({
        planType: 'Basic',
    });
    const [selectedCard, setSelectedCard] = useState(null)
    const basicPlan = allPlans.find(item => item.planType.toLowerCase() === 'basic');
    const { token, creditCard, bankAccount } = useSelector(
        (state: iReducersState) => state.billing
    );

    useEffect(() => {
        if (activationDevice.device_ID) {
            setLoader(true);
            devicePlanById({
                deviceId: activationDevice.device_ID,
            })
                .then((res) => {
                    const { plan, devicePlans } = res.data;
                    setAllPlans(plan);
                })
                .catch((err) => console.log("devicePlanById ===>", err))
                .finally(() => setLoader(false));
        }
    }, [activationDevice.device_ID]);

    useEffect(() => {
        if (row) {
            setLoader(true);
            devicePlanById({
                deviceId: row?.id,
            })
                .then((res) => {
                    const { plan, devicePlans } = res.data;
                    setAllPlans(plan);
                })
                .catch((err) => console.log("err", err))
                .finally(() => setLoader(true));
        }
    }, [row?.id, token.access_token]);

    return (
        <div>
            <div>
                <h4>Select a payment method</h4>
                {bankAccount && bankAccount.length > 0 && (
                    <CreditCard
                        type={bankAccount[0].type}
                        primary={true}
                        creditInfo={bankAccount[0]}
                        onSelectCard={() => setSelectedCard(bankAccount[0])}
                        selectedCardId={selectedCard?.id}
                    />
                )}
                {creditCard && creditCard.length > 0 && (
                    <CreditCard
                        type={creditCard[0].type}
                        primary={false}
                        creditInfo={creditCard[0]}
                        onSelectCard={() => setSelectedCard(creditCard[0])}
                        selectedCardId={selectedCard?.id}
                    />
                )}
                {!creditCard && !bankAccount && (
                    <p>No payment method available</p>
                )}
            </div>
            <PlansForm
                inputs={inputs}
                setInputs={setInputs}
                plan={selectedPlan}
                allPeriods={basicPlan?.info}
                row={row}
                loader={loader}
                activationDevice={activationDevice}
                changePlan={() => console.log('test')}
            />
        </div>
    )
};

export default SwitchToBasicModal;
