import React from "react";

import styles from "./SelectChannelModal.module.scss";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../stores/reducers/videoCameras";


const SelectChannelModal = ({ setSelectedChannels, selectedChannels }) => {

    const dispatch = useDispatch();

    const clickView = () => {
        dispatch(openModal({ key: "openLiveViewPlayer", value: true }))
        dispatch(openModal({ key: "openLiveViewModal", value: false }))
    }

    const channels = [
        {
            "channel_id": "1",
            "name": "Front View",
        },
        {
            "channel_id": "2",
            "name": "Driver View",
        }
    ]


    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 0px' }}>
            <h3>Select the channels to live stream</h3>

            <ul className={styles.channelsBlock}>
                {channels.map(channel => <Channel key={channel.channel_id} channel={channel} setSelectedChannels={setSelectedChannels} />)}
            </ul>

            <button
                className={styles.viewButton}
                onClick={clickView}
                disabled={selectedChannels?.channel_id == null}
            >
                View
            </button>
        </div>
    );
};

const Channel = ({ setSelectedChannels, channel }) => {

    const selectChannel = (): void => {
        setSelectedChannels(channel)
    };

    return (
        <li className={styles.channelItem} key={+channel.channel_id}>
            <div className={styles.nameChannel}>
                <input
                    type="radio"
                    name={'channel'}
                    onChange={selectChannel}
                />
            </div>

            <div className={styles.editChannel}>
                <input
                    type="text"
                    readOnly={true}
                    placeholder={channel?.name}
                    className="form-control-plaintext"
                    style={{width: '216px'}}
                />
            </div>
        </li>
    )
}

export default SelectChannelModal;
