import React, {ButtonHTMLAttributes, /*FC,*/ forwardRef } from "react";
import "./index.scss";

export enum ButtonStyles {
  BLACK_WHITE = "but-black-white",
  ORANGE_WHITE = "but-orange-white",
  RED_WHITE = "but-red-white",
  WHITE_GRAY = "but-white-gray",
  GRAY_INACTIVE = "but-gray-inactive",
  GRAY_BLACK = "but-gray-black",
  PLAIN_INACTIVE = "but-plain-inactive",
  YELLOW_WHITE= "but-yellow-white",
  NONE = ""
}

export enum ButtonSize {
  XSM = "but-xsm",
  SM = "but-sm",
  MD = "but-md",
  LG = "but-lg",
  NONE = ""
}

interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement> , "size"> {
  styleType: ButtonStyles;
  size?: ButtonSize;
  // ref: RefObject<HTMLButtonElement>;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((
  {
    styleType,
    size = ButtonSize.NONE,
    className = "",
    children,
    ...props
  },
  ref
) => {
  return (
    <button {...props} ref={ref} className={`but ${className} ${styleType} ${size}`}>{children}</button>
  )
})