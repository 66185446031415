import React from 'react';
import { Link } from 'react-router-dom';
import { omit } from 'ramda';
import { icon, IconDefinition, RotateProp, IconParams } from '@fortawesome/fontawesome';
import { faStar as faSolidStar } from '@fortawesome/fontawesome-free-solid';
import * as allSolidIcos from '@fortawesome/fontawesome-free-solid';
import * as allRegular from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/fontawesome-free-regular';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import C from '../../shared/constants';

interface iProps {
    icon: IconDefinition,
    style?: React.CSSProperties,
    spin?: boolean,
    rotation?: RotateProp,
    onClick?: ((...any) => any)|false
    className?: string,
    containerClassName?: string,
    title?: string,
    size?: number,
}

interface iFaLinkProps extends iProps {
    to: string,
}


export const Fa = ({rotation, className = '', containerClassName = '', title = '', spin = false, icon: faIcon, style = {}, onClick = false, size}: iProps) => {
    if (!fixIco(faIcon as any)) {
        return null
    }

    const css = {
        ...(onClick ? {cursor: 'pointer'} : {}),
        ...style,
        ...(size ? {fontSize: size} : {})
    }
    return (
        <span
            className={containerClassName}
            title={title}
            style={css}
            {...(onClick ? { onClick } : {} )}
        >
            <FontAwesomeIcon
                className={className}
                rotation={rotation}
                spin={spin}
                icon={fixIco(faIcon as any)} />
        </span>
    )
}

export const FaLink = (props: iFaLinkProps) => <Link style={props.style || {}} to={props.to}>
    <Fa {...omit(['style', 'to'], props) as any} />
</Link>


export const FaFav = ({style={}, onClick=undefined, isFav=false}: {style?: React.CSSProperties, onClick?: (...any) => any, isFav?: boolean, title?: string }) =>
     <Tooltip title={`${!isFav ? 'Add to favorite' : 'Remove from favorite'}`} placement='top'>
         <span>
         <Fa  icon={isFav ? faSolidStar : faStar} style={style} onClick={onClick as any} />
         </span>
  </Tooltip>

export const FaVector = (ico?: IconDefinition, options = {} as IconParams) =>
    icon(fixIco(ico as any), options).icon[4]

const all = {...allSolidIcos, ...allRegular}

const fixIco = (ico: string) => {

    if (typeof ico != 'string') return ico;

    const icoCamel = (ico as any)
        .replace(/^fa-/i, '')
        .replace(/\-o$/i, '')
        .split('-')
        .map(part => `${part[0].toUpperCase()}${part.slice(1)}`)
        .join('')

    const icoAltCamel = (ico as any)
        .replace(/^fa-/i, '')
        .replace(/\-o$/i, '')
        .replace('-', '-alt')
        .split('-')
        .map(part => `${part[0].toUpperCase()}${part.slice(1)}`)
        .join('')

    return all[icoAltCamel] || all[`fa${icoCamel}`] || all.faQuestion;
};

interface IProps extends TooltipProps {
  wrapper?: boolean;
}

const Tooltip: React.FC<IProps> = (props: IProps) => {
  const { wrapper, children, ...rest } = props;
  const content = wrapper ? <span>{children}</span> : children;

  return <MuiTooltip {...rest}>{content}</MuiTooltip>;
};


const FaPer = ({
  style = {},
  active = false,
  title = '',
}: {
  style?: React.CSSProperties;
  active?: boolean;
  title?: any;
}) => {
  const text = active ? `Assigned to ${title.displayName}` : 'No Assigned';

  return (
    <Tooltip title={text} wrapper placement='top'>
      <Fa
        icon={C.faIcos.person}
        style={{ color: !!active ?  C.darkGray : C.lightGray, ...style }}
      />
    </Tooltip>
  );
};

const FaDevice = ({
  style = {},
  active = false,
}: {
  style?: React.CSSProperties;
  active?: boolean;
}) => {
  const text = active ? 'Has device' : 'No device';

  return (
    <Tooltip title={text} wrapper placement='top'>
      <Fa
        icon={C.faIcos.device}
        style={{ color: !!active ?  C.darkGray : C.lightGray, ...style }}
      />
    </Tooltip>
  );
};

export { FaPer, FaDevice };