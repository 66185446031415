import moment from "moment";

export const getAuditLogTitles = (auditLog): string[] => {
  return Object.keys(auditLog);
};

export const filterDataByDate = (data: object, dateStart, dateEnd) => {
  const filteredData = {};

  for (const key in data) {
    if (
      typeof data[key] === "object" &&
      data[key].time &&
      moment(data[key].time).isBetween(dateStart, dateEnd)
    ) {
      filteredData[key] = data[key];
    } else if (typeof data[key] === "object") {
      const nestedFilteredData = filterDataByDate(
        data[key],
        dateStart,
        dateEnd
      );
      if (Object.keys(nestedFilteredData).length !== 0) {
        filteredData[key] = nestedFilteredData;
      }
    }
  }

  return filteredData;
};

export const filterDataByFilters = (data: object, filters) => {
  const filteredData = {};

  if (!filters.length) {
    return data;
  }

  for (const key in data) {
    if (filters.includes(key)) {
      filteredData[key] = data[key];
    } else if (typeof data[key] === "object") {
      const nestedFilteredData = filterDataByFilters(data[key], filters);
      if (Object.keys(nestedFilteredData).length !== 0) {
        filteredData[key] = nestedFilteredData;
      }
    } else {
      if (filters.includes(data[key])) {
        filteredData[key] = data[key];
      }
    }
  }

  return filteredData;
};

export const getFilteredData = (data: object, filters, date) => {
  const { startDate, endDate } = date[0];

  const filterFields = Object.entries(filters)
    .filter((filter) => filter[0] !== "types")
    .map((filter) => Object.keys(filter[1]))
    .flat();

  return filterDataByDate(
    filterDataByFilters(data, filterFields),
    startDate,
    endDate
  );
};
