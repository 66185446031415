import React from 'react';
import { keys, merge, uniq, values } from 'ramda';

import { useLocalStorage } from '../../../stores/reducers/general-reducers';
import StandardItem from '../../../components/general/standard-item'
import { Row } from '../../elements/flex';
import {iDevicePingWithGeofences, iFullStoreState, iList} from "../../../shared/interfaces";

export type iHiddenReportData = {
    [k: string]: boolean
}

export const VisFencesData = ({type, showVisibleFencesData, records}: {type: 'geofence', showVisibleFencesData: false | true, records: iList<iDevicePingWithGeofences[]>}) => {
    const [hiddenFields, setData] = useLocalStorage<iHiddenReportData>(`hidden-report-data:${type}`, {});

    let names = [];
    Object.entries(records).forEach(([keyDev, recordsByDevice]) => {
        Object.entries(recordsByDevice).forEach(([keyFen, {geofences}]) => {
            Object.keys(geofences).forEach(value => {
                names.push(value);
            });
        })
    });
    const uniqueNames = uniq(names);



    return <>
        {/* TODO: make these on off buttons so we can flex wrap them */}
        {!showVisibleFencesData ? null : <Row style={{border: `1px solid gray`, padding: 8, maxWidth: 400}} justify="space-evenly" wrap>
            {uniqueNames.map((field, idx) => <StandardItem
                key={field}
                displayName={field}
                itemId={idx}
                style={{marginBottom: 8, marginRight: 8, cursor: 'pointer', flexGrow: 1}}
                view={() => setData(merge(hiddenFields, {[field]: !hiddenFields[field]}))}
                isViewed={!hiddenFields[field]}
            />)}
        </Row>}
    </>
}