import React from 'react';
import {Fa} from '../fa';
import {NewBtnProps, ToggleBtnProps} from './interface';
import './styles.scss';

export const ToggleBtn = ({active, title, style={}, icon, size, ...rest}: ToggleBtnProps): JSX.Element => {
    return (
        <button
            style={style}
            onFocus={(e) => e.target.blur()}
            className={`btn ${active ? 'btn-primary' : 'btn-light'} btn-${size}`}
            {...rest}
        >
            {!icon ? null : <Fa icon={icon}/>} {title}
        </button>
    );
};

export const NewButton = ({active, title, ...rest}: NewBtnProps): JSX.Element => {
    return (
        <button className = {active ? 'activeStyle' : 'styleNew'} {...rest}>
            {title}
        </button>
    );
};

