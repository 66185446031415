export interface ISubUser {
  desiredUserName: string;
  password: string;
  password_repeat: string;

  firstName: string;
  lastName: string;
  email: string;

  phoneNumber: string;

  address1: string;
  address2: string;
  country: string;
  city: string;
  state: string;
  postalCode: string;
}

export enum userFieldNames {
  desiredUserName = 'desiredUserName',
  password = 'password',
  password_repeat = 'password_repeat',

  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',

  address1 = 'address1',
  address2 = 'address2',
  country = 'country',
  city = 'city',
  state = 'state',
  postalCode = 'postalCode',
  isSubAdmin = 'isSubAdmin',
}

export enum ServerErrorTypes {
  billing_address_zip = 'billing_address_zip',
  email = 'email',
  username = 'username',
}

export interface ServerErrors {
  [ServerErrorTypes.billing_address_zip]?: string[];
  [ServerErrorTypes.email]?: string[];
  [ServerErrorTypes.username]?: string[];
}
