import { OrderedMap, Seq } from 'immutable';
import { put, select, takeEvery } from 'redux-saga/effects';

import { iDevicePing, iFullStoreState } from '../../shared/interfaces';
import { IPointsContainer } from '../reducers/devicesTripsPoints';
import { shouldShowPoint } from '../../components/gmap/pois';
import { setSelectedPoint } from '../reducers/selectedPoint/AC';

const SELECTED_LAST_POINT_IN_PREV_TRIP = 'SELECTED_LAST_POINT_IN_PREV_TRIP';

export const selectLastPointInPrevTrip = (deviceId: string, tripId: string) => ({
    type: SELECTED_LAST_POINT_IN_PREV_TRIP,
    payload: {deviceId, tripId},
});

function* worker (action: ReturnType<typeof selectLastPointInPrevTrip>) {
    const {deviceId, tripId} = action.payload;

    const tripsIds: Seq.Indexed<string> = yield select((s: iFullStoreState) =>
        s.devicesTripsPoints.getIn([deviceId, 'tripsPoints'], OrderedMap()).keySeq(),
    );

    const prevTripId = findPrevElement(tripsIds, tripId);

    if (!prevTripId) return;

    const showPOI: boolean = yield select((s: iFullStoreState) => s.gmap.showPOI);
    const showDots: boolean = yield select((s: iFullStoreState) => s.gmap.showDots);

    const lastPointInPrevTrip: iDevicePing | undefined = yield select(state => {
        let points: IPointsContainer = state.devicesTripsPoints.getIn(
            [deviceId, 'tripsPoints', prevTripId],
            OrderedMap(),
        );
        /** TODO: maybe next condition not need
         * if last point in all trips has msg === "Ignition off"
         */
        if (!showDots || !showPOI) {
            points = points.filter(shouldShowPoint({showDots, showPOI}));
        }
        return points.last<undefined>();
    });

    if (lastPointInPrevTrip) {
        yield put(setSelectedPoint(deviceId, prevTripId, lastPointInPrevTrip.pointId, lastPointInPrevTrip.coordinates.location));
    }
}

export default function* () {
    yield takeEvery(SELECTED_LAST_POINT_IN_PREV_TRIP, worker);
}

const findPrevElement = (collection: Seq.Indexed<string>, element: string): undefined | string => {
    if (!collection.size || !element) return;

    const currentElementIndex = collection.findIndex(el => element === el);
    if (currentElementIndex === -1) return;

    if (currentElementIndex > 0) {
        return collection.get(currentElementIndex - 1);
    }
};
