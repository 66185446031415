import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { ReactComponent as Trash } from "../../../../../assets/svg/delete_outline.svg";
import { ReactComponent as Pen } from "../../../../../assets/svg/edit.svg";
import { deleteCard } from "../../../../../stores/reducers/billing";
import { iReducersState } from "../../../../../stores/reducers";

import { ICreditCard } from "./interfaces";

import { deleteRequest } from "../../BillingApi/billingApiRequests";
import { LoadingModal } from "../../../../elements/loading";
import "./CreditCard.scss";

const CreditCard = ({
  primary,
  type,
  setShowCreditCardActive,
  setShowBankCard,
  setShowBankCardActive,
  setLoadingBankAccount,
  creditInfo,
  bankAcc,
}: ICreditCard) => {
  const dispatch = useDispatch();
  const { loadingCards, token } = useSelector(
    (state: iReducersState) => state.billing
  );

  const handlerDelete = () => {
    setLoadingBankAccount(true);
    deleteRequest({
      id: bankAcc.id,
      url: "api/billing/delete-ach",
      dispatch,
      action: deleteCard,
      loading: setLoadingBankAccount,
    });
    setShowCreditCardActive(false);
    dispatch(deleteCard(bankAcc.id));
  };

  return (
    <div credit-type={primary ? 'bankAccount' : 'creditCard'} className={`card card-${primary ? "primary" : "backup"}`}>
      <div className="card__box">
        {loadingCards ? (
          <LoadingModal />
        ) : (
          <>
          <div>
            <div className="card__name">{type}</div>

              {primary ? (
                <div
                  className={classNames({ card__action: true })}
                  onClick={() => {
                    handlerDelete();
                  }}
                >
                  <Trash />
                </div>
              ) : (
                <div
                  className={classNames({ card__action: true })}
                  onClick={() => {
                    setShowBankCard(true);
                    setShowBankCardActive(false);
                  }}
                >
                  <Pen />
                </div>
              )}
          </div>
            <div className="card__number">
              <span>****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;</span>
              {primary ? bankAcc.last_digits : creditInfo.last_digits}
            </div>

            {primary ? (
              <div className="card__active-bottom">
                {bankAcc.active === "1" && "Active"}
              </div>
            ) : (
              <div className="card__expires">{`Expires:${creditInfo.exp_date}`}</div>
            )}

          </>
        )}
      </div>
    </div>
  );
};

export default CreditCard;
