import React from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import { getDisplayName } from './helpers';

type DispatchActions = {
    mount?: () => AnyAction;
    unmount?: () => AnyAction;
}

export function withDispatchActions<P extends object> (Component: React.ComponentType<P>, actions: DispatchActions) {
    function WithDispatchActions (props: P) {
        const dispatch = useDispatch();

        React.useEffect(() => {
            const {mount, unmount} = actions;
            mount && dispatch(mount());

            return () => {
                unmount && dispatch(unmount());
            };
        }, []);

        return <Component {...props} />;
    }

    WithDispatchActions.displayName = `WithDispatchActions(${getDisplayName(Component)})`;
    return WithDispatchActions;
}
