import React from "react";
import Picker from 'react-mobile-picker';
import {isMobile} from "../../../../../../shared/helpers";
import {IYearPickerOptions, IYearPickerProps, IYearPickerValue} from "./YearPicker.interfces";
import CustomizerStyleForYearPicker from "./CustomizerStyleForYearPicker/CustomizerStyleForYearPicker";

const baseContainerWidth = 1440;

const getScalablePx = (inPx: number, windowWidth) => {
    return inPx * windowWidth / baseContainerWidth;
};

function YearPicker ({changeYear, year, years}: IYearPickerProps) {
    const [mounted, setMounted] = React.useState(false);
    const valueGroups: IYearPickerValue = {year};
    const optionGroups: IYearPickerOptions = {year: years};

    const handleChange = (name: keyof IYearPickerValue, value: number) => changeYear(value);

    const [windowWidth, setWindowWidth] = React.useState<number>(window.innerWidth);

    const resizeWindowListener = () => {
        setWindowWidth(window.innerWidth);
    };

    React.useEffect(() => {
        setMounted(true);

        window.addEventListener('resize', resizeWindowListener);

        return () => window.removeEventListener('resize', resizeWindowListener);
    }, []);

    const currentYearIndex = optionGroups.year.findIndex((year) => year === valueGroups.year);

    return (
        <>
            {!isMobile && <CustomizerStyleForYearPicker baseContainerWidth={baseContainerWidth} activeItemNumber={currentYearIndex + 1}/>}
            {mounted && (
                <Picker
                    height={getScalablePx(isMobile ? 600 : 180, windowWidth)}
                    itemHeight={getScalablePx(isMobile ? 80 : 28, windowWidth)}
                    optionGroups={optionGroups}
                    valueGroups={valueGroups}
                    onChange={handleChange} />
            )}
        </>
    );
}

export default YearPicker;