import React, { useEffect } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { IEmptyBankAccount } from "./interfaces";

import instance from "../../../../../api/instance";
import { LoadingModal } from "../../../../elements/loading";
import { iReducersState } from "../../../../../stores/reducers";
import { addBankAccount } from "../../../../../stores/reducers/billing";
import { ReactComponent as Pen } from "../../../../../assets/svg/edit.svg";
import { ReactComponent as Bank } from "../../../../../assets/svg/bank.svg";
import { ReactComponent as Card } from "../../../../../assets/svg/card.svg";
import { ReactComponent as BlackWave } from "../../../../../assets/svg/black_wave.svg";
import { ReactComponent as OrangeWave } from "../../../../../assets/svg/orange_wave.svg";
import "./emptyCard.scss";

const EmptyBankAccount = ({
  primary,
  setShowCreditCardActive,
  setShowBankCard,
  loadingBankAccount,
  setLoadingBankAccount,
}: IEmptyBankAccount) => {
  const iconWave = primary ? <OrangeWave /> : <BlackWave />;
  const { plaidToken, loadingCards } = useSelector(
    (state: iReducersState) => state.billing
  );
  const dispatch = useDispatch();

  let linkHandler: any;
  if (plaidToken?.link_token) {
    linkHandler = window.Plaid.create({
      env: process.env.REACT_APP_PLAID_ENV,
      clientName: "Stripe/Plaid Test",
      token: plaidToken.link_token,
      product: ["auth"],
      selectAccount: true,
      onSuccess: function (public_token, metadata) {
        // Send the public_token and account ID to your app server.
        addAch(
          {
            public_token: public_token,
            account_id: metadata.account_id,
            mask: metadata.account.mask,
          },
          updateCardShowingState
        );
      },
      onExit: function (err, metadata) {
        // The user exited the Link flow.
        if (err != null) {
          // The user encountered a Plaid API error prior to exiting.
        }
      },
    });
  }

  const addAch = (field, updateCardShowingState) => {
    updateCardShowingState(true);

    instance
      .post(`api/billing/add-ach`, field)
      .then((res) => {
        dispatch(addBankAccount(res.data.paymentMethods));
        updateCardShowingState(false);
        // need to change toast conditions, after update on the backend
        toast.success("Bank Account Was Created");
      })
      .catch((err) => {
        const errorMessage = err.response.data.message
        toast.error(errorMessage);
        console.log("ERROR: Add-ach failed", err);
      })
      .finally(() => updateCardShowingState(false));
  };

  const updateCardShowingState = (isLoading) => {
    setLoadingBankAccount(isLoading);
    setShowCreditCardActive(true);
  };

  useEffect(() => {
    if (primary) {
      const timer = setTimeout(() => setLoadingBankAccount(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [primary, setLoadingBankAccount]);

  const handleClick = () => {
    if (primary) {
      linkHandler && linkHandler.open();
    } else {
      setShowBankCard(true);
    }
  };

  return (
    <div
      className={classNames({
        "empty-card": true,
        "black-card": !primary,
        linkButton: primary,
      })}
    >
      <div className="content" onClick={handleClick}>
        <div className="center-content">
          {loadingBankAccount || loadingCards ? (
            <LoadingModal />
          ) : (
            <>
              <div
                className={classNames({ card__action: true })}
                onClick={handleClick}
              >
                <Pen />
              </div>
              <div className="icon">{primary ? <Bank /> : <Card />}</div>
              <div className="name">
                {primary ? "Add Bank account" : "Add Credit card"}
              </div>
            </>
          )}
        </div>
        <div className="wave">{iconWave}</div>
      </div>
    </div>
  );
};

export default EmptyBankAccount;
