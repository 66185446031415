import React, { CSSProperties as css } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DragSortableList from 'react-drag-sortable';
import { equals } from 'ramda';
import {
  faBars,
  faChevronRight,
  faStar,
} from '@fortawesome/fontawesome-free-solid';

import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import C from '../../../shared/constants';
import { iFullStoreState, UserAuth } from '../../../shared/interfaces';
import * as favoritesdb from '../../../shared/db/favorites-db';
import {
  getFavoriteLinksSelector,
  iFav,
} from '../../../shared/db/favorites-selectors';
import { Fa } from '../../elements/fa';
import { isMobile } from '../../../shared/helpers';
import { withSentry } from '../../../shared/hoc/withSentry';
import SvgIcon from '../../SvgIcon/SvgIcon';

function AllFavsComponent() {
  const favs = useSelector<iFullStoreState, Array<iFav>>(
    getFavoriteLinksSelector(true)
  );
  const authUser = useSelector<iFullStoreState, UserAuth>(
    (s) => s.auth.user,
    (l, r) => equals(l, r)
  );

  const toggleFav = (type, uid, id) =>
    favoritesdb.toggleFavorite(type, uid, id, false);

  const onSort = async (sorted, dropEvent) => {
    dropEvent.preventDefault();
    dropEvent.stopPropagation();

    await favoritesdb.changeFavSort(authUser)(
      sorted.map(({ id, rank }) => {
        const fav = favs.find((f) => f.itemId === id);

        return {
          type: fav.type,
          uid: authUser.uid,
          itemId: fav.itemId,
          order: rank,
        };
      })
    );
  };

  return (
    <DashboardBlock>
      <ModalHeader title='Favorites' />

      <DragSortableList
        type='vertical'
        placeholder={<div />}
        onSort={onSort}
        items={favs.map((fav) => ({
          id: fav.itemId,
          content: (
            <FavRow
              fav={fav}
              removeFav={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleFav(fav.type, authUser.uid, fav.itemId);
              }}
            />
          ),
        }))}
      />
    </DashboardBlock>
  );
}
type FavRowsProps = {
  fav: iFav;
  removeFav: (e) => void;
};

const FavRow = withSentry(({ fav, removeFav }: FavRowsProps) => {
  const [dragging, setDragging] = React.useState(false);
  const [mouseDown, setMouseDown] = React.useState(false);

  const handleMouseDown = () => {
    setMouseDown(true);

    setTimeout(() => {
      mouseDown && setDragging(true);
    }, 500);
  };

  const mouseUp = () => {
    setTimeout(() => {
      setDragging(false);
      setMouseDown(false);
    }, 50);
  };

  const click = (e) => {
    if (dragging) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div
      key={fav.itemId}
      style={favItemCss(true)}
      onMouseDown={handleMouseDown}
      onClick={click}
      onMouseUp={mouseUp}
    >
      <Fa
        icon={faBars}
        style={{ fontSize: 15, cursor: 'move', padding: 5, paddingRight: 15 }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {fav.icon ? (
          <SvgIcon icon={fav.icon} color={fav.color} size='xs' />
        ) : (
          <img
            src={fav.photo}
            alt='photo'
            style={{
              borderRadius: fav.isRounded ? 20 : 0,
              maxWidth: 20,
              borderBottom: `1px solid ${C.mediumGray}`,
            }}
          />
        )}
        <Fa
          icon={faStar}
          style={{ color: C.yellow, paddingTop: 1, marginTop: 1 }}
          onClick={removeFav}
        />
      </div>
      <Link
        to={fav.link}
        style={{
          marginLeft: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        <span>{fav.name}</span>
        <span style={{ fontSize: 11 }}>
          {fav.lastPing ? fav.lastPing.address.street : ''}
        </span>
      </Link>

      <span
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          padding: 5,
          fontSize: 20,
          fontWeight: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Fa icon={faChevronRight} />
      </span>
    </div>
  );
});

const favItemCss = (dragging): css => ({
  padding: '4px 0',
  textDecoration: 'none',
  color: C.primaryText,
  backgroundColor: '#fff',
  marginBottom: -1,
  borderTop: `1px solid ${C.mediumGray}`,
  borderBottom: `1px solid ${C.mediumGray}`,
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  alignItems: 'center',
  ...(dragging && !isMobile ? { width: 300 } : {}),
});

export default withSentry(AllFavsComponent);
