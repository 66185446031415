export const actions = [
    {value: true, label: 'Show'},
    {value: false, label: 'Hide'},
];

export const properties = [
    {value: 'date', label: 'Date/Period'},
    {value: 'city', label: 'City'},
    {value: 'state', label: 'State'},
    {value: 'zip', label: 'Zip Code'},
    {value: 'address', label: 'Address'},
    {value: 'speed', label: 'Speed'},
    {value: 'message', label: 'Message Type'},
];

export const dateProperties = [
    {value: 'hour', label: 'Last hours'},
    {value: 'exactDate', label: 'Exact date'},
    {value: 'period', label: 'Period from/to'},
];

export const speedProperties = [
    {value: 'equalMore', label: 'Equal or more'},
    {value: 'equalLess', label: 'Equal or less'},
    {value: 'more', label: 'More'},
    {value: 'less', label: 'Less'},
];

export const messageProperties = [
    {value: 'IgnOn', label: 'Ignition on'},
    {value: 'IgnOff', label: 'Ignition off'},
    {value: 'Heartbeat', label: 'Heartbeat'},
    {value: 'Idle', label: 'Idle threshold'},
    {value: 'Speed', label: 'Speed threshold'},
    {value: 'Accel', label: 'Acceleration threshold'},
    {value: 'Decel', label: 'Deceleration threshold'},
];

export const customSelectStyles = {
    option: (provided, state): object => ({
        ...provided,
        opacity: state.isSelected ? 1 : 0.5,
        fontSize: 12,
        fontWeight: 400,
        color: 'black',
        padding: 7,
    }),
    container: (provided): object => ({
        ...provided,
        width: 194,
        div: {
            backgroundColor: '#F7F7F7',
            border: '1px solid #F7F7F7',
            borderRadius: 2,
        }
    }),
    control: (): object => ({
        display: 'flex',
        borderColor: '#F7F7F7'
    }),
    indicatorSeparator: (): object => ({
        display: 'none'
    }),
    dropdownIndicator: (provided): object => ({
        ...provided,
        color: 'black'
    }),
    valueContainer: (provided): object => ({
        ...provided,
        fontSize: 12,
        fontWeight: 400,
        color: 'black',
        opacity: 0.5
    }),
};
