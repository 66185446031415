import React, {
    useEffect,
    useRef,
    Fragment,
} from 'react';
import moment from 'moment';
import {localStorage} from "../../shared/storage";
import { logout as logoutAPI } from '../../api';
import {fbapp} from "../../shared/firebase";
import {useSelector} from "react-redux";
import {iFullStoreState} from "../../shared/interfaces";
import {resetRememberMeIfExists} from "../../shared/helpers";

const SessionTimeout = () => {
    const events = ['click', 'load', 'scroll', 'keydown'];
    const isAuthenticated = useSelector<iFullStoreState, string | null>((state) => state.auth.user.uid);
    const logoutTime = useSelector<iFullStoreState, string | number>((state) => state.auth.logoutTime);
    const warningInactiveInterval = useRef();
    const startTimerInterval = useRef();

    let timeStamp;

    useEffect(() => {
        let timeString =  localStorage.get('lastTimeStamp');
        activityChecker(timeString, logoutTime);

        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, () => resetTimer(logoutTime));
            });

            clearTimeout(startTimerInterval.current);
        };
    }, []);

    const logout = () => {
        logoutAPI().finally(() => {
            resetRememberMeIfExists();
            document.location.href = '/';
            fbapp.auth().signOut();
        });
    }

    const activityChecker = (timeString, maxTimeout) => {
        // console.log('log time', timeout);

        if(!timeString) return;

        const date = new Date(timeString).getTime();
        const diff = Date.now() - date;

        // console.log('min', minPast);
        // console.log('max', maxTime);
        // console.log('min > max', minPast >= maxTime);

        if (diff > maxTimeout) {
            clearInterval(warningInactiveInterval.current);
            localStorage.forget('lastTimeStamp');
            logout();
        }
    }

    const timeChecker = (timeoutTime) => {
        // console.log('log from checker', timeoutTime);
        // @ts-ignore
        startTimerInterval.current = setTimeout(() => {
            let storedTimeStamp = localStorage.get('lastTimeStamp');
            warningInactive(storedTimeStamp, timeoutTime);
        }, 15000);
    };

    let warningInactive = (timeString, timeout) => {
        clearTimeout(startTimerInterval.current);

        // @ts-ignore
        warningInactiveInterval.current = setInterval(() => {
            activityChecker(timeString, timeout);
        }, 1000);
    }

    const resetTimer = (timeout) => {
        clearTimeout(startTimerInterval.current);
        clearInterval(warningInactiveInterval.current);

        if(isAuthenticated) {
            timeStamp = moment();
            localStorage.set('lastTimeStamp', timeStamp);
        } else {
            clearInterval(warningInactiveInterval.current);
            localStorage.forget('lastTimeStamp');
        }
        // console.log('rest time check');
        timeChecker(timeout);
    };

    useEffect(() => {
        // console.log('log from use effect', logoutTime);

        clearTimeout(startTimerInterval.current);
        clearInterval(warningInactiveInterval.current);

        events.forEach((event) => {
            window.addEventListener(event, () => resetTimer(logoutTime));
        });

        // console.log('use effect time check');
        isAuthenticated && timeChecker(logoutTime);
    }, [logoutTime])

    return <Fragment />;
}

export default React.memo(SessionTimeout);
