import { createSelector } from 'reselect';
import { iDeviceDetails, iDevicePing, iDevicePingEager, iDevicePingWithGeofences, iDevicePingWithGeofencesEager, iFullStoreState, iList, iPerson, ItemType, iTrip, iTripEager } from "../../shared/interfaces";
import { groupBy } from "ramda";
import { selectPeople } from './general-selectors';
import { selectDevices } from './devicesData/selector';
import { pipe, map } from 'ramda';
import { Map } from "immutable";

export const selectDisplayRecords = (s: iFullStoreState): iList<iDevicePing | iTrip> => s.report.displayRecords;
export const selectDeviceOrPerson = (s: iFullStoreState): ItemType.device | ItemType.person => s.report.details.devicePerson;

export const selectReportsGrouped = createSelector<
    iFullStoreState,
    iList<iDevicePing | iTrip>,
    ItemType.device | ItemType.person,
    iList<iDevicePing[] | iTrip[] | iDevicePingWithGeofences[]>
>(
    [selectDisplayRecords, selectDeviceOrPerson],
    (displayRecords, deviceOrPerson) => {
        return groupBy((x: iDevicePing | iTrip | iDevicePingWithGeofences) => deviceOrPerson === ItemType.device ? x.device : x.personId)(Object.values(displayRecords || {}));
    }
);

export const selectPeopleToSendReportKeys = (s: iFullStoreState): iList<boolean> => s.report.details.peopleToSendReport;

export const selectPeopleToSendEmail = createSelector<iFullStoreState, iList<iPerson>, iList<boolean> ,iList<iPerson>>(
    [selectPeople, selectPeopleToSendReportKeys],
    (people, peopleToSendReportKeys) => {
        const res = {};
        Object.keys(peopleToSendReportKeys).forEach(key => {
            res[key] = people[key];
        })
        return res;
    }
)

export const selectCustomEmailsToSendReport = (s: iFullStoreState): string[] => s.report.details.customEmailsToSendReport;

export const selectAllEmailsToSendReport = createSelector<iFullStoreState, iList<iPerson>, string[], string[]>(
    [selectPeopleToSendEmail, selectCustomEmailsToSendReport],
    (people, emails) => {
        return [...Object.values(people).map(p => p.email), ...emails];
    }
)

export const selectReportsEager = createSelector<
    iFullStoreState,
    iList<iDevicePing | iTrip | iDevicePingWithGeofencesEager>,
    iList<iPerson>,
    Map<string, iDeviceDetails>,
    Array<iDevicePingEager | iTripEager | iDevicePingWithGeofencesEager>
>(
    [selectDisplayRecords, selectPeople, selectDevices],
    (records = {}, people, devices) => {
        console.log("i am here motherfucker", records)
        return pipe(
            Object.values,
            map((record) => ({...record, device: devices.getIn([record.device]), person: people[record.personId]})),
        )(records);
    }
)