export const optionsAddress = [
    {value: 'city', label: 'City', name: 'option'},
    {value: 'state', label: 'State', name: 'option'},
    {value: 'street', label: 'Street', name: 'option'},
    {value: 'zip', label: 'Zip Code', name: 'option'},
]

export const optionsMetrics = [
    {value: 'fuel', label: 'Fuel', name: 'option'},
    {value: 'mpg', label: 'Mpg', name: 'option'},
    {value: 'rpm', label: 'Rpm', name: 'option'},
    {value: 'speed', label: 'Speed', name: 'option'},
    {value: 'volts', label: 'Volts', name: 'option'},
]

export const groupedOptions = [
    {label: 'Address', options: optionsAddress},
    {label: 'Metrics', options: optionsMetrics},
];

export const address = ['city', 'state', 'street', 'zip'];

export const compareOperations = {
    'more': (a, b) => a > b,
    'less': (a, b) => a < b,
    'equalMore': (a, b) => a >= b,
    'equalLess': (a, b) => a <= b,
    'equal': (a, b) => a === b,
    'noEqual': (a, b) => a !== b,
}

export const optionsCompareForMetrics = [
    {value: 'more', label: '>', name: 'compare'},
    {value: 'less', label: '<', name: 'compare'},
    {value: 'equalMore', label: '>=', name: 'compare'},
    {value: 'equalLess', label: '<=', name: 'compare'},
    {value: 'equal', label: '=', name: 'compare'},
    {value: 'noEqual', label: '!=', name: 'compare'},
]

export const optionsCompareForAddress = [
    {value: 'equal', label: '=', name: 'compare'},
    {value: 'noEqual', label: '!=', name: 'compare'},
]

export const getLabelByValue = (value) => {
    const option = optionsCompareForMetrics.find(option => option.value === value);

    return option ? option.label : null;
}

export const customSelectStyles = {
    option: (provided, state): object => ({
        ...provided,
        backgroundColor: '#fff',
        opacity: state.isSelected ? 1 : 0.5,
        fontSize: 12,
        fontWeight: 400,
        color: 'black',
        padding: 7,
        cursor: 'pointer',
    }),
    groupHeading: (provided): object => ({
        ...provided,
        color: 'black',
        fontWeight: 'bold',
    }),
    container: (provided): object => ({
        ...provided,
        div: {
            backgroundColor: '#fff',
            borderRadius: 2,
        }
    }),
    control: (): object => ({
        display: 'flex',
        border: '1px solid #ECECEC',
        borderRadius: 2,
    }),
    dropdownIndicator: (provided): object => ({
        ...provided,
        padding: '0px 5px',
        color: 'black',
        cursor: 'pointer',
    }),
    valueContainer: (provided): object => ({
        ...provided,
        fontSize: 12,
        fontWeight: 400,
        color: 'black',
        opacity: 0.8
    }),
    menuList: (base) => ({
        ...base,
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgb(242, 244, 245)',
        },
        '&::-webkit-scrollbar': {
            width: '7px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey',
        },
    }),
};