import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, DispatchProp, useDispatch } from 'react-redux';
import { pipe, values } from 'ramda';
import { faSpinner } from '@fortawesome/fontawesome-free-solid';
import moment from 'moment';
import classNames from 'classnames';

import DashboardBlock from '../../DashboardBlock';
import TripTravelTablePure from './ReportPage/TripTravelTablePure';
import { Fa } from '../../elements/fa';
import { Overlay } from '../../menus/modal/modal';
import { ACL, UserCan } from '../../../shared/constants';
import {
    beToken,
    iDevicePing,
    iFullStoreState,
    iList,
    iPerson,
    iReportPermissionData,
    iSchedule,
    iTag,
    iTrip,
} from '../../../shared/interfaces';
import {
    friendlyDiff,
    friendlyMilesPer,
    friendlySpeed,
    idValArr,
    isMobile,
    lCount,
    minutesToFriendly,
    userCurrentOffset,
    utcOffset,
    vals
} from '../../../shared/helpers';
import {
    Actions as ReportActions,
    iChoosingDetails, IFilterValue,
    iReportDetails,
    iReportType,
    iState as iReportState,
    recordMatches,
    iTripsFromSelectedArea
} from '../../../stores/reducers/report-reducers';
import { MinimalTile } from '../../general';
import { PersonBox } from '../../general/person-box';
import { getAuditLog, getGeonfeces, getTrips, iGetReportPropsWrapper, makeTrip, makeTripFromEndpointData, tripPointsReal } from '../../../shared/db/report-db';
import { LabelChooser } from '../../general/trip-list-chooser';
import { BaseComponent } from '../../../shared/BaseComponent';
import Dialog, { DialogConfigSetter } from '../../Dialog';
import { localStorage } from '../../../shared/storage';
import {DevicesDetailsContainer, DevicesLastPingContainer} from '../../../stores/reducers/devicesData';
import {
    setReportTrip,
    toggleShowReportTrip
} from '../../../stores/reducers/devicesTripsPoints/AC';

import './ReportPage.scss';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { ReportSubNav, ReportSubNavbar } from "./ReportSubNavbar";
import { ReportNavbar } from "./ReportNavbar"
import { VR } from "../../general/VR";
import { ReactComponent as FilterListSvg } from "../../../assets/svg/filter_list.svg";
import { ReactComponent as ChevronLeft } from "../../../assets/svg/chevron_left.svg";
import { ReactComponent as ChevronRight } from "../../../assets/svg/chevron_right.svg";
import "./styles.scss";
import { ReactComponent as VisibilitySvg } from "../../../assets/svg/visibility.svg";
import { ReactComponent as DeviceSvg } from "../../../assets/svg/device.svg";
import { ReactComponent as PeopleSvg } from "../../../assets/svg/people.svg";
import "./shared.scss";
import { iGMapModal } from '../../../stores/reducers/gMapModal';
import { GMapModalAC, GMapModalContent } from '../../../stores/reducers/gMapModal/AC';
import { Button, ButtonSize, ButtonStyles } from "../../Button";
import GeofenceReport from "./GeofenceReport";
import SummaryReport from "./SummaryReport";
import AuditReport from './AuditReport';
import VideoReport from './VideoReport';
import FilterIndicators from './FilterIndicators';
import { filterRecords, validate } from './reportFilter';
import { compose } from 'redux';
import ShowOnMap from './ReportPage/ShowOnMap';
import LocalModalActions from './ReportPage/LocalModalActions';
import { clientStore, clientDb } from '../../../shared/firebase';
import { toast } from 'react-toastify';
import { GoogleMap } from 'react-google-maps';
import { MAP } from 'react-google-maps/lib/constants';
import {Set as ImmutableSet} from 'immutable';
import { makePointFromEndpointData } from '../../../shared/db/report-db';
import instance from '../../../api/instance';
import "../../../assets/styles/classes.scss";
import {iVideo} from "./VideoReport/VideoItem";
import ReactPaginate from 'react-paginate';
import path from '../../gmap/path';
import { getFilteredData } from './AuditReport/helper';
import {VideoReportBody} from "../../../shared/apis.interfaces";
import {store} from "../../../stores/store";
import ActivityReport from "./ActivityReport";
import {formatWhenLast} from "../devices/device-page";

type IProps = RouteComponentProps<{ type?: 'static' | 'travel' }>;

const formattingData = (docs) => {
    const trips = docs?.reduce((acc, curr) => {
        const tripId = curr.id;
        const trip = makeTrip(curr);

        return { ...acc, [tripId]: trip }
    }, {});
    return trips;
}

const compareArrays = (arr1, arr2) => {
    if (!arr1 || !arr2 || arr1.length !== arr2.length) {
        return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }

    return true;
}

type IPropsFromStore = {
    report: iReportDetails,
    dates: any,
    devicesDetails: DevicesDetailsContainer;
    devicesLastPing: DevicesLastPingContainer,
    people: iList<iPerson>,
    userToken?: beToken;
    tags: iList<iTag>,
    tagDevices: { [key: string]: ImmutableSet<string>; },
    choosing?: iChoosingDetails;
    labels: string[],
    displayRecords: iList<iTrip | iDevicePing>,
    reportType: iReportType,
    reportTypeFromRedux: iReportType,
    userCanDo: UserCan[],
    tripsFromSelectedArea: iTripsFromSelectedArea,
    filterDisplayRecords: Array<IFilterValue>
    selectionsList: [],
    filters: any,
    mapRef: React.RefObject<GoogleMap>,
    schedule: iSchedule[]
}

type IFullProps = IProps & IPropsFromStore & DispatchProp;
const mapStateToProps = (state: iFullStoreState, ownProps): IPropsFromStore => {

    const reportTypeRedux = typeof state.report.details.reportTypeFromRedux == 'undefined' ? 'travel' : state.report.details.reportTypeFromRedux

    return ({
        userToken: state.auth.user?.beToken,
        displayRecords: state.report.displayRecords ?? {},
        choosing: state.report.choosing,
        dates: state.report.details.dates,
        devicesDetails: state.devicesData.devicesDetails,
        devicesLastPing: state.devicesData.devicesLastPing,
        people: state.general.people,
        report: state.report.details,
        tripsFromSelectedArea: state.report.details.tripsFromSelectedArea,
        labels: state.general.tripLabels,
        tags: state.general.tags,
        tagDevices: state.tagsDevicesMap.tagsDevices.toObject(),
        reportType: ownProps.match.params.type, // it takes report type from url
        reportTypeFromRedux: reportTypeRedux, // it takes report type from redux
        userCanDo: state.auth.user?.acl?.can ?? [],
        filterDisplayRecords: state.report.filterDisplayRecords,
        selectionsList: state.report.selectionsList,
        filters: state.report.details.filters,
        mapRef: state.gmap.mapRef(),
        schedule: state.general.schedule,
    })
}

type PageLastDocs = {
    [key: number]: string | null;
};

type IState = {
    loading: boolean;
    paginationLoading: boolean;
    showNewReport: boolean;
    records: object;
    reportData: object;
    showAllRoute: boolean;
    showVisibleReportData: boolean;
    showVisibleFencesData: boolean;
    allTrips: any;
    disablePaginateButton: {
        disableNextButton: boolean, disablePreviousButton: boolean
    }
    isHaveNext: boolean,
    travelCurrentPage: number,
    travelIsLastPage: boolean,
    travelLastDocs: PageLastDocs,
    unauthorizedCurrentPage: number,
    unauthorizedIsLastPage: boolean,
    unauthorizedLastDocs: PageLastDocs,
    prevStartFrom: null | string,
    prevSearchKey: string,
    currentPage: number,
    videoTotalCount: number,
    videoRequestBody?: VideoReportBody,
    pages: any,
    auditLog: object,
}

const disabledButton = {
    disableNextButton: false,
    disablePreviousButton: true,
}

const formatPermissions = (permissions) => {
    const state = store.getState();

    const people = state.general.people;
    const devicesDetails = state.devicesData.devicesDetails;

    const updatedPermissions = permissions.map(permission => {
        const personInfo = people[permission.person];

        const deviceDetails = permission.devices.length ? permission.devices.map(device => {
            const deviceInfo = devicesDetails.getIn([device.id]) || {};

            return {
                id: device.id,
                name: deviceInfo.name,
                device: device.device,
                readOnly: device.readOnly,
                tag: device.tag,
                deviceName: deviceInfo.name
            };
        }) : [];

        return {
            displayName: personInfo.displayName,
            devices: deviceDetails,
        };
    });

    return updatedPermissions;
}

export async function getPermissionsForUsers(persons){
    const userPermissionRecords: iReportPermissionData[] = [];

    for (const person of persons) {
        const devices = [];

        const personsDevicesSnapshot = await clientDb().child(`acl/items-allowed/${person}/device`).once('value');
        const personsDevicesSnapshotValue = personsDevicesSnapshot.val();

        if (personsDevicesSnapshotValue) {
            for (const [id, deviceData] of Object.entries(personsDevicesSnapshotValue)) {
                let device;
                let tag;
                let readOnly: unknown = true;

                const personDevice = {};

                personDevice['id'] = id;

                for (const [key, value] of Object.entries(deviceData)){
                    if (value === 'tag'){
                        tag = key;
                    }
                    else if (value === 'device'){
                        device = key;
                    }
                    else if (key === 'readOnly'){
                        readOnly = value;
                    }
                }

                if (tag){
                    const tagInfoSnapshot = await clientDb().child(`tags/${tag}/details`).once('value');
                    const tagInfoValue = tagInfoSnapshot.val();
                    const tagPermissionSnapshot = await clientDb().child(`tags/${tag}/instances/allowed-see/readOnly`).once('value');
                    const tagPermissionValue = tagPermissionSnapshot.val();

                    if (tagPermissionValue && Object.keys(tagPermissionValue).includes(person)){
                        readOnly = true;
                    }

                    personDevice['tag'] = tagInfoValue.name;
                }

                if (device){
                    personDevice['device'] = device;
                }

                personDevice['readOnly'] = readOnly;

                devices.push(personDevice);
            }
        }

        userPermissionRecords.push({
            person,
            devices
        });
    }

    return formatPermissions(userPermissionRecords);
}

export async function getActivityReports(ids){
    const devices = [];

    const state = store.getState();
    const devicesLastPing = state.devicesData.devicesLastPing;
    const devicesDetails = state.devicesData.devicesDetails;


    for (const id of ids) {
        try {
            const data = devicesLastPing.getIn([id]);
            const deviceInfo = devicesDetails.getIn([id]);
            const time = data && data.time ? data.time.unix() : 0;

            const ago = data ? formatWhenLast(data?.time) : 'No info';
            if (deviceInfo) {
                devices.push({
                    deviceId: deviceInfo.id,
                    deviceName: deviceInfo.name || '',
                    time,
                    ago
                })
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    const sortedDevices = devices.sort((a, b) => {
        if (a.time === 0 && b.time === 0) return 0;
        if (a.time === 0) return 1;
        if (b.time === 0) return -1;
        return a.time - b.time;
    });

    return sortedDevices;
}

class ReportPage extends BaseComponent<IFullProps, IState> {
    constructor(props) {
        super(props);

        this.handleVisReportClick = this.handleVisReportClick.bind(this);
    }

    private dialog: DialogConfigSetter;

    state: IState = {
        loading: false,
        paginationLoading: false,
        showNewReport: true,
        records: {},
        reportData: {},
        showAllRoute: false,
        showVisibleReportData: false,
        showVisibleFencesData: false,
        allTrips: [],
        disablePaginateButton: disabledButton,
        isHaveNext: false,
        travelCurrentPage: 1,
        unauthorizedCurrentPage: 1,
        travelIsLastPage: false,
        unauthorizedIsLastPage: false,
        travelLastDocs: {1: null},
        unauthorizedLastDocs: {1: null},
        prevStartFrom: '',
        prevSearchKey: '',
        currentPage: 1,
        videoTotalCount: 0,
        pages: [],
        auditLog: {},
    };

    getTripQuery = ({ collectionName = 'trips', filters = {}, dates: { minStartDate, maxEndDate } }) => {
        const filtersKeys = Object.keys(filters);

        let query = clientStore().collection(collectionName)
            .where('startDate', '>=', minStartDate.clone().add(userCurrentOffset, 'hours').toDate())
            .where('startDate', '<', maxEndDate.clone().add(userCurrentOffset, 'hours').toDate())

        const getFirebaseQueryParams = ({ filterType, selectedFilterValue }) => {
            const { filterName, filterValue } = selectedFilterValue;

            let obj = { name: '', value: '' }
            switch (filterType) {
                case 'alerts': {
                    obj.name = `alertActivity.has${filterName.toLowerCase()}`
                    obj.value = filterValue;
                    break;
                }
                case 'device': {
                    obj.name = filterType
                    obj.value = filterName;
                    break;
                }
                // TODO need to fix it
                case 'tag': {
                    obj.name = 'device'
                    obj.value = filterValue;
                    break;
                }
                case 'labels': {
                    obj.name = 'label'
                    obj.value = filterValue;
                    break;
                }
                case 'person': {
                    obj.name = 'personId'
                    obj.value = filterName;
                    break;
                }

                default:
                    break;
            }
            return obj
        }

        if (filtersKeys.length) {
            Object.entries(filters).forEach(([filterType, filter]) => {
                if (filterType === 'tag') {
                    filterType = 'device';
                    filter = Object.entries(filter).reduce((prev, [keyName]) => {
                        const deviceIds = (this.props.tagDevices?.[keyName] as ImmutableSet<string>);

                        if (!deviceIds) return prev;

                        deviceIds?.forEach((value) => {
                            Object.assign(prev, { [value]: true })
                        })

                        return prev;
                    }, {})
                }

                let multiValues = [];
                let obj = { filterName: '', filterValue: null }
                const countOfSelectedFilters = Object.keys(filter).length;
                const moreThanOneFilter = countOfSelectedFilters > 1;
                const dynamicOperator = moreThanOneFilter ? 'in' : '==';

                Object.entries(filter).forEach(([keyName, value]) => {
                    if (moreThanOneFilter) {
                        multiValues.push(keyName);
                    } else {
                        obj.filterName = keyName;
                        obj.filterValue = value;
                    }
                });

                const { name: queryFieldName, value: queryFieldValue } = getFirebaseQueryParams({ filterType, selectedFilterValue: obj });
                let dynamicValue = moreThanOneFilter ? multiValues : queryFieldValue;

                if (queryFieldName && dynamicOperator && dynamicValue) {
                    const dynamicValueLenght = dynamicValue?.length

                    if (moreThanOneFilter && dynamicValueLenght > 10) {
                        query = query.where(queryFieldName, dynamicOperator, dynamicValue.slice(0, 10));
                    } else {
                        query = query.where(queryFieldName, dynamicOperator, dynamicValue);
                    }
                }
            });
        }

        return query;
    };

    travelTabQuery = async (props) => {

        const { next, previous, dates, filters } = props;
        const { dispatch } = this.props;
        const TRAVEL_LIMIT = 500;

        // let query: iGetReportPropsWrapper = {
        //   allTags: tags,
        //   filters,
        //   dates,
        //   devicesDetails,
        // };

        let minUtcOffset = 0;
        let maxUtcOffset = 0;

        if (filters.tag) {
            const tags = Object.keys(filters.tag);
            const devicesUtcOffset: Array<number> = [];

            for (const tag of tags) {
                const tagDevices = [];
                const tagDevicesSnapshot = await clientDb().child(`tags/${tag}/instances/device`).once('value');
                const tagDevicesSnapshotValue = tagDevicesSnapshot.val();

                tagDevices.push(...Object.keys(tagDevicesSnapshotValue))

                for (const device of tagDevices) {
                    const tagDevicesSnapshot = await clientDb().child(`devices/device-details/${device}`).once('value');
                    const tagDevicesSnapshotValue = tagDevicesSnapshot.val();

                    const utcOffset = moment.tz(tagDevicesSnapshotValue.timezone).utcOffset() / 60;
                    devicesUtcOffset.push(utcOffset);
                }
            }

            minUtcOffset = Math.min(...devicesUtcOffset);
            maxUtcOffset = Math.max(...devicesUtcOffset);
        }

        const minStartDate = dates.map(({ startDate }) => startDate).reduce((c, d): moment.Moment => !c || d.clone().isBefore(c) ? d.clone() : c.clone(), 0 as any);
        const maxEndDate = dates.map(({ endDate }) => endDate).reduce((c, d): moment.Moment => !c || d.clone().isAfter(c) ? d.clone() : c.clone(), 0 as any);
        const userCurrentOffset = parseInt(moment().format('ZZ')) / 100;

        let defaultQuery = this.getTripQuery({
            filters,
            dates: {
                minStartDate: minUtcOffset < 0 ? minStartDate.add(minUtcOffset, 'hours') : minStartDate,
                maxEndDate: maxUtcOffset > 0 ? maxEndDate.add(maxUtcOffset, 'hours') : maxEndDate,
            }
        });

        const findTripIdsInTrips = (arrayOfTripIdsThatAreInTheSelectedArea, trips) => {
            const tripsInTheSelectedAre = {};

            arrayOfTripIdsThatAreInTheSelectedArea.forEach(tripId => {
                if (trips[tripId]) {
                    tripsInTheSelectedAre[tripId] = trips[tripId];
                }
            });

            return tripsInTheSelectedAre;
        }

        if (next && this.state.allTrips.length) {
            const lastVisible = this.state.allTrips[this.state.allTrips.length - 1];

            const next = defaultQuery?.startAfter(lastVisible)?.limit(TRAVEL_LIMIT);
            const nextData = (await next.get())?.docs;

            if (!!nextData.map((item) => item.data())[0]) {
                const data = formattingData(nextData);
                dispatch(ReportActions.SET_DISPLAY_RECORDS(data));
                this.setState(prevState => {
                    return {
                        ...prevState,
                        allTrips: nextData
                    }
                })

                this.setState(prevState => {
                    return {
                        ...prevState,
                        disablePaginateButton: {
                            disableNextButton: false,
                            disablePreviousButton: false,
                        }
                    }
                })
            } else {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        disablePaginateButton: {
                            disableNextButton: true,
                            disablePreviousButton: false,
                        }
                    }
                })
                toast.error('There is no data')
            }
            this.setState({ loading: false });

            const lastVisibleForCheckNext = nextData[nextData.length - 1];
            const nextDataForCheckNext = (await defaultQuery?.startAfter(lastVisibleForCheckNext)?.limit(1).get())?.docs;

            if (!!nextDataForCheckNext.map((item) => item.data())[0]) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        isHaveNext: true,
                    }
                })
            } else {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        isHaveNext: false,
                    }
                })
            }

        } else if (previous && this.state.allTrips.length) {
            const prevVisible = this.state.allTrips[0];
            const previous = defaultQuery.orderBy('startDate').endBefore(prevVisible).limitToLast(TRAVEL_LIMIT);
            const previousData = (await previous.get()).docs;

            if (!!previousData.map((item) => item.data())[0]) {
                const data = formattingData(previousData);
                dispatch(ReportActions.SET_DISPLAY_RECORDS(data));
                this.setState(prevState => {
                    return {
                        ...prevState,
                        allTrips: previousData
                    }
                })

                this.setState(prevState => {
                    return {
                        ...prevState,
                        disablePaginateButton: {
                            disableNextButton: false,
                            disablePreviousButton: false,
                        }
                    }
                })
            } else {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        disablePaginateButton: {
                            disableNextButton: false,
                            disablePreviousButton: true,
                        }
                    }
                })
                toast.error('There is no data');
            }
            this.setState({ loading: false });

            const lastVisibleForCheckNext = this.state.allTrips[this.state.allTrips.length - 1];
            const nextDataForCheckNext = (await defaultQuery?.startAfter(lastVisibleForCheckNext)?.limit(1).get())?.docs;

            if (!!nextDataForCheckNext.map((item) => item.data())[0]) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        isHaveNext: true,
                    }
                })
            } else {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        isHaveNext: false,
                    }
                })
            }
        } else {

            let firstData;
            let data;

            if (this.props.tripsFromSelectedArea?.uniqueTripPath?.length !== 0 && this.props.tripsFromSelectedArea?.isSelectedAreaMode === true) {

                firstData = (await defaultQuery.get()).docs;
                data = formattingData(firstData);

                const arrayOfTripIdsThatAreInTheSelectedArea = this.props.tripsFromSelectedArea?.uniqueTripPath;
                const selectedAreaData = findTripIdsInTrips(arrayOfTripIdsThatAreInTheSelectedArea, data);

                dispatch(ReportActions.SET_DISPLAY_RECORDS(selectedAreaData));

            } else {
                const first = defaultQuery.limit(TRAVEL_LIMIT);
                firstData = (await first.get()).docs;

                data = formattingData(firstData);
                dispatch(ReportActions.SET_DISPLAY_RECORDS(data));
            }

            this.setState(prevState => {
                return {
                    ...prevState,
                    disablePaginateButton: {
                        disableNextButton: false,
                        disablePreviousButton: true,
                    }
                }
            })
            this.setState(prevState => {
                return {
                    ...prevState,
                    allTrips: firstData
                }
            });

            this.setState({ loading: false });

            if (firstData.length > 0) {

                const lastVisibleForCheckNext = firstData[firstData.length - 1];

                const nextDataForCheckNext = (await defaultQuery?.startAfter(lastVisibleForCheckNext)?.limit(1).get())?.docs;

                if (!!nextDataForCheckNext.map((item) => item.data())[0]) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            isHaveNext: true,
                        }
                    })
                } else {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            isHaveNext: false,
                        }
                    })
                }
            }


        }
    };
    setShowAllRoute = (bool: boolean) => this.setState({ showAllRoute: bool });

    getRecords = (data) => {
        this.setState({ records: { ...data } });
    }

    getNames = (data) => {
        const users = Object.keys(data)
        users.map(user => {
            this.setState(prevState => {
                return {
                    reportData: { ...prevState.reportData, [user]: { ...prevState.reportData[user], name: data[user] } },
                }
            })
        })
    }

    showReportTrip = (item, tripPoints, zoomToTrip?: boolean) => {
        const { dispatch, mapRef } = this.props;

        if (!tripPoints?.getIn([item.device, 'tripsPoints', item.tripId])) {
            tripPointsReal(item.tripId)
                .then(values)
                .then((points: any) => {
                    dispatch(setReportTrip(item.device, item.tripId, points));

                    if (zoomToTrip) {
                        const coordinates = Object.keys(points).reduce((prev, curr) => {
                            const { location } = points[curr].coordinates;

                            return {
                                latMin: location.lat < prev.latMin ? location.lat : prev.latMin,
                                latMax: location.lat > prev.latMax ? location.lat : prev.latMax,
                                lngMin: location.lng < prev.lngMin ? location.lng : prev.lngMin,
                                lngMax: location.lng > prev.lngMax ? location.lng : prev.lngMax,
                            }
                        }, {
                            latMin: Number.MAX_SAFE_INTEGER,
                            latMax: Number.MIN_SAFE_INTEGER,
                            lngMin: Number.MAX_SAFE_INTEGER,
                            lngMax: Number.MIN_SAFE_INTEGER,
                        })

                        const latlngList: google.maps.LatLng[] = [];
                        latlngList.push(new google.maps.LatLng(coordinates.latMax, coordinates.lngMax));
                        latlngList.push(new google.maps.LatLng(coordinates.latMin, coordinates.lngMin));

                        const bounds = new google.maps.LatLngBounds();
                        latlngList.forEach(function (n) {
                            bounds.extend(n);
                        });

                        (mapRef.current!.context[MAP] as google.maps.Map).setCenter(bounds.getCenter());
                        (mapRef.current!.context[MAP] as google.maps.Map).fitBounds(bounds)
                    }


                }).then(res => {
                    dispatch(toggleShowReportTrip(item.device, item.tripId, true));
                })
        } else {
            dispatch(toggleShowReportTrip(item.device, item.tripId, true));
        }
    }

    hideReportTrip = (item) => {
        const { dispatch } = this.props;
        dispatch(toggleShowReportTrip(item.device, item.tripId, false));
    }

    getReportData = (data) => {
        // const { dispatch } = this.props;
        this.setState(prevState => {
            return {
                reportData: { ...prevState.reportData, ...data },
            }
        })
    }

    private toggleDevicePerson = (type: 'device' | 'person') => () => pipe(ReportActions.SET_DEVICE_PERSON, this.props.dispatch)(type)

    handleVisReportClick = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                showVisibleReportData: !prevState.showVisibleReportData
            }
        })
    }

    handleVisFencesClick = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                showVisibleFencesData: !prevState.showVisibleFencesData
            }
        })
    }

    pageClick = ({ next, previous, dates, filters }) => {
        if (next) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    currentPage: prevState.currentPage + 1,
                }
            })
        };
        if (previous) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    currentPage: prevState.currentPage - 1,
                }
            })
        };
        this.setState({ loading: true });
        this.travelTabQuery({ next, previous, dates, filters });

    }

    populateData = async (props: IFullProps) => {
        const { devicesDetails, report, tags, dispatch, userToken, filters } = props;
        const dateIsRequired = report.reportType !== 'permissions' && report.reportType !== 'video' && report.reportType !== 'activity';

        if (!report.reportType && !report.searchKey) return;

        if (report.dates.length === 0 && dateIsRequired) return;

        if (report.reportType) {
            this.setState({ loading: true });
        }

        const formatAlertWithHas = (alert) => 'has' + alert.toLowerCase();

        const getPointsFromBackend = async (page) => {
            const URL = `/api/firestore/points-with-filter`;

            const clientId = localStorage.get('active-client');

            const body = {
                address: {
                    ...(filters.values?.city && {
                        city: filters.values.city
                    }),
                    ...(filters.values?.state && {
                        state: filters.values.state
                    }),
                    ...(filters.values?.street && {
                        street: filters.values.street
                    }),
                    ...(filters.values?.zip && {
                        zip: filters.values.zip
                    }),
                },
                metric: {
                    ...(filters.values?.fuel && {
                        fuel: filters.values.fuel
                    }),
                    ...(filters.values?.mpg && {
                        mpg: filters.values.mpg
                    }),
                    ...(filters.values?.rpm && {
                        rpm: filters.values.rpm
                    }),
                    ...(filters.values?.speed && {
                        speed: filters.values.speed
                    }),
                    ...(filters.values?.volts && {
                        volts: filters.values.volts
                    }),
                },
                ...(filters.device && {
                    device: Object.keys(filters.device)
                }),
                ...(filters.person && {
                    people: Object.keys(filters.person)
                }),
                ...(filters.tag && {
                    tag: Object.keys(filters.tag)
                }),
                ...(filters.alerts && {
                    alerts: Object.keys(filters.alerts).map(alert => formatAlertWithHas(alert))
                }),
                ...(filters.labels && {
                    label: Object.keys(filters.labels)
                }),
                startDate: moment(report.dates[0].startDate).unix(),
                endDate: moment(report.dates[0].endDate).unix(),
                clientId: clientId,
                page,
            }

            try {
                let formattedPoints: any = [];
                const { data } = await instance.post(URL, { ...body });

                Object.values(data.content).map((point: any) => {
                    formattedPoints[point.pointId] = makePointFromEndpointData(point)
                })

                await dispatch(ReportActions.SET_DISPLAY_RECORDS(formattedPoints))

                this.setState({ loading: false })
                return data;
            } catch ({ message }) {
                this.setState({ loading: false })
                console.error(message)
            }
        }

        const getTripsFromBackend = async () => {
            const URL = `/api/firestore/trip`;
            const allTripsURL = `/api/firestore/trip-report`;
            const clientId = localStorage.get('active-client');
            const startFrom = this.state.travelLastDocs[this.state.travelCurrentPage];

            if (startFrom === this.state.prevStartFrom && report.searchKey === this.state.prevSearchKey) return;
            this.setState({prevStartFrom: startFrom});

            const body = {
                ...(filters.device && {
                    firebaseDeviceIds: Object.keys(filters.device)
                }),
                ...(filters.person && {
                    people: Object.keys(filters.person)
                }),
                ...(filters.tag && {
                    firebaseTagIds: Object.keys(filters.tag)
                }),
                ...(filters.alerts && {
                    alert: Object.keys(filters.alerts).map(alert => formatAlertWithHas(alert))
                }),
                ...(filters.labels && {
                    labels: Object.keys(filters.labels)
                }),
                ...(this.props.tripsFromSelectedArea.uniqueTripPath.length > 0) && {
                    firebaseTripIds: this.props.tripsFromSelectedArea.uniqueTripPath
                },
                startTime: moment(report.dates[0].startDate).unix(),
                endTime: moment(report.dates[0].endDate).unix(),
                organizationKey: clientId,
                startAfterDoc: startFrom,
                limit: 20
            }

            const allTripsBody = {
                startTime: moment(report.dates[0].startDate).unix(),
                endTime: moment(report.dates[0].endDate).unix(),
                organizationKey: clientId,
                ...(filters.tag && {
                    firebaseTagIds: Object.keys(filters.tag)
                }),
                ...(filters.device && {
                    firebaseDeviceIds: Object.keys(filters.device)
                }),
                ...(filters.alerts && {
                    alert: Object.keys(filters.alerts).map(alert => formatAlertWithHas(alert))
                }),
            }

            try {
                const formattedTrips: any = [];

                const { data } = await instance.post(URL, { ...body });
                const { data: allTripsData } = await instance.post(allTripsURL, { ...allTripsBody });

                if (allTripsData && allTripsData.data) {
                    await dispatch(ReportActions.SET_TRAVEL_TRIP_IDS(allTripsData.data));
                }

                this.setState({travelIsLastPage: data.isLastPage});

                if (data.lastDoc) {
                    this.setState({
                        travelLastDocs: {
                            ...this.state.travelLastDocs,
                            [this.state.travelCurrentPage + 1]: data.lastDoc
                        }
                    });
                }

                const records = data.results;

                if (records){
                    records.map(trip => {
                        formattedTrips[trip.id] = {
                            ...makeTripFromEndpointData(trip.data),
                            tripId: trip.id,
                        };
                    })
                }

                await dispatch(ReportActions.SET_DISPLAY_RECORDS(formattedTrips))
                this.setState({ loading: false })
            } catch ({ message }) {
                this.setState({ loading: false })
                console.error(message)
            }
        }

        const getUnauthorizedTripsFromBackend = async () => {
            const URL = '/api/work-schedule/report-work-schedule';
            const clientId = localStorage.get('active-client');
            const startFrom = this.state.unauthorizedLastDocs[this.state.unauthorizedCurrentPage];
            const body = {
                ...(filters.device && {
                    firebaseDeviceIds: Object.keys(filters.device)
                }),
                ...(filters.person && {
                    people: Object.keys(filters.person)
                }),
                ...(filters.tag && {
                    firebaseTagIds: Object.keys(filters.tag)
                }),
                ...(filters.alerts && {
                    alert: Object.keys(filters.alerts).map(alert => formatAlertWithHas(alert))
                }),
                ...(filters.labels && {
                    labels: Object.keys(filters.labels)
                }),
                startTime: moment(report.dates[0].startDate).unix(),
                endTime: moment(report.dates[0].endDate).unix(),
                organizationKey: clientId,
                startAfterDoc: startFrom,
                limit: 20
            };

            if (startFrom === this.state.prevStartFrom && report.searchKey === this.state.prevSearchKey) return;
            this.setState({ prevStartFrom: startFrom, loading: true });

            try {
                const formattedTrips: any = [];
                const { data } = await instance.post(URL, {...body});
                const records = data.results;

                this.setState({unauthorizedIsLastPage: data.isLastPage});

                if (data.lastDoc) {
                    this.setState({
                        unauthorizedLastDocs: {
                            ...this.state.unauthorizedLastDocs,
                            [this.state.unauthorizedCurrentPage + 1]: data.lastDoc
                        }
                    });
                }

                if (records){
                    records.forEach(trip => {
                        formattedTrips[trip.id] = {
                            ...makeTripFromEndpointData(trip.data),
                            tripId: trip.id,
                        };
                    });
                }

                if (data.tripIds) {
                    await dispatch(ReportActions.SET_TRAVEL_TRIP_IDS(data.tripIds));
                }

                await dispatch(ReportActions.SET_DISPLAY_RECORDS(formattedTrips));
            } catch (e) {
                console.error(e)
            } finally {
                this.setState({ loading: false });
            }
        }

        const getPointsOrIncreasePage = async () => {
            try {
                let pageHasData = false;

                const data = await getPointsFromBackend(this.state.currentPage);

                if (Object.keys(data.content).length) {
                    pageHasData = true;
                }

                this.setState(prevState => {
                    return {
                        ...prevState,
                        pages: pageHasData ? [...prevState.pages, data.currentPage] : [...prevState.pages],
                        currentPage: data.currentPage,
                        isHaveNext: data.nextPage,
                    }
                })
            } catch ({ message }) {
                console.error(message);
            }
        }

        const getAudit = async () => {
            if (!ACL.check(UserCan.DO_ANYTHING, this.props.userCanDo, true)) {
                this.setState({ loading: false })
                return;
            }
            
            const auditLog = await getAuditLog();

            this.setState({ auditLog: getFilteredData(auditLog, report.filters, report.dates), loading: false })
        }

        let query: iGetReportPropsWrapper = {
            allTags: tags,
            filters: report.filters,
            dates: report.dates,
            devicesDetails,
        };

        // dispatch(ReportAC.fetchDevicePing(query, userToken?.devices || false));
        // don't forget that trips that have start dates before the search start date
        // can happend since the trip end date may be within range.

        if (this.props.reportTypeFromRedux === 'travel') {
            getTripsFromBackend();
        } else if (this.props.reportTypeFromRedux === 'summary') {
            const trips = await getTrips(query, userToken?.devices || false);
            dispatch(ReportActions.SET_DISPLAY_RECORDS(trips));
            this.setState({ loading: false })
        } else if (this.props.reportTypeFromRedux === 'static') {
            getPointsOrIncreasePage();
        } else if (this.props.reportTypeFromRedux === "geofence") {
            let pointsWithGeofences = await getGeonfeces(query, userToken?.devices || false);
            dispatch(ReportActions.SET_DISPLAY_RECORDS(pointsWithGeofences));
            this.setState({ loading: false })
        } else if (this.props.reportTypeFromRedux === 'time-clock') {
            const trips = await getTrips(query, userToken?.devices || false);
            dispatch(ReportActions.SET_DISPLAY_RECORDS(props.selectionsList.length > 0 && validate(props.selectionsList) ? filterRecords(props.selectionsList, trips) : trips));
            this.setState({ loading: false });
        }  else if (this.props.reportTypeFromRedux === 'video') {
            let deviceFilters = [];
            let tagFilters = [];
            const clientId = localStorage.get('active-client');
            const url = '/api/device/video-download-report';

            if (filters.device ) deviceFilters = Object.keys(filters.device);
            if (filters.tag) tagFilters = Object.keys(filters.tag);

            const currentDate = moment();

            const startDate = report.dates && report.dates[0] ? moment(report.dates[0].startDate) : currentDate.startOf('day');
            const endDate = report.dates && report.dates[0] ? moment(report.dates[0].endDate) : currentDate.endOf('day');

            const startTime = startDate.unix().toString();
            const endTime = endDate.unix().toString();

            const body = {
                deviceIds: deviceFilters,
                tagIds: tagFilters,
                orgKey: clientId,
                startTime,
                endTime
            }

            if (deviceFilters.length > 0) {
                body['deviceIds'] = deviceFilters;
            }
            else if (deviceFilters.length === 0) {
                body['deviceIds'] = Object.keys(devicesDetails.toObject());
            }

            if (tagFilters.length > 0) {
                body['tagIds'] = tagFilters;
            }

            try {
                const { data } = await instance.post(url, { ...body });
                const totalCount = Number(data.totalCount);

                dispatch(ReportActions.SET_DISPLAY_RECORDS(data.data));

                this.setState({
                    videoTotalCount: totalCount,
                    videoRequestBody: body
                })
            } catch (error) {
                console.error(error)
            }

            this.setState({ loading: false });
        } else if (this.props.reportTypeFromRedux === 'permissions') {
            const persons = filters.person ? Object.keys(filters.person) : Object.keys(this.props.people);

            const records = await getPermissionsForUsers(persons.slice(0, 10));

            dispatch(ReportActions.SET_DISPLAY_RECORDS(records));
            dispatch(ReportActions.SET_PERMISSION_PERSONS(persons));

            this.setState({ loading: false });
        } else if (this.props.reportTypeFromRedux === 'audit-log') {
            getAudit();
        } else if (this.props.reportTypeFromRedux === 'unauthorized') {
            getUnauthorizedTripsFromBackend();
        } else if (this.props.reportTypeFromRedux === 'activity') {
            let ids = [];

            if (filters.device ) {
                const deviceFilters = Object.keys(filters.device);
                ids = [...deviceFilters];
            }
            else if (filters.tag) {
                const setOfIds = new Set();
                const tagFilters = Object.keys(filters.tag);

                for (const tag of tagFilters) {
                    const tagDevicesSnapshot = await clientDb().child(`tags/${tag}/instances/device`).once('value');
                    const tagDevices = tagDevicesSnapshot.val() ? Object.keys(tagDevicesSnapshot.val()) : [];

                    for (const tagDevice of tagDevices) {
                        setOfIds.add(tagDevice);
                    }
                }

                ids = Array.from(setOfIds);
            }
            else {
                ids = Object.keys(devicesDetails.toObject());
            }

            const sortedRecords = await getActivityReports(ids);

            await dispatch(ReportActions.SET_DISPLAY_RECORDS(sortedRecords));

            this.setState({ loading: false });
        }
    }

    staticPageClick = ({ next, previous }) => {
        if (next) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    currentPage: prevState.currentPage + 1,
                }
            })
        };

        if (previous) {
            this.setState(prevState => {
                const prevPage = prevState.pages[prevState.pages.length - 2];
                
                if (!prevPage) {
                    return {
                        ...prevState,
                        currentPage: 1,
                        pages: [],
                    }
                }

                return {
                    ...prevState,
                    currentPage: prevPage,
                    pages: prevState.pages.slice(0, prevState.pages.length - 2)
                }
            })
        };
        
        setTimeout(() => {
            this.populateData(this.props);
        }, 100);
    }

    async componentDidMount() {
        const { dispatch } = this.props;

        dispatch(ReportActions.CANCEL_CELL_PICKER());
        dispatch(ReportActions.TOGGLE_ITEM_FILTER('types', 'travel'));

        if (this.props.report.reportType !== this.props.reportType) {
            dispatch(ReportActions.SET_TYPE(this.props.reportType));
        }

        if (this.props.report.searchKey) {
            this.populateData(this.props)
        };

        if (localStorage.get(`matt-report-dev-${this.props.reportType}`)) {
            const report = localStorage.get<iReportState>(`matt-report-dev-${this.props.reportType}`);

            report.details.dates = report.details.dates
                .map(({ startDate, endDate }) => ({
                    startDate: moment(startDate),
                    endDate: moment(endDate),
                }));

            setTimeout(() => dispatch({ type: 'GEN_SET', val: report, path: ['report'] }), 100)
        }
    }

    componentWillUnmount(): void {
        this.props.dispatch(ReportActions.SET_TYPE('travel'));
        this.props.dispatch({
            type: "REPORT_SET_DISPLAY_RECORDS",
            displayRecords: {},
        });
    }
    componentDidUpdate(prevProps, prevState) {
        const { report, dispatch, tripsFromSelectedArea } = this.props;
        const { records, reportData } = this.state;

        if (report.searchKey !== prevProps.report.searchKey){
            this.setState({
                prevStartFrom: '',
                prevSearchKey: '',
                travelLastDocs: {1: null},
                travelCurrentPage: 1,
                travelIsLastPage: false,
                unauthorizedLastDocs: {1: null},
                unauthorizedCurrentPage: 1,
                unauthorizedIsLastPage: false
            });
        };

        if (prevProps.report !== report || prevState.records !== records || prevState.reportData !== reportData) {
            dispatch(GMapModalAC.setParams({ params: { records, reportData, report } }))
        }

        if (this.props.filters !== prevProps.filters) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    currentPage: 1,
                }
            })
        }

        if (tripsFromSelectedArea.uniqueTripPath.length && !compareArrays(tripsFromSelectedArea.uniqueTripPath, prevProps.tripsFromSelectedArea.uniqueTripPath)){
            this.populateData(this.props);
        }
    }

    private choiceUpdate = async (setVal) => {
        const { dispatch, displayRecords, choosing } = this.props;

        if (!choosing) return;

        const oldRecord = idValArr(displayRecords).filter(recordMatches(choosing.affectedRowCriteria))[0].val;
        const oldVal = choosing.pickType === 'personId' ? oldRecord.personId : oldRecord.label;

        // optimistic update
        dispatch(ReportActions.UPDATE_DISPLAY_RECORDS_MATCHING(choosing.affectedRowCriteria, choosing.pickType, setVal));
        dispatch(ReportActions.CANCEL_CELL_PICKER());

        try {
            await choosing.updater(setVal);
        } catch (e) {
            console.log(e);
            dispatch(ReportActions.UPDATE_DISPLAY_RECORDS_MATCHING(choosing.affectedRowCriteria, choosing.pickType, oldVal));

            this.dialog?.({
                title: 'Problem encountered',
                body: 'An unexpected problem was encountered while changing the assigment. We had to change it back. If you continue to encounter this problem please notify us.',
                type: 'NOTIFICATION',
            });
        }
    }

    setupDialog = (callBack: () => DialogConfigSetter): void => {
        this.dialog = callBack();
    }

    async componentWillReceiveProps(props) {
        if (this.props.report.searchKey !== props.report.searchKey) {
            this.populateData(props);
        }

        if (props.report.reportType !== props.reportType) {
            this.props.dispatch(ReportActions.SET_TYPE(props.reportType));
        }
    }

    _clearRecords = () => {
        this.props.dispatch({ type: 'REPORT_SET_DISPLAY_RECORDS', displayRecords: {} });
    }

    saveReport = () => {
        const { records, reportData } = this.state;
        const { report } = this.props

        const pdf = new jsPDF();
        const devices = Object.keys(records)

        devices.map((item, index) => {
            if (report.reportType === 'travel') {
                records[item].map((row) => {
                    const deviceOffset = utcOffset(reportData[item].deviceTimezone, moment().isDST());
                    const diff = userCurrentOffset - deviceOffset;
                    const start = moment(row.startDate).subtract(diff, 'hours');
                    const end = moment(row.endDate).subtract(diff, 'hours');

                    autoTable(pdf, {
                        head: [[reportData[item].name, start.format('M/D/YY'), '', `${start.format('h:mmA')} - ${row.startAddress}`]],
                        body: [
                            ['', 'Duration:', friendlyDiff(moment(row.endDate), moment(row.startDate))],
                            ['', 'Idle:', minutesToFriendly(row.idleMinutes)],
                            ['', 'Stopped:', minutesToFriendly(row.stopMinutes)],
                            ['', 'Safety:', row.safetyPercent ? row.safetyPercent + '%' : 'NA'],
                            ['', 'Avg. Speed:', `${row.averageSpeed ? friendlySpeed(row.averageSpeed, reportData[item].deviceUnits) : 'NA'}`],
                            ['', 'Consumption:', friendlyMilesPer(row.mpg, reportData[item].deviceUnits)],
                        ],
                        foot: [['', '', '', `${end.format('h:mmA')} - ${row.endAddress}`]]
                    })
                })
            }
            if (report.reportType === 'static') {
                const data = records[item].map((row) => {
                    const deviceOffset = utcOffset(reportData[item].deviceTimezone, moment().isDST());
                    const diff = userCurrentOffset - deviceOffset;
                    const dateWithCorrection = moment(row.time).subtract(diff, 'hours');

                    return [dateWithCorrection.format('MMM-D'), dateWithCorrection.format('h:mmA'), row.fuel, row.speed, row.posted_speed, row.mpg,
                    `${row.address.street} ${row.address.city} ${row.address.state} ${row.address.zip}`,
                    `${row.coordinates.location['lat']}, ${row.coordinates.location['lng']}`,
                    ]
                })

                autoTable(pdf, {
                    theme: 'grid',
                    headStyles: { fillColor: '#2E80BA' },
                    head: [[reportData[item].name, 'Data', 'Fuel', 'mph', 'Posted', 'mpg', 'Location', 'Coordinates']],
                    body: data,
                })
            }
        })

        pdf.save('Report.pdf')
    }

    debugRender = () => {
        const { report, dispatch, labels, people, devicesDetails, displayRecords, choosing, reportType, userCanDo, dates, filters } = this.props;
        const { loading, disablePaginateButton, records, reportData, showVisibleReportData, showVisibleFencesData } = this.state;

        const activeIdx = ['static', 'travel', 'scheduled', 'queue'].indexOf(report.reportType as any);
        const loginToken = localStorage.get('login-init-be-token');

        let overlay;
        if (choosing && choosing.pickType === 'label')
            overlay =
                <Overlay header="Select label" close={pipe(ReportActions.CANCEL_CELL_PICKER, dispatch) as any}>
                    <LabelChooser type="trip" canAdd choose={this.choiceUpdate} items={labels.map(l => ({ id: l, content: l }))} />
                </Overlay>
        if (choosing && choosing.pickType === 'personId')
            overlay =
                <Overlay close={pipe(ReportActions.CANCEL_CELL_PICKER, dispatch) as any}>
                    <LabelChooser
                        type="person"
                        items={vals(people).map(p => ({
                            id: p.id, content: <div style={{ color: '#000' }}>
                                <PersonBox compressed personId={p.id} person={p} active={false} />
                            </div>
                        }))}
                        choose={this.choiceUpdate} />
                </Overlay>

        const seeAllReports = ACL.check(UserCan.SEE_ALL_REPORTS, userCanDo);
        const isAdmin = ACL.check(UserCan.DO_ANYTHING, userCanDo, true);     
        const showSummaryModal = !isMobile && seeAllReports && report.searchKey && this.props.reportType !== 'audit-log'
        const startDate = moment.min(report.dates.map(d => d.startDate));
        const endDate = moment.max(report.dates.map(d => d.endDate));

        const openBottomShadowModal = () => dispatch(GMapModalAC.showModal({ contentType: GMapModalContent.AVERAGE_VALUES }));

        const handleExport = () => dispatch(GMapModalAC.showModal({ contentType: GMapModalContent.EXPORT_REPORT, params: { report, records, reportData } }));
        const handleFilters = () => dispatch(GMapModalAC.showModal({ contentType: GMapModalContent.REPORT_PAGE_FILTERS }));

        const handleClick = (element) => {
            this._clearRecords();
            dispatch(ReportActions.SET_TYPE_FROM_REDUX(element));
        }

        return <>
            <DashboardBlock title="Reports" overlay={
                <>
                    {overlay}
                    {showSummaryModal && (
                        <LocalModalActions
                            userCanDo={userCanDo}
                            seeAllReports={seeAllReports}
                            records={records}
                            reportData={reportData}
                            report={report}
                        />
                    )}
                </>
            }>
                <ReportNavbar />
                <div className="report-page-container">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="report-header-wrapper" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: "column" }}>
                            {/* Item Type Filters */}
                            {!report.searchKey ? null : (
                                <>
                                    <FilterIndicators />

                                    <div className="report-page-subnav-section">
                                        {!loading && !(this.props.tripsFromSelectedArea?.isSelectedAreaMode === true) && (
                                            <ReportSubNavbar>
                                                <ReportSubNav to="/reports/new/static" onClick={() => handleClick('static')}>Static</ReportSubNav>
                                                <VR className="h-8 mv-8" />
                                                <ReportSubNav to="/reports/new/travel" onClick={() => handleClick('travel')}>Travel</ReportSubNav>
                                                <VR className="h-8 mv-8" />
                                                <ReportSubNav to="/reports/new/geofence" onClick={() => handleClick('geofence')}>Geofence</ReportSubNav>
                                                <VR className="h-8 mv-8" />
                                                <ReportSubNav to="/reports/new/summary" onClick={() => handleClick('summary')}>Summary</ReportSubNav>
                                                <VR className="h-8 mv-8" />
                                                <ReportSubNav to="/reports/new/time-clock" onClick={() => handleClick('time-clock')}>Time Clock</ReportSubNav>
                                                <VR className="h-8 mv-8" />
                                                <ReportSubNav to="/reports/new/maintenance" onClick={() => this._clearRecords()}>Maintenance</ReportSubNav>
                                                <VR className="h-8 mv-8" />
                                                <ReportSubNav to="/reports/new/video" onClick={() => handleClick('video')}>Video</ReportSubNav>
                                                <VR className="h-8 mv-8" />
                                                <ReportSubNav to="/reports/new/unauthorized" onClick={() => handleClick('unauthorized')}>Unauthorized</ReportSubNav>
                                                <VR className="h-8 mv-8" />
                                                {loginToken['do-anything'] && <ReportSubNav to="/reports/new/permissions" onClick={() => this._clearRecords()}>Permissions</ReportSubNav>}
                                                <VR className="h-8 mv-8" />
                                                {isAdmin && (
                                                    <ReportSubNav to="/reports/new/audit-log" onClick={() => handleClick('audit-log')}>Audit Log</ReportSubNav>
                                                )}
                                                <VR className="h-8 mv-8" />
                                                <ReportSubNav to="/reports/new/activity" onClick={() => handleClick('activity')}>Activity</ReportSubNav>
                                                <VR className="h-8 mv-8" />
                                            </ReportSubNavbar>
                                        )}

                                        {/* Device Person Toggle */}
                                        <div className="filter-btn-container">
                                            <div className="restrictive-container">
                                                <div className="visible-report-data-container">
                                                    <Button
                                                        styleType={ButtonStyles.PLAIN_INACTIVE}
                                                        size={ButtonSize.XSM}
                                                        onClick={this.handleVisReportClick}
                                                    >
                                                        <VisibilitySvg className="icon-sm" /> Visible Report Data
                                                    </Button>
                                                </div>
                                                {report.reportType === 'geofence' &&
                                                    (<div className="visible-report-data-container">
                                                        <Button
                                                            styleType={ButtonStyles.PLAIN_INACTIVE}
                                                            size={ButtonSize.XSM}
                                                            onClick={this.handleVisFencesClick}
                                                        >
                                                            <VisibilitySvg className="icon-sm" /> Visible Fences
                                                        </Button>
                                                    </div>)
                                                }
                                            </div>

                                        </div>

                                        <div className="device-person-toggle">
                                            <Button
                                                styleType={
                                                    report.devicePerson === "device"
                                                        ? ButtonStyles.BLACK_WHITE
                                                        : ButtonStyles.GRAY_INACTIVE
                                                }
                                                size={ButtonSize.XSM}
                                                onClick={this.toggleDevicePerson('device')}
                                            >
                                                <DeviceSvg
                                                    className="icon-sm"
                                                />
                                            </Button>
                                            <Button
                                                styleType={
                                                    report.devicePerson === "device"
                                                        ? ButtonStyles.GRAY_INACTIVE
                                                        : ButtonStyles.BLACK_WHITE
                                                }
                                                size={ButtonSize.XSM}
                                                onClick={this.toggleDevicePerson('person')}
                                            >
                                                <PeopleSvg
                                                    className="icon-sm"
                                                />
                                            </Button>
                                        </div>
                                        <ShowOnMap showAllRoute={this.state.showAllRoute} setShowAllRoute={this.setShowAllRoute} />
                                        <div className="for-print" style={{ whiteSpace: "nowrap" }}>
                                            Grouped by: {report.devicePerson === 'device' ? 'Device' : 'Person'}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    {/* !isFullModal ? null :
                        <Row justify="center">
                            <div className="inline-gmap-wrapper" style={{height: 250, width: 250, position: 'relative', marginTop: 20}}>
                                <GMaps />
                            </div>
                        </Row>
                    */}
                    {/* Table */}
                    {(!report.searchKey) ? <FilterPromptBlock /> :
                        <div className='report-tables'  >
                            {loading
                                ? <div style={{ width: '100%', fontSize: 25, textAlign: 'center' }}><Fa icon={faSpinner} spin /></div>
                                : this.props.reportType === 'travel' || this.props.reportType === 'unauthorized' || this.props.reportType === 'time-clock' || this.props.reportType === 'permissions' ? (
                                    <>
                                        {((this.props.reportType === 'travel' || this.props.reportType === 'unauthorized') && !!this.state.allTrips.length) && (
                                            <div className='travelPagination'>
                                                {this.state.currentPage > 1 ? (<button className={classNames({ 'paginateBtn': true })} onClick={() => this.pageClick({ next: false, previous: true, dates: dates, filters })}><ChevronLeft style={{ width: 23, height: 23 }} /> Previous</button>)
                                                    : (<button disabled className={classNames({ 'paginateBtnDisabled': true })}><ChevronLeft style={{ width: 23, height: 23 }} /> Previous</button>)}

                                                {this.state.isHaveNext === true ?
                                                    (<button className={classNames({ 'paginateBtn': true })} onClick={() => this.pageClick({ next: true, previous: false, dates: dates, filters })}>Next <ChevronRight style={{ width: 23, height: 23 }} /></button>)
                                                    : (<button disabled className={classNames({ 'paginateBtnDisabled': true })}>Next <ChevronRight style={{ width: 23, height: 23 }} /></button>)}
                                            </div>
                                        )}
                                        <TripTravelTablePure
                                            showAllRoute={this.state.showAllRoute}
                                            setShowAllRoute={this.setShowAllRoute}
                                            getRecords={this.getRecords}
                                            getReportData={this.getReportData}
                                            showReportTrip={this.showReportTrip}
                                            hideReportTrip={this.hideReportTrip}
                                            isTimeClock={this.props.reportType === 'time-clock'}
                                            showVisibleReportData={showVisibleReportData}
                                        />
                                        {(this.props.reportType === 'travel' || this.props.reportType === 'unauthorized') && <div className="travel-report-pagination">
                                            {(this.state.travelCurrentPage > 1 || this.state.unauthorizedCurrentPage > 1) && lCount(displayRecords) > 0 &&
                                              <button onClick={() => {
                                                  if (this.props.reportType === 'travel') {
                                                      this.setState({travelCurrentPage: this.state.travelCurrentPage - 1}, () => {
                                                          this.populateData(this.props);
                                                      });
                                                  }
                                                  else {
                                                      this.setState({unauthorizedCurrentPage: this.state.unauthorizedCurrentPage - 1}, () => {
                                                          this.populateData(this.props);
                                                      });
                                                  }
                                              }}>Previous</button>}
                                            {((this.props.reportType === 'travel' && !this.state.travelIsLastPage) || (this.props.reportType === 'unauthorized' && !this.state.unauthorizedIsLastPage)) && lCount(displayRecords) > 0 &&
                                              <button onClick={() => {
                                                  if (this.props.reportType === 'travel') {
                                                      this.setState({travelCurrentPage: this.state.travelCurrentPage + 1}, () => {
                                                          this.populateData(this.props);
                                                      });
                                                  }
                                                  else {
                                                      this.setState({unauthorizedCurrentPage: this.state.unauthorizedCurrentPage + 1}, () => {
                                                          this.populateData(this.props);
                                                      });
                                                  }
                                              }}>Next</button>}
                                        </div>}
                                        {(this.props.reportType === "travel" && !!this.state.allTrips.length) && (
                                            <div className='travelPaginationBottom'>
                                                {this.state.currentPage > 1 ? (<button className={classNames({ 'paginateBtn': true })} onClick={() => this.pageClick({ next: false, previous: true, dates: dates, filters })}><ChevronLeft style={{ width: 23, height: 23 }} /> Previous</button>)
                                                    : (<button disabled className={classNames({ 'paginateBtnDisabled': true })}><ChevronLeft style={{ width: 23, height: 23 }} /> Previous</button>)}

                                                {this.state.isHaveNext === true ?
                                                    (<button className={classNames({ 'paginateBtn': true })} onClick={() => this.pageClick({ next: true, previous: false, dates: dates, filters })}>Next <ChevronRight style={{ width: 23, height: 23 }} /></button>)
                                                    : (<button disabled className={classNames({ 'paginateBtnDisabled': true })}>Next <ChevronRight style={{ width: 23, height: 23 }} /></button>)}
                                            </div>
                                        )}
                                    </>
                                ) : this.props.reportType === "static" ? (
                                    <>
                                        <TripTravelTablePure
                                            showAllRoute={this.state.showAllRoute}
                                            setShowAllRoute={this.setShowAllRoute}
                                            getRecords={this.getRecords}
                                            getReportData={this.getReportData}
                                            showReportTrip={this.showReportTrip}
                                            hideReportTrip={this.hideReportTrip}
                                            isTimeClock={false}
                                            showVisibleReportData={showVisibleReportData}
                                        />
                                        {!!this.state.pages.length && (
                                            <div className='travelPaginationBottom'>
                                                {this.state.currentPage !== this.state.pages[0]  ? (<button className={classNames({ 'paginateBtn': true })} onClick={() => this.staticPageClick({ next: false, previous: true })}><ChevronLeft style={{ width: 23, height: 23 }} /> Previous</button>)
                                                    : (<button disabled className={classNames({ 'paginateBtnDisabled': true })}><ChevronLeft style={{ width: 23, height: 23 }} /> Previous</button>)}

                                                {this.state.isHaveNext === true ?
                                                    (<button className={classNames({ 'paginateBtn': true })} onClick={() => this.staticPageClick({ next: true, previous: false })}>Next <ChevronRight style={{ width: 23, height: 23 }} /></button>)
                                                    : (<button disabled className={classNames({ 'paginateBtnDisabled': true })}>Next <ChevronRight style={{ width: 23, height: 23 }} /></button>)}
                                            </div>
                                        )}
                                    </>
                                ) : (this.props.reportType === 'geofence' ? <GeofenceReport showVisibleFencesData={showVisibleFencesData} /> : this.props.reportType === 'audit-log' ? <AuditReport data={this.state.auditLog}/> : this.props.reportType === 'video' ? <VideoReport totalCount={this.state.videoTotalCount} body={this.state.videoRequestBody}/> : this.props.reportType === 'activity' ? <ActivityReport/> : <SummaryReport showReportTrip={this.showReportTrip} hideReportTrip={this.hideReportTrip} />)
                            }
                        </div>
                    }

                    {/* No data indicator */}
                    {!report.searchKey || this.state.loading || lCount(displayRecords) > 0 || Object.keys(this.state.auditLog).length ? null :
                        <MinimalTile className="minimal-tile" style={{ marginTop: 10, textAlign: 'center' }}>
                            <p>No data was found for you filter criteria.</p>
                            <FilterPrompt />
                        </MinimalTile>
                    }
                </div>
            </DashboardBlock>
            <Dialog setupConfig={this.setupDialog} />
        </>
    }
}

export default compose(connect(mapStateToProps), React.memo)(ReportPage);

const FilterPrompt = connect(
    (s: iFullStoreState): { details: iReportDetails, gMapModal: iGMapModal } => ({ details: s.report.details, gMapModal: s.gMapModal })
)(({ details }: { details: iReportDetails, gMapModal }) => {
    const dispatch = useDispatch();
    const showReportFiltersModal = () => {
        dispatch(GMapModalAC.showModal({ contentType: GMapModalContent.REPORT_PAGE_FILTERS, params: { fullHeight: true } }));
    }

    return (
        <Button
            styleType={ButtonStyles.BLACK_WHITE}
            size={ButtonSize.MD}
            onClick={showReportFiltersModal}
        >
            <span className={"align-center"}><FilterListSvg className="icon-md" style={{ marginRight: "5px" }} />Report Filters</span>
        </Button>
    )
});

const FilterPromptBlock = () => {
    return (
        <div className="report-filter-begin">
            <p>Select report filters to begin.</p>
            <FilterPrompt />
        </div>
    )
}

type IFilterIndicatorProps = {
    type: string;
    reportType: string;
}
type IFilterIndicatorPropsFromStore = {
    report: iReportDetails;
}

const FilterIndicatorItem: FC<{ className?: string }> = ({ children, className = "" }) => {
    const dispatch = useDispatch();
    const handleClick = () => dispatch(GMapModalAC.showModal({ contentType: GMapModalContent.REPORT_PAGE_FILTERS }));
    return (
        <Button
            className={`filter-indicator-item ${className}`}
            styleType={ButtonStyles.WHITE_GRAY}
            size={ButtonSize.SM}
            onClick={handleClick}
        >
            {children}
        </Button>
    )
}
