import {FingerprintHelper} from "./FingerPrintHelper";
import instance from "../../../api/instance";

const fingerprintHelper = new FingerprintHelper();

const saveEncryptedToken = (username:string):void => {
    instance.post(`${process.env.REACT_APP_API_URL}/api-fingerprint/register-store-token`, {"device_id": window['device'].uuid, username})
    .then(({ data }) => data)
        .then(res => {
            console.log('save data', res);
            if (res.status === "OK") {
            } else {
                console.log("saveEncryptedToken() Error: " + JSON.stringify(res));
            }
        })
}

const doFingerprintAuthentication = (encrData:any):void => {
    const options:any = {
        secret: encrData.secret,
        username: encrData.username
    };
    if (window["device"].platform === "iOS") {
        options.ios = {
            message: "scanning"
        };
    }
    fingerprintHelper.authenticate(options, (error, result) => {
        if (error) {
            console.log("authentication error: " + JSON.stringify(error));
            window.alert('authentication error' + JSON.stringify(error));
        } else {
            if (window["device"].platform === "iOS") {
                result.token = encrData.secret;
            }
            saveEncryptedToken(encrData.username);
        }
    });
}

const getFingerprintCredentialsToEncrypt = ():void => {
    instance.post(`${process.env.REACT_APP_API_URL}/api-fingerprint/register-get-secret`, {"device_id": window['device'].uuid})
    .then(({ data }) => data)
        .then(res => {
        console.log('data', res);
        if (res.status === "OK") {
            doFingerprintAuthentication({secret: res.fingerprint_secret, username: res.username});
        }
    })
        .catch(error =>  {
            console.log("error: " + JSON.stringify(error));
            window.alert('error' + JSON.stringify(error));
        })
}

export const startFingerprintAuthFlow = ():void => {
    fingerprintHelper.isFingerprintAvailable((error, result) => {
        if (error) {
            window.alert("isFingerpringAvailable error: " + error);
        } else {
            console.log("getFingerprintCredentialsToEncrypt");
            getFingerprintCredentialsToEncrypt();
        }
    })
}
