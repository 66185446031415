import { toast } from "react-toastify";
import instance from "../../../../api/instance";
// import { store } from "../../../../stores/store";
// const { billing } = store.getState();

interface GetRequests {
  endPoint: string;
  dispatch: Function;
  action: Function;
}

export const getRequests = ({
  endPoint,
  dispatch,
  action,
}: GetRequests) => {
  return instance
    .get(`api/billing/${endPoint}`)
    .then((res) => {
      dispatch(action(res.data));
    })
    .catch((err) =>
      console.log("ERROR: plaid/braintree token or cards =>", err)
    );
};

export const deleteRequest = ({
  id,
  url,
  dispatch,
  action,
  loading,
}: any) => {
  instance
    .delete(`${url}`)
    .then(({data: { message }}) => {
      dispatch(action(id));
      loading(false);
      toast.success(message);
    })
    // need to change after upadte backend
    .catch((err) => console.log("ERROR: Can't delete Bank Account", toast.error(err)))
};

export const contractCancelInfo = ({ id, setCancel }) => {
  return instance
    .get(`/api/plan/contract-cancel-info?device_id=${id}`)
    .then((res) => {
      const resData = res.data;
      const allDataRes = Object.assign({}, ...resData);
      setCancel(allDataRes);
    })
    .catch((err) => console.log("err", err));
};

export const changePendingInfo = ({
  id,
  setPendingDescription,
  setPending,
}) => {

  return instance
    .get(`/api/plan/changes-pending-info?device_id=${id}`)
    .then((res) => {
      const pendingMessage = Object.assign({}, ...res.data.message);
      setPendingDescription(pendingMessage);
      setPending(res.data.message);
    })
    .catch((err) => console.log("err", err));
};

export const changesPending = ({ value, status, device_id}) => {
  return instance
  .post(`/api/plan/changes-pending`, {
    value,
    status,
    device_id,
  })
  .then((res) => {
          // need to change toast conditions, after update on the backend
          if (!res || res.data === "undefined") {
            toast.error("Changes Pending Request Was Failed!");
          } else {
            toast.success(res.data.message);
          }
  })
  .catch((err) => console.log("CHANGES PENDING", err));
}

export const contractCancel = ({ device_id, plan_id }) => {
  return instance
    .post('api/plan/contract-cancel', {
      device_id,
      plan_id
    })
    .then((res) => {
      // need to change toast conditions, after update on the backend
      if (!res || res.data === "undefined") {
        toast.error("Contract Cancel Request Was Failed!");
      } else {
        toast.success(res.data.message);
      }
    })
    .catch((err) => console.log("err", err));
};

export const cancelPlan = ({ device_id }) => {
  return instance
    .post('api/plan/cancel', {
      device_id
    })
    .then((res) => {
      // need to change toast conditions, after update on the backend
      if (!res || res.data === "undefined") {
        toast.error("Cancel Plan Request Was Failed!");
      } else {
        toast.success(res.data.message);
      }
    })
    .catch((err) => console.log("err", err));
};

export const devicePlanById = ({ deviceId }) => {
  return instance.get(
    `/api/plan/device-plan?device_ID=${deviceId}`
  );
};

export const changePlan = ({ device_id, plan_id }) => {
  return instance
    .post(`/api/plan/change-plan`, {
      device_id,
      plan_id,
    })
    .then((res) => {
      // need to change toast conditions, after update on the backend
      toast.success(res.data.message);
    })
    .catch((err) => {
      console.log("ERROR: changePlan failed ===>", err);
      toast.error(err);
    });
};

export const activateDevice = ({ device_id, plan_id }) => {
  return instance
    .post(`/api/device/activate`, {
      device_ID: device_id,
      plan_ID: plan_id,
    })
    .then((res) => {
      // need to change toast conditions, after update on the backend
      if (res.status !== 200 || !res || res.data === "undefined") {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
    })
    .catch((err) => {
      const message = "Somethnig went wrong";
      toast.error(message);
      console.log("activate", err);
    });
};

export const getActivateDeviceId = ({
  serial,
  setStep,
  setActivationDevice,
}) => {
  return instance
    .post(`api/device/get-id`, {
      serial: serial,
    })
    .then((res) => {
      const device = res.data;
      if (device && res.status === 200) {
        setStep(device.device_ID && 2);
        setActivationDevice({
          device_ID: device.device_ID,
          serial: device.serial,
        });
      }
    })
    .catch((err) => {
      const message = err.response?.data?.message ?? "Somethnig went wrong";
      console.log("ERROR: api/device/get-id =>", err);
      toast.error(message, { position: "top-right" });
    });
};

export const getUnactivatedDevices = ({ setUnactivatedDevice, token }) => {
  return instance
    .get(`/api/device/unactivated`)
    .then((res) => {
      setUnactivatedDevice(res.data);
    })
    .catch((err) => console.log("ERROR: GET unactivated-device ====>", err));
};

export const getInvoiceOrOrderById = ({ endPoint, id, setState }) => {
  return instance
    .get(`${endPoint}=${id}`)
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => console.log("ERROR: getInvoiceOrOrderById", err));
};

// export const addCreditCard = ({value, token, dispatch, action, fn}) => {
//     instance
//       .post(`api/billing/add-card?access_token=${token}`, value)
//       .then((res) => {
//         console.log("card-add-credit-card", res.data);
//         dispatch(action(res.data.paymentMethods));
//         // dispatch(getCards(res.data.paymentMethods))
//         fn();
//         toast.success("Credit Card Was Created");
//       })
//       .catch((err) => {
//         // setLoadingCreditCard(false);
//         toast.error("Failed To Create Credit Card");
//         console.log("ERROR: Add-credit-card failed", err);
//       });
// }

export const getInvoicePdf = ({ id, setBlob }) => {
  return instance
    .get(`api/invoice/get-invoice-pdf?invoice_ID=${id}`, {
      responseType: "blob",
    })
    .then((res) => {
      setBlob(res.data);
    })
    .catch((err) => console.log("ERROR: res-pdf =>", err));
};
