import React, { CSSProperties as css } from 'react';
import { Tile } from '../../../general';

const localTileStyle: css = {
  margin: 3,
  marginBottom: 6,
};
const LocalPersonTile = ({
  style = {},
  title = '',
  children,
  action,
  collapsible = false,
  initialCollapsed = false,
  loading = false,
}) => (
  <Tile
    style={{ ...style, ...localTileStyle }}
    title={title}
    action={action}
    collapsible={collapsible}
    initialCollapse={initialCollapsed}
    loading={loading}
  >
    {children}
  </Tile>
);

export default LocalPersonTile;
