import React, { FC } from 'react';

import { IButtonProps, cnButton } from './index';
import './Button.scss';

export const Button: FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    children,
    className,
    as: Component = 'button',
    ...props
}) => (
    <Component {...props} className={cnButton({}, [className])}>
        {children}
    </Component>
);
