import React, {useEffect, useRef, useState} from "react";
import {buyTime, getChannels, getHistoryVideoApi, updateAvailableTime} from "../../../../api/openApi";
import ContinueStreamModal from "./ContinueStreamModal";
import {useDispatch, useSelector} from "react-redux";
import {iReducersState} from "../../../../stores/reducers";
import BuyMoreModal from "./BuyMoreModal";
import {
  IAvailableTimeProps,
  IBuyMoreModalProps,
  IChannelsProps,
  ISelectedHistoryVideo,
  IRenderPlayersProps,
  ISelectChannel,
  ITariffs,
  IHistoryVideo,
} from "./interfaces";
import moment from "moment";
import { openModal } from "../../../../stores/reducers/videoCameras";
import LiveViewModal from "./LiveViewModal";
import HistoryModal from "./HistoryModal";
import {iFullStoreState, iPerson} from "../../../../shared/interfaces";

const REMINDER_INTERVAL: number = 60000;


const DeviceStatuses = (props): JSX.Element => {
  const {device: {isCamera, 'extra-info': serialNumber, extraDeviceChannels, id: deviceId}} = props;

  const serial = Object.values(serialNumber)[0].toString();

  const dispatch = useDispatch();
  const { modals, cameraOnline, availableTime: reviewTime} = useSelector((state: iReducersState) => state.videoCameras);
  const person = useSelector<iFullStoreState, iPerson>(state => state.general.people[state.auth.user?.uid]);
  const CAMERA_ONLINE = cameraOnline == 1;
  const {
    openHistoryModal,
    openHistoryPlayer,
    openLiveViewModal,
    openLiveViewPlayer,
    openBuyMoreModal,
    openContinueLiveStreamingModal
  } = modals;


  const playedSecondsRef = useRef<number>(0);
  const [selectedChannels, setSelectedChannels] = useState<Array<ISelectChannel>>([]);
  const [reminderIntervalTime, setReminderIntervalTime] = useState<number>(REMINDER_INTERVAL);
  const [prevPlayedSeconds, setPrevPlayedSeconds] = useState<number>(0);
  const [channels, setChannels] = useState<Array<ISelectChannel>>([]);
  const [availableTime, setAvailableTime] = useState<number>(null || reviewTime);
  const [streamingTariffs, setStreamingTariffs] = useState<ITariffs | null>(null);
  const [isControls, setIsControls] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [currency, setCurrency] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const [prevIdChannels, setPrevIdChannels] = useState(null);
  const [prevHistoryTime,setPrevHistoryTime] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [historyVideo, setHistoryVideo] = useState<Array<IHistoryVideo>>([]);
  const [selectedHistoryChannel, setSelectedHistoryChannel] = useState<ISelectedHistoryVideo>(null);
  const intervalContinueStreaming = useRef<ReturnType<typeof setInterval>>(null);
  const [alertTimestamp, setAlertTimestamp] = useState(0);


  useEffect(() => {
    (CAMERA_ONLINE) && getVideos();
  }, [cameraOnline])

  const updateTime = async (): Promise<number> => {
    setPrevPlayedSeconds(playedSecondsRef.current);
    setPrevHistoryTime((prev) => prev + Math.round(playedSecondsRef.current));
    setAlertTimestamp((prev) => new Date(prev).setSeconds(new Date(prev).getSeconds() + Math.round(playedSecondsRef.current)));
    const {available_time} = await updateAvailableTime({firebaseDeviceId: deviceId, playedSeconds: (playedSecondsRef.current - prevPlayedSeconds)});
    return available_time;
  };

  const getVideos = async (): Promise<Array<ISelectChannel>> => {
    setIsLoading(true);
    if (CAMERA_ONLINE) {
      const hasExtraChannels = !!extraDeviceChannels && extraDeviceChannels.length;
      const extraChannelsEntries = hasExtraChannels && Object.entries(extraDeviceChannels);
      const extraChannelsIds = hasExtraChannels && extraChannelsEntries.map(([key, value]) => {
        const firstExtraChannelId = 3;
        return !!value && firstExtraChannelId + Number(key);
      }).filter(val => val)
      const {
        channels,
        time_info: {
          available_time,
          tariffs: {streaming_tariffs, currency}
        }
      } = await getChannels({extraChannelsIds, firebaseDeviceId: deviceId}).finally(() => setIsLoading(false));
      setAvailableTime(available_time);
      setChannels(channels);
      setStreamingTariffs(streaming_tariffs);
      setCurrency(currency);
      return channels;
    }
  }

  const resetPlayer = (): void => {
    setIsPlaying(false);
    setCurrentTime(0);
    playedSecondsRef.current = 0;
    setPrevPlayedSeconds(0);
    setSelectedChannels([]);
    setCount(0)
  }

  const closeModal = async (): Promise<void> => {
    const time = await updateTime();
    setAvailableTime(time);
    for (let key in modals) modals[key] && dispatch(openModal({key, value: false}))
    setHistoryVideo([])
    resetPlayer();
  };

  const clickView = async (): Promise<void> => {
    setLoadingButton(true);
    await getVideos().then((channels) => {
      setSelectedChannels([channels.find(item => item.channel_id === selectedChannels[0].channel_id)]);
      dispatch(openModal({key: "openLiveViewPlayer", value: true}))
      setLoadingButton(false);
      setIsPlaying(true);
    })
  }

  const getHistoryVideo = async () => {
    setLoadingButton(true);
    if(CAMERA_ONLINE) {
      const history= await getHistoryVideoApi({
        selectedHistoryChannel,
        firebaseDeviceId: deviceId,
        playedSeconds: prevHistoryTime,
        alertTimestamp
      }).finally(() => setLoadingButton(false));

      if(history?.channels){
        const {channels, time_info: { available_time, tariffs: { streaming_tariffs, currency } }} = history;
        setHistoryVideo(channels);
        setCurrency(currency);
        setAvailableTime(available_time);
        setStreamingTariffs(streaming_tariffs);
        setIsPlaying(true)
        return channels
      }else {
        closeModal();
      }
    }
  }

  const viewHistoryVideo = async (): Promise<void> => {
    const { beginTime, endTime } = selectedHistoryChannel;
    const startSecond = moment(beginTime).toDate().getSeconds();
    const lastSecond = moment(endTime).toDate().getSeconds();
    const total = lastSecond - startSecond;
    setTotalSeconds(Math.abs(total));
    await getHistoryVideo()
    dispatch(openModal({key: "openHistoryPlayer", value: true}))
    setIsPlaying(true);
  }

  const ChannelsProps: IChannelsProps = {
    setSelectedChannels,
    setChannels,
    serial,
    channels,
    setPrevIdChannels
  }

  const AvailableTimeProps: IAvailableTimeProps = {
    setIsPlaying,
    setAvailableTime,
    setIsControls,
    currentTime,
    availableTime,
    isPlaying,
    intervalContinueStreaming,
  }


  const RenderPlayersProps: IRenderPlayersProps = {
    channels: selectedChannels,
    reminderIntervalTime,
    isPlaying,
    setIsPlaying,
    setCurrentTime,
    updateTime,
    isControls,
    getVideos,
    isLiveView: true,
    playedSecondsRef,
    resetPlayer,
    setAvailableTime,
    intervalContinueStreaming,
    historyVideo,
    setSelectedHistoryChannel
  };

  const BuyMoreModalProps: IBuyMoreModalProps = {
    firebaseDeviceId: deviceId,
    closeShowBuyMoreModal: () => dispatch(openModal({key: "openBuyMoreModal", value: false})),
    currency,
    streamingTariffs,
    reset: closeModal,
    setIsControls,
    setIsPlaying,
  }

  return (
    <>

      {(CAMERA_ONLINE && openHistoryModal) && (
        <HistoryModal
            openHistoryPlayer={openHistoryPlayer}
            viewHistoryVideo={viewHistoryVideo}
            count={count}
            closeModal={closeModal}
            serial={serial}
            firebaseDeviceId={deviceId}
            setCount={setCount}
            totalSeconds={totalSeconds}
            AvailableTimeProps={AvailableTimeProps}
            RenderPlayersProps={RenderPlayersProps}
            loadingButton={loadingButton}
            selectedHistoryChannel={selectedHistoryChannel}
            setSelectedHistoryChannel={setSelectedHistoryChannel}
            setPrevHistoryTime={setPrevHistoryTime}
            prevHistoryTime={prevHistoryTime}
            setAlertTimestamp={setAlertTimestamp}
        />
      )}

      {(CAMERA_ONLINE && openLiveViewModal) && (
        <LiveViewModal
            availableTime={availableTime}
            openLiveViewPlayer={openLiveViewPlayer}
            selectedChannels={selectedChannels}
            closeModal={closeModal}
            loadingButton={loadingButton}
            clickView={clickView}
            RenderPlayersProps={RenderPlayersProps}
            AvailableTimeProps={AvailableTimeProps}
            ChannelsProps={ChannelsProps}
        />
      )}

      {
        (CAMERA_ONLINE && openBuyMoreModal) &&
          <BuyMoreModal {...BuyMoreModalProps} />
      }

      {(!person.suppressContinueStreamModal && CAMERA_ONLINE && openContinueLiveStreamingModal) && (
        <ContinueStreamModal
          closeContinueLiveStreamingModal={() => dispatch(openModal({key: "openContinueLiveStreamingModal", value: false}))}
          setReminderIntervalTime={setReminderIntervalTime}
          setIsPlaying={setIsPlaying}
          getVideos={getVideos}
          setSelectedChannels={setSelectedChannels}
          prevIdChannels={prevIdChannels}
          historyVideo={historyVideo}
          getHistoryVideo={getHistoryVideo}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default DeviceStatuses;
