import { clientDb, clientStorage, keyGen, globaldb } from '../firebase';
import { AclFb, UserAuth } from '../interfaces';
import { forEachObjIndexed } from 'ramda';
import { makeAudit } from '../helpers';
import {localStorage} from "../storage";
import { UserCan } from '../constants';

const peopleDb = () => clientDb().child('people');
const clientId = localStorage.get('active-client');

export const all = async () => (await peopleDb().child('people-details').once('value')).val();
export const allWatch = (callback) => peopleDb().child('people-details').on('value', (res) => {
    callback(res.val());
}, (err) => console.log(err));

// export const setPerson = async (uid, details: {displayName: string, email: string, photoURL: string}) => {
//     let deets = (await peopleDb().child('people-details').child(uid).once("value")).val();

//     if (!deets || !deets.displayName) {
//         await peopleDb().child('people-details').child(uid).update({...details, id: uid})
//     }
// };


export const getAcl = async (clientId): Promise<AclFb> => (await globaldb.child(`clients/${clientId}`).child('acl/can').once("value")).val();

export const usersHavePermission = async (clientId, permission) => {
    const collection = (await globaldb.child(`clients/${clientId}`).child(`acl/can/${permission}`).once('value')).val() || {};
    return Object.values(collection).some((el) => el === true);
};

export const changePermission = (user: UserAuth, permission: string, flag: boolean) => async () => {
    const users = flag
        ? (await globaldb.child(`clients/${user.beToken.clientId}`).child(`/people/people-details`).once("value")).val() || {}
        : (await globaldb.child(`clients/${user.beToken.clientId}`).child(`acl/can/${permission}`).once("value")).val() || {};
    const usersIds = Object.keys(users);
    const updates = usersIds.reduce((acc, rec) => {
        return {...acc, [`acl/can/${permission}/${rec}`]: flag? flag : null }
    }, {});

    await clientDb().update(makeAudit(user, updates));
}

export const storeProfileImage = async (uid, file: File) => {
    await clientStorage().child(`people/people-details/${uid}/profile-image`).put(file);

    return await clientStorage().child(`people/people-details/${uid}/profile-image`).getDownloadURL();
};

export const updatePersonDetails = (user: UserAuth) => async (personId: string, updates: {[field: string]: any}) => {
    // const ref = peopleDb().child('people-details').child(personId);

    const update = {};
    forEachObjIndexed((val, field) => {
        update[`people/people-details/${personId}/${field}`] = val;
    })(updates);

    await clientDb().update(makeAudit(user, update));
};

export const getSubUsers = async (clientId, uid) => {
    const groups = (await globaldb.child(`clients/${clientId}`).child(`/people/people-details/${uid}/groups`).once("value")).val() || {};

    const ids = new Set();
    for (const groupId of Object.keys(groups)) {
        const users = (await globaldb.child(`clients/${clientId}`).child(`/groups/${groupId}/sub-users`).once("value")).val() || {};

        Object.keys(users).forEach(id => ids.add(id));
    }

    return Array.from(ids);
}
