import React from "react";
import {useSelector} from "react-redux";
import {iFullStoreState} from "../../../../../../shared/interfaces";
import {DashboardSize} from "../../../../../../stores/reducers/dashboardInfo";
import TotalCostItem from "./TotalCostItem";
import "./totalCostList.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import {selectReportsMaintenanceDevices} from "../../../../../../stores/reducers/maintenance/selectors";
import {getSumFrom, isMobile} from "../../../../../../shared/helpers";

const TotalCostList = () => {
    // const isMobile = window['cordova'];
    const reduxDevicesData = useSelector(selectReportsMaintenanceDevices);

    const totalSum = reduxDevicesData.reduce((pr, cr) => {
        const {tasksList} = cr;
        const totalCost = getSumFrom(tasksList, 'taskCompleteInfo', 'cost');
        return pr + totalCost;
    }, 0);

    const devicesData = reduxDevicesData.map(device => {
        const {deviceName, tasksList} = device;
        const totalCost = getSumFrom(tasksList, 'taskCompleteInfo', 'cost');
        return {
            deviceName,
            // totalCost, // only own
            totalCost: totalSum.toFixed(2),
            percent: (totalCost / totalSum * 100).toFixed(2)
        };
    });

    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);

    return (
        <div
            className={`total-cost-wrapper ${DashboardSize.OPEN === size && !isMobile? "total-cost-wrapper-small" : ""}`}>
            <PerfectScrollbar onScroll={(e) => {
                console.log(e)
            }} className="total-cost-scroll">
                {
                    devicesData.map(itm => {
                        const {deviceName, percent, totalCost} = itm;
                        return <TotalCostItem key={deviceName} deviceName={deviceName} percent={percent}
                                              totalCost={totalCost}/>
                    })
                }
            </PerfectScrollbar>
        </div>
    )
}

export default TotalCostList;
