import React, { useState, useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import style from './SummaryReport.module.scss';
import {
	iFullStoreState,
	ReportContainer,
	ItemType
} from '../../../../shared/interfaces';
import {SummaryReportComponent} from './interfaces'
import { iReportType } from '../../../../stores/reducers/report-reducers';
import { groupBy } from 'ramda';
import { vals } from '../../../../shared/helpers';
import { useRedux } from '../../../../states/redux-state';
import { DevicesDetailsContainer } from '../../../../stores/reducers/devicesData';
import { is } from 'immutable';
import { getTitles } from '../ReportPage/reportPageHelpers';
import { generateSummaryResults } from './helper';
import SummaryItem from './SummaryItem';

const SummaryReport: FC<SummaryReportComponent> = ({ showReportTrip, hideReportTrip }) => {
	const reportType = useSelector<iFullStoreState, iReportType>(s => s.report.details.reportType);
	const displayRecords = useSelector<iFullStoreState, ReportContainer>(
		s => s.report.displayRecords || {}
	);

	const [titles, setTitles] = useState({});
	const people = useRedux(s => s.general.people);

	const deviceOrPerson = useRedux(s => s.report.details.devicePerson);
	const devicesDetails = useSelector<iFullStoreState, DevicesDetailsContainer>(
		s => s.devicesData.devicesDetails,
		(left, right) => is(left, right)
	);
	const recordsRaw = groupBy(x => (deviceOrPerson === ItemType.device ? x.device : x.personId))(
		vals(displayRecords)
	);

	const dateSorting = (previous, next) => {
		if (previous?.startDate?.toDate().valueOf() < next?.startDate?.toDate().valueOf()) return - 1;
		else if (previous?.startDate?.toDate().valueOf() === next?.startDate?.toDate().valueOf()) return 0;
		else return 1;
	}

	useEffect(
		() => setTitles(getTitles(recordsRaw, deviceOrPerson, devicesDetails, people)),
		[deviceOrPerson]
	);


	const devices = new Set();
	const records = Object.values(displayRecords);
	const results = {};

	records.forEach(record => devices.add(record.device));

	const renderedItems = Array.from(devices).map((device: string) => {
		const filteredRecords = records.filter(record => record.device === device).sort((a, b) => dateSorting(a, b));

		results[device] = generateSummaryResults(filteredRecords, titles[device], reportType);

		return (
			<SummaryItem
				key={device}
				deviceId={device}
				results={results[device]}
				filteredRecords={filteredRecords}
				showReportTrip={showReportTrip}
				hideReportTrip={hideReportTrip}
			/>
		);
	});

	return <div className={style['summary-report-block']}>{renderedItems}</div>;
};

export default SummaryReport;
