import React from 'react'

const HeaderPlanList = ({ currentPlans, deviceStatus }) => {
  return (
    <div className="modal__device-description">
      {currentPlans?.length > 0 ? "Your device is currently signed up for the following plan:" : 'No subscriptions'}
      {currentPlans?.map((plan, index) => {
        return (
          <ul key={plan.plan_id}>
            <li style={{ fontWeight: "bold" }}>{deviceStatus == 4 ? `${plan.description} ${plan.status}${plan.renewDate}` : plan.description || plan.planDescription || plan.plan_description}</li>
          </ul>
        );
      })}
    </div>
  );
};

export default HeaderPlanList