import React, {useState} from 'react';
import './index.scss';
import {store} from "../../stores/store";
import ToggleSwitch from "../ToggleSwitch";
import EditSchedule from "./EditSchedule";
import Modal from "../Modal";
import {formatTime, mapNumberToDay} from "./schedule-utils";
import {localStorage} from "../../shared/storage";
import {UserCan} from "../../shared/constants";

const Schedule = () => {
  const schedule = store.getState().general.schedule;
  const [is24hFormat, setIs24hFormat] = useState(false);
  const [editScheduleIsOpen, setEditScheduleIsOpen] = useState(false);
  const loginToken = localStorage.get('login-init-be-token');
  const isAdmin = loginToken[UserCan.DO_ANYTHING];

  const handleLanguageToogle = () => {
    setIs24hFormat(!is24hFormat);
  }

  const toggleEditModal = () => {
    setEditScheduleIsOpen(!editScheduleIsOpen);
  }

  return (
    <div className='schedule'>
      {editScheduleIsOpen &&
          <Modal>
            <EditSchedule
                onClose={toggleEditModal}
                is24hFormat={is24hFormat}
                schedule={schedule}
            />
          </Modal>
      }
      <table>
        <tr>
          <th>Day</th>
          <th>Start</th>
          <th>End</th>
          <th>Day Off</th>
        </tr>
        {schedule.map(scheduleItem => {
          const startTime = scheduleItem.startTime ? formatTime(scheduleItem.startTime, is24hFormat) : 'Day Off';
          const endTime = scheduleItem.endTime ? formatTime(scheduleItem.endTime, is24hFormat) : 'Day Off';
          const dayOff = scheduleItem.isDayOff ? '\u2713' : '\u2715';
          const dayOffClass = scheduleItem.isDayOff ? 'checkmark-yes' : 'checkmark-no'

          return (
            <tr className='schedule-item' key={scheduleItem.day}>
              <td><span>{mapNumberToDay(scheduleItem.day)}</span></td>
              <td><span>{startTime}</span></td>
              <td><span>{endTime}</span></td>
              <td><span className={dayOffClass}>{dayOff}</span></td>
            </tr>
          )
        })}
      </table>
      <div className='schedule-buttons'>
      <ToggleSwitch
          label='24h format'
          onToggle={handleLanguageToogle}
          isChecked={is24hFormat}
        />
        <button onClick={() => setEditScheduleIsOpen(true)} disabled={!isAdmin}>Edit</button>
      </div>
    </div>
  );
};

export default Schedule;
