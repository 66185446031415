import React from 'react';
import { connect } from 'react-redux';
import { BaseComponent } from '../../../../../shared/BaseComponent';
import { vals } from '../../../../../shared/helpers';
import {
  iFullStoreState,
  iList,
  iPerson,
} from '../../../../../shared/interfaces';
import SearchGridOld from '../../../../SearchGridOld/SearchGridOld';
import { PersonBox } from '../../../../general/person-box';

interface iPageNotifyProps {
  notifyPeople: iList<true>;
  togglePerson: (personId: string) => any;
}
interface iPageNotifyPropsFull extends iPageNotifyProps {
  people: iList<iPerson>;
}

const mapNotifyToProps = (state: iFullStoreState, ownProps) => ({
  people: state.general.people,
  customRecipients: ownProps.customRecipients,
});

@connect(mapNotifyToProps)
export class AlertSettingsPageNotify extends BaseComponent<
  iPageNotifyProps,
  { tagFilter }
> {
  state = { tagFilter: '' };

  debugRender = () => {
    const {
      people,
      togglePerson,
      notifyPeople = {},
    } = this.props as iPageNotifyPropsFull;

    const { tagFilter } = this.state;

    const filteredPeople = vals(people)
      .filter(
        (person) =>
          (person.displayName || '').toLowerCase().indexOf(tagFilter) != -1
      )
      .map((person) => (
        <PersonBox
          key={person.id}
          personId={person.id}
          person={person}
          active={!!notifyPeople[person.id]}
        />
      ));

    return (
      <SearchGridOld
        list={filteredPeople}
        filterStr={tagFilter}
        placeholder='Search People'
        perPage={12}
        keyClicked={togglePerson}
        filterChange={(tagFilter) => this.setState((s) => ({ tagFilter }))}
        style={{ maxWidth: 390 }}
      />
    );
  };
}
