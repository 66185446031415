import React, { useEffect, useState } from "react";

import AuditLog from "../AuditLog";
import Chevron from "../Chevron";

import styles from "./styles.module.scss";

interface IAuditLogNestedListProps {
  data: object;
  name: string;
  isFirstNest: boolean;
}

const AuditLogNestedList: React.FC<IAuditLogNestedListProps> = ({
  data,
  name,
  isFirstNest,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isFirstNest) {
      setIsOpen(true);
    }
  }, [isFirstNest]);

  const toggleOpen = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <ul
      className={styles.AuditLogNestedList}
      onClick={(event) => event.stopPropagation()}
    >
      {!isFirstNest && (
        <li className={styles.title} onClick={toggleOpen}>
          <Chevron isActive={isOpen} direction={isOpen ? "down" : "right"} />
          <span style={{ fontWeight: isOpen && 600 }}>{name}</span>
        </li>
      )}
      <ul className={styles.nestedChild}>
        {isOpen &&
          Object.entries(data).map(([key, value]) => {
            return (
              <li key={key}>
                {typeof value === "object" ? (
                  <AuditLogNestedList
                    data={value}
                    name={key}
                    isFirstNest={false}
                  />
                ) : (
                  <AuditLog label={key} value={value} />
                )}
              </li>
            );
          })}
      </ul>
    </ul>
  );
};

export default AuditLogNestedList;
