import React, { FunctionComponent, useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { IconTypes, iIcon } from '../../shared/interfaces';
import { Fa } from '../elements/fa';
import { DeviceSvgIcon } from './components/DeviceSvgIcon';
import { IconDefinition } from '@fortawesome/fontawesome';

interface SvgIconProps {
  icon: iIcon;
  color?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  style?: React.CSSProperties;
}

const convertSizeToEm = (size: 'xs' | 'sm' | 'md' | 'lg'): number => {
  switch (size) {
    case 'xs':
      return 1.2;
    case 'sm':
      return 2;
    case 'md':
      return 3;
    case 'lg':
      return 4.5;
  }
};

const determineIconType = (icon: iIcon) => {
  if (typeof icon == 'object' && 'fa' in icon) {
    return IconTypes.FaIcon;
  } else if (typeof icon == 'object' && 'svgIconName' in icon) {
    return IconTypes.SvgIcon;
  } else if (typeof icon == 'object' && 'url' in icon) {
    return IconTypes.UserSvgIcon;
  }

  return null;
};

const SvgIcon: FunctionComponent<SvgIconProps> = ({
  icon,
  color = '#000000',
  size = 'xs',
}) => {
  const [iconType, setIconType] = useState<IconTypes>(null);
  const [iconPath, setIconPath] = useState<string | IconDefinition>(null);

  useEffect(() => {
    if (!icon) {
      return;
    }

    let type = icon.type;

    if (type === undefined) {
      type = determineIconType(icon);
    }

    setIconType(type);

    switch (type) {
      case IconTypes.FaIcon:
        setIconPath(icon?.fa);
        break;
      case IconTypes.SvgIcon:
        setIconPath(icon?.svgIconName);
        break;
      case IconTypes.UserSvgIcon:
        setIconPath(icon?.url);
        break;
    }
  }, [icon]);

  switch (iconType) {
    case IconTypes.FaIcon:
      return (
        <Fa
          icon={iconPath as IconDefinition}
          style={{ color, fontSize: `${convertSizeToEm(size)}em` }}
        />
      );
    case IconTypes.SvgIcon:
      return (
        <DeviceSvgIcon
          name={iconPath as string}
          width={`${convertSizeToEm(size)}em`}
          height={`${convertSizeToEm(size)}em`}
          fill={color}
        />
      );
    case IconTypes.UserSvgIcon:
      return (
        <img 
          src={iconPath as string}
          style={{
            width: `${convertSizeToEm(size)}em`,
            height: `${convertSizeToEm(size)}em`,
            fill: color,
            color
          }}
        />
      );
    default:
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      );
  }
};

export default SvgIcon;
