import React from "react";
import {iFileIconProps} from "../../../../../../shared/interfaces";
import {ReactComponent as FileIcon} from "../../../../../../assets/svg/file_icon.svg";
import FileInLoad from "./FileInLoad";
import InitFileIcon from "./InitFileIcon";
import "./currentFileIcon.scss";

const CurrentFileIcon = ({isLoaded, inLoad, loaded, sizeInBytes, loadType}: iFileIconProps) => {
  if (isLoaded) {
    return <FileIcon className="document-file-icon"/>
  }
  return inLoad
    ? <FileInLoad
      className="document-file-icon"
      width={26}
      percent={loaded / sizeInBytes}
      color="#FF7C02FF"
    />
    : <InitFileIcon
      className="document-file-icon"
      loadType={loadType}
    />
}
export default CurrentFileIcon;