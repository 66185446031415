import { OrderedMap, Seq } from 'immutable';
import { put, select, takeEvery } from 'redux-saga/effects';

import { iDevicePing, iFullStoreState } from '../../shared/interfaces';
import { IPointsContainer } from '../reducers/devicesTripsPoints';
import { shouldShowPoint } from '../../components/gmap/pois';
import { setSelectedPoint } from '../reducers/selectedPoint/AC';

const SELECTED_FIRST_POINT_IN_NEXT_TRIP = 'SELECTED_FIRST_POINT_IN_NEXT_TRIP';

export const selectFirstPointInNextTrip = (deviceId: string, tripId: string) => ({
    type: SELECTED_FIRST_POINT_IN_NEXT_TRIP,
    payload: {deviceId, tripId},
});

function* worker (action: ReturnType<typeof selectFirstPointInNextTrip>) {
    const {deviceId, tripId} = action.payload;

    const tripsIds: Seq.Indexed<string> = yield select((s: iFullStoreState) =>
        s.devicesTripsPoints.getIn([deviceId, 'tripsPoints'], OrderedMap()).keySeq(),
    );

    const nextTripId = findNextElement(tripsIds, tripId);

    if (!nextTripId) return;

    const showPOI: boolean = yield select((s: iFullStoreState) => s.gmap.showPOI);
    const showDots: boolean = yield select((s: iFullStoreState) => s.gmap.showDots);

    const firstPointInNextTrip: iDevicePing | undefined = yield select(state => {
        let points: IPointsContainer = state.devicesTripsPoints.getIn(
            [deviceId, 'tripsPoints', nextTripId],
            OrderedMap(),
        );
        /** TODO: maybe next condition not need
         * if first point in all trips has msg === "Ignition on"
         */
        if (!showDots || !showPOI) {
            points = points.filter(shouldShowPoint({showDots, showPOI}));
        }
        return points.first<undefined>();
    });

    if (firstPointInNextTrip) {
        yield put(setSelectedPoint(deviceId, nextTripId, firstPointInNextTrip.pointId, firstPointInNextTrip.coordinates.location));
    }
}

export default function* () {
    yield takeEvery(SELECTED_FIRST_POINT_IN_NEXT_TRIP, worker);
}

const findNextElement = (collection: Seq.Indexed<string>, element: string): undefined | string => {
    if (!collection.size || !element) return;

    const currentElementIndex = collection.findIndex(el => element === el);
    if (currentElementIndex === -1) return;

    if (currentElementIndex < collection.size - 1) {
        return collection.get(currentElementIndex + 1);
    }
};
