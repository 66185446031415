import React, {/*useState,*/ useMemo} from "react";
import "./devicesTable.scss";
import {useSelector} from "react-redux";
import {iFullStoreState, iList, iTrip} from "../../../../../../shared/interfaces";
import {DashboardSize} from "../../../../../../stores/reducers/dashboardInfo";
import MaintenanceTable from "../../MaintenanceTable";
import {selectReportsMaintenanceDevices, selectDevicesTrips} from "../../../../../../stores/reducers/maintenance/selectors";
import {
    formatPrice,
    getSumFrom,
    getTimeFormated,
    getTotalHoursFor,
    getTotalMilesFor
} from "../../../../../../shared/helpers";
import {isMobile} from "../../../../../../shared/helpers";

const DevicesTable = () => {
    // const isMobile = window['cordova'];
    const reduxDevicesData = useSelector(selectReportsMaintenanceDevices);
    // const devicePing = useSelector(selectDevicePing);
    const devicesTrips = useSelector(selectDevicesTrips);
    // console.log(reduxDevicesData);



    const tableData = useMemo(() => {
        return reduxDevicesData.map( device => {
            // console.log(device)
            const {deviceName, tasksList, deviceKey} = device;
            // console.log(tasksList);

            const totalMiles = getTotalMilesFor(devicesTrips, deviceKey);
            const totalHours = getTimeFormated(getTotalHoursFor(devicesTrips, deviceKey));
            const totalCost = formatPrice(getSumFrom(tasksList, 'taskCompleteInfo', 'cost'));
            const totalLaborHours = getSumFrom(tasksList, 'taskCompleteInfo', 'labourHours');
            // console.log([totalMiles, totalHours, totalCost, totalLaborHours]);
            // console.log(device);
            return {
                // deviceKey,
                // maintenanceKey,
                deviceName,
                totalMiles,
                totalHours,
                totalCost,
                totalLaborHours
            };
        });
    }, [reduxDevicesData]);

    // console.log('devicesData: ', devicesData);

    // const [tableData, updateTableData] = useState(formatResponseData(devicesData));


    // const removeItem = (itm) => {
    //     // const [deviceKey , maintenanceKey] = keys;
    //     // const updatedData = tableData.filter(itm => itm.id !== id);
    //     // updateTableData(updatedData);
    //     console.log(itm);
    // };

    const columns = useMemo(
        () => [
            // {
            //     Header: "Id",
            //     accessor: "id",
            // },
            {
                Header: "Devices",
                accessor: "deviceName",
            },
            {
                Header: "Total Miles",
                accessor: "totalMiles",
            },
            {
                Header: "Total Hours",
                accessor: "totalHours",
            },
            {
                Header: "Total Costs",
                accessor: "totalCost",
            },
            {
                Header: "Total Labor Hours",
                accessor: "totalLaborHours",
            },
        ],
        []
    );

    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);
    return (
        <div className={`devices-table-wrapper ${DashboardSize.OPEN === size && !isMobile ? "devices-table-wrapper-small" : ""}`}>
            {
                tableData.length
                    ? <MaintenanceTable className="devices-table" columns={columns} data={tableData} hasPagination={true} paginationClass={"devices-table__pagination"} pageSize={4} hasScroll />
                    : null
            }
        </div>
    );
}

export default DevicesTable;
