// import { allWatch as dbMaintenanceWatch } from "../../../shared/db/maintenance-db";
import { iDeviceDetails, iList, iMaintenanceTask } from "../../../shared/interfaces";
import { Actions, ActionsCreator } from "./actions";
import { dbMaintenanceTaskOptionsWatch, dbMaintenanceWatch } from "../../../shared/db/maintenance-db";

export interface iMaintenanceStore {
    allowedDevices: iDeviceDetails[];
    taskCreateError: object | null,
    taskEditError: object | null,
    records: iList<iList<iMaintenanceTask>>;
    tasksOptions: iList<string>;
}

export const reducer = (state: iMaintenanceStore = {
    taskCreateError: null,
    taskEditError: null,
    tasksOptions: {},
    records: {},
    allowedDevices: []
}, action) => {
    switch(action.type) {
        case Actions.SET_ALL_RECORDS: {
            return {
                ...state,
                records: action.payload.records
            }
        }
        case Actions.CREATE_NEW_TASK_SUCCESS: {
            return {
                ...state,
                taskCreateError: null
            }
        }
        case Actions.CREATE_NEW_TASK_FAILED: {
            return {
                ...state,
                taskCreateError: action.payload.error
            }
        }
        case Actions.EDIT_MAINTENANCE_TASK_SUCCESS: {
            return {
                ...state,
                taskEditError: null
            }
        }
        case Actions.EDIT_MAINTENANCE_TASK_ERROR:
            return {
                ...state,
                taskEditError: { message: action.payload.error.message }
            }
        case Actions.ADD_NEW_MAINTENANCE_TASK_OPTION_SUCCESS: 
            const {key, task} = action.payload;
            return {
                ...state,
                tasksOptions: {
                    ...state.tasksOptions,
                    [key]: task
                }
            }
        case Actions.SET_MAINTENANCE_TASK_OPTIONS:
            const { tasks } = action.payload;
            console.log("tasks", tasks)
            return {
                ...state,
                tasksOptions: tasks
            }
        case Actions.SET_ALLOWED_DEVICES:
            return {
                ...state,
                allowedDevices: [...state.allowedDevices, action.payload.devices]
            }
        default:
            return state;
    }
}

export const maintenanceWatch = s => dbMaintenanceWatch(
    records => s.dispatch({
        type: Actions.SET_ALL_RECORDS,
        payload: { records }
    })
)

export const maintenanceOptionsWatch = s => dbMaintenanceTaskOptionsWatch(
    records => {
        s.dispatch(ActionsCreator.setAllMaintenanceTaskOptions(records))
    }
);