import * as yup from 'yup';

export const fenceFileSchema = yup.array(
  yup.object({
      'Center Lat': yup.array(yup.number()).test({
          test: function(value) {
              if (!this.parent['Center Lng'] && !this.parent['Physical Address'] && !value) {
                  return this.createError({
                      path: 'Center Lat',
                      message: 'Center Lat is required',
                  });
              }
              return true;
          },
      }),
      'Center Lng': yup.array(yup.number()).test({
          test: function(value) {
              if (!this.parent['Center Lat'] && !this.parent['Physical Address'] && !value) {
                  return this.createError({
                      path: 'Center Lng',
                      message: 'Lng is required',
                  });
              }
              return true;
          },
      }),
    'Color': yup.array(yup.string()).length(1),
    'Description': yup.array(yup.string()).length(1),
    'Enter Alert': yup.array(yup.string().oneOf(['FALSE', 'TRUE'])).length(1),
    'Enter Alert Endpoints': yup.array(yup.string()),
    'Exit Alert': yup.array(yup.string().oneOf(['FALSE', 'TRUE'])).length(1),
    'Exit Alert Endpoints': yup.array(yup.string()),
    'Icon': yup.array(yup.string()),
    'Label': yup.array(yup.string().required()).length(1).required(),
    'Size': yup.array(yup.number().min(0, "Size should be greater than 0")).length(1).required(),
    'Type': yup.array(yup.string().oneOf(['Ellipses', 'Rectangle'])).length(1).required(),
    'Waypoint': yup.array(yup.string().oneOf(['TRUE', 'FALSE'])).length(1),
    'Hide Name': yup.array(yup.string().oneOf(['TRUE', 'FALSE'])).length(1),
    'Hide Shadows': yup.array(yup.string().oneOf(['TRUE', 'FALSE'])).length(1),
    'Hide Icon': yup.array(yup.string().oneOf(['TRUE', 'FALSE'])).length(1),
    'Physical Address': yup.array(yup.string()).test({
        test: function(value) {
            if (!this.parent['Center Lat'] && !this.parent['Center Lng'] && !value) {
                return this.createError({
                    path: 'Physical Address',
                    message: 'Physical Address is required',
                });
            }
            return true;
        },
    }),
  })
);
