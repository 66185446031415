import React from 'react';

export const renderColorfulLegendText = (value: string) => {
  const className = 'avg-vs-cost-legend-item-text';
  return <span className={className}>{value}</span>
}

export const renderTooltip = (data) => {
  if (!data?.payload) return null;
  const info = data?.payload[0]?.payload;
  if (!info) return null;
  const {deviceName, avgScore, totalCost} = info;
  return (
    <div className="avg-vs-cost-custom-tooltip">
      <div className="avg-vs-cost-custom-tooltip-text avg-vs-cost-custom-tooltip-name">{deviceName}</div>
      <div className="avg-vs-cost-custom-tooltip-text">Avg Score: {avgScore}</div>
      <div className="avg-vs-cost-custom-tooltip-text">Cost: ${totalCost}</div>
    </div>
  );
};