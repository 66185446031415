import React, {useEffect, useState} from "react";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../stores/reducers/videoCameras";
import styles from "./AvailableTimeForTag.module.scss";

const AvailableTime = ({currentTime, availableTime, setIsPlaying, setIsControls, intervalContinueStreaming }) => {

  const [time, setTime] = useState(availableTime);
  const dispatch = useDispatch();

  useEffect(() => {
      if(availableTime - Math.round(currentTime) === 0){
        clearInterval(intervalContinueStreaming.current);
        dispatch(openModal({key: "openBuyMoreModal", value: true}));
        setIsPlaying(false);
        setIsControls(false);
      }else{
          setTime(availableTime - Math.round(currentTime));
          setIsControls(true);
      }
  },[currentTime, availableTime]);

  return (
    <>
      <div className={styles.description}>
      </div>

      <h5>
          Available Time:{" "}
          {moment.utc(moment.duration(time,'seconds').as('milliseconds')).format('HH:mm:ss')}
      </h5>

    </>
  );
};

export default AvailableTime;
