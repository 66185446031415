import "./ReportPageFilters.scss";

import {AlertTypes, iFullStoreState, ReportType,} from '../../../shared/interfaces';
import {Button, ButtonSize, ButtonStyles} from "../../Button";
import {DaysChooser, RangeDateChooser} from './DateChoosers';
import {FilterType, IProps, IPropsFromStore, IState} from './ReportPageFilters.interfaces';
import {
    alertsSelector,
    deviceSelector,
    labelsSelector,
    personSelector,
    tagsSelector,
	typeSelector,
} from './ReportSelectors';
import {connect, useDispatch, useSelector} from 'react-redux';
import {path, pipe} from 'ramda';
import C, { ACL, UserCan } from '../../../shared/constants';
import {Collapsible} from "../../general/Collapsible";
import {GMapModalAC} from '../../../stores/reducers/gMapModal/AC';
import {Overlay} from '../../menus/modal/modal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useEffect } from 'react';
import {Actions as ReportActions} from '../../../stores/reducers/report-reducers';
import {ReportPageFilterSection} from './ReportPageFilterSection';
import {isMobile} from '../../../shared/helpers';
import { lEmpty } from '../../../shared/helpers';
import moment from 'moment';
import {BaseComponent} from '../../../shared/BaseComponent';
import {ReactComponent as BookmarkSvg} from "../../../assets/svg/bookmarks.svg";
import {ReactComponent as CalendarToday} from "../../../assets/svg/calendar_today.svg";
import {ReactComponent as DevicesSvg} from "../../../assets/svg/devices.svg"
import {ReactComponent as LayerSvg} from "../../../assets/svg/layers.svg";
import {ReactComponent as PeopleSvg} from "../../../assets/svg/people.svg";
import {ReactComponent as WarningAmberSvg} from "../../../assets/svg/warning_amber.svg";
import {ReactComponent as ScheduleSvg} from "../../../assets/svg/unauthorized_schedule.svg";
import { useHistory } from "react-router-dom";
import {localStorage} from '../../../shared/storage';
import Schedule from "../../Schedule";

export const checkingSelectedFiltersCount = ({currentFilters}) => {
	const filtersItemCount = Object.entries(currentFilters).reduce((acc, [key, value]) => ({
		...acc,
		[key]: Object.keys(value).length
	}), {});

	return filtersItemCount
}

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => {

    const reportTypeRedux = typeof state.report.details.reportTypeFromRedux == 'undefined' ? 'travel' : state.report.details.reportTypeFromRedux
	return ({
    visDevices: deviceSelector(state),
    visTypes: typeSelector(state),
    visPeople: personSelector(state),
    visTags: tagsSelector(state),
    visAlerts: alertsSelector(state),
    visLabels: labelsSelector(state),
    dates: state.report.details.dates,
    reportKey: state.report.details.searchKey,
    dateError: state.report.dateError,
    labels: state.general.tripLabels,
    devicesDetails: state.devicesData.devicesDetails,
    people: state.general.people,
    tags: state.general.tags,
    filterStrs: state.report.filterFilters,
    currentFilters: state.report.details.filters,
	userCan: state.auth.user.acl.can,
	reportTypeFromRedux: reportTypeRedux,
}) 
};


class ReportPageFilters extends BaseComponent<IProps, IState> {

    state = {
        multiDate: this.props.dates.length !== 1 ,
        deviceFilter: '',
        startKey: this.props.reportKey,
		startAndEndDateChange: {},
    }


    __countStartEndDateSafe = () => {
        const { dates } = this.props;
        if (dates.length === 0) return null;

        let startDate, endDate;
        if (this.state.multiDate) {
            startDate = moment.min(dates.map(d => d.startDate));
            endDate = moment.max(dates.map(d => d.endDate));
        } else {
            startDate = dates[0].startDate;
            endDate = dates[0].endDate;
        }

        return { startDate, endDate };
    }

    handleClearAll = () => {
        this.props.dispatch(ReportActions.RESET_ALL_FILTERS());
        this.props.dispatch(ReportActions.RESET_DATES());
        this.setState({multiDate: true});
    };

    debugRender = () => {
			const { 
				visDevices, 
				filterStrs, 
				dispatch,
				visPeople,
				visTypes, 
				dateError, 
				visTags, 
				visAlerts, 
				visLabels,
				reportKey, 
				currentFilters, 
				devicesDetails,
				people, 
				tags, 
				labels,
				dates,
				userCan,
				reportTypeFromRedux
			} = this.props;
			const seePeopleFilter = ACL.check(UserCan.FILTER_REPORTS_BY_PEOPLE, userCan) && reportTypeFromRedux !== 'video' && reportTypeFromRedux !== 'activity';
			const seeDevicesFilter = reportTypeFromRedux !== 'permissions';
			const seeTagsFilter = reportTypeFromRedux !== 'permissions';
			const seeAlertsFilter = reportTypeFromRedux !== 'video' && reportTypeFromRedux !== 'permissions' && reportTypeFromRedux !== 'activity';
			const seeValuesFilter = reportTypeFromRedux === 'static';
			const seeLabelsFilter = reportTypeFromRedux !== 'video' && reportTypeFromRedux !== 'permissions' && reportTypeFromRedux !== 'activity';
			const seeDateFilter = reportTypeFromRedux !== 'permissions' && reportTypeFromRedux !== 'activity';

			const isAdmin = ACL.check(UserCan.DO_ANYTHING, userCan, true);

			const { multiDate, startKey } = this.state;

			const dateDisplay = this.__countStartEndDateSafe();

			const countOfSelectedFilters = checkingSelectedFiltersCount({currentFilters});

			const loginToken = localStorage.get('login-init-be-token');

			return (
			<div className="report-filter-wraper">
				<PerfectScrollbar>
				{/* <ModalHeader title="Report filters" /> */}
				<div className="report-filters"  style={{display: 'flex', flexWrap: 'nowrap', flexDirection: 'column'}}>
					<div className="report-filters-header">
						<h3>Report filters</h3>
					</div>
          <ReportPageFilterSection
            Icon={BookmarkSvg}
            title="Report type"
            visible={isAdmin ? visTypes : visTypes.filter((type) => type.key !== 'audit-log')} 
					  filterType={FilterType.TYPES}
            countOfSelectedFilters={countOfSelectedFilters}
						RedirectToTabs={true}
          />
					{/* Device Filter Selector */}
					{seeDevicesFilter && (
						<ReportPageFilterSection
							disabled={!lEmpty(currentFilters[FilterType.TAGS])}
							Icon={DevicesSvg}
							title="Devices"
							visible={visDevices}
							filterType={FilterType.DEVICES}
							countOfSelectedFilters={countOfSelectedFilters}
						/>
					)}

					{/* Person filter Selector */}
					{seePeopleFilter &&
						<ReportPageFilterSection
							disabled={!lEmpty(currentFilters[FilterType.TAGS])}
							Icon={PeopleSvg}
							visible={visPeople}
							title="People"
							filterType={FilterType.PEOPLE}
							countOfSelectedFilters={countOfSelectedFilters}
						/>
					}

					{/* Tag Filter Selector */}
					{seeTagsFilter && (
						<ReportPageFilterSection
							Icon={LayerSvg}
							disabled={!lEmpty(currentFilters[FilterType.PEOPLE]) || !lEmpty(currentFilters[FilterType.DEVICES])}
							title="Tags"
							visible={visTags}
							filterType={FilterType.TAGS}
							countOfSelectedFilters={countOfSelectedFilters}
						/>
					)}

					{/* Alerts filter selector */}
					{seeAlertsFilter &&
            <ReportPageFilterSection
              Icon={WarningAmberSvg}
              title="Alerts"
              RedirectToStatic
              visible={visAlerts}
              filterType={FilterType.ALERTS}
              countOfSelectedFilters={countOfSelectedFilters}
            />
          }

          {/* Values filter selector */}
          {seeValuesFilter && (<ReportPageFilterSection
            Icon={BookmarkSvg}
            title="Values"
            hasSelectionsGrid
            filterType={FilterType.VALUES}
            countOfSelectedFilters={countOfSelectedFilters}
          />)}

					{/* Labels */}
					{seeLabelsFilter &&
          <ReportPageFilterSection
						Icon={BookmarkSvg}
						title="Labels"
						visible={visLabels}
						filterType={FilterType.LABELS}
						countOfSelectedFilters={countOfSelectedFilters}
					/>}

					{seeDateFilter && (
						<div className="date-choser-filter-section report-filter-section">
							<Collapsible
								isCollapsed={true}
								className="report-filter"
								headerClassName="report-filter-header"
								bodyClassName="report-filter-body"
								scrollIntoView
								header={
									<div className="report-filter-labels-header">
										<i className="report-filter-icon"><CalendarToday/></i>
										<span>
										Date/Range {
												dateDisplay && (
													<span className="date weight-normal">
													({dateDisplay.startDate.format("MM/DD/YYYY")} - {dateDisplay.endDate.format("MM/DD/YYYY")})
												</span>
												)
											}
									</span>
										</div>
									}
								>
									<div>
										<div className={`date-filter-tabs`}>
										<span
											onClick={() => {
												dispatch(ReportActions.RESET_DATES());
												this.setState({multiDate: true})
											}}
											className={`date-tab ${multiDate ? "active" : ""}`}>
											Date
										</span>
											<span
												onClick={() => {
													dispatch(ReportActions.RESET_DATES());
													this.setState({multiDate: false})
												}}
												className={`date-tab ${!multiDate ? "active" : ""}`}>
											Range
										</span>
										</div>
										{!dateError ? <div style={{height: 3}}/> : <div style={{
											marginTop: 8,
											color: 'darkred',
											textAlign: 'center',
											marginBottom: -25,
											paddingBottom: 8
										}}>{dateError}</div>}

										<div style={{marginTop: 15}}>
											{multiDate
												? <DaysChooser
													dates={dates}
													remove={pipe(ReportActions.REMOVE_DATE_GROUP_IN_DATES, dispatch)}
													dispatch={dispatch}
													startDateChange={el => this.setState(function (prevState) {
														return {
															startAndEndDateChange: {
																...prevState.startAndEndDateChange,
																startOfDateChange: el
															}
														}
													})}
													endDateChange={el => this.setState(function (prevState) {
														return {
															startAndEndDateChange: {
																...prevState.startAndEndDateChange,
																endOfDateChange: el
															}
														}
													})}
												/>
												: <RangeDateChooser
													style={dateError ? {borderColor: 'darkred'} : {}}
													initialStart={path([0, 'startDate'])(dates)}
													initialEnd={path([0, 'endDate'])(dates)}
													calendarHideable={false}
													calendarTop
													startDateChange={el => this.setState(function (prevState) {
														return {
															startAndEndDateChange: {
																...prevState.startAndEndDateChange,
																startOfDateChange: el
															}
														}
													})}
													endDateChange={el => this.setState(function (prevState) {
														return {
															startAndEndDateChange: {
																...prevState.startAndEndDateChange,
																endOfDateChange: el
															}
														}
													})}
												/>
											}
										</div>
									</div>
								</Collapsible>
							</div>
						)}
						{reportTypeFromRedux === ReportType.UNAUTHORIZED && (<div className='report-filter-section'>
							<Collapsible
								header={
									<div className='report-filter-labels-header'>
										<i className='report-filter-icon'><ScheduleSvg/></i>
										<span>Schedule</span>
									</div>
								}
								isCollapsed={true}
								className='report-filter'
								headerClassName='report-filter-header'
								bodyClassName='report-filter-body'
							>
								<Schedule/>
							</Collapsible>
						</div>)}

					</div>
				</PerfectScrollbar>
				<FiltersActions handleClearAll={this.handleClearAll} multiDate={multiDate}
												startAndEndDateChange={this.state.startAndEndDateChange}
												reportTypeFromRedux={reportTypeFromRedux}/>
			</div>
			);
		}
}

export default connect(mapStateToProps)(ReportPageFilters);

const FiltersActions = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		const unique = {
			uniqueTripPath: [],
			isSelectedAreaMode: false,
		}

		dispatch(ReportActions.SET_TRIPS_FROM_SELECTED_AREA(unique));
	}, [props.startAndEndDateChange.startOfDateChange, props.startAndEndDateChange.endOfDateChange])

	const _clearRecords = () => {
		dispatch({type: 'REPORT_SET_DISPLAY_RECORDS', displayRecords: {}});
	}

	const handleClose = () => dispatch(GMapModalAC.hideModal());

	const handleApply = () => {
		history.push(`/reports/new/${props.reportTypeFromRedux}`);

		if (props.startAndEndDateChange.startOfDateChange || props.startAndEndDateChange.endOfDateChange) {
			if (!props.startAndEndDateChange.startOfDateChange.isValid() && !props.startAndEndDateChange.endOfDateChange.isValid()) {
				dispatch(ReportActions.RESET_DATES());
			}
			else {
				dispatch(ReportActions.SET_START_DATE(props.startAndEndDateChange.startOfDateChange));
				dispatch(ReportActions.SET_END_DATE(props.startAndEndDateChange.endOfDateChange));
			}
		}

		dispatch(GMapModalAC.hideModal());
	}

	return (
		<div className="report-filters-actions">
			{!(isMobile || window['cordova']) &&
				<div>
					<Button
						styleType={ButtonStyles.GRAY_INACTIVE}
						size={ButtonSize.MD}
						onClick={props.handleClearAll}>
						Clear all
					</Button>
				</div>
			}
			<div className={(isMobile || window['cordova']) ? 'w100' : ''}>
				<Button
					styleType={ButtonStyles.WHITE_GRAY}
					size={(isMobile || window['cordova']) ? ButtonSize.LG : ButtonSize.MD}
					onClick={handleClose}>
					Cancel
				</Button>
				<Button
					style={{marginLeft: "5px"}}
					styleType={ButtonStyles.ORANGE_WHITE}
					size={(isMobile || window['cordova']) ? ButtonSize.LG : ButtonSize.MD}
					onClick={handleApply}
				>
					Apply
				</Button>
			</div>
		</div>
	)
}

@connect((s: iFullStoreState) => ({filters: s.report.details.filters}))
class ExtraInfoHelper extends BaseComponent<any, { value?, isMaxSpeed? }> {
	__setFilter = (key, value) => () => {
		const {dispatch} = this.props;

		dispatch(ReportActions.TOGGLE_ITEM_FILTER('alerts', 'speed'));
		dispatch(ReportActions.ITEM_FILTER_EXPLICIT_VALUE('alerts', key, value));
    }

    __setTravelSpeed = () => {
        const { dispatch } = this.props;

        dispatch(ReportActions.TOGGLE_ITEM_FILTER('alerts', 'speed'));
        dispatch(ReportActions.ITEM_FILTER_EXPLICIT_VALUE('alerts', 'speed-explicit', this.state.value))
        this.setState({isMaxSpeed: undefined});
    }

    __close = () => {
        const { dispatch } = this.props;

        dispatch(ReportActions.TOGGLE_ITEM_FILTER('alerts', 'speed'))
        this.setState({isMaxSpeed: undefined})
    }
    debugRender = () => {
        // this has morphed into a speed only helper.
        const { __setFilter } = this;
        const { filters, dispatch } = this.props;
        const { isMaxSpeed=false } = this.state || {};

        if (!filters) return null;

        const { alerts = {} } = filters;

        const needsExtra = ['speed'].find(type => alerts[type] === true);

        if (!needsExtra) return null;

        // now prompt for type if necessary
        let prompt;
        if (needsExtra == 'speed' && !isMaxSpeed) {
            prompt = <div style={{display: 'flex', flexDirection: 'column'}}>
                <p>Please select the type of speed to filter</p>
                <button style={{marginTop: 8}} onClick={() => this.setState({isMaxSpeed: true})} className="btn btn-info">Maximum travel speed</button>
                <button style={{marginTop: 8}} onClick={__setFilter(AlertTypes.Speed, true)} className="btn btn-info">Exceeded preset speed</button>
                {/* <button style={{marginTop: 8}} onClick={__setFilter(AlertTypes.SpeedPosted, true)} className="btn btn-info">Exceeded posted speed</button> */}
            </div>
        } else {
            switch (needsExtra) {
                case 'speed':
                    prompt = <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p>Please enter the max speed</p>

                        <input type="text" pattern="[09].*" autoFocus className="mas-input" style={C.inputCss} onChange={({target: {value}}) => this.setState({value})}/>

                        <button style={{marginTop: 5}} className="btn btn-sm btn-primary" onClick={this.__setTravelSpeed}>Submit</button>
                    </div>
            }
        }

        return (
            <Overlay close={this.__close}>
                {prompt}
            </Overlay>
        )
    }
}
