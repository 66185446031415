import { Button, ButtonSize, ButtonStyles } from '../Button';
import React, { CSSProperties as css } from 'react';
import { faChevronLeft, faChevronRight, faSearch } from '@fortawesome/fontawesome-free-solid';

import { BaseComponent } from '../../shared/BaseComponent';
import C from '../../shared/constants';
import { Fa } from '../elements/fa';

interface iProps {
    placeholder,
    list: JSX.Element[],
    filterStr: string,
    filterChange: (filter) => any,
    keyClicked: ((keyId: string) => any),
    perPage: number
    page?: number,
    textual?: boolean,
    dispatchers?: any,
    style?: css,
    actions?: JSX.Element
}

interface iState {
    page: number;
}

export default class SearchGrid extends BaseComponent<iProps, iState> {

    state = {
        page: 1
    }

    displayItems = () => {
        const { perPage, list, textual } = this.props;
        const { page } = this.state;

        return list
            .filter((i, idx) => idx >= perPage * (page - 1) && idx < perPage * (page - 1) + perPage)
            .map(i => <div key={i.key} style={!textual ? itemStyle : {}} onClick={_ => this.props.keyClicked(i.key.toString())}>{i}</div>)
    }

    private get pageCount() {
        return Math.ceil(this.props.list.length / this.props.perPage);
    }

    filterChange = ({ target }) => {
        this.props.filterChange(target.value);
    }

    changePage = (amount) => this.setState(state => {
        let newPage = this.state.page + amount;
        if (newPage < 1) newPage = 1;
        if (newPage > this.pageCount) newPage = this.pageCount;

        return { page: newPage };
    })

    countNumberOfUnitsSelected = (list) => {
        let numberOfUnitsSelected = 0;

        list.forEach(obj => {
            if (obj.props && obj.props.children && obj.props.children.props && obj.props.children.props.active === true) {
                numberOfUnitsSelected++;
            }
        });

        return numberOfUnitsSelected;
    }

    returnStringOfUnitsSelected = (numberOfUnitsSelected) => {
        if (numberOfUnitsSelected === 0) {
            return 'No units selected'
        } else if (numberOfUnitsSelected === 1) {
            return `${numberOfUnitsSelected} unit selected`
        } else {
            return `${numberOfUnitsSelected} units selected`
        }
    }

    debugRender = () => {
        const { placeholder, list, style = {} } = this.props;
        const { page } = this.state;
        
        const numberOfUnitsSelected = this.countNumberOfUnitsSelected(list);

        return (
            <div style={{ ...style, margin: '0 auto' }}>
                {
                    this.props.actions && (
                        <div style={gridActionsCss}>
                            <span style={unitsSelectedSpan}>{this.returnStringOfUnitsSelected(numberOfUnitsSelected)}</span>
                            {this.props.actions}
                        </div>
                    )
                }
                <div style={{ borderTop: '1px solid #333', position: 'relative', backgroundColor: C.lightGray, display: 'flex', alignItems: 'center' }}>
                    <Fa icon={faSearch} style={{ color: C.mediumGray, paddingBottom: 4, paddingLeft: 4, fontSize: 12 }} />
                    <input type="text" placeholder={placeholder} style={searchCss} value={this.props.filterStr} onChange={this.filterChange} />
                </div>
                <div style={containerCss}>{this.displayItems()}</div>
                <Pager page={page} pages={this.pageCount} change={this.changePage} />
            </div>
        )
    }
}

const unitsSelectedSpan: css = {
    color: '#212529',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    fontSize: 12,
}

const gridActionsCss: css = {
    display: 'flex',
    justifyContent: 'flex-end',
    border: '1px solid ' + C.lightGray,
    padding: '3px 3px 3px 0px',
}

const pagerContainer: css = {
    marginTop: -1,
    border: '1px solid ' + C.lightGray,
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
}
const chev: css = {
    color: C.primaryColor,
    cursor: 'pointer',
    padding: 10
}
const disabled: css = {
    color: C.mediumGray,
    cursor: 'not-allowed'
}
const pageDot: css = {
    width: 12, height: 12, borderRadius: 12,
    minWidth: 12,
    borderWidth: 1,
    borderColor: C.mediumGray,
    borderStyle: 'solid',
    margin: '0 5px',
    display: 'inline-block',
    cursor: 'pointer',
}
const pageDotCur: css = {
    backgroundColor: C.primaryColor,
    borderColor: C.primaryColor,
    cursor: 'default',
}

const Pager = ({ page, pages, change }) => {
    if (pages < 2) return null;

    let pageNums = Array(pages).fill(1).map((_, p) => p + 1);

    return <div style={pagerContainer}>
        <Fa icon={faChevronLeft} onClick={_ => change(-1)} style={{ ...chev, ...(page == 1 ? disabled : {}) }} />
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '100%', overflowX: 'auto' }}>
            {pageNums.map(p => (
                <span
                    key={p}
                    style={{ ...pageDot, ...(page == p ? pageDotCur : {}) }}
                    onClick={_ => change(p - page)}
                />
            ))}
        </div>
        <Fa icon={faChevronRight} onClick={_ => change(1)} style={{ ...chev, ...(page == pages ? disabled : {}) }} />
    </div>
}

const searchCss: css = {
    width: '100%',
    fontSize: 14,
    backgroundColor: 'transparent',

    outline: 'none',
    marginLeft: 5,
    padding: 5,
    border: '1px solid ' + C.lightGray

}

const itemStyle: css = {
    minWidth: 80,
    flex: 1,
    minHeight: 35,
    borderBottom: '1px solid ' + C.lightGray,
    borderLeft: '1px solid ' + C.lightGray,
    borderRight: '1px solid ' + C.lightGray,
    borderTop: '1px solid ' + C.lightGray,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    cursor: 'pointer',
}

const containerCss: css = {
    display: 'flex',
    flexWrap: 'wrap',
}
