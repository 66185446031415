export const OPEN_DASHBOARD = 'OPEN_DASHBOARD';
export const CLOSE_DASHBOARD = 'CLOSE_DASHBOARD';
export const FULL_OPEN_DASHBOARD = 'FULL_OPEN_DASHBOARD';
export const FULL_CLOSE_DASHBOARD = 'FULL_CLOSE_DASHBOARD';

export const openDashboard = () => ({type: OPEN_DASHBOARD});

export const closeDashboard = () => ({type: CLOSE_DASHBOARD});

export const fullCloseDashboard = () => ({type: FULL_CLOSE_DASHBOARD});

export const fullOpenDashboard = () => ({type: FULL_OPEN_DASHBOARD});
