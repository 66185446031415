import { /*takeEvery,*/ put, takeLatest } from "redux-saga/effects";
import { deleteCompletedMaintenanceTaskDb, fetchCompletedMaintenanceDb } from "../../shared/db/maintenance-db";
import { getPoints, /*getReportMaintenanceTasks,*/ getTrips, iGetReportPropsWrapper } from "../../shared/db/report-db";
// import { iCompleteMaintenanceTaskContainer } from "../../shared/interfaces";
import { ReportActions, Actions as ReportAC } from "../../stores/reducers/report-reducers";

function* fetchCompletedMaintenanceTasks(action) {
    try {
        const { getDevicePingProps, limit }: { getDevicePingProps: iGetReportPropsWrapper, limit: string[] | false} = action.payload;
        // console.log("data", getDevicePingProps.dates)
        // const date = getDevicePingProps.dates[0].startDate;
        yield put(ReportAC.setFetchingMaintenanceReports());

        // const devicePingRecords = yield getPoints(getDevicePingProps, limit || false);
        
        const deviceTripRecords = yield getTrips(getDevicePingProps, limit || false);
        const completedMaintenance = yield fetchCompletedMaintenanceDb(getDevicePingProps, {});
        yield put(ReportAC.fetchMaintenanceReportsSuccess({devicePing: {}, completedMaintenance, deviceTrip: deviceTripRecords}));
    } catch (e) {
        console.log(e);
        yield put(ReportAC.fetchCompletedMaintenanceTaskFailed({
            message: "Something went wrong"
        }));
    }
}

function* deleteCompletedMaintenanceTask(action) {
    const { deviceKey, taskKey } = action.payload;
    try {
        const res = yield deleteCompletedMaintenanceTaskDb({ deviceKey, taskKey });
        yield put(ReportAC.deleteCompletedMaintenanceTaskSuccess(taskKey));
    } catch (e) {
        console.log(e);
        yield put(ReportAC.deleteCompletedMaintenanceTaskFailed(e));
    }
}

export default function* watchReports() {
    // yield takeEvery(ReportActions.FETCH_COMPLETED_MAINTENANCE_TASKS, fetchCompletedMaintenanceTasks);
    yield takeLatest(ReportActions.FETCH_MAINTENANCE_REPORTS, fetchCompletedMaintenanceTasks);
    yield takeLatest(ReportActions.DELETE_COMPLETED_MAINTENANCE_TASK, deleteCompletedMaintenanceTask);
}