import React, { FC, HTMLProps } from "react";
import "./index.scss";

import { ReactComponent as SearchLoopIcon } from "../../assets/svg//search_loop.svg";

interface iSearchInput extends HTMLProps<HTMLInputElement> {};

const SearchInput: FC<iSearchInput> = (props) => {
    return (
        <label className="search-input">
            <input {...props} className="search-input__input" type="text" />
            <SearchLoopIcon className="search-input__icon"  />
        </label>
    )
}

export default SearchInput;