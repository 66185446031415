import React, {useEffect, useState} from 'react'
import { defaultSettingsTemplate } from '../../shared/constants';
import { useFirebase } from '../../states/firebase-state';
import { Col, Row } from '../elements/flex';
import "./styles.scss"
import C from '../../shared/constants';
import { MinimalTile } from '../general';
import { iDefaultSettings } from '../../shared/interfaces';
import Timezones from "../pages/devices/DeviceTabPageEdit/Timezones";
import {namingHelper} from "../../shared/helpers";
import {presetDefaultDeviceSettings} from "../../shared/db/devices-db";

type ModalProps = {
    isOpenModal: Function,
    loadedSettings: iDefaultSettings,
}

type ISettingRowProps = {
    name: string;
    value: string;
    onChange: (field: string) => void;
}

export const SettingRow = ({name, value, onChange}: ISettingRowProps) => (
    <Row className="settingRow" justify={'unset'}>
        <span className="name">{namingHelper(name)}</span>
        {name === 'timezone' ?
            <Timezones value={value} onChange={onChange(name)} className={'selectContainer'}/>
            :
            <input
                type="text"
                className="setting-input mas-input"
                name={name}
                style={{...C.inputCss}}
                value={value}
                //Todo: change this callback function logic if number of default settings increases
                onChange={(e) => onChange(name)}
            />
        }
    </Row>)

const DefaultSettingsModal:React.FunctionComponent<ModalProps> = ( { isOpenModal, loadedSettings } ) =>{

    const [ , setInitialSettings] = useFirebase<iDefaultSettings>('default-settings')
    const [defaultSettings, setDefaultSettings] = useState({})
    const [isSetAll, setIsSetAll] = useState(false)

    if(Object.entries(defaultSettings).length === 0){

        const temp = {}

        Object.entries(defaultSettingsTemplate).forEach(setting => {
            if(Object.entries(loadedSettings||{}).length > 0){
                if(!loadedSettings.hasOwnProperty(`${setting[0]}`)){
                    temp[setting[0]] = setting[1]
                }
            }
            else{
                temp[setting[0]] = setting[1]
            }
        })
        setDefaultSettings(temp)
    }

    const onFieldChange = (name) => (filed) =>{
        setIsSetAll(true)

        const tempSet = {
            ...defaultSettings, 
            [`${name}`]: filed.value
        }

        Object.entries(tempSet).forEach( setting =>{
            if(setting[1] === '') setIsSetAll(false)
        })

        setDefaultSettings({
            ...defaultSettings,
            [`${name}`]: filed.value
        })
    }

    const saveSettings = async () => {

        setInitialSettings({
            ...loadedSettings,
            ...defaultSettings
        })
        await presetDefaultDeviceSettings();
        isOpenModal()
    }

    return (
        <div className="defset-wrapper">
            <div className="defset">
                <p className="title">You need to set some system default settings </p>
                <MinimalTile>
            <Col>
            {Object.entries(defaultSettings || {}).map(
                (setting) =>
                    <SettingRow
                        key={setting[0]}
                        name={setting[0]}
                        value={setting[1].toString()}
                        onChange={onFieldChange}
                    />
                )
            }
            </Col>
            <button 
                className="btn btn-primary btn-xs"
                disabled={!isSetAll}
                onClick={saveSettings}
            > Save </button>
        </MinimalTile>
            </div>
        </div>
    )
}

export default DefaultSettingsModal