import React, {useState, useEffect, useRef} from "react";
import "./index.scss";
import { useRedux } from "../../states/redux-state";
import { GMapModalAC, GMapModalContent } from "../../stores/reducers/gMapModal/AC";
import { ExportReport } from "../pages/reports/ExportReport";
import SelectReportSendTo from "../pages/reports/SelectReportSendTo"
import ReportPageFilters from "../pages/reports/ReportPageFilters";
import NewMaintenanceForm from "../pages/maintenance/NewMaintenanceForm";
import CompleteTaskForm from "../pages/maintenance/CompleteTaskForm";
import ReportTaskDetails from "../pages/reports/ReportTaskDetails";
import EditMaintenanceTask from "../pages/maintenance/EditMaintenanceTask";
import CompleteExtraTask from "../pages/maintenance/CompleteExtraTask";
import "./index.scss";
import {isMobile} from '../../shared/helpers';
import MobileTopArrow from "../elements/MobileTopArrow/MobileTopArrow";
import { useDispatch } from "react-redux";
import LocalModalActions from "../pages/reports/ReportPage/LocalModalActions";
import { CreateReportModal } from "../../components/pages/reports/scheduled-reports";
import ReportPageFiltersDatepicker from "../pages/reports/ReportPageFiltersDatepicker/ReportPageFiltersDatepicker";

export const GMapModal = () => {
    const {show, contentType, params} = useRedux(s => s.gMapModal);
    const content  = getContent(contentType, params);

	// START: for Animation of arrow('MobileTopArrow' component)
	const [animationGoes, setAnimationGoes] = useState(false);
	const parentBlock = useRef(null);
	const dispatch = useDispatch();

	const hideBlock = () => {
		setAnimationGoes(true);
		parentBlock.current.addEventListener("animationend", ()=>{
			dispatch(GMapModalAC.hideModal())
		});
	}

    const toHideModalByEscPress = (e) => {
        dispatch(GMapModalAC.hideModal());
}

    useEffect(() => {
        const closeWindow = (e) => {
            if (e.keyCode === 27) {
                toHideModalByEscPress(e);
            }
        }
        window.addEventListener('keydown', closeWindow)
        return () => window.removeEventListener('keydown', closeWindow)
    }, [])


    const toHideModal = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (e.target === e.currentTarget) {
            dispatch(GMapModalAC.hideModal());
        }
    }

	useEffect(()=>{
		setAnimationGoes(false);
	}, [show])
	// END: for Animation of arrow('MobileTopArrow' component)

    return (
        show? (
			// checking 'animationGoes' and ref -> using for animation of 'MobileTopArrow' component
            <div className={`google-map-modal-container ${params.fullHeight && 'full-height'} ${animationGoes ? 'animation' : ''}`} ref={parentBlock} onClick={toHideModal}>
				{isMobile && <MobileTopArrow onClick={hideBlock}/>}
                <div className="google-map-modal-content">
                    {content}
                </div>
            </div>
        ) : null
    )
}

const getContent = (contentType: GMapModalContent, params) => {
    switch (contentType) {
        case GMapModalContent.REPORT_PAGE_FILTERS:
            return <ReportPageFilters {...params}/>
        case GMapModalContent.REPORT_PAGE_FILTERS_DATEPICKER:
            return <ReportPageFiltersDatepicker {...params}/>
        case GMapModalContent.EXPORT_REPORT:
            return <ExportReport {...params} />
        case GMapModalContent.SELECT_SEND_TO:
            return <SelectReportSendTo />
		case GMapModalContent.AVERAGE_VALUES:
			return <LocalModalActions />
		case GMapModalContent.GENERATE_REPORT:
			return <CreateReportModal />
        case GMapModalContent.NEW_MAINTENANCE:
            return <NewMaintenanceForm />
        case GMapModalContent.MAINTENANCE_COMPLETE_TASK:
            return <CompleteTaskForm {...params}/>
        case GMapModalContent.REPORT_PAGE_TASK_DETAILS:
            return <ReportTaskDetails {...params} />
        case GMapModalContent.EDIT_MAINTENANCE_TASK:
            return <EditMaintenanceTask {...params} />
        case GMapModalContent.COMPLETE_EXTRA_TASK:
            return <CompleteExtraTask {...params}/>
        case GMapModalContent.EMPTY:
            return null;
    }
}