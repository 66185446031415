import {
  faMinusCircle,
  faPlusCircle,
} from '@fortawesome/fontawesome-free-solid';
import React from 'react';
import * as alertsdb from '../../../../../shared/db/alerts-db';
import { connect } from 'react-redux';
import { BaseComponent } from '../../../../../shared/BaseComponent';
import { idValArr } from '../../../../../shared/helpers';
import {
  iFullStoreState,
  iEventInstance,
} from '../../../../../shared/interfaces';
import SwitchComponent from '../../../../elements/SwitchComponent';
import { Fa } from '../../../../elements/fa';
import { FromTo } from './FromTo/FromTo';

import './DoNotDisturb.styles.scss';

const isAllDay = ({ from, to }) => from == 0 && to == 23;

@connect((state: iFullStoreState) => ({
  authUser: state.auth.user,
}))
export class DoNotDisturb extends BaseComponent<
  { alertInst: iEventInstance; eventKey; itemType; itemId },
  { activeDay: string },
  { authUser }
> {
  days = [
    { day: 'sun', disp: 'S' },
    { day: 'mon', disp: 'M' },
    { day: 'tue', disp: 'T' },
    { day: 'wed', disp: 'W' },
    { day: 'thr', disp: 'T' },
    { day: 'fri', disp: 'F' },
    { day: 'sat', disp: 'S' },
  ];

  initNewRange = () => {
    const { eventKey, itemType, itemId } = this.props as {
      alertInst: iEventInstance;
      [rest: string]: any;
    };

    alertsdb.upsertDnd(this.fullProps.authUser)(
      { alertType: eventKey, itemType, itemId },
      this.state.activeDay,
      { from: 13, to: 23 }
    );
  };

  debugRender = () => {
    const { eventKey, itemType, itemId, authUser, alertInst } = this.fullProps;

    const { on = false, days = {} } = this.props.alertInst?.dnd ?? {};
    const { activeDay = '' } = this.state || {};

    const alertKey = { alertType: eventKey, itemType, itemId };

    return (
      <div className='dnd'>
        <div className='dnd__header'>
          <span>Do Not Disturb</span>
          <SwitchComponent
            onChange={() =>
              alertsdb.toggleNotificationSchedule(this.fullProps.authUser)(
                alertKey,
                !on
              )
            }
            checked={on}
          />
        </div>
        <p className='dnd__text'>
          Choose the times you DO NOT want to receive this alert
        </p>

        <div className='dnd__days'>
          {this.days.map(({ day, disp }) => (
            <button
              key={day}
              onClick={() => this.setState({ activeDay: day })}
              className={`btn btn${activeDay == day ? '' : '-outline'}-dark`}
            >
              <span>
                {disp}
                {days[day] ? <sup>*</sup> : ''}
              </span>
            </button>
          ))}
        </div>

        {!activeDay ? null : (
          <table className='table table-sm table-striped'>
            <tbody>
              {(idValArr(days[activeDay]) || []).map(
                ({ id, val: { from, to } }: any, idx) => (
                  <>
                    <tr key={`${from}-${to}-${idx}`}>
                      <td style={{ width: 200 }}>
                        <Fa
                          icon={faMinusCircle}
                          onClick={() =>
                            alertsdb.deleteDnd(this.fullProps.authUser)(
                              alertKey,
                              activeDay,
                              id
                            )
                          }
                        />
                        <button
                          onClick={() =>
                            alertsdb.upsertDnd(authUser)(
                              alertKey,
                              activeDay,
                              { from: 0, to: 23 },
                              id
                            )
                          }
                          style={{ width: '90%' }}
                          className={`btn btn-${
                            isAllDay({ from, to }) ? 'primary' : ''
                          } btn-xs`}
                        >
                          All Day
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-right'>
                        <FromTo
                          from={from}
                          to={to}
                          change={(newVal) =>
                            alertsdb.upsertDnd(authUser)(
                              alertKey,
                              activeDay,
                              newVal,
                              id
                            )
                          }
                        />
                      </td>
                    </tr>
                  </>
                )
              )}
              <tr>
                <td
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Fa icon={faPlusCircle} onClick={this.initNewRange} />
                  <span
                    className='btn-link'
                    style={{
                      color: 'rgb(135, 136, 137)',
                      textDecoration: 'none',
                    }}
                    onClick={() =>
                      alertsdb.clearNotificationSchedules(
                        this.fullProps.authUser
                      )(alertKey, activeDay)
                    }
                  >
                    Clear
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  };
}
