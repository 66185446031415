import React from 'react';
import classNames from 'classnames';

import './styles.scss';
import {ValidationRules} from 'react-hook-form';

type RefReturn =
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;

type IProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    id?: string;
    error?: boolean;
    helperText?: string;
    label: string;
    icon?: React.ComponentType;
    register: (rules?: ValidationRules) => RefReturn;
    rules?: ValidationRules;
    handleChange?: any;
}

const InputBox: React.FC<IProps> = ({
    id,
    name,
    label,
    register,
    icon: Icon = () => null,
    helperText,
    rules,
    type = 'text',
    error,
    handleChange,
    defaultValue= ''
}) => {
    const [active, updateActive] = React.useState(false);
    const onFocus = () => updateActive(true);
    const onBlur = () => updateActive(false);

    const identification = id ? `${id}_${name}` : name;

    return (
        <div className="InputBoxWrapper">
            <div className={classNames({'InputBox': true, active, error})}>
                <Icon />
                <input
                    type={type}
                    id={identification}
                    name={name}
                    placeholder={label}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    ref={register(rules)}
                    onChange={handleChange}
                    defaultValue={defaultValue}
                />
                <label htmlFor={identification}>{label}</label>
            </div>
            <div className={classNames({HelperText: true, error})}>{helperText}</div>
        </div>
    );
};

export default InputBox;
