import React, {useEffect, useState} from 'react'
import Modal from "../../../../../Modal";
import styles from "./ContinueStreamModalForTag.module.scss";

const CONTINUE_LIVE_STREAMING: string = 'Do you wish to continue live streaming?';

const ContinueStreamModalForTag = ({closeContinueLiveStreamingModal, setReminderIntervalTime, setTriggerCloseModal}) => {

  const [selectedTime, setSelectedTime] = useState<number>(60000);

  const changeTime = async (): Promise<void> => {
    setReminderIntervalTime(selectedTime);
    closeContinueLiveStreamingModal();
  }

    const handleSelect = (t): void => setSelectedTime(t.target.value * 60 * 1000)

    useEffect(() => {
      return () => {
        setTriggerCloseModal(false);
      };
    }, []);

    return (
        <Modal className={styles.modalContinueLive} onBackdropClick={()=>closeContinueLiveStreamingModal()}>
              <h4>{CONTINUE_LIVE_STREAMING}</h4>
              <div className={styles.selector}>
                  <label htmlFor="time">Remind me in:</label>
                  <select name="time" id="time" onChange={handleSelect}>
                      { Array(10).fill('').map((t, i) => <option key={i+1} value={i + 1}>{`${i + 1} minute`}</option>)}
                  </select>
              </div>
              <div className={styles.actions}>
                <button onClick={() => setTriggerCloseModal(true)}>No</button>
                <button onClick={changeTime}>Yes</button>
              </div>
        </Modal>
    );
};

export default ContinueStreamModalForTag
