import React from 'react';

const YCustomTick = ({isActive, top, left, content}) => {
  return (
    isActive
      ? <div style={{position: "absolute", top, left, zIndex: 10}} className="avg-vs-cost-y-custom-tick">
        {content}
      </div>
      : null
  );
}

export default YCustomTick;