import React from 'react';
import { connect } from 'react-redux';
import Circle from 'react-google-maps/lib/components/Circle';
import Marker from 'react-google-maps/lib/components/Marker';
import Polyline from 'react-google-maps/lib/components/Polyline';
import { flatten } from 'ramda';
import { getDistance } from 'geolib';

import { snappedPoints, icoSize } from '../../shared/helpers';
import { iLocation, iFullStoreState } from '../../shared/interfaces';
import { Actions as pathActions} from '../../stores/reducers/map-path-reducers';
import { BaseComponent } from "../../shared/BaseComponent";
import { devSettings } from "../../shared/dev-helpers";
import { getMapZoom as getMapZoomFromStore } from '../../stores/reducers/gmap-reducers';


interface iProps {
    id: string,
    editable?: boolean,
    start?: iLocation,
    points?: iLocation[],
    recording?: boolean,
    dispatch?: any,
    getMapZoom: () => number,
    width?: number,
    color?: string,
}

const full = ({lat: latitude, lng: longitude}) => ({latitude, longitude});
const mapStateToProps = (state: iFullStoreState, ownProps) => {
    const getMapZoom = (): number => getMapZoomFromStore(state.gmap);
    return {
        points: !state.paths.vis[ownProps.id] ? [] : state.paths.vis[ownProps.id].points,
        editable: state.paths.vis[ownProps.id].editable,
        recording: state.paths.vis[ownProps.id].recording,
        color: state.paths.vis[ownProps.id].color || '#333',
        getMapZoom,
    };
};


export default connect(mapStateToProps)
(class extends BaseComponent<iProps, {snapped: any[]}> {
    state = {
        snapped: []
    }

    handleDrag = ({latLng}) => {
        const { points, id, dispatch, recording } = this.props;

        let dist = -1;
        if (points.length)
            dist = getDistance(
                full(points[points.length - 1]),
                full({lat: latLng.lat(), lng: latLng.lng()}),
                1,
            );


        if (dist < 20 && dist !== -1) return;

        recording
            ? dispatch(pathActions.PUSH_POINTS(id, [{lat: latLng.lat(), lng: latLng.lng()}]))
            : dispatch(pathActions.SWAP_POINTS(id, [{lat: latLng.lat(), lng: latLng.lng()}]));
    }

    snapPoints = async () => {
        const { dispatch, id, points } = this.props;

        const snapped = flatten((await snappedPoints(points)).map(x => x.coordinates))

        dispatch(pathActions.SWAP_POINTS(id, snapped))
    }

    debugRender = () => {
        const { editable, recording, points, start, color, getMapZoom } = this.props;

        const size = icoSize(getMapZoom());

        const me = this;

        const point = points[points.length - 1] || start

        return <div>
            <Polyline path={points} options={{strokeColor: color, strokeWeight: 2,}}/>

            {/* Move Handle */}
            {!editable ? null :
                <Marker
                    position={point}
                    draggable
                    onDrag={function(x) {
                        this.map.setOptions({draggable: false})
                        me.handleDrag(x)
                    }}
                    onDragEnd={function(x) {
                        this.map.setOptions({draggable: true})
                        me.snapPoints();
                    }}
                    icon={{
                        url: recording ? "images/circled_dot_red.png" : "images/circled_dot.png",
                        size: new google.maps.Size(35, 35),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(size/2, size/2),
                            scaledSize: new google.maps.Size(size, size)
                    }}
                />
            }

            {
                    !devSettings.traceRouteBool ? null : points.map((p, idx) => <Circle
                        key={idx}
                        center={p}
                        onClick={() => console.log(p)}
                        radius={2}
                        options={{zIndex: 1, strokeWeight: 1.6, strokeColor: color || '#000000', fillColor: '#000', fillOpacity: 1, clickable: true}}
                    >T</Circle>)
                }
        </div>
    }
})
