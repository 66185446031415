import {takeEvery, put} from "redux-saga/effects";
import {
    addNewMaintenanceTasksOptionDb,
    createMaintenanceTaskDb,
    deleteMaintenanceTaskDb,
    completeMaintenanceTaskDb,
    editMaintenanceTaskDb,
    completeExtraMaintenanceTaskDb
} from "../../shared/db/maintenance-db";
import { iCompleteMaintenanceTask, iCreateMaintenance, UserAuth } from "../../shared/interfaces";
import { Actions, ActionsCreator } from "../reducers/maintenance/actions";

function* createMaintenanceTasks(action) {
    try {
        const user = action.payload.user;
        const maintenances = action.payload.maintenances as iCreateMaintenance[];
        for(const {deviceName, ...m} of maintenances) {
            const res = yield createMaintenanceTaskDb(user)({
                deviceKey: m.deviceKey,
                maintenance: m,
                mainMaintenance: {
                    deviceName: deviceName,
                    taskName: m.name,
                    upcomingHours: m.hours_needed,
                    upcomingMiles: m.miles_needed
                },
                contacts: m.contacts
            });
        }
        yield put(ActionsCreator.createMaintenanceTasksSuccess());
    } catch(e) {
        console.log(e);
        yield put(ActionsCreator.createMaintenanceTasksFailed(e));
    }
}

function* editMaintenanceTasks(action) {
    try {
        const { editMaintenace, user } = action.payload;
        yield editMaintenanceTaskDb(user)(editMaintenace);
    } catch(e) {
        console.log(e);
        yield put(ActionsCreator.editMaintenanceTaskError(e));
    }
}

function* deleteMaintenanceTasks(action) {
    const { user, maintenanceKey, deviceKey } = action.payload;

    const maintenances = action.payload.maintenances as iCreateMaintenance[];
    
    try {
        const res = yield deleteMaintenanceTaskDb(user)({maintenanceKey, deviceKey, maintenances});
        yield put(ActionsCreator.deleteMaintenanceTaskSuccess());
    } catch(e) {
        console.log(e);
        yield put(ActionsCreator.deleteMaintenanceTaskFailed(e));
    }
}

function* completeMaintenanceTask(action) {
    const { user, completeTask }: { user: UserAuth, completeTask: iCompleteMaintenanceTask } = action.payload;
    try {
        const res = yield completeMaintenanceTaskDb(user)(completeTask);
        yield put(ActionsCreator.completeMaintenanceTaskSuccess());
    } catch (e) {
        console.log(e);
        yield put(ActionsCreator.completeMaintenanceTaskFailed(e));
    }
}

function* completeExtraMaintenanceTask(action) {
    const { user, completeTask } = action.payload;
    try {
        yield completeExtraMaintenanceTaskDb(user)(completeTask);
    } catch(e) {
        console.log(e)
    }
}

function* addNewMaintenanceTaskOption(action) {
    console.log("ac", action)
    const { user, newTask } = action.payload;
    try {
        const res = yield addNewMaintenanceTasksOptionDb(user)(newTask);
        yield put(ActionsCreator.addNewMaintenanceTaskOptionSuccess({ key: res.key, task: newTask}));
    } catch (e) {
        console.log("addNewMaintenanceTaskOption", e);
    }
}

export default function* watchMaintenance() {
    yield takeEvery(Actions.CREATE_NEW_TASK, createMaintenanceTasks);
    yield takeEvery(Actions.EDIT_MAINTENANCE_TASK, editMaintenanceTasks);
    yield takeEvery(Actions.DELETE_MAINTENANCE, deleteMaintenanceTasks);
    yield takeEvery(Actions.COMPLETE_MAINTENANCE_TASK, completeMaintenanceTask);
    yield takeEvery(Actions.ADD_NEW_MAINTENANCE_TASK_OPTION, addNewMaintenanceTaskOption);
    yield takeEvery(Actions.COMPLETE_EXTRA_MAINTENANCE_TAKS, completeExtraMaintenanceTask);
  }