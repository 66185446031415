import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';

import { history } from '../../../stores/store';
import { BaseComponent } from '../../../shared/BaseComponent';
import ModalHeader from '../../menus/modal/modal-header';
import ModalActions from '../../menus/modal/modal-actions';
import DashboardBlock from '../../DashboardBlock';
import * as peopleDb from '../../../shared/db/people-db';
import C, { ACL, UserCan } from '../../../shared/constants';

import { MinimalTile } from '../../general';
import { Fa } from '../../elements/fa';
import { noSubmit } from '../../../shared/helpers';
import Dialog, { DialogConfigSetter } from '../../Dialog';
import { IFullProps, mapStateToProps } from './PersonEditPage.types';

const LocalTile = ({ title, children }) => (
  <MinimalTile title={title} small style={{ marginBottom: 10 }}>
    {children}
  </MinimalTile>
);

class PersonEditPage extends BaseComponent<
  IFullProps,
  { personEdits: { [field: string]: any } }
> {
  private dialog: DialogConfigSetter;

  state = {
    personEdits: { displayName: '', mainNumber: '', email: '' } as any,
  };

  setupDialog = (callBack: () => DialogConfigSetter): void => {
    this.dialog = callBack();
  };

  validEmail = (str) => {
    return (
      !/(\.{2}|-{2}|_{2})/.test(str) &&
      /^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(
        str
      )
    );
  };

  validPhoneNumber = (str) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
      str
    );
  };

  addFieldEdit =
    (name) =>
    ({ target: { value } }) => {
      if (name === 'image') return;

      const action =
        this.props.person?.[name] == value
          ? { $unset: [name] }
          : { [name]: { $set: value } };

      this.setState((s) => ({
        personEdits: update(s.personEdits, action),
      }));
    };

  private update = async () => {
    const { id: personId } = this.props.match.params;
    const { authUser } = this.props;
    const { personEdits } = this.state;

    if (authUser) {
      if (
        (this.validEmail(personEdits.email) || !personEdits.email) &&
        (this.validPhoneNumber(personEdits.mainNumber) ||
          !personEdits.mainNumber)
      ) {
        await peopleDb.updatePersonDetails(authUser)(personId, personEdits);
        this.setState({ personEdits: {} });
        history.goBack();
      } else {
        this.dialog?.({
          title: 'Incorrect input!',
          body: 'This should be a valid email or phone number.',
          type: 'NOTIFICATION',
        });
      }
    } else {
      this.setState({ personEdits: {} });
      history.goBack();
    }
  };

  private saveImage = async ({ target }) => {
    const { id: personId } = this.props.match.params;

    const url = await peopleDb.storeProfileImage(personId, target.files[0]);

    this.setState((s) => ({
      personEdits: update(s.personEdits, {
        $set: { photoURL: url },
      }),
    }));
  };

  private cancel = () => {
    this.setState({ personEdits: {} });
    history.goBack();
  };

  componentDidMount() {
    const { person } = this.props;

    const newPerson = {
      displayName: person.displayName || '',
      mainNumber: person.mainNumber || '',
      email: person.email || '',
    };
    this.setState({ personEdits: newPerson });
  }

  debugRender = () => {
    const { person, userCanDo } = this.props;

    if (!person) return null;

    const { photoURL = person.photoURL } = this.state.personEdits;
    const { personEdits } = this.state;

    return (
      <DashboardBlock
        overlay={
          Object.keys(this.state.personEdits).length ? (
            <ModalActions
              buttons={[
                { click: this.cancel, title: 'Cancel' },
                { click: this.update, title: 'Update', primary: true },
              ]}
            />
          ) : undefined
        }
      >
        <ModalHeader title='Edit Person' />
        <form onSubmit={noSubmit}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 10,
            }}
          >
            <span
              className='btn-link'
              onClick={(_) => {
                document.getElementById('user-file-upload')?.click?.();
              }}
            >
              <div
                style={{
                  width: 90,
                  height: 90,
                  borderRadius: 90,
                  backgroundImage: `url(${photoURL})`,
                  backgroundSize: 'cover',
                  border: `2px solid ${C.mediumGray}`,
                  position: 'relative',
                }}
              >
                <Fa
                  icon={faPencilAlt}
                  style={{
                    position: 'absolute',
                    right: -7,
                    bottom: -2,
                    fontSize: 12,
                    color: C.primaryColor,
                  }}
                />
              </div>
            </span>
          </div>
          <div>
            <input
              type='file'
              id='user-file-upload'
              name='image'
              onChange={this.saveImage}
              style={{ display: 'none' }}
            />
          </div>
          <LocalTile title='Display Name'>
            <input
              key='title-input'
              name='displayName'
              className='mas-input'
              style={{ ...C.inputCss, width: '100%' }}
              onChange={this.addFieldEdit('displayName')}
              value={personEdits.displayName}
            />
          </LocalTile>

          <LocalTile title='Main Phone'>
            <input
              key='title-input'
              name='mainNumber'
              className='mas-input'
              style={{ ...C.inputCss, width: '100%' }}
              onChange={this.addFieldEdit('mainNumber')}
              value={personEdits.mainNumber}
            />
          </LocalTile>

          <LocalTile title='Email'>
            <input
              key='title-input'
              name='email'
              className='mas-input'
              style={{ ...C.inputCss, width: '100%' }}
              onChange={this.addFieldEdit('email')}
              value={personEdits.email}
            />
          </LocalTile>
        </form>
        <Dialog setupConfig={this.setupDialog} />
      </DashboardBlock>
    );
  };
}

export default connect(mapStateToProps)(PersonEditPage);
