import { iList, iPerson, iTag } from "../../../shared/interfaces";

import { DevicesDetailsContainer } from "../../../stores/reducers/devicesData";
import { DispatchProp } from "react-redux";
import { ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { iDataFromSelectedArea, iReportType, iTripsFromSelectedArea } from "../../../stores/reducers/report-reducers";
import moment from "moment-timezone";
import { UserCan } from "../../../shared/constants";

export enum FilterType {
	DEVICES = 'device',
	PEOPLE = 'person',
	TAGS = 'tag',
	ALERTS = 'alerts',
	LABELS = 'labels',
	DATA = 'data',
    VALUES = 'values',
    TYPES = 'types',

    EDIT_DEVICES = 'edit_device',
    EDIT_PEOPLE = 'edit_people',
    EDIT_TAGS = 'edit_tags',
    EDIT_ALERTS = 'edit_alerts',
    EDIT_LABELS = 'edit_labels'
}

export interface IPropsFromStore {
    devicesDetails: DevicesDetailsContainer;
    people: iList<iPerson>,
    tags: iList<iTag>,
    dateError?: string,

    labels: string[],
    reportKey?: string;

    visLabels: ReactElement[],
    visDevices: ReactElement[],
    visPeople: ReactElement[],
    visTags: ReactElement[],
    visAlerts: ReactElement[],
    visTypes: ReactElement[],

    dates: { startDate: moment.Moment, endDate: moment.Moment }[],
    filterStrs: { [itemType: string]: string },
    currentFilters: {[type: string]: { [id: string]: true }},
    tripsFromSelectedArea?: iTripsFromSelectedArea,
    dataFromSelectedArea?: iDataFromSelectedArea,

    userCan?: UserCan[],
    reportTypeFromRedux?: string,
}

export interface IRouteParams {
    type: iReportType;
    filterItem?: FilterType;
}

export interface IProps extends IPropsFromStore, DispatchProp, RouteComponentProps<IRouteParams> {}

export interface IState {
    deviceFilter: string;
    multiDate: boolean;
    startKey?: string
    groupDate?: object;
    startAndEndDateChange?: object;
}