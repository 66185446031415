import moment from "moment";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import { useDispatch, useSelector} from "react-redux";
import {iFullStoreState, iMaintenanceTask, iLoadedFileInfo, UserAuth} from "../../../../shared/interfaces";
import { GMapModalAC } from "../../../../stores/reducers/gMapModal/AC";
import { ActionsCreator } from "../../../../stores/reducers/maintenance/actions";
import { selectMaintenance } from "../../../../stores/reducers/maintenance/selectors";
import { Button, ButtonSize, ButtonStyles } from "../../../Button";
import PerfectScrollbar from 'react-perfect-scrollbar';
import "./completeTaskForm.scss";
import { isMobile } from "../../../../shared/helpers";
import { validateCompleteTaskForm, validateSingleFieldCompleteTaskForm } from "./CompleteTaskFormValidation";
import CompleteTaskFormJSX from './CompleteTaskFormJSX';

const CompleteTaskForm = (props) => {
    const fileSizeLimit = 10*1000*1000; // 10MB
    const filesMaxCount = 10;

    const dispatch = useDispatch();
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
    const { deviceKey, maintenanceKey, deviceName, taskName } = props;
    const maintenance = useSelector<iFullStoreState, iMaintenanceTask>(s => selectMaintenance(s, deviceKey, maintenanceKey));

    const [upcomingMaintenanceKey, upcomingMaintenance] = Object.entries(maintenance?.mainUpcoming || {})[0] || [];
    const [documents, updateDocuments] = useState<iLoadedFileInfo[]>([]);
    const [isDocsInLoad, updateDocsInLoad] = useState<boolean>(false);
    const [values, setValues] = useState({
        date: moment(new Date()),
        odometer: null,
        labourHours: null,
        cost: null
    });
    const [errors, updateErrors] = useState({});
    const [needReset, updateNeedReset] = useState<boolean>(false);
    const [isDocsReset, updateIsDocsReset] = useState<boolean>(false);
    const refContainer = useRef(null);
    const refDatePicker = useRef(null);

    const closeModal = () => {
        dispatch(GMapModalAC.hideModal());
    };

    const showErrors = (errors) => {
        if (!errors || !Object.keys(errors).length) return;
        updateErrors(errors);
    };

    const checkValidate = () => {
        return validateCompleteTaskForm(values);
    };

    const singleFieldValidation = (name) => {
        return validateSingleFieldCompleteTaskForm(name, values);
    };

    const handleBlur = ({target: {name}}: ChangeEvent<HTMLInputElement>) => {
        const {isValid, errors: newErrors} = singleFieldValidation(name);
        if (name === 'cost') {
            if (values.cost) {
                setValues({
                    ...values,
                    cost: Number(values.cost).toFixed(2)
                });
            }
        }
        if (isValid) {
            updateErrors({...errors, [name]: null});
        } else {
            updateErrors({...errors, ...newErrors});
        }
    };

    const handleDateBlur = () => {
        const {isValid, errors: newErrors} = singleFieldValidation('date');
        if (isValid) {
            updateErrors({...errors, 'date': null});
        } else {
            updateErrors({...errors, ...newErrors});
        }
    };

    const handleInputChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    }

    const handleDateChange = (date) => {
        setValues({
            ...values,
            date
        })
    }

    const handleCancelClick = () => {
        updateNeedReset(true);
    }

    const onDocsReset = () => {
        updateIsDocsReset(true);
    }

    useEffect(() => {
        if (needReset && isDocsReset) {
            // updateNeedReset(false);
            // updateIsDocsReset(false);
            closeModal();
        }
    }, [needReset, isDocsReset])

    const makeTaskCompleted = () => {
        if (!maintenance) return;
        dispatch(ActionsCreator.completeMaintenanceTask(user, {
            deviceKey,
            maintenanceKey,
            upcomingMaintenanceKey,
            taskCompleteInfo: {...values, date: values?.date?.startOf("day").toDate().getTime() ||  moment(new Date()).startOf("day").toDate().getTime()},
            taskInfo: {
                deviceName,
                taskName,
                date: maintenance?.date || moment(new Date()).startOf("day").toDate().getTime(),
                milesNeeded: maintenance.miles_needed,
                milesOffset: maintenance.miles_offset,
                hoursNeeded: maintenance.hours_needed,
                hoursOffset: maintenance.hours_offset,
                upcomingHours: upcomingMaintenance.upcomingHours,
                upcomingMiles: upcomingMaintenance.upcomingMiles
            },
            documents,
        }));
    };

    const handleCompleteClick = () => {
        const {isValid, errors} = checkValidate();
        if (isValid) {
            makeTaskCompleted();
            closeModal();
        } else {
            showErrors(errors);
        }
    }

    const JSXProps = {
        taskInfo: {
            taskName,
            deviceName,
            milesNeeded: maintenance?.miles_needed,
            hoursNeeded: maintenance?.hours_needed,
            date: maintenance?.date,
        },
        refDatePicker,
        handleDateBlur,
        handleDateChange,
        dateInputValue: values.date,
        odometerValue: values.odometer,
        handleInputChange,
        handleBlur,
        labourHoursValue: values.labourHours,
        costValue: values.cost,
        errors,
        documentsProps: {
            updateDocuments,
            fileSizeLimit,
            filesMaxCount,
            updateDocsInLoad,
            needReset,
            onDocsReset,
        }
    };

    return (
        <div className="complete-task" ref={refContainer}>
            <div className="complete-task__header">Complete task</div>
            <div className="complete-task__scrollable">
                {
                    isMobile
                        ? (
                            <PerfectScrollbar>
                                <CompleteTaskFormJSX {...JSXProps} />
                            </PerfectScrollbar>
                        )
                        : <CompleteTaskFormJSX {...JSXProps} />
                }
            </div>
            <div className="complete-task__actions">
                <Button styleType={ButtonStyles.WHITE_GRAY} disabled={isDocsInLoad} size={ButtonSize.MD}
                        onClick={handleCancelClick}>Cancel</Button>
                <Button styleType={ButtonStyles.ORANGE_WHITE} size={ButtonSize.MD}
                        onClick={handleCompleteClick} disabled={isDocsInLoad}>Complete</Button>
            </div>
        </div>
    )
}

export default CompleteTaskForm;
