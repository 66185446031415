import React from "react";
import { connect, useDispatch } from "react-redux";
import { iFullStoreState } from "../../../../shared/interfaces";
import { iGMapModal } from "../../../../stores/reducers/gMapModal";
import { GMapModalAC, GMapModalContent } from "../../../../stores/reducers/gMapModal/AC";
import { iReportDetails } from "../../../../stores/reducers/report-reducers";
import {Button, ButtonSize, ButtonStyles} from "../../../Button";
import {ReactComponent as FilterListSvg} from "../../../../assets/svg/filter_list.svg";

const FilterPrompt = connect(
    (s: iFullStoreState): {details: iReportDetails, gMapModal: iGMapModal} => ({details: s.report.details, gMapModal: s.gMapModal})
)(({details}: {details: iReportDetails, gMapModal}) => {
    const dispatch = useDispatch();
    const showReportFiltersModal = () => {
        dispatch(GMapModalAC.showModal({contentType: GMapModalContent.REPORT_PAGE_FILTERS}));
    }
    return (
        <div className="report-filter-begin">
            <p>Select report filters to begin.</p>
            <Button
                styleType={ButtonStyles.BLACK_WHITE}
                size={ButtonSize.MD}
                onClick={showReportFiltersModal}
            >
                <span className={"align-center"}><FilterListSvg className="icon-md"  style={{marginRight: "5px"}}/>Report Filters</span>
            </Button>
        </div>
    )
});

export default FilterPrompt;