import React, {FC} from "react";
import { ReactComponent as CheckBoxSvg} from "../../assets/svg/check_box.svg";
import "./index.scss";
type IProps = {
    checked: boolean;
    onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

export const Checkbox: FC<IProps> = ({checked, onClick, ...props}) => {
    return (
        <span className={`custom-checkbox`} {...props}>
            <CheckBoxSvg className={`custom-checkbox-icon icon icon-smd ${checked? "active": ""}`}/>
        </span>
    )
}