import React from 'react';

import ErrorBoundary from '../ErrorBoundary';
import { getDisplayName } from './helpers';

export function withSentry<P extends object> (Component: React.ComponentType<P>) {
    function WithSentry (props: P) {
        return (
            <ErrorBoundary>
                <Component {...props} />
            </ErrorBoundary>
        );
    }

    WithSentry.displayName = `WithSentry(${getDisplayName(Component)})`;
    return WithSentry;
}
