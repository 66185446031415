import React from "react";
import {useSelector} from "react-redux";
import {iFullStoreState} from "../../../../../../../shared/interfaces";
import {DashboardSize} from "../../../../../../../stores/reducers/dashboardInfo";
import "./totalCostItem.scss";
import {isMobile} from "../../../../../../../shared/helpers";

const TotalCostItem = ({deviceName, percent, totalCost}) => {
    // const isMobile = window['cordova'];
    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);
    return (
        <div className={`total-cost-item ${DashboardSize.OPEN === size && !isMobile? "total-cost-item-small" : ""}`}>
            <div className={`total-cost-item__name ${DashboardSize.OPEN === size && !isMobile ? "total-cost-item__name-small" : ""}`}>{deviceName}</div>
            <div className={`total-cost-item__percent ${DashboardSize.OPEN === size && !isMobile ? "total-cost-item__percent-small" : ""}`}>
                <div className="total-cost-item__percent-color"
                     style={{width: percent > 100 ? "100%" : `${percent}%`}}> </div>
            </div>
            <div className={`total-cost-item__price ${DashboardSize.OPEN === size && !isMobile ? "total-cost-item__price-small" : ""}`}>{`${percent}% ($${totalCost})`}</div>
        </div>
    );
};

export default TotalCostItem;
