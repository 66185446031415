import React from 'react';
import './showDetailsButton.scss';
import {useDispatch} from "react-redux";
import {GMapModalAC, GMapModalContent} from "../../../../../../../stores/reducers/gMapModal/AC";

const ShowDetailsButton = (props) => {
    const {value} = props;
    const taskData = props.cell?.row?.original;
    const dispatch = useDispatch();
    // console.log("task data btn", taskData);
    const openReportModal = () => {
        dispatch(GMapModalAC.showModal({
            contentType: GMapModalContent.REPORT_PAGE_TASK_DETAILS,
            params: {taskData}
        }));
        // console.log(data)
    };
    return (
        <button className="show-details-button" onClick={openReportModal}>{value}</button>
    );
}

export default ShowDetailsButton;
