import { CSVArray } from "../interfaces";

export function parseCSV(string: string): CSVArray {
  const rows = string.trim().split(/\r?\n/g);
  const fields = [];
  for(let i = 0; i < rows.length; i++) {
    const row = rows[i];
    // splits by "," that not in quotes
    // https://stackoverflow.com/questions/25663683/javascript-split-by-spaces-but-not-those-in-quotes
    const splitReg = /\s*,\s*(?=(?:(?:[^"]*"){2})*[^"]*$)/;
    const values = row.split(splitReg);
    const newValeus = values.map(value => value.replace(/"/g, '').split(/\s*,\s*/));
    fields.push(newValeus);
  }
  return fields;
}