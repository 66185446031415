import React from 'react';
import { useRedux } from '../../../states/redux-state';

import { getSearchMarkersSelector } from '../../../stores/reducers/gmap-selectors';
import { gstractLocation } from '../../../shared/helpers';
import SearchMarker from './SearchMarker';

const SearchMarkers = () => {
    const searchMarkers = useRedux(getSearchMarkersSelector);

    return <>{searchMarkers.map(({geometry, place_id, name: title, name: label}) =>
        <SearchMarker key={place_id} {...{id: place_id, title, label, color: '#000', location: gstractLocation(geometry.location)}} />,
    )}</>;
};

export default SearchMarkers;
