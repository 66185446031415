import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";

import BillingButton from "../../Other/BillingButton";
import { iReducersState } from "../../../../../stores/reducers";
import CustomBillingInput from "../../Other/CustomBillingInput";
import { billingLoading } from "../../../../../stores/reducers/billing";
import { getActivateDeviceId } from "../../BillingApi/billingApiRequests";
import "./ActivationDeviceForm.scss";
import "../../ModalPlans/PlansForm/PlansForm.scss";

  const activationDeviceInitialState = {
    serial: "",
    verifySerial: "",
  };
  const activationDeviceValidation = Yup.object({
    serial: Yup.string().required("Required"),
    verifySerial: Yup.string()
      .oneOf([Yup.ref("serial"), null], "Serial must match")
      .required("Required"),
  });

const ActivationDeviceForm = ({ setActivationDevice, setStep }) => {
  const { loading } = useSelector(
    (state: iReducersState) => state.billing
  );
  const dispatch = useDispatch();

  const takeActivateDeviceId = (serial) => {
    dispatch(billingLoading(true));

    getActivateDeviceId({ serial, setStep, setActivationDevice })
    .finally(() => dispatch(billingLoading(false)));
  };

  return (
      <Formik
        initialValues={activationDeviceInitialState}
        validationSchema={activationDeviceValidation}
        onSubmit={(values, actions) => {
          takeActivateDeviceId(values.serial);
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className="activation-form">
            <h5 className="activation-form__title">ACTIVATE ANOTHER DEVICE</h5>
            <div className="activation-form__inputs">
              <CustomBillingInput
                // label={"Serial Number"}
                name="serial"
                errors={errors.serial}
                touched={touched.serial}
                onChange={handleChange}
                value={values.serial}
                className="billing-form__inputs-input"
                placeholder="Serial Number"
              />
              <CustomBillingInput
                // label={"Serial Number(Verify)"}
                name="verifySerial"
                errors={errors.verifySerial}
                touched={touched.verifySerial}
                onChange={handleChange}
                value={values.verifySerial}
                className="billing-form__inputs-input"
                placeholder="Serial Number(Verify)"
              />
              <BillingButton loading={loading} text={"Submit"} />
            </div>
          </Form>
        )}
      </Formik>
  );
};

export default ActivationDeviceForm;
