import React, {useState} from 'react';
import LockIcon from '../../SVG-components/lock';
import classNames from 'classnames';
import {AuthAC} from '../../../stores/reducers/auth-reducer';
import {useDispatch} from 'react-redux';

const NewPassword = () => {
    const dispatch = useDispatch();

    const [active, updateActive] = useState(false);
    const [active2, updateActive2] = useState(false);
    const [error, setError] = useState('');
    const [newPassword, setNewPassword] =  useState('');
    const [confirmPassword, setConfirmPassword] =  useState('');
    const [loading, setLoading] = useState(false);

    const url = window.location.href;
    const paramsString = url?.split('?')[1];
    const paramsArray = paramsString?.split('&');
    const tokenParam = paramsArray?.find(param => param.includes('token'));
    const token = tokenParam?.split('=')[1];

    const onFocus = () => updateActive(true);
    const onBlur = () => updateActive(false);

    const onFocus1 = () => updateActive2(true);
    const onBlur2 = () => updateActive2(false);

    const handleChangeNewPassword = (event): void => {
        setNewPassword(event.target.value);
    };
    const handleChangeRepeatPassword = (event): void => {
        setConfirmPassword(event.target.value);
    };

    const btnClass = classNames({
        btn: true,
    });

    const onSubmit = () => {
        setLoading(true);

        if(newPassword.length < 6 || confirmPassword.length < 6) {
            setError('Your new password should have at least 6 symbols!');
            setLoading(false);

            return;
        }else if (newPassword !== confirmPassword) {
            setError('Your new password and confirmation password do not match!');
            setLoading(false);

            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/site/app-reset-password`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ResetPasswordForm: {token, password: newPassword, password2: confirmPassword}}),
        }).then(async(response) => {
            const data = await response.json();

            if (data.access_token) {
                dispatch(AuthAC.setFbToken(data.access_token));
            }
        }).catch(() => {
            setLoading(false);
        });
    };

    return (
        <div style={{marginTop: '20px'}}>
            <div className="InputBoxWrapper">
                <div className={classNames({'InputBox': true, active})}>
                    <LockIcon />
                    <input
                        id={'new_password'}
                        type="password"
                        placeholder={'New password'}
                        onChange={handleChangeNewPassword}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                    <label htmlFor={'new_password'}>{'New password'}</label>
                </div>
            </div>

            <div className="InputBoxWrapper" style={{marginTop: '10px'}}>
                <div className={classNames({'InputBox': true, active2})}>
                    <LockIcon />
                    <input
                        id={'repeat_new_password'}
                        type="password"
                        placeholder={'Confirmation password'}
                        onChange={handleChangeRepeatPassword}
                        onFocus={onFocus1}
                        onBlur={onBlur2}
                    />
                    <label htmlFor={'repeat_new_password'}>{'Confirmation password'}</label>
                </div>
            </div>
            {error && <div style={{fontSize: '14px', color: 'red'}}>
                {`ERROR! ${error}`}
            </div>}
            <div className="loginBtns">
                <button className={btnClass} type="submit" onClick={() => onSubmit()}>{loading ? 'Wait please...' : 'Submit'}</button>
            </div>
        </div>

    );
};

export default NewPassword;

