import { DispatchProp } from 'react-redux';
import {
  iPerson,
  iList,
  iTag,
  UserAuth,
  iItemsAllowed,
} from '../../../shared/interfaces';
import { DevicesDetailsContainer } from '../../../stores/reducers/devicesData';
import { default as C } from '../../../shared/constants';

export type IPropsFromStore = {
  person: iPerson;
  tags: iList<iTag>;
  devicesDetails: DevicesDetailsContainer;
  authUser: UserAuth;
};
export type IFullProps = IPropsFromStore & DispatchProp;

export interface StateInterface {
  tagFilter;
  deviceFilter;
  allowed?: iItemsAllowed;
  readOnlyAll: boolean;
  readOnlyAccess: object;
  canEditTagsAndFences: boolean;
}

export const itemStyle = (active, compressed = false) => {
  const style = {
    cursor: 'pointer',
    display: 'inline-block',
    margin: compressed ? 4 : 8,
    fontSize: compressed ? 10 : 'initial',
    color: active ? '#fff' : C.primaryColor,
  } as any;

  if (active) style.backgroundColor = C.primaryColor;

  return style;
};

export const helpMsg = `
Manage which devices a user can view. Adding a tag will allow this person to see all devices that have the associated tag.
To prevent a user from seeing a device make sure device overrides and appropriate tags are removed. The complete list
of seeable devices is at the very bottom.
`;
