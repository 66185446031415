import React from 'react';
import { createSelector } from 'reselect';
import {connect} from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { history } from '../../../stores/store';
import { BaseComponent } from "../../../shared/BaseComponent";
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import * as devicedb from '../../../shared/db/devices-db';
import { PersonBox } from '../../general/person-box';
import { iDeviceDetails, iFullStoreState, iList, iPerson, UserAuth } from '../../../shared/interfaces';
import SearchGridOld from '../../SearchGridOld/SearchGridOld';
import { idValArr, extractDevice } from '../../../shared/helpers';
import Dialog, { DialogConfigSetter } from '../../Dialog';
import {getSubUsers} from "../../../shared/db/people-db";
import {localStorage} from '../../../shared/storage';
import {UserCan} from "../../../shared/constants";

const mapStateToProps = (state: iFullStoreState, ownProps: IProps): IPropsFromStore => ({
    device: extractDevice(state.devicesData.devicesDetails, ownProps.match.params.deviceId),
    people: state.general.people,
    authUser: state.auth.user!,
});

type IProps = RouteComponentProps<{ deviceId: string; }>;

type IPropsFromStore = {
    device: iDeviceDetails;
    authUser: UserAuth;
    people: iList<iPerson>;
}
type IFullProps = IProps & IPropsFromStore;

type IState = {
    filterStr?: string;
    userToToggle?: string;
    people?: object;
    isSubAdmin: boolean;
}

class DeviceAssignmentPage extends BaseComponent<IFullProps, IState> {
    private dialog: DialogConfigSetter;
    state: IState = {
        filterStr: '',
        people: {},
        isSubAdmin: false,
    }
    setupDialog = (callBack: () => DialogConfigSetter): void => {
        this.dialog = callBack();
    }

    private setAssign = userToToggle => this.setState(s => ({
        userToToggle: s.userToToggle == userToToggle ? undefined : userToToggle
    }))

    private doAssign = async () => {
        const {device, people} = this.props;
        const {deviceId} = this.props.match.params;
        const {userToToggle = device.assignedTo?.personId} = this.state;
        const person = people[userToToggle];

        if (!!person.hasDevice) {
            const confirmed = await this.dialog?.({
                title: 'Currently Assigned',
                body: 'User currently assigned to a device. By continuing the user will be unassigned from that device.',
            });
            if (!confirmed) return;
        }

        if (!!person.hasDevice) {
            await devicedb.unAssignDevice(this.props.authUser)(person.hasDevice.deviceId, person.id)
        }

        await devicedb.assignDevice(this.props.authUser)(deviceId, person.id);
        history.goBack();
    }

    private doUnAssign = async _ => {
        const {deviceId} = this.props.match.params;
        await devicedb.unAssignDevice(this.props.authUser)(deviceId, this.props.device.assignedTo.personId);

        history.goBack();
    }

    async componentDidMount(){
        const isSubAdmin = localStorage.get('login-init-be-token')[UserCan.SUB_ADMIN];

        this.setState({isSubAdmin})

        if(isSubAdmin){
            const subUsers = await getSubUsers(localStorage.get('login-init-be-token').clientId, this.props.authUser.uid) || [];

            const selectedPeople = {};

            for (const id of subUsers) {
                const peopleId = id as string;
                if (this.props.people[peopleId]) {
                    selectedPeople[peopleId] = this.props.people[peopleId];
                }
            }

            if(Object.keys(selectedPeople).length) this.setState({people: selectedPeople});
        }
    }

    debugRender = () => {
        const {device} = this.props;
        const {filterStr} = this.state;

        const people = this.state.isSubAdmin ? this.state.people : this.props.people;

        if (!device) return null;

        const {userToToggle = device.assignedTo?.personId} = this.state;

        const filteredPeople = createSelector(
            ({ people }) => people as iList<iPerson>,
            ({ filterStr: fString }) => filterStr.toLowerCase() as string,
            ({ userToToggle }) => userToToggle as string,
            (people, fString, userToToggle) => idValArr(people)
                .filter(({val: person}) => !fString || person.displayName.toLowerCase().indexOf(fString) !== -1)
                .map(person => <PersonBox key={person.id} personId={person.id} person={person.val} active={userToToggle == person.id} />)

        )({people, userToToggle, filterStr});

        return <>
            <DashboardBlock>
                <ModalHeader title="Assign Device" />
                <SearchGridOld
                    list={filteredPeople}
                    filterStr={filterStr}
                    placeholder="Search People"
                    perPage={9}
                    keyClicked={this.setAssign}
                    filterChange={filterStr => this.setState({ filterStr })}
                />

                {/* assign to buttons */}
                <div style={{textAlign: 'center', marginTop: 10}}>
                    { !!device.assignedTo && userToToggle == device.assignedTo.personId
                        ? <button
                            className="btn btn-primary"
                            onClick={this.doUnAssign}>Tap out</button>
                        : <button
                            className="btn btn-primary"
                            disabled={!userToToggle}
                            onClick={this.doAssign}>Tap in</button>
                    }
                </div>
            </DashboardBlock>
            <Dialog setupConfig={this.setupDialog} />
        </>;
    }
}

export default connect(mapStateToProps)(DeviceAssignmentPage);