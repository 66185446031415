import React from 'react';
import styles from './AddAlarmIdModalForm.module.scss';

const AddPointTimeInput = ({ type, label, totalSeconds, onChange }) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const handleMinutesChange = (e) => {
    const minuteValue = e.target.value;
    const newTotalSeconds = (minuteValue ? parseInt(minuteValue) * 60 : 0) + seconds;
    onChange(type, newTotalSeconds);
  };

  const handleSecondsChange = (e) => {
    const secondValue = e.target.value;
    const newTotalSeconds = minutes * 60 + (secondValue ? parseInt(secondValue) : 0);
    onChange(type, newTotalSeconds);
  };

  return (
    <div className={styles.inputBlock}>
      <label className={styles.labelTitle}>{label}:</label>
      <input
        type="number"
        value={minutes || ''}
        onChange={handleMinutesChange}
        placeholder="Minutes"
        className={`${styles.styledInput} ${styles.seconds}`}
        min="0"
        max="30"
      />
      <input
        type="number"
        value={seconds || ''}
        onChange={handleSecondsChange}
        placeholder="Seconds"
        className={`${styles.styledInput} ${styles.seconds}`}
        min="0"
        max="59"
      />
    </div>
  );
};

export default AddPointTimeInput;
