import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Actions as GmapActions, Actions as MapActions} from '../../../stores/reducers/gmap-reducers';
import {iFullStoreState} from '../../../shared/interfaces';
import MapControl from '../MapControl';
import ToggleButton from '../../../BemComponents/ToggleButton';
import WarningAmber from '../../SVG-components/warning_amber';
import FilterCenter from '../../SVG-components/filter_center_focus';
import Settings from '../../SVG-components/map-control-settings';
import Timeline from '../../menus/Timeline2';
import GpsFixed from '../../SVG-components/gps-fixed';
import TrafficLight from '../../SVG-components/traffic_light';
import GpsPlace from '../../SVG-components/gps-place';
import GpsSatelite from '../../SVG-components/map_mobile_icon';
import CloseSettings from '../../SVG-components/map-control-close';
import './styles.scss';
import {isMobile} from '../../../shared/helpers';
import {Route, useParams} from 'react-router-dom';
import {Actions as GeneralActions} from '../../../stores/reducers/general-reducers';
import FencesContainer from "../../fence/FencesContainer/FencesContainer";
import {useRedux} from "../../../states/redux-state";
import Tooltip from '@material-ui/core/Tooltip';
import {IS_MOBILE_APP} from "../../../shared/constants";

const MapSettingsButtons = () => {
    const dispatch = useDispatch();

    const showPOI = useSelector<iFullStoreState, boolean>((s) => s.gmap.showPOI);
    const showDots = useSelector<iFullStoreState, boolean>((s) => s.gmap.showDots);
    const isStreetView = useRedux((s) => !!s.gmap.streetViewLocation);
    const [watchId, setWatchId] = useState(null);
    const cordova = window['cordova'];
    const device = window["device"]?.platform;

    const toggleAlerts = () => dispatch(MapActions.TOGGLE_POI());
    const toggleExtraPoints = () => dispatch(MapActions.TOGGLE_DOTS());

    const [showSettings, setShowingSettings] = useState(false);

    const {deviceId} = useParams<{ deviceId: string }>();

    const recenter = () => {
        dispatch(GmapActions.RECENTER_MAP_TO_DEVICE(deviceId));
    };

    const toggleWatchUserLocation = () => {
        if (!navigator?.geolocation) return;
        if (!watchId) {
            let watcherId = navigator.geolocation.watchPosition
            (watchSuccess, watchError, { timeout: 8000, maximumAge: 1000 * 60 * 60, enableHighAccuracy: true });
            setWatchId(watcherId);
        } else {
            navigator.geolocation.clearWatch(watchId);
            setWatchId(null);
            dispatch(GmapActions.UPDATE_USER_LOCATION(null));
        }
    };

    const watchSuccess = (position) => {
        const { coords: { latitude: lat, longitude: lng } } = position;
        dispatch(GmapActions.UPDATE_USER_LOCATION({ lat, lng }));
    };

    const watchError = (error) => {
        console.log('code: ' + error.code + '\n' +
            'message: ' + error.message + '\n');
    };

    const trailToggle = () => {
        // setShowTrail(!showTrail);
        dispatch(GeneralActions.TOGGLE_LIVE_TRAIL());
    };
    const pauseToggle = () => {
        // setPause(!pause);
        dispatch(GmapActions.TOGGLE_RECENTER_MAP());
    };

    const askLocationPermissionAndroid = () => {
        const permissions = cordova?.plugins?.permissions;
        permissions.requestPermission(permissions.ACCESS_COARSE_LOCATION, askUserToEnableGeoModule, displayPermissionError);
    }

    const askUserToEnableGeoModule = (status) => {
        if (status.hasPermission) {
            cordova.dialogGPS("Your GPS is Disabled, please enable it so the app could display your location correctly.");
            toggleWatchUserLocation();
        }
    };

    const displayPermissionError = (err) => {
        console.log(err);
    };

    return (isMobile ? (
        <MapControl
            position={google.maps.ControlPosition.TOP_LEFT}
            className='AlertsGroupButtonsMobile'
        >
            <Timeline/>
            <Tooltip title={`${showPOI ? 'Hide POI' : 'Show POI' }`}>
                <ToggleButton name={'Show/hide POI'} icon={WarningAmber} pressed={showPOI} onClick={toggleAlerts}/>
            </Tooltip>
            <Tooltip title={`${showDots ? 'Hide dots' : 'Show dots' }`}>
                <ToggleButton name={'Show/hide Dots'} icon={FilterCenter} pressed={showDots} onClick={toggleExtraPoints}/>
            </Tooltip>
            <div>
                <Tooltip title={`${showSettings ? 'Close settings' : 'Settings'}`}>
                    <ToggleButton
                        name={'Settings'}
                        icon={showSettings ? CloseSettings : Settings}
                        onClick={() => setShowingSettings(!showSettings)}
                        pressed={showSettings}
                    />
                </Tooltip>
                {showSettings && <div className= "SettingsBlock">
                    <Tooltip title='Street view'>
                        <div className= "SettingsBlockButton">
                            <GpsPlace onClick={() => dispatch(GmapActions.TOGGLE_STREET_VIEW())}/>
                            <span className="setting-title" >Street view</span>
                        </div>
                    </Tooltip>
                    <div className='BlockLine'/>
                    <Tooltip title='Recenter map'>
                        <div className= "SettingsBlockButton">
                            <GpsFixed onClick={() => recenter()}/>
                            <span className="setting-title" >Recenter map</span>
                        </div>
                    </Tooltip>
                    <div className='BlockLine'/>
                    <Tooltip title='Satelite view'>
                        <div className= "SettingsBlockButton">
                            <GpsSatelite onClick={() => dispatch(GmapActions.TOGGLE_MAP_TYPE())}/>
                            <span className="setting-title" >Satelite view</span>
                        </div>
                    </Tooltip>
                    <div className='BlockLine'/>
                    <Tooltip title='Traffic Layer'>
                        <div className= "SettingsBlockButton">
                            <TrafficLight onClick={() => dispatch(GmapActions.TOGGLE_TRAFFIC_LAYER())}/>
                            <span className="setting-title" >Traffic Layer</span>
                        </div>
                    </Tooltip>
                    <div className='BlockLine'/>
                    {!isStreetView && <Tooltip title='Fences'>
                        <div className="SettingsBlockButton">
                            {
                                !isStreetView &&
                                <Route
                                    path={['/tags', '/:itemType/:itemId'/* , '/reports/:reportType(static|travel)'*/]}>
                                    <FencesContainer style={{zIndex: 3, margin: '10px 20px 50px'}}/>
                                </Route>
                            }
                            <span className="setting-title" >Fences</span>
                        </div>
                    </Tooltip>
                    }
                    {IS_MOBILE_APP && (
                      <>
                          <div className='BlockLine'/>
                          <Tooltip title='Show your position'>
                              <div className= "SettingsBlockButton">
                                  <GpsPlace onClick={() => device === "Android" ? askLocationPermissionAndroid() : toggleWatchUserLocation()}/>
                                  <span className="setting-title" >{watchId ? 'Hide' : 'Show'} your position</span>
                              </div>
                          </Tooltip>
                      </>
                    )}
                </div>}
            </div>

        </MapControl>
    ) : (
        <MapControl
            position={google.maps.ControlPosition.BOTTOM_CENTER}
            className="AlertsGroupButtons"
        >
            <ToggleButton icon={WarningAmber} pressed={showPOI} onClick={toggleAlerts}>Alert icons</ToggleButton>
            <ToggleButton icon={FilterCenter} pressed={showDots} onClick={toggleExtraPoints}>Extra points</ToggleButton>
        </MapControl>
    ));
};

export default MapSettingsButtons;
