import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as DeleteOutlineSvg} from "../../../../../../assets/svg/delete_outline.svg";
import {iFullStoreState} from "../../../../../../shared/interfaces";
import {DashboardSize} from "../../../../../../stores/reducers/dashboardInfo";
import MaintenanceTable from "../../MaintenanceTable";
import "./taskTable.scss";
import ShowDetailsButton from "./ShowDetailsButton";
import moment from "moment";
import {ReportAC} from "../../../../../../stores/reducers/report-reducers";
import {isMobile} from "../../../../../../shared/helpers";

const DeleteBtn = (props) => {
    const { taskKey, deviceKey } = props.row.original;
    const dispatch = useDispatch();
    const handleBtnClick = () => {
        dispatch(ReportAC.deleteCompletedMaintenanceTask({deviceKey, taskKey}));
    }
    return (
        <DeleteOutlineSvg onClick={handleBtnClick} className="tasks-table-body-row-itm__delete_button"/>
    )
}

const TaskTable = ({tasksList}) => {
    // const isMobile = window['cordova'];
    const dispatch = useDispatch();
    const tableData = tasksList.map(task => {
        // console.log("task", task);
        const {deviceKey, taskKey , maintenanceKey = "", extraTask, documents} = task;
        const {"date": startDate, cost, labourHours, odometer} = task.taskCompleteInfo || {};
        const {taskName, deviceName, hoursNeeded, milesNeeded, "date": endDate} = task.taskInfo || {};

        return {
            taskName,
            taskKey,
            deviceName,
            hoursNeeded,
            milesNeeded,
            startDate,
            endDate,
            cost,
            labourHours,
            odometer,
            deviceKey ,
            maintenanceKey,
            extraTask,
            documents,
        };
    });

    // console.log(formattedData);

    // const [tableData, updateTableData] = useState(formattedData);

    const removeItem = ({deviceKey, taskKey}) => {
        dispatch(ReportAC.deleteCompletedMaintenanceTask({deviceKey, taskKey}));
    };

    const convertTo2Fixed = (cost) => `$${Number(cost).toFixed(2)}`;

    const columns = useMemo(
        () => [
            // {
            //     Header: "Id",
            //     accessor: "id",
            // },
            {
                Header: "Task",
                accessor: "taskName",
                Cell: ShowDetailsButton,
            },
            {
                Header: "Labor hours",
                accessor: "labourHours",
            },
            {
                Header: "Cost",
                accessor: (m) => {
                    return convertTo2Fixed(m.cost);
                },
            },
            {
                Header: "Date",
                accessor: (m) => {
                    return moment(m.endDate).format("MM/DD/YYYY");
                },
            },
            {
                id: "delete",
                Cell: DeleteBtn
            }
        ],
        [tasksList]
    );

    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);
    return (
        <div className={`tasks-table-wrapper ${DashboardSize.OPEN === size && !isMobile ? "tasks-table-wrapper-small" : ""}`}>
                <MaintenanceTable className="tasks-table" columns={columns} data={tableData} hasDeleteButton={true} removeCallBack={removeItem} hasPagination={true} paginationClass={"tasks-table__pagination"} pageSize={4} hasScroll/>
        </div>
    );
}

export default TaskTable;
