import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface IChevronProps {
  direction: "right" | "top" | "down" | "left";
  isActive: boolean;
}

const Chevron: React.FC<IChevronProps> = ({ direction, isActive }) => {
  return (
    <span
      className={classNames(styles.chevron, {
        [styles.isActive]: isActive,
        [styles.right]: direction === "right",
        [styles.down]: direction === "down",
        [styles.left]: direction === "left",
        [styles.top]: direction === "top",
      })}
    />
  );
};

export default Chevron;
