import React, { useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend, Brush } from "recharts";
import "./avgScoreVSCost.scss";
import {useSelector} from "react-redux";
import {iFullStoreState} from "../../../../../../shared/interfaces";
import {DashboardSize} from "../../../../../../stores/reducers/dashboardInfo";
import {/*selectDevicePing,*/ selectDevicesTrips, selectReportsMaintenanceDevices} from "../../../../../../stores/reducers/maintenance/selectors";
import { countAvgSafetyPercentsFor, /*countSafetyIncidentsFor*/ } from "../../../../../../shared/helpers";
import {isMobile} from "../../../../../../shared/helpers";

import CustomizedCursor from './CustomizedCursor';
import CustomTick from './CustomTick';
import YCustomTick from './YCustomTick';
import { renderColorfulLegendText, renderTooltip } from './AvgScoreVSCost.helpers'

const AvgScoreVSCost = () => {
    const [customTickInfo, setCustomTickInfo] = useState({
        isActive: false,
        top: 0,
        left: 0,
        content: null
    });
    // const isMobile = window['cordova'];
    const reduxDevicesData = useSelector(selectReportsMaintenanceDevices);
    const deviceTrip = useSelector(selectDevicesTrips);
    // const devicePing = useSelector(selectDevicePing);
    const getSumFrom = (array, parentKey, key) => {
        return array.reduce((sum, cr) => {
            const value = +cr?.[parentKey]?.[key];
            return value ? sum + value : sum;
        }, 0);
    };

    const data = reduxDevicesData.map((device, i) => {
        const {deviceName, tasksList, deviceKey} = device;
        const totalCost = getSumFrom(tasksList, 'taskCompleteInfo', 'cost');
        // const safetyIncidents = countSafetyIncidentsFor(devicePing, deviceKey);
        const avgScore = countAvgSafetyPercentsFor(deviceTrip, deviceKey).toFixed(2);
        return {
            deviceName,
            avgScore,
            totalCost,
            index: i + 1
        };
    });

    // console.log(devicesData)

    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);

    // const [devicesData, updateData] = useState(data);
    const devicesData = [...data];
    const mobileCount = devicesData.length > 5 ? 5 : devicesData.length - 1;
    const smallSizeCount = devicesData.length > 4 ? 4 : devicesData.length - 1;
    const fullSizeCount = devicesData.length > 12 ? 12 : devicesData.length - 1;

    return (
        <div className={`avg-vs-cost-wrapper ${DashboardSize.OPEN === size ? "avg-vs-cost-wrapper-small" : ""}`} >
            <ResponsiveContainer width="100%" height={300}>
                <LineChart width={800} height={300} data={devicesData}>
                    <Legend layout="horizontal" verticalAlign="top" align="center" iconType="line"
                            wrapperStyle={{top: "-5px"}} formatter={renderColorfulLegendText} />
                    <YAxis yAxisId="totalCost" tick={
                        <CustomTick setCustomTickInfo={setCustomTickInfo} isActive={customTickInfo.isActive} />
                    } />
                    <YAxis yAxisId="avgScore" orientation="right"/>
                    <XAxis dataKey="index" interval={0}/>
                    <CartesianGrid stroke="#ECECEC"/>
                    <Tooltip cursor={
                        <CustomizedCursor width={13} stroke="#878889" fillColor="#F4F4F4" />
                    } content={renderTooltip} />
                    <Line name="Avg Score" yAxisId="avgScore" dataKey="avgScore" stroke="#FF7C02" strokeWidth={2}/>
                    <Line name="Cost" yAxisId="totalCost" dataKey="totalCost" stroke="#111111" strokeWidth={2}/>
                    <Brush y={282} stroke="#999" fill="#f8f8f8" travellerWidth={0} height={15} endIndex={isMobile ? mobileCount : DashboardSize.OPEN === size ? smallSizeCount : fullSizeCount}/>
                </LineChart>
            </ResponsiveContainer>
            <YCustomTick isActive={customTickInfo.isActive} left={customTickInfo.left} top={customTickInfo.top} content={customTickInfo.content} />
        </div>
    )

}

export default AvgScoreVSCost;