import React, { ChangeEvent } from "react";
import SearchInput from "../../../SearchInput";

const SearchFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id, placeholder },
  }) => {
    return (
        <SearchInput
            type="text"
            value={filterValue || ""}
            placeholder={placeholder}
            size={placeholder.length}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFilter(e.target.value || undefined)
            }}
        />
    )
}

export default SearchFilter;