import { GMapModalContent, ACTIONS } from "./AC"

export type iState = iGMapModal;

export interface iGMapModal {
    show: boolean,
    contentType: GMapModalContent,
    params: any
}

const initialState = {
    show: false,
    contentType: GMapModalContent.EMPTY,
    params: {}
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SHOW_GOOGLE_MAP_MODAL:
            return { ...state, show: true, contentType: action.payload.contentType, params: action.payload.params }
        case ACTIONS.HIDE_GOOGLE_MAP_MODAL:
            return { ...state, show: false }
        case ACTIONS.SET_PARAMS:
            return { ...state, params: action.payload.params}
        default:
            return state;
    }
}