
import {
    iDevicePing,
    iTrip,
    ReportContainer,
    ItemType,
} from '../../../../shared/interfaces';
import {
    getDiff,
} from '../../../../shared/helpers';
import moment from 'moment';
import {sum, keys} from 'ramda';

export const getTimeFormated = ( time: number) => {
    const num: number = Number((time).toFixed(1));
    return num === 0 ? 0 : `${num} h`
}

export const countDriveTime = (displayRecords: ReportContainer | (iDevicePing | iTrip)[]) => {
    return Object.keys(displayRecords).reduce((acc, record) => {
        return acc + getDiff(moment(displayRecords[record].endDate), moment(displayRecords[record].startDate))
    }, 0);
}

export const countTraveledDistance = (displayRecords: ReportContainer | (iDevicePing | iTrip)[]) => {
    return Object.keys(displayRecords).reduce((acc, record) => {
        const drivingTime = getDiff(moment(displayRecords[record].endDate), moment(displayRecords[record].startDate));
        const miles = displayRecords[record].miles || displayRecords[record].averageSpeed * drivingTime;

        return acc + miles;
    }, 0);
}

export const countStoppedTime = (displayRecords: ReportContainer | (iDevicePing | iTrip)[]) => {
    return Object.keys(displayRecords).reduce((acc, record) => {
        const stoppedTime = displayRecords[record].stopMinutes
        return stoppedTime > 0 ? acc + stoppedTime : acc
    }, 0);
}

export const countAverageFuel = (displayRecords: Array<iDevicePing>): number => {
    return sum(displayRecords.map(record => record.fuel ?? 0)) / displayRecords.length || 0;
}

export const countAverageSpeed = (displayRecords: Array<iDevicePing>): number => {
    return sum(displayRecords.map(record => record.speed ?? 0)) / displayRecords.length || 0;
}

export const getTitles = (records, deviceOrPerson, devicesDetails, people) => {
    let titles = { };
    keys(records).forEach(userId => {
        const displayName =
            deviceOrPerson === ItemType.device
                ? devicesDetails.getIn([userId, 'name']) || 'Unknown Device'
                : (people[userId] || {}).displayName || 'Not Assigned';
        titles[userId] = displayName;
        // setTitles(prevState => ({ ...prevState, [userId]: displayName }));
    });
    return titles;
};