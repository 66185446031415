import React, { CSSProperties as css } from 'react';
import { useState } from "react";
import classNames from "classnames";
import { Link } from 'react-router-dom';
import { faSearch, faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid';

import { Fa } from '../../elements/fa';
import {iProps} from "./types";
import { Button, ButtonSize, ButtonStyles } from '../../Button';
import "./index.scss";
import { useDispatch } from 'react-redux';

import {
  Actions as ReportActions,
} from '../../../stores/reducers/report-reducers';

import {localStorage} from '../../../shared/storage';

const ReportTypeTab = ({ element, activeReportTypeTab, onClick }) => {
  const reportTypeTabElement = element.split('-').join(" ");
  return (
    <a onClick={onClick} className={activeReportTypeTab === element ? "activeReportTypeTab" : "search-item"}>{reportTypeTabElement}</a>
  );
}

const SearchGrid = ({
  perPage,
  list,
  RedirectToStatic,
  selectedCount,
  countOfSelectedFilters,
  currentFilterObject,
  filterType,
  filterChange,
  keyClicked,
  onReset,
  filterStr,
  placeholder,
  style = {},
  RedirectToTabs
}: iProps) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState({ currentPage: 1 });

  const [activeReportTypeTab, setActiveReportTypeTab] = useState(null);

  const handleTabClick = (element) => {
    dispatch(ReportActions.RESET_ALL_TYPE_FILTERS(filterType))
    setActiveReportTypeTab(element);

    dispatch(ReportActions.SET_TYPE_FROM_REDUX(element));
    keyClicked(element);
  };

  const { currentPage } = page;


  const displayItems = () => {
    const someFiltersMoreThanTwo = Object.entries(countOfSelectedFilters).some(
      ([key, value]) => value >= 2
    );
   
    const checkingAccessForMultipleFilters = ({ item }) => {
      const forAlerts =
        filterType == "alerts" &&
        !!currentFilterObject["alerts"] &&
        Object.keys(currentFilterObject["alerts"]).length == 1 &&
        Object.keys(currentFilterObject["alerts"])[0] !== item;


      const condition = forAlerts || (someFiltersMoreThanTwo && selectedCount == 1 && Object.keys(currentFilterObject[filterType])[0] !== item);
      const disableOtherFilter = condition ? true : false;
      return disableOtherFilter;
    };

    const loginToken = localStorage.get('login-init-be-token');

    return list
      .filter(
        (i, idx) =>
          idx >= perPage * (currentPage - 1) && idx < perPage * (currentPage - 1) + perPage
      )
      .map((element) => {
        const disableFilters = checkingAccessForMultipleFilters({
          item: element.key,
        });

        if(element.key === 'permissions' && !loginToken['do-anything']) return;

        return RedirectToStatic ? (
          <Link
            key={element.key}
            to={RedirectToStatic && "static"}
            className={classNames({
              "search-item": true,
              disable: disableFilters ? true : false,
            })}
            onClick={(_) => keyClicked(element.key.toString())}
          >
            {element}
          </Link>
        ) : RedirectToTabs ? (
          <ReportTypeTab
            key={element.key}
            element={element.key.toString()}
            activeReportTypeTab={activeReportTypeTab}
            onClick={() => handleTabClick(element.key.toString())}
          />
        ) : (
          <div
            key={element.key}
            className={classNames({
              "search-item": true,
              disable: disableFilters ? true : false,
            })}
            onClick={(_) => keyClicked(element.key.toString())}
          >
            {element}
          </div>
        );
      });
  };

  const pageCount = () => {
      return Math.ceil(list.length / perPage );
  }

  const countOfPages = pageCount();

  const searchFilters = ({ target }) => {
    filterChange(target.value);
  } 

  const changePage = (amount) => {
      setPage((state) => {
        let newPage = state.currentPage + amount;
        
        if (newPage < 1) newPage = 1;
        if (newPage > countOfPages) newPage = countOfPages;

        return { currentPage: newPage };
      });
  }

  return (
    <div className="search-grid" style={style}>
      <div className="search-grid-search">
        <div className="search">
          <input
            type="text"
            placeholder={placeholder}
            value={filterStr}
            onChange={searchFilters}
          />
        </div>
        <div className="reset" onClick={onReset}>
          <Button styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD}>
            Reset to all
          </Button>
        </div>
      </div>
      <div className="search-items">{displayItems()}</div>
      <Pager page={currentPage} pages={countOfPages} change={changePage} />
    </div>
  );
};

export default SearchGrid;


const Pager = ({ page, pages, change }) => {
  if (pages < 2) return null;

  let pageNums = Array(pages).fill(1).map((_, p) => p + 1);
  
  return <div className="pager-container">
      <Fa icon={faChevronLeft} onClick={_ => change(-1)} 
      containerClassName={classNames('chev')}
      className={classNames({'disabled': page == 1 ? true : false})}
      />
      <div style={{display: 'flex', alignItems: 'center'}}>
          {pageNums.map(p => (
              <span 
                  key={p}
                  className={classNames('pageDot', {'pageDotCur': page == p ? true : false})}
                  onClick={_ => change(p - page)}
              />
          ))}
      </div>
      <Fa icon={faChevronRight} onClick={_ => change(1)} 
      containerClassName={classNames('chev')} 
      className={classNames({'disabled': page == pages ? true : false})}
      />
  </div>
}