import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from 'react-select';

import classNames from 'classnames';
import InputBox from '../../elements/InputBox';
import EmailIcon from '../../SVG-components/email_logo';
import Person from '../../SVG-components/person';
import LockIcon from '../../SVG-components/lock';
import Phone from '../../SVG-components/phone';
import House from '../../SVG-components/house';
import City from '../../SVG-components/city';
import ZipCode from '../../SVG-components/zip_code';
import BackIcon from '../../SVG-components/left_chevron';


const SignUpForm = ({goBack, setResponse}): JSX.Element => {

    const [loading, setLoading] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [selectCountry, setSelectCountry] = useState({});
    const [selectState, setSelectState] = useState({});
    const [optionsCountry, setOptionsCountry] = useState([]);
    const [optionsState, setOptionsState] = useState([]);
    const [valueCountry, setValueCountry] = useState('');
    const [valueState, setValueState] = useState('');

    const customSelectStyles = {
        option: (provided, state): object => ({
            ...provided,
            opacity: state.isSelected ? 1 : 0.5,
            fontSize: '14px',
            fontWeight: 600,
            color: '#000000',
            padding: 7,
        }),
        container: (provided): object => ({
            ...provided,
            width: '100%',
            color: 'black',
        }),
        control: (provided, state): object => ({
            ...provided,
            width: '100%',
            height: '49px',
            display: 'flex',
            borderColor: '#F7F7F7',
            backgroundColor: state.isDisabled ? '#DADADA' : '#f4f4f4',
            border: '1px solid #DADADA',
            borderRadius: 2,
            color: '#000000',
        }),
        placeholder: (provided): object => ({
            ...provided,
            color: '#000000'
        }),
        indicatorSeparator: (): object => ({
            display: 'none'
        }),
        dropdownIndicator: (provided): object => ({
            ...provided,
            color: '#000000'
        }),
        valueContainer: (provided): object => ({
            ...provided,
            fontSize: '14px',
            fontWeight: 600,
            color: 'black',
            opacity: 0.5,
        }),
        singleValue: (provided): object => ({
            ...provided,
            color: '#000000'
        }),
    };

    const handleChangeCountry = (event): void => {
        setValueCountry(event.value);
        setOptionsState([]);
        selectState.hasOwnProperty(event.label) && Object.keys(selectState[event.label]).length !== 0 && Object.keys(selectState[event.label]).map(item =>
            setOptionsState(prevState => ([...prevState, {value: item, label: selectState[event.label][item]}]))
        )
        setValueState('');
    };
    const handleChangeState = (event): void => {
        setValueState(event.value);
    };

    const schema = yup.object().shape({
        password: yup.string().required('Password is required').matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 characters, one uppercase, one lowercase, one number and one special case character'
        ),
        password2: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Password confirm is required'),
        first_name: yup.string().required('First name is Required'),
        last_name: yup.string().required('Last name is Required'),
        company: yup.string(),
        email: yup.string().email().required('Email is Required'),
        phone: yup.number().typeError('Phone is Required').required('Phone is Required'),
        phoneSecondary: yup.string(),
        billing_address_line1: yup.string().required('Address is Required'),
        billing_address_line2: yup.string(),
        billing_address_city: yup.string().required('City is Required'),
        billing_address_zip: yup.string().required('Zip is Required'),
    });

    const { register, handleSubmit, setError, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const getCountry = () => {
        setResponse(null);
        fetch(`${process.env.REACT_APP_API_URL}/site/app-get-country`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(async(response) => {
                const data = await response.json();
                const country = {};
                const states = {};
                Object.keys(data.countries).forEach(item => {
                    country[item] = data.countries[item];
                });
                Object.keys(data.state).forEach(item => {
                    if (Object.keys(data.state[item]).length !== 0) {
                        states[item] = data.state[item];
                    }
                });
                setSelectCountry(country);
                setSelectState(states);
            })
            .catch((res) => console.log('ERROR:',res));
    };

    useEffect(getCountry, []);

    useEffect(() => {
        selectCountry && Object.keys(selectCountry).length !== 0 && Object.keys(selectCountry).map(key =>
            setOptionsCountry(prevState => ( [...prevState, { value: key, label: selectCountry[key] }]))
        )
    },[selectCountry]);

    const onSubmit = (data) => {
        setLoading(true);
        setResponse(null);
        fetch(`${process.env.REACT_APP_API_URL}/site/app-signup`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({SignupForm: {
                username: data.email,
                password: data.password,
                password2: data.password2,
                first_name: data.first_name,
                last_name: data.last_name,
                company: data.company,
                email: data.email,
                phone: data.phone,
                // phone_secondary: data.phoneSecondary,
                billing_address_line1: data.billing_address_line1,
                billing_address_line2: data.billing_address_line2,
                billing_address_city: data.billing_address_city,
                billing_address_state: valueState,
                billing_address_zip: data.billing_address_zip,
                billing_address_country: valueCountry
                }}),
        })
            .then(async(response) => {
                const data = await response.json();
                if (data.massage) {
                    setResponse(data.massage);
                    setLoading(false);
                    setShowButton(false);
                } else {
                    (['username', 'password', 'password2', 'first_name', 'last_name', 'company', 'phone', 'email', 'phoneSecondary', 'billing_address_line1', 'billing_address_line2', 'billing_address_city', 'billing_address_state', 'billing_address_zip', 'billing_address_country'] as Array<
                        'username' | 'password' | 'password2' | 'first_name' | 'last_name' | 'company' | 'phone' | 'email' | 'phoneSecondary' | 'billing_address_line1' | 'billing_address_line2' | 'billing_address_city' | 'billing_address_state' | 'billing_address_zip' | 'billing_address_country'
                        >).forEach((field) => {
                        if (data.errors?.[field]) {
                            setError(field, {
                                type: 'manual',
                                message: data.errors?.[field],
                            });
                        }
                    })
                    setLoading(false);
                }
            })
            .catch((res) => console.log('ERROR:',res));

    };

    const btnClass = classNames({
        btn: true,
    });

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div className='singUpForm'>
            <InputBox
                label="Username/Email"
                name="email"
                register={register}
                error={!!errors.email || !!errors.username}
                helperText={(errors.email ? (errors.email?.message || 'Validation error') : '') || (errors.username ? (errors.username?.message || 'Validation error') : '')}
                icon={EmailIcon}
            />
            <div className={'doubleForm'}>
                <InputBox
                    label="Password"
                    name="password"
                    type="password"
                    register={register}
                    error={!!errors.password}
                    helperText={errors.password ? (errors.password?.message || 'Validation error') : ''}
                    icon={LockIcon}
                />
                <InputBox
                    label="Password repeat"
                    name="password2"
                    type="password"
                    register={register}
                    error={!!errors.password2}
                    helperText={errors.password2 ? (errors.password2?.message || 'Validation error') : ''}
                    icon={LockIcon}
                />
            </div>
            <div className={'doubleForm'}>
                <InputBox
                    label="First name"
                    name="first_name"
                    register={register}
                    error={!!errors.first_name}
                    helperText={errors.first_name ? (errors.first_name?.message || 'Validation error') : ''}
                    icon={Person}
                />
                <InputBox
                    label="Last name"
                    name="last_name"
                    register={register}
                    error={!!errors.last_name}
                    helperText={errors.last_name ? (errors.last_name?.message || 'Validation error') : ''}
                    icon={Person}
                />
            </div>
            <InputBox
                label="Company"
                name="company"
                register={register}
                error={!!errors.company}
                helperText={errors.company ? (errors.company?.message || 'Validation error') : ''}
                icon={Person}
            />
            <InputBox
                label="Address line 1"
                name="billing_address_line1"
                register={register}
                error={!!errors.billing_address_line1}
                helperText={errors.billing_address_line1 ? (errors.billing_address_line1?.message || 'Validation error') : ''}
                icon={House}
            />
            <InputBox
                label="Address line 2"
                name="billing_address_line2"
                register={register}
                error={!!errors.billing_address_line2}
                helperText={errors.billing_address_line2 ? (errors.billing_address_line2?.message || 'Validation error') : ''}
                icon={House}
            />
            <div className={'cityForm'}>
                <InputBox
                    label="City"
                    name="billing_address_city"
                    register={register}
                    error={!!errors.billing_address_city}
                    helperText={errors.billing_address_city ? (errors.billing_address_city?.message || 'Validation error') : ''}
                    icon={City}
                />
                <div className={'selectBoxState'}>
                    {!!errors.billing_address_state ?
                        <div style={{border: '1px solid #FF0000', borderRadius: 2}}>
                            <Select
                                placeholder={'State'}
                                isDisabled={!optionsState.length}
                                styles={customSelectStyles}
                                onChange={handleChangeState}
                                options={optionsState}
                            />
                        </div>
                        :
                        <Select
                            placeholder={'State'}
                            isDisabled={!optionsState.length}
                            styles={customSelectStyles}
                            onChange={handleChangeState}
                            options={optionsState}
                        />
                    }
                    <div style={{minHeight: '30px', fontSize: '10px', color: '#CB1313'}} className={classNames({HelperText: true})}>{errors.billing_address_state ? (errors.billing_address_state?.message || 'Validation error') : ''}</div>
                </div>
                <InputBox
                    label="Postal code"
                    name="billing_address_zip"
                    register={register}
                    error={!!errors.billing_address_zip}
                    helperText={errors.billing_address_zip ? (errors.billing_address_zip?.message || 'Validation error') : ''}
                    icon={ZipCode}
                />
            </div>
            <div className={'selectBoxCountry'}>
                {!!errors.billing_address_country ?
                    <div style={{border: '1px solid #FF0000', borderRadius: 2}}>
                        <Select
                            placeholder={'Country'}
                            isDisabled={!optionsCountry.length}
                            styles={customSelectStyles}
                            onChange={handleChangeCountry}
                            options={optionsCountry}
                        />
                    </div>
                    :
                    <Select
                        placeholder={'Country'}
                        isDisabled={!optionsCountry.length}
                        styles={customSelectStyles}
                        onChange={handleChangeCountry}
                        options={optionsCountry}
                    />
                }
                <div style={{minHeight: '30px', fontSize: '10px', color: '#CB1313'}} className={classNames({HelperText: true})}>{errors.billing_address_country ? (errors.billing_address_country?.message || 'Validation error') : ''}</div>
            </div>
            <div  className={'phone'}>
                <InputBox
                    label="Phone"
                    name="phone"
                    register={register}
                    error={!!errors.phone}
                    helperText={errors.phone ? (errors.phone?.message || 'Validation error') : ''}
                    icon={Phone}
                    />
            </div>
        </div>
        <div
            style={{fontSize: '11px', cursor: 'pointer', display: 'flex', alignItems: 'center'}}
            onClick={() => goBack()}
        >
            <BackIcon/>
            Go back
        </div>

        {showButton && <div className="loginBtns">
            <button className={btnClass} type="submit">{loading ? 'Wait please...' : 'Submit'}</button>
        </div>}
    </form>;
};

export default SignUpForm;