import { object, string, number, date } from 'yup';
import { createBiggerThanMsg, createCorrectMsg, createNumberMsg, createRequiredMsg, createShouldBeCorrectMsg } from '../../../../../shared/error-messages-constants';
import { validateForm, validateSingleField } from '../../validate-helpers';

/*const errorsTexts = {
    required: "This field is required",
    number: "Must be a number",
    integer: "Must be an integer",
    positive: "Must be a positive number or 0",
    date: "Choose correct date",
}*/

const EditMaintenanceTaskValidation = object().shape({
    name: string()
        .required({name: createRequiredMsg("Name")}),
    miles_needed: number()
        .typeError({miles_needed: createNumberMsg("Miles needed")})
        .required({miles_needed: createRequiredMsg("Miles needed")})
        .min(0, {miles_needed: createBiggerThanMsg("Miles needed", 0)}),
    miles_offset: number()
        .typeError({miles_offset: createNumberMsg("Miles offset")})
        .required({miles_offset: createRequiredMsg("Miles offset")})
        .min(0, {miles_offset: createBiggerThanMsg("Miles offset", 0)}),
    hours_needed: number()
        .typeError({hours_needed: createNumberMsg("Hours needed")})
        .required({hours_needed: createRequiredMsg("Hours needed")})
        .min(0, {hours_needed: createBiggerThanMsg("Hours needed", 0)}),
    hours_offset: number()
        .typeError({hours_offset: createNumberMsg("Hours offset")})
        .required({hours_offset: createRequiredMsg("Hours offset")})
        .min(0, {hours_offset: createBiggerThanMsg("Hours offset", 0)}),
    date: date()
        .typeError({date: createShouldBeCorrectMsg("Date")})
        .required({date: createRequiredMsg("Date")}),
});

const validateEditMaintenanceTask = (formData) => validateForm({ formData, scheme: EditMaintenanceTaskValidation });

const validateSingleFieldEditMaintenanceTask = (fieldPath, value) => validateSingleField({ fieldPath, value, scheme: EditMaintenanceTaskValidation });

export {validateEditMaintenanceTask, validateSingleFieldEditMaintenanceTask};
