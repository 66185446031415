import {address, compareOperations} from "../../general/selections-grid/constants";

export const validate = (filterDisplayRecords) => {
    let isValid = true;

    filterDisplayRecords.forEach((filter) => {
        const {option, compare, value} = filter;

        if (!option || !compare || !value) {
            isValid = false;
        }
    })

    return isValid;
}

export const filterRecords = (filterDisplayRecords, points) =>{

    let filtered;
    const checkAddressChose = (address, option) => address.some(item => item === option);
    filterDisplayRecords.forEach((filter) => {
        const {option, compare, value} = filter;
        const isAddress = checkAddressChose(address, option);

        filtered = Object.keys(points).map(key=>points[key])
            .filter((point) => {
                if (isAddress && point.hasOwnProperty('address')) {
                    return compare === 'equal'
                        ? point.address[option] === `${value}`.trim()
                        : point.address[option] !== `${value}`.trim();
                } else {
                    return compare && compareOperations?.[`${compare}`](parseInt(point?.[option]), parseInt(value))
                }
            })
    })
    return filtered
}
