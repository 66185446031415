import React, { FunctionComponent, useEffect, useState } from "react";
import { ValidationRules } from "react-hook-form";

import { MinimalTile } from "../../../../general";

import './InputTextField.styles.scss';


interface InputFieldProps {
    fieldName: string,
    type: string,
    title: string,
    value: string | number,
    register: (rules?: ValidationRules) => any,
    errorMsg?: string,
    rules?: ValidationRules;

    styles?: React.CSSProperties
}
 
const InputField: FunctionComponent<InputFieldProps> = (props) => {
    return ( 
        <MinimalTile title={props.title} small style={props.styles}>
            <input 
                key="title-input" 
                type={props.type}
                name={props.fieldName}
                className="form__input"
                value={props.value}
                ref={props.register()}
            />
            <span className="error__wrapper">{props.errorMsg}</span>
        </MinimalTile>
     );
}
 
export default InputField;
