import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import { GithubPicker } from 'react-color';
import update from 'immutability-helper';
import { equals, pipe, pathOr, tap, always, assocPath } from 'ramda';
import { faTruck } from '@fortawesome/fontawesome-free-solid';
import { FenceBodyLayout } from '../BodyLayout/FenceBodyLayout/FenceBodyLayout';
import SwitchComponent from '../../../elements/SwitchComponent';
import Dialog, { DialogConfigSetter } from '../../../Dialog';
import { BaseComponent } from '../../../../shared/BaseComponent';
import { NewButton } from '../../../elements/Buttons';
import ChevronLeft from '../../../SVG-components/chevron_left';
import DeleteIcon from '../../../SVG-components/delete_outline';
import Settings from '../../../SVG-components/settings_grey';
import EditIcon from '../../../SVG-components/edit';
import * as fenceDb from '../../../../shared/db/fences-db';
import * as generalDb from '../../../../shared/db/general-db';
import { setFenceWaypoint } from '../../../../shared/db/fences-db';
import { fenceHistory } from '../../../../stores/store';
import {
  Actions,
  Actions as RegionActions,
} from '../../../../stores/reducers/map-region-reducers';
import { Actions as FenceActions } from '../../../../stores/reducers/fences-reducers';
import { Actions as PathActions } from '../../../../stores/reducers/map-path-reducers';
import { getMinMaxLengthsAcrossFt } from '../../../../stores/reducers/gmap-selectors';
import { selectIconContext } from '../../../../shared/contexts/selectIcon.context';
import {
  getMapCenter as getMapCenterFromStore,
  getMapZoom as getMapZoomFromStore,
} from '../../../../stores/reducers/gmap-reducers';
import EditLabelFence from './EditLabelFence/EditLabelFence';

import C from '../../../../shared/constants';
import { DEFAULT_REGION_COLOR, BASE_RADIUS } from './EditFence.constants';
import { customSelectStyles } from '../../FilterEdit/FilterEdit.constants';
import { ALL_DEVICES_TAG, noSubmit } from '../../../../shared/helpers';
import { PropsInterface, StateInterface } from './EditFence.interfaces';
import {
  iRegion,
  FenceType,
  FenceGroup,
  iIcon,
  iFullStoreState,
  UserAuth,
  CSVObject,
} from '../../../../shared/interfaces';
import { createFenceTransformFrom } from './EditFence.helpers';
import './EditFence.scss';
import ReadSettings from '../../../ImportSettings';
import { fenceFileSchema } from './EditFence.schemas';
import { FileFormat } from '../../../../shared/parsers';
import { Collapsible } from '../../../general/Collapsible';
import SvgIcon from '../../../SvgIcon/SvgIcon';

const roundLoc = (num) => {
  return isNaN(Math.round(num * 1000) / 1000)
    ? ''
    : Math.round(num * 1000) / 1000;
};

class EditFence extends BaseComponent<
  PropsInterface,
  StateInterface,
  { authUser: UserAuth }
> {
  static contextType = selectIconContext;
  private dialog: DialogConfigSetter;

  state: StateInterface = {
    selectedTagId: null,
    serialNumber: '',
    extraInfo: {},
    showEditExtraInfo: false,
    switcher: false,
    enterFenceNotification: false,
    exitFenceNotification: false,
    unauthorizedFenceAlertNotification: false,
    unauthorizedFenceExclusionNotification: false,
    hideName: this.props.initialDetails?.region?.hideName || false,
    hideShadows: this.props.initialDetails?.region?.hideShadows || false,
    hideIcon: this.props.initialDetails?.region?.hideIcon || false,
    eventValues: 'initializing',
    isSvg: false,
    svgUrl: null,
    deetsToUpdate: {
      shape: FenceType.OVAL,
      region: {
        color: '#555555',
        shape: FenceType.OVAL,
        center: this.props.getMapCenter(),
      },
    },
  };

  constructor(props) {
    super(props);
    this.updateIcon = this.updateIcon.bind(this);
  }

  setupDialog = (callBack: () => DialogConfigSetter): void => {
    this.dialog = callBack();
  };

  save = async () => {
    const {
      fenceId,
      fenceRegion,
      initialDetails,
      itemType,
      itemId,
      minMax,
      dispatch,
    } = this.props;
    const { label, shape } = this.state.deetsToUpdate;
    const { extraInfo, selectedTagId } = this.state;
    const details = initialDetails || {};

    // have no idea whan fenceRegion can be false
    const fenceRegionTyped = fenceRegion as iRegion;

    // saving is a combination of the data from the form (deetstoupdate) and the region
    const fence: fenceDb.iUpdateFence = {
      id: fenceId,
      ...initialDetails,
      ...this.state.deetsToUpdate,
      shape: shape || FenceType.PATH,
      region: {
        shape: fenceRegionTyped.shape,
        editable: fenceRegionTyped.editable,
        center: fenceRegionTyped.center,
        height: fenceRegionTyped.height,
        width: fenceRegionTyped.width,
        hideName: this.state.hideName,
        hideShadows: this.state.hideShadows,
        hideIcon: this.state.hideIcon,
        name: label || details.name,
      },
      name: label || details.name,
    } as any;

    if (!fence.color) fence.color = DEFAULT_REGION_COLOR;

    fence.fenceGroup = FenceGroup.FENCE;

    if (!fenceRegion || minMax[0] < 100 || minMax[1] < 100) {
      this.dialog?.({
        title: 'Incorrect size',
        body: 'Fence must be a minimum of 100 feet',
        type: 'NOTIFICATION',
      });

      return;
    }

    if (!label && !details.name) {
      this.dialog?.({
        title: 'Incorrect name',
        body: 'Fence must have a name',
        type: 'NOTIFICATION',
      });

      return;
    }

    if (itemId === 'undefined' && !selectedTagId) {
      this.dialog?.({
        title: 'Incorrect tag',
        body: 'Fence must have a tag',
        type: 'NOTIFICATION',
      });

      return;
    }
    if (this.state.svgUrl?.url) {
      (fence as any).icon = this.state.svgUrl;
    }
    dispatch(RegionActions.UPDATE_REGION_NAME(fenceId, fence.name));

    const updateInfo = Object.keys(extraInfo).reduce((acc, rec) => {
      if (extraInfo[rec]) return { ...acc, [rec]: extraInfo[rec] };

      return acc;
    }, {});

    await fenceDb.updateExtraInfo(this.fullProps.authUser)(fenceId, updateInfo);
    await fenceDb.updateFence(this.fullProps.authUser)(
      itemType,
      itemId === 'undefined' ? selectedTagId : itemId,
      fence
    );

    this.setState({ isReal: true });
    this.setState({ svgUrl: null });

    dispatch(FenceActions.SET_BACKUP_STATUS(false));
    dispatch(FenceActions.CLEAR_FENCE_BACKUP());

    fenceHistory.replace('/fences-tab');
  };

  cancel = () => {
    const { dispatch } = this.props;

    dispatch(FenceActions.SET_BACKUP_STATUS(false));
    dispatch(FenceActions.CLEAR_FENCE_BACKUP());
    fenceHistory.replace('/fences-tab');
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      Object.keys(nextState).find((k) => nextState[k] !== (this.state || {})[k])
    )
      return true;

    const propsChanged = Object.keys(nextProps)
      .filter(
        (k) =>
          ['fenceId', 'initialDetails', 'fenceGroup', 'fenceRegion'].indexOf(
            k
          ) != -1
      )
      .filter((k) => nextProps[k] !== this.props[k]);

    return propsChanged.length > 0;
  }

  setDeet = ({ target }) => {
    const { name, value } = target;
    const { initialDetails } = this.props;

    const details = initialDetails || {};

    const initVal = details[name] || '';

    if (!name.length) return;

    if (initVal === value) {
      this.setState((s) =>
        update(s as any, {
          deetsToUpdate: { $unset: [name] },
        })
      );
    } else {
      this.setState((s) =>
        update(s as any, {
          deetsToUpdate: { [name]: { $set: value } },
        })
      );
    }
  };

  private recenterRegion = () => {
    this.props.dispatch(
      RegionActions.UPDATE_REGION_CENTER(
        this.props.fenceId,
        this.props.getMapCenter()
      )
    );
  };

  componentDidMount() {
    const { fenceId, initialDetails, dispatch, itemId, newFenceBackup } = this.props;

    const details = initialDetails || {};

    const { deetsToUpdate } = this.state;
    let { color = DEFAULT_REGION_COLOR } = details;
    if ((deetsToUpdate as any).color) color = (deetsToUpdate as any).color;
    const icon = (deetsToUpdate as any).icon || details.icon;

    this.setState({ isReal: !!Object.keys(initialDetails).length || !!newFenceBackup });

    if (newFenceBackup) {
      this.setState({ extraInfo: newFenceBackup.extraInfo })
    } else {
      fenceDb
        .getExtraInfo(fenceId)
        .then((response) => this.setState({ extraInfo: response || {} }));
    }

    // lets show the paths which were visible
    dispatch(RegionActions.UNSTASH_REGIONS());
    dispatch(PathActions.UNSTASH_PATHS());

    // if we have a region then put it on the map
    if (details && details.region || newFenceBackup) {
      this.initExistingRegion(
        dispatch,
        fenceId,
        itemId,
        newFenceBackup ? newFenceBackup.deetsToUpdate.region : details.region,
          newFenceBackup ? newFenceBackup.deetsToUpdate.color : details.color,
          newFenceBackup ? newFenceBackup.deetsToUpdate.icon : details.icon,
          newFenceBackup ? newFenceBackup.deetsToUpdate.name : details.name
      );
    } else {
      this.setState(pipe(assocPath(['deetsToUpdate', 'icon', 'fa'], faTruck)));
      this.updateShape(FenceType.OVAL);
    }
    if (newFenceBackup) {
      this.setState({
        deetsToUpdate: newFenceBackup.deetsToUpdate,
        switcher: newFenceBackup.switcher,
        hideIcon: newFenceBackup.hideIcon,
        hideShadows: newFenceBackup.hideShadows,
        hideName: newFenceBackup.hideName,
        enterFenceNotification: newFenceBackup.enterFenceNotification,
        exitFenceNotification: newFenceBackup.exitFenceNotification,
        unauthorizedFenceAlertNotification: newFenceBackup.unauthorizedFenceAlertNotification,
        unauthorizedFenceExclusionNotification: newFenceBackup.unauthorizedFenceExclusionNotification,
      });
      dispatch(FenceActions.CLEAR_FENCE_BACKUP());
    }
  }

  componentDidUpdate(
    prevProps: Readonly<PropsInterface>,
    prevState: Readonly<StateInterface>,
    snapshot?: any
  ) {
    const { deetsToUpdate } = this.state;
    const { initialDetails } = this.props;
    const details = initialDetails || {};
    const icon = (deetsToUpdate as any).icon || details.icon;
  }

  componentWillReceiveProps(props) {
    const { fenceRegion, initialDetails, dispatch, fenceId, itemId, newFenceBackup } = props;
    const details = initialDetails || {};

    // if we have backup for fence that is creating - we don't need to refetch data
    if (newFenceBackup) return;

    // if we are editing existing and we have a region then initialize it
    if (!fenceRegion && details && details.region) {
      const { region } = details;
      this.initExistingRegion(
        dispatch,
        fenceId,
        itemId,
        region,
        details.color,
        details.icon,
        details.name
      );
    }

    // ensure that if the fence changes our state to save is updated
    if (equals(fenceRegion, details.region)) {
      this.setState((s) =>
        update(s as any, {
          deetsToUpdate: { $unset: ['region'] },
        })
      );
    } else if (fenceRegion) {
      this.setState((s) =>
        update(s as any, {
          deetsToUpdate: { region: { $set: fenceRegion } },
        })
      );
    }

    fenceDb.getEnterFenceNotification(details.id).then((res) => {
      res && this.setState({ enterFenceNotification: res.on });
    });
    fenceDb.getExitFenceNotification(details.id).then((res) => {
      res && this.setState({ exitFenceNotification: res.on });
    });
    fenceDb.getUnauthorizedFenceAlertNotification(details.id).then((res) => {
      res && this.setState({ unauthorizedFenceAlertNotification: res.on });
    });
    fenceDb.getUauthorizedFenceExclusionNotification(details.id).then((res) => {
      res && this.setState({ unauthorizedFenceExclusionNotification: res.on });
    });
    fenceDb.getExtraInfo(details.id).then((res) => {
      res &&
        this.setState({
          serialNumber: res.serial_number,
          extraInfo: { ...res },
        });
    });
  }

  initExistingRegion(
    dispatch,
    fenceId,
    itemId,
    region: iRegion,
    color: string,
    icon: iIcon,
    name?: string
  ) {
    dispatch(
      RegionActions.ADD_REGION(
        fenceId,
        itemId,
        region.shape,
        true,
        region.center,
        region.height,
        region.width,
        color,
        icon,
        name
      )
    );
  }

  componentWillUnmount() {
    const { dispatch, fenceId } = this.props;
    const { isReal = false, deetsToUpdate = {} as any } = this.state;

    if (isReal) {
      if (deetsToUpdate.icon)
        dispatch(RegionActions.SET_REGION_ICON(fenceId, deetsToUpdate.icon));
    } else {
      dispatch(RegionActions.REMOVE_REGION(fenceId));
    }

    dispatch(RegionActions.STASH_REGIONS());
    dispatch(PathActions.STASH_PATHS());
  }

  private updateShape = (value: FenceType) => {
    const {
      fenceRegion,
      getMapCenter,
      dispatch,
      fenceId,
      itemId,
      initialDetails,
      getMapZoom,
    } = this.props;

    const getRadius = () => {
      return BASE_RADIUS / Math.pow(2, getMapZoom() - 1);
    };
    // fence type changes need a whole new region painted
    const center = fenceRegion !== false ? fenceRegion.center : getMapCenter();
    const height = fenceRegion !== false ? fenceRegion.height : getRadius();
    const width = fenceRegion !== false ? fenceRegion.width : getRadius();

    const color = pathOr(
      initialDetails.color,
      ['state', 'deetsToUpdate', 'color'],
      this
    );

    dispatch(RegionActions.REMOVE_REGION(fenceId));
    dispatch(
      RegionActions.ADD_REGION(
        fenceId,
        itemId,
        value,
        true,
        center,
        height,
        width,
        color,
        initialDetails.name
      )
    );
  };

  setColor = ({ hex }) => {
    const { dispatch, fenceId, fenceRegion } = this.props;

    // only change the map region color if there is a region
    if (fenceRegion) dispatch(RegionActions.UPDATE_REGION_COLOR(fenceId, hex));

    this.setState((s) =>
      update(s as any, {
        deetsToUpdate: { color: { $set: hex } },
      })
    );
  };

  changeImage = async () => {
    let icon = await this.context.selectIcon();

    if (!icon) {
      return;
    }
    if (typeof icon !== 'object' || !('icon' in icon)) {
      icon = {
        url: await generalDb.saveIcon(icon as File),
      };
    } else {
      icon = {
        fa: icon,
      };
    }
    this.setState((s) =>
      update(s as any, {
        deetsToUpdate: { icon: { $set: icon } },
      })
    );
    this.setState((state) => ({
      ...state,
      svgUrl: null,
    }));
  };

  updateIcon = async (icon) => {
    let updatedIcon = icon;
    updatedIcon = {
      url: await generalDb.saveIcon(updatedIcon as File),
    };
    this.setState((state) => ({
      ...state,
      svgUrl: updatedIcon,
    }));
  };

  handleTextInput = (label, userInput, extraInfo) => {
    this.setState((state) => {
      return { extraInfo: { ...state.extraInfo, [label]: userInput } };
    });
  };

  handleExtraInfoDelete = (name) => {
    this.setState((state) => {
      const newExtraInfo = { ...state.extraInfo };
      delete newExtraInfo[name];
      return { extraInfo: newExtraInfo };
    });
  };

  saveBackupBeforeAlert = () => {
    const { dispatch, shouldMakeBackup } = this.props;
    const { deetsToUpdate, extraInfo, switcher, hideIcon,
      hideName, hideShadows, enterFenceNotification, exitFenceNotification,
      unauthorizedFenceAlertNotification, unauthorizedFenceExclusionNotification,
    } = this.state;
    if (!shouldMakeBackup) return;
    dispatch(FenceActions.UPDATE_FENCE_BACKUP({
      deetsToUpdate,
      switcher,
      hideIcon,
      hideName,
      hideShadows,
      enterFenceNotification,
      exitFenceNotification,
      unauthorizedFenceAlertNotification,
      unauthorizedFenceExclusionNotification,
      extraInfo,
    }));
  }

  toggleFenceNotification = async (
      updateNotification: (user: UserAuth) => (itemId: string, flag: boolean) => Promise<void>,
      currentState:boolean,
      stateKey: keyof StateInterface
  ) => {
    const { [stateKey]: currentStateValue } = this.state;
    const { initialDetails } = this.props;
    const initID = fenceHistory.location.pathname.split('/')[4];

    await updateNotification(this.fullProps.authUser)(
        initialDetails.id || initID,
        !currentStateValue
    );

    const updatedState: StateInterface | {} = { [stateKey]: !currentState };

    this.setState(updatedState);
  };

  enterFenceToggle = () => {
    this.toggleFenceNotification(
        fenceDb.updateEnterFenceNotification,
        this.state.enterFenceNotification,
        'enterFenceNotification'
    );
  };

  exitFenceToggle = () => {
    this.toggleFenceNotification(
        fenceDb.updateExitFenceNotification,
        this.state.exitFenceNotification,
        'exitFenceNotification'
    );
  };

  alertFenceToggle = () => {
    this.toggleFenceNotification(
        fenceDb.updateUnauthorizedFenceAlertNotification,
        this.state.unauthorizedFenceAlertNotification,
        'unauthorizedFenceAlertNotification'
    );
  };

  exclusionFenceToggle = () => {
    this.toggleFenceNotification(
        fenceDb.updateUnauthorizedFenceExclusionNotification,
        this.state.unauthorizedFenceExclusionNotification,
        'unauthorizedFenceExclusionNotification'
    );
  };

  toggleWaypoint = async () => {
    const { switcher } = this.state;
    const { initialDetails, itemType, itemId, fenceId } = this.props;
    const fenceIdf = initialDetails.id ?? fenceId;

    await setFenceWaypoint(this.fullProps.authUser)(
      itemType,
      itemId,
      fenceIdf,
      !switcher
    );

    this.setState({ switcher: !switcher });
  };

  handleSettingsRead = async (entries: CSVObject) => {
    const { itemType, itemId } = this.props;
    const { selectedTagId } = this.state;

    try {
      await fenceFileSchema.validate(entries);
      const fenceTransform = createFenceTransformFrom(FileFormat.CSV);
      const handleFencePromise = async (entrie) => {
        try {
          const result = await fenceTransform(entrie);
          return {
            status: 'success',
            result,
          }
        } catch (e) {
          return {
            status: 'error',
            error: e,
            name: entrie['Label'][0],
          }
        }
      }
      Promise.all(entries.map((entrie) => handleFencePromise(entrie)))
          .then(async (results) => {
            const invalidNames = [];

            results.forEach((promiseResult) => {
              const isError = promiseResult.status === 'error';
              if (isError) invalidNames.push(promiseResult.name);
            });

            if (invalidNames.length > 0) {
              this.dialog?.({
                title: 'Invalid center coordinates',
                body: `Check if Physical Address column has correct value for further decoding via Google Geocode API.
                Elements with next names has invalid address: ${invalidNames.join(', ')}`
              })
            } else {
              await fenceDb.createFences(
                  itemType,
                  itemId === 'undefined' ? selectedTagId : itemId,
                  results.map(i => i.result)
              );
              fenceHistory.replace('/fences-tab');
            }
          })
    } catch (e) {
      console.log(e);
      this.dialog?.({
        title: e.name,
        body: e.message,
      });
    }
  };

  handleSettingReadError = (e: Error) => {
    this.dialog?.({
      title: e.name,
      body: e.message,
    });
  };

  handleToggle = (fieldName: string) => () =>
    this.setState({ ...this.state, [fieldName]: !this.state[fieldName] });

  debugRender = () => {
    // todo: refactor this ugliness
    const { initialDetails, fenceRegion, dispatch, itemId, itemType, allTags } = this.props;
    const { lat, lng } = (fenceRegion || ({} as any)).center || ({} as any);

    const {
      deetsToUpdate,
      switcher,
      enterFenceNotification,
      exitFenceNotification,
      unauthorizedFenceAlertNotification,
      unauthorizedFenceExclusionNotification,
      serialNumber,
      extraInfo,
      showEditExtraInfo,
    } = this.state;

    const details = initialDetails || {};

    let { color = DEFAULT_REGION_COLOR } = details;

    if ((deetsToUpdate as any).color) color = (deetsToUpdate as any).color;

    const icon = (deetsToUpdate as any).icon || details.icon;

    const name = (deetsToUpdate as any)?.label || details?.name;
    const description = (deetsToUpdate as any)?.description || details?.description;

    const deleteFence = () => {
      this.dialog?.({
        title: 'Confirm Location Request',
        body: 'Are you sure you want to delete? This action cannot be undone.',
      }).then((confirmed) => {
        if (!confirmed) return;

        dispatch(Actions.REMOVE_REGION(details.id));
        dispatch(PathActions.REMOVE_PATH(details.id));

        this.setState({ isReal: false });

        fenceDb.deleteFence(this.fullProps.authUser)(
          details.id,
          itemType,
          itemId
        );
        fenceHistory.replace('/fences-tab');
      });
    };

    const shape = (deetsToUpdate.region || details.region).shape;

    const tagsForSelect = Object.keys(allTags).reduce(
      (acc, rec) => {
        return [...acc, { id: rec, label: allTags[rec].details.name }];
      },
      [{ id: ALL_DEVICES_TAG, label: 'ALL DEVICES' }]
    );

    return (
      <>
        <FenceBodyLayout
          isVisible={this.props.isVisible}
          toggleVisible={this.props.toggleVisible}
          needFencesTab={!itemId || !['device', 'person'].includes(itemType)}
        >
          <div className='TittleBox'>
            <ChevronLeft
              className='SvgElement'
              onClick={(): void => this.cancel()}
            />
            <span>{details.name || 'New Fence'}</span>
            <div className='TittleBox-Actions'>
              <ReadSettings
                fileFormat={FileFormat.CSV}
                onImport={this.handleSettingsRead}
                onError={this.handleSettingReadError}
              />
              <button className='TittleBox-Button-Icon'>
                <DeleteIcon
                  className='TittleBox-Icon SvgElement trash-fill'
                  onClick={(): void => deleteFence()}
                />
              </button>
            </div>
          </div>
          <div className='EditForm'>
            <form onChange={this.setDeet} onSubmit={noSubmit}>
              <div className='ContentBox'>
                {itemId === 'undefined' && (
                  <div className='SelectBox'>
                    <span>Tag:</span>
                    <Select
                      styles={customSelectStyles}
                      options={tagsForSelect}
                      onChange={(selectedOption): void =>
                        this.setState({ selectedTagId: selectedOption.id })
                      }
                    />
                  </div>
                )}
                <div className='IconBox'>
                  <div className='Icon' onClick={this.changeImage}>
                    {icon ? (
                      <SvgIcon
                        color={color}
                        icon={icon}
                        style={{ height: '100%' }}
                      />
                    ) : (
                      'Choose Icon'
                    )}
                  </div>
                  <div className='ColorPicker'>
                    <GithubPicker
                      onChange={this.setColor}
                      colors={C.chooserColors}
                      triangle='hide'
                      width={145}
                    />
                  </div>
                </div>
                <div className='WaypointBox'>
                  <span className='SubTitle'>Waypoint</span>
                  <SwitchComponent
                    onChange={this.toggleWaypoint}
                    checked={details.waypoint ?? false}
                  />
                </div>
              </div>
              <div className='ContentBox'>
                <Collapsible
                  header={<span className='SubTitle'>Display options:</span>}
                  isCollapsed
                >
                  <ul className='SettingsList'>
                    <li className='SettingsList-Group'>
                      <span className='SettingsList-Label'>Hide name</span>
                      <SwitchComponent
                        onChange={this.handleToggle('hideName')}
                        checked={this.state.hideName}
                      />
                    </li>
                    <li className='SettingsList-Group'>
                      <span className='SettingsList-Label'>Hide shadow</span>
                      <SwitchComponent
                        onChange={this.handleToggle('hideShadows')}
                        checked={this.state.hideShadows}
                      />
                    </li>
                    <li className='SettingsList-Group'>
                      <span className='SettingsList-Label'>Hide icon</span>
                      <SwitchComponent
                        onChange={this.handleToggle('hideIcon')}
                        checked={this.state.hideIcon}
                      />
                    </li>
                  </ul>
                </Collapsible>
              </div>
              <div className='ContentBox'>
                <span className='SubTitle'>Alerts:</span>
                <ul className='SettingsList'>
                  <li className='SettingsList-Group'>
                    <span>
                      <span className='SettingsList-Label SettingsList-Label--Link'>
                        Enter Fence
                      </span>
                      <Link
                        to={`/fence/${itemType}/${itemId}/${
                          details.id ?? this.props.fenceId
                        }/alerts/46`}
                        onClick={() => this.saveBackupBeforeAlert()}
                      >
                        <Settings className='SettingsList-SettingsIcon' />
                      </Link>
                    </span>
                    <SwitchComponent
                      onChange={this.enterFenceToggle}
                      checked={enterFenceNotification}
                    />
                  </li>
                  <li className='SettingsList-Group'>
                    <span>
                      <span className='SettingsList-Label SettingsList-Label--Link'>
                        Exit Fence
                      </span>
                      <Link
                        to={`/fence/${itemType}/${itemId}/${
                          details.id ?? this.props.fenceId
                        }/alerts/47`}
                        onClick={() => this.saveBackupBeforeAlert()}
                      >
                        <Settings className='SettingsList-SettingsIcon' />
                      </Link>
                    </span>
                    <SwitchComponent
                      onChange={this.exitFenceToggle}
                      checked={exitFenceNotification}
                    />
                  </li>
                  <li className='SettingsList-Group'>
                    <span>
                      <span className='SettingsList-Label SettingsList-Label--Link'>
                        Unauthorized Stop Alert
                      </span>
                      <Link
                          to={`/fence/${itemType}/${itemId}/${
                              details.id ?? this.props.fenceId
                          }/alerts/107`}
                          onClick={() => this.saveBackupBeforeAlert()}
                      >
                        <Settings className='SettingsList-SettingsIcon' />
                      </Link>
                    </span>
                    <SwitchComponent
                        onChange={this.alertFenceToggle}
                        checked={unauthorizedFenceAlertNotification}
                    />
                  </li>
                  <li className='SettingsList-Group'>
                    <span>
                      <span className='SettingsList-Label SettingsList-Label--Link'>
                        Unauthorized Stop Exclusion
                      </span>
                      <Link
                          to={`/fence/${itemType}/${itemId}/${
                              details.id ?? this.props.fenceId
                          }/alerts/108`}
                          onClick={() => this.saveBackupBeforeAlert()}
                      >
                        <Settings className='SettingsList-SettingsIcon' />
                      </Link>
                    </span>
                    <SwitchComponent
                        onChange={this.exclusionFenceToggle}
                        checked={unauthorizedFenceExclusionNotification}
                    />
                  </li>
                </ul>
              </div>
              <div className='ContentBox'>
                <div className='RecenterBox'>
                  <div>
                    <span className='SubTitle'>Center:</span>
                    <span
                      className='RecenterButton'
                      onClick={this.recenterRegion}
                    >
                      Recenter
                    </span>
                  </div>
                  <div className='CoordinatesBox'>
                    <span>lat: {roundLoc(lat)}</span>
                    <span>lng: {roundLoc(lng)}</span>
                  </div>
                </div>
                <div className='FenceTypeBox'>
                  <span className='SubTitle'>Type:</span>
                  <div>
                    <NewButton
                      active={shape === FenceType.OVAL}
                      title='Ellipses'
                      onClick={pipe(
                        tap((e: any) => e.preventDefault()),
                        always(FenceType.OVAL),
                        this.updateShape
                      )}
                    />
                    <NewButton
                      active={shape === FenceType.RECTANGLE}
                      title='Rectangle'
                      onClick={pipe(
                        tap((e: any) => e.preventDefault()),
                        always(FenceType.RECTANGLE),
                        this.updateShape
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className='ContentBox'>
                <div className='EditForm'>
                  <span className='LightTitle'>Label</span>
                  <input
                    className='LabelInput'
                    defaultValue={details.name || name}
                    name='label'
                  />
                </div>
                <div className='EditForm'>
                  <span className='LightTitle'>Description</span>
                  <textarea
                    className='DescriptionArea'
                    defaultValue={details.description || description}
                    name='description'
                  />
                </div>
              </div>

              <div className='ContentBox'>
                <div className='EditForm'>
                  <div className='ExtraInfo-label'>
                    <span className='SubTitle'>Extra info:</span>
                    <EditIcon
                      onClick={() => {
                        this.setState({
                          showEditExtraInfo: !showEditExtraInfo,
                        });
                      }}
                    />
                  </div>
                  {showEditExtraInfo && (
                    <EditLabelFence
                      extraInfo={extraInfo}
                      onChange={this.handleTextInput}
                      onDelete={this.handleExtraInfoDelete}
                    />
                  )}
                  {!showEditExtraInfo && serialNumber != '' && (
                    <div className='ExtraInfo'>
                      {Object.keys(extraInfo).map((el) => (
                        <div className='ExtraInfo-wrapper'>
                          <div>
                            <span>{el}</span>
                          </div>
                          <span>{extraInfo[el]}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className='ButtonsBlock'>
                <button
                  onClick={() => this.cancel()}
                  className='WhiteButton'
                >
                  Cancel
                </button>
                <button onClick={() => this.save()} className='BlackButton'>
                  Save
                </button>
              </div>
            </form>
          </div>
        </FenceBodyLayout>
        <Dialog setupConfig={this.setupDialog} />
      </>
    );
  };
}

const mapStateToProps = (s: iFullStoreState, o) => {
  const minMax =
    o.match.params.fenceId && s.regions.vis[o.match.params.fenceId]
      ? getMinMaxLengthsAcrossFt(s, { id: o.match.params.fenceId })
      : false;
  const getMapCenter = () => getMapCenterFromStore(s.gmap);
  const getMapZoom = (): number => getMapZoomFromStore(s.gmap);
  return {
    minMax,
    itemType: o.match.params.itemType,
    itemId: o.match.params.itemId,
    fenceId: o.match.params.fenceId,
    initialDetails: o.initialDetails,
    fenceGroup:
      o.match.params.fenceGroup === 'fences'
        ? FenceGroup.FENCE
        : FenceGroup.PATH,
    fenceRegion: s.regions.vis[o.match.params.fenceId] || false,
    getMapCenter,
    authUser: s.auth.user,
    getMapZoom,
    allTags: s.general.tags,
    newFenceBackup: s.fences.newFenceBackup,
    shouldMakeBackup: s.fences.shouldMakeBackup,
  };
};

export default connect(mapStateToProps)(EditFence as ComponentType);
