import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import { get } from 'lodash';
import { BaseComponent } from '../../../../shared/BaseComponent';
import { ACL, UserCan } from '../../../../shared/constants';
import { getPersonAllowedSee } from '../../../../shared/db/general-db';
import { iTag, iDeviceDetails } from '../../../../shared/interfaces';
import { DevicesDetailsContainer } from '../../../../stores/reducers/devicesData';
import { FaLink } from '../../../elements/fa';
import { TableSimple } from '../../../elements/plain-table';
import LocalPersonTile from '../LocalPeopleTile';
import * as structure from '../../../../shared/interfaces';
import React from 'react';

export class VisibleTagsTile extends BaseComponent<
  {
    userCanDo;
    personId;
    tags: structure.iList<iTag>;
    devicesDetails: DevicesDetailsContainer;
  },
  { allowed: structure.iItemsAllowed; devices: iDeviceDetails[] }
> {
  async componentDidMount() {
    const { personId } = this.fullProps;

    this.setState({ allowed: await getPersonAllowedSee(personId) });
  }

  debugRender = () => {
    const { userCanDo, personId, tags } = this.props;

    const filteredTags = Object.keys(tags).reduce((acc, rec) => {
      if (get(tags[rec], `instances.allowed-see.person.${personId}`)) {
        return { ...acc, [rec]: tags[rec] };
      }

      return acc;
    }, {});

    const hideTable = Object.keys(filteredTags).length === 0;

    return (
      <LocalPersonTile
        collapsible
        initialCollapsed
        title='Visible Tags'
        loading={!this.state || !this.state.allowed}
        action={
          !ACL.check(UserCan.DO_ANYTHING, userCanDo) ? undefined : (
            <FaLink
              to={`/person/${personId}/visible-tags`}
              icon={faPencilAlt}
              style={{ color: '#fff' }}
            />
          )
        }
      >
        {!this.state || !this.state.allowed || hideTable ? null : (
          <TableSimple
            sm
            striped
            headers={['Tag label', 'Read only']}
            style={{ fontSize: 11 }}
          >
            {Object.keys(filteredTags || {}).map((filterId) => (
              <tr key={filterId}>
                <td
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 170,
                  }}
                >
                  {filteredTags[filterId].details.name}
                </td>
                <td>
                  {get(
                    filteredTags[filterId],
                    `instances.allowed-see.readOnly.${personId}`,
                    false
                  ).toString()}
                </td>
              </tr>
            ))}
          </TableSimple>
        )}
      </LocalPersonTile>
    );
  };
}
