import React from "react";
import "./index.scss";
import DashboardBlock from "../../../DashboardBlock";
import { ReactComponent as ListAlt } from "../../../../assets/svg/list_alt_new.svg";
import { ReactComponent as Add } from "../../../../assets/svg/plus.svg";

import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from "react-redux";
import { iFullStoreState } from "../../../../shared/interfaces";
import SearchModal from '../../../menus/search';
import GlobalSearchInput from "../../../../components/GlobalSearchInput";
import { Button, ButtonStyles, ButtonSize } from "../../../Button";
import MaintenanceTasks from "../MaintenanceTasks";
import MaintenanceRequired from "../MaintenanceRequired";
import { Link } from "react-router-dom";
import { GMapModalAC, GMapModalContent } from "../../../../stores/reducers/gMapModal/AC";
import { ReactComponent as FlipToFront } from "../../../../assets/svg/flip_to_front.svg"
import { ReactComponent as VisibilityIcon } from  "../../../../assets/svg/visibility.svg";

const MaintenanceRequiredActions = () => {
    const isSearchVis = useSelector<iFullStoreState, boolean>(s => s.general.showSearch);
    const dispatch = useDispatch();
    const handleAddExtraTaskBtnClick = () => {
        dispatch(GMapModalAC.showModal({contentType: GMapModalContent.COMPLETE_EXTRA_TASK}))
    }
    return (
        <div className={`maintenance-page__actions maintenance-page__actions${isSearchVis? "--search-visible": ""}`}>
            <Button onClick={handleAddExtraTaskBtnClick} className="maintenance-page__action-button" styleType={ButtonStyles.GRAY_BLACK} size={ButtonSize.MD}><Add className="icon-16px" />&nbsp;Extra task</Button>
                <Link to="/reports/new/maintenance">
                    <Button className="maintenance-page__action-button" styleType={ButtonStyles.GRAY_BLACK} size={ButtonSize.MD}><ListAlt className="icon-16px" />&nbsp;Report</Button>
            </Link>
        </div>
    );
}

const MaintenancePage = () => {
    const dispatch = useDispatch();

    const handleIconClick = () => {
        dispatch(GMapModalAC.showModal({contentType: GMapModalContent.COMPLETE_EXTRA_TASK}));
    }
    return (
        <DashboardBlock searchExtra={<MaintenanceRequiredActions />}>
            <div className="maintenance-page">
                <div className="extra-task-block">
                    <Link to="/reports/new/maintenance" className="maintenance-reports-button">
                        <VisibilityIcon className="icon-17px" />&nbsp;Maintenance reports
                    </Link>
                    <button onClick={handleIconClick} className="extra-task-button">
                        <FlipToFront className="icon-12px" />&nbsp;Add completed task
                    </button>
                </div>
                <div className="maintenance-page__tables">
                    <MaintenanceRequired />
                    <MaintenanceTasks />
                </div>
            </div>

        </DashboardBlock>
    )
}




export default MaintenancePage;