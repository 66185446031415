import React, { useEffect } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { IShowingCreditCard } from "./interfaces";

import { brainTree } from "./brainTree";
import instance from "../../../../../api/instance";
import { LoadingModal } from "../../../../elements/loading";
import { iReducersState } from "../../../../../stores/reducers";
import { addCreditCard, getCards } from "../../../../../stores/reducers/billing";
import "./addCreditCard.scss";

const AddCreditCard = ({
  setShowBankCard,
  setShowBankCardActive,
  loadingCreditCard,
  setLoadingCreditCard,
}: IShowingCreditCard) => {
  const { brainTreeToken } = useSelector(
    (state: iReducersState) => state.billing
  );
  const dispatch = useDispatch();

  const showingCardBehaviour = () => {
    setShowBankCard(false);
    setShowBankCardActive(true);
    setLoadingCreditCard(false);
  };

  useEffect(() => {
    brainTree(addCard, brainTreeToken, showingCardBehaviour); // addCard, token, callback
  }, [brainTreeToken]);

  const addCard = (nonce, showingCardBehaviour) => {
    setLoadingCreditCard(true);

    instance
      .post(`api/billing/add-card`, nonce)
      .then((res) => {
        dispatch(
          addCreditCard(
            res.data.paymentMethods.filter(
              (credit) => credit.processor === "Braintree"
            )
          )
        );
        // dispatch(getCards(res.data.paymentMethods))
        showingCardBehaviour();
        toast.success("Credit Card Was Created");
      })
      .catch((err) => {
        setLoadingCreditCard(false);
        toast.error("Failed To Create Credit Card");
        console.log("ERROR: Add-credit-card failed", err);
      });
  };

  return (
    <div className={classNames({ "add-billing-card": true })}>
      {loadingCreditCard ? (
        <LoadingModal />
      ) : (
        <form action="/" id="my-sample-form">
          <div className="card-number-box">
            <input type="hidden" name="payment_method_nonce" />
            <label htmlFor="card-number">Credit card</label>
            <div id="card-number" />
          </div>

          <div className="bottom-box">
            <div className="expires">
              <label htmlFor="expiration-date">Expires:</label>
              <div id="expiration-date" />
            </div>

            <div className="cvv-box">
              <label htmlFor="cvv">cvv</label>
              <div id="cvv" />
            </div>
          </div>
          <div className="form-box-btn">
            <div
              className="btn-cancel"
              onClick={() => {
                setShowBankCard(false);
                setShowBankCardActive(true);
              }}
            >
              cancel
            </div>
            <input id="my-submit" type="submit" value="save" />
          </div>
        </form>
      )}
    </div>
  );
};

export default AddCreditCard;
