import React, { useEffect } from 'react';
import {Route, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Seq} from 'immutable';

import DeviceTrips from '../DeviceTrips';
import DeviceStatuses from '../../pages/devices/deviceStatuses';
import {iDeviceDetails, iFullStoreState} from '../../../shared/interfaces';
import { iReducersState } from '../../../stores/reducers';
import { resetCamerasState } from '../../../stores/reducers/videoCameras';

export default () => <>
    <Route path="/device/:deviceId">
        <RDeviceRouter />
        <RDeviceCamerasVideo/>
    </Route>
    <Route exact path={[`/reports/new/travel`, `/reports/new/static`, `/reports/new/summary`, `/reports/new/unauthorized`]}>
        <RReportDevicesRouters />
        <RDeviceCamerasVideo/>
    </Route>
</>;

const RDeviceRouter = React.memo(() => {
    const {deviceId} = useParams<{ deviceId: string }>();

    return <DeviceTrips deviceId={deviceId} />;
});

const RDeviceCamerasVideo = React.memo(() => {
    const dispatch = useDispatch();
    const {deviceId} = useParams<{ deviceId: string }>();
    const { cameraOnline, deviceInfo: {id, alertDate}, availableTime } = useSelector((state: iReducersState) => state.videoCameras);
    const CAMERA_ONLINE = cameraOnline == 1;
    const deviceData = useSelector<iFullStoreState, iDeviceDetails>(
                                                                    // + take id from redux 
        (state) => state.devicesData.devicesDetails.get(deviceId || id)
    );

    useEffect(() => {
        return () => {
            dispatch(resetCamerasState());
        }
    },[])
    
    return  (deviceData?.isCamera && CAMERA_ONLINE && availableTime > 0)  && <DeviceStatuses device={deviceData} />;
});

const RReportDevicesRouters = React.memo(() => {
    const reportDevicesIds = useSelector<iFullStoreState, Seq.Indexed<string>>(
        (s) => s.devicesTripsPoints.keySeq(),
        (l, r) => l.size === r.size,
    );

    return <>{reportDevicesIds.map((deviceId) =>
        <DeviceTrips key={deviceId} deviceId={deviceId} isReportTrips />,
    )}</>;
});
