import {history} from './stores/store';
import {fbapp} from './shared/firebase';

export const authenticateWithCustomToken = async ({authToken}) => {
    try {
        await fbapp.auth().signInWithCustomToken(authToken);
    } catch (e) {
        console.log("Authentication with custom token error", e);
        throw new Error(`Authentication with custom token error: ${e?.message ?? ''}`)
    }
}

const setDefaultColorsAndImgFromEnv = () => {
    const rootVariables = {
        '--navMenuBackgroundColor': process.env?.REACT_APP_MOBILE_MENU_BACKGROUND_COLOR ?? '#2C2C2C',
        '--navMenuButtonColor': process.env?.REACT_APP_MOBILE_MENU_BUTTON_COLOR ?? '#FF7C02',
        '--navMenuIconColor': process.env?.REACT_APP_MOBILE_MENU_ICON_COLOR ?? '#ffffff',
        '--navMenuBorderColor': process.env?.REACT_APP_MOBILE_BORDER_MENU_BUTTON_COLOR ?? '#FF7C02',
        '--colorDark': process.env?.REACT_APP_BASE_THEME_DARK ?? '#000000',
        '--colorBlack': process.env?.REACT_APP_BASE_THEME_BLACK ?? '#111111',
        '--colorGunPowder': process.env?.REACT_APP_BASE_THEME_GUN_POWDER ?? '#4C4C4D',
        '--colorWhite': process.env?.REACT_APP_BASE_THEME_WHITE ?? '#FFFFFF',
        '--colorTangerine': process.env?.REACT_APP_BASE_THEME_TANGERINE ?? '#FF7C02',
        '--colorEclipse': process.env?.REACT_APP_BASE_THEME_ECLIPSE ?? '#3A3A3A',
        '--colorSnow': process.env?.REACT_APP_BASE_THEME_SNOW ?? '#F9F9F9',
        '--colorSuvaGrey': process.env?.REACT_APP_BASE_THEME_SUVA_GREY ?? '#898989',
        '--loginColor': process.env?.REACT_APP_LOGIN_MAIN_COLOR ?? '#F26F22',
        '--loginBg': process.env?.REACT_APP_LOGIN_BACKGROUND ?? 'images/bg.png',
        '--sidebarIcon': process.env?.REACT_APP_SIDEBAR_FAVORITE_ICON ?? '#fff',
        '--sidebarIconBorder': process.env?.REACT_APP_SIDEBAR_FAVORITE_BORDER ?? 'rgba(255, 255, 255, 0.6)',
        '--sidebarTitle': process.env?.REACT_APP_SIDEBAR_FAVORITE_TITLE ?? '#fff',
        '--sidebarGradient1': process.env?.REACT_APP_FAVORITE_SIDEBAR_BG_1 ?? '#000',
        '--sidebarGradient2': process.env?.REACT_APP_FAVORITE_SIDEBAR_BG_2 ?? '#111',
        '--headerBackground': process.env?.REACT_APP_HEADER_BG ?? '',
    }

    Object.entries(rootVariables).forEach(([key,value]) => {
        const valueToSet = key.toLowerCase().includes('bg') ? `url(${value})` : value;
        document.documentElement.style.setProperty(key, valueToSet);
    })
};

export const setDefaultValueFromEnv = () => {
    setDefaultColorsAndImgFromEnv();
}