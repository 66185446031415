import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DayPicker from "react-day-picker";
import {Fa} from "../../../../elements/fa";
import { faSpinner } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";
import { isEmpty } from "ramda";
import classnames from "classnames";
import { IS_MOBILE_APP } from "../../../../../shared/constants";

import {
  dayChooseByTimestamp,
  existingHistoryDate,
} from "../../../../../api/openApi";
import { IHistoryProps } from "../interfaces";
import { CircularProgress } from "@material-ui/core";
import DayPickerNavbar from "../../../../elements/DayPickerNavbar";
import DayPickerWeekday from "../../../../elements/DayPickerWeekday";
import HistoryItem from "./HistoryItem";
import { iReducersState } from "../../../../../stores/reducers";
import { resetAlertDate } from "../../../../../stores/reducers/videoCameras";
import styles from "./History.module.scss";
import { iDevicePing, iFullStoreState } from "../../../../../shared/interfaces";
import { IReducerSelectedPoint } from "../../../../../stores/reducers/selectedPoint";

declare let window: any;

const tips = [
  {
    color: "#4a4a4a",
    description: "Selected day",
  },
  {
    color: "#0aaaea",
    description: "Count of a records video stream by day",
  },
  {
    color: "#FF7C02",
    description: "Available dates with records stream",
  },
];

const CONSTANTS = {
  mainDateFormat: "YYYY-MM-DD"
}

const History = ({ serial = undefined, firebaseDeviceId = undefined, setCount, viewHistoryVideo, setSelectedHistoryChannel, selectedHistoryChannel, loadingButton, prevHistoryTime,setPrevHistoryTime, setAlertTimestamp }: IHistoryProps) => {
  const { mainDateFormat } = CONSTANTS;

  const [historyRecordsLoading, setHistoryRecordLoading] = useState(false);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [existingDate, setExistingDate] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [day, setDay] = useState(null);
  const [actualMonth, setActualMonth] = useState(moment().format(mainDateFormat));

  const dateParts = actualMonth?.split('-');
  const yearNumber = parseInt(dateParts[0]);
  const monthNumber = parseInt(dateParts[1]) - 1; 
  const dayNumber = parseInt(dateParts[2]);

  const dispatch = useDispatch();
  const location = useLocation();
  const checkingDeviceRoute = /^\/device\/[\w-]+$/.test(location.pathname);

  const { deviceInfo: { id, alertDate, alertTime } } = useSelector((state: iReducersState) => state.videoCameras);

  const isCordovaIosDevice = IS_MOBILE_APP && window?.cordova?.platformId === 'ios';

  const selectedDate = `${alertDate} ${alertTime}`;
  const alertTimestamp = isCordovaIosDevice ? new Date(selectedDate.replace(/-/g, "/")).getTime() : new Date(selectedDate).getTime();

  const selectedPoint = useSelector<iFullStoreState, IReducerSelectedPoint>(state => state.selectedPoint);

    const point = useSelector<iFullStoreState, iDevicePing | undefined>(
        state => selectedPoint
                 ? state.devicesTripsPoints.getIn([selectedPoint.deviceId, 'tripsPoints', selectedPoint.tripId, selectedPoint.pointId])
                 : undefined,
        (left, right) => left?.pointId === right?.pointId,
    );
    
  const takeExistingHistoryDate = async ({currentMonth}) => {
    setCalendarLoading(true);
    setActualMonth(moment(currentMonth).format(mainDateFormat));
    setAlertTimestamp(alertTimestamp);
    const selectedMonth = moment(currentMonth).format(mainDateFormat)
    const { data } = await existingHistoryDate({ firebaseDeviceId: firebaseDeviceId, currentMonth: selectedMonth });
    setExistingDate(data);
    setCalendarLoading(false);
  };

  const chooseHistoryDate = async (date) => {
    setHistoryRecordLoading(true);
    setDay(date);
    const convertDate = moment(date).format(mainDateFormat);

    const data = await dayChooseByTimestamp({ firebaseDeviceId: firebaseDeviceId, convertDate, alertTime }).finally(() =>
      setHistoryRecordLoading(false)
    );
    setHistoryList(data);
    setCount(data?.length);
  };

  const chooseHistoryDateFromCalendar = async (date) => {
    setHistoryRecordLoading(true);
    setDay(date);
    const convertDate = moment(date).format(mainDateFormat);
    const alertTime = null;

    const data = await dayChooseByTimestamp({ firebaseDeviceId: firebaseDeviceId, convertDate, alertTime }).finally(() =>
      setHistoryRecordLoading(false)
    );
    setHistoryList(data);
    setCount(data?.length);
  };

  useEffect(() => {
    takeExistingHistoryDate({currentMonth: moment().format(mainDateFormat)});
    if(selectedHistoryChannel) setSelectedHistoryChannel(null);
    if(prevHistoryTime) setPrevHistoryTime(0);
  }, []);

  useEffect(() => {
    if(alertDate) chooseHistoryDate(alertDate);

    if(checkingDeviceRoute) {
      return () => dispatch(resetAlertDate());
    }
  },[]);

  const chooseChannel = (channelInfo) => {
    setSelectedHistoryChannel(channelInfo);
  };

  const currentDate = alertDate ? new Date(alertDate) : actualMonth;

  const modifiers = {
    exist: existingDate?.map(({ existsDate }) => moment(existsDate).toDate()),
    select: alertDate ? moment(day).toDate() : day,
  };

  const modifiersStyles = {
    exist: {
      color: "#d8d8d8",
    },
    select: {
      backgroundColor: "#4a4a4a",
      color: "white",
      zIndex: "999",
      borderRadius: "5px",
    },
  };

  return (
    <>
      <div className={styles.history}>
        <div className={styles.calendar}>
          {calendarLoading ? (
            <div className={styles.loader}>
              <CircularProgress size={30} />
            </div>
          ) : (
            <DayPicker
              className={classnames({
                "day-picker": true,
              })}
              modifiers={modifiers}
              modifiersStyles={modifiersStyles}
              month={new Date(yearNumber, monthNumber, dayNumber)}
              selectedDays={existingDate.map(({ existsDate }) =>
                moment(existsDate).toDate()
              )}
              onDayClick={(date) => chooseHistoryDateFromCalendar(date)}
              navbarElement={(props) => (
              <DayPickerNavbar
                {...props}
                onPreviousClick={()=> !alertDate && takeExistingHistoryDate({currentMonth: new Date(props.previousMonth)}) }
                onNextClick={() => !alertDate && takeExistingHistoryDate({currentMonth: new Date(props.nextMonth)}) }
              />
          )}
              weekdayElement={DayPickerWeekday}
              captionElement={() => null}
              disabledDays={{ after: new Date() }}
              showOutsideDays
            />
          )}
        </div>
        <div className={`${styles.history__list} ${historyList?.length && styles.customScroll}`} >
          <div className={styles.history__list__header}>
            <div>Start</div>
            <div>End</div>
            <div>Channel</div>
          </div>

          {historyRecordsLoading ? (
            <div className={styles.loader}>
              <CircularProgress size={30} />
            </div>
          ) : (
            <>
              {!isEmpty(historyList) ? (
                historyList?.map((history, index) => {
                  return (
                    <HistoryItem
                        key={index}
                        history={history}
                        chooseChannel={chooseChannel}
                    />
                  );
                })
              ) : (
                <div className={styles.emptyHistory}>
                  {`There are no records stream ${alertDate && `for this date ${alertDate} ${alertTime}`}`}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <button
        className={styles.viewButton}
        onClick={viewHistoryVideo}
        disabled={selectedHistoryChannel == null}
      >
        {loadingButton ? <Fa icon={faSpinner} spin /> :"View"}
      </button>

      <hr />

      <ul className={styles.tips}>
        {tips.map((option) => {
          return (
            <li key={option.color} className={styles.tips_list}>
              <div style={{ backgroundColor: option.color }} />
              <span>{option.description}</span>
            </li>
          );
        })}
      </ul>

      <hr />
    </>
  );
};

export default History;
