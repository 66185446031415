import React from 'react';
import {createPortal} from 'react-dom';
import {MAP} from 'react-google-maps/lib/constants';
import {useSelector} from 'react-redux';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';

import {iFullStoreState} from '../../../shared/interfaces';
import {isMobile} from '../../../shared/helpers';

type IProps = {
    position: google.maps.ControlPosition;
    className?: string;
    children?: React.ReactNode;
}
const MapControl = (props: IProps) => {
    const mapRef = useSelector<iFullStoreState, React.RefObject<GoogleMap> | null>((state) => state.gmap.mapRef());

    const [controlDiv, setControlDiv] = React.useState<HTMLDivElement | undefined>();

    React.useEffect(() => {
        const map = mapRef?.current?.context?.[MAP];

        if (!controlDiv) {
            const div = document.createElement('div');

            if (props.className) {
                div.className = props.className;
            }

            div.style.margin = isMobile || window['cordova'] ? '0' : '10px';
            div.style.height = 'auto';
            div.style.width = isMobile || window['cordova'] ? '100%' : 'auto';
            div.style.padding = '12px';
            setControlDiv(div);
        } else {
            map.controls?.[props.position]?.push?.(controlDiv);
        }

        return () => {
            if (controlDiv) {
                const index = map.controls?.[props.position]?.getArray?.().indexOf(controlDiv);
                map.controls?.[props.position]?.removeAt?.(index);
            }
        };
    }, [!!controlDiv]);

    return controlDiv ? createPortal(props.children, controlDiv) : null;
};

export default MapControl;
