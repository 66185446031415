import { AlertTypeTitle, AlertTypes, ItemType, iFullStoreState } from '../../../shared/interfaces';
import { filter, mapObjIndexed, path, pathOr, pipe, test, values } from 'ramda';

import C from '../../../shared/constants';
import React from 'react';
import { ReportSelectorLabel } from "./ReportSelectorLabel";
import { createSelector } from 'reselect';

// this selector will only run the actuall builder if any of the initial states change
// otherwise it will cache the result and not fall through
// this is abstracted to work with any of the filters, it is a builder
export const selectorCreator = (stateExtractor: any, filterKey: string, compStrFn: any, elMaker: (item, active) => any) => createSelector(
    (s: iFullStoreState) => stateExtractor(s) || {} as any,
    (s: iFullStoreState) => new RegExp(`${pathOr('.', ['report', 'filterFilters', filterKey], s)}`, 'i'),
    (s: iFullStoreState) => s.report.details.filters[filterKey] || {},

    // now we have a new device / filter / selected state we generate the window components
    (reduxData, filterRegEx: RegExp, selected) => pipe(
        filter(pipe(compStrFn, test(filterRegEx))),
        mapObjIndexed((val, id) => elMaker({ id, val }, selected[id]) as any),
        mapObjIndexed((el, id) => containerWrapper(id, selected[id])(el)),
        values,
    )(reduxData)
);

export const deviceSelector = selectorCreator(
    (s: iFullStoreState) => s.devicesData.devicesDetails.toObject(),
    ItemType.device.toString(),
    path(['name']),
    ({ id, val: device }, active) => <ReportSelectorLabel key={id} itemId={id} active={active} title={device.name} {...device.icon} />
);
export const personSelector = selectorCreator(
    path(['general', 'people']),
    ItemType.person.toString(),
    path(['displayName']),
    ({ id, val: person }, active) => <ReportSelectorLabel key={id} itemId={id} title={person.displayName} active={active} url={person.photoURL} />

);

export const tagsSelector = selectorCreator(
    path(['general', 'tags']),
    ItemType.tag.toString(),
    path(['details', 'name']),
    ({ id, val: tag }, active) => <ReportSelectorLabel key={id} itemId={id} title={tag.details.name} active={active} {...tag.details.icon || {fa: 'tag'}} />
)

export const containerWrapper = (id, active) => el => (
    <div key={id} style={{color: active ? C.primaryColor : C.primaryText}}>
        {el}
    </div>
)


export const alertsSelector = selectorCreator(
    () =>  AlertTypes,
    'alerts',
    alert => AlertTypeTitle(alert),
    ({ val: alert }, active) => <ReportSelectorLabel key={alert} title={AlertTypeTitle(alert)} itemId={alert} active={active} />
)

export const labelsSelector = selectorCreator(
    (state) => {
        var ret = {};
        state.general.tripLabels.map(label => ret[label] = label)
        return ret;
    },
    'labels',
    label => label,
    ({ val: label }, active) => <ReportSelectorLabel key={label} active={active} title={label} itemId={label} />
)

export const typeSelector = selectorCreator(
    (state) => {
        var ret = {};
        state.general.reportTypes.map(label => ret[label] = label)
        return ret;
    },
    'types',
    label => label,
    ({ val: label }, active) => <ReportSelectorLabel key={label} active={active} title={label} itemId={label} />
)
