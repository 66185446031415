import React, { HTMLProps, useState } from "react";
import "./index.scss";
import { Button, ButtonSize, ButtonStyles } from "../Button";
import Input from "../Input";
import * as yup from "yup";

const emailValidator = yup.string().email().required();

interface CustomFieldsProps<T> extends HTMLProps<HTMLDivElement> {
    items: T[];
    onItemAdd: (field: string) => void;
    render: ({item}: {item: T}) => JSX.Element;
}

const CustomFields: <T>(props: CustomFieldsProps<T>) => JSX.Element = ({render, items = [], onItemAdd, placeholder}) => {
    const [field, setField] = useState("");
    const [error, setError] = useState("");
    const handleInputChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        setField(value);
    }
    const handleAddBtnClick = () => {
        try {
            handleValidation(field);
            onItemAdd?.(field);
            setField("");
            setError("");
        } catch (e) {
            setError(e.message);
        }
    }

    const handleInputBlur = () => setError("");

    const handleValidation = (value) => {
        return emailValidator.validateSync(value);
    }

    return (
        <div className="custom-fields">
            <div className="custom-fields__actions">
                <Input type="text" placeholder={placeholder} className="custom-fields__input" value={field} onChange={handleInputChange} onBlur={handleInputBlur}/>
                <Button styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD} onClick={handleAddBtnClick}>Add</Button>
            </div>
            {error && <div className="custom-fields__error">{error}</div>}
            <div className="custom-fields__body">{items.map(item => render({item}))}</div>
        </div>
    )
}

export default CustomFields;