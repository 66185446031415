import React from 'react';
import ReactDOM from 'react-dom';

type IProps = {
    containerId?: Readonly<string>;
}
const Portal: React.FunctionComponent<IProps> = (props) => {
    const portalRoot = React.useRef<HTMLElement | null>(props.containerId ? document.getElementById(props.containerId) : document.createElement('div'));

    React.useEffect(() => {
        if (!props.containerId && portalRoot.current) {
            document.body.appendChild(portalRoot.current);
        }

        return () => {
            if (!props.containerId && portalRoot.current) {
                document.body.removeChild(portalRoot.current);
            }
        };
    }, [!!portalRoot.current]);

    let container = portalRoot.current;

    if (!container && props.containerId) {
        container = document.getElementById(props.containerId);
    }
    if (!container) {
        return null;
    }

    return ReactDOM.createPortal(
        props.children,
        container,
    );
};

export default Portal;
