import React from "react";
import styles from "./styles.module.scss";

interface IAuditLogProps {
  label: string;
  value: any;
}

const AuditLog: React.FC<IAuditLogProps> = ({ label, value }) => {
  let formattedValue = value;

  if (label === "time") {
    formattedValue = new Date(value).toLocaleString();
  }

  return <p className={styles.AuditLog}>{`${label}: ${formattedValue}`}</p>;
};

export default AuditLog;
