import auth from './auth-reducer';
import { billing,BillingState } from "./billing";
import devicesData, { IReducerDevicesData } from './devicesData';
import tagsDevicesMap, { TagsDevicesMapReducer } from './tagsDevicesMap';
import devicesTripsPoints, { IReducerDevicesTripsPoints } from './devicesTripsPoints';
import selectedPoint, { IReducerSelectedPoint } from './selectedPoint';
import dashboardInfo, { IReducerDashboardInfo } from './dashboardInfo';
import { iState as iGeneralReducerState, reducers as general } from './general-reducers-fn';
import { iState as iGmapState, reducers as gmap } from './gmap-reducers';
import { iState as iTagItemState, reducers as tagItem } from './tag-item';
import { iState as iFencesState, reducers as fences } from './fences-reducers';
import { iState as iMapRegionState, reducers as regions } from './map-region-reducers';
import { iState as iMapPathState, reducers as paths } from './map-path-reducers';
import { iState as iReportState, reducers as report } from './report-reducers';
import { iState as iLayoutState, reducers as layout } from './layout-reducers';
import { iState as iTimelineState, reducers as timeline } from './timeline-reducers';
import { iState as iUiState, reducers as ui } from './ui-reducers';
import { iState as iGMapModalState, reducer as  gMapModal } from "./gMapModal"
import { iMaintenanceStore, reducer as maintenance } from "./maintenance";
import { AuthReducer } from '../../shared/interfaces';
import { videoCameras, VideoCamerasState } from './videoCameras';

export { Actions as UiActions } from './ui-reducers'

export interface iReducersState {
  auth: AuthReducer;
  devicesData: IReducerDevicesData;
  tagsDevicesMap: TagsDevicesMapReducer;
  devicesTripsPoints: IReducerDevicesTripsPoints;
  selectedPoint: IReducerSelectedPoint;
  dashboardInfo: IReducerDashboardInfo;
  gmap: iGmapState;
  general: iGeneralReducerState;
  tagItem: iTagItemState;
  fences: iFencesState;
  regions: iMapRegionState;
  paths: iMapPathState;
  report: iReportState;
  layout: iLayoutState;
  timeline: iTimelineState;
  ui: iUiState;
  gMapModal: iGMapModalState;
  billing: BillingState;
  videoCameras: VideoCamerasState;
  maintenance: iMaintenanceStore
}

export const reducers = {
  auth,
  devicesData,
  tagsDevicesMap,
  devicesTripsPoints,
  selectedPoint,
  dashboardInfo,
  general,
  gmap,
  tagItem,
  fences,
  regions,
  paths,
  report,
  layout,
  timeline,
  ui,
  gMapModal,
  billing,
  videoCameras,
  maintenance
};
