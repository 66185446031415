import { clientDb, keyGen, auditKeyGen } from '../firebase';
import { idValArr, makeAudit } from '../helpers';
import { ItemType, UserAuth } from '../interfaces';
import { iEventInstance } from '../../shared/interfaces';

interface AlertKey {
    itemType: ItemType,
    itemId: string,
    alertType: string,
}

export const alertValLoc = (itemType: ItemType, itemId: string) => {
    if (itemType === ItemType.device) {
        return `devices/device-details/${itemId}/eventValues`
    }

    if (itemType === ItemType.fence) {
        return `fences/fence-notifications/${itemId}/eventValues`
    }

    if (itemType === ItemType.tag) {
        return `tags/${itemId}/details/eventValues`
    }

    throw new Error(`missing item type ref for alerts db for itemType: ${itemType} and id ${itemId}`);
}

const alertValRef = (itemType: ItemType, itemId: string) => {
    if (itemType === ItemType.device) {
        return clientDb().child(`devices/device-details/${itemId}/eventValues`)
    }

    if (itemType === ItemType.fence) {
        return clientDb().child(`fences/fence-notifications/${itemId}/eventValues`);
    }

    if (itemType === ItemType.tag) {
        return clientDb().child(`tags/${itemId}/details/eventValues`)
    }

    throw new Error(`missing item type ref for alerts db for itemType: ${itemType} and id ${itemId}`);
}

export const getGeofences = (itemId) => {
    return alertValRef(ItemType.fence, itemId).toJSON()
}

export const toggleNotificationSchedule = (user: UserAuth) => async (alert: AlertKey, on: boolean) => {
    const path = `${alertValLoc(alert.itemType, alert.itemId)}/${alert.alertType}/dnd/on`

    await clientDb().update(makeAudit(user, {
        [path]: on
    }))
}

export const clearNotificationSchedules = (user: UserAuth) => async (alert: AlertKey, day) => {
    const path = `${alertValLoc(alert.itemType, alert.itemId)}/${alert.alertType}/dnd/days/${day}`

    await clientDb().update(makeAudit(user, {
        [path]: null
    }))
}

export const upsertDnd = (user: UserAuth) => async (alert: AlertKey, day: string, {from, to}, id?: string) => {
    id = id || keyGen()

    const path = `${alertValLoc(alert.itemType, alert.itemId)}/${alert.alertType}/dnd/days/${day}`

    await clientDb().update(makeAudit(user, {
        [`${path}/${id}`]: { from, to },
    }))
}

export const deleteDnd = (user: UserAuth) => async (alert: AlertKey, day: string, id: string) => {
    const path = `${alertValLoc(alert.itemType, alert.itemId)}/${alert.alertType}/dnd/days/${day}/${id}`

    await clientDb().update(makeAudit(user, {
        [path]: null,
    }))
}

// i really don't like this sub layer thing. fences are sturctured differently so we are using this.
// since fences can't get pushed from a tag then we should be okay for now with this approach not putting it into array
export const toggleAlerts = (user: UserAuth) => async (alerts: AlertKey[], on: boolean, subLayer?: string) => {
    let update = {}

    alerts.forEach(({itemType, itemId, alertType}) => {
        update[`${alertValLoc(itemType, itemId)}/${subLayer ? subLayer : ''}/${alertType}/on`] = on
    });


    await clientDb().update(makeAudit(user, update));
}

export const alertSettingVal = (user: UserAuth) => async (itemType, itemId, alertType, value, subLayer?: string) => {
    const path = `${alertValLoc(itemType, itemId)}/${alertType}/${subLayer || ''}/settings`

    await clientDb().update(makeAudit(user, {
        [path]: value,
    }))
}

export const toggleAlertNotifyPerson = (user: UserAuth) => async (alerts: AlertKey[], personId: string, newVal: boolean, subLayer?: string) => {
    let update = {};

    alerts.forEach(({itemType, itemId, alertType}) => {
        update[`${alertValLoc(itemType, itemId)}/${subLayer ? subLayer : ''}/${alertType}/people/${personId}`] = newVal;
    });

    await clientDb().update(makeAudit(user, update));
}

export const removeAlertNotifyCustom = (user: UserAuth) => async (alert: AlertKey, customKey: string, subLayer?: string) => clientDb().update(makeAudit(user, {
    [`${alertValLoc(alert.itemType, alert.itemId)}/${subLayer ? subLayer : ''}/${alert.alertType}/custom/${customKey}`]: null
}))

export const addAlertNofityCustom = (user: UserAuth) => async (alerts: AlertKey[], custom: string, subLayer?: string) => {
    let update = {};

    alerts.forEach(({itemType, itemId, alertType}) => {
        update[`${alertValLoc(itemType, itemId)}/${subLayer ? subLayer : ''}/${alertType}/custom/${keyGen()}`] = custom;
    });

    await clientDb().update(makeAudit(user, update));
}

export const watchEventValues = ({itemType, itemId, alertType}: AlertKey, callback: (deets: iEventInstance) => any) => {
    let ref = alertValRef(itemType, itemId);

    let wrapper = res => callback(res.val());

    ref.child(alertType).on("value", wrapper, err => console.log(err));

    return () => ref.child(alertType).off("value", wrapper);
}