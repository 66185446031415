import React, {useEffect, useRef} from 'react';
import {useRedux} from '../../../states/redux-state';
import {useDispatch, useSelector} from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchModal from '../../menus/search';
import {SearchInput} from '../../DashboardBlock';
import {Actions as GeneralActions} from '../../../stores/reducers/general-reducers';
import {iFullStoreState} from "../../../shared/interfaces";
import {DashboardSize} from "../../../stores/reducers/dashboardInfo";
import './style.scss';

const SearchMobile = () => {
    const dispatch = useDispatch();

    const ref = useRef(null);
    const size = useSelector<iFullStoreState, DashboardSize>((s) => s.dashboardInfo.size);
    let open = size !== DashboardSize.CLOSED;

    const showSearch = useRedux((s) => s.general.showSearch);

    useEffect(() => {
        dispatch(GeneralActions.SHOW_SEARCH);
        ref.current.scrollTop = 0;

        return () => {
            dispatch(GeneralActions.HIDE_SEARCH);
        };
    }, []);

    return (
        <div className={`dashboard-mobile-search-${showSearch && open ? 'open' : 'close'}`}>
            <SearchInput visibleInput={true} />
            <PerfectScrollbar className="scrollable-mobile" containerRef={(el) => ref.current = el}>
                <SearchModal style={{zIndex: '3'}}/>
            </PerfectScrollbar>
        </div>
    );
};

export default SearchMobile;
