import React, { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import { iFullStoreState, ItemType } from '../../../shared/interfaces';
import GeneralActions from "../../../stores/reducers/general-actions";
import { FaFav } from "../../elements/fa";

type FavoritePropsType = {
    favoriteType: ItemType;
    favoriteId: string;
}

type FavoriteSelectType = {
    userId: string;
    isFavorite: boolean;
}

const FavoriteBLL: FC<FavoritePropsType> = ({favoriteType, favoriteId}) => {
    const {
        userId,
        isFavorite
    } = useSelector<iFullStoreState, FavoriteSelectType>((s) => {
        return {
            userId: s.auth.user.uid,
            isFavorite: s.general.favorites?.[favoriteType]?.[favoriteId] ?? false
        }
    });

    const handleFavoriteClick = useCallback(() => {
        GeneralActions.toggleFavorite({favoriteType, favoriteId, uid: userId, isFavorite: !isFavorite});
    }, [favoriteId, isFavorite]);

    return (
        <button onClick={handleFavoriteClick}>
            <FaFav isFav={isFavorite} style={isFavorite ? {color: "rgb(247, 216, 45)"} : {color: "black"}}/>
        </button>
    )
}

export default FavoriteBLL;
