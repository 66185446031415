import React, { FC, useState } from "react";
import { getAuditLogTitles } from "./helper";

import Chevron from "./Chevron";
import AuditLogNestedList from "./AuditLogNestedList";

import styles from "./styles.module.scss";

interface IAuditReportProps {
  data: object;
}

const AuditReport: FC<IAuditReportProps> = ({ data }) => {
  const [activeTitle, setActiveTitle] = useState("");

  const titles = getAuditLogTitles(data);

  const handleClick = (title) => {
    if (activeTitle === title) {
      setActiveTitle("");

      return;
    }

    setActiveTitle(title);
  };

  return (
    !!titles.length && (
      <div className={styles.AuditReport}>
        <ul className={styles.titles}>
          {titles.map((title) => {
            const isActive = activeTitle === title;

            return (
              <li key={title} onClick={() => handleClick(title)}>
                <div
                  className={styles.title}
                  style={{ fontWeight: isActive && 600 }}
                >
                  <Chevron
                    isActive={isActive}
                    direction={isActive ? "down" : "right"}
                  />
                  <span>{title}</span>
                </div>

                {isActive && (
                  <AuditLogNestedList
                    data={data[title]}
                    name={title}
                    isFirstNest={true}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

export default AuditReport;
