import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { iReducersState } from "../../../../../../stores/reducers";
import {IS_MOBILE_APP} from "../../../../../../shared/constants";
import { flv, hls } from './videoConfigs';
import styles from "../../History/History.module.scss";
import videoStyles from './iosPlayer.module.scss';

const Player = ({url, isPlaying, setCurrentTime, setPlayedSeconds, updateTime, isControls, setIsPlaying }): JSX.Element => {
    const { modals } = useSelector((state: iReducersState) => state.videoCameras);
    const { openHistoryModal, openHistoryPlayer, openLiveViewModal, openLiveViewPlayer } = modals;
    const [loader, setLoader] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadedTime, setLoadedTime] = useState(0);

    const playerRef = useRef(null);

    const BUFFERING_TIME = +process.env.REACT_APP_BUFFERING_SECONDS;
    const LIVE_MODAL = isPlaying && (openLiveViewModal || openLiveViewPlayer);
    const HISTORY_MODAL = openHistoryModal || openHistoryPlayer;
    const HISTORY_BUFFERING = (HISTORY_MODAL && isPlaying) && (loadedTime >= BUFFERING_TIME)

    // @ts-ignore
    const isIosDevice = IS_MOBILE_APP && window.cordova.platformId === 'ios';

    useEffect(() => {
        if (!isIosDevice) return;
        let timerId;

        if (!isLoaded) {
            timerId = setTimeout(() => {
               playerRef && playerRef.current.load();
            }, 5000);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [isLoaded]);

    const handleLoadedData = () => {
        setIsLoaded(true);
        // playerRef.current.setAttribute('webkit-playsinline', '');
        playerRef.current.play();
    };

    const handleProgress = state => {
        setLoadedTime(Math.round(state.loadedSeconds));

        if(!modals.openContinueLiveStreamingModal && (LIVE_MODAL || HISTORY_BUFFERING)) {
            setLoader(false);
            setIsPlaying(true);
            setCurrentTime(state.playedSeconds);
            setPlayedSeconds(Math.round(state.playedSeconds));
        } else {
            setCurrentTime(0)
        }
    }

    const handleTimeUpdate = () => setLoadedTime(Math.round(playerRef.current.buffered.end(0)));

    const handleIosVideoProgress = () => {
        if(!modals.openContinueLiveStreamingModal && (LIVE_MODAL || HISTORY_BUFFERING)) {
            setLoader(false);
            setIsPlaying(true);
            setCurrentTime(playerRef.current.currentTime);
            setPlayedSeconds(Math.round(playerRef.current.currentTime));
        } else {
            setCurrentTime(0)
        }
    };

    const handlePlay = (): void => console.log('Play');
    const onError = (): void => console.error('Error');


    const checkFlvFormat = url.includes(".flv");
    const configSettings = checkFlvFormat ? flv : hls;

    return (
        <>
            {(loader && HISTORY_MODAL) && (
                <div className={styles.loader}>
                    <CircularProgress size={50} />
                </div>
            )}
            {isIosDevice ? (
                <div className={videoStyles.videoContainer}>
                    <video
                        ref={playerRef}
                        src={url}
                        onTimeUpdate={handleTimeUpdate}
                        onProgress={handleIosVideoProgress}
                        onLoadedData={handleLoadedData}
                        playsInline
                        webkit-playsinline
                        x5-playsinline
                        preload='auto'
                        autoPlay
                        controls
                        disablePictureInPicture
                    />
                </div>
            ) : (
                <ReactPlayer
                    ref={playerRef}
                    className='react-player'
                    width='100%'
                    height='100%'
                    url={url}
                    pip={false}
                    playing={HISTORY_BUFFERING || LIVE_MODAL ? true : false}
                    controls={isControls}
                    onProgress={handleProgress}
                    onPlay={handlePlay}
                    // onPause={handlePause}
                    // onBuffer={handleBuffer}
                    onError={onError}
                    config={configSettings}
                />
            )}
        </>
    )
}

export default Player;
