import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import moment from "moment";
import { useDispatch, useSelector} from "react-redux";
import { iDeviceDetails, iFullStoreState, /*iMaintenanceTask,*/ iLoadedFileInfo, LoadDocumentType, UserAuth } from "../../../../shared/interfaces";
// import { ReactComponent as TrackChangesIcon } from "../../../../assets/svg/track_changes.svg";
// import { ReactComponent as ScheduleIcon } from "../../../../assets/svg/schedule.svg";
// import { ReactComponent as CalendarIcon } from "../../../../assets/svg/calendar_today.svg";
import CostInput from '../CostInput';
import { GMapModalAC } from "../../../../stores/reducers/gMapModal/AC";
import { ActionsCreator } from "../../../../stores/reducers/maintenance/actions";
// import { selectMaintenance } from "../../../../stores/reducers/maintenance/selectors";
import { Button, ButtonSize, ButtonStyles } from "../../../Button";
import DateInput from "../../../elements/DateInput";
import Input from "../../../elements/Input";
import PerfectScrollbar from 'react-perfect-scrollbar';
import "./completeExtraTask.scss";
import { isMobile } from "../../../../shared/helpers";
import { validateCompleteTaskForm, validateSingleFieldCompleteTaskForm } from "./CompleteTaskFormValidation";
import MaintenanceTaskMultiselect from "../../../BLLComponents/MaintenanceTaskMultiselect";
import { selectDevices } from "../../../../stores/reducers/devicesData/selector";
import MultiSelect from "../../../MultiSelect/MultiSelect";
import UploadDocumentBlock from "../../reports/UploadDocumentBlock";

const CompleteExtraTask = () => {
    const dispatch = useDispatch();
    const fileSizeLimit = 10*1000*1000; // 10MB
    const filesMaxCount = 10;
    const [isDocsInLoad, updateDocsInLoad] = useState<boolean>(false);
    const [documents, updateDocuments] = useState<iLoadedFileInfo[]>([]);
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
    // const { deviceKey, maintenanceKey } = props;
    // const maintenance = useSelector<iFullStoreState, iMaintenanceTask>(s => selectMaintenance(s, deviceKey, maintenanceKey));
    // console.log(maintenance);
    // const [upcomingMaintenanceKey, upcomingMaintenance] = Object.entries(maintenance.mainUpcoming || {})[0];
    const [values, setValues] = useState({
        date: moment(new Date()),
        odometer: null,
        labourHours: null,
        cost: null,
        device: undefined,
        taskName: "",
    });
    const [errors, updateErrors] = useState({});
    const refContainer = useRef(null);
    const refDatePicker = useRef(null);
    const [needReset, updateNeedReset] = useState<boolean>(false);
    const [isDocsReset, updateIsDocsReset] = useState<boolean>(false);

    const closeModal = () => {
        dispatch(GMapModalAC.hideModal());
    };

    const showErrors = (errors) => {
        if (!errors || !Object.keys(errors).length) return;
        updateErrors(errors);
        // const newClass = (errors.date ? 'task-edit-modal-info-item-input-date-error ' : '') + dateInputClass;
        // updateClass(newClass);
    };

    const checkValidate = () => {
        return validateCompleteTaskForm(values);
    };

    const singleFieldValidation = (name) => {
        return validateSingleFieldCompleteTaskForm(name, values);
    };

    const handleBlur = ({target: {name}}: ChangeEvent<HTMLInputElement>) => {
        const {isValid, errors: newErrors} = singleFieldValidation(name);
        if (name === 'cost') {
            if (values.cost) {
                setValues({
                    ...values,
                    cost: Number(values.cost).toFixed(2)
                });
            }
        }
        if (isValid) {
            updateErrors({...errors, [name]: null});
        } else {
            updateErrors({...errors, ...newErrors});
        }
    };

    const handleDeviceBlur = () => {
        const {isValid, errors: newErrors} = singleFieldValidation('device');
        console.log("device", isValid);
        if (isValid) {
            updateErrors({...errors, 'device': undefined});
        } else {
            updateErrors({...errors, ...newErrors});
        }
    };

    const handleTaskBlur = () => {
        const {isValid, errors: newErrors} = singleFieldValidation('taskName');
        console.log("task", isValid)
        if (isValid) {
            updateErrors({...errors, 'taskName': undefined});
        } else {
            updateErrors({...errors, ...newErrors});
        }
    };

    const handleDateBlur = () => {
        const {isValid, errors: newErrors} = singleFieldValidation('date');
        if (isValid) {
            updateErrors({...errors, 'date': null});
        } else {
            updateErrors({...errors, ...newErrors});
        }
    };

    const handleInputChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    }

    const handleDateChange = (date) => {
        setValues({
            ...values,
            date
        })
    }

    const handleCancelClick = () => {
        updateNeedReset(true);
    }

    const onDocsReset = () => {
        updateIsDocsReset(true);
    }

    useEffect(() => {
        if (needReset && isDocsReset) {
            // updateNeedReset(false);
            // updateIsDocsReset(false);
            closeModal();
        }
    }, [needReset, isDocsReset])

    const makeTaskCompleted = () => {
        const {device, taskName, ...resValues} = values;
        const date = resValues?.date?.startOf("day").toDate().getTime() ||  moment(new Date).startOf("day").toDate().getTime();
        dispatch(ActionsCreator.completeExtraMaintenanceTask(user, {
            deviceKey: device.deviceKey,
            taskInfo: { date, deviceName: device.deviceName, hoursNeeded: "0", milesNeeded: "0", milesOffset: "0", hoursOffset: "0", taskName, upcomingHours: "0", upcomingMiles: "0"},
            taskCompleteInfo: {...resValues, date},
            extraTask: true,
            documents
        }));
    };

    const handleCompleteClick = () => {
        const {isValid, errors} = checkValidate();
        if (isValid) {
            makeTaskCompleted();
            closeModal();
        } else {
            showErrors(errors);
        }
    }

    const handleDeviceChange = (list) => {
        // @ts-ignore
        const device = Object.values(list)[0];
        // @ts-ignore
        setValues({...values, device: { deviceKey: device?.value, deviceName: device?.label}});
    }

    const handleTaskChange = (list) => {
        // @ts-ignore
        const taskName = list[0]?.label || "";
        // @ts-ignore
        setValues({...values, taskName});
    }


    const devices = useSelector<iFullStoreState, iDeviceDetails[]>(s => [...selectDevices(s).values()]);
    const devicesOptions = devices.map(device => ({value: device.id, label: device.name }));
    const jsx = (<>
        <div className="complete-task__info">
            <div className="complete-task__names--grow">
                <div className="complete-task__names-set">
                    <div className="complete-task__headers">Task</div>
                    <MaintenanceTaskMultiselect onClose={handleTaskBlur} multi={false} onChange={handleTaskChange} placeholder="Select task"/>
                    <p className="complete-task-form__error_special">{errors['taskName']}</p>
                </div>
                <div className="complete-task__names-set">
                    <div className="complete-task__headers">Unit</div>
                    <MultiSelect onClose={handleDeviceBlur} multi={false} placeholder="Select device" onChange={handleDeviceChange} options={devicesOptions}/>
                    <p className="complete-task-form__error_special">{errors['device']}</p>
                </div>
            </div>
            {/* <div className="complete-task__data">
                <div className="field-set">
                    <div className="field-set__header"><TrackChangesIcon className="complete-task__icons"/>Miles</div>
                    <div>{maintenance.miles_needed}</div>
                </div>
                <div className="field-set">
                    <div className="field-set__header"><ScheduleIcon className="complete-task__icons"/>Hours</div>
                    <div>{maintenance.hours_needed}</div>
                </div>
                <div className="field-set">
                    <div className="field-set__header"><CalendarIcon className="complete-task__icons"/>Date</div>
                    <div>{moment(maintenance.date).format("MM/DD/YY")}</div>
                </div>
            </div> */}
        </div>
        <hr/>
        <div className="complete-task-form">
            <div className="complete-task-form__row">
                <div className="complete-task-form__form-set" ref={refDatePicker}>
                    <div className="complete-task-form__headers">Date:</div>
                    <div className="complete-task-form__input">
                        <DateInput onClose={handleDateBlur} onDateChange={handleDateChange} defaultValue={values.date}
                                   closeOnDateSelect={true}/>
                    </div>
                </div>
                <div className="complete-task-form__form-set">
                    <div className="complete-task-form__headers">Odometer:</div>
                    <div className="complete-task-form__input">
                        <Input type="number" className="complete-task-form__input-field" name="odometer" placeholder="0" value={values.odometer}
                               onChange={handleInputChange} onBlur={handleBlur}/>
                    </div>
                </div>
            </div><p className="complete-task-form__error">{errors['date']} {errors['odometer']}</p>
            <div className="complete-task-form__row">
                <div className="complete-task-form__form-set">
                    <div className="complete-task-form__headers">Labour Hours:</div>
                    <div className="complete-task-form__input">
                        <Input type="number" className="complete-task-form__input-field" name="labourHours" placeholder="0" value={values.labourHours}
                               onChange={handleInputChange} onBlur={handleBlur}/>
                    </div>
                </div>
                <div className="complete-task-form__form-set">
                    <div className="complete-task-form__headers">Cost:</div>
                    <div className="complete-task-form__input">
                        <CostInput maskOptions={{}} className="input-plaine complete-task-form__input-field" name="cost" placeholder="0" value={values.cost}
                               onChange={handleInputChange} onBlur={handleBlur}/>
                    </div>
                </div>
            </div>
            <p className="complete-task-form__error">{errors['labourHours']} {errors['cost']} </p>
            <div className="complete-task-form__row">
                <div className="complete-task-form__form-set complete-task-form__form-set-full-width">
                    <div className="complete-task-form__headers">Documents:</div>
                    <div className="complete-task-form__input">
                        <UploadDocumentBlock onChange={updateDocuments} fileSizeLimit={fileSizeLimit} filesMaxCount={filesMaxCount} setInLoad={updateDocsInLoad} loadType={LoadDocumentType.UPLOAD} needReset={needReset} onReset={onDocsReset}/>
                    </div>
                </div>
            </div>
        </div>
    </>)

    return (
        <div className="complete-task" ref={refContainer}>
            <div className="complete-task__header">Complete task</div>
            <div className="complete-task__scrollable">
                {
                    isMobile
                        ? (
                            <PerfectScrollbar>
                                {jsx}
                            </PerfectScrollbar>
                        )
                        : jsx
                }
            </div>
            <div className="complete-task__actions">
                <Button styleType={ButtonStyles.WHITE_GRAY} disabled={isDocsInLoad} size={ButtonSize.MD}
                        onClick={handleCancelClick}>Cancel</Button>
                <Button styleType={ButtonStyles.ORANGE_WHITE} disabled={isDocsInLoad} size={ButtonSize.MD}
                        onClick={handleCompleteClick}>Complete</Button>
            </div>
        </div>
    )
}

export default CompleteExtraTask;
