import React from "react";
// import styles from "../../CompleteExtraTask/completeExtraTask.scss";
import { ReactComponent as TrackChangesIcon } from '../../../../../assets/svg/track_changes.svg';
import { ReactComponent as ScheduleIcon } from '../../../../../assets/svg/schedule.svg';
import { ReactComponent as CalendarIcon } from '../../../../../assets/svg/calendar_today.svg';
import moment from 'moment';
import DateInput from '../../../../elements/DateInput';
import Input from '../../../../elements/Input';
import UploadDocumentBlock from '../../../reports/UploadDocumentBlock';
import { LoadDocumentType } from '../../../../../shared/interfaces';
import CostInput from "../../CostInput";

const CompleteTaskFormJSX = (
  {
    taskInfo: {
      taskName,
      deviceName,
      milesNeeded,
      hoursNeeded,
      date,
    },
    refDatePicker,
    handleDateBlur,
    handleDateChange,
    dateInputValue,
    odometerValue,
    handleInputChange,
    handleBlur,
    labourHoursValue,
    costValue,
    errors,
    documentsProps: {
      updateDocuments,
      fileSizeLimit,
      filesMaxCount,
      updateDocsInLoad,
      needReset,
      onDocsReset,
    }
  }) => (<>
  <div className="complete-task__info">
    <div className="complete-task__names">
      <div className="complete-task__names-set">
        <div className="complete-task__headers">Task</div>
        <div className="complete-task__names-set">{taskName}</div>
      </div>
      <div className="complete-task__names-set">
        <div className="complete-task__headers">Unit</div>
        <div className="complete-task__description">{deviceName}</div>
      </div>
    </div>
    <div className="complete-task__data">
      <div className="field-set">
        <div className="field-set__header"><TrackChangesIcon className="complete-task__icons"/>Miles</div>
        <div>{milesNeeded}</div>
      </div>
      <div className="field-set">
        <div className="field-set__header"><ScheduleIcon className="complete-task__icons"/>Hours</div>
        <div>{hoursNeeded}</div>
      </div>
      <div className="field-set">
        <div className="field-set__header"><CalendarIcon className="complete-task__icons"/>Date</div>
        <div>{moment(date).format("MM/DD/YY")}</div>
      </div>
    </div>
  </div>
  <hr/>
  <div className="complete-task-form">
    <div className="complete-task-form__row">
      <div className="complete-task-form__form-set" ref={refDatePicker}>
        <div className="complete-task-form__headers">Date:</div>
        <div className="complete-task-form__input">
          <DateInput onClose={handleDateBlur} onDateChange={handleDateChange} defaultValue={dateInputValue}
                     closeOnDateSelect={true}/>
        </div>
      </div>
      <div className="complete-task-form__form-set">
        <div className="complete-task-form__headers">Odometer:</div>
        <div className="complete-task-form__input">
          <Input type="number" className="complete-task-form__input-field" placeholder="0" name="odometer" value={odometerValue}
                 onChange={handleInputChange} onBlur={handleBlur}/>
        </div>
      </div>
    </div>
    <p className="complete-task-form__error">{errors['date']} {errors['odometer']}</p>
    <div className="complete-task-form__row">
      <div className="complete-task-form__form-set">
        <div className="complete-task-form__headers">Labour Hours:</div>
        <div className="complete-task-form__input">
          <Input type="number" className="complete-task-form__input-field" placeholder="0" name="labourHours" value={labourHoursValue}
                 onChange={handleInputChange} onBlur={handleBlur}/>
        </div>
      </div>
      <div className="complete-task-form__form-set">
        <div className="complete-task-form__headers">Cost:</div>
        <div className="complete-task-form__input">
          <CostInput maskOptions={{}} className="complete-task-form__input-field" placeholder="0" name="cost" value={costValue}
                 onChange={handleInputChange} onBlur={handleBlur}/>
        </div>
      </div>
    </div>
    <p className="complete-task-form__error">{errors['labourHours']} {errors['cost']}</p>

    <div className="complete-task-form__row">
      <div className="complete-task-form__form-set complete-task-form__form-set-full-width">
        <div className="complete-task-form__headers">Documents:</div>
        <div className="complete-task-form__input">
          <UploadDocumentBlock onChange={updateDocuments} fileSizeLimit={fileSizeLimit} filesMaxCount={filesMaxCount} setInLoad={updateDocsInLoad} loadType={LoadDocumentType.UPLOAD} needReset={needReset} onReset={onDocsReset}/>
        </div>
      </div>
    </div>
  </div>
</>);

export default CompleteTaskFormJSX;
