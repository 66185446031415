//@ts-nocheck
import React, {FC, ChangeEvent, useEffect, useRef, useState} from 'react';
import { ReactComponent as Add } from "../../assets/svg/plus.svg";
import "./index.scss";
import { ReactComponent as ChevronSvg } from '../../assets/svg/chevron_left.svg';
import { ReactComponent as CheckSvg } from '../../assets/svg/check_24px.svg';
import useClickOutside from "../../hooks/useClickOutside";
import {Button, ButtonStyles} from '../Button';
import PerfectScrollbar from "react-perfect-scrollbar";

// helper
const convertToObject = (options, activeOptions) => {
	// all items list obj
	const allOptions = options.reduce((prev, curr) => ({...prev, [curr.value]: {...curr, checked: false}}),{});
	// all active items list obj
	const allActiveOptions = activeOptions.reduce((prev, curr) => ({...prev, [curr.value]: {...curr, checked: true}}),{});

	// obj with updated state of every item
	return {...allOptions, ...allActiveOptions};
}

const getActiveListItems = (list) => Object.values(list).filter(item => item.checked);

const showAll = (elements) => {
	for (let key of Object.keys(elements)) {
		elements[key].show = true;
	}
	return elements;
};

interface iMultiSelect {
	placeholder?: string;
	defaultValue?: {value: string, label: string}[];
	options?: {value: string, label: string}[];
	onChange?: Function;
	onClose?: Function;
	shouldClear?: boolean;
	onNewValueAdd?: Function;
	multi: boolean;
	addCustomField?: boolean;
}

const MultiSelect: FC<iMultiSelect> = ({addCustomField = false, placeholder, defaultValue = [], options = [], onChange, onClose, shouldClear = false, onNewValueAdd, multi = true}) => {
	const initValue = convertToObject(options, defaultValue);
	const [opened, setOpened] = useState(false);
	const [items, setItems] = useState(showAll(initValue));
	const [input, setInput] = useState("");
	const bodyRef = useRef(null);
	const inputRef = useRef(null);

	const closeList = () => {
		if (opened) {
			const activeItemList = Object.values({...items}).filter(el => el.checked).map(el => ({label: el.label, value: el.value}));
			setOpened(false);
			onClose?.(activeItemList);
		}
	};
	useClickOutside({targetRef: bodyRef, callback: closeList})

	const toggleList = () => {
		opened ? closeList() : setOpened(true);
	}

	const resetItemsIfNeeded = () => {
		if (shouldClear) {
			const newItemsValue = showAll(convertToObject(options, defaultValue));
			setItems(newItemsValue);
		}
	}
	useEffect(resetItemsIfNeeded, [shouldClear]);

	const focusInputIfOpened = () => {
		if (opened) inputRef.current?.focus();
	}
	useEffect(focusInputIfOpened, [opened]);

	const handleItemClick = (key) => {
		const newState = {...items, [key]: {...items[key], checked: !items[key].checked} };
		const activeItemList = Object.values(newState).filter(el => el.checked).map(el => ({label: el.label, value: el.value}));

		if (multi) {
			setItems(showAll(newState));
			onChange?.(activeItemList);
		} else {
			const defaultState = convertToObject(options, defaultValue);
			defaultState[key].checked = true;
			onChange?.(getActiveListItems(defaultState));
			setItems(showAll(defaultState));
		}
		setInput("");
	}

	const handleInputChange = ({target: { value }}: ChangeEvent) => {
		const newItems = {...items};

		const checkItem = (itm, check) => {
			const checkValue = check.toLowerCase();
			const labelMatch = itm.label.toLowerCase().includes(checkValue);
			const valueMatch = itm.value.toLowerCase().includes(checkValue);

			return labelMatch || valueMatch;
		};

		const setVisible = (elements, testingValue) => {
			for (let key of Object.keys(elements)) {
				elements[key] = {...elements[key], show: checkItem(elements[key], testingValue)};
			}
			return elements;
		};

		if (value) {
			setItems(setVisible(newItems, value));
		} else {
			setItems(showAll(newItems));
		}

		setInput(value);
	}

	const handleAddBtnClick = () => {
		if (!input) return;
		let newList;
		if (multi) {
			const newItems = showAll({...items, [input]: {
					value: input,
					label: input,
					checked: true
				}});
			setItems(newItems);
			newList = newItems;
		} else {
			const defaultState = convertToObject(options, [...defaultValue, {
				value: input, label: input, checked: true
			}]);
			const allItemsVisible = showAll(defaultState);
			setItems(allItemsVisible);
			newList = allItemsVisible;
		}
		setInput("");
		const checkedItems = Object.values(newList || {}).filter(itm => itm.checked);
		onNewValueAdd?.(input, checkedItems);
	}

	const activeItems =  Object.values(items).filter(el => el.checked);
	const title = !!activeItems.length ? activeItems.map(el => el.label).join('; ') : placeholder;

	const RenderItems = ({items}) => {
		const newItems = Object.values(items || {});
		if (newItems.length) {
			return newItems.map((el) => (
				<div className={`item ${el.checked ? 'chosen' : ''}`} key={el.value}
					 onClick={() => handleItemClick(el.value)}>
					<li>
						{el.label}
					</li>
					<CheckSvg />
				</div>
			))
		} else {
			return <p className="multi-select__no-items-placeholder">No items found</p>
		}
	};

	return (
		<div className="multi-select" ref={bodyRef}>
			<div className={`multi-select_block ${opened ? 'open' : ''}`}>
				{
					!opened?
						(<p onClick={toggleList} className="chosen">{title}</p>)
						:
						( addCustomField? (
							<div className="multi-select__chosen">
								<Button className="multi-select__add-btn" onClick={handleAddBtnClick} styleType={ButtonStyles.NONE}><Add className="multi-select__add-icon"/></Button>
								<input ref={inputRef} className="multi-select__input" placeholder="Add task..." onChange={handleInputChange} type="text" value={input}/>
							</div>
						) : (
							<p onClick={toggleList} className="chosen">{title}</p>
							)
						)
				}
				<div className="svg" onClick={toggleList}>
					<ChevronSvg/>
				</div>
			</div>
			<div className={`multi-select_list ${opened ? 'open' : ''}`}>
				<PerfectScrollbar>
					<ul>
						<RenderItems items={items} />
					</ul>
				</PerfectScrollbar>
			</div>
			{opened && (<button className="closeButton" onClick={() => setOpened(false)}>Done</button>)}
		</div>
	)
}

export default MultiSelect;