import { Map } from 'immutable';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { csvExporter } from "../../../../shared/csv";
import { countDateWithUserAndTimezoneOffset } from '../../../../shared/helpers';
import { iPerson, iList, iDevicePingWithGeofences, iDeviceDetails, ItemType } from "../../../../shared/interfaces";
import {utils, writeFileXLSX} from "xlsx";

export const exportGeofenceReportPdf = (
    {
        geofenceReportsGrouped,
        people,
        devices,
        deviceOrPerson
    }:
    {
        geofenceReportsGrouped: iList<iDevicePingWithGeofences[]>,
        people: iList<iPerson>,
        devices: Map<string, iDeviceDetails>,
        deviceOrPerson: ItemType.device | ItemType.person
    }
) => {
    const pdf = new jsPDF();

    Object.entries(geofenceReportsGrouped).map(([key, report]) => {
        const name = deviceOrPerson === ItemType.device? devices.getIn([key, "name"]): people[key]?.displayName;
        const records = report.reduce((red, record) =>
            [...red, ...Object.entries(record.geofences)
                .map(([name, status]) =>{
                        const timezone = devices.getIn([key, "timezone"]);
                        const time = countDateWithUserAndTimezoneOffset(record.time, timezone);
                        return [name, time.format("MM-DD-YYYY hh:mmA"), status? "Entered": "Exited"]
                    })
            ], []);
        autoTable(pdf, {
            head: [[name, "", ""]],
            body: records
        })
    })

    pdf.save("GeofenceReport.pdf");
}

export const exportGeofenceReportXlsx = (
    {
        geofenceReportsGrouped,
        people,
        devices,
        deviceOrPerson
    }:
    {
        geofenceReportsGrouped: iList<iDevicePingWithGeofences[]>,
        people: iList<iPerson>,
        devices: Map<string, iDeviceDetails>,
        deviceOrPerson: ItemType.device | ItemType.person
    }
) => {
    const table = Object.entries(geofenceReportsGrouped).map(([key, report]) => {
        const name = deviceOrPerson === ItemType.device ? devices.getIn([key, "name"]) : people[key]?.displayName;
        return report.reduce((red, record) =>
            [...red, ...Object.entries(record.geofences)
                .map(([name, status]) => {
                    const timezone = devices.getIn([key, "timezone"]);
                    const time = countDateWithUserAndTimezoneOffset(record.time, timezone);
                    return {
                        name,
                        geofence: '',
                        timestamp: time.format("MM-DD-YYYY hh:mmA"),
                        status: status ? "Entered" : "Exited",
                    }
                })
            ], []);
    })
    const worksheet = utils.json_to_sheet(table);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "summary");
    writeFileXLSX(workbook, "ReportGeofence.xlsx");
}

export const exportGeofenceReportCsv =  (
    {
        geofenceReportsGrouped,
        people,
        devices,
        deviceOrPerson
    }:
    {
        geofenceReportsGrouped: iList<iDevicePingWithGeofences[]>,
        people: iList<iPerson>,
        devices: Map<string, iDeviceDetails>,
        deviceOrPerson: ItemType.device | ItemType.person
    }
) => {
    const records = Object.entries(geofenceReportsGrouped).reduce((red, [key, report]) => {
        const name = deviceOrPerson === ItemType.device? devices.getIn([key, "name"]): people[key]?.displayName;
        return [...red, ...report.reduce((red, record) =>
            [...red, ...Object.entries(record.geofences).map(([name, status]) => {
                const timezone = devices.getIn([key, "timezone"]);
                const time = countDateWithUserAndTimezoneOffset(record.time, timezone);
                return [name, time.format("MM-DD-YYYY hh:mm A"), status? "Entered": "Exited"];
            })], [])]
    }, []);
    csvExporter("GeofenceReport.csv")([
        ["Name", "Geofence", "Timestamp", "Status"],
        ...records
    ])
}