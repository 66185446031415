import React, { FC, HTMLProps } from "react";
import "./index.scss";

import { Icon } from "../../general/Icon";

interface iProps extends HTMLProps<HTMLDivElement> {
    itemId?: string,
    url?: any,
    fa?: any,
    title?: any,
    active?: any
}

const SelectorLabel: FC<iProps> = ({itemId, active, className, fa, url, title, ...props}) => {
    return (
        <div key={itemId} className={`selector-label ${active? "selector-label--active" : ""} ${className}`} {...props}>
            <div className={`selector-label__image`}><Icon fa={fa} url={url} /></div>
            <div className="selector-label__label"><span>{title}</span></div>
        </div>
    )
}

export default SelectorLabel;