import React from 'react';
import Modal from "../../../Modal";
import History from "./History";
import RenderPlayers from "./RenderPlayers";
import AvailableTime from "./AvailableTime";
import styles from "./deviceStatuses.module.scss";

const HistoryModal = ({
    openHistoryPlayer,
    viewHistoryVideo,
    count,
    closeModal,
    serial,
    firebaseDeviceId,
    setCount,
    totalSeconds,
    AvailableTimeProps,
    RenderPlayersProps,
    loadingButton,
    selectedHistoryChannel,
    setSelectedHistoryChannel,
    setPrevHistoryTime,
    prevHistoryTime,
    setAlertTimestamp,
}) => {
    return (
        <Modal className={`${styles.modal} ${!openHistoryPlayer && styles.historyWidth}`}>
            <div className={styles.modalChannels}>
                <div className={styles.buttonClose}>
                    <div className={styles.modal_header}>
                        <h6 className={styles.modal_header__title}>History</h6>
                        {(count > 0 && !openHistoryPlayer) && <span className={styles.modal_header__count}>{count}</span>}
                    </div>
                    <button className={styles.modal_header__close} onClick={closeModal}>X</button>
                </div>
                {!openHistoryPlayer ? (
                    <History
                        serial={serial}
                        firebaseDeviceId={firebaseDeviceId}
                        setCount={setCount}
                        loadingButton={loadingButton}
                        viewHistoryVideo={viewHistoryVideo}
                        selectedHistoryChannel={selectedHistoryChannel}
                        setSelectedHistoryChannel={setSelectedHistoryChannel}
                        setPrevHistoryTime={setPrevHistoryTime}
                        prevHistoryTime={prevHistoryTime}
                        setAlertTimestamp={setAlertTimestamp}
                    />
                ) : (
                    <RenderPlayers {...RenderPlayersProps} totalSeconds={totalSeconds} isLiveView={false}/>
                )
                }
                <AvailableTime {...AvailableTimeProps}/>
            </div>
        </Modal>
    );
};

export default HistoryModal;
