import React from 'react';
import classNames from "classnames";
import styles from './CancelSwitcher.module.scss';

const CancelSwitcher = ({showPlan}) => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.switchBlock}>
                <div className={styles.switchHeader}>
                    <span>Switch to basic service</span>
                </div>
                <div className={styles.switchContent}>
                    <h5>Before you suspend service and lose track of your device,
                        we’d like you to consider the value and advantages of the low cost
                        Basic Service with Theft Recovery feature.
                    </h5>
                    <p>Basic with Theft Recovery: $10.99 per month or $109.00 per year (Avg. $9.08 per mo).</p>
                    <h4>What you get: </h4>
                    <ul>
                        <li>View the time, date and location of all of the day’s ignition events.</li>
                        <li>View the time, date and location of your vehicle should it be towed.</li>
                        <li>Set up an immediate text message or email notification if the vehicle is towed</li>
                        <li>Events are transmitted live, as they occur and displayed live on your tracking map.</li>
                        <li>A Daily Heartbeat with location information will confirm your device is still connected and working even when there is no vehicle use for the day.</li>
                    </ul>
                    <h4>Theft Recovery:</h4>
                    <p>Even a fully insured vehicle may not merit the full value of the vehicle and any insurance payout will be reduced by your deductible resulting in a financial loss. The Theft Recovery feature allows you to Instantly track a stolen or missing vehicle every 1 minute as it travels for up to 24 hours allowing for a quick recovery and helping to avoid the financial loss.</p>
                    <p>Insurance Discount may completely offset the minimal cost of the Basic subscription.
                        You may save more in auto insurance than the Basic service actually costs. Save up to 25% or more off the comprehensive portion of many auto premiums. (Contact your auto insurance provider to determine your savings)
                    </p>
                    <button
                        onClick={() => showPlan(true)}
                        className={classNames(styles.actionButton, styles.switchBtn)}
                        type="button"
                    >
                        Switch to basic plan
                    </button>
                </div>
            </div>
            <div className={styles.cancelBlock}>
                <div className={styles.switchHeader}>
                    <span>Cancel service</span>
                </div>
                <div className={styles.cancelContent}>
                    <h5>Thank you for being a valued customer.
                        By clicking below you agree to cancel service for this device.
                        Your request to cancel is processed immediately.
                    </h5>
                    <p>Once service is cancelled, no further charges will be made to your account for this device unless you reactivate service.
                        Service will automatically expire at the end of the current billing cycle,
                        and you will no longer be able to track or access information.
                    </p>
                    <p>All account changes are subject to the MasTrack.com terms of service.</p>
                    <button className={classNames(styles.actionButton, styles.cancelBtn)} type="button">Cancel service on this device</button>
                </div>
            </div>
        </div>
    )
};

export default CancelSwitcher;
