import React, { useEffect, useState } from 'react';

import { isMobile } from '../../../../shared/helpers';
import { Button, ButtonSize, ButtonStyles } from '../../../Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ItemType } from '../../../../shared/interfaces';
import { ReportFiltersLabels } from '../../../ReportFiltersLabels';
import {
  GMapModalAC,
  GMapModalContent,
} from '../../../../stores/reducers/gMapModal/AC';
import { OptionElement, SelectElement } from '../../../SelectElement';
import update from 'immutability-helper';
import { Calendar, MonthDay, WeekDays } from '../create-report';
import './UpdateReport.scss';
import { equals } from 'ramda';
import { FilterType } from '../ReportPageFilters.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { ReportAC, Actions as ReportActions } from '../../../../stores/reducers/report-reducers';
import { Fa } from '../../../elements/fa';
import { faCircle } from '@fortawesome/fontawesome-free-solid';
import SheduleFiltersEditor from '../../../SheduleFiltersEditor/SheduleFiltersEditor';
import { ReactComponent as FilterListSvg } from "../../../../assets/svg/filter_list.svg";
import { selectCustomEmailsToSendReport, selectPeopleToSendEmail } from '../../../../stores/reducers/report-selectors';

const initialReport = {
  type: 'travel',
  deliverySchedule: {
    schedule: 'monthly',
    frequencyDays: {},
    frequencyMonths: { month: 0, day: 1 },
  },
  frequency: 'monthly',
  format: 'pdf',
  reportRangeDays: 1,
};

const UpdateReport = ({
  reportData,
  id,
  onClose,
  onSubmit,
  refreshReports,
}) => {
  const [report, setReport] = useState(reportData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isActivityReportType = report.type === 'activity';

  const filters = useSelector((state) => state.report.details.filters);

  const state = useSelector((state) => state);
  const people = useSelector((state) => state.general.people);

  const peopleToSendReport = selectPeopleToSendEmail(state);
  const customEmailsToSendReport = selectCustomEmailsToSendReport(state);

  const dispatch = useDispatch();

  useEffect(() => {
    const filters = {};

    report.filters?.[FilterType.ALERTS] && (filters[FilterType.EDIT_ALERTS] = report.filters?.[FilterType.ALERTS]);
    report.filters?.[FilterType.DEVICES] && (filters[FilterType.EDIT_DEVICES] = report.filters?.[FilterType.DEVICES]);
    report.filters?.[FilterType.LABELS] && (filters[FilterType.EDIT_LABELS] = report.filters?.[FilterType.LABELS]);
    report.filters?.[FilterType.PEOPLE] && (filters[FilterType.EDIT_PEOPLE] = report.filters?.[FilterType.PEOPLE]);
    report.filters?.[FilterType.TAGS] && (filters[FilterType.EDIT_TAGS] = report.filters?.[FilterType.TAGS]);

    const sentToPeopleIds = reportData?.sendTo?.people;
    const sentToEmails = reportData?.sendTo?.emails;

    if (sentToPeopleIds?.length) {
      sentToPeopleIds.forEach(key => dispatch(ReportActions.TOGGLE_PEOPLE_SEND_TO_REPORT(people[key])));
    }

    if (sentToEmails?.length) {
      sentToEmails.forEach(email => dispatch(ReportActions.ADD_SEND_TO_REPROT_EMAIL(email)));
    }

    dispatch(ReportAC.SET_ALL_FILTERS(filters));
    
    return () => {
      dispatch(GMapModalAC.hideModal({contentType: GMapModalContent.SELECT_SEND_TO}));
      dispatch(ReportActions.RESET_ALL_SEND_INFO());
    }
  }, []);

  const hasDeliveryOptions = ['anually', 'monthly', 'weekly'].some(
    equals(report.deliverySchedule.schedule)
  );

  const hideMonth = report.deliverySchedule.schedule === 'monthly';

  const updateReport = async () => {
    const { format, type, deliverySchedule, reportRangeDays, label } = report;

    const updatedReport = {
      deliverySchedule: {
        ...(deliverySchedule.frequencyMonths !== undefined && {frequencyMonths: deliverySchedule.frequencyMonths}),
        ...(deliverySchedule.frequencyDays !== undefined && {frequencyDays: deliverySchedule.frequencyDays}),
        schedule: deliverySchedule.schedule,
      },
      format,
      label,
      reportRangeDays,
      type,
      filters: {},
      sendTo: {
        people: Object.values(peopleToSendReport).map(p => p.id),
        emails: customEmailsToSendReport,
      }
    };


    filters?.[FilterType.EDIT_ALERTS] && (updatedReport.filters[FilterType.ALERTS] = filters?.[FilterType.EDIT_ALERTS]);
    filters?.[FilterType.EDIT_DEVICES] && (updatedReport.filters[FilterType.DEVICES] = filters?.[FilterType.EDIT_DEVICES]);
    filters?.[FilterType.EDIT_LABELS] && (updatedReport.filters[FilterType.LABELS] = filters?.[FilterType.EDIT_LABELS]);
    filters?.[FilterType.EDIT_PEOPLE] && (updatedReport.filters[FilterType.PEOPLE] = filters?.[FilterType.EDIT_PEOPLE]);
    filters?.[FilterType.EDIT_TAGS] && (updatedReport.filters[FilterType.TAGS] = filters?.[FilterType.EDIT_TAGS]);

    await onSubmit(id, updatedReport);
    onClose();
    await refreshReports();

    console.log('we write we get', updatedReport);
  };

  const openFilterEditModal = () => {
    setIsModalOpen(true);
  }

  const closeFilterEditModal = () => {
    setIsModalOpen(false);
  }

  return (
    <div style={{ overflowY: 'scroll' }}>
      <div className={`update-report-container dashboard-open`}>
        <PerfectScrollbar>
          <div className={`update-report dashboard-open`}>
            {(isMobile || window['cordova']) && <h5>Update report</h5>}
            <div className={'update-report-col update-report-details'}>
              {!(isMobile || window['cordova']) && (
                <div className='update-report-row'>
                  <div className='update-report-header'>
                    <h5>Update report:</h5>
                  </div>
                </div>
              )}
              <div className={'update-report-row'}>
                <div className='update-report-row-title'>Type</div>
                <div className={'update-report-row-value'}>
                  <Button
                    styleType={
                      report.format === 'pdf'
                        ? ButtonStyles.BLACK_WHITE
                        : ButtonStyles.GRAY_INACTIVE
                    }
                    size={ButtonSize.MD}
                    title={'PDF'}
                    onClick={() =>
                      setReport((prevState) => ({
                        ...prevState,
                        format: 'pdf',
                      }))
                    }
                  >
                    <b>PDF</b>
                  </Button>
                  <Button
                    styleType={
                      report.format === 'csv'
                        ? ButtonStyles.BLACK_WHITE
                        : ButtonStyles.GRAY_INACTIVE
                    }
                    size={ButtonSize.MD}
                    title={'CSV'}
                    onClick={() =>
                      setReport((prevState) => ({
                        ...prevState,
                        format: 'csv',
                      }))
                    }
                  >
                    <b>CSV</b>
                  </Button>
                </div>
              </div>

              <div className='update-report-row'>
                <div className='update-report-row-title'>Filters</div>
                <div className='update-report-row-value'>
                  <Button
                      styleType={ButtonStyles.BLACK_WHITE}
                      size={ButtonSize.SM}
                      onClick={openFilterEditModal}
                  >
                      <span className={"align-center"}><FilterListSvg className="icon-md" style={{ marginRight: "5px" }} />Set</span>
                  </Button>
                </div>
              </div>

              {!isActivityReportType && <div className='update-report-row'>
                <div className='update-report-row-title'>Alerts</div>
                <div className='update-report-row-value update-report-row-value--clickable'
                     onClick={openFilterEditModal}>
                  <ReportFiltersLabels
                      type={FilterType.EDIT_ALERTS}
                      render={({item}) => <p>{item}</p>}
                      defaultLabel={'All filters'}
                  />
                </div>
              </div>}

              {!isActivityReportType && <div className='update-report-row'>
                <div className='update-report-row-title'>Labels</div>
                <div className='update-report-row-value update-report-row-value--clickable'
                     onClick={openFilterEditModal}>
                  <ReportFiltersLabels
                      type={FilterType.EDIT_LABELS}
                      render={({item}) => <p>{item}</p>}
                      defaultLabel={'All labels'}
                  />
                </div>
              </div>}
              <div className='update-report-row'>
                <div className='update-report-row-title'>Tags</div>
                <div className='update-report-row-value update-report-row-value--clickable' onClick={openFilterEditModal}>
                  <ReportFiltersLabels
                    type={FilterType.EDIT_TAGS}
                    render={({ item }) => <p>{item}</p>}
                    defaultLabel={'All tags'}
                  />
                </div>
              </div>

              <div className='update-report-row'>
                <div className='update-report-row-title'>Devices</div>
                <div className='update-report-row-value update-report-row-value--clickable' onClick={openFilterEditModal}>
                  <ReportFiltersLabels
                    type={FilterType.EDIT_DEVICES}
                    render={({ item }) => <p>{item}</p>}
                    defaultLabel={'All devices'}
                  />
                </div>
              </div>

              {!isActivityReportType && <div className='update-report-row'>
                <div className='update-report-row-title'>People</div>
                <div className='update-report-row-value update-report-row-value--clickable'
                     onClick={openFilterEditModal}>
                  <ReportFiltersLabels
                      type={FilterType.EDIT_PEOPLE}
                      render={({item}) => <p>{item}</p>}
                      defaultLabel={'All persons'}
                  />
                </div>
              </div>}

              <div className="update-report-row">
                <div className="update-report-row-title">Send to</div>
                <div className="update-report-row-value">
                    <div className="update-report-row-label">
                        {[...Object.values(peopleToSendReport).map(p => p.displayName), ...customEmailsToSendReport].join(", ")}
                    </div>
                    <Button className="update-repport-add-send-to-btn" styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD} onClick={() =>{
                        dispatch(GMapModalAC.showModal({contentType: GMapModalContent.SELECT_SEND_TO}));
                    }}>Add</Button>
                </div>
              </div>
            </div>

            <div className='update-report-col update-report-select'>
              {!hideMonth ? null : (
                <div className={'update-report-note'}>
                  <b>Note:</b> Picking a day which a month doesn't contain, will
                  cause that month's report to not be delivered. (eg. Feb 31st)
                </div>
              )}
              <div>
                <div className={'update-report-select-section-title'}>
                  Delivery
                </div>
                <SelectElement
                  options={[
                    { value: 'weekly', text: 'Weekly' },
                    { value: 'monthly', text: 'Monthly' },
                    { value: 'daily', text: 'Daily' },
                    { value: 'anually', text: 'Anually' },
                  ]}
                  value={report.deliverySchedule.schedule}
                  Component={OptionElement}
                  onChange={(event) =>
                    setReport((prevState) => ({
                      ...prevState,
                      deliverySchedule: {
                        ...prevState.deliverySchedule,
                        schedule: event.target.value,
                      },
                    }))
                  }
                />
              </div>
              {hasDeliveryOptions && (
                <div className='update-report-select-section'>
                  <div className={'update-report-select-section-title'}>
                    Send report on
                  </div>
                  {report.deliverySchedule.schedule === 'weekly' && (
                    <WeekDays
                      selected={Object.keys(
                        report.deliverySchedule.frequencyDays
                      )
                        .filter(
                          (key) => report.deliverySchedule.frequencyDays[key]
                        )
                        .map(Number)}
                      onChange={(dayNumber) =>
                        setReport((prevState) =>
                          update(prevState, {
                            deliverySchedule: {
                              frequencyDays: { $toggle: [dayNumber] },
                            },
                          })
                        )
                      }
                    />
                  )}

                  {(report.deliverySchedule.schedule === 'anually' ||
                    report.deliverySchedule.schedule === 'monthly') && (
                    <MonthDay
                      day={report?.deliverySchedule?.frequencyMonths?.day}
                      month={report?.deliverySchedule?.frequencyMonths?.month}
                      hideMonth={hideMonth}
                      onChange={(event) =>
                        setReport((prevState) => ({
                          ...prevState,
                          deliverySchedule: {
                            ...prevState.deliverySchedule,
                            frequencyMonths: {
                              day: event.day,
                              month: event.month,
                            },
                          },
                        }))
                      }
                    />
                  )}
                </div>
              )}
              <div title='Range' className='update-report-select-section'>
                <div className={'update-report-select-section-title'}>
                  Range
                </div>
                <SelectElement
                  options={[
                    { value: 'weekly', text: 'Weekly' },
                    { value: 'monthly', text: 'Monthly' },
                    { value: 'daily', text: 'Daily' },
                    { value: 'anually', text: 'Anually' },
                  ]}
                  value={report.frequency}
                  Component={OptionElement}
                  onChange={(event) =>
                    setReport((prevState) => ({
                      ...prevState,
                      frequency: event.target.value,
                      reportRangeDays: 1,
                    }))
                  }
                />
              </div>
              <div>
                <Calendar
                  type={report.frequency}
                  dayCount={report.reportRangeDays}
                  onChange={(event) =>
                    setReport((prevState) => ({
                      ...prevState,
                      reportRangeDays: event,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
      <div className='update-report-actions'>
        <div className='update-report-actions-right'>
          <Button
            className='but white but-28px'
            styleType={
              isMobile || window['cordova']
                ? ButtonStyles.WHITE_GRAY
                : ButtonStyles.GRAY_INACTIVE
            }
            size={isMobile || window['cordova'] ? ButtonSize.LG : ButtonSize.MD}
            onClick={() => onClose('', initialReport)}
          >
            Cancel
          </Button>
          <Button
            styleType={ButtonStyles.ORANGE_WHITE}
            size={isMobile || window['cordova'] ? ButtonSize.LG : ButtonSize.MD}
            onClick={updateReport}
          >
            Apply
          </Button>
        </div>
      </div>
      <SheduleFiltersEditor isOpen={isModalOpen} onClose={closeFilterEditModal} isActivity={isActivityReportType}/>
    </div>
  );
};

export default UpdateReport;
