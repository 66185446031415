import React from 'react';
import {useSelector} from 'react-redux';
import {sum} from 'ramda';
import useOneDeviceReportUnits from './useOneDeviceReportUnits';
import {countSafety, getSafetyPercent} from '../report-table/cells/helpers';
import { getTimeFormated, countDriveTime, countTraveledDistance, countStoppedTime } from '../ReportPage/reportPageHelpers';
import {
    friendlyDist,
    friendlySpeed,
    vals
} from '../../../../shared/helpers';
import {
    iFullStoreState,
    ReportContainer,
} from '../../../../shared/interfaces';

import {
    iReportType,
} from '../../../../stores/reducers/report-reducers';

const TravelTotalTable = () => {
    const reportType = useSelector<iFullStoreState, iReportType>(s => s.report.details.reportType);
    const displayRecords = useSelector<iFullStoreState, ReportContainer>(s => s.report.displayRecords || {});
    const safetyPercent = (sum(vals(displayRecords).map(getSafetyPercent(reportType as any))) / vals(displayRecords).length);
    const displaySafetyPercent = safetyPercent ? safetyPercent.toFixed(2) + "%" : '';

    const driveTime = countDriveTime(displayRecords);
    const distanceTraveled = Math.round(countTraveledDistance(displayRecords) * 100) / 100;
    const averageSpeed = Math.floor((distanceTraveled / driveTime) * 10 ) / 10;
    const stoppedTime = countStoppedTime(displayRecords);
    const deviceUnits = useOneDeviceReportUnits();
    return (
        <div className="report-summary-table">
            <div className="report-summary-col">
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Average Speed:</span>
                    <span className="report-summary-data-value">{isNaN(averageSpeed) ? 0 : friendlySpeed(averageSpeed, deviceUnits)}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Safety:</span>
                    <span className="report-summary-data-value">{displaySafetyPercent}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Drive Time:</span>
                    <span className="report-summary-data-value">{getTimeFormated(driveTime)}</span>
                </div>
            </div>
            <div className="report-summary-col">
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Stopped Time:</span>
                    <span className="report-summary-data-value">{getTimeFormated(stoppedTime / 60)}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name"> Total Distance:</span>
                    <span className="report-summary-data-value">
                        {distanceTraveled > 0 ? friendlyDist(distanceTraveled, deviceUnits) : 0}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default TravelTotalTable;