import React, {useEffect, useState} from 'react';
import './documentItem.scss';
import {iDocumentItem, iFileIconProps, LoadDocumentType} from "../../../../../shared/interfaces";
import {formatFileSize} from "../../../../../shared/helpers";
import CurrentFileIcon from "./CurrentFileIcon";
import FileName from "./FileName";
import ActionButtonSVG from "./ActionButtonSVG";

const DocumentItem = ({name, sizeInBytes, loaded: loadedInit, fullPath, storageName, onDelete, isCancel, isLoaded, inLoad, errorText, loadType, hasDeleteButton, onDownloadFileClick, file, actionButtonType, isDocumentDisable}: iDocumentItem) => {

    const [loaded, updateLoaded] = useState<number>(loadedInit);
    const [isDisable, setDisable] = useState<boolean>(false);

    const handleDownloadFileClick = () => {
        // console.log("fullPath", fullPath);
        if (!fullPath) return;
        !isLoaded && setDisable(true);
        onDownloadFileClick?.({storageName, loaded: 0, name, sizeInBytes, fullPath, file, inLoad, isLoaded});
        // console.log("download file click");
    };

    const handleUploadFileClick = () => {
        console.log("upload file click");
    };

    const handleNoneTypeFileClick = () => {
        console.log("none type file click");
    };

    const handleLoadedFileClick = () => {
        switch (loadType) {
            case LoadDocumentType.DOWNLOAD:
                handleDownloadFileClick();
                break;
            case LoadDocumentType.UPLOAD:
                handleUploadFileClick();
                break;
            default:
                handleNoneTypeFileClick();
                break;
        }
    };

    useEffect(() => {
        updateLoaded(loadedInit)
    }, [loadedInit]);

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        onDelete();
    };

    useEffect(() => {
            setDisable(isDocumentDisable);
        }, [isDocumentDisable]);

    const fileIconProps = {
        isLoaded,
        inLoad,
        loaded,
        sizeInBytes,
        loadType,
    } as iFileIconProps;

    const currentText = inLoad
        ? `${formatFileSize(loaded)} / ${formatFileSize(sizeInBytes)}`
        : `${formatFileSize(sizeInBytes)}`;

    const statusText = isCancel
      ? errorText
      : currentText;

    return (
        <button className={`document-file ${loadType === LoadDocumentType.DOWNLOAD ? "document-file-pointer" : ""}`} onClick={handleLoadedFileClick} disabled={isDisable}>
            <div className={`document-file-icon-block`}>
                <CurrentFileIcon {...fileIconProps}/>
            </div>
            <div className="document-file-info">
                <FileName name={name} />
                {/*<div className="document-file-name">{name}</div>*/}
                <div className={`document-file-size ${isCancel ? 'document-file-cancel-text' : ''}`}>
                    {statusText}
                </div>
            </div>
            {(hasDeleteButton && onDelete)
                ? <div className="document-file-action">
                    <div className="document-file-action-button" onClick={handleDeleteClick}>
                        <ActionButtonSVG actionButtonType={actionButtonType} />
                    </div>
                </div>
                : null
            }
        </button>
    );
};

export default DocumentItem;
