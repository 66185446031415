import React from "react";
import {iFileName} from "../../../../../../shared/interfaces";
import "./fileName.scss";

const FileName = ({name, className}: iFileName) => {
  const match = name.match(/^(.+)([.].+)$/);
  const left = match ? match[1] : name;
  const right = match ? match[2] : "";

  return (
    <div className={`document-file-name ${className ? className : ""}`}>
      <div className="document-file-name-left">{left}</div>
      <div className="document-file-name-right">{right}</div>
    </div>
  );
}

export default FileName;