import React, {useState} from 'react';
import './EditSchedule.scss';
import {formatTime, mapNumberToDay} from "./schedule-utils";
import {localStorage} from "../../shared/storage";
import instance from "../../api/instance";
import {store} from "../../stores/store";
import TimeInput from "./TimeInput";
import {iSchedule, ScheduleTimeType} from "../../shared/interfaces";

const EditSchedule = ({onClose, is24hFormat, schedule: initialSchedule}) => {
  const [schedule, setSchedule] = useState(initialSchedule);
  const [editingItem, setEditingItem] = useState<iSchedule>(null);
  const [activeTooltip, setActiveTooltip] = useState<{day: number; timeType: ScheduleTimeType}>(null);
  const [timeType, setTimeType] = useState<'start' | 'end'>();
  const [newTime, setNewTime] = useState('');
  const [isValidTime, setIsValidTime] = useState(true);
  const loginToken = localStorage.get('login-init-be-token');
  const clientId = loginToken['clientId'];

  const handleCheckboxChange = (day) => {
    setEditingItem(null);
    setSchedule(prevSchedule =>
      prevSchedule.map(item =>
        item.day === day ? {
          ...item,
          isDayOff: item.isDayOff ? 0 : 1,
          startTime: item.isDayOff ? '09:00:00' : null,
          endTime: item.isDayOff ? '17:00:00' : null
        } : item
      )
    );
  }

  const handleTimeChange = (day, timeType, newTime) => {
    setSchedule(prevSchedule =>
      prevSchedule.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            [timeType === 'start' ? 'startTime' : 'endTime']: newTime
          }
        }
        else {
          return item;
        }
      })
    );
  }

  const editSchedule = async () => {
    await instance.post(`api/work-schedule/update-work-schedule?organizationKey=${clientId}`, {data: schedule});

    const URL = `api/work-schedule/get-work-schedule?organizationKey=${clientId}`;
    const { data } = (await instance.get(URL)).data;

    store.dispatch({ type: 'SET_ORGANIZATION_SCHEDULE', schedule: data });

    onClose();
  }

  const handleTimeClick = (item, timeType: 'start' | 'end') => {
    if (item.isDayOff) return;

    setEditingItem(item);
    setTimeType(timeType);
  }

  const closeTimeEditForm = () => {
    setEditingItem(null);
  }

  const handleMouseEnter = (day, timeType) => {
    setActiveTooltip({day, timeType});
  };

  const handleMouseLeave = () => {
    setActiveTooltip(null);
  };

  return (
    <div className='edit-schedule'>
      <table>

        <tr>
          <th>Day</th>
          <th>Start</th>
          <th>End</th>
          <th>Day Off</th>
        </tr>
        {schedule.map((scheduleItem: iSchedule) => {
          const startTime = scheduleItem.startTime ? formatTime(scheduleItem.startTime, is24hFormat) : 'Day Off';
          const endTime = scheduleItem.endTime ? formatTime(scheduleItem.endTime, is24hFormat) : 'Day Off';

          return (
            <tr className="schedule-item" key={scheduleItem.day}>
              <td>
                <span>{mapNumberToDay(scheduleItem.day)}</span>
              </td>
              <td
                className={`${scheduleItem.isDayOff ? '' : 'editable-time'} ${
                  editingItem && editingItem.day === scheduleItem.day && timeType === 'start'
                    ? 'active-editing'
                    : ''
                }`}
                onClick={() => handleTimeClick(scheduleItem, 'start')}
                onMouseEnter={() => handleMouseEnter(scheduleItem.day, 'start')}
                onMouseLeave={handleMouseLeave}
              >
                {scheduleItem.isDayOff ? (
                  <span>{startTime}</span>
                ) : (
                  <span>{startTime}</span>
                )}
                {!editingItem && !scheduleItem.isDayOff && activeTooltip && activeTooltip.day === scheduleItem.day && activeTooltip.timeType === 'start' && <span className="time-edit-tooltip">Click to edit</span>}
              </td>
              <td
                className={`${scheduleItem.isDayOff ? '' : 'editable-time'} ${
                  editingItem && editingItem.day === scheduleItem.day && timeType === 'end'
                    ? 'active-editing'
                    : ''
                }`}
                onClick={() => handleTimeClick(scheduleItem, 'end')}
                onMouseEnter={() => handleMouseEnter(scheduleItem.day, 'end')}
                onMouseLeave={handleMouseLeave}
              >
                {scheduleItem.isDayOff ? (
                  <span>{endTime}</span>
                ) : (
                  <span>{endTime}</span>
                )}
                {!editingItem && !scheduleItem.isDayOff && activeTooltip && activeTooltip.day === scheduleItem.day && activeTooltip.timeType === 'end' && <span className="time-edit-tooltip">Click to edit</span>}
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={!!scheduleItem.isDayOff}
                  onChange={() => handleCheckboxChange(scheduleItem.day)}
                />
              </td>
            </tr>
          )
        })}
      </table>
      {editingItem && (
        <div className='schedule-time-edit-form'>
          <TimeInput
            item={editingItem}
            is24hFormat={is24hFormat}
            timeType={timeType}
            onTimeChange={setNewTime}
            setIsValidTime={setIsValidTime}
          />
          <div className='edit-schedule-buttons'>
            <button
              onClick={closeTimeEditForm}
            >
              Cancel
            </button>
            <button
              onClick={() => handleTimeChange(editingItem.day, timeType, newTime)}
              disabled={!isValidTime}
            >
              Update
            </button>
          </div>
        </div>
      )}
      <div className='edit-schedule-buttons'>
        <button onClick={onClose}>Close</button>
        <button onClick={editSchedule}>Apply</button>
      </div>
    </div>
  );
};

export default EditSchedule;
