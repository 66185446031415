import React from "react";
import UnitsTable from "./UnitsTable";
import { billigTablesApiHOC } from "../BillingApi/BillingTablesApiHOC";

const BillingUnits = () => {
  const Units = billigTablesApiHOC(UnitsTable);
  return <Units />;
};

export default BillingUnits;
