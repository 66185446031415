import React from 'react';
import update from 'immutability-helper';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import {iList, iLocation} from '../../shared/interfaces';
import {localStorage} from '../../shared/storage';

let gmapIdx = 0;

export const defaultBounds = {latNorth: 85, latSouth: -85, lngEast: 180, lngWest: -180};

export interface iState {
    mapRef: () => null | React.RefObject<GoogleMap>;
    defaultCenter: iLocation;
    defaultZoom: number;
    snapRoutes: boolean;
    showPOI: boolean;
    touchedDot?: string;
    theMap?: number;
    bounds?: any;
    streetViewLocation: boolean | iLocation;
    showDots: boolean;
    searchMarkers: iList<google.maps.places.PlaceResult>;
    mapType: google.maps.MapTypeId;
    toggleRecenter: boolean;
    replayPath: boolean;
    userLocation?: iLocation
    isRendered: boolean;
    trafficLayer: boolean;
}

const initialState: iState = {
    mapRef: () => null,
    streetViewLocation: false,
    snapRoutes: false,
    mapType: google.maps.MapTypeId.ROADMAP,
    showPOI: true, // points of interest
    searchMarkers: {},
    defaultZoom: parseInt(localStorage.get('map-default-zoom', 15), 10),
    defaultCenter: localStorage.get('map-default-center', {lat: 37.772, lng: -122.214}),
    showDots: false,
    toggleRecenter: true,
    replayPath: false,
    bounds: localStorage.get('map-default-bounds', defaultBounds),
    isRendered: false,
    trafficLayer: false,
    userLocation: null,
};

class ActionsClass {
    ADD_SEARCH_MARKER = (marker: google.maps.places.PlaceResult) => ({type: 'ADD_SEARCH_MARKER', marker})
    GMAP_SET_DEFAULT_ZOOM = (zoom: number) => ({ type: 'GMAP_SET_DEFAULT_ZOOM', zoom })
    DEL_SEARCH_MARKER = (id: string) => ({type: 'DEL_SEARCH_MARKER', id})

    SET_MAP_STATUS = (status) => ({type: 'SET_MAP_STATUS', payload: { status } })

    SET_MAP = (gmap) => ({type: 'SET_GMAP', gmap});
    SET_BOUNDS = (bounds) => ({type: 'SET_BOUNDS', bounds});
    TOGGLE_STREET_VIEW = (location?: iLocation) => ({type: 'GMAP_STREET_VIEW_TOGGLE', payload: {location}});
    TOGGLE_SNAP = () => ({type: 'GMAP_TOGGLE_SNAP'});
    TOGGLE_POI = () => ({type: 'GMAP_TOGGLE_POINTS_OF_INTEREST'})
    TOGGLE_DOTS = () => ({type: 'GMAP_TOGGLE_DOTS'})

    TOGGLE_RECENTER_MAP = () => ({type: 'GMAP_TOGGLE_RECENTER_MAP'})
    TOGGLE_MAP_TYPE = () => ({type: 'GMAP_TOGGLE_MAP_TYPE'})
    TOGGLE_TRAFFIC_LAYER = () => ({ type: 'GMAP_TOGGLE_TRAFFIC' })

    TOGGLE_REPLAY_PATH = () => ({type: 'TOGGLE_REPLAY_PATH'})
    TOUCH_DOT = (id) => ({type: 'GMAP_TOUCH_DOT', id: id})

    // Only side effects. Next actions dont changes sate.
    RECENTER_MAP = (center: iLocation, recenterIfOutOfBounds = false) => ({type: 'RECENTER_MAP', payload: {center, recenterIfOutOfBounds}})
    RECENTER_MAP_TO_DEVICE = (deviceId: string, recenterIfOutOfBounds = false) => ({type: 'RECENTER_MAP_TO_DEVICE', payload: {deviceId, recenterIfOutOfBounds}})
    UPDATE_USER_LOCATION = (position: iLocation) => ({ type: 'UPDATE_USER_LOCATION', payload: { position } })
}

export const Actions = new ActionsClass();

export const getMapZoom = (store: iState): number => {
    const {defaultZoom, mapRef: mapRefFunc} = store;
    const mapRef = mapRefFunc();

    if (mapRef && mapRef.current) {
        return mapRef.current.getZoom();
    }

    return defaultZoom;
};

export const getMapCenter = (store: iState): iLocation => {
    const {defaultCenter, mapRef: mapRefFunc} = store;
    const mapRef = mapRefFunc();

    if (mapRef && mapRef.current) {
        const center = mapRef.current.getCenter();

        return {lat: center.lat(), lng: center.lng()};
    }

    return defaultCenter;
};

export const recenterMap = (
    mapRef: null | React.RefObject<GoogleMap>,
    center: iLocation,
    recenterIfOutOfBounds = false,
    toggleRecenter = true
): void => {
    if (!mapRef?.current) return;

    // sometimes mapRef.current.getBounds() returns undefined
    const needRecenter = toggleRecenter && (!recenterIfOutOfBounds || !mapRef.current.getBounds()?.contains(center));

    if (needRecenter) {
        mapRef.current.panTo(center);
    }
};

export const reducers = (state: iState = initialState, action) => {
    if (action.type === 'SET_GMAP') {
        const mapRef = () => action.gmap;

        return update(state, {
            theMap: {$set: gmapIdx++},
            mapRef: {$set: mapRef},
        });
    }

    // a dot touched should cause an action and then be
    // untouched right away.
    if (action.type === 'GMAP_TOUCH_DOT') return update(state, !!action.id ?
        {touchedDot: {$set: action.id}} :
        {$unset: ['touchedDot']}
    );

    if (action.type === 'UPDATE_USER_LOCATION') {
        const { position } = action.payload;
        return update(state, {
            userLocation: { $set: position ?? null }
        });
    }

    if (action.type === 'GMAP_TOGGLE_MAP_TYPE') return update(state, {
        mapType: {$set: state.mapType === google.maps.MapTypeId.ROADMAP ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP}
    });

    if (action.type === 'GMAP_TOGGLE_TRAFFIC') return update(state, {
        trafficLayer: {$set: !state.trafficLayer}
    });

    if (action.type === 'GMAP_SET_DEFAULT_ZOOM') return update(state, {
        defaultZoom: {$set: action.zoom}
    })

    if (action.type === 'GMAP_TOGGLE_SNAP') return update(state, {
        snapRoutes: {$set: !state.snapRoutes}
    });

    if (action.type === 'GMAP_TOGGLE_DOTS') return update(state, {
        showDots: {$set: !state.showDots}
    });

    if (action.type === 'GMAP_TOGGLE_POINTS_OF_INTEREST') return update(state, {
        showPOI: {$set: !state.showPOI}
    });

    if (action.type === 'ADD_SEARCH_MARKER') return update(state, {
        searchMarkers: {[action.marker.place_id]: {$set: action.marker}}
    });

    if (action.type === 'DEL_SEARCH_MARKER') return update(state as any, {
        searchMarkers: {$unset: [action.id]}
    });

    if (action.type === 'SET_BOUNDS') return update(state, {
        bounds: {$set: action.bounds}
    });

    if (action.type === 'GMAP_TOGGLE_RECENTER_MAP') return update(state, {
        toggleRecenter: {$set: !state.toggleRecenter}
    });

    if(action.type == 'SET_MAP_STATUS') return update(state, {
        isRendered: { $set: action.payload.status }
    });

    if (action.type === 'TOGGLE_REPLAY_PATH') return update(state, {
        replayPath: {$set: !state.replayPath}
    })

    if (action.type == 'GMAP_STREET_VIEW_TOGGLE') {
        const {location} = action.payload;

        return update(state, {
            streetViewLocation: {$set: location ?? !state.streetViewLocation}
        });
    }

    return state;
};
