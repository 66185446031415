import React from 'react';
import {connect, DispatchProp, useDispatch, useSelector} from 'react-redux';
import {useRedux} from '../../../../states/redux-state';
import {groupBy, keys, pipe, sum, values} from 'ramda';
import {getGeonfeces, getPoints, getTrips, iGetReportPropsWrapper, tripPointsReal} from '../../../../shared/db/report-db';
import {
    friendlyDiff,
    friendlyDist,
    friendlyMilesPer,
    friendlySpeed,
    getDiff,
    idValArr,
    isMobile,
    lCount,
    minutesToFriendly,
    userCurrentOffset,
    utcOffset,
    vals
} from '../../../../shared/helpers';

import {
    hideReportTrips,
    removeReportTrips,
    setReportTrip,
    toggleShowReportTrip
} from '../../../../stores/reducers/devicesTripsPoints/AC';
import {Actions as GeneralActions} from '../../../../stores/reducers/general-reducers';
import { fullCloseDashboard } from '../../../../stores/reducers/dashboardInfo/AC';
import {Button, ButtonSize, ButtonStyles} from "../../../Button";
import {ReactComponent as MapPointer} from "../../../../assets/svg/map_pointer.svg";
import {
    ItemType,
} from '../../../../shared/interfaces';

const ShowOnMap = ({showAllRoute, setShowAllRoute}) => {
    const dispatch = useDispatch();
    const displayRecordsRaw = useRedux(s => s.report.displayRecords) || {};
    const deviceOrPerson = useRedux(s => s.report.details.devicePerson);
    const tripPoints = useRedux(s => s.devicesTripsPoints);

    const records = groupBy(x => deviceOrPerson === ItemType.device ? x.device : x.personId)(vals(displayRecordsRaw));


    const showReportTrip = (item) => {
        if (!tripPoints.getIn([item.device, 'tripsPoints', item.tripId])) {
            tripPointsReal(item.tripId)
                .then(values)
                .then((points: any) => {
                    dispatch(setReportTrip(item.device, item.tripId, points));
                }).then(res => {
                dispatch(toggleShowReportTrip(item.device, item.tripId, true));
            })
        } else {
            dispatch(toggleShowReportTrip(item.device, item.tripId, !showAllRoute));
        }
    }

    const showAllTrips = (records, k) => {
        records[k].forEach((item) => {
            setShowAllRoute(!showAllRoute);
            showReportTrip(item)
        })
    }

    const handleAllOnMapClick = () => {
        if (isMobile) {
            dispatch(GeneralActions.SHOW_SEARCH)
            dispatch(fullCloseDashboard())
        }
        keys(records).map(k => showAllTrips(records, k))
    }

    return (
        <Button
            styleType={
                showAllRoute
                    ? ButtonStyles.BLACK_WHITE
                    : ButtonStyles.GRAY_INACTIVE
            }
            size={(isMobile || window['cordova']) ? ButtonSize.MD : ButtonSize.XSM}
            onClick={handleAllOnMapClick}
        >
            <MapPointer className={(isMobile || window['cordova']) ? 'icon-smd' : 'icon-sm'} /> All On Map
        </Button>
    )
}

export default ShowOnMap;