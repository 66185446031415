import React, {CSSProperties as css} from 'react';
import {Link} from 'react-router-dom';
import {faTimes, faBell} from '@fortawesome/fontawesome-free-solid';
import {faEye, faEyeSlash} from '@fortawesome/fontawesome-free-regular';

import C, { UserCan } from '../../shared/constants';
import {Fa} from '../elements/fa';
import {ALL_DEVICES_TAG} from '../../shared/helpers';
import { useIsReadOnly } from '../../hooks/useIsReadOnly';
import { useAuthUserHasPermission } from '../../hooks/useAuthUserHasPermission';
import { ItemType } from '../../shared/interfaces';
import Dialog, {DialogConfigSetter} from '../Dialog';

const tagStyle: css = {
    padding: '2px 8px 2px 4px',
    backgroundColor: C.lightGray.toString(),
    borderLeft: '1px solid '+ C.primaryColor,
    color: C.primaryColor.toString(),
    userSelect: 'none',
    position: 'relative',
};
interface iProps {
    userId?: string;
    link?: string|false;
    remove?: Function|false;
    itemId: string;
    onClick?: any;
    view?: false|(() => any);
    isViewed?: boolean;
    isViewedIcon?: boolean;
    displayName: string|JSX.Element;
    style?: css;
    className?: string;
    showBell?: boolean;
    overrideStyles?: boolean;
    isGroup?: boolean;
}
const aStyle = (showRemove) => ({
    marginRight: !showRemove ? 0 : 7,
    transition: 'all 100ms ease-in',
    maxWidth: !showRemove ? 0 : 20,
    display: 'inline-block',
    paddingBottom: 1,
    overflow: 'hidden',
    verticalAlign: 'middle',
    textAlign: 'center',
} as React.CSSProperties);

export default ({
    userId,
    itemId,
    displayName,
    className = "",
    style = {},
    view = false,
    link = false,
    remove = false,
    showBell = false,
    isViewed = false,
    overrideStyles=false,
    isGroup = false,
    onClick =(() => {})
}: iProps) => {
    const toggle = (itemId === ALL_DEVICES_TAG);
    const canEditTags = useAuthUserHasPermission(UserCan.TAG_THINGS);
    const hasPermission = useIsReadOnly(itemId, ItemType.tag);

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();
    const removeTag = async () => {
        const dialog = dialogRef.current;

        if (remove) {
            const confirmed = await dialog?.({
                type: 'CONFIRM',
                title: 'Warning!',
                body: isGroup ? (
                      <div>
                          <p>This will remove the group <b>{displayName}</b> from user.</p>
                          <p>Click OK to confirm.</p>
                      </div>
                  ) : (
                    <div>
                        <p>This will delete the tag <b>{displayName}</b> along with any associated geoFences or settings.</p>
                        <p>Click OK to confirm.</p>
                    </div>
                ),
            });

            if (!confirmed) return;

            remove(itemId);
        }

        return;
    };

    return (
        <div key={itemId} style={overrideStyles? {...style} : {...tagStyle, ...style}} className={className}  onClick={onClick}>
            {!remove || toggle || !canEditTags || hasPermission?
                null :
                <span className="close-standard-item btn-link" style={aStyle(remove)} onClick={() => removeTag?.()}>
                    <Fa icon={faTimes} />
                </span>}
            {!link || toggle || !canEditTags || hasPermission? displayName : <Link to={link}>{displayName}</Link>}
            {!showBell ? null : <Fa icon={faBell} style={{marginLeft: 10}} />}
            {!view ? null : <Fa icon={!isViewed ? faEyeSlash : (isViewed ? faEye : null)} style={{marginLeft: 10, cursor: 'pointer'}} onClick={view} />}
            <Dialog setupConfig={setupDialog} />
        </div>
    )
};
