import React, {useState} from 'react';
import {Fa} from '../../../elements/fa';
import {faArrowLeft} from '@fortawesome/fontawesome-free-solid';
import {FaAction, ButtonLink, ButtonAction, Props} from './ActionRow.interfaces'
import ActionLink from './ActionLink'
import styles from './ActionRow.module.scss';
import {useHistory} from "react-router-dom";

const ActionRow: React.FC<Props> = ({ title, actions, canBack = false, style = {}, itemId, itemType }) => {
    const history = useHistory();
    const [fenceHistory] = useState(history);

    const handleGoBack = () => {
        fenceHistory.goBack();
    };

    return (
        <div className={styles.actionRow} style={{ ...style }}>
            {!canBack ? null : (
                <span className={`btn-link ${styles.actionBackBtn}`} onClick={handleGoBack}>
          <Fa icon={faArrowLeft} />
        </span>
            )}
            <span className={styles.actionRowTitle} title={`${title}`}>
        {title}
      </span>

            <span className={styles.actionsList}>
        {Array.isArray(actions) ? (
            actions.map((x, index) => (
                <ActionLink itemId={itemId} itemType={itemType} key={index} to={x.link} title={x.title}>
                    <Fa icon={x.fa} containerClassName={styles.actionIcon} />
                </ActionLink>
            ))
        ) : (actions as ButtonLink).to ? (
            <ActionLink itemId={itemId} itemType={itemType} to={(actions as ButtonLink).to} className="btn btn-primary btn-xs">
                {actions.text}
            </ActionLink>
        ) : (
            <button className="btn btn-primary btn-xs" onClick={(actions as ButtonAction).action}>
                {actions.text}
            </button>
        )}
      </span>
        </div>
    );
};

export default ActionRow;