import {UserAuth} from '../interfaces';
import {clientDb} from '../firebase';
import {makeAudit} from '../helpers';

export const updateFilter = (user: UserAuth) => async (filterId, filter): Promise<any>  => {
    const update = {
        [`filters/${filterId}/`]: filter,
    };

    await clientDb().update(makeAudit(user, update));
};

export const getFilters = async (): Promise<object> => {
    return (await clientDb().child('filters').once('value')).val() || {};
};

export const getFilter = async (filterId): Promise<object> => {
    return (await clientDb().child('filters').child(filterId).once('value')).val() || {};
};

export const dbFiltersWatcher = (callback) => clientDb().child('filters').on('value', (res) => {
    callback(res.val());
}, (err) => console.log(err));