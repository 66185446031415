import React, { ReactChild, ReactElement, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ReactComponent as ChevronSvg } from "../../../assets/svg/chevron_right.svg";
import "./index.scss";

type CollapsibleProps = {
    header: any;
    isCollapsed: boolean;
    className?: string;
    headerClassName?: string;
    bodyClassName?: string;
    scrollIntoView?: boolean;
    children: ReactChild
}

export const Collapsible = ({
    header,
    isCollapsed,
    className = "",
    headerClassName = "",
    bodyClassName = "",
    scrollIntoView = false,
    children
}: CollapsibleProps) => {
    const [collapsed, setCollapsed] = useState(isCollapsed);

    const toggleColapsed = () => {
        setCollapsed(!collapsed);
    };

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
		if (scrollIntoView) {
            ref.current.scrollIntoView({block: "end", inline: "nearest", behavior: 'auto'});
        }
    }, [collapsed]);


    const collapsedClass = collapsed ? "collapsed" : "";
    const activeClass = collapsed ? "" : "active";

    return (
        <div ref={ref} className={`collapsible ${className}` }>
            <div className={`collapsible-header ${headerClassName} ${activeClass}`} onClick={toggleColapsed}>
                {header}
                <ChevronSvg className={`icon ${collapsedClass}`} style={{ width: "24px", height: "24px" }} />
            </div>
            <div className={`collapsible-body ${bodyClassName} ${collapsedClass}`}>
                {children}
            </div>
        </div>
    )
}