import React, {useEffect, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from 'classnames';
import { isEmpty } from 'ramda'
import Player from "./Player";
import { closeHistorySession } from "../../../../../api/openApi";
import { openModal } from "../../../../../stores/reducers/videoCameras";
import styles from "../ForTag/DeviceStatusesForTag/deviceStatusesForTag.module.scss";
import { iFullStoreState, iPerson } from "../../../../../shared/interfaces";

const RenderPlayers = (
  { 
    playedSecondsRef,
    channels,
    reminderIntervalTime,
    isPlaying,
    setIsPlaying,
    setCurrentTime,
    updateTime,
    isControls,
    isLiveView,
    resetPlayer,
    setAvailableTime,
    intervalContinueStreaming,
    historyVideo,
    setSelectedHistoryChannel,
    totalSeconds = 0,
    isTag = false,
    name = null,
    deviceName = null,
  }
): JSX.Element => {

  const dispatch = useDispatch();
  const person = useSelector<iFullStoreState, iPerson>(state => state.general.people[state.auth.user?.uid]);

  const showContinueStreamingModal = async (): Promise<void> => {
    if (person.suppressContinueStreamModal) {
      return;
    }
    
    clearInterval(intervalContinueStreaming.current);
    setIsPlaying(false);
    await updateTime().then((time)=>{
      setAvailableTime(time);
      dispatch(openModal({key: "openContinueLiveStreamingModal", value: true}))
      resetPlayer()
    })
  }

  useEffect(() => {
    const int = setInterval(showContinueStreamingModal, reminderIntervalTime);
    intervalContinueStreaming.current = int;
    return () => {
      clearInterval(int);
      if (!isEmpty(historyVideo)) closeHistorySession({ session: historyVideo[0]?.session })
    }

    return () => {
      updateTime();
    }
  }, [reminderIntervalTime, historyVideo]);

  const historyOrLiveView = !isEmpty(channels) ? channels : historyVideo;
  return historyOrLiveView.map(c => {
    return (
      <div key={+c?.channel}>
        {isTag ? (<h5>{deviceName || name}</h5>) : (<h3>{c?.name}</h3>)}
        <div className={classNames(styles.player, {
          [styles.player__isLiveView]: isLiveView ,
          [styles.player__isHistoryView]: !isLiveView,
          [styles.player__isTag]: isTag 
        })}>
          {c?.url ? (<Player
            url={c?.url}
            isPlaying={isPlaying}
            setCurrentTime={setCurrentTime}
            setPlayedSeconds={(seconds) => {playedSecondsRef.current = seconds}}
            updateTime={updateTime}
            isControls={isControls}
            setIsPlaying={setIsPlaying}
          />) : (
              <div className={styles.offlineBlock}>
                <div className={styles.offlineText}>Video Not Available</div>
              </div>
          )}
        </div>
      </div>
    )
  })
};

export default RenderPlayers;
