import React from 'react';
import {ReactComponent as OrangeWave} from "../../assets/svg/orange_wave.svg";
import {ReactComponent as BlackWave} from "../../assets/svg/black_wave.svg";
import styles from "./CreditCard.module.scss";

interface ICardDetails {
    active: string;
    customer_id: string;
    default: string;
    descriptor?: string;
    email: string;
    exp_date: string;
    id: string;
    last_digits: string;
    processor: string;
    token: string;
    type: string;
}

export interface ICreditCard {
    primary?: boolean;
    creditName?: string;
    creditInfo?: ICardDetails;
    bankAcc?: ICardDetails;
    onSelectCard: () => void;
    type: string;
    selectedCardId: string | null;
}

const CreditCard = ({ primary, type, creditInfo, onSelectCard, selectedCardId }: ICreditCard) => {
    const iconWave = primary ? <OrangeWave /> : <BlackWave />;
    return (
        <div className={`${styles.userCard} ${primary ? styles.cardPrimary : styles.cardBackup} ${creditInfo.id === selectedCardId ? styles.selectedCard : ''}`} onClick={onSelectCard}>
            <div className={styles.cardBox}>
                <>
                    <div className={styles.waveIcon}>{iconWave}</div>
                    <div className={styles.cardName}>{type}</div>
                    <div className={styles.cardNumber}>
                        <span>****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;</span>
                        {creditInfo.last_digits}
                    </div>
                    {primary ? (
                        <div className={styles.cardActiveBottom}>
                            {creditInfo.active === "1" && "Active"}
                        </div>
                    ) : (
                        <div className={styles.cardExpires}>{`Expires:${creditInfo.exp_date}`}</div>
                    )}
                </>
            </div>
        </div>
    )
}

export default CreditCard;