import React, { useEffect, useRef, useState } from "react";
import RenderPlayers from "../../RenderPlayers";
import { getHistoryVideoApi, updateAvailableTime } from "../../../../../../api/openApi";
import ContinueStreamModal from "../../ContinueStreamModal";
import { useDispatch, useSelector } from "react-redux";
import { iReducersState } from "../../../../../../stores/reducers";
import {
  IAvailableTimeProps,
  ISelectedHistoryVideo,
  IRenderPlayersProps,
  ISelectChannel,
  ITariffs,
  IHistoryVideo,
} from "../../interfaces";
import styles from "./deviceStatusesForTag.module.scss";
import { openModal } from "../../../../../../stores/reducers/videoCameras";
import AvailableTimeForTag from "../AvailableTimeForTag";


const DeviceStatusesForTag = (props): JSX.Element => {
  const { reminderIntervalTime, deviceName, triggerCloseModal, channelProp, uniqueId, channelLinksForCameras, device: { isCamera, 'extra-info': serialNumber, extraDeviceChannels, id: deviceId } } = props;

  const serial = Object.values(serialNumber)[0].toString();

  const dispatch = useDispatch();
  const { modals, availableTime: reviewTime } = useSelector((state: iReducersState) => state.videoCameras);

  const {
    openLiveViewPlayer,
    openContinueLiveStreamingModal
  } = modals;

  const playedSecondsRef = useRef<number>(0);
  const [selectedChannels, setSelectedChannels] = useState<Array<ISelectChannel>>([]);
  const [prevSelectedChannels, setPrevSelectedChannels] = useState<Array<ISelectChannel>>([]);
  const [prevPlayedSeconds, setPrevPlayedSeconds] = useState<number>(0);
  const [channels, setChannels] = useState<Array<ISelectChannel>>([]);
  const [availableTime, setAvailableTime] = useState<number>(null || reviewTime);
  const [streamingTariffs, setStreamingTariffs] = useState<ITariffs | null>(null);
  const [isControls, setIsControls] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [currency, setCurrency] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const [prevIdChannels, setPrevIdChannels] = useState(null);
  const [prevHistoryTime, setPrevHistoryTime] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [historyVideo, setHistoryVideo] = useState<Array<IHistoryVideo>>([]);
  const [selectedHistoryChannel, setSelectedHistoryChannel] = useState<ISelectedHistoryVideo>(null);
  const intervalContinueStreaming = useRef<ReturnType<typeof setInterval>>(null);
  const [alertTimestamp, setAlertTimestamp] = useState(0);


  useEffect(() => {
    if (openContinueLiveStreamingModal === false) {
      channelLinksForCameras && clickView(channelProp);
    }
  }, [channelLinksForCameras])

  const updateTime = async (): Promise<number> => {
    setPrevPlayedSeconds(playedSecondsRef.current);
    setPrevHistoryTime((prev) => prev + Math.round(playedSecondsRef.current));
    setAlertTimestamp((prev) => new Date(prev).setSeconds(new Date(prev).getSeconds() + Math.round(playedSecondsRef.current)));
    const { available_time } = await updateAvailableTime({firebaseDeviceId: deviceId, playedSeconds: playedSecondsRef.current - prevPlayedSeconds});
    return available_time;
  };

  const getVideos = async () => {
    setIsLoading(true);

    if (channelLinksForCameras == undefined) return

    setAvailableTime(channelLinksForCameras?.timeInfo?.available_time);
    setChannels(channelLinksForCameras?.channels);
    setStreamingTariffs(channelLinksForCameras?.streaming_tariffs);
    setCurrency(channelLinksForCameras?.currency);

    return channelLinksForCameras.channels;
  }

  const resetPlayer = (): void => {
    setIsPlaying(false);
    setCurrentTime(0);
    playedSecondsRef.current = 0;
    setPrevPlayedSeconds(0);
    setPrevSelectedChannels(selectedChannels);
    setSelectedChannels([]);
    setCount(0)
  }

  const closeModal = async (): Promise<void> => {
    const time = await updateTime();
    setAvailableTime(time);
    for (let key in modals) modals[key] && dispatch(openModal({ key, value: false }))
    setHistoryVideo([])
    resetPlayer();
  };

  const clickView = async (channelProp): Promise<void> => {
    setLoadingButton(true);
    await getVideos().then((channels) => {
      setSelectedChannels(channels ? [channels.find(item => item.channel_id === channelProp)] : [null]);
      setLoadingButton(false);
      setIsPlaying(true);
    })
  }

  useEffect(() => {
    if (openLiveViewPlayer === true) {
      clickView(channelProp)
    }
  }, [openLiveViewPlayer])

  useEffect(() => {
    if (triggerCloseModal === true) {
      closeModal()
    }
  }, [triggerCloseModal])

  useEffect(() => {
    setPrevIdChannels(channelProp)

    return () => {
      closeModal()
    }
  }, [])

  const getHistoryVideo = async () => {
    setLoadingButton(true);
    const history = await getHistoryVideoApi({
      selectedHistoryChannel,
      firebaseDeviceId: deviceId,
      playedSeconds: prevHistoryTime,
      alertTimestamp
    }).finally(() => setLoadingButton(false));

    if (history?.channels) {
      const { channels, time_info: { available_time, tariffs: { streaming_tariffs, currency } } } = history;
      setHistoryVideo(channels);
      setCurrency(currency);
      setAvailableTime(available_time);
      setStreamingTariffs(streaming_tariffs);
      setIsPlaying(true)
      return channels
    } else {
      closeModal();
    }
  }

  const AvailableTimeProps: IAvailableTimeProps = {
    setIsPlaying,
    setAvailableTime,
    setIsControls,
    currentTime,
    availableTime,
    isPlaying,
    intervalContinueStreaming,
  }

  const RenderPlayersProps: IRenderPlayersProps = {
    channels: selectedChannels,
    reminderIntervalTime,
    isPlaying,
    setIsPlaying,
    setCurrentTime,
    updateTime,
    isControls,
    getVideos,
    isLiveView: true,
    playedSecondsRef,
    resetPlayer,
    setAvailableTime,
    intervalContinueStreaming,
    historyVideo,
    setSelectedHistoryChannel
  };

  return (
    <>
      <div className={styles.view}>
        {selectedChannels.length > 0 && <RenderPlayers isTag name={props.device.name} deviceName={deviceName} {...RenderPlayersProps} />}
        {!openContinueLiveStreamingModal && selectedChannels.length > 0 && <AvailableTimeForTag {...AvailableTimeProps} />}
      </div>
    </>
  )
};

export default DeviceStatusesForTag;
