import React, {useEffect, useState} from 'react';
import './ReportTaskDetails.scss';
import {GMapModalAC} from "../../../../stores/reducers/gMapModal/AC";
import {useDispatch} from "react-redux";
import {ReactComponent as CloseSvg} from "../../../../assets/svg/close_icon.svg";
// import DocumentsList from "../DocumentsList";
import moment from "moment";
// import {LoadDocumentType} from "../../../../shared/interfaces";
import PerfectScrollbar from "react-perfect-scrollbar";
import DownloadDocumentBlock from "../DownloadDocumentBlock";

const ReportTaskDetails = ({taskData = {
    id: undefined,
    taskName: undefined,
    deviceName: undefined,
    milesNeeded: undefined,
    hoursNeeded: undefined,
    odometer: undefined,
    labourHours: undefined,
    cost: undefined,
    startDate: undefined,
    endDate: undefined,
    documents: undefined,
}}) => {
    // const documents = [
    //     {name: "124.pdf", sizeInBytes: 100.5*1024*1024, downloadedSize: 0},
    //     {name: "124.pdf ", sizeInBytes: 100.5*1024*1024, downloadedSize: 70*1024*1024},
    //     {name: "poiuy1288884.pdf", sizeInBytes: 100.5*1024*1024, downloadedSize: 100.5*1024*1024},
    // ];

    const {
        taskName,
        deviceName,
        milesNeeded,
        hoursNeeded,
        odometer,
        labourHours,
        cost,
        documents: initDocuments,
    } = taskData;

    const [documents, updateDocuments] = useState([...initDocuments].map(itm => ({...itm, loaded: 0})))

    useEffect(() => {
        // stop downloading on modal leave
        /*[...documents].forEach((doc) => {
            doc?.onDelete?.();
        });*/

        updateDocuments(initDocuments);
    }, [initDocuments])

    // console.log("task data", taskData);
    const dispatch = useDispatch();
    const closeModal = () => {
        [...documents].forEach((doc) => {
            doc?.onDelete?.();
        });
        dispatch(GMapModalAC.hideModal());
    };
    const formatPrice = (item) => {
        if (!item) return item;
        return +item >= 0 ? `$${item}` : `-$${Math.abs(item)}`;
    }

    const formatDate = (timestamp) => {
        return moment(timestamp).format("MM/DD/YYYY")
    }

    const startDate = +taskData?.startDate;
    const endDate = +taskData?.endDate;
    return (
        <div className='task-details-modal'>
            <div className="task-details-modal-header">
                <p className="task-details-modal-title">Task Details</p>
                <button onClick={closeModal} className="task-details-modal-close-button">
                    <CloseSvg className="task-details-modal-close-icon"/>
                </button>
            </div>
            <div className="task-details-modal-top-block">
                <div className="task-details-modal-info-item">
                    <p className="task-details-modal-info-item-label">Task:</p>
                    <p className="task-details-modal-info-item-text">{taskName}</p>
                </div>
                <div className="task-details-modal-info-item">
                    <p className="task-details-modal-info-item-label">Unit:</p>
                    <p className="task-details-modal-info-item-text">{deviceName}</p>
                </div>
                <div className="task-details-modal-multi-items-wrapper">
                    <div className="task-details-modal-info-item">
                        <p className="task-details-modal-info-item-label">Miles:</p>
                        <p className="task-details-modal-info-item-text">{milesNeeded}</p>
                    </div>
                    <div className="task-details-modal-info-item">
                        <p className="task-details-modal-info-item-label">Hours:</p>
                        <p className="task-details-modal-info-item-text">{hoursNeeded}</p>
                    </div>
                    <div className="task-details-modal-info-item">
                        <p className="task-details-modal-info-item-label">Date:</p>
                        <p className="task-details-modal-info-item-text">{formatDate(endDate)}</p>
                    </div>
                </div>
            </div>
            <div className="task-details-modal-bottom-block">
                <div className="task-details-modal-multi-items-wrapper">
                    <div className="task-details-modal-info-item">
                        <p className="task-details-modal-info-item-label">Date:</p>
                        <p className="task-details-modal-info-item-text">{formatDate(startDate)}</p>
                    </div>
                    <div className="task-details-modal-info-item">
                        <p className="task-details-modal-info-item-label">Odometer:</p>
                        <p className="task-details-modal-info-item-text">{odometer}</p>
                    </div>
                </div>

                <div className="task-details-modal-multi-items-wrapper">
                    <div className="task-details-modal-info-item">
                        <p className="task-details-modal-info-item-label">Labor Hours:</p>
                        <p className="task-details-modal-info-item-text">{labourHours}</p>
                    </div>
                    <div className="task-details-modal-info-item">
                        <p className="task-details-modal-info-item-label">Cost:</p>
                        <p className="task-details-modal-info-item-text">{formatPrice(cost)}</p>
                    </div>
                </div>

                {
                    documents?.length
                    ? <div className="task-details-modal-info-item">
                            <p className="task-details-modal-info-item-label">Documents:</p>
                            <div className="task-details-modal-info-item-text">
                                <PerfectScrollbar className="task-details-modal-info-documents-wrapper">
                                    <DownloadDocumentBlock documents={documents} updateDocuments={updateDocuments} className="task-details-modal-info-documents-block"/>
                                </PerfectScrollbar>
                            </div>
                    </div>
                    : null
                }
            </div>
        </div>
    );
}

export default ReportTaskDetails;
