import Portal from '../Portal';
import React from 'react';
import styles from './Modal.module.scss';
import {classnames} from "@bem-react/classnames";

export default function Modal(props) {
  const {children, onBackdropClick} = props

  const handleBackdropClick = (event) => {
    if (onBackdropClick) {
      const { target, currentTarget } = event;
      const shouldCloseModal = target === currentTarget;
      shouldCloseModal && onBackdropClick();
    }
  };

  return (
    <Portal>
      <div className={styles.modal__overlay} onClick={handleBackdropClick}>
        <div className={classnames(styles.modal__block, props?.className)}>
          {children}
        </div>
      </div>
    </Portal>
  )
}
