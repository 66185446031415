import { call, put, select, takeLatest } from "redux-saga/effects";
import instance from "../../api/instance";
import { iReducersState } from "../reducers";
import { ACTIONS, billingLoading, setInvoices, setOrders, setUnits, updateUnitsName } from "../reducers/billing";
import { toast } from "react-toastify";

export interface Pagination {
  payload: {
    size: number;
    page: number;
  };
}

export const axiosCall = (url, method) => instance({ url, method });

export function* getOrders({payload: {size = 10, page = 1}}:any) {
    // const token = yield select((state: iReducersState) => state.billing.token);
    
    try {
        yield put(billingLoading(true));
        const { data: {records} } = yield call(axiosCall, `/api/order/get-orders?size=${size}&page=${page}`, 'GET');
        yield put(setOrders(records));
    } catch (error) {
        console.log('e',error);
    } finally {
        yield put(billingLoading(false));
    }
}

export function* getUnits({payload: {size = 10, page = 1}}:any) {

    
    try {
        yield put(billingLoading(true));
        const { data: {records} } = yield call(axiosCall, `/api/device/devices-subscriptions?size=${size}&page=${page}`, 'GET');
        yield put(setUnits(records));
    } catch (error) {
        console.log('e',error);
    } finally {
        yield put(billingLoading(false));
    }
}

export function* getInvoices({payload: {size = 10, page = 1}}:any) {

  try {
    yield put(billingLoading(true));
    const { data: {records} } = yield call(axiosCall, `/api/invoice/get-invoices?size=${size}&page=${page}`, "GET");
    yield put(setInvoices(records));
  } catch (error) {
    console.log("e", error);
  } finally {
    yield put(billingLoading(false));
  }
}

export function* updateUnitsNameSaga(props) {
  const { id, name, withLoading=true } = props.payload;

  try {
    const unitInfo = {
        name: name,
        device_id: id,
    };
      yield withLoading && put(billingLoading(true));
      const updateUnit = yield call(axiosCall, `/api/device/rename`, "POST");

      // need to change toast conditions, after update on the backend 
      if (!updateUnit || updateUnit.data === "undefined") {
        toast.error("Request For Update Unit Failed");
      } else {
        toast.success(`Unit Name Was Updated to ${name}`);
      }
      yield put(updateUnitsName(updateUnit.data.id, updateUnit.data.name));
    } catch (error) {
      console.log("ERROR: update unit ==>", error);
    } finally {
      yield put(billingLoading(false));
    }
}

export default function* watchLessons() {
  yield takeLatest(ACTIONS.SET_ORDERS_START, getOrders);
  yield takeLatest(ACTIONS.SET_UNITS_START, getUnits);
  yield takeLatest(ACTIONS.SET_INVOICES_START, getInvoices);
  yield takeLatest(ACTIONS.UPDATE_UNITS_NAME_START, updateUnitsNameSaga);
}
