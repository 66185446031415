import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {is, Map, Set} from 'immutable';

import {iFullStoreState} from '../../../../shared/interfaces';
import {deviceShowToggle} from '../../../../stores/reducers/tagsDevicesMap/AC';
import {Actions as GmapActions} from '../../../../stores/reducers/gmap-reducers';
import Checkbox from '../../../elements/Checkbox';
import TuneIcon from '../../../SVG-components/tune';
import GpsIcon from '../../../SVG-components/gps_fixed';

import './styles.scss';

const VisTagsDevices = (): JSX.Element => {
    const dispatch = useDispatch();

    const devicesNames = useSelector<iFullStoreState, Map<string, string>>(
        (s) => s.devicesData.devicesDetails.map((deviceInfo) => deviceInfo.name),
        (l, r) => is(l, r)
    );

    const showedDevices = useSelector<iFullStoreState, Map<string, { selected: boolean; tagsIds: Set<string> }>>(
        (s) => s.tagsDevicesMap.showedDevices,
        (l, r) => is(l, r),
    );

    const recenterMapToDevice = (deviceId: string): object => dispatch(GmapActions.RECENTER_MAP_TO_DEVICE(deviceId));

    return (
        <div className='DevicesBox' >
            {showedDevices.map(({selected}, deviceId) =>
                <div key={deviceId}>
                    <div>
                        <Checkbox inputId={deviceId} checked={selected} onChange={ () => dispatch(deviceShowToggle(deviceId))} />
                        <span>{devicesNames.get(deviceId) ?? ''}</span>
                    </div>
                    <GpsIcon onClick={()=> recenterMapToDevice(deviceId)}/>
                    {/* <TuneIcon />*/}
                </div>
            ).valueSeq()}
        </div>
    );
};

export default VisTagsDevices;
