import React, { SVGProps, FunctionComponent } from 'react';
import * as Icons from '../../SVG-components/DeviceIcons';

interface DeviceSvgIconProps extends SVGProps<SVGSVGElement> {
  name: string;
}

export const DeviceSvgIcon: FunctionComponent<DeviceSvgIconProps> = ({
  name,
  width,
  height,
  color,
  fill,
  ...props
}) => {
  const Icon = Icons[name] ?? (() => <></>);

  return <Icon width={width} height={height} fill={fill} />;
};
