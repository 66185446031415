import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CreditCard from "../Cards/creditCard";
import EmptyBankAccount from "../Cards/emptyCard/EmptyBankAccount";
import ModalCreateDevice from "./ModalCreateDevice";
import { Tooltip } from "@material-ui/core";

import { iReducersState } from "../../../../stores/reducers";
import AddCreditCard from "../Cards/addCreditCard";
import { getRequests as getAllCards, getRequests as getTokens } from "../BillingApi/billingApiRequests";
import {
  getBrainTreeToken,
  getCards,
  getPlaidToken,
  loadingCards,
} from "../../../../stores/reducers/billing";
import ModalPlans from "../ModalPlans/ModalPlans";
import "./BillingHeader.scss";

const BillingHeader = () => {
  const [open, setOpen] = useState(false);
  const [step,setStep] = useState(0)
  const [loadingBankAccount, setLoadingBankAccount] = useState(false);
  const [loadingCreditCard, setLoadingCreditCard] = useState(false);
  const [showBankCard, setShowBankCard] = useState(false);
  const [showBankCardActive, setShowBankCardActive] = useState(true);
  const [showCreditCardActive, setShowCreditCardActive] = useState(true);
  const { token, creditCard, bankAccount } = useSelector(
    (state: iReducersState) => state.billing
  );
const activationDeviceSteps = () => {
  setOpen(true)
  setStep(1)
}
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingCards(true));
    getTokens({ endPoint: "token", dispatch, action: getBrainTreeToken});
    getTokens({endPoint: "get-link-token", dispatch, action: getPlaidToken});
    getAllCards({ endPoint: "cards", dispatch, action: getCards})
    .finally(() => dispatch(loadingCards(false)));
  }, [token?.access_token, dispatch]);

  return (
    <>
      <div className="credits">
        <span>Credits: $3250</span>
      </div>
      <div className="cards-block">
        <div className="cards-container">
          <div className="cards">
            <div className="block">
              <div className="name-card">Primary</div>
              {showCreditCardActive && bankAccount?.length > 0 ? (
                bankAccount.map((item) => (
                  <CreditCard
                    key={item.id}
                    primary
                    bankAcc={item}
                    type={item.type}
                    setShowCreditCardActive={setShowCreditCardActive}
                    setLoadingBankAccount={setLoadingBankAccount}
                  />
                ))
              ) : (
                <EmptyBankAccount
                  primary
                  loadingBankAccount={loadingBankAccount}
                  setLoadingBankAccount={setLoadingBankAccount}
                  setShowCreditCardActive={setShowCreditCardActive}
                />
              )}
            </div>

            <div className="block">
              <div className="name-card">Backup</div>
              {showBankCardActive &&
                creditCard?.length > 0 &&
                creditCard.map((item) => (
                  <CreditCard
                    key={item.id}
                    type={item.type}
                    creditInfo={item}
                    setShowBankCard={setShowBankCard}
                    setShowBankCardActive={setShowBankCardActive}
                  />
                ))}
              {!creditCard?.length && !showBankCard && (
                <EmptyBankAccount setShowBankCard={setShowBankCard} />
              )}
              {showBankCard && (
                <AddCreditCard
                  loadingCreditCard={loadingCreditCard}
                  setLoadingCreditCard={setLoadingCreditCard}
                  setShowBankCard={setShowBankCard}
                  setShowBankCardActive={setShowBankCardActive}
                />
              )}
            </div>
          </div>
          <Tooltip  title={!creditCard?.length ? 'There are no available Credit Card' : ''}>
            <div>
              <button
                className="tracker-btn"
                onClick={() => activationDeviceSteps()}
                disabled={!creditCard?.length}
              >
                Activate a new tracker
              </button>
            </div>
          </Tooltip>
        </div>
         {/*{open && <ModalCreateDevice open={open} setOpen={setOpen} />}*/}
        {open && (
          <ModalPlans active={open} setActive={setOpen} setStep={setStep} step={step}/>
        )}
      </div>
    </>
  );
};

export default React.memo(BillingHeader);
