import React from 'react';
import { faExclamation } from '@fortawesome/fontawesome-free-solid';

import { Fa } from '../components/elements/fa';

type ErrorCatcherState = {
    error?: string;
}

export default class ErrorBoundary extends React.Component<{}, ErrorCatcherState> {
    state: ErrorCatcherState = {};

    componentDidCatch (error: Error, info) {

        console.groupCollapsed(error.message);
        console.log(error);
        console.log(info.componentStack);
        console.groupEnd();

        this.setState({error: info.componentStack.split('\n')[2].split(' (')[0]} as any);
    }

    render () {
        return this.state.error ? <Fa icon={faExclamation} title={this.state.error} /> : this.props.children;
    }
}
