import React, {CSSProperties as css, useState} from 'react';
import * as color from "color";
import {faEye, faTachometerAlt, faTag, faTags, faUser, faUsers, faWifi} from "@fortawesome/fontawesome-free-solid";
import {Overlay} from "../../../menus/modal/modal";
import {clientDb, keyGen} from "../../../../shared/firebase";

function AddGroupOverlay({close, onUpdate}) {
  const [value, setValue] = useState('');

  const submit = async () => {
    if (value.length) {
      const groupKey = keyGen();

      await clientDb().update({
        [`groups/${groupKey}`]: {
          'name': value,
          'sub-admins': {},
          'sub-users': {}
        }
      });

      await onUpdate();

      close();
    }
  };

  return (
    <Overlay close={close}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>Please enter a group name</p>
        <input
          type="text"
          autoFocus
          className="mas-input"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <button
          disabled={!value.length}
          style={{ marginTop: 5 }}
          className="btn btn-sm btn-primary"
          onClick={submit}
        >
          Submit
        </button>
      </div>
    </Overlay>
  );
}

export default AddGroupOverlay;
