import './FenceBody.scss';

import * as fenceDb from '../../../../shared/db/fences-db';
import * as fenceSelectors from '../../../../shared/db/fences-selectors';

import {ALL_DEVICES_TAG, isMobile} from '../../../../shared/helpers';
import {Actions, useVisRegions} from '../../../../stores/reducers/map-region-reducers';
import C, { ACL, UserCan } from '../../../../shared/constants';
import {FenceGroup, FenceType, ItemType, UserAuth, iFenceDetails, iFullStoreState} from '../../../../shared/interfaces';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Col} from '../../../elements/flex';
import FenceLine from "./FenceLine";
import {IProps} from "./FenceBody.interfaces";
import {Link} from 'react-router-dom';
import {Actions as PathActions} from '../../../../stores/reducers/map-path-reducers';
import { Actions as FenceActions } from '../../../../stores/reducers/fences-reducers';
import ShowVisTagsItems from "./ShowVisTagsItems";
import {equals} from 'ramda';
import {keyGen} from '../../../../shared/firebase';
import {useIsReadOnly} from "../../../../hooks/useIsReadOnly";
import {useRedux} from '../../../../states/redux-state';

function FenceBody(props: IProps): JSX.Element  {
    const {itemId, itemType} = props;
    const dispatch = useDispatch();
    const allDevicesTagFences = useRedux((s) => s.general.fences?.tag?.[ALL_DEVICES_TAG]);

    React.useEffect(() => {
        //dispatch(PathActions.UNSTASH_PATHS());
        dispatch(Actions.UNSTASH_REGIONS());

        // return () => {
            // dispatch(PathActions.STASH_PATHS());
            // dispatch(Actions.STASH_REGIONS());
        // };
    }, []);

    const fences = useSelector<iFullStoreState, Array<iFenceDetails>>(
        (s) => fenceSelectors.fenceOrPathSelector(itemType, itemId, FenceGroup.FENCE)(s, {itemType, itemId}),
        (l, r) => equals(l, r)
    );
    const paths = useSelector<iFullStoreState, Array<iFenceDetails>>(
        (s) => fenceSelectors.fenceOrPathSelector(itemType, itemId, FenceGroup.PATH)(s, {itemType, itemId}),
        (l, r) => equals(l, r)
    );
    const visibleRegions = useSelector<iFullStoreState, Array<string>>(
        (s) => Object.keys(s.regions.vis || {}),
        (l, r) => equals(l, r)
    );
    const visiblePaths = useSelector<iFullStoreState, Array<string>>(
        (state) => Object.keys(state.paths.vis || {}),
        (l, r) => equals(l, r)
    );

    const authUser = useSelector<iFullStoreState, UserAuth>((s) => s.auth.user, (l, r) => equals(l, r));

    const hasShowedTags = useSelector<iFullStoreState, boolean>((s) => !!s.tagsDevicesMap.showedTagsIds.size);

    const mapOnly = (!itemId || !['device', 'person'].includes(itemType)) && !hasShowedTags;

    const allFences = allDevicesTagFences ? Object.values(allDevicesTagFences).concat(fences) : fences;

    const [filter, setFilter] = useState<string>('');

    const isReadOnly = useIsReadOnly(itemId, itemType as ItemType);

    const hasPermissionToEditFences = ACL.check(UserCan.EDIT_FENCES, authUser.acl.can);

    const canEditFence = !isReadOnly && hasPermissionToEditFences;

    const toggleFencePath = async (id: string, on: boolean) => {
        const fence = allFences.concat(paths).find((x) => x.id === id);

        // if going on and already on short circuit here
        if (on && visiblePaths.concat(visibleRegions).indexOf(id) !== -1) {
            return;
        }

        if (!on) {
            dispatch(fence.fenceType === FenceType.PATH ? PathActions.REMOVE_PATH(id) : Actions.REMOVE_REGION(id));
        } else {
            dispatch(
                fence.fenceType === FenceType.PATH ?
                    PathActions.PLACE_FENCE(fence, await fenceDb.getPath(id)) :
                    Actions.PLACE_FENCE(fence, false),
            );
        }
    };

    const toggleAll = (region: boolean, on: boolean) => {
        const toToggle = region ? fences : paths;
        toToggle.forEach((fence) => toggleFencePath(fence.id, on));
    };

    if (mapOnly && isMobile) {
        return <Col justify="center" align="center" className='FenceBody-MobileNotify'>
            <h4>
                Please select device person or tag to create a fence
            </h4>
        </Col>;
    }

    if (mapOnly) return null;

    const fenceVis = (id: string) => visibleRegions.includes(id);
    // const pathVis = (id) => visiblePaths.includes(id);
    const centered = canEditFence ? '' : 'ButtonsBlockCentered';

    return (
            itemType === ItemType.device ? (
                <div className={`FenceBody ${isMobile ? 'FenceBody-Mobile' : ''}`}>
                    <div className='FenceBody-Content'>
                        <input
                            type="input"
                            placeholder="Search fences..."
                            onChange={e => setFilter(e.target.value)}
                            className='SearchInput'
                        />
                        <div className='FenceBody-List'>
                            {fences
                                .filter((fence: iFenceDetails) => filter ? fence.name.toLowerCase().includes(filter.toLowerCase()) : fence)
                                .map((fence: iFenceDetails) =>
                                    <FenceLine
                                        fenceGroup={FenceGroup.FENCE}
                                        key={fence.id}
                                        isVis={fenceVis(fence.id)}
                                        {...{
                                            fence,
                                            itemId,
                                            itemType
                                        }}
                                        toggler={toggleFencePath}
                                    />
                            )}
                        </div>
                        <Link
                            className='FenceBody-SimpleLink'
                            title={'Create New Fence'}
                            to={`/${ItemType.device}/${itemId}/${FenceGroup.FENCE}/${keyGen()}/edit`}
                            onClick={() => dispatch(FenceActions.SET_BACKUP_STATUS(true))}
                        >
                            + Add new
                        </Link>

                    </div>
                    <div className={`ButtonsBlock ${centered}`}>
                        <button
                            className='WhiteButton'
                            onClick={() => toggleAll(true, fences.length !== visibleRegions.length)}>
                            {fences.length !== visibleRegions.length ? "Select All" : "Clear All"}
                        </button>
                        <Link
                            title={'Create New Fence'}
                            to={`/${ItemType.device}/${itemId}/${FenceGroup.FENCE}/${keyGen()}/edit`}
                            onClick={() => dispatch(FenceActions.SET_BACKUP_STATUS(true))}
                        >
                            {
                                canEditFence && (
                                    <button className='BlackButton'>
                                        Add Fence
                                    </button>
                                )
                            }
                        </Link>
                    </div>
                </div>

            ) : (
                <ShowVisTagsItems itemId={itemId} toggleAll={toggleAll}/>
            )
    );
};

export default FenceBody;
