import { keyGen } from './firebase';
import { store } from '../stores/store';
import { Actions as PathActions } from '../stores/reducers/map-path-reducers';

class devSettingsClass {
    get traceRouteBool() {
        return Boolean(localStorage.getItem('trace-route'))
    }
    set traceRouteBool(value) {
        localStorage.setItem('trace-rotue', value ? "1" : "0");
    }

    get enabledBool() {
        return Boolean(localStorage.getItem('dev-enabled'))
    }
    set enabledBool(value) {
        localStorage.setItem('dev-enabled', value ? "1" : "0");
    }

    get disableSentryBool() {
        return Boolean(localStorage.getItem('disable-sentry'));
    }

    set disableSentryBool(value) {
        localStorage.setItem('disabled-sentry', value ? "1" : "0");
    }
}
export const devSettings = new devSettingsClass();

const addRoute = (coordinates, color = '#00f') => {
    const key = keyGen();

    let action = PathActions.ADD_PATH(key, coordinates, color, false);

    store.dispatch(action);

    console.log(`Adding route ${key}`)
}

const removeRoute = (id) => store.dispatch(PathActions.REMOVE_PATH(id));

window['devSettings'] = devSettings;

if (devSettings.enabledBool) {
    window['devActions'] = {
        addRoute, removeRoute
    }
}
