import React from 'react';
import { ifElse, pipe, identity } from 'ramda';

import { BaseComponent } from '../../shared/BaseComponent';

interface iProps {
    striped?: boolean,
    sm?: boolean,
    dark?: boolean,
    headers?: string[],
    style?: React.CSSProperties,
    onAllClick?: () => void;
    allBtn?: any,
}

export class TableSimple extends BaseComponent<iProps> {
    _bootstrapClass = (p: iProps) => pipe(
        ifElse(_ => p.striped, s => `${s} table-striped`, identity),
        ifElse(_ => p.sm, s => `${s} table-sm`, identity),
        ifElse(_ => p.dark, s => `${s} table-dark`, identity),
    )('table') as any

    debugRender = () => {
        const { allBtn: AllBtn, onAllClick } = this.props;
        return <table className={this._bootstrapClass(this.props)} style={{backgroundColor: this.props.dark ? '' : '#fff', opacity: 1, ...(this.props.style || {})}}>
            {!this.props.headers ? null : <thead><tr>
                {this.props.headers.map(h => <th key={h}>{h}</th>)}
                {this.props.allBtn && (
                    <th>
                        <AllBtn onAllClick={onAllClick} />
                    </th>
                )}
            </tr></thead>}
            <tbody>
                {this.props.children}
            </tbody>
        </table>
    }
}
