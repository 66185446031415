import { DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { extractDevice } from '../../../shared/helpers';
import {
  ItemType,
  iDevicePing,
  iConfig,
  iPerson,
  iList,
  iTag,
  iDeviceDetails,
  UserAuth, iFullStoreState,
} from '../../../shared/interfaces';
import { DevicesDetailsContainer } from '../../../stores/reducers/devicesData';
import * as tagSelectors from '../../../shared/db/tags-labels-selectors';
import * as structure from '../../../shared/interfaces';

export const mapStateToProps = (
  state: structure.iFullStoreState,
  {
    match: {
      params: { id: personId },
    },
  }: IProps
): IPropsFromStore => ({
  config: state.general.config!,
  assignedDevice: state.general.people?.[personId]?.hasDevice
    ? extractDevice(
        state.devicesData.devicesDetails,
        state.general.people[personId]?.hasDevice?.deviceId
      )
    : undefined,
  assignedDeviceLastPing: state.general.people?.[personId]?.hasDevice
    ? state.devicesData.devicesLastPing.get(
        state.general.people[personId]?.hasDevice?.deviceId
      )
    : undefined,
  devicesDetails: state.devicesData.devicesDetails,
  person: state.general.people[personId],
  userCanDo: state.auth.user?.acl?.can ?? [],
  authUser: state.auth.user,
  itemTags: tagSelectors.getItemTagsSelector(state, {
    itemId: personId,
    itemType: ItemType.person,
  }),
  tags: state.general.tags,
  currentLabels: tagSelectors.getItemLabelNameValMap(state, {
    type: ItemType.person,
    itemId: personId,
  }),
  logoutTime: state.auth.logoutTime,
  username: state.auth.username,
});

type IProps = RouteComponentProps<{ id: string }>;

type IPropsFromStore = {
  currentLabels: [string, string][];
  userCanDo: string[];
  authUser: UserAuth;
  devicesDetails: DevicesDetailsContainer;
  assignedDeviceLastPing?: iDevicePing;
  config: iConfig;
  person: iPerson;
  tags: iList<iTag>;
  itemTags: iList<iTag>;
  assignedDevice: iDeviceDetails | undefined;
  logoutTime: string | number;
  username: string;
};

export type IFullProps = IProps & IPropsFromStore & DispatchProp & iFullStoreState;
