import { RouteComponentProps } from 'react-router-dom';
import { UserCan } from '../../../shared/constants';
import { iPerson, UserAuth, iFullStoreState } from '../../../shared/interfaces';

export type IProps = RouteComponentProps<{ id: string }>;

export type IPropsFromStore = {
  person?: iPerson;
  authUser?: UserAuth;
  userCanDo: UserCan[];
};

export type IFullProps = IProps & IPropsFromStore;

export const mapStateToProps = (
  state: iFullStoreState,
  ownProps: IProps
): IPropsFromStore => ({
  person: state.general.people[ownProps.match.params.id],
  authUser: state.auth.user ?? undefined,
  userCanDo: state.auth?.user?.acl?.can ?? [],
});
