import React, { FC, useEffect, useState } from "react";
import "./index.scss";
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerNavbar from '../DayPickerNavbar';
import Weekday from '../../menus/Timeline2/DayPickerCustomWeekday';

type RangeDayPickerProps = {
    defaultRange: {
        startDate: Date,
        endDate: Date
    }
    onRangeChange?: (range: {from: Date | undefined, to: Date | undefined}) => void
}

const RangeDayPicker: FC<RangeDayPickerProps> = ({defaultRange, onRangeChange}) => {
    const [range, setRange] = useState({from: defaultRange?.startDate, to: defaultRange?.endDate});
    const modifiers = { start: range.from, end: range.to };
    const handleDayClick = (day) => {
        const newRange = DateUtils.addDayToRange(day, range);
        setRange(newRange);
    }

    useEffect(() => {
        onRangeChange?.(range);
    }, [range]);

    const today = new Date();
    return (
        <DayPicker
            className="day-picker"
            selectedDays={[range.from, range]}
            modifiers={modifiers}
            onDayClick={handleDayClick}
            weekdayElement={Weekday}
            navbarElement={DayPickerNavbar}
            captionElement={() => null}
            disabledDays={{after: today}}
            showOutsideDays={true}
        />
    )
}

export default RangeDayPicker;