import {all} from 'redux-saga/effects';

import watchRecenterMap from './recenterMap';
import watchRecenterMapToDevice from './recenterMapToDevice';
import watchSelectPoint from './selectPoint';
import watchSelectLastPointInPrevTrip from './selectLastPointInPrevTrip';
import watchSelectFirstPointInNextTrip from './selectFirstPointInNextTrip';
import watchDevicesData from './devicesData';
import watchDevicesLocations from './devicesLocations';
import watchMaintenance from './maintenance';
import watchReports from "./reports";
import watchBilling from "./billing";


export default function* rootSaga () {
    yield all([
      watchRecenterMap(),
      watchRecenterMapToDevice(),
      watchSelectPoint(),
      watchSelectLastPointInPrevTrip(),
      watchSelectFirstPointInNextTrip(),
      watchDevicesData(),
      watchDevicesLocations(),
      watchBilling(),
      watchReports(),
      watchMaintenance(),
    ]);
}
