import React from 'react';
import {withBemMod} from '@bem-react/core';

import {cnTabs} from '../';
import './Tabs_theme_dark_new.scss';

export interface TabsThemeLightProps {
    theme?: 'dark_new';
}

export const withTabsThemeDarkNew = withBemMod<TabsThemeLightProps>(
    cnTabs(),
    {theme: 'dark_new'},
    (Component) => ({theme, ...props}) => <Component {...props} />,
);
