import React from 'react';
import './documentsList.scss';
import DocumentItem from "./DocumentItem";
import {iDocumentsList} from "../../../../shared/interfaces";

const DocumentsList = ({documents, loadType, columns = 1, hasDeleteButton, style, className, onDownloadFileClick}: iDocumentsList) => {
    return (
        <div className={`documents-list-wrapper documents-columns-${columns} ${className ? className : ""}`} style={style}>
            {
                documents.map(itm => <DocumentItem {...itm} loadType={loadType} hasDeleteButton={hasDeleteButton} key={itm?.name} onDownloadFileClick={onDownloadFileClick} />)
            }
        </div>
    );
}

export default DocumentsList;
