import { AuthReducer, MyUserInfo, UserAuth } from '../../shared/interfaces';
import update from 'immutability-helper';
import { path, omit } from 'ramda';

import { ACL, UserCan } from '../../shared/constants';
import { localStorage } from '../../shared/storage';

const doAnythingifyToken = (user: UserAuth, beToken) =>
    ACL.check(UserCan.DO_ANYTHING, path(['acl', 'can'], user))
    ? omit(['devices'], beToken)
    : beToken;

const isDriver = (user: UserAuth) =>
    ACL.checkDriver(UserCan.ASSIGN_DRIVER, path(['acl', 'can'], user))

export class AuthAC {
    static ACTIONS = {
        SET_FB_TOKEN: 'SET_FB_TOKEN',
        SET_MAP_API_TOKEN: 'AUTH/SET_MAP_API_TOKEN',
        USER_LOGOUT: 'USER_LOGOUT',
        SET_BE_TOKEN: 'SET_BE_TOKEN',
        SET_FP: 'SET_FP',
        SET_IS_DRIVER: 'SET_IS_DRIVER',
        USER_SET: 'USER_SET',
        SET_PERMISIONS: 'SET_PERMISIONS',
        SET_LOGOUT_TIME: 'SET_LOGOUT_TIME',
        SET_USERNAME: 'SET_USERNAME',
        USER_SET_UID: 'USER_SET_UID',
        IS_PUBLIC: 'IS_PUBLIC',
    };
    static setFbToken = (token: string) => ({type: AuthAC.ACTIONS.SET_FB_TOKEN, payload: {token}});
    static setMapApiToken = ({mapApiToken}: {mapApiToken: string}) => ({type: AuthAC.ACTIONS.SET_MAP_API_TOKEN, payload: {mapApiToken}});
    static setLogoutTime = (time) => ({type: AuthAC.ACTIONS.SET_LOGOUT_TIME, payload: {logoutTime: time}});
    static setIsPublicLink = (status) => ({ type: AuthAC.ACTIONS.IS_PUBLIC, payload: { status } });
}

let timeFromStorage = localStorage.get('logout-time');
let time = timeFromStorage?.selectTime || 600000;

let initialState = {
    user: localStorage.get('cached-user', null),
    logoutTime: time,
    isFingerPrint: false,
    isDriver: false,
    username: localStorage.get('cached-username', ''),
    isPublic: false,
};

type AuthReducerAction = {
    type: string,
    details?: MyUserInfo | null,
    token?: string,
    isFinger?: boolean,
    payload?: {
        mapApiToken?: string,
        logoutTime?: string | number,
        token?: string,
        status?: boolean,
    }
    can?: UserCan[],
    username: string,
}

export default (store: AuthReducer = initialState, action: AuthReducerAction): AuthReducer => {
    const ACTIONS = AuthAC.ACTIONS;
    switch (action.type) {

        case ACTIONS.USER_LOGOUT:
            return update(store, {$unset: ['user', 'fbToken']});

        case ACTIONS.SET_USERNAME: {
            return update(store, {
                username: {$set: action.username},
            });
        }

        case ACTIONS.SET_FB_TOKEN: {
            return update(store, {fbToken: {$set: action.payload.token}});
        }
        case ACTIONS.SET_BE_TOKEN: {
            return update(store, {user: {beToken: {$set: doAnythingifyToken(store.user, action.token)}}});
        }
        case ACTIONS.SET_FP: {
            return update(store, {isFingerPrint: {$set: action.isFinger}});
        }
        case ACTIONS.SET_IS_DRIVER: {
            return update(store, {isDriver: {$set: isDriver(store.user)}})
        }
        case ACTIONS.IS_PUBLIC: {
            return  update(store, {isPublic: { $set: action.payload.status }})
        }
        case ACTIONS.USER_SET: {
            if (action.details == null) return update(store, {user: {$set: null}});

            return update(store, {
                user: {
                    $set: {
                        firstName: action.details.firstName,
                        lastName: action.details.lastName,
                        company: action.details.company,
                        organizationId: action.details.organizationId,
                        displayName: action.details.displayName,
                        email: action.details.email,
                        photoURL: action.details.photoURL,
                        uid: action.details.uid,
                        acl: action.details.acl,
                    } as UserAuth,
                },
            });
        }

        case ACTIONS.USER_SET_UID: {
            return update(store, {
                user: {
                    $set: {
                        uid: action.details.uid,
                    } as UserAuth,
                },
            });
        }

        case ACTIONS.SET_PERMISIONS: {
            return update(store, {user: { acl: {$set: {can: action.can}}}});
        }
        case ACTIONS.SET_LOGOUT_TIME: {
            return update(store, { logoutTime: { $set: +action.payload.logoutTime } })
        }
        case ACTIONS.SET_MAP_API_TOKEN:
            return update(store, { mapApiToken: { $set: action.payload.mapApiToken } });
        default:
            return store;
    }
};
