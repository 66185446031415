
import { IFormInput, IProps } from './ReadSettings.interfaces';
import FileImportIcon from '../SVG-components/file_import';
import React from 'react';
import { useForm } from 'react-hook-form';
import { createConverter } from '../../shared/convertors/create-converter';
import { createParser, FileFormat } from '../../shared/parsers';
import { createFileReader, FileReaders} from '../../shared/files'
import styles from './ReadSettings.module.scss';

const readFileAsText = createFileReader(FileReaders.TEXT);


const ReadSettings: React.FunctionComponent<IProps> = ({
  fileFormat,
  onImport,
  onError
}) => {
  const { register, reset } = useForm<IFormInput>();

  const handleFileSettingsReaded = (fileContent: string) => {
    const parse = createParser(fileFormat);
    const convertToJson = createConverter(fileFormat, FileFormat.JSON);

    try {
      const content = parse(fileContent);
      const json = convertToJson(content);
      onImport(json);
      reset();
    } catch(e) {
      onError?.({
        name: 'Error during file parse',
        message: 'Something went wrong during file parsing ensure that you are using right format'
      });
    }
  }

  const handleFileSettingsChange = (e) => {
    e.persist();
    const file = e.target.files[0];
    readFileAsText(file).then(handleFileSettingsReaded);
  }

  return (
    <form
      className={styles['form']}
    >
      <label>
        <input
          ref={register}
          type="file"
          id="fileSettings"
          name="fileSettings"
          accept={`.${fileFormat}`}
          onChange={handleFileSettingsChange}
        />
        <FileImportIcon className={styles['form__icon']}/>
      </label>
    </form>
  );
}

export default ReadSettings;