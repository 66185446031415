import React, {useEffect, useState} from "react";

import './IconTabs.scss';
import {ICON_NAMES_BY_TABS, ICON_TABS} from "../../shared/constants";

const IconTabs = ({ otherIconsChildren, selectIcon }) => {

    const [selectedTab, setSelectedTab] = useState<string>(ICON_TABS[0]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        importTabImages();
    }, [selectedTab])

    const importTabImages = () => {
        setImages([]);
        selectedTab !== 'Other' && ICON_NAMES_BY_TABS[selectedTab.toLowerCase()]
            .map((name) => {
                try {
                    const image = require(`../../assets/svg/${selectedTab.toLowerCase()}/${name}.svg`);
                    setImages(prevState => [...prevState, {name, path: image}]);
                } catch (e) {
                    console.error('Cannot find picture:' + name);
                }
            });
    }

    const convertBase64ToFile = async (base64String, fileName) => {
        const response = await fetch(base64String);
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: 'image/svg+xml' });
        selectIcon(file);
    }

    return (
      <div className={'icon-tabs__container'}>
          <div className={'tabs'} >
              {ICON_TABS.map((tab) => {
                  return (
                      <div
                          key={tab}
                          className={`tab ${selectedTab === tab ? 'active' : ''}`}
                          onClick={() => setSelectedTab(tab)}
                      >
                          {tab}
                      </div>
                  )
              })}
          </div>
          <div className={'icons-container'}>
              {selectedTab === 'Other' ? otherIconsChildren : (
                  images.length ?
                      images.map(({name, path}) => {
                          return (
                              <div key={name} className={'icon'} onClick={() => convertBase64ToFile(path, name)}>
                                  <img src={path} alt={name}/>
                              </div>
                          )
                      })
                      : <p className={'empty-icons-list'}>Not found icons</p>
              )}
          </div>
      </div>
    );
};

export default IconTabs;