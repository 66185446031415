import {useSelector} from 'react-redux';

import {
    iFullStoreState,
    ReportContainer,
    Units
} from '../../../../shared/interfaces';


const useOneDeviceReportUnits = () => {
    const displayRecords = useSelector<iFullStoreState, ReportContainer>(s => s.report.displayRecords || {});
    const deviceIds = Object.keys(displayRecords).reduce((acc, record) => {
        if(acc.indexOf(displayRecords[record].device) === -1) {
            return [...acc, displayRecords[record].device]
        }
        return acc
    }, []);

    const oneDeviceReport = deviceIds.length === 1;
    let deviceUnits = useSelector<iFullStoreState, Units>(
        s => s.devicesData.devicesDetails.getIn([deviceIds[0], 'units'], Units.miles),
    )
    deviceUnits = oneDeviceReport ? (
        deviceUnits
    ) : Units.miles ;

    return deviceUnits;
}

export default useOneDeviceReportUnits;