import React, { FC, useEffect } from "react";
import { useRedux } from "../../../../states/redux-state";
import { store } from "../../../../stores/store";
import RenderTripReportData from "./RenderTripReportData";
import RenderStaticReportData from "./RenderStaticReportData";

import { ReactComponent as NotificationSvg } from "../../../../assets/svg/notification.svg";
import { ReactComponent as MapPointerSvg } from "../../../../assets/svg/map_pointer.svg";
import { Button, ButtonSize, ButtonStyles } from "../../../Button";
import { withPrevPair } from "../../../../shared/ramda";
import {
    isMobile,
  } from "../../../../shared/helpers";
import {
    addIndex,
    identity,
    map,
    path,
    pipe,
  } from "ramda";
import {
    iDevicePing,
    iTrip,
    Units,
  } from "../../../../shared/interfaces";

type NewDeviceReportSectionsProps = {
    displayName?: string;
    records: Array<iDevicePing | iTrip>;
    getReportData: (data: object) => void;
    showAllRoute: boolean;
    showTrip: (item: { device: "string"; tripId: string }) => void;
    showAllTrips?: () => void;
    hideAllTrips?: () => void;
    isTimeClock?: boolean;
  };

const NewDeviceReportSections: FC<NewDeviceReportSectionsProps> = ({
    displayName,
    records,
    getReportData,
    showAllRoute,
    showAllTrips,
    showTrip,
    hideAllTrips,
    isTimeClock,
  }) => {
    const deviceId = pipe(path([0, "device"]))(records);
  
    const state = store.getState();
    const deviceUnits = state.devicesData.devicesDetails.getIn([deviceId, "units"], Units.miles);
    const deviceTimezone = state.devicesData.devicesDetails.getIn([deviceId, "timezone"], "EST");
    const ids = state.devicesTripsPoints.getIn([deviceId, "reportTripsIds"]);
    const reportTripsIds = ids ? [...ids.values()] : [];
  
    const showAllRoutes = reportTripsIds.length
      ? reportTripsIds.some((item) => item)
      : false;
    const reportType = useRedux((s) => s.report.details.reportType);
    const [isCondensed, setIsCondensed] = React.useState(false);
    const tableRef = React.useRef(null);
  
    // Effect to check if the table is causing page scroll
    // if so set the condensed flag
    useEffect(() => {
      if (!tableRef.current || !isMobile) return;
  
      let next = tableRef.current;
      while (next.parentElement) {
        if (next.parentElement.scrollWidth > next.parentElement.clientWidth) {
          setIsCondensed(true);
          break;
        }
  
        next = next.parentElement;
      }
      }, [records, tableRef]);
  
    useEffect(() => {
      getReportData({ [deviceId]: { deviceUnits, deviceTimezone } });
    }, [deviceId]);
  
    const outTable = reportType === "travel" ? addIndex(map) : identity;
  
    const getRecordsWithPrevPair = withPrevPair(records);
  
    const getTripOrPointData = outTable((tripOrPoint) => {
      const trip: {
        current: iTrip;
        previous: iTrip;
      } = tripOrPoint;
  
      const points: Array<{
        current: iDevicePing;
        previous: iDevicePing;
      }> = tripOrPoint;
  
      return reportType === "travel" ? trip : points;
    });
  
    const reportData = getTripOrPointData(getRecordsWithPrevPair);
    return (
      <div className="tables-wraper" ref={tableRef}>
          {!isTimeClock && (
              <div
                  className={`d-flex justify-content-between ${(isMobile || window["cordova"]) && "align-items-center"
                  }`}
                  style={{marginTop: "30px"}}
              >
                  <h3
                      style={{
                          maxWidth: 450,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                      }}
                  >
                      {displayName}
                  </h3>
                  {!(reportType === 'permissions') && (
                      <div className={isMobile || window["cordova"] ? "d-flex" : ""}>
                          <Button
                              className="but but-toggle but-24px"
                              styleType={ButtonStyles.GRAY_INACTIVE}
                              style={
                                  isMobile || window["cordova"] ? {height: "max-content"} : {}
                              }
                              size={ButtonSize.XSM}
                          >
                              <NotificationSvg
                                  className={isMobile || window["cordova"] ? "icon-smd" : "icon-sm"}
                              />
                          </Button>
                          <Button
                              className={`but but-toggle but-24px ${
                                  showAllRoutes ? "active" : ""
                              }`}
                              styleType={
                                  showAllRoutes
                                      ? ButtonStyles.BLACK_WHITE
                                      : ButtonStyles.GRAY_INACTIVE
                              }
                              style={
                                  isMobile || window["cordova"] ? {height: "max-content"} : {}
                              }
                              size={ButtonSize.XSM}
                              onClick={showAllRoutes ? hideAllTrips : showAllTrips}
                          >
                              <MapPointerSvg
                                  className={isMobile || window["cordova"] ? "icon-smd" : "icon-sm"}
                              />
                          </Button>
                      </div>
                  )}
              </div>
          )}
          {(reportType === 'travel' || reportType === 'unauthorized') ? (
              reportData.map((trip) => (
                  <div className="report-table-wraper" key={trip.current.tripId}>
                      <RenderTripReportData
                          trip={trip}
                          showTrip={showTrip}
                          records={records}
                          deviceUnits={deviceUnits}
                          deviceTimezone={deviceTimezone}
                          showAllRoutes={showAllRoutes}
                          deviceId={deviceId}
                      />
                  </div>
              ))
          ) : reportType === 'time-clock' ? (
              <div className="report-table-wraper">
                  {reportData && <RenderStaticReportData
                      points={reportData}
                      deviceUnits={deviceUnits}
                      deviceTimezone={deviceTimezone}
                      isCondensed={isCondensed}
                      isTimeClock={isTimeClock}
                  />}
              </div>
          ) : reportType === 'permissions' ? (
              <h1>Permissions</h1>
          ) : (
              <div className="report-table-wraper">
                  {reportData && <RenderStaticReportData
                      points={reportData}
                      deviceUnits={deviceUnits}
                      deviceTimezone={deviceTimezone}
                      isCondensed={isCondensed}
                  />}
              </div>
          )}
      </div>
    );
};

export default NewDeviceReportSections;
