import { UserCan } from "../../shared/constants";
import { clientDb, globaldb } from "../firebase";
import { DefaultAclFb } from "../interfaces";
import { DEFAULT_PERMISSIONS_COLLECTIONS } from "./firebase-constants";


export const userCan = async (userId: string, userCan: UserCan): Promise<boolean> => {
    const res = (await clientDb().child(`acl/can/${userCan}/${userId}`).once("value")).val()
    return res === true || res === false;
}

export const toggleUserPermission = async (userId: string, userCan: UserCan, value: boolean): Promise<boolean> => {
    await clientDb().child(`acl/can/${userCan}/${userId}`).set(value? value: null);
    return value;
}

export const getDefaultAcl = async (): Promise<DefaultAclFb> => {
    const defaultPermissions = await (await globaldb.child(DEFAULT_PERMISSIONS_COLLECTIONS).once("value")).val();
    return defaultPermissions || {};
}