import React from 'react';
import './index.scss';

const ToggleSwitch = ({isChecked, onToggle, label}) => {
  return (
    <div className='toggle-switch-container'>
      <label className='toggle-switch'>
        <input
          type='checkbox'
          checked={isChecked}
          onChange={onToggle}
        />
        <span className='slider'></span>
      </label>
      <span className='toggle-label'>{label}</span>
    </div>
  );
};

export default ToggleSwitch;
