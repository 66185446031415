import Marker from "react-google-maps/lib/components/Marker";
import React from "react";
import {useSelector} from "react-redux";
import {iFullStoreState, iLocation} from "../../shared/interfaces";
import { getMapZoom as getMapZoomFromStore } from "../../stores/reducers/gmap-reducers";
import { icoSize } from "../../shared/helpers";

const UserMarker = () => {
  const location = useSelector<iFullStoreState, iLocation>(state => state.gmap.userLocation);
  const mapZoom = useSelector<iFullStoreState>(state => getMapZoomFromStore(state.gmap));
  const size = icoSize(mapZoom);
  return (
      <Marker
          zIndex={9}
          position={location}
          icon={{
              url: "images/Dot.svg",
              size: new google.maps.Size(size * 2, size * 2),
              anchor: new google.maps.Point(size, size * 2),
              scaledSize: new google.maps.Size(size * 2, size * 2),
          }}
      />
  )
};

export default UserMarker;
