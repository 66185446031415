import React from 'react';
import Modal from "../../../Modal";
import {Fa} from "../../../elements/fa";
import {faSpinner, faTimes} from "@fortawesome/fontawesome-free-solid";
import RenderPlayers from "./RenderPlayers";
import AvailableTime from "./AvailableTime";
import RenderChannels from "./RenderChannels";
import styles from "./deviceStatuses.module.scss";

const LiveViewModal = ({
    closeModal,
    openLiveViewPlayer,
    RenderPlayersProps,
    AvailableTimeProps,
    ChannelsProps,
    selectedChannels,
    availableTime,
    loadingButton,
    clickView,
}) => {
    return (
        <Modal className={styles.modal}>
            <div className={styles.modalChannels}>

                <div className={styles.buttonClose}>
                    <button className={styles.modal_header__close_ml} onClick={closeModal}>
                        <Fa icon={faTimes} className={styles.modalCamera__icon}/>
                    </button>
                </div>

                {openLiveViewPlayer ? (
                    <>
                        <div className={styles.view}>
                            {selectedChannels.length > 0 && <RenderPlayers {...RenderPlayersProps}/>}
                            <AvailableTime {...AvailableTimeProps}/>
                        </div>
                    </>
                ) : (
                    <>
                        {availableTime > 0 && (
                            <>
                                <h3>Select the channels to live stream</h3>

                                <RenderChannels {...ChannelsProps}/>

                                <button
                                    className={styles.viewButton}
                                    onClick={clickView}
                                    disabled={!selectedChannels.length}
                                >
                                    {loadingButton ? <Fa icon={faSpinner} spin /> :"View"}
                                </button>
                                <AvailableTime {...AvailableTimeProps}/>
                            </>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default LiveViewModal;
