import React, { useEffect, useState } from "react";
import { useRedux } from "../../../../states/redux-state";
import { iActivityReportData } from "../../../../shared/interfaces";
import styles from "./styles.module.scss";
import Loader from "../../Billing/Other/Loader";

const ActivityReport = () => {
  const displayRecords = useRedux(s => s.report.displayRecords) || [];
  const [activityRecords, setActivityRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const limit = 100;
  const pages = Math.ceil(displayRecords.length / limit);

  useEffect(() => {
    setActivityRecords(displayRecords.slice(0, limit));
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const scrollableElement = document.querySelector<HTMLElement>('.ps__rail-y');
    if (scrollableElement){
      scrollableElement.style.top = '0px';
    }

    const startFrom = (page - 1) * limit;
    setActivityRecords(displayRecords.slice(startFrom, startFrom + limit));

    setIsLoading(false);
  }, [page]);

  const nextPage = () => {
    setPage(prevPage => prevPage + 1);
  }

  const previousPage = () => {
    setPage(prevPage => prevPage - 1);
  }

  if (displayRecords.length === 0) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <table className={styles.ActivityReport}>
        <thead>
        <tr>
          <th>Name</th>
          <th>Last Report</th>
        </tr>
        </thead>
        <tbody>
        {activityRecords.map((record: iActivityReportData, i) => (
          <tr key={record.deviceId}>
            <td>{record.deviceName}</td>
            <td>{record.ago}</td>
          </tr>
        ))}
        </tbody>
      </table>
      <div className={styles.ActivityPagination}>
        {page > 1 && <button onClick={previousPage}>Previous</button>}
        {page < pages && <button onClick={nextPage}>Next</button>}
      </div>
    </>
  );
};

export default ActivityReport;