import { has, or, isEmpty, not, curryN } from "ramda";

export const isNothing = x => or(isEmpty(x), not(x))

export const con = (fn): any => x => !fn(x)

export const thrower = (msg: string) => (...any) => {
    throw Error(msg)
}

export const bounded = curryN(3, (min: number, max: number, n: number) =>
    Math.min(
        Math.max(
            n, min
        ),
        max
    )
)

export const safeHas = p => o => has(p, o || {});

export const whole = Math.round;

export const withPrevPair = (arr: any[]) => arr.reduce((carry, current, idx) => [...carry, {
    previous: carry[idx - 1] ? (carry[idx - 1].current) : null,
    current
}], [])