import React, { CSSProperties as css } from 'react';
import { connect } from 'react-redux';
import { equals, pipe, path } from 'ramda';
import * as humanFormat from 'human-format';
import { faArrowAltCircleUp, faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid';
import { faArrowAltCircleDown } from '@fortawesome/fontawesome-free-regular';

import C from '../../shared/constants';
import { iTrip, iDevicePing, iList, iDeviceDetails, iFullStoreState } from '../../shared/interfaces';
import { idValArr, extractDevice } from '../../shared/helpers';
import { BaseComponent } from '../../shared/BaseComponent';
import { Fa } from '../elements/fa';
import { DevicesDetailsContainer } from '../../stores/reducers/devicesData';

// const mapStateToProps = (state:iFullStoreState, ownProps) => ({})
interface iProps {
    columns: {flex?: number, prop: string, name: string, cellMapper: (row: iTrip|iDevicePing|any, device?: iDeviceDetails|undefined, id?: string) => JSX.Element|string }[]
    lockColumn?: string,
    data: iList<iTrip|iDevicePing|any>,
    style?: css,
    rowHeight?: number,
    perPage?: number,
    noMeta?: boolean,
    pageSize?: number,
    colSort?: string[]
}

const sortCmp = sort => ({prop: prop1}, {prop: prop2}) => {
    const aOrd = sort.indexOf(prop1) === -1 ? Infinity : sort.indexOf(prop1);
    const bOrd = sort.indexOf(prop2) === -1 ? Infinity : sort.indexOf(prop2);

    return aOrd - bOrd;
}


export class Table extends BaseComponent<iProps, {page, recordsPage}> {
    state = {
        page: 1,
        recordsPage: 1
    }

    pageLeft = () => this.setState(s => ({page: s.page - 1}))
    pageRight = () => this.setState(s => ({page: s.page + 1}))

    pageDown = () => this.setState(s => ({recordsPage: s.recordsPage + 1}))
    pageUp = () => this.setState(s => ({recordsPage: s.recordsPage - 1}))

    debugRender = () => {
        const { columns, lockColumn, noMeta, pageSize, data, style={}, perPage=3, rowHeight=32, colSort=[], children = false } = this.props;
        const { page, recordsPage } = this.state;


        const visColumns = columns
            .filter(({ prop }) => prop !== lockColumn)
            .sort(sortCmp(colSort))
            .filter((_, idx) => perPage === Infinity || (idx >= (page*perPage-perPage) && idx < page*perPage ))

        const colCount = columns.length;

        let visData = {} as iList<iTrip|iDevicePing>;

        if (!pageSize) visData = data;
        else
            idValArr(data).filter((_, idx) => idx >= (recordsPage - 1)*pageSize && idx <= recordsPage*pageSize).forEach(({id, val}) => visData[id] = val);

        const pageCount = Math.ceil((colCount - (!!lockColumn ? 1 : 0)) / perPage);

        const visColCount = visColumns.length;

        return (
            <div style={style}>

                <VisRecords page={recordsPage} perPage={pageSize} recordCount={data.length} />
                <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%', position: 'relative' }}>
                    {/* start of a modal */}
                    {/*<div style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundColor: C.darkGray, opacity: .5, zIndex: 1, borderTopLeftRadius: 5, borderTopRightRadius: 5}} /> */}

                    {/* Locked column */}
                    {columns
                        .filter(({ prop }) => prop === lockColumn)
                        .map(({ name /*, prop*/ }/*, idx*/) =>
                            <TableCol
                                key={name}
                                id={name}
                                formatter={columns.find(pipe(path(['name']), equals(name))).cellMapper}
                                header={name}
                                data={visData}
                                isLocked
                                rowHeight={rowHeight}
                                isFirst={true}
                                isLast={false}
                                style={{whiteSpace: 'nowrap'}}
                            />)
                    }

                    {/* print columns */}
                    <div style={{display: 'flex', flexWrap: 'nowrap', flex: 1, position: 'relative'}}>
                            {/* Meta Column */}
                            {noMeta ? null :
                                <TableCol
                                    key="meta"
                                    id="meta"
                                    formatter={(row: iTrip, device, id) => <div>{id}</div>}
                                    header={false}
                                    data={visData}
                                    rowHeight={rowHeight}
                                    isFirst={false}
                                    isLast={false}
                                    style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 3}}
                                    isMeta={true}
                                />
                            }
                        {
                            visColumns.sort(sortCmp(colSort)).map(({ name, prop, flex = 1 }, idx) =>
                                <TableCol
                                    key={name}
                                    id={name}
                                    flex={columns.find(pipe(path(['name']), equals(name))).flex || 1}
                                    formatter={columns.find(pipe(path(['name']), equals(name))).cellMapper}
                                    pageLeft={page === 1 ? false : this.pageLeft}
                                    pageRight={page === Math.ceil((colCount - (!!lockColumn ? 1 : 0)) / perPage) ? false : this.pageRight}
                                    header={name}
                                    rowHeight={rowHeight}
                                    data={visData}
                                    showPageLeft={pageCount > 1 && idx === 0}
                                    showPageRight={pageCount > 1 && idx === visColCount - 1}
                                    isFirst={!lockColumn && idx === 0}
                                    isLast={idx === visColCount - 1}

                                />)

                        }
                    </div>
                </div>
                <PageChanger perPage={pageSize} page={recordsPage} recordCount={data.length} up={this.pageUp} down={this.pageDown} />
                {!children ? null :
                    <div style={{backgroundColor: '#fff', padding: 20, display: 'flex', border: bcolor, borderBottomRadius: 5} as any}>
                        {/* summary */}
                        {children}
                    </div>
                }
            </div>
        )
    }
}

const VisRecords = ({perPage, page, recordCount}) => {
    if (!perPage || recordCount <= perPage) return null;

    return <div style={{marginRight: 10, textAlign: 'right'}}>{(page - 1)*perPage + 1 } - {Math.min(page*perPage, recordCount)} of { humanFormat(recordCount, { decimals: 1 }) } records</div>;
}

const PageChanger = ({perPage, page, recordCount, up, down}) => {

    if (!perPage || recordCount <= perPage) return null;

    const maxPage = Math.ceil(recordCount / perPage);

    return (
        <div style={{userSelect: 'none', textAlign: 'center', padding: 4, fontSize: 11, color: '#fff', backgroundColor: C.primaryColor, cursor: 'pointer', display: 'flex', justifyContent: 'space-around'}} >
            <span style={{width: '45%', cursor: 'pointer'}}>{page === 1 ? null : <span onClick={up}><Fa icon={faArrowAltCircleUp} />Previous {perPage}</span>}</span>
            <span style={{fontStyle:'italic', fontSize: 46, lineHeight: '9px'}}>/</span>
            <span style={{width: '45%', cursor: 'pointer'}}>{page === maxPage ? null : <span onClick={down}>Next {perPage} <Fa icon={faArrowAltCircleDown} /></span>}</span>
        </div>
    )
}

interface iTableColProps {
    id,
    header,
    data: iList<(iTrip|iDevicePing)>,
    isFirst,
    isLast,
    pageLeft?,
    pageRight?,
    isLocked?,
    isMeta?: boolean,
    style?: css,
    flex?: number,
    rowHeight?: number,
    formatter: (row: any, device?, id?: string) => any,
    showPageLeft?: boolean,
    showPageRight?: boolean,
}

type ITableColPropsFromStore = {
    devicesDetails: DevicesDetailsContainer;
    visMetaId?: string | false;
    visMetaDom?: any;
}

const TableCol = connect((state: iFullStoreState): ITableColPropsFromStore => ({
    devicesDetails: state.devicesData.devicesDetails,
    visMetaId: state.report.tripMeta.id,
    visMetaDom: state.report.tripMeta.dom,
}))
(class extends BaseComponent<iTableColProps & ITableColPropsFromStore, any> {

    debugRender = () => {
        const { header, formatter, data, /*id,*/ isFirst, isLast, devicesDetails, isLocked, pageLeft, pageRight, showPageLeft, flex, rowHeight, showPageRight, isMeta, visMetaId, visMetaDom, style={} } = this.props;

        let extraStyle = (id: string) => {
            if (!isMeta) return {};
            if (isMeta && visMetaId === id) return {transition: 'width .15s ease-in', width: '100%', padding: 10}
            return {width: 0, padding: 0, margin: 0, borderRightWidth: 0};
        }

        return (
            <div style={{ display: 'flex',position: 'relative', flexDirection: 'column', ...(isLocked ? {flex: 0} : {flex}), ...style, ...(isMeta ? {zIndex: visMetaId ? 3 : -1} : {}) }}>
                <div className={`t-head-${isMeta}`} style={{...headerCellCss(isFirst, isLast, isLocked), ...(isMeta ? {backgroundColor: 'transparent', borderWidth: 0, zIndex: -1} : {})}}>
                    {showPageLeft  ? <div onClick={pageLeft || (() => {})} style={chevStyle(!pageLeft, true)}><Fa icon={faChevronLeft} /></div> : null}
                    {showPageRight  ? <div onClick={pageRight || (() => {})} style={chevStyle(!pageRight, false)}><Fa icon={faChevronRight} /></div> : null}
                    <div style={{paddingLeft: showPageLeft ? 10 : 'initial', paddingRight: showPageRight ? 10 : 'initial', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{header}</div>
                </div>

                { idValArr(data).map(({ id, val: row }) => {
                    return (
                        <div key={id} style={{...tdCellCss(isFirst, isLast, isLocked, rowHeight), ...extraStyle(id)}}>
                            { isMeta && visMetaId == id ? visMetaDom : formatter(row, extractDevice(devicesDetails, row.device), id) }
                        </div>
                        )
                }) }
            </div>
        )
    }
});

const chevStyle = (disabled, isLeft): css => ({
    backgroundColor: disabled ?  C.primaryColor.lighten(.3) : C.primaryColor,
    color: '#fff',
    fontSize: 10,
    position: 'absolute',
    zIndex: 5,
    top: 0,
    bottom: 0,
    paddingLeft: 3,
    paddingRight: 3,
    cursor: disabled ? 'default' : 'pointer',
    ...(isLeft ? {left: 0} : {right: 0}),
    display: 'flex',
    alignItems: 'center',
    ...(!isLeft ? {borderTopRightRadius: 5} : {})
})

const headerHight = 40;
const cellPadding = 6;
const bcolor = `1px solid ${C.mediumGray}`;

const tdCellCss = (isFirst, isLast, isLocked, rowHeight): css => ({
    height: rowHeight,
    backgroundColor: isLocked ? C.lightGray : '#fff',
    borderBottom: bcolor,
    borderRight: bcolor,
    paddingLeft: cellPadding,
    paddingRight: cellPadding,
    borderLeft: isFirst ? bcolor : 'none',
    overflow: 'hidden',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    alignItems: 'stretch',
    position: 'relative',
    // whiteSpace: 'nowrap',
})

const headerCellCss = (isFirst, isLast, isLocked): css => ({
    position: 'sticky',
    top: 0,
    zIndex: 3,
    borderRight: bcolor,
    borderTop: bcolor,
    padding: cellPadding,
    borderBottom: bcolor,
    whiteSpace: 'nowrap',
    borderLeft: isFirst ? bcolor : 'none',
    borderTopLeftRadius: isFirst ? 5 : 0,
    borderTopRightRadius: isLast ? 5 : 0,
    display: 'flex',
    justifyContent: isLocked ? 'center' : 'flex-start',
    height: headerHight,
    backgroundColor: isLocked ? C.lightGray : C.lightGray.lighten(.05)
})
