import React, { useEffect, useState } from "react";
import "./index.scss";
import { useTable, usePagination, useFilters } from 'react-table'
import { iMaintenanceTaskExteded, selectMaintenanceTasks } from "../../../../stores/reducers/maintenance/selectors";
import Paginator from "../../../Paginator";
import { ReactComponent as Edit } from "../../../../assets/svg/edit.svg";
import { ReactComponent as Delete } from "../../../../assets/svg/delete.svg";
import { ReactComponent as FlipToFront } from "../../../../assets/svg/flip_to_front.svg"

import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from "react-redux";
import { iFullStoreState, UserAuth } from "../../../../shared/interfaces";
import { DashboardSize } from "../../../../stores/reducers/dashboardInfo";
import { Button, ButtonStyles, ButtonSize } from "../../../Button";
import { GMapModalAC, GMapModalContent } from "../../../../stores/reducers/gMapModal/AC";
import { ActionsCreator } from "../../../../stores/reducers/maintenance/actions";
import { MaintenanceTask } from "../../../../stores/reducers/maintenance/types";
import moment from "moment";
import SearchFilter from "../SearchFilter";

const MaintenanceActionsCell = (props) => {
    const { maintenanceKey, deviceKey } = props.row.original;
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
    const dispatch = useDispatch();
    const handleEditClick = () => {
        dispatch(GMapModalAC.showModal({
            contentType: GMapModalContent.EDIT_MAINTENANCE_TASK,
            params: {'taskData': props.row.original}
        }));
    };
    const handleDeleteClick = () => {
        dispatch(ActionsCreator.deleteMaintenanceTask(user, maintenanceKey, deviceKey, props.row.original));
    }

    return (
        <div className="maintenance-tasks-table__actions">
            <Edit className="maintenance-tasks-table__action-icon icon-16px" onClick={handleEditClick}/>
            <Delete className="maintenance-tasks-table__action-icon icon-16px" onClick={handleDeleteClick} />
        </div>
    )
}

const isMiliseconds = (timestamp) => Math.abs(Date.now() - timestamp) < Math.abs(Date.now() - timestamp * 1000);

const mColumns =  [
    {
        Header: 'Task',
        accessor: 'name',
        placeholder: 'Task...',
        Filter: SearchFilter
    },
    {
        Header: 'Device',
        accessor: 'deviceName',
        placeholder: "Device...",
        Filter: SearchFilter
    },
    {
        Header: 'Miles',
        accessor: 'miles_needed',
        placeholder: "Miles...",
        Filter: SearchFilter
    },
    {
        Header: "Hours",
        accessor: "hours_needed",
        placeholder: "Hours...",
        Filter: SearchFilter
    },
    {
        Header: "Date",
        accessor: (m: MaintenanceTask) => {
            if (m.hasOwnProperty('date')) {
                // @ts-ignore
                return moment(isMiliseconds(m.date) ? m.date : m.date * 1000).format("MM/DD/YYYY")
            }
            return ""
        },
        placeholder: "Date...",
        Filter: SearchFilter
    },
    {
        id: "actions",
        Cell: MaintenanceActionsCell
    }
];

const MaintenanceTasks = () => {
    const dispatch = useDispatch();

    const handleAddNewClick = () => {
        dispatch(GMapModalAC.showModal({contentType: GMapModalContent.NEW_MAINTENANCE}));
    }

    return (
        <div className="maintenance-tasks">
            <div className="maintenance-tasks__header">
                Maintenance tasks
                <Button onClick={handleAddNewClick} className="maintenance-tasks__action-button" styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD}>
                    <FlipToFront className="icon-12px"/>&nbsp;Add task
                </Button>
            </div>
            <MaintenanceTasksTable />
        </div>
    )
}

const MaintenanceTasksTable = () => {
    const mData = useSelector<iFullStoreState, iMaintenanceTaskExteded[]>(selectMaintenanceTasks);

    const [initialPage, setInitialPage] = useState(1);
    const [mDataWithoutDeletedMaintenance, setMDataWithoutDeletedMaintenance] = useState(mData);

    useEffect(() => {
        const dataWithoutDeletedMaintenance = mData.filter((task) => {
            return task.deviceKey !== "deletedMaintenance";
        });
        setMDataWithoutDeletedMaintenance(dataWithoutDeletedMaintenance);
    }, [mData])

    const {
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        // The rest of these things are super handy, too ;)
        pageCount,
        gotoPage,
        state: { pageIndex },
      } = useTable(
        {
          columns: mColumns,
          data: mDataWithoutDeletedMaintenance,
          initialState: { pageIndex: initialPage - 1, pageSize: 5 },
        },
        useFilters,
        usePagination
    )

    const handlePageChange = (newPage) => {
        if (newPage - 1 !== pageIndex) {
            setInitialPage(newPage);
            gotoPage(newPage - 1);
        }
    }

    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);

    return (
        <div className="maintenance-table-container">
            <PerfectScrollbar>
                <div className={`maintenance-tasks-table maintenance-tasks-table${size === DashboardSize.OPEN? "--open": ""}`}>
                    {headerGroups.map(headerGroup => {
                        return (
                            headerGroup.headers.map(column => {
                                return (
                                    <div key={column.id} className={`maintenance-tasks-table__cell maintenance-tasks-table__header`} >
                                        {column.Filter? column.render('Filter') : column.render('Header')}
                                    </div>
                                )
                            })
                        )
                    })}
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            row.cells.map(cell => {
                                return <div className={`maintenance-tasks-table__cell`} {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </div>
                            })
                        )
                    })}
                </div>
            </PerfectScrollbar>
            <div className="maintenance-required__pagination">
                <Paginator
                    current={pageIndex + 1}
                    total={pageCount}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    )
}

export default MaintenanceTasks;
