export const ACTIONS = {
  DELETE_BANK_ACCOUNT: "DELETE_BANK_ACCOUNT",
  UPDATE_CREDIT_CARD: "UPDATE_CREDIT_CARD",
  UPDATE_UNITS_NAME: "UPDATE_UNITS_NAME",
  USER_BILLING: "USER_BILLING",
  SET_ORDERS: "SET_ORDERS",
  SET_ORDERS_START: "SET_ORDERS_START",
  SET_UNITS: "SET_UNITS",
  SET_UNITS_START: "SET_UNITS_START",
  LOADING: "LOADING",
  SET_INVOICES_START: "SET_INVOICES_START",
  SET_INVOICES: "SET_INVOICES",
  UPDATE_UNITS_NAME_START: "UPDATE_UNITS_NAME_START",
  PLAID_TOKEN: "PLAID_TOKEN",
  GET_CARDS: "GET_CARDS",
  BRAINTREE_TOKEN: "BRAINTREE_TOKEN",
  ADD_CREDIT_CARD: "ADD_CREDIT_CARD",
  ADD_BANK_ACCOUNT: "ADD_BANK_ACCOUNT",
  LOADING_CARDS: "LOADING_CARDS",
};

export const billingLoading = (payload) => ({
  type: ACTIONS.LOADING,
  payload,
});

export const loadingCards = (payload) => ({
  type: ACTIONS.LOADING_CARDS,
  payload,
});

export const deleteCard = (id) => ({
  type: ACTIONS.DELETE_BANK_ACCOUNT,
  payload: {id},
});

export const setOrders = (orders) => ({
  type: ACTIONS.SET_ORDERS,
  payload: {orders},
});

export const setOrdersStart = (payload ={}):any => ({
  type: ACTIONS.SET_ORDERS_START,
  payload
});

export const setUnits = (units) => ({
  type: ACTIONS.SET_UNITS,
  payload: { units },
});

export const setUnitsStart = (payload = {}):any => ({
  type: ACTIONS.SET_UNITS_START,
  payload,
});


export const setInvoices = (invoices) => ({
  type: ACTIONS.SET_INVOICES,
  payload: { invoices },
});

export const setInvoicesStart = (payload = {}):any => ({
  type: ACTIONS.SET_INVOICES_START,
  payload,
});

export const updateCreditCard = (info) => ({
  type: ACTIONS.UPDATE_CREDIT_CARD,
  payload: info,
});

export const updateUnitsNameStart = (payload) => ({
  type: ACTIONS.UPDATE_UNITS_NAME_START,
  payload
});

export const updateUnitsName = (id, value) => ({
  type: ACTIONS.UPDATE_UNITS_NAME,
  payload: { id, value },
});

export const userBilling = (token) => {
  return {
    type: ACTIONS.USER_BILLING,
    payload: {token}
  }
}

export const getCards = (cards) => ({
    type: ACTIONS.GET_CARDS,
    payload: { cards } ,
})

export const getBrainTreeToken = (brainTreeToken) => ({
  type: ACTIONS.BRAINTREE_TOKEN,
  payload: { brainTreeToken },
});

export const getPlaidToken = (plaidToken) => ({
  type: ACTIONS.PLAID_TOKEN,
  payload: { plaidToken },
});

export const addCreditCard = (newCard): any => ({
  type: ACTIONS.ADD_CREDIT_CARD,
  payload: { newCard },
});

export const addBankAccount = (paymentMethod) => ({
    type: ACTIONS.ADD_BANK_ACCOUNT,
    payload: { paymentMethod },
})