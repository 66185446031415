import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {Route, Router, Switch, useParams} from 'react-router-dom';

import {fenceHistory} from '../../../stores/store';
import {FenceGroup, iFullStoreState, ItemType} from '../../../shared/interfaces';
import VisTagsDevices from '../tab-bodies/Tags-Devices';
import {isMobile} from '../../../shared/helpers';
import {withSentry} from '../../../shared/hoc/withSentry';
import {FenceBodyLayout, MapEmptyBodyLayout} from '../tab-bodies/BodyLayout';
import {useRedux} from '../../../states/redux-state';
import FenceBody from '../tab-bodies/FenceBody/FenceBody';
import FenceEdit from '../tab-bodies/EditFence/EditFence';
import FilterEdit from '../FilterEdit/FilterEdit';
import PathEdit from '../tab-bodies/EditPath/EditPath';
import RegionOverview from '../tab-bodies/RegionOverview/RegionOverview';
import LoadFenceAsyncWrapper from "./ LoadFenceAsyncWrapper";
import AddNoteWithToggleActions from "./AddNoteWithToggleActions";
import AlertSettingsPageWithToggleActions from "./AlertSettingsPageWithToggleActions";
import TagItemWithToggleActions from "./TagItemWithToggleActions";
import LabelItemWithToggleActions from "./LabelItemWithToggleActions";
import LayersIcon from "../../SVG-components/layers_icon";
import {IProps} from "./FencesContainer.interfaces";
import styles from './FencesContainer.module.scss';

function FencesContainer (props: IProps): JSX.Element {
    const tagView = useSelector<iFullStoreState, boolean>((s) => !!s.tagsDevicesMap.showedTagsIds.size);
    const {style = {}} = props;
    const {itemId, itemType} = useParams<{ itemType: string; itemId: string }>();
    const {pathname} = fenceHistory.location;
    const [show, setShow] = useState(false);
    const isDriver = useRedux((s) => s.auth.isDriver);

    React.useEffect(() => {
        // no redirect needed if on base
        if (pathname == '/') return;

        if (!tagView && (!itemId || !itemType)) {
            fenceHistory.push('/');
        }
    }, [tagView, itemType, itemId, pathname]);

    if (isDriver) return null;

    const IS_PUBLIC_VIEW = window.location.href.includes('public-view');

    return !IS_PUBLIC_VIEW ? (
        <Router history={fenceHistory}>
            <div
                className={`fence-container ${!isMobile ? styles.fencesContainer : ''} ${!isMobile && show ? styles.fencesContainerOpened  : ''}`}
                style={isMobile ? {} : {...style}}
            >
                <Route path="/:tab(map|fences|vis-tag-devices)?">
                    <div
                        className={`${isMobile ? '' : styles.layersBtn}`}
                        onClick={(): void => setShow(!show) }
                    >
                        <LayersIcon/>
                        {!isMobile ? <span className={styles.layersBtnText}>Layers</span> : null}
                    </div>
                </Route>

                <Switch>
                    <Route
                        exact
                        path="/fences"
                        render={(all): JSX.Element => <FenceBody {...all} itemType={itemType as ItemType} itemId={itemId} />}
                    />
                    <Route exact path="/vis-tag-devices" component={VisTagsDevices} />
                    <Route
                        exact
                        path={`/:itemType/:itemId/${FenceGroup.FENCE}/:fenceId/edit`}
                        render={(all): JSX.Element => {
                            return <LoadFenceAsyncWrapper
                                isVisible={show}
                                toggleVisible={setShow}
                                tagView={tagView}
                                setEditMode={true}
                                itemType={itemType}
                                itemId={itemId}
                                comp={FenceEdit} {...all} />;
                        }}
                    />
                    <Route
                        exact
                        path={'/filters/:filterId/edit'}
                        render={(): JSX.Element => <FilterEdit  isVisible={show} toggleVisible={setShow} />}
                    />
                    <Route
                        exact
                        path={'/filters'}
                        render={(): JSX.Element => <FenceBodyLayout isVisible={show} toggleVisible={setShow} needFencesTab={null} showTabs={true} activeTab={1}/>}
                    />
                    <Route
                        exact
                        path={'/fences-tab'}
                        render={(): JSX.Element => <FenceBodyLayout isVisible={show} toggleVisible={setShow} needFencesTab={null} showTabs={true} activeTab={0}/>}
                    />
                    <Route
                        exact
                        path={`/:itemType/:itemId/${FenceGroup.PATH}/:fenceId/edit`}
                        render={(all): JSX.Element => {
                            return <LoadFenceAsyncWrapper
                                isVisible={show}
                                toggleVisible={setShow}
                                tagView={tagView}
                                setEditMode={true}
                                itemType={itemType}
                                itemId={itemId}
                                comp={PathEdit} {...all} />;
                        }} />
                    <Route
                        exact
                        path={'/:itemType/:itemId/:fenceGroup/fence-details/:fenceId'}
                        render={(all): JSX.Element => {
                            return <LoadFenceAsyncWrapper
                                isVisible={show}
                                toggleVisible={setShow}
                                tagView={tagView}
                                setEditMode={true}
                                itemType={itemType}
                                itemId={itemId}
                                comp={RegionOverview} {...all} />;
                        }} />

                    <Route
                        exact
                        path={'/tag-fence/:isFence/:type/:itemId'}
                        render={(all): JSX.Element => <TagItemWithToggleActions {...all} />} />
                    <Route
                        exact
                        path={'/label-fence/:isFence/:type/:itemId'}
                        render={(all): JSX.Element => <LabelItemWithToggleActions {...all} />} />
                    <Route
                        exact
                        path="/:deviceOrFence(fence)/:deviceOrFenceId/notes/:action(add|edit)/:noteId"
                        render={(all): JSX.Element => <AddNoteWithToggleActions {...all} />} />
                    <Route
                        exact
                        path="/:itemType/:fenceType/:deviceId/:itemId/alerts/:eventKey"
                        render={(all): JSX.Element => <AlertSettingsPageWithToggleActions isVisible={show} toggleVisible={setShow} {...all} />} />

                    <Route
                        exact
                        path="/:tab(map|fences|vis-tag-devices)?"
                        render={(): JSX.Element => <FenceBodyLayout isVisible={show} toggleVisible={setShow} needFencesTab={null} showTabs={true} /> }/>

                    <Route path="/" render={(all): JSX.Element => <MapEmptyBodyLayout {...all} isVisible={show} toggleVisible={setShow} itemType={itemType} itemId={itemId} />} />
                </Switch>
            </div>
        </Router>
    ) : null;
}

export default withSentry(FencesContainer);
