// import { iMaintenanceTaskExteded } from '../../stores/reducers/maintenance/selectors';
import { clientDb, clientStore, updatesDb } from '../firebase';
import firebase from 'firebase';
import { /*iCreateMaintenance,*/ iMaintenanceTask, UserAuth, iCompleteMaintenanceTask, /*iMaintenanceUpcomingTask, iDeviceDetails, iList,*/ DevicePingContainer } from '../interfaces';
// import { globaldb } from "../../shared/firebase";
import { iGetReportPropsWrapper } from './report-db';
import { extractDevicesFromTags } from '../helpers';

const maintenanceDb = () => clientDb().child('newMaintenance');
const maintenanceTaskOptionsDb = () => clientDb().child("maintenanceTasksOptions");
const completedMaintenanceDb = () => clientStore().collection("maintenanceReports");

export const dbMaintenanceWatch = callback => maintenanceDb().on("value", res => {
    callback(res.val());
}, err => console.log(err));

export const dbMaintenanceTaskOptionsWatch = callback => maintenanceTaskOptionsDb().on("value", res => {
    callback(res.val() || {});
}, err => console.log(err));

export const dbMaintenanceReportWatchDb = callback => clientDb().child("reporting").child("completedMaintenance").on("value", res => {
    callback(res.val());
})

export interface iCreateMaintenanceTaskDb {
    deviceKey: string;
    maintenance: iMaintenanceTask;
    mainMaintenance: {
        deviceName: string;
        taskName: string;
        upcomingHours: string;
        upcomingMiles: string;
    },
    contacts?: Array<string>;
}

export const createMaintenanceTaskDb = user => async ({
    deviceKey,
    maintenance,
    mainMaintenance
}: iCreateMaintenanceTaskDb) => {
    console.log("mdb", maintenance);
    const res = await maintenanceDb().child(deviceKey).push(maintenance);
    // await maintenanceDb().child(deviceKey).child(res.key).child("mainUpcoming").push(mainMaintenance);
    return maintenanceTaskUpdateDb(user)({deviceKey, maintenanceKey: res.key})
}

export const editMaintenanceTaskDb = user => async ({
    deviceKey, maintenanceKey, ...editMaintenance
}) => {
    await maintenanceDb().child(deviceKey).child(maintenanceKey).update(editMaintenance);
    return maintenanceTaskUpdateDb(user)({deviceKey, maintenanceKey});
}

export interface iDeleteMaintenaceTaskDb {
    deviceKey: string;
    maintenanceKey: string;
}

export const deleteMaintenanceTaskDb = (user) => async ({
    deviceKey,
    maintenanceKey,
    maintenances
}) => {
    await maintenanceDb().child('deletedMaintenance').child(deviceKey).child(maintenanceKey).set({ deviceKey, maintenanceKey, ...maintenances });;
    await maintenanceDb().child(deviceKey).child(maintenanceKey).remove();
    return maintenanceTaskUpdateDb(user)({deviceKey, maintenanceKey})
}

export interface iCreateMaintenanceTaskUpdateDb {
    deviceKey: string;
    maintenanceKey: string;
}

export const maintenanceTaskUpdateDb = (user: UserAuth) => ({
    deviceKey,
    maintenanceKey
}: iCreateMaintenanceTaskUpdateDb) => {
    return updatesDb().child(`|clients|${user.beToken.clientId}|device-details|${deviceKey}|newMaintenance|${maintenanceKey}`).set(true);
}

export interface iCompleteMaintenanceTaskUpdate {
    deviceKey,
    maintenanceKey,
    upcomingMaintenanceKey
}


export const completeMaintenanceTaskDb = (user: UserAuth) => async ({deviceKey, maintenanceKey, ...maintenanceTask}: iCompleteMaintenanceTask) => {
    await completedMaintenanceDb().add({
        deviceKey, maintenanceKey, ...maintenanceTask
    });
    await maintenanceDb().child(deviceKey).child(maintenanceKey).remove();
    return await maintenanceTaskUpdateDb(user)({deviceKey, maintenanceKey})
}

export const completeExtraMaintenanceTaskDb = (user: UserAuth) => async ({deviceKey, ...maintenanceTask}) => {
    console.log("arg", deviceKey, maintenanceTask)
    return await completedMaintenanceDb().add({
        deviceKey, ...maintenanceTask
    });
}

export const deleteCompletedMaintenanceTaskDb = async ({deviceKey, taskKey}) => {
    await completedMaintenanceDb().doc(taskKey).delete();
};

export const fetchCompletedMaintenanceDb = async (obj: iGetReportPropsWrapper, devices: DevicePingContainer) => {
    const { filters, allTags } = obj;
    const devicesFromFilter = obj.filters.devices || {};
    const devicesFromTags = extractDevicesFromTags(filters, allTags) || [];
    const devicesKeys = new Set([
        ...Object.keys(devicesFromFilter),
        ...devicesFromTags
    ]);
    const queries: firebase.firestore.Query<firebase.firestore.DocumentData>[] = [];
    obj.dates.forEach(d => {
        queries.push(
            completedMaintenanceDb()
                .where("taskCompleteInfo.date", ">=", d.startDate.clone().startOf("day").toDate().getTime())
                .where("taskCompleteInfo.date", "<=", d.endDate.clone().endOf("day").toDate().getTime())
        );
    });

    const resData = {};
    await Promise.all(
        queries.map(async q => {
            const res = (await q.get()).docs;
            res.forEach(d => {
                const data = d.data();
                if (!devicesKeys.size) {
                    resData[d.id] = data;
                    console.log("size", data);
                } else if (devicesKeys.has(data.deviceKey)) {
                    resData[d.id] = data;
                    console.log("else", data);
                }
            });
        })
    );
    return resData;
}

export const addNewMaintenanceTasksOptionDb = (user: UserAuth) => async (newTaskOption) => {
    return clientDb().child("maintenanceTasksOptions").push(newTaskOption);
}