import React from 'react';

import { MapModal } from '../elements/map-modal';
import { Col } from '../elements/flex';
import { LoadingModal } from '../elements/loading';

const txtChangeTime = 1000 * 20;

export const LocatingModal = () => {
    const [attempt, setLocateAccount] = React.useState(1);

    const locatingTxt = attempt === 1
        ? 'Attempting to Locate'
        : <Col align="center">
            <div>No Response. Trying Again</div>
            <div>Attempt #{attempt}</div>
        </Col>

    React.useEffect(() => {
        setTimeout(() => setLocateAccount(attempt + 1), txtChangeTime)
    }, [attempt])

    return (
        <MapModal>
            <Col justify="center" align="center" style={{ height: '100%' }}>
                <LoadingModal size={70} subText={locatingTxt} />
            </Col>
        </MapModal>
    )
}
