import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {GMapModalAC} from "../../../../stores/reducers/gMapModal/AC";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as CloseSvg} from "../../../../assets/svg/close_icon.svg";
import {ReactComponent as Edit} from "../../../../assets/svg/edit.svg";
import './index.scss';
import moment from "moment";
import Input from "../../../../components/elements/Input/index";
import DateInput from "../../../elements/DateInput";
import {Button, ButtonSize, ButtonStyles} from '../../../Button';
import {ActionsCreator as MaintenanceAC} from '../../../../stores/reducers/maintenance/actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {isMobile} from '../../../../shared/helpers';
import {validateEditMaintenanceTask, validateSingleFieldEditMaintenanceTask} from "./EditMaintenanceTaskValidation";
import { iFullStoreState, UserAuth } from '../../../../shared/interfaces';
import MaintenanceTaskMultiselect from "../../../BLLComponents/MaintenanceTaskMultiselect";
import AddContact from "../AddContact";

const EditMaintenanceTask = ({taskData}) => {
    const dateInputClass = 'task-edit-modal-info-item-input-date';
    const dateInit = new Date(+taskData?.date);
    const initialContacts = taskData?.contacts ?? [];
    const isMiliseconds = (timestamp) => Math.abs(Date.now() - timestamp) < Math.abs(Date.now() - timestamp * 1000);
    // @ts-ignore
    const [editedData, updateEditedData] = useState({...taskData, contacts: initialContacts, date: isMiliseconds(dateInit) ? dateInit : dateInit * 1000});
    const [contact, setContact] = useState('');
    const [errors, updateErrors] = useState({});
    const dispatch = useDispatch();
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);

    const closeModal = () => {
        dispatch(GMapModalAC.hideModal());
    };

    const showErrors = (errors) => {
        if (!errors || !Object.keys(errors).length) return;
        updateErrors(errors);
    };

    const checkValidate = () => {
        return validateEditMaintenanceTask(editedData);
    };

    const singleFieldValidation = (name) => {
        return validateSingleFieldEditMaintenanceTask(name, editedData)
    };

    const handleBlur = ({target: {name, value}}: ChangeEvent<HTMLInputElement>) => {
        const {isValid, errors: newErrors} = singleFieldValidation(name);
        if (isValid) {
            updateErrors({...errors, [name]: null});
        } else {
            updateErrors({...errors, ...newErrors});
        }
    };

    const handleTaskBlur = () => {
        const {isValid, errors: newErrors} = singleFieldValidation('name');
        if (isValid) {
            updateErrors({...errors, 'name': null});
        } else {
            updateErrors({...errors, ...newErrors});
        }
    };

    const handleDateBlur = () => {
        const {isValid, errors: newErrors} = singleFieldValidation('date');
        if (isValid) {
            updateErrors({...errors, 'date': null});
        } else {
            updateErrors({...errors, ...newErrors});
        }
    };

    const updateContact = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        setContact(value);
    }

    const addNewContact = (value) => {
        updateEditedData({
            ...editedData,
            contacts: [...editedData.contacts, value]
        });
        setContact('');
    }

    const deleteContact = (index) => {
        updateEditedData({
            ...editedData,
            contacts: [...editedData.contacts.slice(0, index), ...editedData.contacts.slice(index + 1)]
        });
    }

    const handleChange = ({target: {name, value}}: ChangeEvent<HTMLInputElement>) => {
        updateEditedData({...editedData, [name]: value});
    }

    const handleTaskChange = (newTasks) => {
        const newName = newTasks?.[0]?.label;
        updateEditedData({...editedData, 'name': newName});
    }

    const handleDateChange = (newTime) => {
        updateEditedData({...editedData, 'date': newTime});
    }

    const handleEditClick = () => {
        const {isValid, errors} = checkValidate();
        if (isValid) {
            const dataToSend = {...editedData, date: moment(editedData.date).toDate().getTime() / 1000}
            dispatch(MaintenanceAC.editMaintenanceTask(user, dataToSend));
            closeModal();
        } else {
            showErrors(errors);
        }
    }

    const {
        name,
        deviceName,
        miles_needed,
        miles_offset,
        hours_needed,
        hours_offset,
        date,
        contacts
    } = editedData;

    console.log(date);

    const jsx = (<>
        <div className="task-edit-modal-header">
            <p className="task-edit-modal-title">Edit Task</p>
            <button onClick={closeModal} className="task-edit-modal-close-button">
                <CloseSvg className="task-edit-modal-close-icon"/>
            </button>
        </div>
        <div className="task-edit-modal-top-block">
            <div className="task-edit-modal-multi-items-wrapper">
                <div className="task-edit-modal-info-item">
                    <p className="task-edit-modal-info-item-label">Task:</p>
                    <MaintenanceTaskMultiselect placeholder={name} multi={false} onChange={handleTaskChange} onClose={handleTaskBlur}/>
                {/*<Input name={'name'} className={`task-edit-modal-info-item-input ${*/}
                {/*    errors['name'] ? 'task-edit-modal-info-item-input-error' : ''}`} value={name}*/}
                {/*       onChange={handleChange} onBlur={handleBlur}/>*/}
                </div>
            </div>
            <p className="task-edit-modal-info-item-error">{errors['name']}</p>

            <div className="task-edit-modal-multi-items-wrapper">
                <div className="task-edit-modal-info-item">
                    <p className="task-edit-modal-info-item-label">Miles:</p>
                    <Input type="number" name={'miles_needed'} className={`task-edit-modal-info-item-input ${
                        errors['miles_needed'] ? 'task-edit-modal-info-item-input-error' : ''}`} value={miles_needed}
                           onChange={handleChange} onBlur={handleBlur}/>
                </div>

                <div className="task-edit-modal-info-item">
                    <p className="task-edit-modal-info-item-label">Offset:</p>
                    <Input type="number" name={'miles_offset'} className={`task-edit-modal-info-item-input ${
                        errors['miles_offset'] ? 'task-edit-modal-info-item-input-error' : ''}`} value={miles_offset}
                           onChange={handleChange} onBlur={handleBlur}/>
                </div>
            </div>
            <p className="task-edit-modal-info-item-error">{errors['miles_needed']} {errors['miles_offset']}</p>

            <div className="task-edit-modal-multi-items-wrapper">
                <div className="task-edit-modal-info-item">
                    <p className="task-edit-modal-info-item-label">Hours:</p>
                    <Input type="number" name={'hours_needed'} className={`task-edit-modal-info-item-input ${
                        errors['hours_needed'] ? 'task-edit-modal-info-item-input-error' : ''}`} value={hours_needed}
                           onChange={handleChange} onBlur={handleBlur}/>
                </div>

                <div className="task-edit-modal-info-item">
                    <p className="task-edit-modal-info-item-label">Offset:</p>
                    <Input type="number"name={'hours_offset'} className={`task-edit-modal-info-item-input ${
                        errors['hours_offset'] ? 'task-edit-modal-info-item-input-error' : ''}`} value={hours_offset}
                           onChange={handleChange} onBlur={handleBlur}/>
                </div>
            </div>
            <p className="task-edit-modal-info-item-error">{errors['hours_offset']} {errors['hours_needed']}</p>

            <div className="task-edit-modal-multi-items-wrapper">
                <div className="task-edit-modal-info-item">
                    <p className="task-edit-modal-info-item-label">Date:</p>
                    <DateInput closeOnDateSelect={true} name={'date'} onClose={handleDateBlur} className={dateInputClass} defaultValue={moment(date)} onDateChange={handleDateChange}/>
                </div>
            </div>
            <p className="task-edit-modal-info-item-error">{errors['date']}</p>
            <AddContact
                contact={contact}
                contacts={contacts}
                onBlur={updateContact}
                onChange={updateContact}
                onAddContact={addNewContact}
                onDeleteContact={deleteContact}
            />
        </div>
    </>);

    return (

        <div className='task-edit-modal'>
            {
                isMobile
                    ? (
                        <PerfectScrollbar>
                            {jsx}
                        </PerfectScrollbar>
                    )
                    : jsx
            }


            <div className="task-edit-modal-save-button-block">
                <Button size={ButtonSize.MD} styleType={ButtonStyles.BLACK_WHITE} onClick={handleEditClick}
                        className="task-edit-modal-save-button">
                    <Edit className={'task-edit-modal-save-button-svg'}/> Edit
                </Button>
                <Button size={ButtonSize.MD} styleType={ButtonStyles.GRAY_BLACK} onClick={closeModal}
                        className="task-edit-modal-save-button">
                    <CloseSvg className="task-edit-modal-close-icon-button"/>Cancel
                </Button>
            </div>
        </div>
    );
};

export default EditMaintenanceTask;
