import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFingerprint} from '@fortawesome/free-solid-svg-icons';

import InputBox from '../../elements/InputBox';
import PersonIcon from '../../SVG-components/person';
import LockIcon from '../../SVG-components/lock';
import {AuthAC} from '../../../stores/reducers/auth-reducer';
import {FingerprintHelper} from './FingerPrintHelper';
import {startFingerprintAuthFlow} from './EnableFingerPrint';
import Dialog, {DialogConfigSetter} from '../../Dialog';
import {faSpinner} from '@fortawesome/fontawesome-free-solid';
import {Fa} from '../../elements/fa';
import Checkbox from '../../elements/Checkbox';
import { login as loginAPI, loginByToken} from "../../../api";
import ValidationError from "../../../errors/validation-error";
import AccessError from "../../../errors/access-error";
import { IS_MOBILE_APP } from '../../../shared/constants';
import { userBilling } from "../../../stores/reducers/billing";
import instance from "../../../api/instance";
import {localStorage as Storage} from "../../../shared/storage";

const REMEMBER_ME = 'remember-me';

type SignFields = { username: string; password: string };

interface IProps {
    setForgotPassword: any, 
    setSignUp: any,
    token: string,
}

const LoginForm = ({setForgotPassword, setSignUp, token}: IProps): JSX.Element => {
    const fingerprintHelper = new FingerprintHelper();
    const dispatch = useDispatch();
    const {register, handleSubmit, setError, errors, getValues, setValue, watch} = useForm<SignFields>( {
        defaultValues: {username: '', password: ''}
    });
    const username = watch('username');

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter): void  => {
        dialogRef.current = callBack();
    };

    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        if(token) onLoginByToken()
    }, [])

    useEffect(()=> {
        const login = window.localStorage.getItem(REMEMBER_ME);
        if(login && IS_MOBILE_APP) {
            setRememberMe(true);
            setValue('username', login);
        }
    }, []);

    const verifyCredentials = (): void => {
        const username = getValues().username;
        instance.post(`${process.env.REACT_APP_API_URL}/api-fingerprint/login-process`, { 'device_id': window['device'].uuid, username })
            .then(({ data }) => data)
            .then((res) => {
                if (res.status === 'OK') {
                    dispatch(userBilling(res.oauth2));
                    dispatch(AuthAC.setFbToken(res.access_token));
                    dispatch(AuthAC.setMapApiToken({mapApiToken: res.access_token}));
                    dispatch({type: 'SET_USERNAME', username: res.username});
                    Storage.set('cached-username', res.username);
                } else {
                    const dialog = dialogRef.current;

                    dialog?.({
                        type: 'NOTIFICATION',
                        title: 'Error',
                        body: res.status,
                    });
                }

            });
    };

    const doFingerprintAuthentication = (secret: string): void => {
        const username = getValues().username;
        const options: any = {
            secret: secret,
            mode: 'DECRYPT',
            username
        };

        if (window['device'].platform === 'iOS') {
            options.ios = {
                message: 'Scanning'
            };
        }

        fingerprintHelper.authenticate(options, (error, result) => {
            if (error) {
                console.log('error: ' + JSON.stringify(error));
                const dialog = dialogRef.current;
                const errorText: any = JSON.stringify(error);

                if (errorText?.message === 'INIT_CIPHER_FAILED' || errorText?.message === 'BAD_PADDING_EXCEPTION') {
                    dialog?.({
                        type: 'NOTIFICATION',
                        title: 'Error',
                        body: 'Something went wrong. Your token is invalid. Please login with password and enable fingerprint again.',
                    });

                } else {
                    dialog?.({
                        type: 'NOTIFICATION',
                        title: 'Error',
                        body: 'Your token is missing on the current device. Please login with password and enable fingerprint again.',
                    });
                }

            } else verifyCredentials();
        });
    };

    const isFingerprintAuthAvailable = (secret: string): void => {
        fingerprintHelper.isFingerprintAvailable((error) => {
            if (error) {
                console.log('error: ' + error);
                const dialog = dialogRef.current;

                dialog?.({
                    type: 'NOTIFICATION',
                    title: 'Error',
                    body: error,
                });
            } else {
                doFingerprintAuthentication(secret);
            }
        });
    };

    const getFingerprintToken = (): void => {
        const username = getValues().username;
        if(rememberMe) {
            window.localStorage.setItem(REMEMBER_ME, username);
        }

        if (IS_MOBILE_APP) {
            setLoading(true);
            instance.post(`${process.env.REACT_APP_API_URL}/api-fingerprint/login-get-token`, {'device_id': window['device'].uuid, username})
                .then(({ data }) => data)
                .then((res) => {
                    setLoading(false);

                    if (res.status === 'OK') {
                        isFingerprintAuthAvailable(res.fingerprint_token);
                    } else {
                        const dialog = dialogRef.current;

                        dialog?.({
                            type: 'NOTIFICATION',
                            title: 'Error',
                            body: res.status,
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        }
    };

    const startBiometric = (btnNumber) => {
        btnNumber == 2 && startFingerprintAuthFlow();
    };

    const onSubmit = (fields): void => {
        if(rememberMe) {
            window.localStorage.setItem(REMEMBER_ME, fields.username);
        }

        setLoading(true);
        loginAPI({ LoginForm: fields })
            .then(async (response) => {
                const data = response.data;

                if (data.access_token) {
                    localStorage.setItem('app-login', JSON.stringify(data.data));
                    dispatch(userBilling(data.oauth2));
                    setLoading(false);
                    dispatch({type: 'SET_FP', isFinger: data.has_fingerprint_token});
                    localStorage.setItem("t", data.oauth2.access_token);
                    dispatch(AuthAC.setFbToken(data.access_token));
                    dispatch(AuthAC.setMapApiToken({mapApiToken: data.access_token}));
                    dispatch({type: 'SET_USERNAME', username: data.data.username});
                    Storage.set('cached-username', data.data.username);
                } else if (data.legacyAccount) {
                    const message = `Your account does not support the features in this application.
                        To upgrade your account at no charge please contact customer support.`;
                    throw new AccessError(message);
                } else if (data.errors){
                    throw new ValidationError(data.errors);
                } else {
                    throw new Error("Unhandled responce error");
                }

                return data;
            })
            .then(() => {
                if (IS_MOBILE_APP) {
                    fingerprintHelper.isUserHaveFingerPrint()
                        .then((res) => {
                            if (!res.has_fingerprint) {
                                //@ts-ignore
                                navigator.notification.confirm(
                                    'Do you want to enable biometric auth?',
                                    (bntNumber) => startBiometric(bntNumber),
                                    '',
                                    ['No', 'Yes']
                                );
                            }
                        })
                        .catch((err) => console.log('onSubmit: cordova',err));
                }
            })
            .catch(async (error) => {
                let dialogTitle = "";
                let dialogMessage = "";

                if (error instanceof ValidationError) {
                    (['username', 'password'] as Array<'username' | 'password'>).forEach((field) => {
                        if (error.errors?.[field]) {
                            setError(field, {
                                type: 'manual',
                                message: error.errors?.[field],
                            });
                        }
                    });
                    setLoading(false);
                    return;
                } else if (error instanceof AccessError) {
                    dialogTitle = "Access error";
                    dialogMessage = error.message;
                } else {
                    dialogTitle = "Connection error";
                    dialogMessage = 'Something went wrong. Please try again later.';
                }

                const dialog = dialogRef.current;
                await dialog?.({
                    type: 'NOTIFICATION',
                    title: dialogTitle,
                    body: dialogMessage,
                });

                setLoading(false);
                console.error(error);
            });
    };

    const onLoginByToken = () => {
        setLoading(true);
        loginByToken(token)
            .then(async (response) => {
                const data = response.data;

                if (data.access_token) {
                    localStorage.setItem('app-login', JSON.stringify(data.data));
                    dispatch(userBilling(data.oauth2));
                    setLoading(false);
                    dispatch({type: 'SET_FP', isFinger: data.has_fingerprint_token});
                    localStorage.setItem("t", data.oauth2.access_token);
                    dispatch(AuthAC.setFbToken(data.access_token));
                    dispatch(AuthAC.setMapApiToken({mapApiToken: data.access_token}));
                    dispatch({type: 'SET_USERNAME', username: data.data.username});
                    Storage.set('cached-username', data.data.username);
                } else if (data.legacyAccount) {
                    const message = `Your account does not support the features in this application.
                        To upgrade your account at no charge please contact customer support.`;
                    throw new AccessError(message);
                } else if (data.errors){
                    throw new ValidationError(data.errors);
                } else {
                    throw new Error("Unhandled responce error");
                }

                return data;
            })
            .then(() => {
                if (IS_MOBILE_APP) {
                    fingerprintHelper.isUserHaveFingerPrint()
                        .then((res) => {
                            if (!res.has_fingerprint) {
                                //@ts-ignore
                                navigator.notification.confirm(
                                    'Do you want to enable biometric auth?',
                                    (bntNumber) => startBiometric(bntNumber),
                                    '',
                                    ['No', 'Yes']
                                );
                            }
                        })
                        .catch((err) => console.log('onSubmit: cordova',err));
                }
            })
            .catch(async (error) => {
                let dialogTitle = "";
                let dialogMessage = "";

                if (error instanceof ValidationError) {
                    (['username', 'password'] as Array<'username' | 'password'>).forEach((field) => {
                        if (error.errors?.[field]) {
                            setError(field, {
                                type: 'manual',
                                message: error.errors?.[field],
                            });
                        }
                    });
                    setLoading(false);
                    return;
                } else if (error instanceof AccessError) {
                    dialogTitle = "Access error";
                    dialogMessage = error.message;
                } else {
                    dialogTitle = "Connection error";
                    dialogMessage = 'Something went wrong. Please try again later.';
                }

                const dialog = dialogRef.current;
                await dialog?.({
                    type: 'NOTIFICATION',
                    title: dialogTitle,
                    body: dialogMessage,
                });

                setLoading(false);
                console.error(error);
            });
    }

    const btnClass = classNames({
        btn: true,
        'cordova': IS_MOBILE_APP,
        'loading': loading,
    });

    const btnFingerPrint = classNames({
        btn: true,
        fingerPrintBtn: true,
        disabledFn: username.length < 1 || loading
    });

    return <form onSubmit={handleSubmit(onSubmit)}>
        <InputBox
            label="Username"
            name="username"
            register={register}
            rules={{
                required: 'Username is required',
                minLength: {value: 3, message: '3 characters minimum.'},
            }}
            error={!!errors.username}
            icon={PersonIcon}
            helperText={errors.username ? (errors.username?.message || 'Validation error') : ''}
        />
        <InputBox
            label="Password"
            type="password"
            name="password"
            register={register}
            rules={{required: 'Password is required'}}
            error={!!errors.password}
            icon={LockIcon}
            helperText={errors.password ? (errors.password?.message || 'Validation error') : ''}
        />
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {IS_MOBILE_APP && <Checkbox
                onChange={() => setRememberMe(!rememberMe)}
                checked={rememberMe}
                label='Remember me'
                labelStyles={{fontSize: '11px'}}
            />}
            <div
                style={{fontSize: '11px', cursor: 'pointer'}}
                onClick={() => setForgotPassword()}
            >
                Forgot my password
            </div>
            <div
                style={{fontSize: '11px', cursor: 'pointer'}}
                onClick={() => setSignUp()}
            >
                Sign up
            </div>
        </div>

        <div className="loginBtns">
            <button className={btnClass} type="submit">{loading ?'Logging in...' : 'Log In'}</button>
            {IS_MOBILE_APP  &&
                <button className={btnFingerPrint} onClick={getFingerprintToken} type="button">
                    {loading ? <Fa icon={faSpinner} spin /> : <FontAwesomeIcon icon={faFingerprint}/>}
                </button>
            }
        </div>
        <Dialog setupConfig={setupDialog} />
    </form>;
};

export default LoginForm;
