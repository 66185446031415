import React from "react";
import "./index.scss";
import { useTable, usePagination, useFilters } from 'react-table'
import { selectMaintenanceActiveTasks } from "../../../../stores/reducers/maintenance/selectors";
import Paginator from "../../../Paginator";
import { ReactComponent as CheckCircle } from "../../../../assets/svg/check_circle.svg";
import { ReactComponent as Circle } from "../../../../assets/svg/circle.svg";

import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from "react-redux";
import { iFullStoreState } from "../../../../shared/interfaces";
import { DashboardSize } from "../../../../stores/reducers/dashboardInfo";
import { GMapModalAC, GMapModalContent } from "../../../../stores/reducers/gMapModal/AC";
import SearchFilter from "../SearchFilter";


const StatusCell = ({value, ...props}) => {
    const dispatch = useDispatch();
    const original = props.cell.row.original;

    const handleIconClick = () => {
        dispatch(GMapModalAC.showModal({contentType: GMapModalContent.MAINTENANCE_COMPLETE_TASK, params: original}));
    }

    return (
        value
        ? <CheckCircle onClick={handleIconClick} className="status-circle--checked icon-16px"/>
        : <Circle onClick={handleIconClick} className="status-circle icon-16px"/>
    )
}

const columns =  [
    {
        Header: 'Status',
        Cell: StatusCell,
        accessor: 'status',
    },
    {
        Header: 'Task',
        accessor: 'taskName',
        placeholder: "Task...",
        Filter: SearchFilter,
    },
    {
        Header: 'Device',
        accessor: 'deviceName',
        placeholder: "Device...",
        Filter: SearchFilter,
    }
];

const MaintenanceRequiredTable = () => {
    const maintenanceActiveTasks = useSelector(selectMaintenanceActiveTasks);
    const {
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        // The rest of these things are super handy, too ;)
        pageCount,
        gotoPage,
        state: { pageIndex },
      } = useTable(
        {
          columns,
          data: maintenanceActiveTasks,
          initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        usePagination
    )

    const handlePageChange = (newPage) => {
        if (newPage -1 !== pageIndex) {
            gotoPage(newPage - 1);
        }
    }

    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);

    return (
        <div className="maintenance-table-container">
            <PerfectScrollbar>
                <div className={`maintenance-required-table maintenance-required-table${size === DashboardSize.OPEN? "--open": ""}`}>
                    {headerGroups.map(headerGroup => {
                        return (
                            headerGroup.headers.map(column => {
                                return (
                                    <div key={column.id} className={`maintenance-required-table__cell maintenance-required-table__header ${column.Header === "Status"? "maintenance-required-table__status": ""}`} >
                                        {column.Filter? column.render('Filter') : column.render('Header')}
                                    </div>
                                )
                            })
                        )
                    })}
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            row.cells.map(cell => {
                                return <div className={`maintenance-required-table__cell ${cell.column.Header === "Status"? "maintenance-required-table__status": ""}`} {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </div>
                            })
                        )
                    })}
                </div>
            </PerfectScrollbar>
            <div className="maintenance-required__pagination">
                <Paginator
                    current={pageIndex + 1}
                    total={pageCount}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    );
}

const MaintenanceRequired = () => {
    return (
        <div className="maintenance-required">
            <div className="maintenance-required__header">Maintenance Required</div>
            <MaintenanceRequiredTable />
        </div>
    )
}

export default MaintenanceRequired;

// mainUpcoming
    // -MeQCuboUEHUT7WyHfTY
        // deviceName
        // :
        // "Will 2"
        // taskName
        // :
        // "201"
        // upcomingHours
        // :
        // 0
        // upcomingMiles
        // :
        // 0
