import React, {useState} from "react";
import RadioInput from "../RadioInput/RadioInput";
import ChooseCard from "../ChooseCard/ChooseCard";
import BillingDetails from "../../Other/BillingDetails/BillingDetails";
import BillingButton from "../../Other/BillingButton";
import { IPlansFormProps } from "./interfaces";
import Modal from "../../../../Modal";
import {Button} from "@material-ui/core";

const PlansForm = ({
  inputs,
  setInputs,
  plan,
  allPeriods,
  allPlans,
  row,
  changePlan,
  loader,
  activationDevice,
  handleBillingDetails,
}: IPlansFormProps) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    changePlan();
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const selectedPlanDetails = allPlans.find(({ planType }) => plan?.planType === planType);
  const planDetails = selectedPlanDetails?.info.find(({ billingPeriods }) => billingPeriods === inputs?.period);

  return (
    <>
      <form onSubmit={handleSubmit} className="billing-form">
        <hr />
        <>
          {(row?.isActivated || activationDevice) && (
            <div className="billing-form__payment">
              <RadioInput
                inputs={inputs}
                setInputs={setInputs}
                plan={plan}
                allPeriods={allPeriods}
                row={row}
              />
            </div>
          )}
          <hr />
          <Button
            type={'button'}
            variant="outlined"
            disabled={!planDetails}
            onClick={()=>setShowConfirmationModal(true)}
          >
            Submit
          </Button>
        </>
      </form>

      {
        showConfirmationModal &&
          <Modal onBackdropClick={()=>setShowConfirmationModal(false)}>
              <div className="modal__content" style={{ width: "40vw" }}>
                  <div className="modal__header">
                      <div className="modal__header-row">
                          <h2>Please, confirm your selection</h2>
                          <button onClick={() => setShowConfirmationModal(false)}>X</button>
                      </div>
                    {selectedPlanDetails && planDetails && (
                        <div>
                          <p>You've selected <b>{selectedPlanDetails?.planType} Plan</b></p>
                          <p><b>Billing Period:</b> {planDetails?.billingPeriods}</p>
                          <p><b>Plan Price:</b> ${planDetails?.price}</p>
                        </div>
                    )}
                  </div>
                  <hr />
                      <div className="modal__confirm-btn">
                          <Button
                              type={'button'}
                              variant="outlined"
                              onClick={() => setShowConfirmationModal(false)}
                          >
                              Cancel
                          </Button>

                          <Button
                              type={'button'}
                              variant="contained"
                              onClick={handleSubmit}
                          >
                              Submit
                          </Button>
                      </div>
              </div>
          </Modal>
      }
    </>

  );
};

export default PlansForm;
