import React from "react";

const TariffItem = ({price, currency, minutes, setSelectedTariff, tariff}) => {

    const changeTariff = ({target}) => setSelectedTariff(target.checked ? tariff : null);

    return(
        <li key={price}>
            <div>
                <input type="radio" name={'tariffs'} onChange={changeTariff}/>
                <span>{` ${minutes} Minutes`}</span>
            </div>

            <p>{price} {currency}</p>
        </li>
    )
}

export default TariffItem;