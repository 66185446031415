import React, { useEffect, useRef } from "react";
import { IPeriodsRadioInput } from "./interfaces";
import "./RadioInput.scss";

const RadioInput = ({ setInputs, plan, inputs, row, allPeriods }:IPeriodsRadioInput) => {
  const radioRef = useRef(null);
  const isRadioSelected = (value: string): boolean => inputs.period === value;
  const { planType } = plan;

  useEffect(() => {
    if (radioRef?.current) {
      scrollToPeriodsPlan();
    }
  }, [radioRef.current, planType]);

  const scrollToPeriodsPlan = () => radioRef?.current.scrollIntoView({
      behavior: "smooth",
  });

  const handleChange = (e, plan_ID) => {
    const { name, value } = e.target;

    setInputs((prev) => ({
      ...prev,
      [name]: value,
      planType,
      plan_id: plan_ID,
    }));
  };

  return (
    <>
      {plan && (
        <div className="radio-input" ref={radioRef}>
          <p className="radio-input__title">
            Please pick a billing option below
          </p>
          {allPeriods?.map(({ billingPeriods, description, plan_ID, price }) => {
            const NOT_AVAILABLE_PLAN = price === 'Not Available';
            return (
              <div key={billingPeriods} className={NOT_AVAILABLE_PLAN && 'disabled'}>
                <input
                  type="radio"
                  name="period"
                  className="radio"
                  disabled={NOT_AVAILABLE_PLAN}
                  value={billingPeriods}
                  checked={isRadioSelected(billingPeriods)}
                  onChange={(e) => handleChange(e, plan_ID)}
                  id={billingPeriods}
                />
                <label
                  htmlFor={billingPeriods}
                  className="radio-input__label"
                >
                  <span>{billingPeriods}</span>
                  <span>{price} $</span>
                </label>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default RadioInput;
