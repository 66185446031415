import React from 'react';
import { withBemMod } from '@bem-react/core';

import { IButtonProps, cnButton } from '../index';
import './Button_color_main-primary.scss';

export interface IButtonColorMainPrimaryProps {
    color?: 'main-primary';
}

export const withButtonColorMainPrimary = withBemMod<IButtonColorMainPrimaryProps, IButtonProps>(
    cnButton(),
    {color: 'main-primary'},
    (Button) => ({color, ...props}) => <Button {...props} />,
);
