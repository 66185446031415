import {combineReducers, compose, createStore, applyMiddleware, Store} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import {dissocPath, assocPath} from 'ramda';
import {createHashHistory as createHistory, createMemoryHistory} from 'history';

import {localStorage} from '../shared/storage';
import {iFullStoreState} from '../shared/interfaces';
import {
    tagsWatcher,
    peopleWatcher,
    favoritesWatcher,
    labelWatcher,
    incidentIconsGetter,
    infoWatcher,
    fencesWatcher,
    Actions as generalActions,
    filtersWatcher,
    permisionWather
} from './reducers/general-reducers';
import {userTokenRefreshHandler, getEventTypes} from '../shared/db/general-db';
import {getConfig, storePathAwaiterMaker} from '../shared/helpers';
import {modalSize} from './reducers/layout-reducers';
import {captchaKeyV3} from '../shared/firebase';
import {reducers} from './reducers';
import rootSaga from './sagas';
import { maintenanceOptionsWatch, maintenanceWatch } from './reducers/maintenance';
import { maintenanceReportWatch } from './reducers/report-reducers';
import {IS_PUBLIC_VIEW} from "../shared/constants";
// import { maintenanceWatch } from './reducers/maintenance';

export const history = createHistory();

// when user prints then go to full screen for better UI
window.addEventListener('beforeprint', () => store.dispatch({type: 'LAYOUT_SET_MODAL_SIZE', size: modalSize.full}));
window.matchMedia('print').addListener(function(mql) {
    if(mql.matches) {
        store.dispatch({type: 'LAYOUT_SET_MODAL_SIZE', size: modalSize.full});
    }
});

let initialState;

if (module && (module as any).hot && !!window.localStorage.getItem('dev-store-state')) {
    initialState = JSON.parse(window.localStorage.getItem('dev-store-state'));
}

/** Ugly dev stuff - needs to be moved and cleaned up -- if deving store the fence history for easy reload */
let config = {};

if (module && (module as any).hot && !!window.localStorage.getItem('dev-fence-history-state')) config = JSON.parse(window.localStorage.getItem('dev-fence-history-state'));

export const fenceHistory = createMemoryHistory(config);

if (module && (module as any).hot) fenceHistory.listen((_) => {
    window.localStorage.setItem('dev-fence-history-state', JSON.stringify({
        initialIndex: fenceHistory.index,
        initialEntries: fenceHistory.entries.map((x) => x.pathname),
        keyLength: fenceHistory.location.key.length,
        getUserConfirmation: null
    }));
});
/** End fence history stuff */

const sagaMiddleware = createSagaMiddleware();
const middleWare = [
    sagaMiddleware,
];

const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 20
}) ?? compose;

const combined = combineReducers<any>(reducers);

const theStore: Store<iFullStoreState> = createStore(
    (state: any, action: any) => {
        if (action.type == 'GEN_SET') return action.val === undefined ?
            dissocPath(action.path, state) :
            assocPath(action.path, action.val, state);

        return combined(state, action);
    },
    initialState as any,
    composeEnhancers(applyMiddleware(...middleWare))
);
sagaMiddleware.run(rootSaga);

userTokenRefreshHandler(theStore);
export const storePathAwaiter = storePathAwaiterMaker(theStore);

// const grecaptcha: any = window['grecaptcha'];

// we are grabbing some top level stuff which shouldn't contain lots of data and watching
// them so we don't have to continually fetch individually. These places should remain
// shallow and small.
export const runWatchers = async () => {
    await storePathAwaiter(['auth', 'user']);
    await storePathAwaiter(['general', 'config']);

    // grecaptcha.ready(async () => {
    //     const token = await grecaptcha.execute(captchaKeyV3, {action: 'homepage'});

    //     fetch(`https://us-central1-mastrack-test.cloudfunctions.net/captcha?token=${token}`)
    //         .then(() => theStore.dispatch(generalActions.SET_INVIS_CAPTCHA(true)))
    //         .catch(() => theStore.dispatch(generalActions.SET_INVIS_CAPTCHA(false)));
    // });

    // todo find a way how to not trigger all of the watchers in the public mode
    // also update initWatcher

    fencesWatcher(theStore);
    tagsWatcher(theStore);
    filtersWatcher(theStore);
    peopleWatcher(theStore);
    infoWatcher(theStore);
    labelWatcher(theStore);
    incidentIconsGetter(theStore);
    maintenanceWatch(theStore);
    maintenanceOptionsWatch(theStore);
    // maintenanceReportWatch(theStore);
    theStore.dispatch(generalActions.SET_DEVICE_EVENTS(await getEventTypes()));

};

export const store = theStore as Store<iFullStoreState>;

// user specific syncronicity
let uid: string|false = false;
let favoriteCanceler = () => {};
let permissionCanceler = () => {};
store.subscribe(async () => {
    const auth = (store.getState() as iFullStoreState).auth;

    // cancel if we have no user or wrong user
    if (!auth || !auth.user || auth.user.uid != uid) {
        favoriteCanceler();
        permissionCanceler();
    }

    // start one up if we have a user
    if (auth && auth.user && auth.user.uid != uid) {
        uid = auth.user.uid;
        favoriteCanceler = await favoritesWatcher(theStore, auth.user.uid);
        permissionCanceler = await permisionWather(theStore, auth.user.uid);
    }
});

let oldUser: any = false;
store.subscribe(() => {
    const IS_PUBLIC_VIEW = window.location.href.includes('public-view');
    if (IS_PUBLIC_VIEW) return;

    const stateUser = store.getState().auth.user as any;

    if (oldUser === stateUser) return;

    if (stateUser != undefined && stateUser != 'undefined')
        localStorage.set('cached-user', stateUser);

    oldUser = stateUser;
});

let oldBilling: any = false;
store.subscribe(() => {
  const IS_PUBLIC_VIEW = window.location.href.includes('public-view');
  if (IS_PUBLIC_VIEW) return;

  const billingToken = store.getState().billing?.token;

  if (oldBilling === billingToken?.access_token) return;

  if (billingToken?.access_token != undefined && billingToken?.access_token != "undefined")
    localStorage.set("billing-auth", billingToken);

  oldBilling = billingToken?.access_token;
});


// watch for initialize stuff to complete
const initWatcher = store.subscribe(() => {
    const general = store.getState().general;
    // TODO remove this ugly subscription
    if (general.deviceEvents && general.incidentIcons && general.people && general.tags && general.extraInfo && !general.initComplete && general.fences && general.tagsInitialized) {
        store.dispatch({type: 'INITALIZE_COMPLETE'});
        initWatcher(); // cancel watch
    }
});

// send the config into the store
const configCanceller = store.subscribe(async () => {
    const state = store.getState();

    if (!state?.auth?.user?.uid) return;

    configCanceller();

    store.dispatch(generalActions.SET_CLIENT_CONFIG(await getConfig()));
});

