import {IFullProps, IProps, IPropsFromStore} from './ReportPageFilterSection.interfaces';

import {Collapsible} from "../../../general/Collapsible";
import React from 'react';
import {Actions as ReportActions} from '../../../../stores/reducers/report-reducers';
import {SearchGrid} from '../../../general';
import {SelectionsGrid} from '../../../general';
import {RootStateOrAny} from "react-redux";
import { lCount } from "../../../../shared/helpers";
import { useSelector, useDispatch } from 'react-redux';

export const ReportPageFilterSection = ({ title, visible, filterType, Icon, disabled, RedirectToStatic, hasSelectionsGrid, RedirectToTabs, countOfSelectedFilters }: IFullProps) => {
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state);
    const currentFilter = state.report.details.filters[filterType];
    const currentFilterObject = state.report.details.filters;
    const filterStr = state.report.filterFilters[filterType];

    const selectedCount = lCount(currentFilter);
    const countTitle = selectedCount ? `(${selectedCount})` : (hasSelectionsGrid ? '' : '(all)');

    const someFiltersLessThanTwo = Object.entries(countOfSelectedFilters).every(([key,value]) => value < 2);

    const checkingSelectedFilters = () => {
        return countOfSelectedFilters[filterType] >= 2 || !countOfSelectedFilters[filterType] || countOfSelectedFilters[filterType] == 1;
    }

    const checkingCurrentFiltersMoreThanTwo = checkingSelectedFilters()
    const setFilterByClick = ({filterType, id}: { filterType: string, id: string }) => {
        if(someFiltersLessThanTwo || checkingCurrentFiltersMoreThanTwo) {
            dispatch(ReportActions.TOGGLE_ITEM_FILTER(filterType, id))
        }
    }

    return (
        <div id={filterType} className={`report-filter-section ${disabled? "disabled": ""}`}>
            <Collapsible
                isCollapsed={true}
                className="report-filter"
                headerClassName="report-filter-header"
                bodyClassName="report-filter-body"
                header={
                    <div className="report-filter-labels-header" >
                        <Icon className="report-filter-icon "/>
                        {title}
                        {!RedirectToTabs && (<span className="weight-normal">&nbsp;{countTitle}</span>)}
                    </div>
                }
            >
                {
                    hasSelectionsGrid ?
                        (<SelectionsGrid
                            filterStr={filterStr || ''}
                        />)
                        : (<SearchGrid
                            onReset={() => dispatch(ReportActions.RESET_ALL_TYPE_FILTERS(filterType))}
                            list={visible}
                            filterStr={filterStr || ''}
                            placeholder={`Search...`}
                            perPage={9}
                            RedirectToStatic={RedirectToStatic}
                            keyClicked={id => setFilterByClick({filterType, id})}
                            filterChange={f => dispatch(ReportActions.SET_FILTER_FILTER(filterType, f))}
                            selectedCount={selectedCount}
                            countOfSelectedFilters={countOfSelectedFilters}
                            currentFilterObject={currentFilterObject}
                            filterType={filterType}
                            RedirectToTabs={RedirectToTabs}
                        />)
                }
            </Collapsible>
        </div>
    );
}