import React, { useEffect, useState } from "react";
import Modal from "../../Modal";
import instance from "../../../api/instance";
import { Button, ButtonSize, ButtonStyles } from "../../Button";
import { toast } from "react-toastify";
import { useRedux } from "../../../states/redux-state";
import { MuiThemeProvider, Switch, createTheme } from "@material-ui/core";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Fa } from "../../elements/fa";
import { nanoid } from "nanoid";
import { localStorage } from "../../../shared/storage";
import styles from './PublicLinkModal.module.scss'

const PublicLinkModal = ({ setIsPublicLinkModalOpen, tagId }) => {

    const [isPublicLinkOn, setIsPublicLinkOn] = useState(false);
    const [publicLinkTokenFromDb, setPublicLinkTokenFromDb] = useState(null);
    const [uniqueId, setUniqueId] = useState(null);

    const checkIfPublicLinkEnabled = async () => {
        const URL = `/api/tag/one?firebaseId=${tagId}`;

        await instance.get(URL)
            .then(response => {
                const { data } = response;
                data.share_link_token && (setPublicLinkTokenFromDb(data.share_link_token))
                data?.is_share_link_enabled === 1 && (setIsPublicLinkOn(true))

                return data
            })
            .catch(({ message }) => {
                toast.error(message, { position: toast.POSITION.TOP_RIGHT });
            });
    }

    useEffect(() => {
        checkIfPublicLinkEnabled();
    }, [])

    const clientId = localStorage.get('active-client');
    const uid = useRedux((s) => s.auth.user.uid);

    const generatedLink = `${process.env.REACT_APP_FRONTEND_DOMAIN_URL}/#/public-view/?tag=${tagId}&client=${clientId}&token=${publicLinkTokenFromDb}`;

    if (publicLinkTokenFromDb === null) {
        const uniqueId = nanoid();
        setUniqueId(uniqueId);
        setPublicLinkTokenFromDb(uniqueId)
    }

    const setPublicLinkToDb = async (isEnabled) => {

        setIsPublicLinkOn(isEnabled)
        if (isEnabled === false) {
            setPublicLinkTokenFromDb(null);
        }

        const URL = `/api/tag/store`;

        const body = {
            firebase_id: tagId,
            is_share_link_enabled: isEnabled ? 1 : 0,
            client_id: clientId,
            firebase_user_id: uid,
            share_link_token: isEnabled ? publicLinkTokenFromDb : null,
        }

        await instance.post(URL, body)
            .then(response => {
                const { data } = response;

                return data
            })
            .catch(({ message }) => {
                toast.error(message, { position: toast.POSITION.TOP_RIGHT });
            });
    }

    const theme = createTheme({
        overrides: {
            MuiSwitch: {
                track: {
                    "$checked$checked + &": {
                        opacity: 1.0,
                        backgroundColor: "rgb(255, 124, 2)"
                    },
                    "MuiIconButton-label": {
                        backgroundColor: "rgb(255, 124, 2)"
                    }
                }
            }
        }
    });

    return (
        <Modal className={styles.modal} onBackdropClick={() => setIsPublicLinkModalOpen(false)}>
            <div className={styles.modalContainer}>
                <div className={styles.buttonClose}>
                    <button onClick={() => setIsPublicLinkModalOpen(false)}>
                        <Fa icon={faTimes} />
                    </button>
                </div>
                <div className={styles.warning}>Warning! Enabling this switch will allow anyone with this link to view the location of devices from this tag</div>
                <div className={styles.switcher}>
                    <MuiThemeProvider theme={theme}>
                        Enable public link <Switch checked={isPublicLinkOn} onChange={event => setPublicLinkToDb(event.target.checked)} />
                    </MuiThemeProvider>
                </div>
                {isPublicLinkOn && (
                    <>
                        <a href={`https://${generatedLink}`} target='_blank' className={styles.publicLink}>{generatedLink}</a>
                        <div className={styles.buttonBlock}>
                            <Button
                                styleType={ButtonStyles.GRAY_BLACK}
                                size={ButtonSize.LG}
                                onClick={() => { navigator.clipboard.writeText(generatedLink) }}
                            >
                                Copy Link
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default PublicLinkModal;
