import React, { useEffect } from 'react';
import { InfoWindow, Marker } from 'react-google-maps';
import * as color from 'color';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import { useDispatch, connect } from 'react-redux';
import { MAP } from 'react-google-maps/lib/constants';

import { iLocation } from '../../../shared/interfaces';
import { useVisRegions } from '../../../stores/reducers/map-region-reducers';
import { useRedux } from '../../../states/redux-state';
import { GMapModalAC, GMapModalContent } from '../../../stores/reducers/gMapModal/AC';
import { Actions as ReportActions } from '../../../stores/reducers/report-reducers';
import { Actions as GmapActions } from '../../../stores/reducers/gmap-reducers';
import { getBottomLeftAngleOfRectangle, getRightLng, getTopLat } from '../../../shared/helpers';
import styles from './SearchMarker.module.scss';

interface iProps {
    location: iLocation,
    title: string,
    id: string,
    dispatch?: any,
    color,
    label: string,
    dates,
    mapRef
}

const mapStateToProps = (state) => ({
    dates: state.report.details.dates,
    mapRef: state.gmap.mapRef()
});

const SearchMarker = (props: iProps) => {

    const dispatch = useDispatch();
    const [regions, { toggleRegion }] = useVisRegions();

    const { location, label, title, id, color: c, mapRef } = props;

    const pickedDates = props?.dates[0];

    const regionsInRedux = useRedux((s) => s.regions.vis);
    const isDisplaying = regionsInRedux[id];

    let center = location;
    let topRightVertex;
    let bottomLeftVertex;

    let fence = {
        topRightVertex: {
            lat: 0,
            lng: 0,
        },
        bottomLeftVertex: {
            lat: 0,
            lng: 0,
        }
    };

    if (isDisplaying) {
        topRightVertex = {lat: getTopLat(location, regions[id]?.height), lng: getRightLng(location, regions[id]?.width)};
        bottomLeftVertex = getBottomLeftAngleOfRectangle(center, topRightVertex);

        fence = {
            topRightVertex,
            bottomLeftVertex,
        }
    }

    useEffect(() => {

        if (isDisplaying) {
            dispatch(ReportActions.SET_DATA_FROM_SELECTED_AREA(fence));
        }

    }, [fence.topRightVertex.lat, fence.topRightVertex.lng, fence.bottomLeftVertex.lat, fence.bottomLeftVertex.lng])


    useEffect(() => {
        mapRef && mapRef.current && mapRef.current.context[MAP].setZoom(16);
    }, [])



    const fenceObj = {
        color: "#0000FF",
        description: "",
        enterAlert: false,
        exitAlert: false,
        fenceGroup: 1,
        icon: {
            fa: {
                iconName: "map-pin",
                prefix: "fas"
            }
        },
        id: id,
        itemId: "-MNxZQbyXPGom9vGjNSj",
        itemType: "tag",
        label: title,
        region: {
            center: {
                ...location,
            },
            editable: true,
            height: 32.035439817430124,
            hideIcon: true,
            hideName: true,
            hideShadows: false,
            name: title,
            shape: 0,
            width: 34.723651971360745
        },
        shape: 0,
        waypoint: true
    }

    const showReportFiltersModal = () => {
        dispatch(GMapModalAC.showModal({ contentType: GMapModalContent.REPORT_PAGE_FILTERS_DATEPICKER }));
    }

    const onCloseClick = () => {
        dispatch(GmapActions.DEL_SEARCH_MARKER(id));
        dispatch(ReportActions.RESET_ALL_FILTERS());
        dispatch(ReportActions.RESET_DATES());
        isDisplaying && toggleRegion(fenceObj, true);
    }

    const onSearchClick = () => {
        toggleRegion(fenceObj, true)
    }

    return <>
        <Marker
            position={location}
            animation={google.maps.Animation.DROP}
            title={title}
        >
            <InfoWindow position={location} onCloseClick={onCloseClick}>
                <div className={styles.container}>
                    <div className={styles.label}>{label}</div>
                    {isDisplaying && <div className={styles.hint}>Please resize the selected area around the point as you need and select continue</div>}
                    <div className={styles.buttons}>
                        {isDisplaying && (
                            <button onClick={showReportFiltersModal}>Continue</button>
                        )}
                        {!isDisplaying && (<button
                            className={styles.button}
                            onClick={onSearchClick}
                        >
                            Search Location
                        </button>)}
                        <button
                            className={styles.buttonRed}
                            onClick={onCloseClick}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </InfoWindow>
        </Marker>
        {label && <InfoBox
            options={{
                disableAutoPan: true,
                zIndex: 4,
                boxStyle: {
                    zIndex: 4,
                    overflow: 'hidden',
                    'background-color': '#000',
                    width: '80px',
                    border: `1px solid ${color(c).darken(.5)}`,
                    padding: '2px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: c,
                    color: (color(c).light() ? '#000' : '#fff'),
                    opacity: '.9',
                },
                alignBottom: true,
                closeBoxURL: '',
                pixelOffset: new google.maps.Size(-30, 29),
            }}
            defaultPosition={new google.maps.LatLng(location.lat, location.lng)}>
            <div>{label}</div>
        </InfoBox>}
    </>;
};

export default connect(mapStateToProps)(SearchMarker);