import React from 'react';
import {
    iDevicePing,
    iFullStoreState,
    ReportContainer,
} from '../../../../shared/interfaces';
import {useSelector} from 'react-redux';
import { getTimeFormated, countAverageFuel, countAverageSpeed } from './reportPageHelpers';
import {sum} from 'ramda';
import {
    friendlySpeed,
    vals
} from '../../../../shared/helpers';
import {countSafety} from '../report-table/cells/helpers';
import useOneDeviceReportUnits from './useOneDeviceReportUnits';

const StaticTotalTable = () => {
    const displayRecords = useSelector<iFullStoreState, ReportContainer>(s => s.report.displayRecords || {});
    const displayValues = Object.values(displayRecords);
    
    
    const averageFuel = countAverageFuel(displayValues as Array<iDevicePing>).toFixed(2);
    const safetIncidents = sum(vals(displayRecords).map(countSafety));
    const averageSpeed = countAverageSpeed(displayValues as Array<iDevicePing>).toFixed(2);
    const deviceUnits = useOneDeviceReportUnits();
    
    return (
        <div className="report-summary-table report-summary-table--one-col">
            <div className="report-summary-col">
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Average fuel:</span>
                    <span className="report-summary-data-value">{averageFuel}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Average speed:</span>
                    <span className="report-summary-data-value">{friendlySpeed(averageSpeed, deviceUnits)}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Safety Incidents</span>
                    <span className="report-summary-data-value">{safetIncidents}</span>
                </div>
            </div>
        </div>
    )
}

export default StaticTotalTable;