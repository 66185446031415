import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {iFullStoreState} from "../../../shared/interfaces";
import {Actions as GmapActions} from "../../../stores/reducers/gmap-reducers";
import {ReactComponent as FaTimesSolid} from '../../../assets/svg/times-solid.svg'

import './styles.scss';

export const CloseButtonStreetView = () => {
    const dispatch = useDispatch();
    const toggleStreetView = () => dispatch(GmapActions.TOGGLE_STREET_VIEW());
    const isStreetView = useSelector<iFullStoreState, boolean>(s => !!s.gmap.streetViewLocation);

    return (
        <div style={{display: 'flex', paddingRight: 60}}>
            <span className={`${isStreetView ? 'close-button' : 'close-button-hidden' }`} onClick={toggleStreetView}>
                <FaTimesSolid  className={'icon'}/>
            </span>
        </div>
    );
};