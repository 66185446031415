import React, { useState } from 'react';
import { compose } from '@bem-react/core';

import { withSentry } from '../../../shared/hoc/withSentry';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import DevicePeopleToggle from './device-people-toggle';

const Dashboard = () => {
    localStorage.removeItem("doNotShowAdModalAgain");

    return (
        <DashboardBlock>
            <ModalHeader title="Recent" />
            <DevicePeopleToggle />
        </DashboardBlock>
    )
};


export default withSentry(Dashboard);
