import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import styles from "./styles.module.scss";
import axios from "axios";
import { VIDEO_STATUSES } from "../../../../../shared/constants";
import { friendlySpeed } from "../../../../../shared/helpers";
import { Units } from "../../../../../shared/interfaces";
export interface iVideo {
  createdAt: string;
  device: string | null;
  status: string;
  link: string | null;
  msg: string | null;
  speed: string;
  assignedDriver: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  timezone: string;
}

interface VideoProps {
  video: iVideo;
  showDevice: boolean;
}

const VideoItem: React.FC<VideoProps> = ({ video, showDevice }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    link,
    status,
    createdAt,
    device,
    msg,
    speed,
    street,
    city,
    state,
    zip,
  } = video;
  const formatDate = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const convertToTimezone = (date, offset) => {
    const utcTime = date.getTime();
    const offsetTime = utcTime + offset * 3600 * 1000;
    return new Date(offsetTime);
  }

  const utcDate = new Date(`${createdAt}Z`);
  const timestamp = utcDate.getTime()
  const timezoneOffset = new Date().getTimezoneOffset() / -60;
  const date = new Date(timestamp);
  const dateInTimezone = convertToTimezone(date, timezoneOffset);
  const formattedDate = formatDate(dateInTimezone);

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const response = await axios({
        url: link,
        method: "GET",
        responseType: "blob",
      });

      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const videoLink = document.createElement("a");
      const fileName = `${device.replace(/\s/g, "_")}-${createdAt}.mp4`;

      videoLink.href = urlObject;
      videoLink.setAttribute("download", fileName);
      document.body.appendChild(videoLink);
      videoLink.click();
      document.body.removeChild(videoLink);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDownloading(false);
    }
  };

  const isDownloadButtonDisabled =
    isDownloading || status === VIDEO_STATUSES.PENDING || !link;

  return (
    <li className={styles.VideoItem}>
      {showDevice && <h3 className={styles.deviceTitle}>{device}</h3>}
      <div
        className={classNames(styles.card, {
          [styles.downloadedStatus]: status === VIDEO_STATUSES.DOWNLOADED,
        })}
      >
        <div className={styles.row}>
          <span>Date: </span>
          <span className={styles.value}>
            {formattedDate}
          </span>

        </div>
        <div className={styles.row}>
          <span>Report Type: </span>
          <span className={styles.value}>Video</span>
        </div>
        <div className={styles.row}>
          <span>Status: </span>
          <span className={`${styles.value}`}>{status}</span>
        </div>

        {msg && (
          <div className={styles.row}>
            <span>Message: </span>
            <span className={`${styles.value}`}>{msg}</span>
          </div>
        )}
        {speed && (
          <div className={styles.row}>
            <span>Speed: </span>
            <span className={`${styles.value}`}>
              {friendlySpeed(speed, Units.miles)}
            </span>
          </div>
        )}
        {street && (
          <div className={styles.row}>
            <span>Current location: </span>
            <span className={`${styles.value}`}>
              {<span>{street}</span>}
              {
                <p>
                  {city}, {state} {zip}
                </p>
              }
            </span>
          </div>
        )}

        <div className={styles.row}>
          <button disabled={isDownloadButtonDisabled} onClick={handleDownload}>
            {link ? "Download" : "No link"}
          </button>
        </div>
      </div>
    </li>
  );
};

export default VideoItem;
