import React from 'react';

const CustomizedCursor = (props) => {
  const { points: [{x, y}], width=13, height, stroke="#878889", fillColor="#F4F4F4" } = props;
  const dx = width/2;
  return <g>
    <rect fill={fillColor} stroke="none" width={width} x={x-dx} y={y} height={height} />
    <line x1={x} x2={x} y1={y} y2={y+height} stroke={stroke} strokeDasharray="5" />
  </g>
};

export default CustomizedCursor;