export const DEVICE_SHOW_TOGGLE = 'DEVICE_SHOW_TOGGLE';
export const HIDE_ALL_TAGS = 'HIDE_ALL_TAGS';
export const SHOW_TAG_DEVICES = 'SHOW_TAG_DEVICES';
export const TAG_SHOW_TOGGLE = 'TAG_SHOW_TOGGLE';
export const TOGGLE_SHOW_SELECTED_TAG_DEVICE = 'TOGGLE_SHOW_SELECTED_TAG_DEVICE';
export const SHOW_ALL_DEVICES = 'SHOW_ALL_DEVICES';
export const HIDE_ALL_DEVICES = 'HIDE_ALL_DEVICES';

export const deviceShowToggle = (deviceId: string) => ({
    type: DEVICE_SHOW_TOGGLE,
    payload: {deviceId},
});

export const tagShowToggle = (tagId: string, devicesIds?:  Array<string>, filter?: boolean)  => ({
    type: TAG_SHOW_TOGGLE,
    payload: {tagId, devicesIds, filter},
});

export const showTagDevices = (tagId: string) => ({
    type: SHOW_TAG_DEVICES,
    payload: {tagId},
});

export const toggleShowSelectedTagDevice = (tagId: string, deviceId: string) => ({
    type: TOGGLE_SHOW_SELECTED_TAG_DEVICE,
    payload: {tagId, deviceId},
});

export const hideAllTags = () => ({
    type: HIDE_ALL_TAGS,
});

export const showAllDevices = (devicesIds: Array<string>) => ({
    type: SHOW_ALL_DEVICES,
    payload: {devicesIds},
});

export const hideAllDevices = () => ({
    type: HIDE_ALL_DEVICES,
});
