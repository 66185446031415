import { RefObject, useEffect } from "react";

interface UseClickOutsideProps {
    targetRef: RefObject<HTMLElement>;
    omitRefs?: RefObject<HTMLElement>[];
    callback: Function;
}

const useClickOutside = ({targetRef, omitRefs ,callback}: UseClickOutsideProps): void => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (omitRefs) {
                for (const omitRef of omitRefs) {
                    if (omitRef.current && omitRef?.current?.contains(event.target)) {
                        return;
                    }
                }
            }
            if (targetRef.current && !targetRef.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [targetRef, callback, omitRefs]);
}

export default useClickOutside;