import React from 'react';
import { useIsReadOnly } from '../../../../../hooks/useIsReadOnly';
import Notepad from '../../../../general/notepad';

const NotepadWrapper = (props) => {
    const isReadOnly = useIsReadOnly(props.itemId, props.itemType)
    return (
        <Notepad
            notes={props.notes}
            deviceOrFence={props.deviceOrFence}
            deviceOrFenceId={props.deviceOrFenceId}
            style={props.style}
            ownerTitle={props.ownerTitle}
            disabled={isReadOnly}
        />
    )
}

export default NotepadWrapper;