import React, { useState } from "react";
// import "./summaryBlock.scss";
import TotalCostList from "./TotalCostList";
import AvgScoreVSCost from "./AvgScoreVSCost";
import DevicesTable from "./DevicesTable";

const SummaryBlock = ({filter}) => {
    return <>
        {(filter === "total") ? <TotalCostList /> : null}
        {(filter === "average") ? <AvgScoreVSCost /> : null}
        <DevicesTable />
    </>
};

export default SummaryBlock;