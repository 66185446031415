import React from "react";
import {TimeLineRangeProps} from "./TimeLineRange.interfaces";

const TimelineRange = ({start, end}: TimeLineRangeProps) => {
    if (!start || !end) {
        return null;
    }

    const startDay = start?.format('MM.DD.Y');
    const endDay = end?.format('MM.DD.Y');

    return (
        <div className="range">
            <span className="from">{startDay}</span>
            {startDay !== endDay && (
                <span className="to"> - {endDay}</span>
            )}
        </div>
    );
};

export default TimelineRange;