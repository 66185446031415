import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  values,
  isEmpty,
} from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  countDateWithUserAndTimezoneOffset,
  isMobile,
  userCurrentOffset,
  utcOffset,
} from "../../../../shared/helpers";
import {
  iFullStoreState,
  iTrip,
  Units,
} from "../../../../shared/interfaces";
import { useRedux } from "../../../../states/redux-state";
import { Row } from "../../../elements/flex";
import { TripAndTravelCell } from "./cells/TripAndTravelCell";
import { ActivityDeetsCol } from "./cells/activity-deets";
import { tripPointsReal } from "../../../../shared/db/report-db";
import {
  setReportTrip,
  toggleShowReportTrip,
} from "../../../../stores/reducers/devicesTripsPoints/AC";
import { IPointsContainer } from "../../../../stores/reducers/devicesTripsPoints";
import "./table-wrapper.scss";
import { ReactComponent as Elipse } from "../../../../assets/svg/elipse.svg";
import { DashboardSize } from "../../../../stores/reducers/dashboardInfo";
import { ReactComponent as MapPointerSvg } from "../../../../assets/svg/map_pointer.svg";
import { ReactComponent as NotificationSvg } from "../../../../assets/svg/notification.svg";
import { Button, ButtonSize, ButtonStyles } from "../../../Button";
import { Actions as GeneralActions, useLocalStorage } from "../../../../stores/reducers/general-reducers";
import { fullCloseDashboard } from "../../../../stores/reducers/dashboardInfo/AC";
import { iHiddenReportData } from "../vis-report-data";

type IReportTripRowProps = {
  deviceId: string;
  timezone: string;
  units: Units;
  current: iTrip;
  showAllRoute: boolean;
  device?: any;
  showTrip: (item: { device: string; tripId: string }, tripPoints?: any, zoomToTrip?: boolean) => void;
};

export const ReportTripRow = ({
  current: row,
  deviceId,
  units,
  timezone,
  showTrip,
  device
}: IReportTripRowProps) => {
  const dispatch = useDispatch();

  const dashboardInfo = useRedux((s) => s.dashboardInfo);

  const tripPoints = useSelector<iFullStoreState, IPointsContainer | undefined>(
    (s) => s.devicesTripsPoints.getIn([deviceId, "tripsPoints", row.tripId]),
    (l, r) => l?.keySeq?.()?.last?.() === r?.keySeq?.()?.last?.()
  );

  const [showAlerts, setShowAlerts] = React.useState(false);
  const showRoute = useSelector<iFullStoreState, boolean>(
    (s) =>
      s.devicesTripsPoints.getIn([deviceId, "reportTripsIds", row.tripId]) ||
      false
  );

  React.useEffect(() => {
    if (showRoute) {
      tripPointsReal(row.tripId)
        .then(values)
        .then((points: any) => {
          dispatch(setReportTrip(deviceId, row.tripId, points));
        });
    } else if (!!tripPoints) {
      dispatch(toggleShowReportTrip(deviceId, row.tripId, false));
    }
  }, [showRoute]);

  const showRouteToggle = () => {
    console.log("deviceId", deviceId, "trip id", row.tripId);

    if (!showRoute) {
      if (isMobile) {
        dispatch(GeneralActions.SHOW_SEARCH);
        dispatch(fullCloseDashboard());
      }
      showTrip({ device: deviceId, tripId: row.tripId }, null, true);
    } else {
      dispatch(toggleShowReportTrip(deviceId, row.tripId, false));
    }
  };

  const empty = values(row.alertActivity).filter((a) => a > 0);
  const emptyAlerts = isEmpty(empty);

  // moment().isDST() don't using, cause userCurrent Offset already contain or not DST
  // that's why we pass instead moment().isDST() -> just false, to utcOffset
  const deviceOffset = utcOffset(timezone, false);
  const diff = userCurrentOffset - deviceOffset;

  const start = countDateWithUserAndTimezoneOffset(row.startDate, timezone);
  const end = countDateWithUserAndTimezoneOffset(row.endDate, timezone);
  const sameDay = start.dayOfYear() == end.dayOfYear();
  
  const [hiddenFields] = useLocalStorage<iHiddenReportData>(`hidden-report-data:travel`, {});
  
  return (
    <>
      <tr key={`${row.tripId}-1`}>
        <td colSpan={2} style={{ backgroundColor: "#f0f0f0f0" }}>
          <Row style={{ flexDirection: "row" }}>
            <div className={"trip-date-wrap"}>
              <span style={{ whiteSpace: "nowrap", flexGrow: 1 }}>
                <Elipse className="start-date-icon" />{" "}
                {start.format("MM/DD/YYYY")} {start.format("h:mmA")}
              </span>
              <div
                className={`address-actions-wrapper ${
                  dashboardInfo.size === DashboardSize.OPEN ? "open" : ""
                }`}
              >
                <span>{row.startAddress}</span>
                <div
                  style={{ marginLeft: "10px" }}
                  className={isMobile || window["cordova"] ? "d-flex" : ""}
                >
                  <Button
                    styleType={
                      emptyAlerts
                        ? ButtonStyles.GRAY_INACTIVE
                        : ButtonStyles.YELLOW_WHITE
                    }
                    style={
                      isMobile || window["cordova"]
                        ? { height: "max-content" }
                        : {}
                    }
                    size={ButtonSize.XSM}
                    disabled={emptyAlerts}
                    onClick={() =>
                      setShowAlerts(!showRoute ? showAlerts : !showAlerts)
                    }
                  >
                      <NotificationSvg
                        className={
                          isMobile || window["cordova"] ? "icon-smd" : "icon-sm"
                        }
                      />
                  </Button>
                  {showRoute && !tripPoints ? (
                    <Button
                      styleType={ButtonStyles.NONE}
                      title="Click to show route on map"
                      disabled={showRoute && !tripPoints}
                      onClick={() => showRouteToggle()}
                    >
                      <FontAwesomeIcon icon="spinner" spin />
                    </Button>
                  ) : (
                    <Button
                      styleType={
                        showRoute
                          ? ButtonStyles.BLACK_WHITE
                          : ButtonStyles.GRAY_INACTIVE
                      }
                      style={
                        isMobile || window["cordova"]
                          ? { height: "max-content" }
                          : {}
                      }
                      size={ButtonSize.XSM}
                      disabled={showRoute && !tripPoints}
                      onClick={() => showRouteToggle()}
                    >
                        <MapPointerSvg
                          className={
                            isMobile || window["cordova"]
                              ? "icon-smd"
                              : "icon-sm"
                          }
                        />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </td>
      </tr>
      <tr key={`${row.tripId}-3`}>
        <td colSpan={2}>
          {!showAlerts ? (
            <Row
              disableStyles={true}
              className={`trip-wraper ${
                dashboardInfo.size === DashboardSize.OPEN ? "open" : ""
              }`}
              justify="space-evenly"
              style={{ fontSize: 11.5 }}
            >
              <div>
                <TripAndTravelCell
                  row={row}
                  hiddenFields={hiddenFields}
                  device={device}
                />
              </div>
            </Row>
          ) : (
            <ActivityDeetsCol
              tripId={row.tripId}
              units={units}
              deviceId={deviceId}
              errorCloser={() => setShowAlerts(false)}
              timezone={timezone}
            />
          )}
        </td>
      </tr> 
      <tr key={`${row.tripId}-4`}>
        <td
          className="footer"
          colSpan={2}
          style={{
            backgroundColor: "#f0f0f0f0",
            fontSize: isMobile ? 9 : "inherit",
          }}
        >
          <Row
            justify="flex-start"
            style={{ fontSize: isMobile ? 9 : "inherit" }}
          >
            <div className="trip-date-wrap">
              <span>
                <Elipse className="end-date-icon" /> {end.format("h:mmA")}
              </span>
              <span>{row.endAddress}</span>
            </div>
          </Row>
        </td>
      </tr> 
    </>
  );
};
