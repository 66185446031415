import React from 'react';

import { cnTabs } from '../';

const TabContent: React.FC = ({children}) => (
    <div className={cnTabs('TabContent')}>
        {children}
    </div>
);

export default TabContent;
