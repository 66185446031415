import { DispatchProp } from 'react-redux';
import {
  iPerson,
  iList,
  iTag,
  UserAuth,
  iItemsAllowed,
} from '../../../../shared/interfaces';
import { default as C } from '../../../../shared/constants';
import { DevicesDetailsContainer } from '../../../../stores/reducers/devicesData';

export type IPropsFromStore = {
  person: iPerson;
  tags: iList<iTag>;
  devicesDetails: DevicesDetailsContainer;
  authUser: UserAuth;
};
export type IFullProps = IPropsFromStore & DispatchProp;

export interface StateInterface {
  tagFilter;
  deviceFilter;
  allowed?: iItemsAllowed;
  readOnlyAll: boolean;
  readOnlyAccess: object;
  canEditDevicesAndFences: boolean;
}

export const itemStyle = (active, compressed = false) => {
  const style = {
    cursor: 'pointer',
    display: 'inline-block',
    margin: compressed ? 4 : 8,
    fontSize: compressed ? 10 : 'initial',
    color: active ? '#fff' : C.primaryColor,
  } as any;

  if (active) style.backgroundColor = C.primaryColor;

  return style;
};
