import { FileFormat } from "../parsers";
import { convertCSVToJSON } from "./convert-csv-to-json";

export function createConverter(formatFrom: FileFormat, formatTo: FileFormat): (data: any) => any {
  switch(formatFrom) {
    case FileFormat.CSV:
      switch(formatTo) {
        case FileFormat.JSON:
          return convertCSVToJSON;
      }
  }
}
