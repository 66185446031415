export const formatTime = (rawTime, is24hFormat) => {
  try {
    const [hoursString, minutesString] = rawTime.split(':');
    const hours = Number(hoursString);
    const minutes = Number(minutesString);

    if (!is24hFormat && hours === 0 && minutes === 0) return '12:00 AM';

    if (!is24hFormat) {
      const hoursRemainder = hours % 12;
      const abbreviation = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = !!hoursRemainder ? hoursRemainder : hours;

      return `${formattedHours}`.padStart(2, '0') + `:${minutesString} ${abbreviation}`;
    } else {
      return `${hours}`.padStart(2, '0') + `:${minutesString}`;
    }

    return '';
  } catch (e) {
    console.log(e);
    return ''
  }
}

export const mapNumberToDay = (number) => {
  const days = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday'
  };

  return days[number];
}