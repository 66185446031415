import React from 'react'
import {Link} from 'react-router-dom';
import { useIsReadOnly } from '../../../../../hooks/useIsReadOnly';

const ActionLink = ({to, className = "", children, title = "", itemId, itemType}) => {
    const isReadOnly = useIsReadOnly(itemId, itemType);
    return (
        (!isReadOnly && <Link to={to} title={title} className={`1 ${className}`}>{children}</Link>)
    )
}
export default ActionLink