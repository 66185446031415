import React from 'react';
import { CSSProperties as css } from 'react';

import C from '../../shared/constants';
import { BaseComponent } from '../../shared/BaseComponent';
import { faChevronDown, faChevronRight, faSpinner } from '@fortawesome/fontawesome-free-solid';
import { Fa } from '../elements/fa';

/// minimal tile
const mTileStyle = ({ small, connectTop, connectBottom }): css => {
    let style = {
        backgroundColor: '#fff',
        padding: 8,
        border: '1px solid ' + C.mediumGray,
        borderRadius: 5,
        color: C.primaryText,
        fontSize: small ? 12 : 'inherit',
        overflowX: 'hidden',
        minHeight: '55px'
    } as css;

    if (connectTop) {
        style.borderTopLeftRadius = 0;
        style.borderTopRightRadius = 0;
        style.borderTop = 'none';
    }

    if (connectBottom) {
        style.borderBottomStyle = 'dotted';
        style.borderBottomLeftRadius = 0;
        style.borderBottomRightRadius = 0;
    }
    return style;
}
const mTitleStyle = (small): css => ({
    fontSize: small ? 12 : 'large',
    fontWeight: small ? 'bold' : 'normal',
    justifyContent: 'space-between',
})


const collapseBody: css = {
    borderTop: `2px solid ${C.darkGray}`,
    marginTop: 2,
    paddingTop: 5,
}
export class CollapseTile extends BaseComponent<{style?: css, alwaysOpen?: boolean, disabled?, header: JSX.Element, body: JSX.Element}, { collapsed: boolean }> {
    state = {
        collapsed: true,
    }

    get children() {
        if (!Array.isArray(this.props.children)) return [this.props.children];
        return this.props.children;
    }

    disabledOverlayCss = {
        position: 'absolute',
        cursor: 'not-allowed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(200, 200, 200, .5)',

    }

    toggle = () => this.setState(s => ({collapsed: !s.collapsed}));
    debugRender = () => {
        let { collapsed } = this.state;
        const { alwaysOpen, style = {}, disabled = false, header, body } = this.props;

        if (alwaysOpen) collapsed = false;

        return <MinimalTile style={{...style, position: 'relative'}}>
            <div style={disabled ? this.disabledOverlayCss : {} as any} />
            <div style={{display: 'flex', justifyContent: 'space-between' as any, alignItems: 'center', cursor:'pointer'}} onClick={this.toggle}>
                {header}
                {alwaysOpen ? null : <Fa icon={faChevronDown} style={{padding: 4, fontSize: 21}} />}
            </div>
            {collapsed ? null : <div style={collapseBody}>{body}</div>}
        </MinimalTile>
    }
}

export class CollapseHeader extends BaseComponent<any, any> {
    debugRender = () => {
        return (<div>{this.props.children}</div>);
    }
}

export class CollapseBody extends BaseComponent<any, any> {
    debugRender = () => {
        return (<div>{this.props.children}</div>);
    }
}

interface iTileProps {
    children,
    title?: string,
    style?: css,
    small?: boolean,
    connectTop?: boolean,
    connectBottom?: boolean,
    className?: string,
}

export class MinimalTile extends BaseComponent<iTileProps, any> {
    debugRender = () => {
        const { children, className = '', title = '', style={}, small=false, connectBottom=false, connectTop=false } = this.props;

        return (
            <div className={`mtile ${className}`} style={{...mTileStyle({small, connectTop, connectBottom}), ...style}}>
                { !title ? null : <div className="tile-header" style={mTitleStyle(small)}><div className="txt">{title} </div></div> }
                {children}
            </div>
        )
    }
}

// standard tile
const tileStyle: css = {
    width: '100%',
    userSelect: 'none'
}

const rad = 4;
const titleStyle: css = {
    borderTopRightRadius: rad,
    borderTopLeftRadius: rad,
    backgroundColor: C.darkGray,
    color: '#fff',
    padding: '3px 8px',
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
}
const bodyStyle: css = {
    overflow: 'auto',
    padding: titleStyle.padding,
    border: '1px solid '+C.lightGray,
    borderBottomRightRadius: rad,
    borderBottomLeftRadius: rad,
}
interface iTileProps {
    children: any,
    title?: string,
    style?: css,
    loading?: boolean,
    action?: JSX.Element,
    collapsible?: boolean,
    initialCollapse?: boolean,
}

export class Tile extends BaseComponent<iTileProps, { collapsed }> {
    constructor(props) {
        super(props)
        this.state = { collapsed: !!this.props.initialCollapse }
    }

    debugRender = () => {
        const { style = {}, title, action, collapsible, children, loading=false } = this.props;
        const { collapsed } = this.state

        return (
            <div style={{...tileStyle, ...style}}>
                <Title
                    loading={loading}
                    text={title}
                    action={action}
                    collapsible={collapsible}
                    collapsed={collapsed}
                    toggle={() => this.setState(s => ({ collapsed: !s.collapsed }))}
                />
                <div style={bodyStyle}>
                    {collapsed ? null : children}
                </div>
            </div>
        )
    }
}

const Title = ({text, action=false, collapsible, collapsed, toggle, loading = false}: any) => (
    <div style={titleStyle} onClick={toggle}>
        {loading
            ? <Fa style={{marginRight: 10, cursor: 'pointer'}} spin icon={faSpinner} />
            : !collapsible
                ? null
                : <Fa style={{marginRight: 10, cursor: 'pointer'}} icon={collapsed ? faChevronRight : faChevronDown} />
        }
        <div style={{flex: 1}}>{text}</div>
        {!action ? null : <div>{action}</div>}
    </div>
)