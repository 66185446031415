import React from 'react';
import moment from 'moment';

import { friendlyDiff, minutesToFriendly } from '../../../../../shared/helpers';
import { iTrip } from '../../../../../shared/interfaces';
import { TripTravelRow } from '../trip-travel-row';
import { LabelOrPersonRow } from './person-label-row';
import { friendlySpeed, friendlyDist, friendlyMilesPer } from '../../../../../shared/helpers';

interface iProps { row: iTrip, hiddenFields?: any, device?: any }

export const TripAndTravelCell = ({row, hiddenFields, device}: iProps,) => {
    let counter = 0;

    return <div style={{display: 'flex', flexDirection: 'column'}}>
        <TripTravelRow
            visible={!hiddenFields['duration']}
            label="Duration"
            val={friendlyDiff(moment(row.endDate), moment(row.startDate))}
            odd={(hiddenFields['duration'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['idle']}
            label="Idle"
            val={minutesToFriendly(row.idleMinutes)}
            odd={(hiddenFields['idle'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['stopped']}
            label="Stopped"
            val={minutesToFriendly(row.stopMinutes)}
            odd={(hiddenFields['stopped'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['safety']}
            label="Safety"
            val={row.safetyPercent ? row.safetyPercent + '%' : 'NA'}
            odd={(hiddenFields['safety'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['person']}
            label="Person"
            val={<LabelOrPersonRow row={row} type="trip" field="personId" />}
            odd={(hiddenFields['person'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow visible={!hiddenFields['maxSpeed']}
            label="Max Speed"
            val={friendlySpeed(row.maxSpeed, device?.units)}
            odd={(hiddenFields['maxSpeed'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow visible={!hiddenFields['avgSpeed']}
            label="Avg. Speed"
            val={`${row.averageSpeed ? friendlySpeed(row.averageSpeed, device?.units) : 'NA'}`}
            odd={(hiddenFields['avgSpeed'] ? 0 : counter++) % 2 === 0}
            />
        <TripTravelRow visible={!hiddenFields['distance']}
            label="Distance" val={friendlyDist(row.miles, device?.units)}
            odd={(hiddenFields['distance'] ? 0 : counter++) % 2 === 0} 
            />
        <TripTravelRow visible={!hiddenFields['consumption']}
            label="Consumption" val={friendlyMilesPer(row.mpg, device?.units)}
            odd={(hiddenFields['consumption'] ? 0 : counter++) % 2 === 0}
            />
        <TripTravelRow visible={!hiddenFields['label']}
            label="Label" val={<LabelOrPersonRow
                row={row} type="trip" field="label" />}
            odd={(hiddenFields['label'] ? 0 : counter++) % 2 === 0} 
            />
    </div>
}
