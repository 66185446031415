import { friendlyDist, friendlySpeed } from '../../../../shared/helpers';
import { getSafetyPercent, SafetyPercent } from '../report-table/cells/helpers';
import {
	countDriveTime,
	countTraveledDistance,
	getTimeFormated,
	countStoppedTime
} from '../ReportPage/reportPageHelpers';
import { sum } from 'ramda';

export const mapFromDeviceResultToTableBody = (result, deviceUnits, csv = false) => {
	const { device, averageSpeed, distanceTraveled, driveTime, safetyPercent, stoppedTime } = result;

	const speedCalc = isNaN(averageSpeed) ? 0 : friendlySpeed(averageSpeed, deviceUnits);
	const driveTimeCalc = getTimeFormated(driveTime);
	const stoppedTimeCalc = getTimeFormated(stoppedTime / 60);
	const distanceCalc = distanceTraveled > 0 ? friendlyDist(distanceTraveled, deviceUnits) : 0;
	
	if (csv) {
		return [
			[device, speedCalc, safetyPercent, driveTimeCalc, stoppedTimeCalc, distanceCalc]
		];
	}
	return [
		['Average Speed', speedCalc],
		['Safety', safetyPercent],
		['Drive Time', driveTimeCalc],
		['Stopped Time', stoppedTimeCalc],
		['Total Distance', distanceCalc],
	];
};

export const mapFromDeviceResultToTableXlsx = (result, deviceUnits) => {
	const { device, averageSpeed, distanceTraveled, driveTime, safetyPercent, stoppedTime } = result;

	const speedCalc = isNaN(averageSpeed) ? 0 : friendlySpeed(averageSpeed, deviceUnits);
	const driveTimeCalc = getTimeFormated(driveTime);
	const stoppedTimeCalc = getTimeFormated(stoppedTime / 60);
	const distanceCalc = distanceTraveled > 0 ? friendlyDist(distanceTraveled, deviceUnits) : 0;

	return {
		Device: device,
		'Average Speed': speedCalc,
		Safety: safetyPercent,
		'Drive Time': driveTimeCalc,
		'Stopped Time': stoppedTimeCalc,
		'Total Distance': distanceCalc,
	};
};

export const checkFileResolution = ({
	typeWithoutDot,
	fileName,
}: {
	typeWithoutDot: string;
	fileName: string;
}): string => {
	return fileName.endsWith(typeWithoutDot) ? fileName : `${fileName}.${typeWithoutDot}`;
};

export const generateSummaryResults = (records, deviceName, reportType) => {
	const recordsValues = Object.values(records);
	const percents = getSafetyPercent(reportType as SafetyPercent);
	const safetyPercent = sum(recordsValues.map(percents)) / recordsValues.length;
	const travelledTime = countTraveledDistance(records);

	return {
		device: deviceName,
		safetyPercent: safetyPercent ? safetyPercent.toFixed(2) + '%' : safetyPercent,
		driveTime: countDriveTime(records),
		distanceTraveled: Math.round(travelledTime * 100) / 100,
		averageSpeed: Math.floor((travelledTime / countDriveTime(records)) * 10) / 10,
		stoppedTime: countStoppedTime(records),
	};
};
