import axios from "axios";
import { fbapp } from "../shared/firebase";
import { userBilling } from "../stores/reducers/billing";
import { store } from "../stores/store";
import { logout } from "./map-api";
import {resetRememberMeIfExists} from "../shared/helpers";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {

    const { billing } = store.getState();
    const { token } = billing;

    if (token?.access_token) {
      config.headers[
        "Access-Token"
      ] = `${token?.access_token}`;
    }

    return config;
  },
  (err) => {
    console.log("err", err);

    return Promise.reject(err);
  }
);

const refreshToken = async (data) => await axios.post("https://api.mastrack-map.sapient.pro/oauth2/token", data, {
  headers: { "Content-Type": "multipart/form-data" },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const { config } = err;

    if (err?.response?.status === 401 && !config._retry) {
      config._retry = true;
      try {
        const { billing } = store.getState();
        const { refresh_token } = billing?.token;

        const formData = new FormData()
        const refreshData = {
            client_id: process.env.REACT_APP_CLIENT_ID || "testclient",
            client_secret: process.env.REACT_APP_CLIENT_SECRET || "testpass",
            grant_type: "refresh_token",
            refresh_token
        }
        Object.entries(refreshData).forEach(([key, value]) => {
          formData.append(key, value);
        });

        await refreshToken(formData)
        .then((res) => {
          store.dispatch(userBilling(res.data));
        })
        .catch(() => {
          logout().finally(() => {
            document.location.href = "/";
            resetRememberMeIfExists();
            fbapp.auth().signOut();
          });
        });

        return instance(config)
        // instance.defaults.headers.common[
        //   "Authorization"
        //   // @ts-ignore
        // ] = `${data.token_type} ${data.access_token}`;
      } catch (error) {
        if (error?.response?.data) {
          return Promise.reject(error.response.data);
        }

        return Promise.reject(error);
      }
      return Promise.reject(err);
    } else {
      return Promise.reject(err)
    }
  }
);

export default instance;
