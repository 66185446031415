import React from 'react';
import { connect, DispatchProp } from 'react-redux';

import {
  iFullStoreState,
  iList,
  iPerson,
  ItemType,
} from '../../../../shared/interfaces';
import { idValArr, extractDevice } from '../../../../shared/helpers';
import LauncherTile from '../../../../shared/item-launch';
import UserImage from '../../../general/user-image';
import { BaseComponent } from '../../../../shared/BaseComponent';
import { DevicesDetailsContainer } from '../../../../stores/reducers/devicesData';
import { PersonRow } from './PersonRow';

type IPropsFromStore = {
  people: iList<iPerson>;
  devicesDetails: DevicesDetailsContainer;
};
type IProps = IPropsFromStore & DispatchProp;

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => {
  const devicesDetails = state.devicesData.devicesDetails;
  const people = state.general.people || {};

  return {
    people,
    devicesDetails,
  };
};

export default connect(mapStateToProps)(
  class People extends BaseComponent<IProps> {
    debugRender = () => {
      const { people, devicesDetails } = this.props;

      const lastIdx = Object.keys(people).length - 1;

      return (
        <div>
          {idValArr(people).map(({ id, val }, idx) => {
            const assignedDevice = val.hasDevice
              ? extractDevice(devicesDetails, val.hasDevice.deviceId)
              : false;

            return (
              <LauncherTile
                key={id}
                id={id}
                link={`/person/${id}`}
                strikeBottom={idx != lastIdx}
                type={ItemType.person}
              >
                {/* Image and Name */}
                <div style={{ display: 'flex', fontSize: 18 }}>
                  <UserImage
                    person={val}
                    size={35}
                    style={{ marginRight: 8 }}
                  />
                  <span>{val.displayName}</span>
                </div>

                {assignedDevice && <PersonRow device={assignedDevice} />}
              </LauncherTile>
            );
          })}
        </div>
      );
    };
  }
);
