
import React, { useEffect, useState } from "react";
import { useTable, useFilters, usePagination } from "react-table";

import Loader from "../Other/Loader";
import { COLUMNS } from "./unitsData";
import { ColumnFilter } from "./ColumnFiltering";
import BillingPagination from "../Other/BillingPagination";
import { TableApi } from "../interfaces";

export const UnitsTable = ({
  data,
  pageCount,
  fetchData,
  loading,
}: TableApi) => {
  const [skipPageReset, setSkipPageReset] = useState(false);
  const columns = COLUMNS;
  const units = data;

  const updateMyData = () => {
    setSkipPageReset(true);
  };

  useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  const defaultColumn = { Filter: ColumnFilter };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state: tableDetails,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data: units,
      defaultColumn,
      manualPagination: true,
      autoResetPage: false,
      updateMyData,
      pageCount,
      initialState: {
        pageIndex: 0,
      },
    },
    useFilters,
    usePagination
  );

  const { pageIndex, pageSize } = tableDetails;

  useEffect(() => {
    fetchData &&
      fetchData({
        pageIndex,
        pageSize,
        url: "/api/device/devices-subscriptions",
      });
  }, [fetchData, pageIndex, pageSize]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <table className="billing-tables" {...getTableProps()}>
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page?.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells?.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <BillingPagination
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
          />
        </>
      )}
    </>
  );
};

export default UnitsTable;
