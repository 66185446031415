import {put, select, take, takeLatest} from 'redux-saga/effects';
import {channel} from 'redux-saga';
import {iDeviceDetails, iFullStoreState, iList, UserAuth} from '../../shared/interfaces';
import {Actions as GeneralActions} from '../reducers/general-reducers';
import {ACL, UserCan} from '../../shared/constants';
import {cleanDevice, presetDefaultDeviceSettings} from '../../shared/db/devices-db';
import {setAllDevicesInfo, setDeviceInfo} from '../reducers/devicesData/AC';
import { ActionsCreator } from '../reducers/maintenance/actions';
import {clientDb, getDeviceItemsAllowedIds} from '../../shared/firebase';
import {localStorage} from '../../shared/storage';

const deviceDb = () => clientDb().child('devices');
const watchDevicesChannel = channel();

function* worker (action: ReturnType<typeof GeneralActions.SET_CLIENT_CONFIG>) {
    const {config} = action;
    const user: UserAuth = yield select((state: iFullStoreState) => state.auth.user);
    const sub_id = localStorage.get('login-init-be-token')?.sub;

     /* Make visible devices for maintenance actions */
    if (ACL.check(UserCan.SET_MAINTENANCE, user.acl?.can) && !ACL.check(UserCan.DO_ANYTHING, user.acl?.can)) {
        const deviceIds = yield getDeviceItemsAllowedIds(sub_id);

        for (const deviceId of deviceIds) {
            deviceDb().child('device-details').child(deviceId).on('value', (data) => {
                // TODO: remove this cleaning
                if(data.val() !== null){
                    let deviceInfo: iDeviceDetails = cleanDevice(data.val(), deviceId);
                    console.log('deviceInfo', deviceInfo);

                    if (deviceInfo.visible === undefined || deviceInfo.visible === true) {
                        watchDevicesChannel.put(ActionsCreator.setMaintenanceAllowedDevices(deviceInfo));
                        watchDevicesChannel.put(setDeviceInfo(deviceInfo));
                    }
                }
            },
            err => {
                    console.log('subsceribe-err', err);
            });
        }
    } else if (config.strictAccess && !ACL.check(UserCan.DO_ANYTHING, user.acl?.can)) {
        // TODO in next row there is error. Need answer from JP.
        // Question for JP: strictAccess = true and user haven't got permission 'do-anything' in user.acl
        const deviceIds = yield getDeviceItemsAllowedIds(sub_id);

        for (const deviceId of deviceIds) {
            deviceDb().child('device-details').child(deviceId).on('value', (data) => {
                // TODO: remove this cleaning
                if(data.val() !== null){
                    let deviceInfo: iDeviceDetails = cleanDevice(data.val(), deviceId);

                    if (deviceInfo.visible === undefined || deviceInfo.visible === true) {
                        watchDevicesChannel.put(setDeviceInfo(deviceInfo));
                    }
                }
            },
            err => {
                console.log('subsceribe-err', err);
            });
        }
    } else {
        deviceDb().child('device-details').on('value', (data) => {
            const devicesInfo: iList<iDeviceDetails> = data.val();

            for (const deviceId of Object.keys(devicesInfo)) {
                // TODO: remove this cleaning
                const deviceVisible = cleanDevice(devicesInfo[deviceId], deviceId);
                if (deviceVisible.visible === undefined || deviceVisible.visible === true) {
                    devicesInfo[deviceId] = deviceVisible;
                } else {
                    delete devicesInfo[deviceId]
                }
            }

            watchDevicesChannel.put(setAllDevicesInfo(devicesInfo));
        },
        err => {
            console.log('subsceribe-err', err);
        });
    }
}

export default function* () {
    yield takeLatest('SET_CLIENT_CONFIG', worker);
    while (true) {
        const action = yield take(watchDevicesChannel);

        yield put(action);
    }
}
