import React from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { openModal } from "../../../stores/reducers/videoCameras";

import Modal from "../../Modal";

import styles from "./AddAlarmIdModalPrompt.module.scss";

const AddAlarmIdModalPrompt = ({ onClosePrompt, openAddAlarmIdFormModal, IsUserHasNotEnteredAllData, handleLiveStream, isEvent }) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        onClosePrompt();
        openAddAlarmIdFormModal();
    };

    const clickView = () => {
        dispatch(openModal({ key: 'openLiveViewModal', value: true }));
    };

    return (
        <Modal>
            <div className={styles.modalContent} style={{ width: "35vw", padding: '30px 50px' }}>
                <div className={styles.modalHeader}>
                    <div className={styles.modalHeaderRow}>
                        <div className={styles.prompt_text}>
                            {IsUserHasNotEnteredAllData
                                ? (
                                    <>
                                        <h2>There are no pending downloads for this video. </h2>
                                        <div className={styles.asking_text}>Downloading video is prefect for when you want to keep a local record of your event. Once started downloading will take several minutes to process and complete. If you want to view this right now you can click live stream instead.</div>
                                    </>
                                )
                                : isEvent ? (
                                    <>
                                        <h2>This event has not been downloaded from the device.</h2>
                                        <div className={styles.asking_text}>Do you want to download the alarm?</div>
                                    </>
                                )
                                : (
                                    <>
                                        <h2>This video has not been downloaded from the device.</h2>
                                        <div className={styles.asking_text}>Do you want to download the video?</div>
                                    </>
                                )
                            }
                        </div>
                        <button onClick={onClosePrompt} role="close modal button">X</button>
                    </div>
                </div>
                <hr />

                <div className={styles.buttons_block}>
                    <Button
                        type={'button'}
                        variant="contained"
                        onClick={handleClick}
                    >
                        {IsUserHasNotEnteredAllData ? "Yes, download" : isEvent ? "Yes, download the alarm" : "Yes, download the video"}
                    </Button>
                    <Button
                        type={'button'}
                        variant="contained"
                        onClick={clickView}
                    >
                        Live Stream
                    </Button>
                    <Button
                        type={'button'}
                        variant="outlined"
                        onClick={onClosePrompt}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default AddAlarmIdModalPrompt;
