import { AnyAction } from 'redux';

import {
    REMOVE_SELECTED_POINT,
    SET_SELECTED_POINT, setSelectedPoint,
} from './AC';
import { iLocation } from '../../../shared/interfaces';
import { SET_DEVICE_ROUTE } from '../devicesTripsPoints/AC';


export type IReducerSelectedPoint = {
    deviceId: string;
    tripId: string;
    pointId: string;
    position: iLocation;
} | null;

export default (state: IReducerSelectedPoint = null, action: AnyAction): IReducerSelectedPoint => {
    switch (action.type) {
        case SET_SELECTED_POINT: {
            const {deviceId, tripId, pointId, position} = (action as ReturnType<typeof setSelectedPoint>).payload;

            if (pointId === state?.pointId) return null;

            return {
                deviceId,
                tripId,
                pointId,
                position,
            };
        }

        case SET_DEVICE_ROUTE:
        case REMOVE_SELECTED_POINT: {
            if (state) {
                return null;
            }
            return state;
        }

        default:
            return state;
    }
}
