import React, {useEffect, useState} from "react";
import {ReportNavbar} from "./ReportNavbar";
import DashboardBlock from "../../DashboardBlock";
import "./maintenance.scss";
import SummaryBlock from "./MaintenanceParts/SummaryBlock";
import ItemizedBlock from "./MaintenanceParts/ItemizedBlock";
import {ReactComponent as ListSvg} from "../../../assets/svg/total_list.svg";
import {ReactComponent as RatingSvg} from "../../../assets/svg/rating.svg";
import {beToken, iCompleteMaintenanceTask, iDeviceDetails, iFullStoreState, iList, iTag} from "../../../shared/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {DashboardSize} from "../../../stores/reducers/dashboardInfo";
import { iReportDetails, ReportAC } from "../../../stores/reducers/report-reducers";
import FilterPrompt from "./FilterPrompt";
import {Fa} from '../../elements/fa';
import {faSpinner} from '@fortawesome/fontawesome-free-solid';
import { iGetReportPropsWrapper } from "../../../shared/db/report-db";
import { Map } from "immutable";
import FilterIndicators from "./FilterIndicators";
import {isMobile} from "../../../shared/helpers";

const usePopulateReportData = () => {
    const dispatch = useDispatch();
    const {devicesDetails, report, allTags, userToken} = useSelector<iFullStoreState, {
        devicesDetails: Map<string, iDeviceDetails>;
        report: iReportDetails;
        allTags: iList<iTag>;
        userToken: beToken;
    }>(s => ({
        devicesDetails: s.devicesData.devicesDetails,
        report: s.report.details,
        allTags: s.general.tags,
        userToken: s.auth.user?.beToken
    }));

    const populateReportData = () => {
        if (!report.searchKey) return;
        if (report.dates.length === 0) return;

        let query: iGetReportPropsWrapper = {
            allTags,
            filters: report.filters,
            dates: report.dates,
            devicesDetails,
        };
        dispatch(ReportAC.fetchMaintenanceReports(query, userToken?.devices || false));
    }

    return {
        populateReportData
    }
}

const Maintenance = () => {
    // const isMobile = window['cordova'];
    // console.log(isMobile);
    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);
    const [tabName, setTab] = useState("summary");
    const [summaryFiler, setSummaryFiler] = useState("total");
    const searchKey = useSelector<iFullStoreState, string>(s => s.report.details.searchKey);
    const loadingData = useSelector<iFullStoreState, boolean>(s => s.report.loadingData);
    const maintenance = useSelector<iFullStoreState, iList<iCompleteMaintenanceTask> | null>(s => s.report.completedMaintenance.records)
    const reportDetails = useSelector<iFullStoreState, iReportDetails>(s => s.report.details);
    const { populateReportData } = usePopulateReportData();

    useEffect(() => {
        populateReportData();
    }, [reportDetails])

    if (!searchKey) {
        return (
            <DashboardBlock>
                <ReportNavbar/>
                <div className="report-maintenance-wrapper report-maintenance-wrapper--prompt">
                    <FilterPrompt />
                </div>
            </DashboardBlock>
        )
    } else if (searchKey && !loadingData && !Object.keys(maintenance || {}).length) {
        return (
            <DashboardBlock>
                <ReportNavbar/>
                <div className="report-maintenance-wrapper report-maintenance-wrapper--prompt">
                    <div>
                        No data found
                        <FilterPrompt />
                    </div>
                </div>
            </DashboardBlock>
        )
    }
    else if (loadingData) {
        return (
            <DashboardBlock>
                <ReportNavbar/>
                <div className="report-maintenance-wrapper">
                    <FilterIndicators />
                    <div className="report-maintenance-wrapper report-maintenance-wrapper--prompt">
                        <div style={{width: '100%', fontSize: 25, textAlign: 'center'}}><Fa icon={faSpinner} spin /></div>
                    </div>
                </div>
            </DashboardBlock>
        )
    }

    console.log("search key", searchKey, "loading", loadingData);

    return <DashboardBlock>
        <ReportNavbar/>
            <div className="report-maintenance-wrapper">
            <FilterIndicators />
            <div className="report-maintenance-controls">
                <div className={`report-maintenance-tabs ${DashboardSize.OPEN === size && !isMobile ? "report-maintenance-tabs-column" : ""}`
                }>
                    <div className="report-maintenance-tab-block-wrapper">
                        <button className={`report-maintenance-tab-block ${(tabName === "summary") ? "report-maintenance-tab-block-active" : ""}`}
                                onClick={() => setTab("summary")}>Summary
                        </button>
                        <div className="report-maintenance-tab-separator">
                        </div>
                        <button className={`report-maintenance-tab-block ${(tabName === "itemized") ? "report-maintenance-tab-block-active" : ""}`}
                                onClick={() => setTab("itemized")}>Itemized
                        </button>
                    </div>
                    {
                        (tabName === "summary")
                            ? <div
                                className={`report-maintenance-summary-tabs ${DashboardSize.OPEN === size && !isMobile ? "report-maintenance-summary-tabs-small" : ""}`
                                }>
                                <button
                                    className={`report-maintenance-summary-tabs-button report-maintenance-summary-tabs-button-total ${
                                        (summaryFiler === "total")
                                            ? "report-maintenance-summary-tabs-button-active" : ""}`}
                                    onClick={() => setSummaryFiler("total")}>
                                    <ListSvg
                                        className={`report-maintenance-summary-tabs-svg ${
                                            (summaryFiler === "total")
                                                ? "report-maintenance-summary-tabs-svg-active" : ""}`} />
                                    Total Costs Spent
                                </button>
                                <button
                                    className={`report-maintenance-summary-tabs-button report-maintenance-summary-tabs-button-total ${
                                        (summaryFiler === "average")
                                            ? "report-maintenance-summary-tabs-button-active" : ""}`}
                                    onClick={() => setSummaryFiler("average")}>
                                    <RatingSvg className={`report-maintenance-summary-tabs-svg ${
                                        (summaryFiler === "average")
                                            ? "report-maintenance-summary-tabs-svg-active" : ""}`}
                                    />
                                    Avg Score vs Cost
                                </button>
                            </div>
                            : null
                    }
                </div>
            </div>

            {(tabName === "summary") ? <SummaryBlock filter={summaryFiler} /> : null}
            {(tabName === "itemized") ? <ItemizedBlock /> : null}
        </div>
    </DashboardBlock>
};

export default Maintenance;