import moment from "moment";

export const COLUMNS = [
  {
    Header: "Order Number",
    accessor: "order_ID",
    disableSortBy: true,
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ cell: { value } }) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },

  {
    Header: "Shipped",
    accessor: "shipped",
    Cell: ({ cell: { value } }) => {
      return value === 0 ? "No" : "Yes";
    },
    disableSortBy: true,
  },
];
