import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import { BaseComponent } from '../../../../shared/BaseComponent';
import { ACL, UserCan } from '../../../../shared/constants';
import { getPersonAllowedSee } from '../../../../shared/db/general-db';
import {
  clientDefaultSettings,
  namingHelper,
} from '../../../../shared/helpers';
import { FaLink } from '../../../elements/fa';
import { TableSimple } from '../../../elements/plain-table';
import LocalPersonTile from '../LocalPeopleTile';
import React from 'react';
import * as structure from '../../../../shared/interfaces';
import styles from '../PersonTabPage.module.scss';

export class DefaultSettingsTile extends BaseComponent<
  { userCanDo; personId },
  {
    allowed: structure.iItemsAllowed;
    defaultSettings: structure.iDefaultSettings;
  }
> {
  async componentDidMount() {
    const { personId } = this.fullProps;

    this.setState({ allowed: await getPersonAllowedSee(personId) });
  }

  debugRender = () => {
    const { userCanDo, personId } = this.props;

    const isSetAllowed = !this.state || !this.state.allowed;
    const isSetDefaultSettings = !this.state || !this.state.defaultSettings;

    isSetDefaultSettings &&
      clientDefaultSettings((value) =>
        this.setState({ defaultSettings: value })
      );

    return (
      <LocalPersonTile
        collapsible
        initialCollapsed
        title='Default Settings'
        loading={isSetAllowed}
        action={
          !ACL.check(UserCan.DO_ANYTHING, userCanDo) ? undefined : (
            <FaLink
              to={`/person/${personId}/default-settings`}
              icon={faPencilAlt}
              className={styles.tableLink}
            />
          )
        }
      >
        {isSetAllowed && isSetDefaultSettings ? null : (
          <TableSimple
            sm
            striped
            headers={['Name', 'Value']}
            style={{ fontSize: 11 }}
          >
            {Object.entries(this.state.defaultSettings || {}).map((entrie) => (
              <tr key={entrie[0]}>
                <td className={styles.tableItem}>{namingHelper(entrie[0])}</td>
                <td className={styles.tableItem}>{entrie[1]}</td>
              </tr>
            ))}
          </TableSimple>
        )}
      </LocalPersonTile>
    );
  };
}
