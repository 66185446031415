import React from "react";
import Modal from "../../../../../Modal";
import {Fa} from "../../../../../elements/fa";
import { faTimes, faSpinner } from "@fortawesome/fontawesome-free-solid";
import {useDispatch, useSelector} from "react-redux";
import {iReducersState} from "../../../../../../stores/reducers";
import styles from "./modalCamerasForTag.module.scss";
import { openModal } from "../../../../../../stores/reducers/videoCameras";


const ModalCamerasForTag = (props): JSX.Element => {

  const dispatch = useDispatch();
  const { modals } = useSelector((state: iReducersState) => state.videoCameras);

  const {
    openLiveViewModal, 
    openLiveViewPlayer, 
  } = modals;

  const closeModal = async () => {
    props.setToCloseModalNow(true);
    for (let key in modals) modals[key] && dispatch(openModal({key, value: false}));
  };

  return (
    <>
      {(openLiveViewPlayer || openLiveViewModal) && (
        <Modal className={styles.modal} onBackdropClick={closeModal}> 
          <div className={styles.modalChannels}>
            <div className={styles.buttonClose}>
              <button className={styles.modal_header__close_ml} onClick={closeModal}>
                <Fa icon={faTimes} className={styles.modalCamera__icon} />
              </button>
            </div>
            {props.children}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalCamerasForTag;
