import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { iFullStoreState, UserAuth } from "../../../shared/interfaces";
import {ActionsCreator as MaintenanceAC} from "../../../stores/reducers/maintenance/actions";

interface iMaintenanceTaskMultiselect {
    shouldClear?: boolean;
    onClose?: Function;
    onChange?: Function;
    placeholder?: string;
    multi?: boolean;
    options?: { value: string, label: string }[];
}

const MaintenanceTaskMultiselect: FC<iMaintenanceTaskMultiselect> = ({ options: optionsProps ,shouldClear, onClose, onChange, placeholder = "", multi = true }) => {
    const dispatch = useDispatch();
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
    const options = useSelector<iFullStoreState, string[]>(s => Object.values(s.maintenance.tasksOptions));
    const handleActiveTasksChange = (list) => {
        onChange?.(list);
    }
    const handleClose = (list) => {
        onClose?.(list);
    }

    const handleNewValueAdd = (value, newList) => {
        dispatch(MaintenanceAC.addNewMaintenanceTaskOption(user, value));
        onChange?.(newList);
    }
    return (
        <MultiSelect
            onChange={(list) => {
                handleActiveTasksChange(list);
            }}
            multi={multi}
            onClose={handleClose}
            addCustomField={true}
            onNewValueAdd={handleNewValueAdd}
            placeholder={placeholder}
            shouldClear={shouldClear}
            defaultValue={[]}
            options={[{value: 'oil_change', label: 'Oil Change'},
                {value: 'brake_check', label: 'Brake Check'},
                {value: 'air_filter', label: 'Air Filter'},
                {value: 'cabin_filter', label: 'Cabin Filter'},
                {value: 'rotate_tires', label: 'Rotate Tires'},
                ...options.map(task => ({value: task, label: task}))
            ]}
        />
    )
}

export default MaintenanceTaskMultiselect;