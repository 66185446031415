import { Portal } from "@material-ui/core";
import React, { FunctionComponent, useEffect } from "react";
import { FilterType } from "../pages/reports/ReportPageFilters.interfaces";
import FilterEditor from "./FilterEditor/FilterEditor";
import {ReactComponent as DevicesSvg} from "../../assets/svg/device.svg"
import {ReactComponent as PeopleSvg} from "../../assets/svg/people.svg";
import {ReactComponent as LayerSvg} from "../../assets/svg/layers.svg";
import {ReactComponent as WarningAmberSvg} from "../../assets/svg/warning_amber.svg";
import {ReactComponent as BookmarkSvg} from "../../assets/svg/bookmarks.svg";

import { useDispatch, useSelector } from "react-redux";
import { alertsSelector, deviceSelector, labelsSelector, personSelector, tagsSelector } from "../pages/reports/ReportSelectors";

import './SheduleFiltersEditor.styles.scss';
import { AlertTypeTitle, AlertTypes, iDeviceDetails, iFullStoreState } from "../../shared/interfaces";
import { ReportSelectorLabel } from "../pages/reports/ReportSelectorLabel";
import { ReportPageFilterSection } from "../pages/reports/ReportPageFilterSection";
import { lEmpty } from "../../shared/helpers";
import { ReportAC, ReportActions } from "../../stores/reducers/report-reducers";

export const checkingSelectedFiltersCount = ({filters}) => {
	const filtersItemCount = Object.entries(filters).reduce((acc, [key, value]) => ({
		...acc,
		[key]: Object.keys(value).length
	}), {});

	return filtersItemCount
}

interface SheduleFiltersEditorProps {
    isOpen: boolean;
    onClose: () => void;
    isActivity: boolean;
}
 
const SheduleFiltersEditor: FunctionComponent<SheduleFiltersEditorProps> = ({isOpen, onClose, isActivity}) => {
    const filters = useSelector((state: iFullStoreState) => state.report.details.filters);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ReportAC.RESET_ALL_FILTERS());
    }, []);

    const visibleDevices = useSelector((state: iFullStoreState) => {
        const devices = state.devicesData.devicesDetails;

        const filter = Object.keys(filters[FilterType.EDIT_DEVICES] ?? {});

        return devices.toArray().map(([, device]) => {
            const {id, name, icon} = device
            const isActive = filter.includes(id);

            return <ReportSelectorLabel key={id} itemId={id} active={isActive} title={name} fa={icon} url={icon} />
        })
    });
    const visiblePeoples = useSelector((state: iFullStoreState) => {
        const peoples = state.general.people;

        const filter = Object.keys(filters[FilterType.EDIT_PEOPLE] ?? {});

        return Object.values(peoples).map((person) => {
            const {id, displayName, photoURL} = person
            const isActive = filter.includes(id);

            return <ReportSelectorLabel key={id} itemId={id} active={isActive} title={displayName} url={photoURL} />
        })
    });
    const visibleTags = useSelector((state: iFullStoreState) => {
        const tags = state.general.tags;

        const filter = Object.keys(filters[FilterType.EDIT_TAGS] ?? {});

        return Object.values(tags).map((tag) => {
            const {id, icon, name} = tag.details
            const isActive = filter.includes(id);
            const tagIcon = {
                fa: icon?.fa ?? (icon?.url ? null : 'tag'),
                url: icon?.url
            }

            return <ReportSelectorLabel key={id} itemId={id} active={isActive} title={name} {...tagIcon} />
        })
    });
    const visibleAlerts = useSelector((state: iFullStoreState) => {
        const filter = Object.keys(filters[FilterType.EDIT_ALERTS] ?? {});

        return Object.keys(AlertTypes).map((alert) => {
            const isActive = filter.includes(alert);

            return <ReportSelectorLabel key={alert} itemId={alert} active={isActive} title={AlertTypeTitle(alert)} />
        })
    });
    const visibleLabels = useSelector((state: iFullStoreState) => {
        const labels = state.general.tripLabels.reduce((prev, label) => {
            prev[label] = label

            return prev;
        }, {})  as {[key: string]: string}

        const filter = Object.keys(filters[FilterType.EDIT_LABELS] ?? {});

        return Object.values(labels).map((label) => {

            const isActive = filter.includes(label);

            return <ReportSelectorLabel key={label} itemId={label} active={isActive} title={label} />
        })
    });

    const countOfSelectedFilters = checkingSelectedFiltersCount({filters});

    return ( 
        <>
            {
                isOpen && (
                    <Portal>
            <div className="filters__modal__wrapper">
                <div className="filters__modal">
                    <div className="filters__modal__header">Report filters</div>
                    <div className="filters__modal__content">
					<ReportPageFilterSection
						disabled={!lEmpty(filters[FilterType.EDIT_TAGS])}
						Icon={DevicesSvg}
						title="Devices"
						visible={visibleDevices}
						filterType={FilterType.EDIT_DEVICES}
						countOfSelectedFilters={countOfSelectedFilters}
					/>
					{!isActivity && <ReportPageFilterSection
						disabled={!lEmpty(filters[FilterType.EDIT_TAGS])}
						Icon={PeopleSvg}
						visible={visiblePeoples}
						title="People"
						filterType={FilterType.EDIT_PEOPLE}
						countOfSelectedFilters={countOfSelectedFilters}
					/>}
					<ReportPageFilterSection
						Icon={LayerSvg}
						disabled={!lEmpty(filters[FilterType.EDIT_PEOPLE]) || !lEmpty(filters[FilterType.EDIT_DEVICES])}
						title="Tags"
						visible={visibleTags}
						filterType={FilterType.EDIT_TAGS}
						countOfSelectedFilters={countOfSelectedFilters}
					/>
          {!isActivity && <ReportPageFilterSection
						Icon={WarningAmberSvg}
						title="Alerts"
						visible={visibleAlerts}
						filterType={FilterType.EDIT_ALERTS}
						countOfSelectedFilters={countOfSelectedFilters}
					/>}
          {!isActivity && <ReportPageFilterSection
						Icon={BookmarkSvg}
						title="Labels"
						visible={visibleLabels}
						filterType={FilterType.EDIT_LABELS}
						countOfSelectedFilters={countOfSelectedFilters}
					/>}

                    </div>
                <div className="filters__modal__footer">
                    <button className="but  but-orange-white but-md" onClick={onClose}>Apply</button>
                </div>
                </div>
            </div>
        </Portal>
                )
            }
        </>
     );
}
 
export default SheduleFiltersEditor;