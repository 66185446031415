import React, {FC, useState} from "react";
// import {Collapsible} from "../general/Collapsible";
import "./index.scss";
import { ReactComponent as ChevronSvg } from "../../assets/svg/chevron_right.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { isMobile } from "../../shared/helpers";

type IProps = {
    options: {value: string, text: string}[]
    value: string;
    Component: FC<any>
    onChange: ({target: {value: string}}) => any
}

export const SelectElement: FC<IProps> = ({
    options,
    value,
    Component,
    onChange
}) => {
    const [selecting, setSelecting] = useState(false);
    const handleSelectingToggle = () => setSelecting(!selecting);
    const handleSelectOptionClick = (clickValue) => () => {
        clickValue !== value && onChange({target: {value: clickValue}});
        setSelecting(false);
    }

    const handleOutsideClick = () => {
        setSelecting(false);
    }
    return (
        <div className={"select-element"}
             tabIndex={10000}
             onBlur={handleOutsideClick}
        >
            <Component
                className={"select-element-item"}
                onClick={handleSelectingToggle}
            >
                <div className={"select-element-active-content"}>
                    {options.find(o => o.value === value).text}
                    <ChevronSvg className={`select-element-icon`}  style={(isMobile || window["cordova"] ? {width: "16px", height: "20px"} : {width: "24px", height: "24px"})} />
                </div>
            </Component>
            {
                selecting && (
                    <div className={`select-element-body`}>
                        <PerfectScrollbar>
                            {options.map(option => (
                                <Component
                                    key={option.value}
                                    onClick={handleSelectOptionClick(option.value)}
                                    className={`select-element-item ${option.value === value? "active": ""}`}
                                >
                                    {option.text}
                                </Component>
                            ))}
                        </PerfectScrollbar>
                    </div>
                )
            }
        </div>
    )
}

export const OptionElement = ({children, className, ...props}) => {
    return (
        <div {...props} className={`${className} option-element`}>{children}</div>
    )
}