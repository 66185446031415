import * as yup from 'yup';
import {userFieldNames} from './AddSubUserPage.types';

export const getFieldOptions = (fieldName: userFieldNames) => {
  switch (fieldName) {
    case userFieldNames.desiredUserName:
      return {
        name: 'Desired user name',
        type: 'text',
      };
    case userFieldNames.password:
      return {
        name: 'Password',
        type: 'password',
      };
    case userFieldNames.password_repeat:
      return {
        name: 'Repeat password',
        type: 'password',
      };
    case userFieldNames.firstName:
      return {
        name: 'First name',
        type: 'text',
      };
    case userFieldNames.lastName:
      return {
        name: 'Last name',
        type: 'text',
      };
    case userFieldNames.email:
      return {
        name: 'Email',
        type: 'text',
      };
    case userFieldNames.phoneNumber:
      return {
        name: 'Phone number',
        type: 'text',
      };
    case userFieldNames.address1:
      return {
        name: 'Address',
        type: 'text',
      };
    case userFieldNames.address2:
      return {
        name: 'Address (aditional)',
        type: 'text',
      };
    case userFieldNames.country:
      return {
        name: 'Country',
        type: 'text',
      };
    case userFieldNames.city:
      return {
        name: 'City',
        type: 'text',
      };
    case userFieldNames.state:
      return {
        name: 'State',
        type: 'text',
      };
    case userFieldNames.postalCode:
      return {
        name: 'Postal code',
        type: 'text',
      };
    case userFieldNames.isSubAdmin:
      return {
        name: 'Sub Admin',
        type: 'checkbox',
      };
  }
};

const mustBeNumber = 'Must be a number';
const someIsRequired = (some: string) => `${some} is required`;

export const validationSchema = yup
  .object()
  .shape({
    desiredUserName: yup.string(),
    password: yup
      .string()
      .required(someIsRequired('Password'))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 characters, one uppercase, one lowercase, one number and one special case character'
      ),
    password_repeat: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required(someIsRequired('Password confirm')),
    firstName: yup.string().required(someIsRequired('First name')),
    lastName: yup.string().required(someIsRequired('Last name')),

    email: yup.string().email().required(someIsRequired('Email')),
    phoneNumber: yup
      .number()
      .typeError(mustBeNumber)
      .required(someIsRequired('Phone')),
    address1: yup.string().required(someIsRequired('Address')),
    address2: yup.string(),
    country: yup.string(),
    state: yup.string(),
    postalCode: yup.string().typeError(mustBeNumber),
    city: yup.string().required(someIsRequired('City')),
  })
  .required();
